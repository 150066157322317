/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @component Login
 *
 * @version 20190715
 * @since 20190715 Initial release
 *
 */

import { memo, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import IntlMessages from 'components/util/IntlMessages';
import { Link } from 'react-router-dom';
import { BASENAME_URL } from 'constants/settings';
import ErrorMessage from 'components/errors/ErrorMessage';
import OutlinedInput from '@mui/material/OutlinedInput';
import { intlMessages, isDefined } from 'services/util/auxiliaryUtils';
import { StyledLoginForm, StyledLoginInputsContainer } from 'pages/auth/StylesLogin';
import useAuth from 'hooks/auth/useAuth';
import TermsBanner from 'components/core/ServicesTerms';
import { useUserStore } from 'store/user';
import { Button } from '@save2compete/efz-design-system';

interface ILoginInputs {
    username: string;
    password: string;
}
/**
 *
 * @param {*} param0
 */
const FormLogin = ({ isS2CVersion }) => {
    const { requesting, login, userTerms, setUserTerms, SSOUserOK, checkADFSUser, setSSOUserOK } = useAuth();
    const { user } = useUserStore();
    const userTypeID = user?.tipo_utilizador_id ?? null;
    const {
        handleSubmit,
        control,

        formState: { errors },
    } = useForm<ILoginInputs>({
        defaultValues: {
            username: '',
            password: '',
        },
    });

    const normalLoginCondition = useMemo(() => isDefined(SSOUserOK) && !SSOUserOK, [SSOUserOK]);

    const onSubmit = (payload: ILoginInputs) => {
        if (normalLoginCondition) {
            login(payload);
        } else {
            checkADFSUser(payload?.username);
        }
    };

    return (
        <>
            <StyledLoginForm autoComplete={'true'} onSubmit={handleSubmit(onSubmit)} className="col-12 col-lg-10 col-xl-8">
                <StyledLoginInputsContainer>
                    <div>
                        <Controller
                            name="username"
                            control={control}
                            rules={{ required: 'yup.message.required' }}
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            render={({ field: { ref, ...field } }) => (
                                <OutlinedInput
                                    {...field}
                                    disabled={requesting || normalLoginCondition}
                                    className={`w-100`}
                                    error={!!errors?.username}
                                    placeholder={intlMessages('label.username')}
                                    inputProps={{
                                        'data-testid': 'login-username-id',
                                    }}
                                />
                            )}
                        />
                        {errors?.username && (
                            <span className="d-block mb-3 w-100">
                                <ErrorMessage error={errors.username} />
                            </span>
                        )}
                    </div>
                    {normalLoginCondition && (
                        <span className="reset-form" onClick={() => setSSOUserOK(null)}>
                            <IntlMessages id="label.change" />
                        </span>
                    )}
                </StyledLoginInputsContainer>
                {normalLoginCondition && (
                    <StyledLoginInputsContainer>
                        <div>
                            <Controller
                                name="password"
                                control={control}
                                rules={{ required: 'yup.message.required' }}
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                render={({ field: { ref, ...field } }) => (
                                    <OutlinedInput
                                        {...field}
                                        type={'password'}
                                        disabled={requesting}
                                        placeholder={intlMessages('label.password')}
                                        className="w-100"
                                        error={!!errors?.password}
                                        inputProps={{
                                            'data-testid': 'login-password-id',
                                        }}
                                    />
                                )}
                            />
                            {errors?.password && (
                                <span className="d-block w-100">
                                    <ErrorMessage error={errors.password} />
                                </span>
                            )}
                        </div>
                    </StyledLoginInputsContainer>
                )}

                {/*actions*/}
                <Button disabled={requesting} loading={requesting} type="submit" data-testid="login-submit-id" dataTestId="login-submit">
                    <IntlMessages id={'label.signin'} />
                </Button>

                {/*reset password*/}
                {normalLoginCondition && (
                    <div className={`mt-3 reset-password-text ${isS2CVersion ? 'isS2C' : ''}`}>
                        <h4>
                            <Link
                                className={requesting ? 'disabled-cursor' : ''}
                                to={`${BASENAME_URL}reset-password`}
                                data-testid="login-forget-password-id"
                            >
                                <IntlMessages id={'page.login.forgetPassword'} />
                            </Link>
                        </h4>
                    </div>
                )}
            </StyledLoginForm>
            {isDefined(userTerms) ?
                <TermsBanner userTerms={userTerms} userTypeID={userTypeID} setUserTerms={setUserTerms} />
            :   <></>}
        </>
    );
};

export default memo(FormLogin);
