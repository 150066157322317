import BusinessModels from 'components/BusinessModels';
import { StyledBusinessModelsProContainter } from 'pages/Restricted/BusinessModelsPro/StylesBusinessModelsPro';
import { Helmet } from 'react-helmet';
import { getHeadTitle } from 'services/settings';
import { intlMessages } from 'services/util/auxiliaryUtils';

const BusinessModelsContainer = () => {
    return (
        <StyledBusinessModelsProContainter>
            <Helmet>
                <title>{`${getHeadTitle()} · ${intlMessages('page.spvSimple.reserved.step.6')}`}</title>
            </Helmet>
            <BusinessModels haveActions={false} />
        </StyledBusinessModelsProContainter>
    );
};

export default BusinessModelsContainer;
