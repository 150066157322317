//Libraries
import PropTypes from 'prop-types';
import IntlMessages from 'components/util/IntlMessages';
import { USERS_TYPE_ID } from 'constants/user';
import { gAnalyticsEvt } from 'services/gAnalytics';
import { GA_LABEL_TOP_LEVEL_NAVIGATION, GA_TYPE_EVT } from 'constants/gAnalytics';
import { Button } from '@save2compete/efz-design-system';

/**
 * SectionInfo by Client
 *
 * @param {*} param
 */
const SectionInfo = ({ title, subtitle, handlerClearSelection, userTypeID, handleGoTo, isThisPathActive, segmento_descricao }) => {
    return (
        <div className={`dropdown-header-section justify-content-between`}>
            <div className={`header-section-text ms-2${subtitle ? '' : ' justify-content-center'}`}>
                <div className="client-info">
                    <div>
                        <h5 className="mb-0">{title}</h5>
                        {subtitle && (
                            <h6 className="d-flex mb-0 mt-1">
                                {subtitle} {segmento_descricao}
                            </h6>
                        )}
                    </div>
                    {/* <ClientDataIcon /> */}
                </div>
                <div className={'d-flex justify-content-between mb-0 btn-actions'}>
                    {/* Go to Facilities */}
                    <Button
                        icon="startArrowRight"
                        variant="tertiary"
                        size="sm"
                        onClick={() => {
                            gAnalyticsEvt(GA_LABEL_TOP_LEVEL_NAVIGATION.CLIENT_DATA, GA_TYPE_EVT.TOP_LEVEL_NAVIGATION);
                            if (!isThisPathActive) handleGoTo();
                        }}
                    >
                        <IntlMessages id="page.client.clientData" />
                    </Button>
                    {/* Clear client */}
                    {userTypeID !== USERS_TYPE_ID.CLIENT && (
                        <Button
                            variant="tertiary"
                            size="sm"
                            icon="startXMarkExit"
                            onClick={() => {
                                gAnalyticsEvt(GA_LABEL_TOP_LEVEL_NAVIGATION.CLIENT_CLEAN_SELECTION, GA_TYPE_EVT.TOP_LEVEL_NAVIGATION);
                                handlerClearSelection();
                            }}
                        >
                            <IntlMessages id="label.clearSelection" />
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

SectionInfo.defaultProps = {
    title: 'loremIpsum',
};

SectionInfo.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    handlerClearSelection: PropTypes.func.isRequired,
    handleGoTo: PropTypes.func.isRequired,
    userTypeID: PropTypes.number.isRequired,
};

export default SectionInfo;
