import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import Logo from 'components/common/NGHeader/Logo';
import { GA_LABEL_TOP_LEVEL_NAVIGATION, GA_TYPE_EVT } from 'constants/gAnalytics';
import { BASENAME_URL, BASENAME_URL_CLIENT, BASENAME_URL_FACILITY } from 'constants/settings';
import { showFacilityAndClientCards, styleHeaderByCompanyID } from 'services/settings';
import { gAnalyticsEvt } from 'services/gAnalytics';
import LangSwitcherCard from 'components/common/Header/LangSwitcherCard';
import UserCard from 'components/common/Header/UserCard';
import useHeader from 'hooks/header';
import { isDefined, isExistWord, isFieldDefined } from 'services/util/auxiliaryUtils';
import FacilityCard from 'components/common/Header/FacilityCard';
import ClientCard from 'components/common/Header/ClientCard';
import SidebarOpener from 'components/common/NGHeader/SidebarOpener';
import { useContext } from 'react';
import { SidebarContext } from 'contexts/sidebarContext';
import SalesSegment from 'components/common/NGHeader/SalesSegment';
import CompanyDescription from 'components/common/NGHeader/CompanyDescription';
import { useMediaQuery } from '@mui/material';
import { ReduxContext } from 'contexts/redux/reduxContext';
import { StyledHeader } from './StylesHeader';

const Header = (props) => {
    const location = useLocation();

    const { isSidebarPermanent } = useContext(SidebarContext);

    const isMobile = useMediaQuery('(max-width:480px)');
    const isTablet = useMediaQuery('(max-width:760px)');

    const {
        isOpenLangSwitcher,
        handleToggleLangSwitcherCard,
        handleSwitchLanguage,
        userLocale,
        locale,
        handleToggleUserCard,
        isOpenUserInfo,
        handleUserSignOut,
        userUsername,
        userTypeID,
        facilityCPE,
        isOpenFacilityCard,
        handleToggleFacilityCard,
        companyProfileId,
        detailedAddress,
        facilityAddress,
        facilitiesList,
        isRequestingFacilities,
        handleChangeAutocomplete,
        handlerSeeAllFacilities,
        handlerGoToFacility,
        handlerCreateFacility,
        clientID,
        handlerClearFacility,
        forwardFormFacility,
        userChannelIDGroup,
        isOpenClientCard,
        handleToggleClientCard,
        sClientsData,
        sClientsHistory,
        isRequestingSClients,
        handlerClearClient,
        clientNIPC,
        clientDescription,
        handlerGoToUser,
        hasUniqueSalesSegment,
        isB2C,
        clientFirstName,
        clientSurname,
        clientVAT,
        facilityInfo,
        hasWhiteSalesLogo,
        hasCompanyDescription,
        // hasClientFacilitySearch
    } = useHeader();

    const { facility } = useContext(ReduxContext);
    const facilityInfoRedux = facility?.info ?? null;

    const fillTitle = () => {
        if (!isB2C) {
            if (isFieldDefined(facilityInfoRedux?.manual_address)) return facilityInfoRedux?.manual_address;
            return !!detailedAddress && detailedAddress?.street ? detailedAddress?.street : facilityAddress ?? '';
        } else {
            if (isFieldDefined(facilityInfoRedux?.location?.manual_address)) return facilityInfoRedux?.location?.manual_address;
            return detailedAddress?.address ?? facilityInfo?.location?.address ?? '';
        }
    };

    return (
        <StyledHeader
            className={`header-container${styleHeaderByCompanyID(companyProfileId)} ${isExistWord(location.pathname, 'error') || isExistWord(location.pathname, 'logout') ? 'd-none' : ''}`}
        >
            {!isSidebarPermanent && <SidebarOpener />}
            <Link
                to={`${BASENAME_URL}app/solutions`}
                onClick={() => gAnalyticsEvt(GA_LABEL_TOP_LEVEL_NAVIGATION.LOGO_HOMEPAGE, GA_TYPE_EVT.TOP_LEVEL_NAVIGATION)}
                className="h-100 d-flex align-items-center"
            >
                <Logo isWhite={hasWhiteSalesLogo} showLogoEfz={!isTablet} />
            </Link>
            {hasCompanyDescription && <CompanyDescription companyProfileId={companyProfileId} />}
            {!hasUniqueSalesSegment && <SalesSegment />}
            <div className="header-menu h-100">
                <ul className="menu-list">
                    {showFacilityAndClientCards(location.pathname) && !isMobile && (
                        <>
                            {
                                /*hasClientFacilitySearch &&*/ <li className={`menu-item-large${isOpenClientCard ? '-expanded' : ''}`}>
                                    <ClientCard
                                        showInfo={!isB2C ? isDefined(clientNIPC) : isDefined(clientFirstName)}
                                        title={!isB2C ? clientDescription ?? '' : `${clientFirstName ?? ''} ${clientSurname ?? ''}`}
                                        subtitle={!isB2C ? clientNIPC ?? '' : clientVAT ?? ''}
                                        isOpen={isOpenClientCard}
                                        handleToggle={handleToggleClientCard}
                                        handleChangeAutocomplete={handleChangeAutocomplete}
                                        searchData={{
                                            list: sClientsData?.length > 0 ? sClientsData : sClientsHistory,
                                            loader: isRequestingSClients,
                                            history: sClientsHistory,
                                        }}
                                        userTypeID={userTypeID}
                                        companyProfileId={companyProfileId}
                                        handlerClearClient={handlerClearClient}
                                        isThisPathActive={window.location.pathname === BASENAME_URL_CLIENT}
                                        userChannelIDGroup={userChannelIDGroup}
                                    />
                                </li>
                            }
                            {/*facility*/}
                            {
                                /*hasClientFacilitySearch &&*/ <li className={`menu-item-large${isOpenFacilityCard ? '-expanded' : ''}`}>
                                    <FacilityCard
                                        showInfo={isDefined(facilityCPE)}
                                        title={fillTitle()}
                                        subtitle={facilityCPE ?? ''}
                                        isOpen={isOpenFacilityCard}
                                        handleToggle={handleToggleFacilityCard}
                                        handleChangeAutocomplete={handleChangeAutocomplete}
                                        handlerSeeAll={handlerSeeAllFacilities}
                                        handlerOpenFacility={handlerGoToFacility}
                                        searchData={{
                                            list: facilitiesList,
                                            loader: isRequestingFacilities,
                                            history: facilitiesList,
                                        }}
                                        facilitiesList={facilitiesList}
                                        companyProfileId={companyProfileId}
                                        handlerCreateFacility={handlerCreateFacility}
                                        isClientSelected={isDefined(clientID)}
                                        handlerClearFacility={handlerClearFacility}
                                        isActiveViewEdit={isDefined(forwardFormFacility)}
                                        isThisPathActive={window.location.pathname === BASENAME_URL_FACILITY}
                                        userChannelIDGroup={userChannelIDGroup}
                                    />
                                </li>
                            }
                        </>
                    )}
                    <li
                        className={`menu-item${isOpenLangSwitcher ? '-expanded' : ''} h-100`}
                        onClick={() => handleToggleLangSwitcherCard()}
                    >
                        <LangSwitcherCard
                            isOpen={isOpenLangSwitcher}
                            handleToggle={handleToggleLangSwitcherCard}
                            handleSwitchLanguage={handleSwitchLanguage}
                            langCode={locale?.icon}
                            userLocale={userLocale}
                        />
                    </li>

                    {/*user*/}
                    <li className={`menu-item${isOpenUserInfo ? '-expanded' : ''}`} onClick={() => handleToggleUserCard()}>
                        <UserCard
                            isOpen={isOpenUserInfo}
                            handleToggle={handleToggleUserCard}
                            handleUserSignOut={handleUserSignOut}
                            userTypeID={userTypeID}
                            username={userUsername}
                            setDarkTheme={props.setDarkTheme}
                            handleGoTo={handlerGoToUser}
                        />
                    </li>
                </ul>
            </div>
        </StyledHeader>
    );
};

export default Header;
