import { lazy } from 'react';

// Services
import lazyLoader from 'services/lazyLoader';

// Actions

const CreateSiteSurveyProcessPage = lazy(() => lazyLoader(() => import('pages/Restricted/Processes/SiteSurvey/CreateSiteSurvey')));

const CreateSiteSurveyProcess = () => {
    const container = <CreateSiteSurveyProcessPage />;

    return container;
};

export default CreateSiteSurveyProcess;
