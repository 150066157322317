import { memo, useContext, useEffect } from 'react';
import IntlMessages from 'components/util/IntlMessages';
import {
    SPV_SIMPLE_QUESTIONS as questions,
    SPV_SIMPLE_INPUTS as INPUT_NAMES,
    NUMBER_PHASES_CONFIGS,
} from 'constants/products/solarpvSimple';
import ErrorMessage from 'components/errors/ErrorMessage';
import { Controller, useFormContext } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import ToggleButton from '@mui/material/ToggleButton';
import NumberFormat from 'react-number-format';
import CustomUnit from 'components/util/CustomUnit';
import { intlMessages, isFieldDefined, isNumber } from 'services/util/auxiliaryUtils';
import Tooltip from 'components/@efz/Tooltip';
import { SPVSimpleContext } from 'contexts/products/solarpvSimple/solarpvSimpleContext';
import { Helmet } from 'react-helmet';
import { getHeadTitle } from 'services/settings';
import { StyledToggleRadioLabel } from 'components/@efz/LabelRadio/StylesRadioLabel';

const ConsumptionDetails = () => {
    const {
        spvSimpleState: { inputs, options },
    } = useContext(SPVSimpleContext);

    const {
        control,
        watch,
        setValue,
        formState: { errors },
    } = useFormContext();

    const watchAllFields = watch(); // when pass nothing as argument, you are watching everything

    useEffect(() => {
        // set value (for safety)
        if (!isFieldDefined(watchAllFields?.[INPUT_NAMES.EQUIPMENTS])) setValue(INPUT_NAMES.EQUIPMENTS, options?.[INPUT_NAMES.EQUIPMENTS]);
    }, [inputs?.[INPUT_NAMES.EQUIPMENTS], watchAllFields?.[INPUT_NAMES.EQUIPMENTS]]); //eslint-disable-line

    return (
        <div className="consumption-details-container">
            <Helmet>
                <title>{`${getHeadTitle()} · ${intlMessages('page.spvSimple.open.b2c.step.5')}`}</title>
            </Helmet>
            <h2>
                <IntlMessages id={'page.spvSimple.consumptionDetails.title'} />
                <hr />
            </h2>
            <div className="consumption-details-form">
                {/* Number of phases */}
                <div className="input-container">
                    <label className="input-label">
                        <IntlMessages id={questions[INPUT_NAMES.PHASES_NUMBER_ID].label} />*
                        <Tooltip
                            title={<IntlMessages id={questions[INPUT_NAMES.PHASES_NUMBER_ID].tooltip} />}
                            style={{ textAlign: 'center' }}
                        />
                    </label>
                    <Controller
                        name={questions[INPUT_NAMES.PHASES_NUMBER_ID].name}
                        rules={{ required: 'yup.message.required' }}
                        control={control}
                        error={!!errors?.[INPUT_NAMES.PHASES_NUMBER_ID]}
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        render={({ field: { value, onChange, ref, ...field } }) => (
                            <StyledToggleRadioLabel
                                {...field}
                                value={value}
                                exclusive
                                onChange={(_, newValue) => onChange(newValue)}
                                aria-label="solarpv NUMBER_PHASES"
                                className={`radio-with-label`}
                            >
                                {NUMBER_PHASES_CONFIGS.map((el) => (
                                    <ToggleButton
                                        disableRipple
                                        disabled={watchAllFields?.[INPUT_NAMES.PHASES_NUMBER_ID] === el.id}
                                        key={`number-phases-${el.id}`}
                                        className={`number-phases-button ${watchAllFields?.[INPUT_NAMES.PHASES_NUMBER_ID] === el.id ? 'selected' : ''}`}
                                        value={el.id}
                                        aria-label={`${el.id} aligned`}
                                    >
                                        {el.label}
                                    </ToggleButton>
                                ))}
                            </StyledToggleRadioLabel>
                        )}
                    />
                    {!!errors?.[INPUT_NAMES.PHASES_NUMBER_ID] && <ErrorMessage error={errors[INPUT_NAMES.PHASES_NUMBER_ID]} />}
                </div>
                {/* Energy price */}
                <div className="input-container">
                    <label>
                        <IntlMessages id={questions[INPUT_NAMES.ENERGY_PRICE].label} />*
                        <Tooltip
                            title={<IntlMessages id={questions[INPUT_NAMES.ENERGY_PRICE].tooltip} values={{ vat: 6 }} />}
                            style={{ textAlign: 'center' }}
                        />
                    </label>
                    <Controller
                        name={questions[INPUT_NAMES.ENERGY_PRICE].name}
                        rules={{ required: 'yup.message.required' }}
                        control={control}
                        variant="outlined"
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        render={({ field: { ref, ...field } }) => (
                            <NumberFormat
                                {...field}
                                error={!!errors[INPUT_NAMES.ENERGY_PRICE]}
                                value={watchAllFields?.[INPUT_NAMES.ENERGY_PRICE]}
                                allowNegative={false}
                                placeholder={intlMessages(questions[INPUT_NAMES.ENERGY_PRICE].placeholder)}
                                customInput={OutlinedInput}
                                decimalScale={5}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <CustomUnit unit={questions[INPUT_NAMES.ENERGY_PRICE].unit} />
                                    </InputAdornment>
                                }
                            />
                        )}
                    />
                    {!!errors[INPUT_NAMES.ENERGY_PRICE] && <ErrorMessage error={errors[INPUT_NAMES.ENERGY_PRICE]} />}
                </div>
                {/* Annual consumption */}
                <div className="input-container">
                    <label>
                        <IntlMessages id={questions[INPUT_NAMES.ANNUAL_CONSUMPTION].label} />*
                        <Tooltip
                            title={
                                <IntlMessages
                                    id={questions[INPUT_NAMES.ANNUAL_CONSUMPTION].tooltip}
                                    values={{ unit: questions[INPUT_NAMES.ANNUAL_CONSUMPTION].unit }}
                                />
                            }
                            style={{ textAlign: 'center' }}
                        />
                    </label>
                    <Controller
                        name={questions[INPUT_NAMES.ANNUAL_CONSUMPTION].name}
                        rules={{
                            required: 'yup.message.required',
                            validate: (value) => {
                                if (isNumber(parseFloat(value)) && parseFloat(value) <= 0) return 'yup.message.number.largerThanZero';
                                return [/^-?\d*(\.\d+)?$/].every((pattern) => pattern.test(value)) || 'yup.message.number';
                            },
                        }}
                        control={control}
                        variant="outlined"
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        render={({ field: { ref, ...field } }) => (
                            <NumberFormat
                                {...field}
                                error={!!errors[INPUT_NAMES.ANNUAL_CONSUMPTION]}
                                value={watchAllFields?.[INPUT_NAMES.ANNUAL_CONSUMPTION]}
                                allowNegative={false}
                                placeholder={intlMessages(questions[INPUT_NAMES.ANNUAL_CONSUMPTION].placeholder)}
                                customInput={OutlinedInput}
                                decimalScale={2}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <CustomUnit unit={questions[INPUT_NAMES.ANNUAL_CONSUMPTION].unit} />
                                    </InputAdornment>
                                }
                            />
                        )}
                    />
                    {!!errors[INPUT_NAMES.ANNUAL_CONSUMPTION] && <ErrorMessage error={errors[INPUT_NAMES.ANNUAL_CONSUMPTION]} />}
                </div>
            </div>
        </div>
    );
};

export default memo(ConsumptionDetails);
