import { ReactComponent as BatteryIcon } from 'assets/@efz/icons/products/battery.svg';

import { BASENAME_URL_BACKOFFICE } from '../settings';
import { efzProductIcon } from 'services/@efz/icons';
import { PRODUCT_IDS } from 'constants/products';

export const BACKOFFICE_SECTIONS = {
    SOLAR: 'solar',
    EVC: 'evc',
    BATTERIES: 'batteries',
    HEATPUMPS: 'heatpumps',
} as const;

export const BACKOFFICE_PRODUCTS = {
    SPV: 'pv',
    EVC: 'me',
    BATTERIES: 'batteries',
    HP: 'hp',
} as const;

export const COMMON_SECTIONS = {
    TEMPLATES: 'templates',
};

export const SOLAR_SECTIONS = {
    COMPONENTS: 'components',
    COSTS: 'costs',
    TECH_CONFIGS: 'tech_configs',
    BUSINESS_CONFIGS: 'business_configs',
    BRANDS: 'brands',
    OFFERING: 'offering',
    ...COMMON_SECTIONS,
};

export const HEATPUMPS_SECTIONS = {
    COMPONENTS: 'components',
    BRANDS: 'brands',
    INSTALATION: 'instalation',
    FAST_MODULE: 'fast-module',
    ...COMMON_SECTIONS,
};

export const SOLAR_COMPONENTS = {
    PANELS: 'panels',
    INVERTERS: 'inverters',
    STRUCTURES: 'structures',
};

export const SOLAR_BRANDS_COMPONENTS = {
    PANELS: 'panels',
    INVERTERS: 'inverters',
};

export const SOLAR_BRANDS_COMPONENTS_CONFIGS = [
    {
        name: SOLAR_BRANDS_COMPONENTS.PANELS,
        label: 'page.backoffice.solar.brands.panels',
        visible: true,
    },
    {
        name: SOLAR_BRANDS_COMPONENTS.INVERTERS,
        label: 'page.backoffice.solar.brands.inverters',
        visible: true,
    },
];

export const SOLAR_COMPONENTS_TABS = {
    PANELS: 'panels',
    INVERTERS: 'inverters',
    MODULE_RACKINGS: 'moduleRackings',
};

export const SOLAR_COMPONENTS_TABS_CONFIGS = [
    {
        name: SOLAR_COMPONENTS_TABS.PANELS,
        label: 'page.backoffice.solar.components.panels',
        visible: true,
    },
    {
        name: SOLAR_COMPONENTS_TABS.INVERTERS,
        label: 'page.backoffice.solar.components.inverters',
        visible: true,
    },
    /* {
		name: SOLAR_COMPONENTS_TABS.MODULE_RACKINGS,
		label: 'page.backoffice.solar.components.moduleRackings',
		visible: true
	} */
];

export const SOLAR_COMPONENTS_CONFIGS = [
    {
        name: SOLAR_COMPONENTS.PANELS,
        label: 'page.backoffice.solar.components.panels',
    },
    {
        name: SOLAR_COMPONENTS.INVERTERS,
        label: 'page.backoffice.solar.components.inverters',
    },
    {
        name: SOLAR_COMPONENTS.STRUCTURES,
        label: 'page.backoffice.solar.components.structures',
    },
];

export const HEATPUMPS_COMPONENTS = {
    HEATPUMPS: 'heatpumps',
    EMITERS: 'emitters',
    EXTRAS: 'extras',
};

export const HEATPUMPS_COMPONENTS_CONFIGS = [
    {
        name: HEATPUMPS_COMPONENTS.HEATPUMPS,
        label: 'page.backoffice.heatpumps.components.heatpumps',
        visible: true,
    },
    {
        name: HEATPUMPS_COMPONENTS.EMITERS,
        label: 'page.backoffice.heatpumps.components.emmiters',
        visible: true,
    },
    {
        name: HEATPUMPS_COMPONENTS.EXTRAS,
        label: 'page.backoffice.heatpumps.components.extras',
        visible: true,
    },
];

export const HEATPUMPS_FAST_MODULE_TABS = {
    QUESTIONS: 'questions',
    COMBINATIONS: 'combinations',
};

export const HEATPUMPS_FAST_MODULE_TABS_CONFIGS = [
    {
        name: HEATPUMPS_FAST_MODULE_TABS.QUESTIONS,
        label: 'page.backoffice.heatpumps.fastModule.questions',
        visible: true,
    },
    {
        name: HEATPUMPS_FAST_MODULE_TABS.COMBINATIONS,
        label: 'page.backoffice.heatpumps.fastModule.combinations',
        visible: true,
    },
];

const BACKOFFICE_COMMON_SECTIONS = (section) => [
    {
        title: 'templates',
        name: `${section}_templates`,
        label: 'page.backoffice.heatpumps.templates',
        navigationLabel: `${BASENAME_URL_BACKOFFICE}/${section}/${[HEATPUMPS_SECTIONS.TEMPLATES]}`,
        visible: function (bdFlag) {
            return bdFlag;
        },
    },
];

export const BACKOFFICE_CONFIGS = {
    DEFAULT: [
        {
            title: BACKOFFICE_PRODUCTS.SPV,
            name: BACKOFFICE_SECTIONS.SOLAR,
            label: 'page.spvPro.label.solarPV',
            visible: function (bdFlag) {
                return bdFlag;
            },
            visibleFlag: 'mostra_backoffice_pv',
            icon: function ({ disabled = false, selected = false }) {
                return efzProductIcon(PRODUCT_IDS.SPV, selected, disabled);
            },
            disabled: function () {
                return false;
            },
            sections: [
                {
                    title: 'components',
                    name: `${[BACKOFFICE_SECTIONS.SOLAR]}_components`,
                    label: 'page.backoffice.solar.components',
                    navigationLabel: `${BASENAME_URL_BACKOFFICE}/${[BACKOFFICE_SECTIONS.SOLAR]}/${[SOLAR_SECTIONS.COMPONENTS]}`,
                    visible: function (bdFlag) {
                        return bdFlag;
                    },
                },
                {
                    title: 'brands',
                    name: `${[BACKOFFICE_SECTIONS.SOLAR]}_brands`,
                    label: 'page.backoffice.solar.brands',
                    navigationLabel: `${BASENAME_URL_BACKOFFICE}/${[BACKOFFICE_SECTIONS.SOLAR]}/${[SOLAR_SECTIONS.BRANDS]}`,
                    visible: function (bdFlag) {
                        return bdFlag;
                    },
                },
                {
                    title: 'offering',
                    name: `${[BACKOFFICE_SECTIONS.SOLAR]}_offering`,
                    label: 'page.backoffice.solar.offering',
                    navigationLabel: `${BASENAME_URL_BACKOFFICE}/${[BACKOFFICE_SECTIONS.SOLAR]}/${[SOLAR_SECTIONS.OFFERING]}`,
                    visible: function (bdFlag) {
                        return bdFlag;
                    },
                },
                /* {
					title: 'costs',
					name: `${[BACKOFFICE_SECTIONS.SOLAR]}_costs`,
					label: 'page.backoffice.solar.costs',
					navigationLabel: `${BASENAME_URL_BACKOFFICE}/${[BACKOFFICE_SECTIONS.SOLAR]}/${[SOLAR_SECTIONS.COSTS]}`,
					visible: function (bdFlag) {
						return bdFlag;
					},
				}, */
                // {
                //     name: `${[BACKOFFICE_SECTIONS.SOLAR]}_tech_configs`,
                //     label: 'page.backoffice.solar.techConfigs',
                //     navigationLabel: `${BASENAME_URL_BACKOFFICE}/${[BACKOFFICE_SECTIONS.SOLAR]}/${[SOLAR_SECTIONS.TECH_CONFIGS]}`
                // },
                // {
                //     name: `${[BACKOFFICE_SECTIONS.SOLAR]}_business_configs`,
                //     label: 'page.backoffice.solar.businessConfigs',
                //     navigationLabel: `${BASENAME_URL_BACKOFFICE}/${[BACKOFFICE_SECTIONS.SOLAR]}/${[SOLAR_SECTIONS.BUSINESS_CONFIGS]}`
                // },
                ...BACKOFFICE_COMMON_SECTIONS(BACKOFFICE_SECTIONS.SOLAR),
            ],
        },
        {
            title: BACKOFFICE_PRODUCTS.EVC,
            name: BACKOFFICE_SECTIONS.EVC,
            label: 'product.name.ELECTRIC_MOBILITY',
            visible: function (bdFlag) {
                return bdFlag;
            },
            visibleFlag: 'mostra_backoffice_mobilidade',
            icon: function ({ disabled = true, selected = false }) {
                return efzProductIcon(PRODUCT_IDS.EVC, selected, disabled);
            },
            disabled: function () {
                return true;
            },
        },
        {
            title: BACKOFFICE_PRODUCTS.BATTERIES,
            name: BACKOFFICE_SECTIONS.BATTERIES,
            label: 'label.batteries',
            visible: function (bdFlag) {
                return bdFlag;
            },
            visibleFlag: 'mostra_backoffice_baterias',
            icon: function ({ disabled = true, selected = false }) {
                return <BatteryIcon className={`efz-battery${selected ? '-selected' : ''}${disabled ? '-disabled' : ''}`} />;
            },
            disabled: function () {
                return true;
            },
        },
        {
            title: BACKOFFICE_PRODUCTS.HP,
            name: BACKOFFICE_SECTIONS.HEATPUMPS,
            label: 'page.backoffice.heatpumps',
            visible: function (bdFlag) {
                return bdFlag;
            },
            visibleFlag: 'mostra_backoffice_hp',
            icon: function ({ disabled = false, selected = false }) {
                return efzProductIcon(PRODUCT_IDS.HP, selected, disabled);
            },
            disabled: function () {
                return false;
            },
            sections: [
                {
                    title: 'components',
                    name: `${[BACKOFFICE_SECTIONS.HEATPUMPS]}_components`,
                    label: 'page.backoffice.heatpumps.components',
                    visibleFlag: 'show_components_menu',
                    navigationLabel: `${BASENAME_URL_BACKOFFICE}/${[BACKOFFICE_SECTIONS.HEATPUMPS]}/${[HEATPUMPS_SECTIONS.COMPONENTS]}`,
                    visible: function (bdFlag) {
                        return bdFlag;
                    },
                },
                {
                    title: 'brands',
                    name: `${[BACKOFFICE_SECTIONS.HEATPUMPS]}_brands`,
                    label: 'page.backoffice.heatpumps.brands',
                    navigationLabel: `${BASENAME_URL_BACKOFFICE}/${[BACKOFFICE_SECTIONS.HEATPUMPS]}/${[HEATPUMPS_SECTIONS.BRANDS]}`,
                    visible: function (bdFlag) {
                        return bdFlag;
                    },
                },
                {
                    title: 'installation',
                    name: `${[BACKOFFICE_SECTIONS.HEATPUMPS]}_instalation`,
                    label: 'page.backoffice.heatpumps.instalation',
                    navigationLabel: `${BASENAME_URL_BACKOFFICE}/${[BACKOFFICE_SECTIONS.HEATPUMPS]}/${[HEATPUMPS_SECTIONS.INSTALATION]}`,
                    visible: function (bdFlag) {
                        return bdFlag;
                    },
                },
                {
                    title: 'fast_module',
                    name: `${[BACKOFFICE_SECTIONS.HEATPUMPS]}_fast_module`,
                    label: 'page.backoffice.heatpumps.fastModule',
                    navigationLabel: `${BASENAME_URL_BACKOFFICE}/${[BACKOFFICE_SECTIONS.HEATPUMPS]}/${[HEATPUMPS_SECTIONS.FAST_MODULE]}`,
                    visible: function (bdFlag) {
                        return bdFlag;
                    },
                },
                ...BACKOFFICE_COMMON_SECTIONS(BACKOFFICE_SECTIONS.HEATPUMPS),
            ],
        },
    ],
};

export const AVAILABLE_BO_MENU_STATES_DESCRIPTION = {
    save: 'Saved',
    preview: 'Preview',
    publish: 'Published',
} as const;
