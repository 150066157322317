import Dialog from '@mui/material/Dialog';
import { themeBase } from 'constants/styles/global';
import { styled } from 'styled-components';

export const StyledHousingTypeDialog = styled(Dialog)`
    .MuiDialog-paperWidthSm {
        max-width: calc(560px + 4rem);
        padding: 1.5rem;
    }

    .dialog-title {
        display: flex;
        flex-direction: column;

        .MuiDialogTitle-root {
            display: flex;
            justify-content: flex-start;
            padding: 0;
            font-size: 0.875rem;
            font-weight: ${themeBase.fontWeight.semibold};
            color: var(--primary-color);
        }

        hr {
            position: relative;
            margin-top: 0.5rem;
            margin-bottom: 1rem;
            height: 1px;
            background-color: ${themeBase.color.info200};
        }

        .close-button {
            position: absolute;
            top: 0;
            right: 0;
            margin-right: 0.75rem;
            margin-top: 0.75rem;

            svg {
                width: 20px;
                height: 20px;

                path {
                    fill: var(--primary-color);
                }
            }
        }
    }

    .dialog-content {
        padding: 0 !important;
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;

        section {
            display: flex;
            flex-direction: column;
            row-gap: 0.5rem;

            .subtitle {
                font-size: 0.875rem;
                font-weight: 500;
                line-height: 1.5rem;
            }
        }

        .MuiDialogContentText-root {
            font-size: 0.875rem;
            line-height: 1.5rem;
        }
    }
`;
