import { themeBase } from 'constants/styles/global';
import styled from 'styled-components/macro';
import { media } from 'styles/utils/mediaQueries';

export const StyledFooter = styled.footer`
    min-height: ${themeBase.footerHeight};
    color: ${themeBase.lightGray};
    font-size: 0.813rem;
    position: relative;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 1;

    display: flex;
    align-items: center;

    ${media.tablet`
        min-height: ${themeBase.footerHeightLg};
        font-size: 0.7rem;
        padding: 0.5rem 0;

        .container {
            flex-direction: column-reverse;
            row-gap: 0.5rem;
        }   
        
        `}

    .footer-policy-links {
        position: absolute;
        right: 0;
        margin-right: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 30%;

        ${media.tablet`
            
            position: relative;
            margin: 0;
            max-width: 100%;
        `}

        span {
            text-decoration: underline;
            margin-right: 1rem;
            cursor: pointer;
        }
    }
`;
