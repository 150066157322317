// This is an adaptation of the official DragControls.js file from
// the THREE.js library. It was necessary to perform an adaptation
// since we are working with the ThreeJSOverlay library, and some
// events (like raycasting) were not detected correctly.

import { isFieldDefined } from 'services/util/auxiliaryUtils';
import { EventDispatcher, Matrix4, Plane, Raycaster, Vector2, Vector3 } from 'three';
import { forceMapRender } from './map';

const _plane = new Plane();
const _raycaster = new Raycaster();

const _pointer = new Vector2();
const _offset = new Vector3();
const _intersection = new Vector3();
const _worldPosition = new Vector3();
const _inverseMatrix = new Matrix4();

const _startPosition = new Vector3();
const _translation = new Vector3();

class DragControls extends EventDispatcher {
    constructor(
        _objects,
        _camera,
        _domElement,
        {
            overlay,
            polygons,
            opacity,
            isDuplicateBehavior = false,
            canDuplicate = false,
            startDragUseAsStamp = false,
            isExclusionControl = false,
        }
    ) {
        super();

        _domElement.style.touchAction = 'none'; // disable touch scroll

        let _selected = null,
            _hovered = null;

        let _isCtrlPressed = false,
            _tempMesh = null;

        let _intersections = [];

        let _initialMousePosition;

        //

        const ref = document.getElementById('solarpv-wrapper');

        //eslint-disable-next-line
        const scope = this;

        function activate() {
            _domElement.addEventListener('pointermove', onPointerMove);
            !canDuplicate && _domElement.addEventListener('pointerdown', onPointerDown);
            _domElement.addEventListener('pointerup', onPointerCancel);
            _domElement.addEventListener('pointerleave', onPointerCancel);
            !isDuplicateBehavior && canDuplicate && ref.addEventListener('keydown', onKeyDown);
            !isDuplicateBehavior && canDuplicate && ref.addEventListener('keyup', onKeyUp);
            _domElement.addEventListener('mouseup', onMouseUp);
        }

        function deactivate() {
            _domElement.removeEventListener('pointermove', onPointerMove);
            !canDuplicate && _domElement.removeEventListener('pointerdown', onPointerDown);
            _domElement.removeEventListener('pointerup', onPointerCancel);
            _domElement.removeEventListener('pointerleave', onPointerCancel);
            !isDuplicateBehavior && canDuplicate && ref.removeEventListener('keydown', onKeyDown);
            !isDuplicateBehavior && canDuplicate && ref.removeEventListener('keyup', onKeyUp);
            !isDuplicateBehavior && canDuplicate && ref.addEventListener('keyup', onKeyUp);
            _domElement.removeEventListener('mouseup', onMouseUp);

            _domElement.style.cursor = '';
        }

        function dispose() {
            deactivate();
        }

        function getObjects() {
            return _objects;
        }

        function getRaycaster() {
            return _raycaster;
        }

        function onPointerMove(event) {
            if (scope.enabled === false) return;

            updatePointer(event);

            setRayOriginAndDirection();

            if (_selected) {
                if (overlay.raycaster.ray.intersectPlane(_plane, _intersection)) {
                    (!_tempMesh || isDuplicateBehavior) && overlay.overlay.map.setOptions({ gestureHandling: 'none' });
                    _selected.position.copy(_intersection.sub(_offset).applyMatrix4(_inverseMatrix));
                }

                scope.dispatchEvent({ type: 'drag', object: _selected });

                return;
            }

            // hover support

            if (event.pointerType === 'mouse' || event.pointerType === 'pen') {
                _intersections.length = 0;

                setRayOriginAndDirection();

                _intersections = overlay.raycast(_pointer, _objects)?.filter((i) => i?.object?.isMesh);

                if (_intersections.length > 0) {
                    const object = _intersections[0].object;

                    _plane.setFromNormalAndCoplanarPoint(
                        _camera.getWorldDirection(_plane.normal),
                        _worldPosition.setFromMatrixPosition(object.matrixWorld)
                    );

                    if (_hovered !== object && _hovered !== null) {
                        scope.dispatchEvent({ type: 'hoveroff', object: _hovered });

                        _domElement.style.cursor = 'auto';
                        _hovered = null;
                    }

                    if (_hovered !== object) {
                        scope.dispatchEvent({ type: 'hoveron', object: object });

                        _domElement.style.cursor = 'pointer';
                        _hovered = object;
                    }
                } else {
                    if (_hovered !== null) {
                        scope.dispatchEvent({ type: 'hoveroff', object: _hovered });

                        _domElement.style.cursor = 'auto';
                        _hovered = null;
                    }
                }
            }
        }

        function onPointerDown(event) {
            if (scope.enabled === false || event.button === 2) return;

            updatePointer(event);

            _intersections.length = 0;

            setRayOriginAndDirection();

            _initialMousePosition = { x: parseInt(event?.clientX), y: parseInt(event?.clientY) };

            _intersections = overlay
                .raycast(_pointer, _objects, {
                    recursive: scope.recursive,
                    raycasterParameters: {
                        Mesh: {},
                        Line: { threshold: 2 },
                        LOD: {},
                        Points: { threshold: 1 },
                        Sprite: {},
                    },
                })
                .filter((i) => i?.object?.isMesh || i?.object?.isLine || i?.object?.isPoints);

            if (_intersections?.find((i) => i?.object?.isPoints)) return;
            const meshIntersections = _intersections.map((i) => (i?.object?.isMesh ? i?.object : i?.object?.parent));

            if (_intersections.length > 0) {
                _selected = scope.transformGroup === true ? _objects[0] : meshIntersections[0];
                _plane.setFromNormalAndCoplanarPoint(
                    _camera.getWorldDirection(_plane.normal),
                    _worldPosition.setFromMatrixPosition(_selected.matrixWorld)
                );
                _startPosition.copy(_selected.position);

                isDuplicateBehavior && addTempMesh();

                if (overlay.raycaster.ray.intersectPlane(_plane, _intersection)) {
                    _inverseMatrix.copy(_selected.parent.matrixWorld).invert();
                    _offset.copy(_intersection).sub(_worldPosition.setFromMatrixPosition(_selected.matrixWorld));
                }

                _domElement.style.cursor = 'move';

                scope.dispatchEvent({ type: 'dragstart', object: _selected });
            }
        }

        function onMouseUp(event) {
            if (scope.enabled === false) return;

            if (startDragUseAsStamp && isFieldDefined(_selected?.position)) {
                // Get the current mouse position.
                // Using parseInt to avoid minimal mouse mouves (with decimals) as we are doing a comparison betweeen mouseDown and mouseUp
                const currentMousePosition = { x: parseInt(event?.clientX), y: parseInt(event?.clientY) };

                // Check if the mouse has moved
                const hasMouseMoved =
                    _initialMousePosition?.x !== currentMousePosition?.x || _initialMousePosition?.y !== currentMousePosition?.y;

                if (hasMouseMoved) {
                    forceMapRender();
                    //return to allow map drag
                    return;
                }

                if (!hasMouseMoved) {
                    _translation.subVectors(_selected?.position, _startPosition);

                    // get currentPoints coordinates
                    const currentPoints = polygons
                        .find((p) => p.id === parseInt(_objects?.[0]?.name))
                        .getPaths()
                        .map((point) => {
                            const newPos = {
                                lat: point.lat(),
                                lng: point.lng(),
                                altitude: 0,
                            };
                            return overlay.latLngAltitudeToVector3(newPos);
                        });

                    // update currentPoints coordinates
                    const newPoints = [];
                    currentPoints.forEach((curr) => {
                        const point = new Vector3().copy(curr);
                        point.add(_translation);
                        newPoints.push(point);
                    });

                    if (!isExclusionControl) {
                        overlay.scene.remove(...overlay.scene.getObjectsByProperty('name', 'tempMesh'));

                        _tempMesh = null;

                        _selected = null;
                    }

                    scope.dispatchEvent({ type: 'dragend', object: _selected, tempMeshPoints: newPoints });
                    return;
                }
            }
        }

        function onPointerCancel() {
            if (scope.enabled === false) return;

            if (_selected && !startDragUseAsStamp) {
                _translation.subVectors(_selected?.position, _startPosition);

                // get currentPoints coordinates
                const currentPoints = polygons
                    .find((p) => p.id === parseInt(_objects[0].name))
                    .getPaths()
                    .map((point) => {
                        const newPos = {
                            lat: point.lat(),
                            lng: point.lng(),
                            altitude: 0,
                        };
                        return overlay.latLngAltitudeToVector3(newPos);
                    });

                // update currentPoints coordinates
                const newPoints = [];
                currentPoints.forEach((curr) => {
                    const point = new Vector3().copy(curr);
                    point.add(_translation);
                    newPoints.push(point);
                });

                overlay.overlay.map.setOptions({ gestureHandling: 'auto' });

                scope.dispatchEvent({
                    type: !_tempMesh ? 'dragend' : 'advancedDuplication',
                    object: _selected,
                    points: newPoints,
                    hasMoved: !(_translation.x === 0 && _translation.y === 0),
                });

                _selected = null;

                if (_tempMesh) {
                    if (!isDuplicateBehavior) {
                        _tempMesh.visible = false;
                    } else {
                        overlay.scene.remove(...overlay.scene.getObjectsByProperty('name', 'tempMesh'));
                        _tempMesh = null;
                    }
                }
            }

            _domElement.style.cursor = _hovered ? 'pointer' : 'auto';
        }

        function startUseAsStamp() {
            if (scope.enabled === false) return;

            if (_objects.length > 0) {
                if (!_tempMesh) {
                    _tempMesh = _objects[0].clone();
                    _tempMesh.material = _tempMesh.material.clone();
                    _tempMesh.name = 'tempMesh';
                    _tempMesh.getObjectsByProperty('name', 'point').forEach((mp) => (mp.visible = false));
                    _tempMesh.getObjectsByProperty('name', 'middlePoint').forEach((mp) => (mp.visible = false));
                    _tempMesh.material.opacity = opacity;
                } else {
                    _tempMesh.visible = true;
                }

                _startPosition.copy(_objects[0].position);

                overlay.scene.add(_tempMesh);

                _selected = _tempMesh;

                _plane.setFromNormalAndCoplanarPoint(
                    _camera.getWorldDirection(_plane.normal),
                    _worldPosition.setFromMatrixPosition(_selected.matrixWorld)
                );
            }
        }

        function onKeyDown(event) {
            if (scope.enabled === false) return;

            _isCtrlPressed = event.ctrlKey || event.keyCode === 17;

            if (_objects.length > 0 && _isCtrlPressed) {
                if (!_tempMesh) {
                    _tempMesh = _objects[0].clone();
                    _tempMesh.material = _tempMesh.material.clone();
                    _tempMesh.name = 'tempMesh';
                    _tempMesh.getObjectsByProperty('name', 'point').forEach((mp) => (mp.visible = false));
                    _tempMesh.getObjectsByProperty('name', 'middlePoint').forEach((mp) => (mp.visible = false));
                    _tempMesh.material.opacity = opacity;
                } else {
                    _tempMesh.visible = true;
                }

                _startPosition.copy(_objects[0].position);

                overlay.scene.add(_tempMesh);

                _selected = _tempMesh;

                scope.dispatchEvent({ type: 'keydown', object: _selected });
            }
        }

        function onKeyUp() {
            if (scope.enabled === false) return;

            _isCtrlPressed = false;

            overlay.scene.remove(...overlay.scene.getObjectsByProperty('name', 'tempMesh'));

            _tempMesh = null;

            _selected = null;

            overlay.overlay.map.setOptions({ gestureHandling: 'auto' });

            scope.dispatchEvent({ type: 'keyup' });
        }

        function setRayOriginAndDirection() {
            overlay.raycaster.ray.origin.set(_pointer.x, _pointer.y, 0).applyMatrix4(overlay.projectionMatrixInverse);

            overlay.raycaster.ray.direction
                .set(_pointer.x, _pointer.y, 0.5)
                .applyMatrix4(overlay.projectionMatrixInverse)
                .sub(overlay.raycaster.ray.origin)
                .normalize();
        }

        function updatePointer(event) {
            const rect = _domElement.getBoundingClientRect();

            _pointer.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
            _pointer.y = (-(event.clientY - rect.top) / rect.height) * 2 + 1;
        }

        function addTempMesh() {
            if (scope.enabled === false) return;

            if (!isDuplicateBehavior) return;

            if (_objects.length > 0 && !_tempMesh) {
                _objects[0].getObjectsByProperty('name', 'point').forEach((mp) => (mp.visible = false));
                _objects[0].getObjectsByProperty('name', 'middlePoint').forEach((mp) => (mp.visible = false));
                _tempMesh = _objects[0].clone();
                _tempMesh.traverse((child) => {
                    if (child.isMesh) child.material = child.material.clone();
                });
                _tempMesh.name = 'tempMesh';
                _tempMesh.traverse((child) => {
                    if (child.isMesh) child.material.opacity = opacity;
                });

                overlay.scene.add(_tempMesh);

                _plane.setFromNormalAndCoplanarPoint(
                    _camera.getWorldDirection(_plane.normal),
                    _worldPosition.setFromMatrixPosition(_selected.matrixWorld)
                );
            }
        }

        activate();

        if (startDragUseAsStamp) startUseAsStamp();

        // API

        this.enabled = true;
        this.recursive = true;
        this.transformGroup = false;

        this.activate = activate;
        this.deactivate = deactivate;
        this.dispose = dispose;
        this.getObjects = getObjects;
        this.getRaycaster = getRaycaster;
    }
}

export { DragControls };
