import { BUSINESS_MODEL_NON_ELIGIBLE_REASONS } from './../../constants/businessModels';
import { BUSINESS_MODEL_ELIGIBLE } from 'constants/businessModels';
//import { USERS_TYPE_ID } from 'constants/user';
import { PAYMENT_MODELS_IDS } from 'constants/businessModels';
import { Nullable } from './../../types/utils';
import { TSPV } from 'interfaces/products/solarpv';
import { isDefined, isFieldDefined } from 'services/util/auxiliaryUtils';
import { PRODUCT_IDS } from 'constants/products';
import { TInputs, TProductInputs } from 'interfaces/businessModels';
import { SEND_AAS_PRICING_IN_BM, SEND_CHARGERS_RANGES_IN_BM } from 'constants/products/evc';
import { getInputsPartnerShip } from 'services/products/evc';
import copy from 'fast-copy';
import { getCompanyProfileIds } from 'services/user';
// export const EVCWithManagementOption = (managementOption:number) => managementOption !== 99;
export const EVCWithManagementOption = {
    visible: (managementOption: number) => managementOption !== 99,
    tag: (companyProfileId: number) =>
        [getCompanyProfileIds().EDP_PT, getCompanyProfileIds().EFZ].includes(companyProfileId) ?
            getCompanyProfileIds().EDP_PT
        :   companyProfileId,
};

export const fetchBMPayload = (
    productID: number,
    bm: TProductInputs,
    configs: {
        newTotalCost?: Nullable<number>;
        companyProfileId: number;
        isReformulation?: boolean;
    }
) => {
    let payload;
    const equipments_cost = bm?.equipments_cost ?? undefined;
    const services_cost = bm.services_cost ?? undefined;

    switch (productID) {
        case PRODUCT_IDS.EVC:
            payload = {
                target_investment: bm?.target_investment ?? undefined,
                cost: !isDefined(bm?.target_investment) && isDefined(bm?.cost) ? bm?.cost : undefined,
                equipments_cost,
                services_cost,
                facility_id: configs.isReformulation ? bm?.reformulation?.facility_id : bm.facility_id,
                entrance_type_id: bm?.entrance_type_id,
                management_option_id: bm.management_option_id,
                aas_pricing: SEND_AAS_PRICING_IN_BM[configs.companyProfileId] ? bm.aas_pricing : undefined,
                ranges: SEND_CHARGERS_RANGES_IN_BM[configs.companyProfileId] ? bm.ranges : undefined,
                required_power: bm?.required_power ?? undefined,
                total_charger_power: bm?.total_charger_power ?? undefined,
                total_charger_price: bm?.total_charger_price ?? undefined,
                total_maintenance_price_year: bm?.total_maintenance_price_year ?? undefined,
                total_platform_price_month: bm?.total_platform_price_month ?? undefined,
                total_project_price: bm?.total_project_price ?? undefined,
                total_upfront_price: bm?.total_upfront_price ?? undefined,
                chargers_total_power: bm?.chargers_total_power ?? undefined,
                contracted_power: bm?.contracted_power ?? undefined,
                new_contracted_power: bm?.new_contracted_power ?? undefined,
                is_fast_module: false,
                ...getInputsPartnerShip(bm),
            };

            break;
        case PRODUCT_IDS.SPV: {
            const solarBM: TInputs<TSPV> = bm;
            const cost = configs?.newTotalCost ?? solarBM.cost;
            const spvPayload: {
                annual_saving: number;
                cost?: number;
                equipments_cost: number;
                facility_id: string;
                panel_id: Nullable<number>;
                peak_power: number;
                range_id: number;
                service_cost: Nullable<number>;
                services_cost: number;
                has_lecs: Nullable<boolean>;
                installation_fixed_prices?: number;
                installation_labor_prices?: number;
                nominal_power?: number;
                network_sale?: boolean;
            } = {
                annual_saving: solarBM.annual_saving,
                cost,
                equipments_cost: solarBM.equipments_cost,
                // @ts-ignore
                facility_id: solarBM.facility_id,
                panel_id: solarBM.panel_id,
                peak_power: solarBM?.peak_power,
                range_id: solarBM.range_id,
                service_cost: solarBM.service_cost,
                services_cost: bm.services_cost,
                has_lecs: bm.has_lecs,
                nominal_power: bm?.nominal_power,
                network_sale: bm?.network_sale,
                lec_aux_kpis_arr: solarBM?.productInputs?.kpis?.local_energy_community?.has_lec_business_model_conditions ? undefined : [],
            };
            return spvPayload;
        }
        case PRODUCT_IDS.SPVSB: {
            let lecData = {};
            if (isDefined(bm.lecData?.lec_annual_saving) && isDefined(bm.lecData?.lec_energy_tariff)) {
                lecData = {
                    lec_annual_saving: bm.lecData?.lec_annual_saving,
                    lec_energy_tariff: bm.lecData?.lec_energy_tariff,
                };
            }
            const payload = {
                facility_id: bm.facility_id,
                annual_saving: bm.annual_saving,
                module_type: 'SIMPLES',
                cost: bm.cost,
                service_cost: bm.service_cost,
                range_id: bm.range_id,
                panel_id: bm.inputs?.panel_id,
                default_range_cost: bm.default_range_cost,
                peak_power: bm?.peak_power,
                panels_number: bm.panels_number,
                ...lecData,
                battery: bm.inputs?.battery ?? undefined,
            };
            return payload;
        }
        case PRODUCT_IDS.SPV_SIMPLE: {
            const solarSimpleInputs = bm?.productInputs;
            return {
                annual_saving: solarSimpleInputs.annual_saving,
                target_investment: solarSimpleInputs.target_investment,
                facility_id: solarSimpleInputs?.facility_id,
                panel_id: solarSimpleInputs?.panel_id,
                range_id: solarSimpleInputs.range_id,
                peak_power: solarSimpleInputs?.contractedPower,
            };
        }
        case PRODUCT_IDS.HP: {
            const hpInputs = bm?.productInputs;
            return {
                facility_id: hpInputs?.facility_id,
                module_type: hpInputs?.module_type,
                //@ts-ignore
                annual_saving: hpInputs?.simulation?.financial?.annual_savings,
                //@ts-ignore
                cost: configs?.newTotalCost ?? hpInputs?.simulation?.financial?.cost?.total_cost,
                // HP installation Prices
                //@ts-ignore
                installation_fixed_price: bm?.installation_fixed_price ?? undefined,
                //@ts-ignore
                installation_labor_price: bm?.installation_labor_price ?? undefined,
            };
        }
        case PRODUCT_IDS.CFP: {
            const has_offer_edition = isDefined(bm?.offerEdition);
            payload = {
                annual_saving: bm.annual_saving,
                target_investment: bm.target_investment,
                cost: configs?.newTotalCost ?? bm.cost,
                facility_id: bm.facility_id,
                module_type: 'SIMPLES',
            };

            if (has_offer_edition) {
                payload.body.cost = bm?.offerEdition?.cost?.base;
                delete payload.body.target_investment;
            }
            break;
        }
        default:
            break;
    }

    return payload;
};

export const simulationCost = (productID, simulation, showCapex = false) => {
    switch (productID) {
        case PRODUCT_IDS.SPV:
        case PRODUCT_IDS.SPV_SIMPLE:
            return showCapex ? simulation?.kpis?.capex : simulation?.kpis?.cost;

        case PRODUCT_IDS.HP:
            return simulation?.simulation?.financial?.cost?.total_cost;
        case PRODUCT_IDS.CFP:
            return simulation?.offerEdition?.cost?.base;
        default:
            break;
    }
};

export const offerCost = (productID, simulation) => {
    switch (productID) {
        case PRODUCT_IDS.SPV:
        case PRODUCT_IDS.SPV_SIMPLE:
        case PRODUCT_IDS.SPVSB:
            return simulation.kpis?.offer_edition?.cost;
        case PRODUCT_IDS.CFP:
            return simulation?.offerEdition?.cost;
        default:
            break;
    }
};

export const businessModelsMapped = [
    Number(PAYMENT_MODELS_IDS.UP_FRONT),
    Number(PAYMENT_MODELS_IDS.INSTALLMENTS),
    Number(PAYMENT_MODELS_IDS.SERVICE),
    Number(PAYMENT_MODELS_IDS.SERVICE_PPA),
    Number(PAYMENT_MODELS_IDS.PPA),
    Number(PAYMENT_MODELS_IDS.PAY_AS_PRODUCED),
    Number(PAYMENT_MODELS_IDS.EASY_SOLAR),
    Number(PAYMENT_MODELS_IDS.LEC),
];

/**
 *
 * Ativar hasBmodelsPro
 */
export const businessModelProAccess = ({ companyProfileId, productID }) => {
    const companyProfilesWithBMpro = [
        getCompanyProfileIds().PROFILE_BASE_SWISS,
        getCompanyProfileIds().PPC,
        getCompanyProfileIds().NRG,
        getCompanyProfileIds().PROFILE_BASE_UK,
        getCompanyProfileIds().EVOLO,
        getCompanyProfileIds().PROFILE_BASE_USA,
        getCompanyProfileIds().PROFILE_BASE_DE,
        getCompanyProfileIds().PROFILE_EDP_FR,
        getCompanyProfileIds().PROFILE_BASE_IT,
        getCompanyProfileIds().EDP_ES,
        getCompanyProfileIds().ROMANDE,
        getCompanyProfileIds().SUNSEAP,
        getCompanyProfileIds().EFZ,
        getCompanyProfileIds().EDP_IT,
        getCompanyProfileIds().EDP_PT,
        getCompanyProfileIds().EDP_PL,
    ];

    const productsWithBMPro: (typeof PRODUCT_IDS)[keyof typeof PRODUCT_IDS][] = [PRODUCT_IDS.SPV, PRODUCT_IDS.SPVSB, PRODUCT_IDS.CFP];
    return productsWithBMPro.includes(productID) && companyProfilesWithBMpro.includes(companyProfileId);
};

export const computeRubricCostsPayload = (_systemCosts, newRubricsCosts) => {
    const systemCosts = copy(_systemCosts);
    const rubrics = newRubricsCosts;
    const rubricsName = [];
    let totalRubricsCost = 0;

    rubrics.forEach((item) => {
        // @ts-ignore
        if (!rubricsName.includes(item?.name)) rubricsName.push(item?.name);

        if (isFieldDefined(systemCosts?.[item.name]?.details?.find((r) => r.cost_tag === item.cost_tag))) {
            const cost = systemCosts?.[item.name]?.details?.find((r) => r.cost_tag === item.cost_tag);
            cost.included_cost = item?.included_cost;
            if (cost.included_cost && !item?.included_default_cost) {
                if (cost.cost_recurrence_id === 1) systemCosts.capex += parseInt(cost?.final_cost);
                else systemCosts.opex += parseInt(cost?.final_cost);
            } else if (!cost.included_cost && item?.included_default_cost) {
                if (cost.cost_recurrence_id === 1) systemCosts.capex -= parseInt(cost?.final_cost);
                else systemCosts.opex -= parseInt(cost?.final_cost);
            }
        }

        if (item?.included_cost && !item?.included_default_cost) totalRubricsCost += parseInt(item?.final_cost);
        else if (!item?.included_cost && item?.included_default_cost) totalRubricsCost -= parseInt(item?.final_cost);
    });

    rubricsName.forEach((rubric) => {
        const total = systemCosts?.[rubric]?.details?.reduce((accumulator, currentValue) => accumulator + currentValue[rubric], 0);
        systemCosts[rubric].total_costs = total;
    });

    systemCosts.total_costs += totalRubricsCost;

    // @ts-ignore
    delete systemCosts?.extra_cost;

    return systemCosts;
};

export const conditionsToDisableDetailedBM = (negotiation) => {
    const disabled: { condition: boolean; reason: Nullable<string> } = { condition: false, reason: null };
    if (isDefined(negotiation?.status_risk_premium_id) && negotiation?.status_risk_premium_id !== BUSINESS_MODEL_ELIGIBLE.YES)
        disabled.reason = BUSINESS_MODEL_NON_ELIGIBLE_REASONS.RISK; // só tem 0 3
    if (isDefined(negotiation?.can_negotiate) && !negotiation?.can_negotiate)
        disabled.reason = BUSINESS_MODEL_NON_ELIGIBLE_REASONS.CAN_NEGOTIATE;
    if (negotiation?.hasErrorOnNetSavingsEligibility) disabled.reason = BUSINESS_MODEL_NON_ELIGIBLE_REASONS.NOT_NET_SAVINGS_ELIGIBILITY;

    if (isDefined(disabled.reason)) disabled.condition = true;
    return disabled;
};

export const hasServiceByCompanyID = (companyProfileId) =>
    [
        getCompanyProfileIds().EDP_ES,
        getCompanyProfileIds().NRG,
        getCompanyProfileIds().EDP_IT,
        getCompanyProfileIds().EDP_PL,
        getCompanyProfileIds().EDP_PT,
        getCompanyProfileIds().NRG,
        getCompanyProfileIds().SUNSEAP,
        getCompanyProfileIds().ROMANDE,
        getCompanyProfileIds().PPC,
        getCompanyProfileIds().PROFILE_BASE_UK,
        getCompanyProfileIds().EVOLO,
        getCompanyProfileIds().PROFILE_BASE_USA,
        getCompanyProfileIds().PROFILE_BASE_IT,
        getCompanyProfileIds().PROFILE_BASE_DE,
        getCompanyProfileIds().PROFILE_EDP_FR,
        getCompanyProfileIds().EFZ,
    ].includes(companyProfileId);
