//Libraries
import clone from 'fast-copy';
import PropTypes from 'prop-types';
//Our Components
import { isNumber } from 'services/util/auxiliaryUtils';
import CustomUnit from './CustomUnit';
import { isOpenArea } from 'services/settings';
import { useUserStore } from 'store/user';

//Defaults styles available
const STYLES_FORMAT = {
    DECIMAL: 'decimal', //for plain number formatting.
    CURRENCY: 'currency', //for currency formatting.
    PERCENT: 'percent', //for percent formatting. (Range: [-1, 1])
    UNIT: 'unit', //for unit formatting.
    NOS: 'nos', //for no style
};
//Styles Available
const STYLES_AVAILABLE = [STYLES_FORMAT.DECIMAL, STYLES_FORMAT.CURRENCY, STYLES_FORMAT.PERCENT, STYLES_FORMAT.UNIT, STYLES_FORMAT.NOS];

//Defaults unit Display formats
const UNIT_DISPLAY = {
    LONG: 'long', //(e.g., 16 litres)
    SHORT: 'short', //(e.g., 16 l)
    NARROW: 'narrow', //(e.g., 16l)
};
//Defaults currency displays
const CURRENCY_DISPLAY = {
    SYMBOL: 'symbol', //$
    NARROW_SYMBOL: 'narrowSymbol', //"$100" rather than "US$100"
    CODE: 'code', //USD (Iso Code)
    NAME: 'name', //Dollar
};
//Defaults
const DEFAULTS_PROPS = {
    LOCALE: 'en-GB',
    CURRENCY: 'EUR',
    STYLE: STYLES_FORMAT.DECIMAL,
    UNIT_DISPLAY: UNIT_DISPLAY.SHORT,
    UNIT: 'liter',
    NUMBER_DECIMAL_PLACES: 1,
    DELIMITER: '.',
    CURRENCY_DISPLAY: CURRENCY_DISPLAY.NARROW_SYMBOL,
    NUMBER: 0,
};

/**
 * formatNumber
 * Link: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 * @description Formats a number according to its type and locale
 * @param {string} locale - Locale from the user's token
 * @param {string} styleFormat - Styling method of the number
 * - "decimal" for plain number formatting.
 * - "currency" for currency formatting.
 * - "percent" for percent formatting. (Range: [-1, 1])
 * - "unit" for unit formatting.
 * @param {string} currency - Currency unit desired
 * @param {string} unitDisplay - Unit Format desired
 * - "long" (e.g., 16 litres)
 * - "short" (e.g., 16 l)
 * - "narrow" (e.g., 16l)
 * @param {string} unit - Unit desired
 * - Link: https://github.com/unicode-org/cldr/blob/master/common/validity/unit.xml
 * @param {number} numberOfDecimalPlaces - Number of decimals [0,21]
 * @param {string} currencyDisplay - Format of the currency
 * - "symbol" (e.g., $)
 * - "narrowSymbol" (e.g., "$" rather than "US$")
 * - "code" (e.g., USD Iso Code)
 * - "name" (e.g., Dollar)
 * @param {any} number - Value to be formated
 * @returns {string}
 */
const formatNumber = ({ locale, styleFormat, currency, unitDisplay, unit, numberOfDecimalPlaces, delimiter, number }) => {
    let value = JSON.stringify(getParseNumber(number, delimiter));
    switch (styleFormat) {
        case STYLES_FORMAT.NOS:
            return number;
        case STYLES_FORMAT.CURRENCY:
            value = new Intl.NumberFormat(locale, {
                style: styleFormat,
                currency,
                unitDisplay,
                minimumFractionDigits: numberOfDecimalPlaces,
                maximumFractionDigits: numberOfDecimalPlaces,
                //currencyDisplay,
            }).format(Number(value));
            break;
        case STYLES_FORMAT.UNIT:
            value = new Intl.NumberFormat(locale, {
                style: styleFormat,
                unit,
                unitDisplay,
                minimumFractionDigits: numberOfDecimalPlaces,
                maximumFractionDigits: numberOfDecimalPlaces,
            }).format(Number(value));
            break;
        case STYLES_FORMAT.PERCENT:
        case STYLES_FORMAT.DECIMAL:
        default:
            value = new Intl.NumberFormat(locale, {
                style: styleFormat,
                unitDisplay,
                minimumFractionDigits: numberOfDecimalPlaces,
                maximumFractionDigits: numberOfDecimalPlaces,
            }).format(Number(value));
            break;
    }

    return value;
};

/**
 * getParseNumber
 *
 * @description Get Integer from a formatted string
 * @param {any} value - Number or String of a Number
 * @param {string} delimiter - Delimiter on the string
 * @returns {number}
 */
export const getParseNumber = (number, delimiter) => {
    let value = clone(number);

    if (!isNumber(value)) {
        if (delimiter === '.' && !!value) {
            value = value.split(delimiter).join('');
            value = value.replace(',', '.');
        }
        if (delimiter === ',' && !!value) {
            value = value.split(delimiter).join('');
        }
    }

    return parseFloat(value);
};

/**
 * Component
 *  Examples:
 *  - <FormatNumber styleFormat="percent" number={-0.22} />
 *  - <FormatNumber styleFormat="currency" number={22.12} />
 *  - <FormatNumber unit="kw" number={"22,12"} />
 */
const FormatNumber = ({ styleFormat, unitDisplay, unit, numberOfDecimalPlaces, delimiter, number, style }) => {
    const { user } = useUserStore();

    const locale = isOpenArea() ? 'el-GR' : user?.locale;
    const currency = isOpenArea() ? 'EUR' : user?.currency.code ?? 'EUR';

    if (isNaN(parseFloat(number))) return <span>-</span>;

    //Condition to show custom Format
    const conditionCustomFormat = styleFormat === 'decimal' && !!unit;
    //Validate valid unit as style
    if (STYLES_AVAILABLE.includes(unit)) {
        styleFormat = unit;
        unit = null;
    }
    //Fallback invalid style
    if (!STYLES_AVAILABLE.includes(styleFormat)) {
        styleFormat = 'decimal';
    }

    return (
        <>
            {formatNumber({
                locale,
                styleFormat,
                currency,
                unitDisplay,
                unit,
                numberOfDecimalPlaces,
                delimiter,
                number,
            })}
            {conditionCustomFormat && (
                /*
                    Custom Units Available:
                    -100 years
                    -100 €/year
                    -100 &
                    -100 %/year
                    -100 €/kWh
                    -100 €/kWp
                    -100 €/month
                    -100 kW
                    -100 kWp
                    -100 €/MWh
                    -100 MWh
                    -100 m²
                    -100 panels
                    -100 º
                    -100 tep
                    -100 BTU/h
                */
                <>
                    {' '}
                    {/*@ts-ignore*/}
                    <CustomUnit style={style} unit={unit} number={number} />
                </>
            )}
        </>
    );
};

// Set default props
FormatNumber.defaultProps = {
    locale: DEFAULTS_PROPS.LOCALE,
    currency: DEFAULTS_PROPS.CURRENCY,
    styleFormat: DEFAULTS_PROPS.STYLE,
    unitDisplay: DEFAULTS_PROPS.UNIT_DISPLAY,
    numberOfDecimalPlaces: DEFAULTS_PROPS.NUMBER_DECIMAL_PLACES,
    delimiter: DEFAULTS_PROPS.DELIMITER,
    currencyDisplay: DEFAULTS_PROPS.CURRENCY_DISPLAY,
    number: DEFAULTS_PROPS.NUMBER,
    style: {},
};

//Proptypes
FormatNumber.propTypes = {
    locale: PropTypes.string,
    styleFormat: PropTypes.string,
    currency: PropTypes.string,
    unitDisplay: PropTypes.string,
    unit: PropTypes.string,
    numberOfDecimalPlaces: PropTypes.number,
    delimiter: PropTypes.string,
    currencyDisplay: PropTypes.string,
    // number: PropTypes.any.isRequired,
};

export default FormatNumber;
