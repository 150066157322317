import { createContext, useEffect, useState, useContext, useMemo } from 'react';
import { getClient, getClientFacilities, getClientList } from 'redux/selectors/client';
import { useSelector } from 'react-redux';
import { getFacilityInfo } from 'redux/selectors/facility';
import { useDispatch } from 'react-redux';
import { resetFacility } from 'redux/actions/facility';
import { resetClient, setClientFacilities } from 'redux/actions/client';
import { ISolution, ISolutionEvent, ISolutionsContext } from 'interfaces/solutions';
import { IClient } from 'interfaces/solutions/client';
import { INITIAL_SOLUTIONS_CLIENT, INITIAL_SOLUTIONS_FACILITY } from 'constants/solutions';
import { PRODUCTS_IDS_IMPLEMENTED, PRODUCT_IDS } from 'constants/products';
import { IFacility } from 'interfaces/solutions/facility';
import { isDefined } from 'services/util/auxiliaryUtils';
import { UserContext } from 'contexts/userContext';
import { GRUPO_CANAL_IDS, USERS_TYPE_ID } from 'constants/user';
import { isEnvDevFlag } from 'services/settings';
import { useFeatureFlags } from 'store/featureFlags';

export const SolutionsContext = createContext<ISolutionsContext>({
    solutions: [],
    setSolutions: () => [],
    isRequestingSolutions: false,
    setIsRequestingSolutions: () => false,
    isUpdatingSolutions: false,
    setIsUpdatingSolutions: () => false,
    client: INITIAL_SOLUTIONS_CLIENT,
    setClient: () => {},
    clientList: [],
    setClientList: () => [],
    isRequestingFacilitiesOnClientSearch: false,
    setIsRequestingFacilitiesOnClientSearch: () => false,
    facility: INITIAL_SOLUTIONS_FACILITY,
    setFacility: () => {},
    facilityList: null,
    setFacilityList: () => [],
    availableSolutions: [],
    unavailableSolutions: [],
    solutionsEvent: { type: null },
    setSolutionEvent: () => {},
    companyProfileId: null,
    // Reset
    resetClientHandler: () => {},
    resetFacilityHandler: () => {},
    resetSolutionsHandler: () => {},
    resetAllHandler: () => {},
    mayPassOpenProposals: false,
});
SolutionsContext.displayName = 'efz-SolutionsContext';

export const SolutionsProvider: React.FC<React.ReactNode> = ({ children }) => {
    const dispatch = useDispatch();

    const rClient: IClient | any = useSelector<any>((state) => getClient(state?.client));
    const rFacility: IFacility | any = useSelector<any>((state) => getFacilityInfo(state?.facility));

    const { userTypeID, companyProfileId, userChannelIDGroup } = useContext(UserContext);

    const mayPassOpenProposals = useMemo(
        () =>
            [GRUPO_CANAL_IDS.CGD, GRUPO_CANAL_IDS.SANTANDER, GRUPO_CANAL_IDS.NOVOBANCO, GRUPO_CANAL_IDS.BCP, GRUPO_CANAL_IDS.NOS].includes(
                Number(userChannelIDGroup)
            ),
        [userChannelIDGroup]
    );

    const [solutions, setSolutions] = useState<ISolution[]>([]);
    const [isRequestingSolutions, setIsRequestingSolutions] = useState<boolean>(false);
    const [isUpdatingSolutions, setIsUpdatingSolutions] = useState<boolean>(false);
    const [client, setClient] = useState<IClient | any>(rClient ?? INITIAL_SOLUTIONS_CLIENT);
    const [clientList, setClientList] = useState<IClient[] | unknown>(useSelector<any>((state) => getClientList(state?.client)) ?? []);
    const [isRequestingFacilitiesOnClientSearch, setIsRequestingFacilitiesOnClientSearch] = useState(false);
    const [facility, setFacility] = useState<IFacility | any>(rFacility ?? INITIAL_SOLUTIONS_FACILITY);
    const [facilityList, setFacilityList] = useState<IFacility[] | any>(
        useSelector<any>((state) => {
            const res = getClientFacilities(state?.client);
            // @ts-ignore
            return res?.length === 0 ? null : res;
        }) ?? null
    );
    const [solutionsEvent, setSolutionEvent] = useState<ISolutionEvent>({ type: null });
    const [openHasProposals, setOpenHasProposals] = useState(false);
    // @ts-ignore
    const availableSolutions: ISolution[] = solutions?.filter(
        (solution) =>
            !!solution?.tem_potencial &&
            // @ts-ignore
            PRODUCTS_IDS_IMPLEMENTED.includes(Number(solution?.id)) &&
            (![USERS_TYPE_ID.MANAGER].includes(userTypeID) && ![USERS_TYPE_ID.CHANNEL].includes(userTypeID) ?
                isEnvDevFlag(useFeatureFlags.getState().featureFlags['fe-2438']) ? Number(solution.id)
                :   Number(solution.id) !== PRODUCT_IDS.EVCSB
            :   true)
    );
    // @ts-ignore
    const unavailableSolutions: ISolution[] = solutions?.filter(
        (solution) =>
            // @ts-ignore
            (!solution?.tem_potencial || !PRODUCTS_IDS_IMPLEMENTED.includes(Number(solution?.id))) &&
            (![USERS_TYPE_ID.MANAGER].includes(userTypeID) && ![USERS_TYPE_ID.CHANNEL].includes(userTypeID) ?
                isEnvDevFlag(useFeatureFlags.getState().featureFlags['fe-2438']) ? Number(solution.id)
                :   Number(solution.id) !== PRODUCT_IDS.EVCSB
            :   true)
    );

    const resetClientHandler = () => {
        dispatch(resetClient());
        setClient(INITIAL_SOLUTIONS_CLIENT);
        dispatch(setClientFacilities([]));
    };

    const resetFacilityHandler = () => {
        dispatch(resetFacility());
        setFacility(INITIAL_SOLUTIONS_FACILITY);
        if (!isDefined(rClient?.id)) {
            setFacilityList([]);
            dispatch(setClientFacilities([]));
        }
    };

    const resetSolutionsHandler = () => {
        setSolutions([]);
    };

    const resetAllHandler = () => {
        resetClientHandler();
        resetFacilityHandler();
        resetSolutionsHandler();
    };
    useEffect(() => {
        if (!isDefined(rClient?.id)) resetClientHandler();
    }, [rClient?.id]); // eslint-disable-line

    useEffect(() => {
        if (!isDefined(rFacility?.id)) resetFacilityHandler();
    }, [rFacility?.id]); // eslint-disable-line

    const solutionsContextValues: ISolutionsContext = {
        solutions,
        setSolutions,
        isRequestingSolutions,
        setIsRequestingSolutions,
        isUpdatingSolutions,
        setIsUpdatingSolutions,
        client,
        setClient,
        clientList,
        setClientList,
        isRequestingFacilitiesOnClientSearch,
        setIsRequestingFacilitiesOnClientSearch,
        facility,
        setFacility,
        facilityList,
        setFacilityList,
        availableSolutions,
        unavailableSolutions,
        solutionsEvent,
        setSolutionEvent,
        companyProfileId,

        // Reset
        resetAllHandler,
        resetClientHandler,
        resetFacilityHandler,
        resetSolutionsHandler,
        openHasProposals,
        setOpenHasProposals,
        mayPassOpenProposals,
    };

    return <SolutionsContext.Provider value={solutionsContextValues}>{children}</SolutionsContext.Provider>;
};
