// Components
import SPVSimpleNav from 'components/Products/solarpvSimple/b2c/Nav';
import SPVSimpleNavBottom from 'components/Products/solarpvSimple/common/NavBottom';
import Loading from 'components/core/Loading';
import SolarpvSimpleFormContainer from 'components/Products/solarpvSimple/b2c';
import useSolarPVSimpleMain from 'hooks/products/solarpvSimple/useSolarpvSimpleMain';
import OAFooter from '../../../OAFooter';
import { useEffect, useRef } from 'react';
import { useMediaQuery } from '@mui/material';

import { STEPS_RESERVED_AREA } from 'constants/products/solarpvSimple';
import OALangingPage from 'components/Products/solarpvSimple/common/LandingPage';
import { StyledSPVSimplePage } from 'pages/Restricted/Products/SolarpvSimple/styles';

// open area
const OASolarpvSimpleB2C = ({ isB2C }) => {
    const ref = useRef();
    const matches = useMediaQuery('(min-width:960px)', { noSsr: true });
    const isDesktop = useMediaQuery('(min-width:1366px)', { noSsr: true });

    const { isOArea, step, goBack, isMounting, goForward, forwardFormId } = useSolarPVSimpleMain();

    useEffect(() => {
        if (ref?.current) {
            // FIXME: use only one scroll behavior
            if (matches || step === 0)
                /* step=0 OA LandingPage */
                ref.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            else ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
    }, [step]); //eslint-disable-line

    return (
        <div className="app-main-content-wrapper" ref={ref}>
            <div className="app-main-content">
                <StyledSPVSimplePage className={`solarpv-simple-page ${step === 0 ? 'landing' : ''}`}>
                    {isOArea && step === 0 /* LANDING_PAGE */ ?
                        <OALangingPage isB2C={isB2C} />
                    :   <>
                            <SPVSimpleNav forwardFormId={forwardFormId} />
                            {isMounting ?
                                <Loading /> /* TODO: montar um Skeleton MUI  */
                            :   <SolarpvSimpleFormContainer />}
                            <SPVSimpleNavBottom goBack={goBack} goForward={goForward} forwardFormId={forwardFormId} />
                        </>
                    }
                </StyledSPVSimplePage>
            </div>
            {(step !== STEPS_RESERVED_AREA.ADDRESS || isDesktop) && <OAFooter />}
        </div>
    );
};

export default OASolarpvSimpleB2C;
