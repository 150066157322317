import { useFeatureFlags } from './../featureFlags/index';
import jwt_decode from 'jwt-decode';
import { requestGetClientSuccess, setClientHistory } from 'redux/actions/client';
import { getClient } from 'api/client';
import { AVAILABLE_LANGUAGES, BASENAME_URL_SOLUTIONS, COMPANY_IDS, COMPANY_PROFILE_IDS, USE_COMPANYID } from 'constants/settings';
import { USERS_TYPE_ID } from 'constants/user';
import { StatusCodes } from 'http-status-codes';
import { TTokenUser, TUser /*TFieldToken*/ } from 'interfaces/user';
import { getCompanyProfileIds, isUserClient } from 'services/user';
import { convertThemeBleachedToSolid, isDefined } from 'services/util/auxiliaryUtils';
import { Nullable } from 'types/utils';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useUserStore } from 'store/user';
import { getBaseURL } from 'constants/endpoints';
import { getTheme } from 'api/theme';
import { getUserHistoryClients } from 'api/user';
import { getLogoutADFS } from 'api/auth';

// import { devtools } from 'zustand/middleware';
let themeController: AbortController;

interface IAuth {
    token: Nullable<string>;
    fieldToken: Nullable<string>;
    isUserAuthenticated: boolean;
    redirectLogout: Nullable<IADFSParams>;
    loginOK: ({ token, history, dispatch, fieldToken }) => Promise<void>;
    setLogin: ({ user, theme, history }: { user: TUser; theme: any; history; token: string; lngObj; dispatch }) => void;
    logout: ({ fromADFS }: { fromADFS?: IADFSParams }) => void;
    removeToken: () => void;

    adfsLogin: () => void;
    adfsLogout: ({ token, nameID, sessionIndex, empresa_id }) => Promise<void>;
    // logout: () => void;
}

interface IADFSParams {
    token: string;
    nameID: string;
    sessionIndex: string;
    empresa_id: number;
}

export const useAuthStore = create<IAuth>()(
    persist(
        // devtools(
        (set) => ({
            isUserAuthenticated: false,
            token: null,
            fieldToken: null,
            redirectLogout: null,
            setLogin: async ({ user, theme, history, token, lngObj, dispatch }) => {
                let client: Nullable<any> = null;
                if (isUserClient(user?.tipo_utilizador_id)) {
                    client = await getClient();
                }
                set((state) => {
                    const isUserAuthenticated = !!user;
                    if (isUserAuthenticated && isDefined(user.tipo_utilizador_id)) {
                        useUserStore.getState().setUser({ user, theme, locale: lngObj });
                        if ([USERS_TYPE_ID.ADMIN, USERS_TYPE_ID.COMMERCIAL_SUPPORT].includes(user.tipo_utilizador_id)) {
                            state.logout({});
                            return { user: null, isUserAuthenticated: false };
                        } else if (!isUserClient(user.tipo_utilizador_id)) {
                            history.push(BASENAME_URL_SOLUTIONS);
                        } else if (client !== null) {
                            if (client?.status === StatusCodes.OK) {
                                dispatch(requestGetClientSuccess({ data: client.data.data[0] }));
                            }
                        }
                    }

                    return { isUserAuthenticated, token };
                });
            },
            logout: async (props) => {
                set((state) => {
                    localStorage.removeItem('userToken');
                    localStorage.removeItem('userTypeId');
                    localStorage.removeItem('salesSegment');
                    localStorage.removeItem('CPID');
                    localStorage.removeItem('pais_id');

                    const locale_code = useUserStore.getState().LOCALE_CODE;
                    const lang = AVAILABLE_LANGUAGES.find((language) => language.locale === locale_code);
                    useUserStore.getState().setUser({
                        user: null,
                        locale: lang,
                        theme: null,
                        fieldUser: null,
                    });

                    return { ...state, isUserAuthenticated: false, token: null, redirectLogout: props?.fromADFS ?? null, fieldToken: null };
                });
            },
            removeToken: () => {
                set(() => {
                    return { token: null };
                });
            },
            adfsLogin: () => {
                const uriAuthADFS = `${getBaseURL()}auth/adfs1?redirect_to=${getBaseURL(false)}`;
                window.location.replace(uriAuthADFS);
            },
            adfsLogout: async ({ token, nameID, sessionIndex, empresa_id }: IADFSParams) => {
                const uriRsp = await getLogoutADFS(token, nameID, sessionIndex, empresa_id);
                if (uriRsp?.status === StatusCodes.OK) {
                    window.location.replace(uriRsp?.data?.data?.uri);
                }
            },
            loginOK: async ({ token, history, dispatch, fieldToken }) => {
                localStorage.setItem('userToken', token);
                const jwtDecodeToken: TTokenUser = jwt_decode(token);
                // const jwtDecodeFieldToken: Nullable<TFieldToken> = null;
                // if (isDefined(fieldToken)) {
                //     jwtDecodeFieldToken = jwt_decode(fieldToken);
                // }

                const userTypeID = jwtDecodeToken.user.tipo_utilizador_id;
                localStorage.setItem('userTypeId', JSON.stringify(userTypeID));
                const { segmentos_mercado, default_segmento_mercado_id, locale, empresa_id, perfil_empresa_id } =
                    jwtDecodeToken?.user ?? {};
                let lngObj = AVAILABLE_LANGUAGES.find((obj) => obj.appLocale === locale);
                if (!lngObj || [getCompanyProfileIds().EFZ].includes(empresa_id)) {
                    lngObj = AVAILABLE_LANGUAGES.find((obj) => obj.appLocale === 'en-GB')!;
                    jwtDecodeToken.user.locale = lngObj?.appLocale ?? 'en-GB'; //fallback
                }

                const applyCompanyId = USE_COMPANYID.includes(perfil_empresa_id);
                localStorage.setItem('CPID', window.btoa(JSON.stringify(applyCompanyId ? COMPANY_IDS : COMPANY_PROFILE_IDS)));
                localStorage.setItem('applyCompanyProfileId', JSON.stringify(!applyCompanyId));
                localStorage.setItem(
                    'salesSegment',
                    segmentos_mercado?.find((el) => el?.id === default_segmento_mercado_id)?.description ?? 'B2B'
                );

                themeController?.abort();
                themeController = new AbortController();
                const theme = await getTheme(themeController);
                //Convert theme bleached colors to solid colors, to avoid problems with the theme
                const solidThemePallet = convertThemeBleachedToSolid(theme?.data?.data?.palette);
                //Merge the solid colors with the rest of the theme
                const solidTheme = { ...theme?.data?.data, palette: solidThemePallet };
                let user = { ...jwtDecodeToken.user, shows_terms: false, is_logged_by_sso: jwtDecodeToken?.is_logged_by_sso };
                if (user?.is_logged_by_sso) user = { ...user, nameID: jwtDecodeToken?.nameID, sessionIndex: jwtDecodeToken?.sessionIndex };
                let client: Nullable<any> = null;
                if (isUserClient(user.tipo_utilizador_id)) {
                    client = await getClient();
                }
                const rspClientHistory = await getUserHistoryClients();
                await dispatch(setClientHistory(rspClientHistory));
                set((state) => {
                    const isUserAuthenticated = !!user;
                    if (isUserAuthenticated && isDefined(user.tipo_utilizador_id)) {
                        useUserStore.getState().setUser({
                            user,
                            theme: solidTheme,
                            locale: lngObj,
                            fieldUser: useFeatureFlags.getState().featureFlags['fe-2362'] ? user.perfil_field_user_id ?? null : null,
                        });
                        if ([USERS_TYPE_ID.ADMIN, USERS_TYPE_ID.COMMERCIAL_SUPPORT].includes(user.tipo_utilizador_id)) {
                            state.logout({});
                            return { user: null, isUserAuthenticated: false };
                        } else if (!isUserClient(user.tipo_utilizador_id)) {
                            history.push(BASENAME_URL_SOLUTIONS);
                        } else if (client !== null) {
                            if (client?.status === StatusCodes.OK) {
                                dispatch(requestGetClientSuccess({ data: client.data.data[0] }));
                            }
                        }
                    }
                    useUserStore.getState().switchLanguage(lngObj);
                    return { isUserAuthenticated, token, fieldToken };
                });
            },
        }),
        // )
        // @ts-ignore
        {
            name: 'auth',
            partialize: (state) => Object.fromEntries(Object.entries(state).filter(([key]) => !['redirectLogout'].includes(key))),
        }
    )
);
