import { lazy } from 'react';
// Services
import lazyLoader from 'services/lazyLoader';

const OMProcessesPage = lazy(() => lazyLoader(() => import('pages/Restricted/Processes/O&M')));

const OMProcesses = () => {
    return <OMProcessesPage />;
};

export default OMProcesses;
