import { themeBase } from 'constants/styles/global';
import { styled } from 'styled-components';

export const StyledErrorBoundary = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: ${themeBase.bodyBg};

    .error-boundary-background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }

    .error-boundary-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: rgba(255, 255, 255, 0.8);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        z-index: 2;
        padding: 3.5rem;
    }

    h1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.875rem;
        text-align: center;
        color: ${themeBase.color.neutral800};
        margin: 0 0 1rem;
    }

    p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.375rem;
        text-align: center;
        color: ${themeBase.color.neutral800};
        margin-bottom: 2rem;
    }

    &.clock {
        height: 100vh;
    }
`;
