import validate from 'services/util/validate';
import { FACILITY_QUESTIONS_SECTIONS } from './facility';
import IntlMessages from 'components/util/IntlMessages';
import LegalRepresentativesTableOptions from 'components/Forms/Client/LegalRepresentativesTableOptions';

//Icons
import { ReactComponent as EditIcon } from 'assets/images/icons/svg/edit-pencil2.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/svg/trash-2.svg';
import { getCompanyProfileIds } from 'services/user';

export const MAX_HISTORY_LIST_CLIENT = 5;
/* default objects */
export const DEFAULT_CLIENT_SEARCH = {
    loader: false,
    history: [],
};

/* default objects */
export const INPUT_NAMES = {
    ID: 'id',
    CONTACTO_EMAIL: 'contacto_email',
    CONTACTO_NOME: 'contacto_nome',
    CONTACTO_NUMERO: 'contacto_numero',
    DESCRICAO: 'descricao',
    NIPC: 'nipc',
    PAIS_ID: 'pais_id',
    CAE5: 'cae5',
    CAE2: 'cae2',
    RISCO: 'risco_energia_id',
    SEGMENTO: 'segmento_id',
    MORADA: 'morada',
    CODIGO_POSTAL: 'codigo_postal',
    LOCALIDADE: 'localidade',
    CONTRIBUINTE_ICMS: 'contribuinte_icms',
    // Detailed Address
    NUMERO: 'numero',
    COMPLEMENTO: 'complemento',
    BAIRRO: 'bairro',
    MUNICIPIO: 'municipio_descricao',
    NUT: 'nuts_i_id',
    TNW: 'tnw',
    NUMERO_REGISTO_EMPRESA: 'numero_registo_empresa',
};

export const INPUT_NAMES_B2C = {
    NOME_PROPRIO: 'nome_proprio',
    APELIDO: 'apelido',
    EMAIL: 'email',
    NUMERO_FISCAL: 'numero_fiscal',
    NUMERO_TELEFONE: 'numero_telefone',
    MORADA: 'morada',
    CODIGO_POSTAL: 'codigo_postal',
    LOCALIDADE: 'localidade',
};

// Inputs for the Create Client form
export const CREATE_CLIENT_FORM_QUESTIONS = {
    [INPUT_NAMES.DESCRICAO]: {
        name: INPUT_NAMES.DESCRICAO,
        label: 'page.client.label.description',
        placeholder: 'page.client.placeholder.description',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['required', 'maxLength'], { maxLength: 500 }),
        optValidation: { maxLength: 500 },
    },
    [INPUT_NAMES.NIPC]: {
        name: INPUT_NAMES.NIPC,
        label: 'page.client.label.nipc',
        placeholder: 'page.client.placeholder.nipc',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['required', 'maxLength'], { maxLength: 16 }),
        optValidation: { maxLength: 16 },
    },
    [INPUT_NAMES.NUMERO_REGISTO_EMPRESA]: {
        section: FACILITY_QUESTIONS_SECTIONS.CLIENT,
        name: INPUT_NAMES.NUMERO_REGISTO_EMPRESA,
        label: 'label.companyRegistrationNumber',
        placeholder: 'placeholder.general',
        type: 'string',
        defaultValue: '',
        visible: false,
        validation: validate(['required', 'maxLength'], { maxLength: 48 }),
        optValidation: { maxLength: 48 },
    },
    [INPUT_NAMES.CAE5]: {
        name: INPUT_NAMES.CAE5,
        label: 'page.client.label.cae5',
        placeholder: 'page.client.placeholder.cae5',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['required', 'minLength', 'maxLength'], { maxLength: 8, minLength: 2 }),
        optValidation: { maxLength: 8, minLength: 2 },
        tooltip: 'label.extraInformation',
        tooltipLink: '#',
    },
    [INPUT_NAMES.MORADA]: {
        name: INPUT_NAMES.MORADA,
        label: 'page.client.label.address',
        placeholder: 'page.client.placeholder.address',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['maxLength'], { maxLength: 48 }),
        optValidation: { maxLength: 48 },
    },
    [INPUT_NAMES.CODIGO_POSTAL]: {
        name: INPUT_NAMES.CODIGO_POSTAL,
        label: 'page.client.label.postcode',
        placeholder: 'page.client.placeholder.postcode',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['maxLength'], { maxLength: 8 }),
        optValidation: { maxLength: 8 },
    },
    [INPUT_NAMES.LOCALIDADE]: {
        name: INPUT_NAMES.LOCALIDADE,
        label: 'page.client.label.locality',
        placeholder: 'page.client.placeholder.locality',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['maxLength'], { maxLength: 64 }),
        optValidation: { maxLength: 64 },
    },
    [INPUT_NAMES.CONTACTO_NOME]: {
        name: INPUT_NAMES.CONTACTO_NOME,
        label: 'page.client.label.contactName',
        placeholder: 'page.client.placeholder.contactName',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['maxLength'], { maxLength: 256 }),
        optValidation: { maxLength: 256 },
    },
    [INPUT_NAMES.CONTACTO_NUMERO]: {
        name: INPUT_NAMES.CONTACTO_NUMERO,
        label: 'page.client.label.contactNumber',
        placeholder: 'page.client.placeholder.contactNumber',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['maxLength'], { maxLength: 20 }),
        optValidation: { maxLength: 20 },
    },
    [INPUT_NAMES.CONTACTO_EMAIL]: {
        name: INPUT_NAMES.CONTACTO_EMAIL,
        label: 'page.client.label.contactEmail',
        placeholder: 'page.client.placeholder.contactEmail',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['email', 'maxLength'], { maxLength: 64 }),
        optValidation: { maxLength: 64 },
    },
    [INPUT_NAMES.RISCO]: {
        name: INPUT_NAMES.RISCO,
        label: 'page.client.label.risk',
        type: 'number',
        defaultValue: '',
        visible: true,
        validation: null,
    },
    [INPUT_NAMES.CONTRIBUINTE_ICMS]: {
        name: INPUT_NAMES.CONTRIBUINTE_ICMS,
        label: 'page.client.label.icms',
        defaultValue: 'false',
        visible: false,
        disabled: false,
        validation: validate(['required']),
        options: [
            {
                label: 'label.yes',
                value: 'true',
            },
            {
                label: 'label.no',
                value: 'false',
            },
        ],
    },
    [INPUT_NAMES.NUMERO]: {
        name: INPUT_NAMES.NUMERO,
        label: 'page.client.label.numero',
        placeholder: 'page.client.placeholder.numero',
        type: 'string',
        defaultValue: '',
        visible: false,
        validation: validate(['required', 'maxLength'], { maxLength: 500 }),
        optValidation: { maxLength: 500 },
    },
    [INPUT_NAMES.COMPLEMENTO]: {
        name: INPUT_NAMES.COMPLEMENTO,
        label: 'label.complement',
        placeholder: 'placeholder.complement',
        type: 'string',
        defaultValue: '',
        visible: false,
        validation: validate(['required', 'maxLength'], { maxLength: 500 }),
        optValidation: { maxLength: 500 },
    },
    [INPUT_NAMES.BAIRRO]: {
        name: INPUT_NAMES.BAIRRO,
        label: 'label.district',
        placeholder: 'placeholder.district',
        type: 'string',
        defaultValue: '',
        visible: false,
        validation: validate(['required', 'maxLength'], { maxLength: 500 }),
        optValidation: { maxLength: 500 },
    },
    [INPUT_NAMES.NUT]: {
        name: INPUT_NAMES.NUT,
        label: 'page.client.label.nut',
        placeholder: 'label.selectOption',
        tooltip: null,
        disabled: false,
        visible: false,
        options: [],
        type: 'select',
        validation: validate(['required']),
    },
    [INPUT_NAMES.MUNICIPIO]: {
        name: INPUT_NAMES.MUNICIPIO,
        label: 'page.client.label.municipio',
        placeholder: 'label.selectOption',
        tooltip: null,
        disabled: false,
        visible: false,
        options: [],
        type: 'select',
        validation: validate(['required']),
    },
};

// Inputs for the Create Client form (B2C)
export const CREATE_CLIENT_FORM_QUESTIONS_B2C = {
    [INPUT_NAMES_B2C.NOME_PROPRIO]: {
        name: INPUT_NAMES_B2C.NOME_PROPRIO,
        label: 'label.firstName',
        placeholder: 'placeholder.name',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['required', 'maxLength'], { maxLength: 20 }),
        optValidation: { maxLength: 20 },
    },
    [INPUT_NAMES_B2C.APELIDO]: {
        name: INPUT_NAMES_B2C.APELIDO,
        label: 'label.lastName',
        placeholder: 'placeholder.lastname',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['maxLength'], { maxLength: 20 }),
        optValidation: { maxLength: 20 },
    },
    [INPUT_NAMES_B2C.EMAIL]: {
        name: INPUT_NAMES_B2C.EMAIL,
        label: 'label.email',
        placeholder: 'page.client.placeholder.contactEmail',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['required', 'email', 'maxLength'], { maxLength: 64 }),
        optValidation: { maxLength: 64 },
    },
    [INPUT_NAMES_B2C.NUMERO_FISCAL]: {
        name: INPUT_NAMES_B2C.NUMERO_FISCAL,
        label: 'label.nif',
        placeholder: 'page.client.placeholder.NIF',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['maxLength'], { maxLength: 9 }),
        optValidation: { maxLength: 9 },
    },
    [INPUT_NAMES_B2C.NUMERO_TELEFONE]: {
        name: INPUT_NAMES_B2C.NUMERO_TELEFONE,
        label: 'label.phone',
        placeholder: 'page.client.placeholder.contactNumber',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['maxLength'], { maxLength: 20 }),
        optValidation: { maxLength: 20 },
    },
    [INPUT_NAMES_B2C.MORADA]: {
        name: INPUT_NAMES_B2C.MORADA,
        label: 'label.address',
        placeholder: 'question.placeholder.address',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['maxLength'], { maxLength: 100 }),
        optValidation: { maxLength: 100 },
    },
    [INPUT_NAMES_B2C.CODIGO_POSTAL]: {
        name: INPUT_NAMES_B2C.CODIGO_POSTAL,
        label: 'label.postalCode',
        placeholder: 'placeholder.postalCode',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['maxLength'], { maxLength: 4 }),
        optValidation: { maxLength: 4 },
    },
    [INPUT_NAMES_B2C.LOCALIDADE]: {
        name: INPUT_NAMES_B2C.LOCALIDADE,
        label: 'label.locality',
        placeholder: 'placeholder.locality',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['maxLength'], { maxLength: 30 }),
        optValidation: { maxLength: 30 },
    },
    [INPUT_NAMES.RISCO]: {
        name: INPUT_NAMES.RISCO,
        label: 'page.client.label.risk',
        type: 'number',
        defaultValue: '',
        visible: false,
        validation: null,
    },
    [INPUT_NAMES.TNW]: {
        name: INPUT_NAMES.TNW,
        label: 'page.client.label.tnw',
        tooltip: null,
        disabled: true,
        visible: false,
        type: 'string',
        defaultValue: '',
        validation: null,
    },
};

// Inputs for the View/Edit Client form
export const EDIT_CLIENT_FORM_QUESTIONS = {
    [INPUT_NAMES.DESCRICAO]: {
        section: FACILITY_QUESTIONS_SECTIONS.CLIENT,
        name: INPUT_NAMES.DESCRICAO,
        label: 'page.client.label.description',
        placeholder: 'page.client.placeholder.description',
        type: 'string',
        defaultValue: '',
        visible: true,
        disabled: true,
        validation: validate(['required', 'maxLength'], { maxLength: 500 }),
        optValidation: { maxLength: 500 },
    },
    [INPUT_NAMES.NIPC]: {
        section: FACILITY_QUESTIONS_SECTIONS.CLIENT,
        name: INPUT_NAMES.NIPC,
        label: 'page.client.label.nipc',
        placeholder: 'page.client.placeholder.nipc',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['required', 'maxLength'], { maxLength: 16 }),
        optValidation: { maxLength: 16 },
    },
    [INPUT_NAMES.NUMERO_REGISTO_EMPRESA]: {
        section: FACILITY_QUESTIONS_SECTIONS.CLIENT,
        name: INPUT_NAMES.NUMERO_REGISTO_EMPRESA,
        label: 'label.companyRegistrationNumber',
        placeholder: 'placeholder.general',
        type: 'string',
        defaultValue: '',
        visible: false,
        validation: validate(['required', 'maxLength'], { maxLength: 16 }),
        optValidation: { maxLength: 16 },
    },
    [INPUT_NAMES.CAE5]: {
        name: INPUT_NAMES.CAE5,
        label: 'page.client.label.cae5',
        placeholder: 'page.client.placeholder.cae5',
        type: 'string',
        defaultValue: '',
        visible: false,
        validation: validate(['required', 'minLength', 'maxLength'], { maxLength: 8, minLength: 2 }),
        optValidation: { maxLength: 8, minLength: 2 },
        tooltip: 'label.extraInformation',
        tooltipLink: '#',
    },
    [INPUT_NAMES.RISCO]: {
        name: INPUT_NAMES.RISCO,
        label: 'page.client.label.risk',
        type: 'number',
        defaultValue: '',
        visible: true,
        validation: null,
    },
    [INPUT_NAMES.MORADA]: {
        name: INPUT_NAMES.MORADA,
        label: 'page.client.label.address',
        placeholder: 'page.client.placeholder.address',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['maxLength'], { maxLength: 48 }),
        optValidation: { maxLength: 48 },
    },
    [INPUT_NAMES.CODIGO_POSTAL]: {
        name: INPUT_NAMES.CODIGO_POSTAL,
        label: 'page.client.label.postcode',
        placeholder: 'page.client.placeholder.postcode',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['maxLength'], { maxLength: 8 }),
        optValidation: { maxLength: 8 },
    },
    [INPUT_NAMES.LOCALIDADE]: {
        name: INPUT_NAMES.LOCALIDADE,
        label: 'page.client.label.locality',
        placeholder: 'page.client.placeholder.locality',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['maxLength'], { maxLength: 64 }),
        optValidation: { maxLength: 64 },
    },
    [INPUT_NAMES.CONTACTO_NOME]: {
        section: FACILITY_QUESTIONS_SECTIONS.CLIENT,
        name: INPUT_NAMES.CONTACTO_NOME,
        label: 'page.client.label.contactName',
        placeholder: 'page.client.placeholder.contactName',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['maxLength'], { maxLength: 256 }),
        optValidation: { maxLength: 256 },
    },
    [INPUT_NAMES.CONTACTO_NUMERO]: {
        section: FACILITY_QUESTIONS_SECTIONS.CLIENT,
        name: INPUT_NAMES.CONTACTO_NUMERO,
        label: 'page.client.label.contactNumber',
        placeholder: 'page.client.placeholder.contactNumber',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['maxLength'], { maxLength: 20 }),
        optValidation: { maxLength: 20 },
    },
    [INPUT_NAMES.CONTACTO_EMAIL]: {
        section: FACILITY_QUESTIONS_SECTIONS.CLIENT,
        name: INPUT_NAMES.CONTACTO_EMAIL,
        label: 'page.client.label.contactEmail',
        placeholder: 'page.client.placeholder.contactEmail',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['email', 'maxLength'], { maxLength: 64 }),
        optValidation: { maxLength: 64 },
    },
    [INPUT_NAMES.CONTRIBUINTE_ICMS]: {
        name: INPUT_NAMES.CONTRIBUINTE_ICMS,
        label: 'page.client.label.icms',
        defaultValue: '',
        visible: false,
        validation: validate(['required']),
        options: [
            {
                label: 'label.yes',
                value: 'true',
            },
            {
                label: 'label.no',
                value: 'false',
            },
        ],
    },
    [INPUT_NAMES.NUMERO]: {
        name: INPUT_NAMES.NUMERO,
        label: 'page.client.label.numero',
        placeholder: 'page.client.placeholder.numero',
        type: 'number',
        defaultValue: '',
        visible: false,
        validation: validate(['required', 'maxLength'], { maxLength: 500 }),
        optValidation: { maxLength: 500 },
    },
    [INPUT_NAMES.COMPLEMENTO]: {
        name: INPUT_NAMES.COMPLEMENTO,
        label: 'label.complement',
        placeholder: 'placeholder.complement',
        type: 'string',
        defaultValue: '',
        visible: false,
        validation: validate(['required', 'maxLength'], { maxLength: 500 }),
        optValidation: { maxLength: 500 },
    },
    [INPUT_NAMES.BAIRRO]: {
        name: INPUT_NAMES.BAIRRO,
        label: 'label.district',
        placeholder: 'placeholder.district',
        type: 'string',
        defaultValue: '',
        visible: false,
        validation: validate(['required', 'maxLength'], { maxLength: 500 }),
        optValidation: { maxLength: 500 },
    },
    [INPUT_NAMES.NUT]: {
        name: INPUT_NAMES.NUT,
        label: 'page.client.label.nut',
        placeholder: 'label.selectOption',
        tooltip: null,
        disabled: false,
        visible: false,
        options: [],
        type: 'select',
        validation: validate(['required']),
    },
    [INPUT_NAMES.MUNICIPIO]: {
        name: INPUT_NAMES.MUNICIPIO,
        label: 'page.client.label.municipio',
        placeholder: 'label.selectOption',
        tooltip: null,
        disabled: false,
        visible: false,
        options: [],
        type: 'select',
        validation: validate(['required']),
    },
    [INPUT_NAMES.TNW]: {
        name: INPUT_NAMES.TNW,
        label: 'page.client.label.tnw',
        tooltip: null,
        disabled: true,
        visible: false,
        type: 'string',
        defaultValue: '',
        validation: null,
    },
};

export const LEGAL_REPRESENTATIVE_INPUT_NAMES = {
    NOME: 'nome_proprio',
    SOBRENOME: 'sobrenome',
    EMAIL: 'email',
    NUMERO: 'numero',
    NUMERO_FISCAL: 'numero_fiscal',
    CARGO: 'cargo',
};

// Inputs for the legal representative dialog
export const LEGAL_REPRESENTATIVE_FORM_QUESTIONS = {
    [LEGAL_REPRESENTATIVE_INPUT_NAMES.NOME]: {
        name: LEGAL_REPRESENTATIVE_INPUT_NAMES.NOME,
        label: 'label.name',
        placeholder: 'page.client.placeholder.contactName',
        type: 'string',
        defaultValue: '',
        validation: validate(['required', 'maxLength'], { maxLength: 64 }),
    },
    [LEGAL_REPRESENTATIVE_INPUT_NAMES.SOBRENOME]: {
        name: LEGAL_REPRESENTATIVE_INPUT_NAMES.SOBRENOME,
        label: 'label.lastName',
        placeholder: 'page.client.placeholder.contactSurname',
        type: 'string',
        defaultValue: '',
        validation: validate(['required', 'maxLength'], { maxLength: 64 }),
    },
    [LEGAL_REPRESENTATIVE_INPUT_NAMES.EMAIL]: {
        name: LEGAL_REPRESENTATIVE_INPUT_NAMES.EMAIL,
        label: 'label.email',
        placeholder: 'page.client.placeholder.contactEmail',
        type: 'string',
        defaultValue: '',
        validation: validate(['required', 'email', 'maxLength'], { maxLength: 64 }),
    },
    [LEGAL_REPRESENTATIVE_INPUT_NAMES.NUMERO]: {
        name: LEGAL_REPRESENTATIVE_INPUT_NAMES.NUMERO,
        label: 'label.phone',
        placeholder: 'page.client.placeholder.contactNumber',
        type: 'string',
        defaultValue: '',
        validation: validate(['required', 'maxLength'], { maxLength: 16 }),
    },
    // [LEGAL_REPRESENTATIVE_INPUT_NAMES.NUMERO_FISCAL]: {
    //     name: LEGAL_REPRESENTATIVE_INPUT_NAMES.NUMERO_FISCAL,
    //     label: 'label.nif',
    //     placeholder: 'page.client.placeholder.NIF',
    //     type: 'string',
    //     defaultValue: '',
    //     // validation: validate(['required', 'maxLength'], { maxLength: 20 }),
    // },
    [LEGAL_REPRESENTATIVE_INPUT_NAMES.CARGO]: {
        name: LEGAL_REPRESENTATIVE_INPUT_NAMES.CARGO,
        label: 'label.position',
        placeholder: 'page.client.placeholder.personPosition',
        type: 'string',
        defaultValue: '',
        validation: validate(['required', 'maxLength'], { maxLength: 64 }),
    },
};

export const LEGAL_REPRESENTATIVES_TABLE_COLUMNS = [
    {
        id: 'name',
        Header: (
            <div className="w-100">
                <span>
                    <IntlMessages id={`label.name`} />
                </span>
            </div>
        ),
        minWidth: 100,
        width: 100,
        accessor: (row) => <div className="d-flex w-100">{row.nome_proprio}</div>,
    },
    {
        id: 'surname',
        Header: (
            <div className="w-100">
                <span>
                    <IntlMessages id={`label.lastName`} />
                </span>
            </div>
        ),
        minWidth: 100,
        width: 100,
        accessor: (row) => <div className="d-flex w-100">{row.sobrenome.trim().length > 0 ? row.sobrenome : '-'}</div>,
    },
    {
        id: 'number',
        Header: (
            <div className="w-100">
                <span>
                    <IntlMessages id={`label.phone`} />
                </span>
            </div>
        ),
        minWidth: 60,
        width: 60,
        accessor: (row) => <div className="d-flex w-100">{row.numero}</div>,
    },
    {
        id: 'email',
        Header: (
            <div className="w-100">
                <span>
                    <IntlMessages id={`label.email`} />
                </span>
            </div>
        ),
        minWidth: 100,
        width: 100,
        accessor: (row) => <div className="d-flex w-100">{row.email}</div>,
    },
    // {
    //     id: 'nif',
    //     Header: (
    //         <div className="w-100">
    //             <span>
    //                 <IntlMessages id={`label.nif`} />
    //             </span>
    //         </div>
    //     ),
    //     minWidth: 60,
    //     width: 60,
    //     accessor: (row) => (
    //         <div className="d-flex w-100">
    //             {row.numero_fiscal}
    //         </div>
    //     ),
    // },
    {
        id: 'position',
        Header: (
            <div className="w-100">
                <span>
                    <IntlMessages id={`label.position`} />
                </span>
            </div>
        ),
        minWidth: 70,
        width: 70,
        accessor: (row) => <div className="d-flex w-100">{row.cargo}</div>,
    },
    {
        id: 'moreOptions',
        Header: (
            <div className="w-100 text-center">
                <span>
                    <IntlMessages id={'label.actions'} />
                </span>
            </div>
        ),
        minWidth: 60,
        width: 60,
        Cell: ({ row, handleEdit, handleDelete }) => {
            let actions = [
                {
                    icon: <EditIcon />,
                    text: <IntlMessages id="label.edit" />,
                    click: () => handleEdit(row.original.id),
                    disabled: false,
                },
                {
                    icon: <DeleteIcon />,
                    text: <IntlMessages id="label.remove" />,
                    click: () => handleDelete(row.original),
                    disabled: false,
                },
            ];

            return <LegalRepresentativesTableOptions actions={actions} />;
        },
    },
];

export const MAX_LEGAL_REPRESENTATIVES = 2;

export const COMPANIES_WITH_LEGAL_REPRESENTATIVES = [getCompanyProfileIds().EDP_BR];

// REDUX default state for client subtree
export const INITIAL_CLIENT = {
    [INPUT_NAMES.ID]: null,
    [INPUT_NAMES.CONTACTO_EMAIL]: null,
    [INPUT_NAMES.CONTACTO_NOME]: null,
    [INPUT_NAMES.CONTACTO_NUMERO]: null,
    [INPUT_NAMES.DESCRICAO]: null,
    [INPUT_NAMES.NIPC]: null,
    [INPUT_NAMES.PAIS_ID]: null,
    counters: null,
    loader: false,
    search: {
        ...DEFAULT_CLIENT_SEARCH,
    },
    loading: false,
    isRequesting: false,
    isRequestingCreateClient: false,
    hasSuccessfullyCreatedClient: false,
    isOpenClientCard: false,
    facilities: [],
    list: [],
};

//* ACTION TYPES *\\
export const FETCH_CLIENT = 'FETCH_CLIENT';
export const FETCH_CLIENT_SUCCESS = 'FETCH_CLIENT_SUCCESS';
export const SET_CLIENT = 'SET_CLIENT';
export const SET_CLIENT_B2C = 'SET_CLIENT_B2C';
export const SET_CLIENT_CAE5 = 'SET_CLIENT_CAE5';
export const RESET_CLIENT = 'RESET_CLIENT';
export const SET_IS_OPEN_CLIENT_CARD = 'SET_IS_OPEN_CLIENT_CARD';
export const REQUEST_GET_CLIENT = 'REQUEST_GET_CLIENT';
export const REQUEST_GET_CLIENT_SUCCESS = 'REQUEST_GET_CLIENT_SUCCESS';
export const REQUEST_GET_CLIENT_FAIL = 'REQUEST_GET_CLIENT_FAIL';
export const REQUEST_GET_CLIENT_DATA_BY_FACILITY = 'REQUEST_GET_CLIENT_DATA_BY_FACILITY';
export const REQUEST_GET_CLIENT_DATA_BY_FACILITY_SUCCESS = 'REQUEST_GET_CLIENT_DATA_BY_FACILITY_SUCCESS';
export const REQUEST_GET_CLIENT_DATA_BY_FACILITY_FAIL = 'REQUEST_GET_CLIENT_DATA_BY_FACILITY_FAIL';
export const REQUEST_POST_CREATE_CLIENT = 'REQUEST_POST_CREATE_CLIENT';
export const REQUEST_POST_CREATE_CLIENT_SUCCESS = 'REQUEST_POST_CREATE_CLIENT_SUCCESS';
export const REQUEST_POST_CREATE_CLIENT_FAIL = 'REQUEST_POST_CREATE_CLIENT_FAIL';
export const REQUEST_RESET_SUCCESSFULLY_CREATED_CLIENT = 'REQUEST_RESET_SUCCESSFULLY_CREATED_CLIENT';
export const REQUEST_PUT_CLIENT_INFO = 'REQUEST_PUT_CLIENT_INFO';
export const REQUEST_PUT_CLIENT_INFO_SUCCESS = 'REQUEST_PUT_CLIENT_INFO_SUCCESS';
export const REQUEST_PUT_CLIENT_INFO_FAIL = 'REQUEST_PUT_CLIENT_INFO_FAIL';
export const REQUEST_PUT_DISABLE_CLIENT = 'REQUEST_PUT_DISABLE_CLIENT';
export const REQUEST_PUT_DISABLE_CLIENT_SUCCESS = 'REQUEST_PUT_DISABLE_CLIENT_SUCCESS';
export const REQUEST_PUT_DISABLE_CLIENT_FAIL = 'REQUEST_PUT_DISABLE_CLIENT_FAIL';
export const SET_CLIENT_BY_TOKEN = 'SET_CLIENT_BY_TOKEN';
export const SET_CLIENT_FACILITIES = 'SET_CLIENT_FACILITIES';
export const RESET_CLIENT_FACILITIES = 'RESET_CLIENT_FACILITIES';
export const SET_CLIENT_HISTORY = 'SET_CLIENT_HISTORY';
