import { auth } from 'api/auth';
import { StatusCodes } from 'http-status-codes';
import { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { Nullable } from 'types/utils';
import { TTokenUser } from 'interfaces/user';
import { useDispatch } from 'react-redux';
import { useAuthStore } from 'store/auth';
import { getADFSUser } from 'api/user';
import { useHistory } from 'react-router';
import { notify } from 'services/@efz/notify';
import { isFieldDefined } from 'services/util/auxiliaryUtils';
import { resetState } from 'redux/actions/setting';
let adfsController: AbortController;

const useAuth = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [requesting, setRequesting] = useState(false);
    const adfsLogin = useAuthStore((state) => state.adfsLogin);
    const loginOK = useAuthStore((state) => state.loginOK);
    const [SSOUserOK, setSSOUserOK] = useState<Nullable<boolean>>(null);
    const [userTerms, setUserTerms] = useState<Nullable<any>>(null);

    const login = async (data: { username: string; password: string }) => {
        try {
            setRequesting(true);
            const authRsp = await auth(data);
            if (authRsp?.status === StatusCodes.OK) {
                const authData = authRsp.data.data;
                const decodedToken: TTokenUser = jwt_decode(authData.token);
                const responseShownterms = decodedToken.user.shows_terms ?? false;
                if (responseShownterms) {
                    setUserTerms(authData.token);
                    setRequesting(false);
                } else {
                    loginOK({ token: authData.token, dispatch, history, fieldToken: authData?.fieldToken ?? null });
                }
            } else {
                isFieldDefined(authRsp.data?.message) && notify(authRsp.data?.message, 'error');
                throw new Error();
            }
        } catch (error) {
            setRequesting(false);
        }
    };

    const checkADFSUser = async (user) => {
        try {
            setRequesting(true);
            adfsController && adfsController.abort();
            adfsController = new AbortController();
            const rspADFS = await getADFSUser(user, adfsController);

            if (rspADFS.status === StatusCodes.OK && rspADFS.data.login_by_sso) {
                adfsLogin();
            } else {
                setSSOUserOK(false);
            }

            setRequesting(false);
        } catch (err) {
            setRequesting(false);
            console.log(err);
        }
    };

    useEffect(() => {
        dispatch(resetState());
        return () => {
            setRequesting(false);
        };
    }, []); // eslint-disable-line

    return {
        requesting,
        login,
        userTerms,
        setUserTerms,
        SSOUserOK,
        setSSOUserOK,
        checkADFSUser,
    };
};

export default useAuth;
