import { SettingsActions } from 'constants/settings';
import { ISettingsAction, ISettingsState } from 'interfaces/settings';

export const initialSettingsState: ISettingsState = {
    isMoutingApp: true,
    featureFlags: [],
    hasNewVersion: false,
    platformLatestVersion: '1.0.0',
};

export const settingsReducer = (state = initialSettingsState, action: ISettingsAction) => {
    switch (action.type) {
        case SettingsActions.MOUNT_APP:
            return {
                ...state,
                isMoutingApp: action.payload.isMoutingApp,
                featureFlags: action.payload.featureFlags,
            };
        case SettingsActions.SET_HAS_NEW_VERSION:
            //colocamos o setTimeout para não apresentar a versão 1.0.0 no dialog de atualização
            if (!action.payload.hasNewVersion) setTimeout(() => localStorage.setItem('Platform-Version', '1.0.0'), 100);

            return {
                ...state,
                hasNewVersion: action.payload.hasNewVersion,
                platformLatestVersion: action?.payload?.platformLatestVersion ?? state.platformLatestVersion,
            };
        default:
            return state;
    }
};
