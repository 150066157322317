/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description Cache Busting
 * @see https://dev.to/flexdinesh/cache-busting-a-react-app-22lk
 *
 * @version 20201027
 * @since 20201027 Initial release
 *
 */
import { useEffect } from 'react';
import axios from 'services/@efz/axios';
import lazyLoader from 'services/lazyLoader';
import { isEmpty, isEmptyArray } from 'services/util/auxiliaryUtils';
import packageJson from '../../package.json';
global.appVersion = packageJson.version;

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);

    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());

        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) continue;
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
    }
    return false;
};

/**
 * refreshCacheAndReload
 * @description delete browser cache and hard reload
 *
 * @param {*} params
 */
const refreshCacheAndReload = async () => {
    //#region refreshCache
    if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then(async function (names) {
            if ((Array.isArray(names) && !isEmptyArray(names)) || !isEmpty(names))
                await Promise.all(names.map((name) => caches.delete(name)));
        });
    }
    //#endregion

    // reload with lazyLoader
    await lazyLoader(null, true);
};

const CacheBuster = ({ children, location }) => {
    //componentDidMountWithUseEffect
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            axios
                .create()
                .get('/meta.json', {})
                .then((response) => {
                    const { data } = response;
                    let latestVersion = data.version;
                    let currentVersion = global.appVersion;
                    localStorage.setItem('Platform-Version', latestVersion);
                    const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
                    if (shouldForceRefresh) {
                        refreshCacheAndReload();
                    }
                })
                .catch((error) => console.log('metaJson erro', error));
        }
    }, [location]); //eslint-disable-line

    return <>{children}</>;
};

export default CacheBuster;
