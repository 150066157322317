// Constants
export const solarpvOverlayColumnWidth = 328;

// Font Families
export const fontFamilyBase = 'Open Sans';
export const fontFamilyAccent = 'Poppins';

// Font Sizes
export const fontSize500 = '1.125rem';
export const fontSize400 = '1rem';
export const fontSize300 = '0.875rem';
export const fontSize200 = '0.75rem';
export const fontSize100 = '0.5rem';

// Font Weight
export const fontWeightLight = 300;
export const fontWeightNormal = 400;
export const fontWeightMedium = 500;
export const fontWeightSemibold = 600;
export const fontWeightBold = 700;
export const fontWeightBolder = 800;

// Line Heights
export const lineHeight500 = '2rem';
export const lineHeight400 = '1.5rem';
export const lineHeight300 = '1.313rem';
export const lineHeight200 = '1.125rem';
export const lineHeight100 = '1rem';

// Colors
export const colorBlack = 'black';
export const colorText = '#202020';
export const colorNeutral50 = '#FAFAFA';
export const colorNeutral100 = '#F5F5F5';
export const colorNeutral200 = '#E5E5E5';
export const colorNeutral300 = '#DCDCDC';
export const colorNeutral400 = '#C4C4C4';
export const colorNeutral600 = '#A3A3A3';
export const colorNeutral700 = '#484447';
export const colorNeutral800 = '#202020';
export const colorNeutral950 = '#0D0D0D';
export const colorSteel400 = '#949CA2';
export const colorSteel500 = '#79838B';
export const colorInfo = '#3885CD';
export const colorInfo50 = '#F1F8FF';
export const colorInfo200 = '#C3DAF0';
export const colorError = '#D0433C';

// Borders
export const borderNeutralE0 = '1px solid #e0e0e0';
export const borderNeutralE5 = '1px solid #e5e5e5';
export const borderNeutralC4 = '1px solid #c4c4c4';
export const borderNeutralC7 = '1px solid #c7c7c7';

export const borderPrimary = '1px solid var(--primary-color, #3885CD)';
export const borderDisabled = '1px solid var(--disabled-color, #B1B1B1)';
export const borderInfo = `1px solid ${colorInfo}`;

export const borderRadius400 = '0.3125rem';
