/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description Container to Footer
 *
 * @version 20191120
 * @since 20191120 Initial release
 *
 */

import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IntlMessages from 'components/util/IntlMessages';
import CookieConsent from 'components/Cookies/CookieConsent';
import CustomizeCookiesDialog from 'components/Cookies/CustomizeCookiesDialog';
import { routeToCookiePolicy } from 'services/cookies';
import { getProductId } from 'redux/selectors/product';
import { PRODUCT_IDS } from 'constants/products';
import { useMediaQuery } from '@mui/material';
import { StyledFooter } from 'pages/Restricted/shared/Footer/StylesFooter';

/**
 * @context Container to Footer
 *
 * @returns {*}
 * @constructor
 */
const Footer = (props) => {
    const { isUserAuthenticated } = props;
    const productID = useSelector((state) => getProductId(state?.product));

    const matches = useMediaQuery('(min-width:1366px)', { noSsr: true });
    let history = useHistory();

    const yearCurrent = useMemo(() => new Date().getFullYear(), []);
    const [showPolicy, setShowPolicy] = useState(false);
    const [showPrivacySettings, setShowPrivacySettings] = useState(false);
    const [policyTypeToShow, setPolicyTypeToShow] = useState(null);

    const showPolicyHandler = (type = null) => {
        if (type) {
            routeToCookiePolicy(history, type, isUserAuthenticated);
        } else {
            setShowPolicy(false);
        }
    };

    useEffect(() => {
        if (policyTypeToShow) {
            setShowPolicy(true);
        } else {
            setShowPolicy(false);
        }
    }, [policyTypeToShow]);

    useEffect(() => {
        if (!showPolicy) {
            setPolicyTypeToShow(null);
        }
    }, [showPolicy]);

    return (
        (![PRODUCT_IDS.SPV_SIMPLE].includes(productID) || ([PRODUCT_IDS.SPV_SIMPLE].includes(productID) && matches)) && (
            <>
                <StyledFooter>
                    <div className="container d-flex align-items-center justify-content-center">
                        <span className="d-inline-block">
                            &copy; <IntlMessages id="footer.copyright" values={{ year: yearCurrent, company: 'effizency' }} />
                        </span>
                        <div className="footer-policy-links h-100">
                            <span onClick={() => showPolicyHandler('use-conditions')}>
                                <IntlMessages id="footer.conditions" />
                            </span>
                            <span onClick={() => showPolicyHandler('privacy-policy')}>
                                <IntlMessages id="footer.privacyPolicy" />
                            </span>
                            <span onClick={() => showPolicyHandler('cookies-policy')}>
                                <IntlMessages id="footer.cookiesPolicy" />
                            </span>
                            <span onClick={() => setShowPrivacySettings(true)}>
                                <IntlMessages id="footer.privacySettings" />
                            </span>
                        </div>
                    </div>
                </StyledFooter>
                {showPolicy && <CookieConsent type={policyTypeToShow} close={showPolicyHandler} showPolicyHandler={showPolicyHandler} />}
                {showPrivacySettings && <CustomizeCookiesDialog isOpen={showPrivacySettings} setIsOpen={setShowPrivacySettings} />}
            </>
        )
    );
};

export default Footer;
