import { useState, useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Logo from 'components/common/NGHeader/Logo';
import { BASENAME_URL, BASENAME_URL_OAREA_PPC_B2B, BASENAME_URL_OAREA_PPC_B2C } from 'constants/settings';
import LangSwitcherCard from 'components/common/Header/LangSwitcherCard';
import { OAUserContext } from 'contexts/openArea/oaUserContext';
import { SPVSimpleContext } from 'contexts/products/solarpvSimple/solarpvSimpleContext';
import IntlMessages from 'components/util/IntlMessages';
import { useUserStore } from 'store/user';
import { StyledPPCHeader } from 'pages/Restricted/shared/Header/StylesHeader';

const OAHeader = ({ isB2c }) => {
    const [isOpenLangSwitcher, setIsOpenLangSwitcher] = useState(false);
    const { userLocale, locale } = useContext(OAUserContext);
    const { switchLanguage } = useUserStore();
    const handleSwitchLanguage = useCallback((locale) => switchLanguage(locale), []); //eslint-disable-line

    const {
        handleStartOver,
        spvSimpleState: { step },
    } = useContext(SPVSimpleContext);

    const OAreaLink = isB2c ? BASENAME_URL_OAREA_PPC_B2C : BASENAME_URL_OAREA_PPC_B2B;

    return (
        <StyledPPCHeader className={`${isB2c ? 'b2c' : 'b2b'} open-area ${step === 0 ? 'landing' : ''}`}>
            <Link
                to={`${BASENAME_URL}${OAreaLink}`}
                onClick={handleStartOver}
                /* onClick={() => gAnalyticsEvt(GA_LABEL_TOP_LEVEL_NAVIGATION.LOGO_HOMEPAGE, GA_TYPE_EVT.TOP_LEVEL_NAVIGATION)}  */
                className="h-100 d-flex align-items-center"
            >
                <Logo isAltLogo={step !== 0} showLogoEfz={false} />
            </Link>
            <span className="company-description ms-3">
                <IntlMessages id="page.spvPro.label.solarPV" />
            </span>
            <div className="header-menu h-100">
                <ul className="menu-list">
                    <li
                        className={`menu-item${isOpenLangSwitcher ? '-expanded' : ''} h-100`}
                        onClick={() => setIsOpenLangSwitcher(!isOpenLangSwitcher)}
                    >
                        <LangSwitcherCard
                            isOpen={isOpenLangSwitcher}
                            handleToggle={() => setIsOpenLangSwitcher(!isOpenLangSwitcher)}
                            handleSwitchLanguage={handleSwitchLanguage}
                            langCode={locale?.icon}
                            userLocale={userLocale}
                            uniqueItem={true}
                        />
                    </li>
                </ul>
            </div>
        </StyledPPCHeader>
    );
};

export default OAHeader;
