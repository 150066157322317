import { BASENAME_URL } from 'constants/settings';
import IntlMessages from 'components/util/IntlMessages';
import { useHistory } from 'react-router';
import { withTracker } from 'services/withTracker';
import { ReactComponent as BrokenLightBulb } from 'assets/images/icons/svg/broken-lightbulb.svg';
import { ReactComponent as BackgroundLeft } from 'assets/images/home/svg/left-graph.svg';
import { ReactComponent as BackgroundRight } from 'assets/images/home/svg/right-graph.svg';
import { ReactComponent as BackgroundBottom } from 'assets/images/home/svg/bottom-graph.svg';
import { Button } from '@save2compete/efz-design-system';
import { StyledErrorPage } from '../StylesErrorPage';

const Error404 = () => {
    const history = useHistory();

    return (
        <StyledErrorPage className="content-wrapper-fluid">
            <div className="ornaments-svg">
                <div className="ornament-left">
                    <BackgroundLeft />
                </div>
                <div className="ornament-bottom-container">
                    <div className="ornament-bottom">
                        <BackgroundBottom />
                    </div>
                </div>
                <div className="ornament-right">
                    <BackgroundRight />
                </div>
            </div>

            <section className="page404-content-container">
                <div className="content-container">
                    <div className="page404-content-image">
                        <BrokenLightBulb />
                    </div>
                    <div className="page404-content-text">
                        <h3 className="text-center mt-3">
                            <IntlMessages id="page.404.description.part1" />
                        </h3>
                        <span className="text-center">
                            <IntlMessages id="page.404.description.part2" />
                        </span>
                    </div>
                    <div className="page404-content-button">
                        <Button onClick={() => history.push(BASENAME_URL)} dataTestId="go-solutions-button">
                            <IntlMessages id="page.404.button.goSolutions" />
                        </Button>
                    </div>
                </div>
            </section>
        </StyledErrorPage>
    );
};

export default withTracker(Error404, 'Error 404');
