import { forwardRef, memo, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { arrayOf, bool, func, object, string } from 'prop-types';
import { useSelector } from 'react-redux';

import 'react-phone-input-2/lib/plain.css';
import OutlinedInput from '@mui/material/OutlinedInput';
import Skeleton from '@mui/material/Skeleton';
import { AVAILABLE_LOCALES } from 'constants/settings';

import { getLocale } from 'redux/selectors/settings';
import { generateRandomString, intlMessages, isFieldDefined } from 'services/util/auxiliaryUtils';
import { useUserStore } from 'store/user';
import { AVAILABLE_USER_LOCALES } from 'constants/user';
import { StyledPhoneInput } from './StylesPhoneInput';

/* 
    The library is missing some formats,
    to fix a specific format place it in this constant
*/
const CustomMasks = {
    pt: '... ... ...',
    gr: '... .......',
    de: '.... .........',
};
const InternalInputComponent = forwardRef((props, ref) => {
    const componentProps = props.component.props;
    const { name, onChange, id, error, setError, clearErrors, inputProps } = componentProps;

    const [formattedValue, setFormattedValue] = useState('');
    const [formattedPlaceholder, setFormattedPlaceholder] = useState('');
    const [hasMounted, setHasMounted] = useState(false);

    const onInputMount = (value, data) => {
        /* 
            Because some values are not correctly stored, they come with the wrong format.
            Values inputed via this component will always be in the correct format
        */
        if (componentProps?.value?.startsWith('+')) {
            // the value is correctly formated
            setFormattedValue(componentProps.value);
            setFormattedPlaceholder(getFormattedPlaceholder(data.format, data.dialCode));
            setHasMounted(true);
        } else {
            // the value has the wrong format
            // will try to guess the correct country or set as default
            // this is done inside 'isValid' method, since it is called at start and has access to all countries data
        }
    };

    const onInputChange = (value, data, event, formattedValue) => {
        if (event.type === 'click') {
            // on country change via dropdown
            // re-format the placeholder, to match the new country
            setFormattedPlaceholder(getFormattedPlaceholder(data.format, data.dialCode));
            // if the input was empty (showing the placeholder)
            // the value is changed to be the dial code of the newly selected country (by the library default behavior)
            // so we update the value to match
            if (value === data.dialCode) setFormattedValue(formattedValue);
        } else {
            isValid(value, {
                dialCode: data.dialCode,
                iso2: data.countryCode,
                format: data.format,
                forceValidation: true,
            });
        }

        // if its only the dial code, the form gets an empty string
        if (value === data.dialCode) onChange('', data, event, formattedValue);
        else onChange(formattedValue, data, event, formattedValue); // force the '+' on the value
    };

    const getFormattedPlaceholder = (placeholder, dialCode) => {
        const parts = placeholder.split(' ');
        // fill with dial code
        parts[0] = '+' + dialCode;

        // dummy numbers to fill placeholder
        // PT placeholder is wrong, so force the max of 12 nrs
        const totalNrDots = dialCode === '351' ? 9 : parts.slice(1, parts.length).join('').split('').length;
        const numbers = new Array(totalNrDots).fill(1).flatMap((val, index) => (val * (index + 1)) % 10);

        // swap placeholder's '.' with numbers from previous array
        let subparts;
        let currentIndex = 0;
        for (let i = 1; i < parts.length; i++) {
            subparts = parts[i].split()[0]?.split('');
            for (let y = 0; y < parts[i].length; y++) {
                if (currentIndex >= numbers.length)
                    //to deal with PT
                    subparts[y] = '';
                else if (subparts[y] === '.') {
                    subparts[y] = `${numbers[currentIndex++]}`;
                }
            }
            parts[i] = subparts.join('');
        }

        return parts.join(' ');
    };

    const isValid = (value, country) => {
        //#region initialValidation
        // initial validation to guess country
        if (!hasMounted && !country.forceValidation) {
            if (componentProps?.value?.startsWith(country?.dialCode)) {
                // the value is correctly formated, let onMount deal with it
                return true;
            }

            // check if value is empty
            if (componentProps.value === '') {
                // set as default country empty number
                const tempFormattedValue = country.dialCode + componentProps.value;
                setFormattedValue(tempFormattedValue);
                // onChange(tempFormattedValue)
                setFormattedPlaceholder(getFormattedPlaceholder(country.format, country.dialCode));
                setHasMounted(true);
                return true;
            }

            //#region guess
            /* 
            // extract dial codes for all countries
            const dialCodes = []
            countries.forEach(country => dialCodes.push(country.countryCode))

            // match initial value with dial codes
            const guesses = []
            for (let index = 0; index < dialCodes.length; index++) {
                if (componentProps.value.startsWith(dialCodes[index])) {
                    guesses.push(countries[index])
                }
            }

            // check validity of guess
            // and return the first that matches
            let params
            let gotGuess = false
            guesses.forEach(guess => {
                params = {
                    dialCode: guess.dialCode,
                    iso2: guess.iso2,
                    format: guess.format,
                    forceValidation: true
                }

                if (isValid(value, params)) {
                    //value was correctly guessed
                    setFormattedValue(componentProps.value)
                    onChange(componentProps.value)
                    setFormattedPlaceholder(getFormattedPlaceholder(guess.format, guess.dialCode))
                    setHasMounted(true)
                    gotGuess = true
                    return
                }
            })
            if (gotGuess) return true
            */
            //#endregion guess

            // if no guess is valid
            // the value from the BD is invalid
            // set as default user country with error
            const tempFormattedValue = country.dialCode + componentProps.value;
            setFormattedValue(tempFormattedValue);
            // onChange(tempFormattedValue)
            setFormattedPlaceholder(getFormattedPlaceholder(country.format, country.dialCode));
            setHasMounted(true);
            return false;
        }
        //#endregion initialValidation

        //#region onChange
        // validation on changes
        if (hasMounted && (value !== country.dialCode || country?.forceValidation)) {
            const startsWithValidCode = value?.startsWith(country.dialCode);
            const isOnlyCode = value === country.dialCode;

            // get nr of expected digits
            const parts = country.format.replace(/[+()-]/g, '').split(' ');
            const totalNrDigits = parts.join('').split('').length;

            // some phone formats are not correct on the library. TODO: create PR with fix
            let hasValidLength = false;
            switch (country.iso2) {
                case 'pt':
                case 'gr': {
                    hasValidLength = value.split('').length === 12;
                    break;
                }
                case 'it': {
                    // TODO:
                    // landline can have numbers 6 to 11 digits long
                    // mobile usualy have 10 digits but rarely can have 9
                    // libraby is only validating for mobile numbers with 10 digits
                    hasValidLength = value.split('').length === totalNrDigits;
                    break;
                }
                default:
                    hasValidLength = value.split('').length === totalNrDigits;
            }

            if (!hasValidLength && !isOnlyCode) {
                if (!error)
                    setError(name, {
                        type: 'invalidFormat',
                        message: 'page.user.phone.error.invalidFormat',
                    });
            }

            if ((hasValidLength || isOnlyCode) && startsWithValidCode) {
                if (error) {
                    clearErrors(name);
                }
            }

            return startsWithValidCode && hasValidLength;
        }
        //#endregion onChange

        return true;
    };

    return (
        <PhoneInput
            {...props.component.props}
            value={formattedValue}
            placeholder={formattedPlaceholder}
            onMount={onInputMount}
            onChange={onInputChange}
            className={id}
            isValid={isValid}
            defaultMask={'... ... ... ...'} // FIX: Input has maxlength=15 and default mask has 17 digits
            masks={CustomMasks}
            inputProps={{
                ...inputProps,
                ref,
            }}
        />
    );
});

const EFZPhoneInput = ({
    name,
    rules,
    control,
    error,
    setError,
    clearErrors,
    defaultValue,

    country,
    onlyCountries,
    preferredCountries,
    excludeCountries,
    inputProps,

    autoFormat,
    disabled,
    disableDropdown,
    disableCountryCode,
    enableAreaCode,
    enableTerritories,
    countryCodeEditable,
    enableSearch,
    disableSearchIcon,
    preserveOrder,

    searchPlaceholder,
    searchNotFoundPlaceholder,

    className,
    variant,

    defaultLocale,
}) => {
    const id = generateRandomString();
    const [key, setKey] = useState(0);

    const { user } = useUserStore();
    const userlocale = user?.locale ?? AVAILABLE_USER_LOCALES.enGB;
    const locale = useMemo(
        () => (Intl.Locale.prototype ? new Intl.Locale(isFieldDefined(defaultLocale) ? defaultLocale : userlocale) : defaultLocale),
        [userlocale, defaultLocale]
    );
    const defaultCountry = country === '' ? locale.region.toLocaleLowerCase() : country;

    //#region translations
    const pageLanguage = useSelector((state) => getLocale(state.settings));
    const [localization, setLocalization] = useState(null);

    const [translatedSearchPlaceholder, setTranslatedSearchPlaceholder] = useState(intlMessages(searchPlaceholder));
    const [translatedSearchNotFoundPlaceholder, setTranslatedSearchNotFoundPlaceholder] = useState(intlMessages(searchNotFoundPlaceholder));

    useEffect(() => {
        if (Object.keys(AVAILABLE_LOCALES).includes(locale.region)) {
            import(`react-phone-input-2/lang/${pageLanguage.locale}.json`)
                .then((imp) => {
                    setLocalization(imp);
                    setTranslatedSearchPlaceholder(intlMessages(searchPlaceholder));
                    setTranslatedSearchNotFoundPlaceholder(intlMessages(searchNotFoundPlaceholder));
                })
                .catch(() => {
                    // is english or its not supported by the library
                    // fallback to english
                    setLocalization('');
                    setTranslatedSearchPlaceholder(intlMessages(searchPlaceholder));
                    setTranslatedSearchNotFoundPlaceholder(intlMessages(searchNotFoundPlaceholder));
                });
        } else {
            // is not supported by the application
            // fallback to english
            setLocalization('');
            setTranslatedSearchPlaceholder(intlMessages(searchPlaceholder));
            setTranslatedSearchNotFoundPlaceholder(intlMessages(searchNotFoundPlaceholder));
        }

        setTimeout(() => {
            // must be set after Localization
            // forces component re-render to rebuild countries list in new language
            setKey((previous) => previous + 1);
        }, 100);
    }, [pageLanguage]); // eslint-disable-line react-hooks/exhaustive-deps
    //#endregion translations

    return (
        <StyledPhoneInput className={`${className}`}>
            {(localization || localization === '') && key > 0 ?
                <Controller
                    key={key}
                    name={name}
                    rules={rules}
                    control={control}
                    defaultValue={defaultValue}
                    variant={variant}
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    render={({ field: { ref, ...field } }) => {
                        return (
                            <OutlinedInput
                                error={!!error}
                                inputComponent={InternalInputComponent}
                                disabled={disabled}
                                inputProps={{
                                    component: (
                                        <PhoneInput
                                            id={id}
                                            {...field}
                                            name={name}
                                            error={error}
                                            setError={setError}
                                            clearErrors={clearErrors}
                                            country={defaultCountry}
                                            localization={localization}
                                            onlyCountries={onlyCountries}
                                            preferredCountries={preferredCountries}
                                            excludeCountries={excludeCountries}
                                            inputProps={inputProps}
                                            autoFormat={autoFormat}
                                            disabled={disabled}
                                            disableDropdown={disableDropdown}
                                            disableCountryCode={disableCountryCode}
                                            enableAreaCode={enableAreaCode}
                                            enableTerritories={enableTerritories}
                                            countryCodeEditable={countryCodeEditable}
                                            enableSearch={enableSearch}
                                            disableSearchIcon={disableSearchIcon}
                                            preserveOrder={preserveOrder}
                                            searchPlaceholder={translatedSearchPlaceholder}
                                            searchNotFound={translatedSearchNotFoundPlaceholder}
                                        />
                                    ),
                                }}
                            />
                        );
                    }}
                />
            :   <Skeleton variant="rounded" height={40} sx={{ width: '100%', borderRadius: '4px' }} />}
        </StyledPhoneInput>
    );
};

// default props
EFZPhoneInput.defaultProps = {
    error: null,
    defaultValue: '',

    country: '',
    onlyCountries: [],
    preferredCountries: ['pt', 'es', 'pl', 'it', 'br', 'ie', 'gr'],
    excludeCountries: ['mk'],
    inputProps: {},

    autoFormat: true,
    disabled: false,
    disableDropdown: false,
    disableCountryCode: false,
    enableAreaCode: false,
    enableTerritories: false,
    countryCodeEditable: false,
    enableSearch: true,
    disableSearchIcon: true,
    preserveOrder: ['onlyCountries', 'preferredCountries'],

    searchPlaceholder: 'page.user.phone.searchCountry',
    searchNotFoundPlaceholder: 'page.user.phone.error.searchCountry',

    className: '',
    variant: 'outlined',

    defaultLocale: '',
};

// prop types
EFZPhoneInput.propTypes = {
    name: string.isRequired,
    rules: object.isRequired,
    control: object.isRequired,
    error: object,
    setError: func.isRequired,
    clearErrors: func.isRequired,
    defaultValue: string,

    country: string,
    onlyCountries: arrayOf(string),
    preferredCountries: arrayOf(string),
    excludeCountries: arrayOf(string),
    inputProps: object,

    autoFormat: bool,
    disabled: bool,
    disableDropdown: bool,
    disableCountryCode: bool,
    enableAreaCode: bool,
    enableTerritories: bool,
    countryCodeEditable: bool,
    enableSearch: bool,
    disableSearchIcon: bool,
    preserveOrder: arrayOf(string),

    searchPlaceholder: string,
    searchNotFoundPlaceholder: string,

    className: string,
    variant: string,

    defaultLocale: string,
};

export default memo(EFZPhoneInput);
