//Libraries
import PropTypes from 'prop-types';
import IntlMessages from 'components/util/IntlMessages';
import { gAnalyticsEvt } from 'services/gAnalytics';
import { GA_LABEL_TOP_LEVEL_NAVIGATION, GA_TYPE_EVT } from 'constants/gAnalytics';
import { Button } from '@save2compete/efz-design-system';

/**
 * SectionInfo by Facility
 *
 * @param {*} param
 */
const SectionInfo = ({ title, subtitle, handlerClearSelection, handleGoTo, isActiveViewEdit }) => {
    return (
        <div className={`dropdown-header-section justify-content-between`}>
            <div className={`header-section-text ms-2`}>
                <h5 className="mb-0">{title}</h5>
                {subtitle && <h6 className="d-flex justify-content-between mb-0 mt-1">{subtitle}</h6>}
                <div className={'d-flex justify-content-between mb-0 btn-actions'}>
                    {/* Go To */}
                    <Button
                        variant="tertiary"
                        size="sm"
                        onClick={() => {
                            gAnalyticsEvt(GA_LABEL_TOP_LEVEL_NAVIGATION.FACILITY_DATA, GA_TYPE_EVT.TOP_LEVEL_NAVIGATION);
                            if (!isActiveViewEdit) handleGoTo();
                        }}
                        icon="startArrowRight"
                    >
                        <IntlMessages id="label.characterization" />
                    </Button>
                    {/* Clear facility */}
                    <Button
                        icon="startXMarkExit"
                        variant="tertiary"
                        size="sm"
                        onClick={() => {
                            gAnalyticsEvt(GA_LABEL_TOP_LEVEL_NAVIGATION.FACILITY_CLEAN_SELECTION, GA_TYPE_EVT.TOP_LEVEL_NAVIGATION);
                            handlerClearSelection();
                        }}
                    >
                        <IntlMessages id="label.clearSelection" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

SectionInfo.defaultProps = {
    title: 'loremIpsum',
};
SectionInfo.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    handlerClearSelection: PropTypes.func.isRequired,
};
export default SectionInfo;
