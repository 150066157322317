import styled from 'styled-components/macro';

export const StyledGenerateDocumentAuto = styled.div`
    font-size: 14px;
    &-label {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        line-height: 1.5rem;
        .loading-component {
            min-width: 30px;
            flex: none;
        }
        span {
            margin-left: 10px;
        }
    }
`;
