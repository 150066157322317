import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { ProductFruits } from 'react-product-fruits';
import { pbkdf2Sync } from 'pbkdf2';
import { jam } from '@jam.dev/sdk';

import Grid from '@mui/material/Grid';
import Restricted from '../pages/Restricted';
import Header from 'pages/Restricted/shared/Header';
import { getClient } from 'redux/selectors/client';
import { getFacilityInfo } from 'redux/selectors/facility';
import { getProductInfo } from 'redux/selectors/product';

// Constants
import { PRODUCT_IDS } from 'constants/products';

// Contexts
import { UploadImgContractProvider } from 'contexts/uploadImgContract';
import { FacilitiesProvider } from 'contexts/facilitiesContext';
import { FiltersProvider } from 'contexts/filtersContext';
import { UserProvider } from 'contexts/userContext';
import { SidebarProvider } from 'contexts/sidebarContext';
import { HeaderProvider } from 'contexts/headerContext';

// Open Area \\
import useSettings from 'hooks/useSettings';
import { ReduxProvider } from 'contexts/redux/reduxContext';
import { getCompanyProfileIds } from 'services/user';
import { isFieldDefined } from 'services/util/auxiliaryUtils';
import { useMediaQuery } from '@mui/material';
import { isEnvDevelopment } from 'services/settings';
import { useProjects } from 'store/solutions/projects';
import { useUserStore } from 'store/user';
import { useProposalStore } from 'store/proposal';
import { StyledApp } from 'pages/StylesApp';

const App = ({ match, client, facility, product }) => {
    const {
        img_contract_filename,
        actions: { resetAll: resetAllProposal },
    } = useProposalStore();
    const { user, LOCALE_CODE } = useUserStore();
    const userChangedPassword = user?.id ? user?.mudou_password : true;
    let { fetchThemeOnHandler } = useSettings();

    const locale = LOCALE_CODE ?? 'en';
    const isB2C = localStorage.getItem('salesSegment') === 'B2C';
    const isTabletOrMobile = useMediaQuery('(max-width:1200px)'); // disabled in tablet / mobile until api support is completed

    const hasProductFruitsEnabled = useMemo(() => {
        if (!isFieldDefined(locale)) return false;
        if (isTabletOrMobile) return false;
        if (!isB2C) return false;
        if (
            ![getCompanyProfileIds().PROFILE_BASE_UK, getCompanyProfileIds().EVOLO, getCompanyProfileIds().PROFILE_BASE_USA].includes(
                user?.perfil_empresa_id
            )
        )
            return false;

        return true;
    }, [isB2C, isTabletOrMobile, locale, user?.perfil_empresa_id]);

    // Checks if the product is enabled for the current facility
    const hasProductEnabled = (productId) =>
        isFieldDefined(user?.available_products_ids?.find((s) => Number(s) === Number(productId))) ?? false;

    const { selected: selectedProject } = useProjects();

    /* 
        ⚠️ ATTENTION ⚠️
        Only non-confidential info should be included, this is sent to a third-party app.
        For debugging purposes, only EFZ Demo Account's usernames are not hashed. 
        In theory, a faster algorithm could have been used, but taking only around 10ms, there is no point.
    */
    const userInfo =
        hasProductFruitsEnabled ?
            {
                username:
                    (
                        user?.username?.includes('efzdman') //isEfz
                    ) ?
                        user?.username
                    :   pbkdf2Sync(
                            user?.username,
                            process.env.REACT_APP_PBKF2_SALT,
                            parseInt(process.env.REACT_APP_PBKF2_ITERATIONS),
                            parseInt(process.env.REACT_APP_PBKF2_KEYLEN),
                            process.env.REACT_APP_PBKF2_DIGEST
                        ).toString(process.env.REACT_APP_PBKF2_ENCODING),
                props: {
                    idioma: user?.idioma,
                    negoceia_margem: user?.negoceia_margem,
                    empresa_id: user?.empresa_id,
                    is_demo: user?.is_demo,
                    acede_a_propostas: user?.acede_a_propostas,
                    mudou_password: user?.mudou_password,
                    ativo: user?.ativo,
                    grupo_canal_id: user?.grupo_canal_id,
                    parceiro_id: user?.parceiro_id,
                    supervisiona: user?.supervisiona,
                    tipo: user?.tipo,
                    pv_utilizadr_configs_id: user?.pv_utilizadr_configs_id,
                    utilizador_configs_id: user?.utilizador_configs_id,
                    perfil_configs_gerais_negociacao_id: user?.perfil_configs_gerais_negociacao_id,
                    perfil_gama_negociacao_id: user?.perfil_gama_negociacao_id,
                    perfil_menu_representacao_paineis_id: user?.perfil_menu_representacao_paineis_id,
                    perfil_visibilidade_processos_kpi_id: user?.perfil_visibilidade_processos_kpi_id,
                    tipo_utilizador_id: user?.tipo_utilizador_id,
                    is_efz: user?.is_efz,
                    has_spv: hasProductEnabled(PRODUCT_IDS.SPV),
                    has_hp: hasProductEnabled(PRODUCT_IDS.HP),
                    has_evc: hasProductEnabled(PRODUCT_IDS.EVC),
                    has_spv_sb: hasProductEnabled(PRODUCT_IDS.SPVSB),
                    has_evc_sb: hasProductEnabled(PRODUCT_IDS.EVCSB),
                    has_spv_simple: hasProductEnabled(PRODUCT_IDS.SPV_SIMPLE),
                    has_rr: hasProductEnabled(PRODUCT_IDS.RR),
                    has_spv_maintenance: hasProductEnabled(PRODUCT_IDS.SPV_MAINTENANCE),
                },
            }
        :   {};

    useEffect(() => {
        fetchThemeOnHandler();

        /**
         * This tries to fix an error from Sentry where ProductFruits' script (fetched from its CDN)
         * calls an internal function that is yet not defined.
         * I don't know in which situation this can happen, this is only a saffety net.
         */
        if (!isFieldDefined(window?.productFruits?.identifyUser)) {
            window.productFruits = {
                identifyUser: () => console.warn('ProductFruits is still loading...'),
            };
            // TODO: maybe force reload?
        }
    }, []); // eslint-disable-line

    /**
     * JAM data (for better JAM debugging)
     */
    jam.metadata(() => {
        return {
            environment: process.env.REACT_APP_ENV,
            sales_segment: localStorage.getItem('salesSegment'),
            user: {
                id: user?.id,
                name: user?.nome,
                username: user?.username,
                company_id: user?.empresa_id,
                company_profile_id: user?.perfil_empresa_id,
                language: user?.idioma,
                locale: user?.locale,
            },
            client: {
                id: client?.id,
                name: !isB2C ? client?.descricao : `${client?.nome_proprio} ${client?.apelido}`,
                nipc: client?.nipc,
            },
            facility: {
                id: facility?.id,
                cpe: facility?.cpe,
                address: !isB2C ? facility?.morada : facility?.location?.address,
                manual_address: !isB2C ? facility?.manual_address : facility?.location?.manual_address,
            },
            product: {
                id: product?.id,
                tag: product?.tp_tag,
                project: selectedProject,
            },
        };
    });

    //clean up img_contract_filename
    const keepProposalData = window.location.pathname
        .split('/')
        .some((pageName) =>
            ['business-models', 'proposal', 'reformulation', `${PRODUCT_IDS.SPV}`, `${PRODUCT_IDS.SPVSB}`].includes(pageName)
        );

    if (isFieldDefined(img_contract_filename) && !keepProposalData) resetAllProposal();

    return (
        <Grid container className="app-container">
            <StyledApp className="app-main-container">
                <ReduxProvider>
                    <UserProvider>
                        <HeaderProvider>
                            <SidebarProvider>
                                <FacilitiesProvider>
                                    <UploadImgContractProvider>
                                        <FiltersProvider>
                                            <Header />
                                            <Restricted match={match} userChangedPassword={userChangedPassword} />
                                            {hasProductFruitsEnabled ?
                                                <ProductFruits
                                                    workspaceCode={process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE_CODE}
                                                    language={`${locale}`}
                                                    user={userInfo}
                                                    debug={isEnvDevelopment()}
                                                />
                                            :   <></>}
                                        </FiltersProvider>
                                    </UploadImgContractProvider>
                                </FacilitiesProvider>
                            </SidebarProvider>
                        </HeaderProvider>
                    </UserProvider>
                </ReduxProvider>
            </StyledApp>
        </Grid>
    );
};

const mapStateToProps = ({ client, facility, product }) => {
    return {
        client: getClient(client),
        facility: getFacilityInfo(facility),
        product: getProductInfo(product),
    };
};
export default connect(mapStateToProps, {
    // userSignOut
})(App);
