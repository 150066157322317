import Dialog from '@mui/material/Dialog';
import { themeBase } from 'constants/styles/global';
import { styled } from 'styled-components';

export const StyledSalesSegmentDialog = styled(Dialog)`
    .MuiDialog-paperWidthSm {
        max-width: 560px;
        padding: 2rem 3.5rem;
    }

    .dialog-title {
        display: flex;
        flex-direction: column;

        .MuiDialogTitle-root {
            display: flex;
            justify-content: center;
            padding: 0;
            font-family: 'Poppins';
            font-weight: ${themeBase.fontWeight.light};
            color: var(--primary-color);
        }

        hr {
            position: relative;
            margin-top: 0.5rem;
            margin-bottom: 1.5rem;
            height: 1px;
            background-color: ${themeBase.color.info200};
        }

        .close-button {
            position: absolute;
            top: 1.5rem;
            right: 1.5rem;
        }
    }

    .dialog-content {
        padding: 0 !important;
        row-gap: 1.5rem;

        .MuiDialogContentText-root {
            text-align: center;
        }

        .MuiDialogActions-root {
            padding: 0;
            justify-content: center;
            gap: 40px;
        }
    }
`;
