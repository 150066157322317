import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

// Utilities
import FormatNumber from 'components/util/FormatNumber';
import IntlMessages from 'components/util/IntlMessages';
import { isDisabledDownloadProposal } from 'services/processes';
import { formatData, getParseNumber, intlMessages, isDefined, isFieldDefined, parseBoolean } from 'services/util/auxiliaryUtils';
import { PAYMENT_MODELS_IDS, PAYMENT_MODELS_TAGS } from './businessModels';
import { PRODUCT_IDS } from './products';
import { STATUS_CRM } from './proposal';

// Constants
import { GRUPO_CANAL_IDS, USERS_TYPE_ID } from 'constants/user';
import { getCompanyProfileIds, isUserManager } from 'services/user';
import { PROPOSAL_STATUS } from './efficiencyMeasures';

// Components
import TranslateFacility from 'components/@efz/TranslateFacility';
import Loading from 'components/core/Loading';
import TimestampToDate from 'components/core/TimestampToDate';

// SVG's
import { ReactComponent as CalendarIcon } from 'assets/images/icons/svg/calendar.svg';
import { ReactComponent as DetailsIcon } from 'assets/images/processes/details.svg';
import { ReactComponent as ProposalIcon } from 'assets/images/processes/download-docs.svg';
import { ReactComponent as UploadIcon } from 'assets/images/processes/upload.svg';
import { ReactComponent as DownloadIcon } from 'assets/images/processes/download.svg';
import { ReactComponent as ExportIcon } from 'assets/images/processes/export-to-excel.svg';
import { ReactComponent as FieldLogoIcon } from 'assets/images/processes/field-logo.svg';
import { ReactComponent as NoDataIcon } from 'assets/images/processes/no-data.svg';
import { ReactComponent as OwnerIcon } from 'assets/images/processes/owner.svg';
import { ReactComponent as PencilIcon } from 'assets/images/processes/pencil.svg';
import { ReactComponent as ReformulateIcon } from 'assets/images/processes/reformulate.svg';
import { ReactComponent as ReSendIcon } from 'assets/images/processes/resend.svg';
import { ReactComponent as SendToFieldIcon } from 'assets/images/processes/send-to-field.svg';
import { ReactComponent as SendIcon } from 'assets/images/processes/send.svg';
import { ReactComponent as CancelledStateIcon } from 'assets/images/processes/state-cancelled.svg';
import { ReactComponent as GrantedStateIcon } from 'assets/images/processes/state-granted.svg';
import { ReactComponent as NegotiatingStateIcon } from 'assets/images/processes/state-negotiating.svg';
import { ReactComponent as PendingStateIcon } from 'assets/images/processes/state-pending.svg';
import { ReactComponent as WonStateIcon } from 'assets/images/processes/state-won.svg';
import { ReactComponent as MeasureIcon } from 'assets/images/processes/tape-measure.svg';
import { ReactComponent as UpdateStateIcon } from 'assets/images/processes/update-state.svg';
import Tooltip from 'components/@efz/Tooltip';
import { GA_LABEL_PROCESS_LIST, GA_TYPE_EVT } from 'constants/gAnalytics';
import { gAnalyticsEvt } from 'services/gAnalytics';
import { isEnvDevFlag } from 'services/settings';
import { IconButton } from '@save2compete/efz-design-system';
import {
    StyledCheckIconCursorHelp,
    StyledDoubleCheckIconCursorHelp,
    StyledProcessTablePrice,
} from 'components/Processes/ProcessTable/StylesProcessTable';

// * DEFAULT OBJECTS * \\
export const INITIAL_PROCESSES_FILTERS = {
    limit: 50,
    page: 1,
    datefrom: null,
    dateto: null,
    fid: null, // Facility ID
    gcid: null, // Gestor de cliente ID
    gid: null, // Group ID
    gsid: null, // Gestor de serviços ID
    ntid: null, // Nível de tensão
    search: null,
    status_id: null,
    sid: null, // Segmento
    state: null, // Estado
    tpid: null, // Tipo de produto
    uid: null, // User ID
    cid: null, // Client
    oid: null, // Owner
    pid: null, // Partner
    gbm_ids: null,
    estado: null,
    send_status_crm: null,
};

export const PROCESSES_LOADING_ACTIONS = {
    DOWNLOAD_CSV: 'downloadCSV',
    OPEN_MODAL: 'openModal',
    DOWNLOAD_PROPOSAL: 'downloadProposal',
    UPLOAD_PROPOSAL: 'uploadProposal',
    SEND_TO_CRM: 'sendToCRM',
    DOWNLOAD_CONTRACT: 'downloadContract',
    UPLOAD_CONTRACT: 'uploadContract',
    REVERT_CANCEL_PROCESS: 'revertCancelProcess',
    CANCEL_PROCESS: 'cancelProcess',
    EDIT_OWNER: 'editOwner',
    DOWNLOAD_IMAGES: 'downloadImages',
};

export const PROCESS_STATE = {
    PENDING: {
        id: 1,
        tag: 'PENDING',
        icon: <PendingStateIcon />,
        color: 'rgba(249, 191, 59, 1)',
    },
    NEGOTIATING: {
        id: 2,
        tag: 'NEGOTIATING',
        icon: <NegotiatingStateIcon />,
        color: 'rgba(249, 191, 59, 1)',
    },
    GRANTED: {
        id: 3,
        tag: 'GRANTED',
        icon: <GrantedStateIcon />,
        color: 'rgba(62, 192, 156, 1)',
    },
    WON: {
        id: 4,
        tag: 'WON',
        icon: <WonStateIcon />,
        color: 'rgba(62, 192, 156, 1)',
    },
    CANCELLED: {
        id: 5,
        tag: 'CANCELLED',
        icon: <CancelledStateIcon />,
        color: 'rgba(208, 67, 60, 1)',
    },
};

export const substatus = {
    COMPETITION: 'COMPETITION',
    NOT_INTERESTED: 'NOT_INTERESTED',
    ANOTHER_BM: 'ANOTHER_BM',
    RISK: 'RISK',
    SITE_SURVEY: 'SITE_SURVEY',
    TEST: 'TEST',
    DUPLICATED: 'DUPLICATED',
    OTHER_REASON: 'OTHER_REASON',
};

const FIXED_INPUT_OPTIONS = {
    DIRECT_PAYMENT: 'DIRECT_PAYMENT',
    OPERATION_PAYMENT: 'OPERATION_PAYMENT',
    LEASING_PAYMENT: 'LEASING_PAYMENT',
    LEASING_ALBA_PAYMENT: 'LEASING_ALBA_PAYMENT',
};

// * Send to Field * \\
export const canSendToEffizencyField = (
    companyProfileId,
    userTypeID,
    productID,
    proposalStatus,
    paymentMethod,
    featureFlags,
    { segmento_descricao }
) => {
    if (companyProfileId === getCompanyProfileIds().EFZ) {
        return (
            [PRODUCT_IDS.SPV].includes(parseInt(productID)) &&
            isUserManager(userTypeID) &&
            [PAYMENT_MODELS_TAGS.UP_FRONT, PAYMENT_MODELS_TAGS.INSTALLMENTS].includes(paymentMethod)
        );
    }
    if (companyProfileId === getCompanyProfileIds().EDP_ES) {
        return [
            isUserManager(userTypeID) &&
                [PRODUCT_IDS.SPV, PRODUCT_IDS.EVC, PRODUCT_IDS.CFP].includes(parseInt(productID)) &&
                [PROCESS_STATE.PENDING.tag, PROCESS_STATE.NEGOTIATING.tag, PROCESS_STATE.GRANTED.tag].includes(proposalStatus),
        ];
    }
    return (
        [getCompanyProfileIds().EDP_PT, getCompanyProfileIds().EDP_PL, getCompanyProfileIds().EDP_IT].includes(companyProfileId) &&
        isUserManager(userTypeID) &&
        [
            PRODUCT_IDS.SPV,
            isEnvDevFlag(featureFlags['fe-1992'] && segmento_descricao !== 'BRONZE') ? PRODUCT_IDS.SPVSB : null,
            PRODUCT_IDS.EVC,
            PRODUCT_IDS.CFP,
            PRODUCT_IDS.RR,
        ].includes(parseInt(productID)) &&
        [PROCESS_STATE.PENDING.tag, PROCESS_STATE.NEGOTIATING.tag, PROCESS_STATE.GRANTED.tag].includes(proposalStatus)
    );
};

export const TABLE_COLUMNS_PROCESSES = [
    {
        accessor: 'state',
        Header: (
            <div className="w-100 text-center">
                <span>
                    <IntlMessages id={`label.state`} />
                </span>
            </div>
        ),
        minWidth: 50,
        width: 50,
        visible: (_ff) => true,
        Cell: ({ row, setUpdateProcessStatusData, userTypeID }) => {
            let icon;
            let canEditStatus = [USERS_TYPE_ID.MANAGER, USERS_TYPE_ID.CHANNEL].includes(userTypeID);
            let sentToField = !!row.original?.enviado_para_field;
            let reformulated = !!row.original?.nova_simulacao_id;

            switch (row.original.estado) {
                case PROCESS_STATE.PENDING.tag:
                    icon = PROCESS_STATE.PENDING.icon;
                    break;
                case PROCESS_STATE.NEGOTIATING.tag:
                    icon = PROCESS_STATE.NEGOTIATING.icon;
                    break;
                case PROCESS_STATE.GRANTED.tag:
                    icon = PROCESS_STATE.GRANTED.icon;
                    break;
                case PROCESS_STATE.WON.tag:
                    icon = PROCESS_STATE.WON.icon;
                    break;
                case PROCESS_STATE.CANCELLED.tag:
                    icon = PROCESS_STATE.CANCELLED.icon;
                    break;
                default:
                    icon = PROCESS_STATE.PENDING.icon;
                    break;
            }

            return (
                <div
                    className="d-flex w-100 justify-content-center"
                    onClick={canEditStatus ? () => setUpdateProcessStatusData(row.original) : () => {}}
                >
                    <Tooltip
                        title={
                            <span>
                                <IntlMessages id={'page.processes.state.' + row.original.estado} />
                                {sentToField && (
                                    <>
                                        {`: `}
                                        <IntlMessages id="page.processes.state.sentToField" />
                                    </>
                                )}
                                <br />
                                {reformulated && (
                                    <>
                                        <IntlMessages
                                            id="page.process.state.reformulatedProposal"
                                            values={{ id: row.original?.nova_simulacao_id }}
                                        />
                                    </>
                                )}
                            </span>
                        }
                    >
                        <div className={`process-state-tooltip${canEditStatus ? ' clickable' : ''} d-flex flex-column flex-nowrap`}>
                            {icon}
                            {sentToField && <FieldLogoIcon className="mt-1" />}
                        </div>
                    </Tooltip>
                </div>
            );
        },
    },
    {
        Header: (
            <div className="w-100">
                <span>Id</span>
            </div>
        ),
        minWidth: 60,
        width: 60,
        id: 'id',
        visible: (_ff) => true,
        accessor: (row) => <div className="text-break">{row.id}</div>,
    },
    {
        id: 'owner',
        Header: <IntlMessages id={'page.processes.column.owner'} />,
        minWidth: 140,
        width: 140,
        visible: (_ff) => true,
        accessor: (row) => {
            return (
                <div className="w-100 d-flex flex-column justify-content-start">
                    <div>{row.owner_name}</div>
                </div>
            );
        },
    },
    {
        id: 'Product',
        Header: <IntlMessages id={'label.product'} />,
        minWidth: 140,
        width: 140,
        visible: (_ff) => true,
        Cell: ({ row: { original: row }, companyProfileId }) => {
            let mpTag = null;
            let productTag = row.tp_tag;
            let productId = parseInt(row.tp_id);
            let pOpt = null;

            if (row.has_battery && [PRODUCT_IDS.SPV, PRODUCT_IDS.SPVSB].includes(productId))
                productId === PRODUCT_IDS.SPV ?
                    (productTag = 'SOLAR_PHOTOVOLTAIC_WITH_BATTERY')
                :   (productTag = 'SOLAR_PHOTOVOLTAIC_SMALL_BUSINESS_WITH_BATTERY');

            if (row.gmn_tag !== 'NOT_APPLICABLE') {
                mpTag = (
                    <span>
                        <IntlMessages id={`product.typeBusinessModel.${row.gmn_tag}`} />
                    </span>
                );
            }

            if ([getCompanyProfileIds().EDP_IT].includes(companyProfileId)) {
                if (
                    row?.variaveis_financeiras?.[0]?.paynent_type &&
                    Object.values(FIXED_INPUT_OPTIONS).includes(row?.variaveis_financeiras?.[0]?.paynent_type)
                ) {
                    pOpt = (
                        <span>
                            <IntlMessages id={`label.${row?.variaveis_financeiras?.[0]?.paynent_type}`} />
                        </span>
                    );
                }
            }

            return (
                <div className="w-100 d-flex flex-column justify-content-start">
                    <IntlMessages id={`product.name.${productTag}`} /> + <span>{mpTag}</span>
                    {!!pOpt && pOpt}
                </div>
            );
        },
    },
    {
        id: 'simulation_date',
        Header: <IntlMessages id={'label.dateSimulation'} />,
        minWidth: 100,
        width: 100,
        visible: (_ff) => true,
        accessor: (row) => <TimestampToDate timestamp={row.data_simulacao} format={'DD/MM/YYYY HH:mm'} />,
    },
    {
        id: 'price',
        Header: ({ companyProfileId, isB2C }) => {
            let tooltipText;
            if ([getCompanyProfileIds().EDP_BR].includes(companyProfileId)) {
                tooltipText = (
                    <>
                        <li className="m-0">
                            <strong>
                                <IntlMessages id="page.processes.table.tooltip.price.part1" />
                            </strong>{' '}
                            - <IntlMessages id="page.processes.table.tooltip.price.part1.1" />
                        </li>
                        <li className="m-0">
                            <strong>
                                <IntlMessages id="page.processes.table.tooltip.price.part4" />
                            </strong>{' '}
                            - <IntlMessages id="page.processes.table.tooltip.price.part4.1" />
                        </li>
                    </>
                );
            } else if (!isB2C) {
                tooltipText = (
                    <>
                        <li className="m-0">
                            <strong>
                                <IntlMessages id="page.processes.table.tooltip.price.part1" />
                            </strong>{' '}
                            - <IntlMessages id="page.processes.table.tooltip.price.part1.1" />
                        </li>
                        <li className="m-0">
                            <strong>
                                <IntlMessages id="page.processes.table.tooltip.price.part2" />
                            </strong>{' '}
                            - <IntlMessages id="page.processes.table.tooltip.price.part2.1" />
                        </li>
                        <li className="m-0">
                            <strong>
                                <IntlMessages id="page.processes.table.tooltip.price.part3" />
                            </strong>{' '}
                            - <IntlMessages id="page.processes.table.tooltip.price.part3.1" />
                        </li>
                        {[getCompanyProfileIds().EDP_PT].includes(companyProfileId) && (
                            <li className="m-0">
                                <strong>
                                    <IntlMessages id="page.processes.table.tooltip.price.LEC" />
                                </strong>{' '}
                                - <IntlMessages id="page.processes.table.tooltip.price.LEC.1" />
                            </li>
                        )}
                    </>
                );
            }

            return (
                <div className="d-flex align-items-center">
                    <IntlMessages id={'label.price'} />
                    {!isB2C && (
                        <Tooltip title={<StyledProcessTablePrice className="process-table-price">{tooltipText}</StyledProcessTablePrice>} />
                    )}
                </div>
            );
        },
        minWidth: 120,
        width: 120,
        accessor: 'price',
        visible: (_ff) => true,
        Cell: ({ row: { original }, companyProfileId }) => {
            let cell = <IconButton variant="tertiary" icon="minusSign" disabled dataTestId="processes-table-price" />;
            let infoIcon = null;
            let styleFormat = 'currency';
            let unit = null;
            let value = original?.preco_num ?? null;
            let numberOfDecimalPlaces = 0;
            let tooltipText = null;

            switch (original.mp_id) {
                case PAYMENT_MODELS_IDS.INSTALLMENTS:
                    tooltipText = (
                        <IntlMessages
                            id="label.moneyPerMonth"
                            values={{
                                M: original.numero_mensalidades_num,
                                P: <FormatNumber number={original.valor_mensalidade_num} numberOfDecimalPlaces={0} />,
                            }}
                        />
                    );
                    break;
                case PAYMENT_MODELS_IDS.SERVICE_PPA:
                    styleFormat = 'decimal';
                    unit = 'cmwp';
                    break;
                case PAYMENT_MODELS_IDS.LEC:
                case PAYMENT_MODELS_IDS.PAY_AS_PRODUCED:
                case PAYMENT_MODELS_IDS.SERVICE_SECOND_VERSION: {
                    let isSPVSB = parseInt(original?.tp_id) === PRODUCT_IDS.SPVSB;
                    styleFormat = 'currency';
                    value = isSPVSB ? original?.preco_num : getParseNumber(original?.variaveis_financeiras?.[0]?.price) ?? null;
                    break;
                }
                case PAYMENT_MODELS_IDS.PARTNERSHIP:
                    value = null;
                    break;
                default:
                    styleFormat = 'currency';
                    break;
            }

            if (isDefined(tooltipText)) {
                infoIcon = <Tooltip title={tooltipText} />;
            }

            if ([getCompanyProfileIds().ROMANDE].includes(companyProfileId)) {
                numberOfDecimalPlaces = 2;
                unit = 'cypw';
                styleFormat = 'decimal';
            }

            if (getParseNumber(value) > 0) {
                cell = (
                    <>
                        <FormatNumber styleFormat={styleFormat} unit={unit} number={value} numberOfDecimalPlaces={numberOfDecimalPlaces} />
                        {infoIcon}
                    </>
                );
            }
            return cell;
        },
    },
    {
        accessor: 'client',
        Header: <IntlMessages id={'label.client'} />,
        minWidth: 160,
        width: 160,
        visible: (_ff) => true,
        Cell: ({ row, getRowClient, isRequestingRedirection }) => {
            return (
                <div className="w-100 d-flex flex-column justify-content-start">
                    <div
                        className={`redirect-option${isRequestingRedirection ? '-disabled' : ''}`}
                        onClick={() => getRowClient(row?.original?.cid)}
                    >
                        <div>{row?.original?.descricao_cliente}</div>
                        <div>{row?.original?.nipc}</div>
                    </div>
                </div>
            );
        },
    },
    {
        accessor: 'facility',
        Header: <TranslateFacility styleLetter="Capitalize" />,
        minWidth: 190,
        width: 190,
        visible: (_ff) => true,
        Cell: ({ row, getRowFacility, isRequestingRedirection }) => {
            return (
                <div className="w-100 d-flex flex-column justify-content-start">
                    <div
                        className={`redirect-option${isRequestingRedirection ? '-disabled' : ''}`}
                        onClick={() => getRowFacility(row?.original?.cid, row?.original?.fid)}
                    >
                        <div>{row?.original?.morada}</div>
                        <div>{row?.original?.cpe}</div>
                    </div>
                </div>
            );
        },
    },
    {
        id: 'Description',
        Header: <IntlMessages id={'label.description'} />,
        minWidth: 140,
        width: 140,
        visible: (visible) => visible,
        accessor: (row) => {
            return isFieldDefined(row.descricao) ?
                    <div className="w-100 d-flex flex-column justify-content-start">
                        <Tooltip
                            placement="top"
                            type="default"
                            title={row.descricao.split('\n').map((str, index) => (
                                <p key={index}>{str}</p>
                            ))}
                        >
                            <div className="tolltip-option">
                                {row.descricao.split('\n').map((str, index) => (
                                    <p key={index}>{str}</p>
                                ))}
                            </div>
                        </Tooltip>
                    </div>
                :   <div className="w-100 d-flex flex-column justify-content-start">
                        <div> - </div>
                    </div>;
        },
    },
    {
        accessor: 'details',
        Header: (
            <div className="d-flex w-100 justify-content-center text-center">
                <IntlMessages id="label.details" />
            </div>
        ),
        minWidth: 80,
        width: 80,
        className: 'align-items-center',
        visible: (_ff) => true,
        Cell: ({ row, setEditDialogData }) => {
            let value = null;
            let iconDetails = null;
            let isSPVSB = parseInt(row?.original?.tp_id) === PRODUCT_IDS.SPVSB;
            switch (row.original.mp_id) {
                case PAYMENT_MODELS_IDS.LEC:
                case PAYMENT_MODELS_IDS.PAY_AS_PRODUCED:
                case PAYMENT_MODELS_IDS.SERVICE_SECOND_VERSION:
                    value =
                        isSPVSB ?
                            parseFloat(row?.original?.tariff_num) ?? null
                        :   getParseNumber(row?.original?.variaveis_financeiras?.[0]?.price);
                    break;
                default:
                    value = row?.original?.preco_num ?? null;
                    break;
            }

            let disabled = !(getParseNumber(value) > 0) || row.original.estado === 'CANCELADA';

            /* DETAILS */
            iconDetails = (
                <div
                    className={`d-flex flex-fill align-items-center justify-content-center process-details-button${disabled ? '-disabled' : ''}`}
                >
                    <div className="d-flex align-items-center position-relative justify-content-center">
                        <DetailsIcon
                            onClick={() => {
                                gAnalyticsEvt(GA_LABEL_PROCESS_LIST.PROCESS_DETAILS, GA_TYPE_EVT.PROCESS_LIST);
                                setEditDialogData(row.original);
                            }}
                            role="button"
                        />
                    </div>
                </div>
            );

            return <div className="d-flex w-100 justify-content-center">{iconDetails}</div>;
        },
    },
    {
        accessor: 'proposal',
        Header: (
            <div className="d-flex w-100 justify-content-center text-center">
                <IntlMessages id="label.proposal" />
            </div>
        ),
        minWidth: 80,
        fixed: 'right',
        width: 80,
        className: 'align-items-center sticky col3',
        visible: (_ff) => true,
        Cell: ({ row, downloadFilesHandler, userCanAccessProposals, isRequestingRedirection }) => {
            let iconsProposal = null;

            /* PROPOSAL */
            const disabledDownload = isDisabledDownloadProposal(row, userCanAccessProposals);
            iconsProposal = (
                <div
                    className={`d-flex flex-fill align-items-center justify-content-center process-action-button${disabledDownload || isRequestingRedirection ? '-disabled' : ''}`}
                >
                    <div className="d-flex align-items-center position-relative justify-content-center">
                        {!row.original.downloadProposalLoading ?
                            <ProposalIcon
                                onClick={() => {
                                    gAnalyticsEvt(GA_LABEL_PROCESS_LIST.DOWNLOAD_PROPOSAL, GA_TYPE_EVT.PROCESS_LIST);
                                    downloadFilesHandler(row.original.id, PROCESSES_LOADING_ACTIONS.DOWNLOAD_PROPOSAL);
                                }}
                                role={disabledDownload ? '' : 'button'}
                            />
                        :   <Loading size={24} />}
                    </div>
                </div>
            );

            return iconsProposal;
        },
    },
    {
        accessor: 'crm',
        Header: (
            <div className="d-flex w-100 justify-content-center text-center">
                <IntlMessages id="label.statusCRM" />
            </div>
        ),
        minWidth: 80,
        width: 80,
        className: 'align-items-center sticky col2',
        visible: (_ff) => true,
        Cell: ({ row, postSendToCRM, isRequestingRedirection }) => {
            let iconStatusCRM = null;
            let isDisabledCRM = row.original.estado === 'CANCELADA' || row.original?.disabledSendCRM || isRequestingRedirection;
            let { status_crm } = row.original;
            let extraClassName = '';

            if (row.original.can_send_to_crm) {
                switch (status_crm) {
                    case STATUS_CRM.NULL:
                        extraClassName = `process-action-button${isDisabledCRM ? '-disabled' : ''}`;
                        iconStatusCRM = (
                            <SendIcon
                                role="button"
                                onClick={() => {
                                    gAnalyticsEvt(GA_LABEL_PROCESS_LIST.SEND_TO_CRM, GA_TYPE_EVT.PROCESS_LIST);
                                    postSendToCRM({ id: row.original.id });
                                }}
                            />
                        );
                        break;
                    case STATUS_CRM.ABORTED:
                        extraClassName = `process-action-button${isDisabledCRM ? '-disabled' : ''}`;
                        iconStatusCRM = (
                            <Tooltip
                                title={intlMessages(`label.stateCRM.ABORTED`, {
                                    date: formatData(row.original.data_envio_para_crm),
                                })}
                            >
                                <ReSendIcon
                                    role="button"
                                    onClick={() => {
                                        gAnalyticsEvt(GA_LABEL_PROCESS_LIST.SEND_TO_CRM, GA_TYPE_EVT.PROCESS_LIST);
                                        postSendToCRM({ id: row.original.id });
                                    }}
                                />
                            </Tooltip>
                        );
                        break;
                    default:
                        iconStatusCRM = <NoDataIcon />;
                        break;
                }
            } else {
                switch (status_crm) {
                    case STATUS_CRM.COMPLETED:
                        iconStatusCRM = (
                            <Tooltip
                                title={intlMessages(`label.stateCRM.COMPLETED`, {
                                    date: formatData(row.original.data_envio_para_crm),
                                })}
                            >
                                <StyledDoubleCheckIconCursorHelp className="cursor-help" />
                            </Tooltip>
                        );
                        break;
                    case STATUS_CRM.SUBMITTED:
                        iconStatusCRM = (
                            <Tooltip
                                title={
                                    <IntlMessages
                                        id="label.stateCRM.SUBMITTED"
                                        values={{ date: formatData(row.original.data_envio_para_crm) }}
                                    />
                                }
                            >
                                <StyledCheckIconCursorHelp className="cursor-help" />
                            </Tooltip>
                        );
                        break;
                    case STATUS_CRM.ABORTED:
                        iconStatusCRM = (
                            <Tooltip
                                title={
                                    <IntlMessages
                                        id="label.stateCRM.ABORTED"
                                        values={{ date: formatData(row.original.data_envio_para_crm) }}
                                    />
                                }
                            >
                                <ReSendIcon
                                    role="button"
                                    onClick={() => {
                                        gAnalyticsEvt(GA_LABEL_PROCESS_LIST.SEND_TO_CRM, GA_TYPE_EVT.PROCESS_LIST);
                                        postSendToCRM({ id: row.original.id });
                                    }}
                                />
                            </Tooltip>
                        );
                        break;
                    default:
                        iconStatusCRM = <NoDataIcon />;
                        break;
                }
            }

            return (
                <div className={`d-flex flex-fill align-items-center justify-content-center ${extraClassName}`}>
                    <div className="d-flex align-items-center position-relative justify-content-center">
                        {!row.original.loadingSendCRM ? iconStatusCRM : <Loading size={24} />}
                    </div>
                </div>
            );
        },
    },
    {
        accessor: 'actions',
        Header: (
            <div className="d-flex w-100 justify-content-center">
                <IntlMessages id="label.actions" />
            </div>
        ),
        minWidth: 70,
        width: 70,
        className: 'align-items-center sticky col1',
        visible: (_ff) => true,
        Cell: ({
            row,
            userTypeID,
            downloadFilesHandler,
            companyProfileId,
            setUploadDocsData,
            setEditOwnerData,
            setEditDescriptionData,
            handleEditProposal,
            setUpdateProcessStatusData,
            isUpdatingProcess,
            setSendToFieldData,
            handleSendLinkAR,
            handleGoToReformulationPage,
            isRequestingRedirection,
            negotiationProfile,
            getAuditFilesHandler,
            setBookMeetingDialogOpen,
            setBookMeetingDetails,
            featureFlags,
        }) => {
            let actions = null;
            let disabledUploadButton = getParseNumber(row.original.preco_num) <= 0 && getParseNumber(row.original?.tariff_num) <= 0;
            let disabledDownloadImagesButton = !((
                !!row?.original?.tem_fich_inputs_uploads && typeof row.original.tem_fich_inputs_uploads === 'boolean'
            ) ?
                row.original.tem_fich_inputs_uploads
            :   parseBoolean(row.original.tem_fich_inputs_uploads));
            let editProposal = canEditProposal(companyProfileId, userTypeID, {
                productID: row.original.tp_id,
                canEdit: row?.original?.permite_edicao ?? false,
            });
            let canEditOwner = CAN_EDIT_OWNER?.includes(userTypeID);
            let canEditStatus = [USERS_TYPE_ID.MANAGER, USERS_TYPE_ID.CHANNEL].includes(userTypeID);
            let canSendToField = canSendToEffizencyField(
                companyProfileId,
                userTypeID,
                parseInt(row.original.tp_id),
                row.original.estado,
                row.original.gmn_tag,
                featureFlags,
                { segmento_descricao: row.original?.segmento_descricao }
            );
            let canReformulateProposal = isDefined(row.original.pode_reformular);
            let canAuditProposal = negotiationProfile?.audita_proposta;

            const disabledDownload =
                !row.original.nome_fich_proposta_assinada ||
                row.original.estado === PROPOSAL_STATUS.CANCELADA ||
                row.original.downloadContractLoading ||
                isRequestingRedirection;

            if (row.original.estado === PROPOSAL_STATUS.CANCELADA) {
                actions = canEditStatus && (
                    <DropdownItem
                        className={`dropdown-item`}
                        onMouseDown={() => {
                            gAnalyticsEvt(GA_LABEL_PROCESS_LIST.UPDATE_STATUS, GA_TYPE_EVT.PROCESS_LIST);
                            setUpdateProcessStatusData(row.original);
                        }}
                    >
                        <div className="d-flex flex-row justify-content-start">
                            <div className="d-flex align-items-center me-2 icon-container">
                                <UpdateStateIcon />
                            </div>
                            <span className="d-flex align-items-center justify-content-center options-text">
                                <h5>
                                    <IntlMessages id="label.updateState" />
                                </h5>
                            </span>
                        </div>
                    </DropdownItem>
                );
            } else {
                actions = (
                    <>
                        {/* UPDATE STATE */}
                        {canEditStatus && (
                            <DropdownItem
                                className={`dropdown-item`}
                                onMouseDown={() => {
                                    gAnalyticsEvt(GA_LABEL_PROCESS_LIST.UPDATE_STATUS, GA_TYPE_EVT.PROCESS_LIST);
                                    setUpdateProcessStatusData(row.original);
                                }}
                            >
                                <div className="d-flex flex-row justify-content-start">
                                    <div className="d-flex align-items-center me-2 icon-container">
                                        <UpdateStateIcon className="icon-upd-state" />
                                    </div>
                                    <span className="d-flex align-items-center justify-content-center options-text">
                                        <h5>
                                            <IntlMessages id="label.updateState" />
                                        </h5>
                                    </span>
                                </div>
                            </DropdownItem>
                        )}

                        {
                            /* EDIT PROPOSAL */
                            editProposal && (
                                <DropdownItem
                                    className={`dropdown-item${row.original?.is_quick_proposal ? '-disabled' : ''}`}
                                    onClick={() => handleEditProposal({ ...row?.original, userTypeID })}
                                    disabled={row.original?.is_quick_proposal}
                                >
                                    <div className="d-flex flex-row justify-content-start">
                                        <div className="d-flex align-items-center me-2 icon-container">
                                            <PencilIcon className="icon-edit-proposal" />
                                        </div>
                                        <span className="d-flex align-items-center justify-content-center options-text">
                                            <h5>
                                                <IntlMessages id="page.processes.label.editProposal" />
                                            </h5>
                                            {row.original?.is_quick_proposal && (
                                                <Tooltip
                                                    title={<IntlMessages id="page.processes.editProposal.quickTeaserTooltip" />}
                                                    className="quick-teaser-tooltip"
                                                />
                                            )}
                                        </span>
                                    </div>
                                </DropdownItem>
                            )
                        }

                        {
                            /* EDIT Description */
                            row.original.has_proposal_description_feature && (
                                <DropdownItem
                                    className={`dropdown-item`}
                                    onClick={() => {
                                        gAnalyticsEvt(GA_LABEL_PROCESS_LIST.EDIT_DESCRIPTION, GA_TYPE_EVT.PROCESS_LIST);
                                        setEditDescriptionData(row.original);
                                    }}
                                >
                                    <div className="d-flex flex-row justify-content-start">
                                        <div className="d-flex align-items-center me-2 icon-container">
                                            <PencilIcon className="icon-edit-proposal" />
                                        </div>
                                        <div className="d-flex flex-column justify-content-center align-items-start">
                                            <span className="d-flex align-items-center justify-content-center options-text">
                                                <h5>
                                                    <IntlMessages id="page.processes.label.editDescription" />
                                                </h5>
                                            </span>
                                        </div>
                                    </div>
                                </DropdownItem>
                            )
                        }

                        {/* REFORMULATE FIELD PROPOSAL */}
                        {canReformulateProposal && (
                            <DropdownItem
                                className={`dropdown-item${!row.original?.pode_reformular ? '-disabled' : ''}`}
                                onClick={() => handleGoToReformulationPage(row?.original)}
                                disabled={!row.original?.pode_reformular}
                            >
                                <div className="d-flex flex-row justify-content-start">
                                    <div className="d-flex align-items-center me-2 icon-container">
                                        <ReformulateIcon className="icon-reformulate" />
                                    </div>
                                    <span className="d-flex align-items-center justify-content-center options-text">
                                        <h5>
                                            <IntlMessages id="page.processes.reformulateProposal" />
                                        </h5>
                                    </span>
                                </div>
                            </DropdownItem>
                        )}

                        {
                            /* SEND TO FIELD */
                            canSendToField && (
                                <DropdownItem
                                    className={`dropdown-item${row.original?.enviado_para_field ? '-disabled' : ''}`}
                                    onClick={() => {
                                        gAnalyticsEvt(GA_LABEL_PROCESS_LIST.SEND_TO_FIELD, GA_TYPE_EVT.PROCESS_LIST);
                                        setSendToFieldData({
                                            id: row.original.id,
                                            gs_nome: row.original.gs_nome,
                                            gs_email: row.original.gs_email,
                                        });
                                    }}
                                >
                                    <div className="d-flex flex-row justify-content-start">
                                        <div className="d-flex align-items-center me-2 icon-container">
                                            <SendToFieldIcon className="icon-send-field" />
                                        </div>
                                        <span className="d-flex align-items-center justify-content-center options-text">
                                            <h5>
                                                <IntlMessages id="page.processes.sendToField" />
                                            </h5>
                                        </span>
                                    </div>
                                </DropdownItem>
                            )
                        }

                        {
                            /* EDIT OWNER */
                            canEditOwner && (
                                <DropdownItem
                                    className={`dropdown-item`}
                                    onClick={() => {
                                        gAnalyticsEvt(GA_LABEL_PROCESS_LIST.EDIT_OWNER, GA_TYPE_EVT.PROCESS_LIST);
                                        setEditOwnerData(row.original);
                                    }}
                                >
                                    <div className="d-flex flex-row justify-content-start">
                                        <div className="d-flex align-items-center me-2 icon-container">
                                            <OwnerIcon className="icon-owner" />
                                        </div>
                                        <div className="d-flex flex-column justify-content-center align-items-start">
                                            <span className="d-flex align-items-center justify-content-center options-text">
                                                <h5>
                                                    <IntlMessages id="label.editOwner" />
                                                </h5>
                                            </span>
                                        </div>
                                    </div>
                                </DropdownItem>
                            )
                        }

                        {/* BOOK MEETING */}
                        {!!row.original?.enable_booking && (
                            <DropdownItem
                                className={`dropdown-item`}
                                onClick={() => {
                                    gAnalyticsEvt(GA_LABEL_PROCESS_LIST.BOOK_MEETING, GA_TYPE_EVT.PROCESS_LIST);
                                    setBookMeetingDialogOpen(true);
                                    setBookMeetingDetails({
                                        presencialMeetingLink: row.original?.link_booking_presencial,
                                        remoteMeetingLink: row.original?.link_booking_remoto,
                                    });
                                }}
                            >
                                <div className="d-flex flex-row justify-content-start">
                                    <div className="d-flex align-items-center me-2 icon-container">
                                        <CalendarIcon className="icon-book-meeting" />
                                    </div>
                                    <div className="d-flex flex-column justify-content-center align-items-start">
                                        <span className="d-flex align-items-center justify-content-center options-text">
                                            <h5>
                                                <IntlMessages id="label.bookMeeting" />
                                            </h5>
                                        </span>
                                    </div>
                                </div>
                            </DropdownItem>
                        )}

                        {
                            /* UPLOAD SUPPORT DOCS */
                            !disabledUploadButton && (
                                <DropdownItem
                                    className={`dropdown-item`}
                                    onMouseDown={() => {
                                        gAnalyticsEvt(GA_LABEL_PROCESS_LIST.UPLOAD_DOCUMENTS, GA_TYPE_EVT.PROCESS_LIST);
                                        setUploadDocsData(row.original);
                                    }}
                                >
                                    <div className="d-flex flex-row justify-content-start">
                                        <div className="d-flex align-items-center me-2 icon-container">
                                            <UploadIcon className="icon-upload-docs" />
                                        </div>
                                        <div className="d-flex flex-column justify-content-center align-items-start">
                                            <span className="d-flex align-items-center justify-content-center options-text">
                                                <h5>
                                                    <IntlMessages id="label.uploadSuppDocs" />
                                                </h5>
                                            </span>
                                        </div>
                                    </div>
                                </DropdownItem>
                            )
                        }

                        <>
                            {
                                /* DOWNLOAD PHOTOS */
                                <DropdownItem
                                    className={`dropdown-item`}
                                    disabled={disabledDownloadImagesButton}
                                    onMouseDown={() => {
                                        // gAnalyticsEvt(GA_LABEL_PROCESS_LIST.DOWNLOAD_IMAGES, GA_TYPE_EVT.PROCESS_LIST);
                                        downloadFilesHandler(row.original.id, PROCESSES_LOADING_ACTIONS.DOWNLOAD_IMAGES);
                                    }}
                                >
                                    <div className="d-flex flex-row justify-content-start">
                                        <div className="d-flex align-items-center me-2 icon-container">
                                            <DownloadIcon className="icon-signed-contract" />
                                        </div>
                                        <div className="d-flex flex-column justify-content-center align-items-start">
                                            <span className="d-flex align-items-center justify-content-center options-text">
                                                <h5>
                                                    <IntlMessages id="label.downloadImages" />
                                                </h5>
                                            </span>
                                        </div>
                                    </div>
                                </DropdownItem>
                            }
                        </>

                        {
                            /* EXPORT TO EXCEL */
                            [USERS_TYPE_ID.MANAGER].includes(userTypeID) &&
                                [PRODUCT_IDS.SPV, PRODUCT_IDS.SPVSB].includes(parseInt(row.original.tp_id)) && (
                                    <DropdownItem
                                        className={`dropdown-item`}
                                        onClick={() => {
                                            gAnalyticsEvt(GA_LABEL_PROCESS_LIST.EXPORT_XLS, GA_TYPE_EVT.PROCESS_LIST);
                                            downloadFilesHandler(row.original.id, PROCESSES_LOADING_ACTIONS.DOWNLOAD_CSV);
                                        }}
                                    >
                                        <div className="d-flex flex-row justify-content-start">
                                            <div className="d-flex align-items-center me-2 icon-container">
                                                <ExportIcon className="icon-export-excel" />
                                            </div>
                                            <span className="d-flex align-items-center justify-content-center options-text">
                                                <h5>
                                                    <IntlMessages id="label.exportData" />
                                                </h5>
                                            </span>
                                        </div>
                                    </DropdownItem>
                                )
                        }
                        {
                            /* DOWNLOAD AUDIT FILES */
                            canAuditProposal && isDefined(row.original?.log_sizing_simulador_pedido_id_arr) && (
                                <DropdownItem
                                    className={`dropdown-item`}
                                    onClick={() => {
                                        getAuditFilesHandler(row.original.id, row.original?.log_sizing_simulador_pedido_id_arr);
                                    }}
                                >
                                    <div className={`d-flex flex-row justify-content-start`}>
                                        <div className="d-flex align-items-center me-2 icon-container">
                                            {!row.original.auditFilesLoading ?
                                                <DownloadIcon className="icon-signed-contract" />
                                            :   <Loading size={24} />}
                                        </div>
                                        <span className="d-flex align-items-center justify-content-center options-text">
                                            <h5>
                                                <IntlMessages id="page.businessModels.auditProposal" />
                                            </h5>
                                        </span>
                                    </div>
                                </DropdownItem>
                            )
                        }
                        {
                            /* DOWNLOAD CONTRACT */
                            !disabledDownload && (
                                <DropdownItem
                                    className={`dropdown-item`}
                                    onClick={() => {
                                        gAnalyticsEvt(GA_LABEL_PROCESS_LIST.DOWNLOAD_SIGNED_CONTRACT, GA_TYPE_EVT.PROCESS_LIST);
                                        downloadFilesHandler(row.original.id, PROCESSES_LOADING_ACTIONS.DOWNLOAD_CONTRACT);
                                    }}
                                >
                                    <div className={`d-flex flex-row justify-content-start`}>
                                        <div className="d-flex align-items-center me-2 icon-container">
                                            {!row.original.downloadContractLoading ?
                                                <DownloadIcon className="icon-signed-contract" />
                                            :   <Loading size={24} />}
                                        </div>
                                        <span className="d-flex align-items-center justify-content-center options-text">
                                            <h5>
                                                <IntlMessages id="label.signedContract" />
                                            </h5>
                                        </span>
                                    </div>
                                </DropdownItem>
                            )
                        }

                        {
                            /* LINK AR TO EMAIL */
                            [getCompanyProfileIds().EFZ].includes(companyProfileId) &&
                                [PRODUCT_IDS.EVC].includes(parseInt(row.original.tp_id)) && (
                                    <DropdownItem className={`dropdown-item`} onClick={() => handleSendLinkAR(row.original.id)}>
                                        <div className={`d-flex flex-row justify-content-start`}>
                                            <div className="d-flex align-items-center me-2 icon-container">
                                                {!row.original.sendLinkARLoading ?
                                                    <MeasureIcon className="icon-measure" />
                                                :   <Loading size={24} />}
                                            </div>
                                            <span className="d-flex align-items-center justify-content-center options-text">
                                                <h5>
                                                    <IntlMessages id="page.processes.label.ARSendEmail" />
                                                </h5>
                                            </span>
                                        </div>
                                    </DropdownItem>
                                )
                        }
                    </>
                );
            }

            return (
                <div className="d-flex w-100 h-100 justify-content-center align-items-center action-options">
                    <UncontrolledDropdown direction="down">
                        {(
                            isUpdatingProcess ||
                            !row.original.downloadCSVLoading ||
                            row.original.downloadContractLoading ||
                            isRequestingRedirection ||
                            !row.original.auditFilesLoading
                        ) ?
                            <DropdownToggle className="actions-dropdown-toggle">
                                <span>
                                    <i className="zmdi zmdi-more" />
                                </span>
                            </DropdownToggle>
                        :   <Loading size={24} />}
                        <DropdownMenu end className="drop-menu">
                            {actions}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            );
        },
    },
];

// Filters visibility by company - relates to privacy policies
export const PROCESSES_FILTERS_VISIBILITY = {
    [getCompanyProfileIds().EFZ]: {
        [USERS_TYPE_ID.MANAGER]: [
            'datefrom',
            'dateto',
            'cid',
            'gcid',
            'uid',
            'fid',
            'gsid',
            'tpid',
            'sid',
            'oid',
            'ntid',
            'status_id',
            'sent_to_field',
            'gbm_ids',
        ],
        [USERS_TYPE_ID.CHANNEL]: ['datefrom', 'dateto', 'status_id', 'tpid', 'fid', 'cid', 'uid', 'sent_to_field', 'gbm_ids'],
        [USERS_TYPE_ID.CLIENT]: ['datefrom', 'dateto', 'status_id', 'tpid', 'fid', 'sent_to_field', 'gbm_ids'],
    },
    [getCompanyProfileIds().EDP_PT]: {
        [USERS_TYPE_ID.CHANNEL]: ['datefrom', 'dateto', 'status_id', 'tpid', 'fid', 'cid', 'uid', 'enviado_para_field', 'gbm_ids'],
        [USERS_TYPE_ID.CLIENT]: ['datefrom', 'dateto', 'status_id', 'tpid', 'fid', 'enviado_para_field', 'gbm_ids'],
    },
    [getCompanyProfileIds().EDP_ES]: {
        [USERS_TYPE_ID.MANAGER]: [
            'datefrom',
            'dateto',
            'cid',
            'gcid',
            'uid',
            'fid',
            'gsid',
            'tpid',
            'sid',
            'oid',
            'pid',
            'ntid',
            'status_id',
            'sent_to_field',
            'gbm_ids',
        ],
        [USERS_TYPE_ID.CHANNEL]: ['datefrom', 'dateto', 'status_id', 'tpid', 'fid', 'cid', 'uid', 'sent_to_field', 'gbm_ids'],
        [USERS_TYPE_ID.CLIENT]: ['datefrom', 'dateto', 'status_id', 'tpid', 'fid', 'sent_to_field', 'gbm_ids'],
    },
    [getCompanyProfileIds().EDP_IT]: {
        [USERS_TYPE_ID.CHANNEL]: ['datefrom', 'dateto', 'status_id', 'tpid', 'fid', 'cid', 'uid', 'enviado_para_field', 'gbm_ids'],
        [USERS_TYPE_ID.CLIENT]: ['datefrom', 'dateto', 'status_id', 'tpid', 'fid', 'enviado_para_field', 'gbm_ids'],
    },
    [getCompanyProfileIds().EDP_PL]: {
        [USERS_TYPE_ID.CHANNEL]: ['datefrom', 'dateto', 'status_id', 'tpid', 'fid', 'cid', 'uid', 'enviado_para_field', 'gbm_ids'],
        [USERS_TYPE_ID.CLIENT]: ['datefrom', 'dateto', 'status_id', 'tpid', 'fid', 'enviado_para_field', 'gbm_ids'],
    },
    [getCompanyProfileIds().EDP_BR]: {
        [USERS_TYPE_ID.MANAGER]: [
            'datefrom',
            'dateto',
            'cid',
            'gcid',
            'uid',
            'fid',
            'gsid',
            'tpid',
            'sid',
            'oid',
            'ntid',
            'status_id',
            'gbm_ids',
        ],
        [USERS_TYPE_ID.CHANNEL]: ['datefrom', 'dateto', 'status_id', 'tpid', 'fid', 'cid', 'uid', 'gbm_ids'],
        [USERS_TYPE_ID.CLIENT]: ['datefrom', 'dateto', 'status_id', 'tpid', 'fid', 'gbm_ids'],
    },
    [getCompanyProfileIds().NRG]: {
        [USERS_TYPE_ID.MANAGER]: [
            'datefrom',
            'dateto',
            'cid',
            'gcid',
            'uid',
            'fid',
            'gsid',
            'tpid',
            'sid',
            'oid',
            'ntid',
            'status_id',
            'gbm_ids',
        ],
        [USERS_TYPE_ID.CHANNEL]: ['datefrom', 'dateto', 'status_id', 'tpid', 'fid', 'cid', 'uid', 'gbm_ids'],
        [USERS_TYPE_ID.CLIENT]: ['datefrom', 'dateto', 'status_id', 'tpid', 'fid', 'gbm_ids'],
    },
    [getCompanyProfileIds().NRG]: {
        [USERS_TYPE_ID.MANAGER]: [
            'datefrom',
            'dateto',
            'cid',
            'gcid',
            'uid',
            'fid',
            'gsid',
            'tpid',
            'sid',
            'oid',
            'ntid',
            'status_id',
            'gbm_ids',
        ],
        [USERS_TYPE_ID.CHANNEL]: ['datefrom', 'dateto', 'status_id', 'tpid', 'fid', 'cid', 'uid', 'gbm_ids'],
        [USERS_TYPE_ID.CLIENT]: ['datefrom', 'dateto', 'status_id', 'tpid', 'fid', 'gbm_ids'],
    },
    [getCompanyProfileIds().TELEFONICA]: {
        [USERS_TYPE_ID.MANAGER]: [
            'datefrom',
            'dateto',
            'cid',
            'gcid',
            'uid',
            'fid',
            'gsid',
            'tpid',
            'sid',
            'oid',
            'ntid',
            'status_id',
            'gbm_ids',
        ],
        [USERS_TYPE_ID.CHANNEL]: ['datefrom', 'dateto', 'status_id', 'tpid', 'fid', 'cid', 'uid', 'gbm_ids'],
        [USERS_TYPE_ID.CLIENT]: ['datefrom', 'dateto', 'status_id', 'tpid', 'fid', 'gbm_ids'],
    },
    [getCompanyProfileIds().PROFILE_BASE_UK]: {
        [USERS_TYPE_ID.MANAGER]: [
            'datefrom',
            'dateto',
            'cid',
            'gcid',
            'uid',
            'fid',
            'gsid',
            'tpid',
            'sid',
            'oid',
            'ntid',
            'status_id',
            'sent_to_field',
            'gbm_ids',
        ],
        [USERS_TYPE_ID.CHANNEL]: ['datefrom', 'dateto', 'status_id', 'tpid', 'fid', 'cid', 'uid', 'sent_to_field', 'gbm_ids'],
        [USERS_TYPE_ID.CLIENT]: ['datefrom', 'dateto', 'status_id', 'tpid', 'fid', 'sent_to_field', 'gbm_ids'],
    },
    [getCompanyProfileIds().EVOLO]: {
        [USERS_TYPE_ID.MANAGER]: [
            'datefrom',
            'dateto',
            'cid',
            'gcid',
            'uid',
            'fid',
            'gsid',
            'tpid',
            'sid',
            'oid',
            'ntid',
            'status_id',
            'sent_to_field',
            'gbm_ids',
        ],
        [USERS_TYPE_ID.CHANNEL]: ['datefrom', 'dateto', 'status_id', 'tpid', 'fid', 'cid', 'uid', 'sent_to_field', 'gbm_ids'],
        [USERS_TYPE_ID.CLIENT]: ['datefrom', 'dateto', 'status_id', 'tpid', 'fid', 'sent_to_field', 'gbm_ids'],
    },
    [getCompanyProfileIds().PROFILE_BASE_USA]: {
        [USERS_TYPE_ID.MANAGER]: [
            'datefrom',
            'dateto',
            'cid',
            'gcid',
            'uid',
            'fid',
            'gsid',
            'tpid',
            'sid',
            'oid',
            'ntid',
            'status_id',
            'sent_to_field',
            'gbm_ids',
        ],
        [USERS_TYPE_ID.CHANNEL]: ['datefrom', 'dateto', 'status_id', 'tpid', 'fid', 'cid', 'uid', 'sent_to_field', 'gbm_ids'],
        [USERS_TYPE_ID.CLIENT]: ['datefrom', 'dateto', 'status_id', 'tpid', 'fid', 'sent_to_field', 'gbm_ids'],
    },
};

export const canEditProposal = (companyProfileId, userTypeID, { productID, canEdit }) => {
    switch (companyProfileId) {
        case getCompanyProfileIds().EDP_PT: {
            return (
                [USERS_TYPE_ID.MANAGER].includes(userTypeID) &&
                [PRODUCT_IDS.SPV, PRODUCT_IDS.SPVSB, PRODUCT_IDS.CFP, PRODUCT_IDS.EVC, PRODUCT_IDS.EVCSB].includes(parseInt(productID))
            );
        }
        case getCompanyProfileIds().EDP_ES: {
            return (
                [USERS_TYPE_ID.MANAGER].includes(userTypeID) &&
                [PRODUCT_IDS.SPV, PRODUCT_IDS.CFP, PRODUCT_IDS.EVC].includes(parseInt(productID))
            );
        }
        case getCompanyProfileIds().EDP_BR: {
            return [USERS_TYPE_ID.MANAGER].includes(userTypeID) && [PRODUCT_IDS.SPV, PRODUCT_IDS.EVC].includes(parseInt(productID));
        }
        case getCompanyProfileIds().EDP_PL: {
            return [USERS_TYPE_ID.MANAGER].includes(userTypeID) && [PRODUCT_IDS.SPV].includes(parseInt(productID));
        }
        case getCompanyProfileIds().EFZ: {
            return [USERS_TYPE_ID.MANAGER].includes(userTypeID) && [PRODUCT_IDS.SPV, PRODUCT_IDS.EVC].includes(parseInt(productID));
        }
        case getCompanyProfileIds().PROFILE_BASE_UK:
        case getCompanyProfileIds().PROFILE_BASE_USA:
        case getCompanyProfileIds().EVOLO: {
            return [USERS_TYPE_ID.MANAGER].includes(userTypeID) && [PRODUCT_IDS.HP].includes(parseInt(productID)) && canEdit;
        }
        default:
            return false;
    }
};

export const CAN_EDIT_OWNER = [USERS_TYPE_ID.MANAGER];

export const AUTO_SEND_CRM_ON_PROPOSAL_DOWNLOAD = [
    GRUPO_CANAL_IDS.CGD,
    GRUPO_CANAL_IDS.SANTANDER,
    GRUPO_CANAL_IDS.NOVOBANCO,
    GRUPO_CANAL_IDS.BCP,
    GRUPO_CANAL_IDS.NOS,
    GRUPO_CANAL_IDS.BBVA,
    GRUPO_CANAL_IDS.LIBERBANK,
    GRUPO_CANAL_IDS.SELECTRA,
];
