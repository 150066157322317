import { TGrants, TUpFront, TUpFrontValues } from 'interfaces/businessModels/upfront';
import { EVCWithManagementOption } from './index';
import { getInputWithTaxes } from 'services/products/evc';
import { isDefined, isFieldDefined, parseNumberWithDecimals } from 'services/util/auxiliaryUtils';
import { PRODUCT_IDS } from 'constants/products';
import { IBusinessModelsState } from './../../interfaces/businessModels/reducer';
import { TBMKpi, TProductInputs } from './../../interfaces/businessModels/index';
import { getCompanyProfileIds } from 'services/user';
import { TOMDurationOptionType } from 'interfaces/businessModels/detailed';
import { FixedInputOptions } from 'constants/businessModelsPro/index';

export const upFrontProjectSummary = (
    kpis: TUpFrontValues[],
    state: IBusinessModelsState<TProductInputs, TUpFront>,
    configs: { companyProfileId: number; productID: number }
) => {
    let projectSummary: TBMKpi[] = [];

    switch (configs.productID) {
        case PRODUCT_IDS.SPV:
        case PRODUCT_IDS.SPVSB:
            projectSummary = [
                {
                    label: 'label.investment',
                    value: parseNumberWithDecimals(kpis[0].kpis_per_margin?.[0].investment, 0),
                    unit: 'currency',
                    numberOfDecimalPlaces: 0,
                },
                {
                    label: 'label.payback',
                    value: parseNumberWithDecimals(kpis[0].kpis_per_margin?.[0].investment / kpis[0].kpis_per_margin?.[0].annual_saving, 1),
                    unit: 'year',
                    numberOfDecimalPlaces: 1,
                },
            ];
            break;
        case PRODUCT_IDS.SPV_SIMPLE:
            projectSummary = [
                {
                    label: 'label.investment',
                    value: parseNumberWithDecimals(kpis[0].kpis_per_margin?.[0].investment, 0),
                    unit: 'currency',
                    numberOfDecimalPlaces: 0,
                },
                {
                    label: 'label.annualSaving',
                    value: upFrontPayback(kpis[0].kpis_per_margin?.[0].annual_saving, 1),
                    unit: 'currency',
                    numberOfDecimalPlaces: 1,
                },
            ];
            break;
        case PRODUCT_IDS.HP: {
            const hasAnnualSaving = isFieldDefined(kpis[0].kpis_per_margin?.[0].annual_saving);
            const investment = hasAnnualSaving ? kpis[0].kpis_per_margin?.[0].investment : kpis[0].kpis_per_margin?.[0].price;
            projectSummary = [
                {
                    label: 'label.investment',
                    value: parseNumberWithDecimals(investment, 0),
                    unit: 'currency',
                    numberOfDecimalPlaces: 0,
                },
                {
                    label: 'label.annualSaving',
                    value: hasAnnualSaving ? upFrontPayback(kpis[0].kpis_per_margin?.[0].annual_saving, 1) : '-',
                    unit: 'currency',
                    numberOfDecimalPlaces: 1,
                },
            ];
            break;
        }
        case PRODUCT_IDS.EVC:
        case PRODUCT_IDS.EVCSB:
            projectSummary = [
                {
                    label: 'label.equipmentAndInstallation',
                    subLabel:
                        [getCompanyProfileIds().EDP_ES].includes(configs.companyProfileId) ? 'page.evc.BModel.servicessubLabel3' : (
                            undefined
                        ),
                    value: kpis[0]?.kpis_per_margin[0]?.price ?? 'N/A',
                    isServiceME_ES: false,
                    unit: 'currency',
                    numberOfDecimalPlaces: 0,
                },
            ];
            if (EVCWithManagementOption.visible(state.simulation.inputs.management_option_id)) {
                projectSummary.push({
                    isServiceME_ES: false,
                    label:
                        [getCompanyProfileIds().EDP_ES].includes(configs.companyProfileId) ? 'label.managementPlatforms' : (
                            `page.evc.resume.total_platform_price_month_companyID${EVCWithManagementOption.tag(state.simulation.inputs.management_option_id)}`
                        ),
                    subLabel:
                        [getCompanyProfileIds().EDP_ES].includes(configs.companyProfileId) ? 'page.evc.BModel.servicessubLabel2' : (
                            undefined
                        ),
                    value: getInputWithTaxes('total_platform_price_month', configs.companyProfileId, state.simulation),
                    unit: 'mc',
                    numberOfDecimalPlaces: 0,
                });
            }

            break;

        case PRODUCT_IDS.TRE:
            projectSummary = [{ label: 'label.price', value: kpis[0].kpis_per_margin?.[0].price }];
            break;

        case PRODUCT_IDS.CFP: {
            const payback = kpis[0].kpis_per_margin?.[0].investment / kpis[0].kpis_per_margin?.[0].annual_saving;
            projectSummary = [
                {
                    label: 'label.investment',
                    value:
                        kpis[0].kpis_per_margin?.[0].investment > 0 ?
                            parseNumberWithDecimals(kpis[0].kpis_per_margin?.[0].investment, 0)
                        :   '-',
                    unit: 'currency',
                    numberOfDecimalPlaces: 0,
                },
                {
                    label: 'label.payback',
                    value: payback > 0 ? parseNumberWithDecimals(payback, 1) : '-',
                    unit: 'year',
                    numberOfDecimalPlaces: 1,
                },
            ];
            break;
        }
        case PRODUCT_IDS.HVAC_BR:
            projectSummary = [{ label: 'label.investment', value: kpis[0].kpis_per_margin?.[0]?.investment }];
            break;
        default:
            break;
    }

    return projectSummary;
};

export const upFrontPayback = (investment: number, annualSaving: number) => parseNumberWithDecimals(investment / annualSaving, 0);

export const grantPrcAmmount = (investment: number, value: number) => {
    return {
        prc: Number(value.toFixed(4)),
        currency: Number(((investment * value) / 100).toFixed(2)),
        fixed: false,
    };
};

export const grantCurrencyAmmount = (investment: number, value: number) => {
    return {
        prc: Number(((value * 100) / investment).toFixed(4)),
        currency: Number(value.toFixed(2)),
        fixed: true,
    };
};

export const updateTotalGrantsHandler = (grants: TGrants[]) => {
    return {
        currency: grants?.reduce((accumulator, currentValue) => accumulator + currentValue.ammounts.currency, 0),
        prc: grants?.reduce((accumulator, currentValue) => accumulator + currentValue.ammounts.prc, 0),
    };
};

export const negotiationElementsDefaultValue = (
    omDurationOptions: TOMDurationOptionType[],
    paymentModel?: FixedInputOptions | undefined
): number => {
    if (isDefined(paymentModel)) return omDurationOptions.find((option) => option.input_fixed_text === paymentModel)!.om_durations_default;
    return omDurationOptions[0].om_durations_default;
};
