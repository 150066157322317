import { css, styled } from 'styled-components/macro';
import { themeBase } from 'constants/styles/global';
import { Dialog } from '@mui/material';
import ConfirmationDialog from 'components/core/dialogs/ConfirmationDialog';

const inputContainerCSS = css`
    display: flex;
    flex-flow: column nowrap;
    flex: 0 0 33.33333%;
    width: 100%;
    max-width: 300px;
    position: relative;

    > label {
        margin-bottom: 0.75rem;
    }

    label {
        font-weight: ${themeBase.fontWeight.semibold};
        color: var(--text-color);
        font-size: 0.875rem;
        line-height: 1.3125rem;
        flex: 1;

        .efz-tooltip-icon-info svg {
            margin: 0;
            margin-inline: 5px;
        }

        &.disabled {
            color: ${themeBase.color.neutral600};
        }
    }

    .MuiInputBase-input {
        appearance: none;
        height: 20px;
        line-height: 1.1875rem;
        padding: 0.625rem 1rem;
    }

    &:only-child {
        flex: 0 0 33.33333%;
        max-width: 300px;
    }
`;

export const StyledEVCProPage = styled.div`
    counter-reset: section;
    padding: 0 5.1875rem 0 5.1875rem !important;
    // background-color: $neutral-200;

    .evcPro-form {
        min-height: 750px;
        padding: 2.5rem 3.5rem;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);

        display: flex;
        flex-flow: column nowrap;
        row-gap: 2.5rem;
        margin-bottom: 2.5rem;

        &-inner {
            display: flex;
            flex-flow: column nowrap;
            row-gap: 2rem;
        }

        &-actions {
            display: flex;
            justify-content: space-between;

            .reset-button {
                background-color: var(--paper-color);
                border: 2px solid var(--primary-color);
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
                box-sizing: border-box;
                color: var(--primary-color);
                height: 40px;
                padding: 10px 18px;

                &:hover {
                    background-color: var(--primary-color);
                    color: var(--paper-color);
                }
            }

            .submit-button {
                gap: 0.5rem;
            }
        }

        &-installation-details {
            .next-button {
                max-width: fit-content;
                align-self: center;
            }
        }
    }

    [class^='evcPro-form-']:not(.evcPro-form-inner):not(.evcPro-form-actions) {
        display: flex;
        flex-flow: column nowrap;
        row-gap: 1.5rem;
    }

    .section-header {
        h2 {
            font-weight: ${themeBase.fontWeight.semibold};
            font-size: 1rem;
            line-height: 1.5rem;
            margin: 0;
            font-family: 'Open Sans';
            color: black;
        }

        hr {
            margin: 0;
            margin-top: calc(0.5rem - 3px);
            background-color: var(--primary-color);
            height: 3px;
            max-width: 70px;
            border-radius: 1.5px;
            border-top: unset;
            opacity: 1;
        }

        &.count {
            h2:before {
                counter-increment: section;
                content: counter(section) '. ';
            }
        }
    }

    .input-container {
        ${inputContainerCSS};

        &-2 {
            ${inputContainerCSS};
            flex: 0 0 50%;
            max-width: 50%;
        }

        &-100 {
            ${inputContainerCSS};
            flex: 0 0 100%;
            max-width: 100%;

            .MuiInputBase-root,
            .file-upload-template,
            .file-upload-upload {
                max-width: 300px;
            }
        }

        &-sub {
            --icon-width: 2.375rem;
            --icon-height: 1.625rem;
            --icon-horizontal-spacing: 1rem;
            --icon-total-horizontal-size: calc(var(--icon-horizontal-spacing) + var(--icon-width) + var(--icon-horizontal-spacing));

            position: relative;
            display: grid;
            grid-template-columns: max-content 1fr;
            column-gap: 1rem;
            align-items: center;
            margin-left: var(--icon-total-horizontal-size);

            &::after {
                position: absolute;
                top: 0;
                left: calc(-1 * (var(--icon-horizontal-spacing) + var(--icon-width)));
                display: inline-block;
                width: var(--icon-width);
                height: var(--icon-height);
                background-image: url('../../assets/images/icons/svg/arrow-subitem.svg');
                background-size: var(--icon-width) var(--icon-height);
                content: '';
            }

            &.input-container {
                &-2 {
                    max-width: calc(50% - var(--icon-total-horizontal-size));
                }
                &-100 {
                    max-width: calc(100% - var(--icon-total-horizontal-size));
                }
            }

            .MuiFormLabel-root {
                margin: 0;
            }

            .MuiFormGroup-root {
                flex-flow: row wrap;
            }

            .MuiFormHelperText-root.Mui-error {
                grid-column: 1 / span 2;
            }
        }
    }

    .radio-with-label {
        .radio-button-unchecked {
            .MuiFormControlLabel-label {
                &:hover:not(.Mui-disabled) {
                    border-color: var(--primary-color);
                    background-color: var(--primary-color-bleached);
                    color: var(--primary-color);
                }
            }
        }
    }

    .radio-with-image {
        .MuiFormGroup-root {
            flex-direction: row;
            gap: 1.5rem;
        }

        .option {
            --borderRadius: 20px;

            max-width: 315px;
            display: flex;
            flex: 1 1;
            flex-flow: column nowrap;
            border: 1px solid transparent;
            border-radius: var(--borderRadius);
            overflow: hidden;

            &-inner {
                display: flex;
                flex: 1 1;
                flex-flow: column nowrap;
                row-gap: 0.75rem;
                background-color: ${themeBase.color.neutral50};
                border: 1px solid ${themeBase.color.neutral400};
                border-radius: var(--borderRadius);
                margin-top: calc(var(--borderRadius) * -1);
                padding-block: 1.5rem;
                padding-inline: 1.6875rem;
                z-index: 1;
            }

            img,
            &-image-placeholder {
                height: 117px;
                max-height: 117px;
                min-height: 117px;
                object-fit: cover;
            }

            &-title {
                font-size: 1rem;
                font-weight: ${themeBase.fontWeight.semibold};
                line-height: 1.375rem;
                color: var(--text-color);
                text-align: center;
            }

            &-description {
                font-size: 0.75rem;
                font-weight: ${themeBase.fontWeight.normal};
                line-height: 1rem;
                color: var(--text-color);
                text-align: center;
            }

            &-action {
                max-height: calc(1.375rem + 20px);
                margin: 0;
                margin-top: auto;
                align-self: center;
                background-color: white;
                border: 1px solid var(--primary-color);
                border-radius: 5px;

                .MuiRadio-root {
                    display: none;
                }

                .MuiFormControlLabel-label {
                    padding: 10px 1rem;
                    font-size: 1rem;
                    font-weight: ${themeBase.fontWeight.normal};
                    line-height: 1.375rem;
                    color: var(--primary-color);
                    text-transform: uppercase;
                    text-align: center;

                    &:hover {
                        background-color: var(--primary-color-bleached);
                    }
                }

                &.Mui-disabled {
                    border-color: var(--disabled-color);
                    pointer-events: none;

                    .MuiFormControlLabel-label {
                        color: var(--disabled-color);
                    }
                }
            }

            &.selected {
                .option-inner {
                    border-color: var(--primary-color);
                }

                .option-action {
                    background-color: var(--primary-color-bleached);
                }
            }
        }

        .download-button {
            width: fit-content;
            margin: 1.5rem auto 0;
            font-size: 1rem;
            font-weight: ${themeBase.fontWeight.normal};
            line-height: 1.375rem;
            text-decoration: underline;
            text-transform: uppercase;
            color: var(--text-color);

            &:hover,
            &:active {
                color: var(--primary-color);
            }
        }
    }

    .radio-with-list {
        width: fit-content !important;

        & > label {
            margin-bottom: 1rem;
        }

        .MuiFormGroup-root {
            gap: 1.5rem;
        }

        .option {
            --borderRadius: 10px;

            max-width: 350px;
            display: flex;
            flex: 1 1;
            flex-flow: column nowrap;
            border: 1px solid ${themeBase.color.neutral400};
            border-radius: var(--borderRadius);
            overflow: hidden;

            &-title {
                width: 100%;
                padding-block: 1rem 1.0625rem;
                text-align: center;
                background-color: ${themeBase.mediumGray2};
                font-size: 1rem;
                font-weight: ${themeBase.fontWeight.semibold};
                line-height: 1.375rem;
                color: ${themeBase.color.neutral50};
            }

            &-list {
                display: flex;
                flex-flow: column nowrap;
                row-gap: 10px;
                list-style: none;
                margin: 0;
                padding-left: 0;
                padding: 1rem 1.75rem;

                &-item {
                    --check-icon-width: 1.625rem;
                    position: relative;
                    padding: 0;
                    padding-left: calc(var(--check-icon-width) + 1rem);
                    margin: 0;
                    font-size: 0.75rem;
                    font-weight: ${themeBase.fontWeight.normal};
                    line-height: 1rem;
                    color: var(--text-color);

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: var(--check-icon-width);
                        height: 100%;
                        background-repeat: no-repeat;
                        background-image: url('../../assets/images/products/me/svg/check.svg');
                        background-position: center;
                    }
                }
            }

            &-action {
                max-height: calc(1.375rem + 20px);
                margin: 0;
                margin-top: auto;
                margin-bottom: 1.5rem;
                align-self: center;
                background-color: white;
                border: 1px solid var(--primary-color);
                border-radius: 5px;

                .MuiRadio-root {
                    display: none;
                }

                .MuiFormControlLabel-label {
                    padding: 10px 1rem;
                    font-size: 1rem;
                    font-weight: ${themeBase.fontWeight.normal};
                    line-height: 1.375rem;
                    color: var(--primary-color);
                    text-transform: uppercase;
                    text-align: center;

                    &:hover {
                        background-color: var(--primary-color-bleached);
                    }
                }

                &.Mui-disabled {
                    border-color: var(--disabled-color);
                    pointer-events: none;

                    .MuiFormControlLabel-label {
                        color: var(--disabled-color);
                    }
                }
            }

            &.selected {
                .option-inner {
                    border-color: var(--primary-color);
                }

                .option-action {
                    background-color: var(--primary-color-bleached);
                }
            }
        }

        .download-button {
            width: fit-content;
            margin: 1.5rem auto 0;
            font-size: 1rem;
            font-weight: ${themeBase.fontWeight.normal};
            line-height: 1.375rem;
            text-decoration: underline;
            text-transform: uppercase;
            color: var(--text-color);

            &:hover,
            &:active {
                color: var(--primary-color);
            }
        }
    }

    .checkbox-group {
        display: flex;
        flex-flow: column nowrap;
        row-gap: 0.75rem;
    }

    .styled-checkbox {
        .MuiFormGroup-root {
            padding: 0.75rem;
            background-color: ${themeBase.color.neutral100};
            border: 1px solid ${themeBase.color.neutral200};
            border-radius: 10px;

            .MuiFormControlLabel-root {
                margin: 0;
            }

            .MuiButtonBase-root {
                padding: 0 !important;
                margin-right: 0.75rem;

                svg {
                    width: 28px;
                    height: 28px;
                }
            }

            .MuiTypography-root {
                display: flex;
                column-gap: 0.75rem;
                align-items: center;

                svg {
                    fill: var(--primary-color);
                }
            }
        }
    }

    .styled-radio {
        padding: 0.75rem;
        background-color: ${themeBase.color.neutral100};
        border: 1px solid ${themeBase.color.neutral200};
        border-radius: 10px;

        .MuiFormGroup-root {
            gap: 1rem;

            .MuiFormControlLabel-root {
                max-width: fit-content;
                margin: 0;
            }

            .MuiButtonBase-root {
                padding: 2px 0.5rem 2px 0;
            }

            .MuiTypography-root {
                display: flex;
                column-gap: 0.75rem;

                svg {
                    fill: var(--primary-color);
                }
            }
        }
    }

    .charger-points-management {
        border: 1px solid transparent;
        border-radius: 10px;
        overflow: hidden;

        &-header {
            display: flex;
            align-items: center;
            column-gap: 1.25rem;
            padding: 1rem;
            background-color: ${themeBase.color.neutral200};

            label {
                height: 1.3125rem;
            }
        }

        &-chargers {
            display: flex;
            column-gap: 1.5rem;
            padding: 1.5rem;
            background-color: ${themeBase.color.neutral50};

            .add-card {
                max-width: 400px;
                min-height: 227px;
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                justify-content: center;
                gap: 1rem;
                border: 1px solid var(--primary-color);
                border-radius: 10px;
                background-color: var(--primary-color-bleached);
                padding: 58px 7.9375rem;

                &-label {
                    font-size: 0.875rem;
                    line-height: 1.1875rem;
                    text-align: center;
                }
            }

            .charger-point-card {
                width: 203px;
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                gap: 0.75rem;
                padding: 1rem;
                background-color: white;
                border-radius: 8px;
                box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);

                &-header {
                    width: 100%;
                    display: flex;
                    gap: 1rem;

                    .remove-icon {
                        margin-left: auto;
                    }
                }

                &-image {
                    display: flex;
                    flex-flow: column nowrap;
                    justify-content: center;
                    align-items: center;
                    gap: 0.375rem;

                    &-placeholder,
                    img {
                        height: 91px !important;
                    }

                    .tech-details-link {
                        font-size: 0.625rem;
                        font-weight: ${themeBase.fontWeight.normal};
                        line-height: 0.875rem;
                        text-align: center;
                        text-decoration: underline;
                        text-transform: uppercase;
                        color: var(--primary-color);
                    }
                }

                &-info {
                    display: flex;
                    flex-flow: column nowrap;
                    gap: 0.25rem;

                    &-title {
                        font-size: 0.875rem;
                        font-weight: ${themeBase.fontWeight.normal};
                        line-height: 1.1875rem;
                        color: var(--text-color);
                    }

                    &-sub {
                        display: flex;
                        column-gap: 1rem;

                        span {
                            font-size: 0.75rem;
                            font-weight: ${themeBase.fontWeight.normal};
                            line-height: 1rem;
                            color: ${themeBase.color.neutral600};
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        .MuiButtonBase-root {
            &.Mui-disabled {
                opacity: 0.2;
            }
        }
    }

    .info-OPC-sharing {
        max-width: unset !important;
    }
`;

export const StyledEVCProChargerDialog = styled(Dialog)`
    .form-me-new-charger {
        margin-top: 3.5rem;
    }

    .charge-stations {
        &-dialog {
            &-content {
                padding: 2rem 5.1875rem;

                .tab-container {
                    display: grid;
                    grid-template-columns: 0.5fr 1fr 0.5fr;
                    grid-template-rows: 1fr;
                    width: 100%;

                    span:nth-child(1) {
                        grid-column-start: 2;
                    }

                    &-end {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                    }
                }

                .content-header {
                    margin-bottom: 1.5rem;
                    display: flex;
                    flex-direction: column;

                    h3 {
                        font-family: 'Open Sans';
                        font-weight: 600;
                        font-size: 0.875rem;
                        color: var(--primary-color);
                        width: 100%;
                    }

                    &-line {
                        width: 100%;
                        height: 1px;
                        background-color: ${themeBase.color.neutral200};
                    }
                }

                .content-inputs {
                    display: flex;
                    flex-direction: column;

                    .input-info {
                        &.switch {
                            position: absolute;
                            left: 2.8125rem;
                            top: 1.5625rem;
                        }

                        &.radio2 {
                            position: absolute;
                            left: 2.4375rem;
                            bottom: 0.9375rem;
                        }

                        margin-top: 0.5rem;

                        svg {
                            width: 14px;
                            height: 14px;

                            path {
                                fill: var(--primary-color);
                            }
                        }

                        font-family: 'Open Sans';
                        font-weight: 400;
                        font-size: 0.75rem;
                        line-height: 1rem;
                        color: var(--primary-color);
                    }

                    .input-separator {
                        border: 1px dashed ${themeBase.color.neutral300};
                        margin: 0.5rem 0;
                    }

                    .input-container {
                        label {
                            font-family: 'Open Sans';
                            font-size: 0.875rem;
                            color: ${themeBase.color.neutral800};
                            margin-bottom: 0.5rem;
                        }

                        &.input-radio {
                            margin-bottom: 1.5rem;

                            .MuiFormGroup-root {
                                label {
                                    margin-bottom: 0.625rem;

                                    .MuiButtonBase-root {
                                        padding: 0 1rem 0 0.5rem;
                                    }

                                    .MuiTypography-root {
                                        font-family: 'Open Sans';
                                        font-weight: 400;
                                        font-size: 0.875rem;
                                        color: ${themeBase.color.neutral800};

                                        &.Mui-disabled {
                                            font-family: 'Open Sans';
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 0.875rem;
                                            color: #a3a3a3;
                                        }
                                    }
                                }
                            }
                        }

                        &.w15 {
                            .MuiInputBase-root {
                                width: 15%;
                            }
                        }
                    }

                    .radio-with-label {
                        .MuiTypography-root {
                            padding: 0.625rem 2rem;
                            min-width: fit-content;
                        }
                    }
                }
            }

            &-title {
                color: var(--primary-color);
            }
        }

        &-form {
            &-tabs {
                &-unselected {
                    border-bottom: 2px solid ${themeBase.color.neutral200} !important;
                    background-color: ${themeBase.color.neutral100} !important;
                    color: #000000 !important;
                    box-sizing: border-box;
                }

                &-selected {
                    color: var(--primary-color) !important;
                    background-color: var(--primary-color-bleached) !important;
                    box-sizing: border-box;
                    border-bottom: 2px solid var(--primary-color) !important;
                }
            }

            .charger-container {
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                margin: 0 auto;
            }

            .MuiIconButton-label {
                svg {
                    fill: transparent;
                    g rect {
                        stroke: var(--primary-color);
                    }
                }
            }
            .chargers-container {
                display: flex;
                flex-flow: row;
                justify-content: flex-start;
                min-height: 400px;
                padding-bottom: 5px;
            }

            .card-setup-charger {
                display: flex;
                flex-flow: column nowrap;
                flex: 1 1 450px;
                width: 400px;
                //height: 450px;
                background-color: var(--paper-color);
                box-shadow: ${themeBase.shadow.sm};
                margin-right: 2rem;
                border-radius: ${themeBase.borderRadius.lg};

                .card-setup-charger-header {
                    max-height: 55px;
                    min-height: 55px;
                    background-color: ${themeBase.silverChalice};
                    padding: 0.5rem 1rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: ${themeBase.borderRadius.lg} ${themeBase.borderRadius.lg} 0 0;
                    color: var(--paper-color);
                    position: relative;
                    font-weight: ${themeBase.fontWeight.semibold};
                }

                .charger-selection-checkbox {
                    position: absolute;
                    right: 0.5rem;
                    padding: 0;

                    svg {
                        width: 36px;
                        height: 36px;

                        rect {
                            stroke-width: 1px;
                        }

                        &.unselected-checkbox-icon {
                            rect {
                                fill: white;
                                stroke: rgba(238, 22, 45, 1);
                            }

                            &--hovered {
                                rect {
                                    fill: white;
                                    stroke: var(--primary-color);
                                }
                            }
                        }

                        &.selected-checkbox-icon {
                            rect {
                                fill: white;
                                stroke: var(--primary-color);
                            }
                        }
                    }

                    &.Mui-disabled {
                        svg {
                            &.unselected-checkbox-icon {
                                rect {
                                    fill: rgba(250, 250, 250, 0.2);
                                    stroke: rgba(238, 22, 45, 0.2);
                                }
                            }
                        }
                    }
                }

                .card-setup-charger-body {
                    display: flex;
                    flex-flow: row nowrap;
                    padding: 0.75rem;

                    label {
                        font-weight: ${themeBase.fontWeight.semibold};
                        margin-bottom: 0.5rem;
                    }
                }

                .card-setup-charger-body-left {
                    display: flex;
                    flex-flow: column nowrap;
                    margin-right: 0.75rem;
                }

                .card-setup-charger-body-right {
                    display: flex;
                    flex-flow: column nowrap;
                    font-size: 0.75rem;

                    // Each Radio button
                    .MuiFormControlLabel-root {
                        margin-right: 1rem;
                        margin-left: -4px;
                        flex-basis: calc(50% - 1rem);
                        flex-grow: 1;
                        flex-shrink: 1;

                        &:nth-child(even) {
                            margin-right: 0;
                        }
                    }

                    // Radio labels
                    .MuiTypography-body1 {
                        font-size: 0.75rem;
                    }

                    // Radio button padding
                    .MuiRadio-root {
                        padding: 4px;
                    }

                    // Select padding
                    .MuiSelect-outlined {
                        padding: 5px 15px;
                        font-size: 0.75rem;
                    }

                    // Select size
                    .MuiOutlinedInput-root {
                        max-height: 30px;
                        max-width: 5rem;
                    }

                    hr {
                        opacity: 1;
                        height: 1px;
                        width: 100%;
                        background-color: var(--disabled-bg-color);
                        margin: 0.5rem 0;
                    }
                }

                .card-setup-charger-img {
                    width: 180px;
                    height: 180px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        max-height: 100%;
                        max-width: 100%;
                    }
                }

                .tech-details-link {
                    margin-top: 2rem;
                }
            }
        }
    }
`;

export const StyledEVCProChargerDialogSmall = styled(Dialog)`
    .MuiDialogTitle-root {
        margin: 2rem 3.5rem 1.5rem;
        padding: 0;
        font-family: 'Poppins';
        font-weight: 400;
    }

    .MuiDialogContent-root {
        margin: 0 3.5rem 2rem;
        padding: 0;
        overflow-y: hidden;
    }

    .MuiDialogActions-root {
        padding: 0 0 2rem;
    }

    .MuiFormControlLabel-root {
        margin-left: -9px;
    }

    .MuiPaper-root {
        width: 736px;
        max-width: 100%;
    }

    .charge-stations-dialog-small {
        &-header {
            width: 624px;
            font-weight: ${themeBase.fontWeight.normal};
            font-size: 1rem;
            color: var(--primary-color);
            font-size: 1rem !important;
            line-height: 24px;

            hr {
                opacity: 1;
                height: 1px; // +1 of the border-top of the HR element
                background-color: var(--primary-color);
                width: 100%;
                margin-top: 0.5rem;
                margin-bottom: 0;
                border-radius: 3px;
                border-top: unset;
            }
        }

        &-body {
            width: 624px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .input-container {
                ${inputContainerCSS};

                &-2 {
                    ${inputContainerCSS};
                    flex: 0 0 50%;
                    max-width: 50%;
                }

                &-100 {
                    ${inputContainerCSS};
                    flex: 0 0 100%;
                    max-width: 100%;

                    .MuiInputBase-root,
                    .file-upload-template,
                    .file-upload-upload {
                        max-width: 300px;
                    }

                    &:only-child {
                        flex: 0 0 100% !important;
                        max-width: 100% !important;
                    }
                }
            }

            .radio-with-label {
                .radio-button-unchecked {
                    .MuiFormControlLabel-label {
                        &:hover:not(.Mui-disabled) {
                            border-color: var(--primary-color);
                            background-color: var(--primary-color-bleached);
                            color: var(--primary-color);
                        }
                    }
                }
            }
        }

        &-close {
            padding: 0;
            margin: 0 !important;
            position: absolute !important;
            top: 1.5rem;
            right: 1.5rem;
            border: none !important;

            path {
                fill: var(--primary-color);
            }
        }
    }
`;

export const StyledEVCProWarningDialog = styled(ConfirmationDialog).attrs((props) => ({
    ...props,
}))`
    .MuiDialogTitle-root {
        text-align: left;

        &::after {
            content: '';
            display: block;
            width: 70px;
            margin-top: 0.5rem;
            border-bottom: 3px solid var(--primary-color);
        }
    }

    .MuiPaper-root {
        row-gap: 1.5rem;
        padding: 2rem 3.5rem;

        .MuiDialogContent-root {
            display: flex;
            flex-flow: column nowrap;
            row-gap: 1.5rem;
            padding: 0;

            > * {
                margin: 0 !important;
                padding: 0;
            }

            .MuiDialogActions-root {
                column-gap: 1rem;
            }
        }
    }
`;

export const StyledEVCProOPCSharing = styled.div`
    min-width: fit-content;
    background-color: white;
    border-color: #dfdfdf;

    .container {
        min-width: fit-content;
        display: flex;
        flex-flow: row nowrap;
        column-gap: 1.5rem;
        padding: 0;

        &-inner {
            min-width: 10.9375rem;
            display: flex;
            flex-flow: column nowrap;
            text-align: center;

            .title {
                display: block;
                padding: 0.5rem 0.75rem;
                margin-bottom: 0.5rem;
                background-color: #dfdfdf;
                border-radius: 5px;
                font-size: 12px;
                font-weight: ${themeBase.fontWeight.semibold};
            }

            p {
                margin: 0;
                padding: 0.25rem 0;
                font-size: 12px;
                border-bottom: 1px solid #dfdfdf;

                &:first-of-type {
                    border-top: 1px solid #dfdfdf;
                }
            }
        }
    }
`;
