// Terms of Expression of Interest and Personal Data Protection Policy

import { memo, useMemo, Fragment } from 'react';
import IntlMessages from 'components/util/IntlMessages';
import { useUserStore } from 'store/user';
import { IconButton } from '@save2compete/efz-design-system';
import { StyledPrivacyDialog } from './StylesPrivacyDialog';

const TEIPDPPDialog = ({ isOpen, handleClose, isB2C }) => {
    const { user } = useUserStore();
    const userLocale = user?.locale;

    const link = useMemo(
        () =>
            userLocale === 'el-GR' ?
                'https://www.dei.gr/el/dei-omilos/i-dei/etairiki-diakivernisi/enimerwsi-gia-ta-dedomena-proswpikou-xaraktira/'
            :   'https://www.dei.gr/en/ppc-group/ppc/corporate-governance/personal-data-protection/',
        [userLocale]
    );

    const dialogBody = (
        <Fragment>
            <section className="privacy-data-dialog-body">
                <div className="privacy-data-dialog-body-bullets">
                    <ul>
                        <li>
                            <IntlMessages id="page.spvSimple.simulation.card.contacts.dialog.info.1" />
                        </li>
                        <li>
                            <IntlMessages
                                id="page.spvSimple.simulation.card.contacts.dialog.info.2"
                                values={{
                                    link: (
                                        <a target="_blank" rel="noreferrer" href={link}>
                                            {link}
                                        </a>
                                    ),
                                }}
                            />
                        </li>
                        <li>
                            <IntlMessages
                                id="page.spvSimple.simulation.card.contacts.dialog.info.3"
                                values={{
                                    type: <IntlMessages id={`label.${isB2C ? 'residentialRoofs' : 'businesses'}`} />,
                                }}
                            />
                        </li>
                        <li>
                            <IntlMessages
                                id="page.spvSimple.simulation.card.contacts.dialog.info.4"
                                values={{
                                    type: <IntlMessages id={`label.${isB2C ? 'home' : 'business'}`} />,
                                }}
                            />
                        </li>
                        <li>
                            <IntlMessages id="page.spvSimple.simulation.card.contacts.dialog.info.5" />
                        </li>
                    </ul>
                </div>
                <div className="privacy-data-dialog-body-info">
                    <IntlMessages
                        id="page.spvSimple.simulation.card.contacts.dialog.footer"
                        values={{ email: <a href="mailto:customercare@dei.gr">customercare@dei.gr</a> }}
                    />
                </div>
            </section>
        </Fragment>
    );
    return (
        <StyledPrivacyDialog onClose={handleClose} open={isOpen} maxWidth="sm" className="privacy-data-dialog">
            <div className="privacy-data-dialog-title">
                <div className="d-flex flex-row justify-content-between">
                    <h2>
                        <IntlMessages id="page.spvSimple.simulation.card.contacts.dialog.title" />
                    </h2>
                    <IconButton
                        variant="tertiary"
                        icon="xMarkExit"
                        size="bg"
                        aria-label="close"
                        className={'close-dialog-icon'}
                        onClick={handleClose}
                        dataTestId="close-dialog"
                    />
                </div>
                <hr />
            </div>
            {dialogBody}
        </StyledPrivacyDialog>
    );
};

export default memo(TEIPDPPDialog);
