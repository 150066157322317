import { Fragment, memo, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
// MUI
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useMediaQuery } from '@mui/material';
// Assets
import NoBatteryIcon from 'assets/images/products/spv-simple/solution/no-battery.png';
import BatteryIcon from 'assets/images/products/spv-simple/solution/battery.png';
import { ReactComponent as DiscountIcon } from 'assets/images/products/spv-simple/discount.svg';
import { ReactComponent as PPCIcon } from 'assets/images/logos/svg/ppc-logo.svg';
import { ReactComponent as CheckboxChecked } from 'assets/images/icons/svg/mui/checkbox/selected-checkbox.svg';
import { ReactComponent as CheckboxUnchecked } from 'assets/images/icons/svg/mui/checkbox/unselected-checkbox.svg';
// Components
import IntlMessages from 'components/util/IntlMessages';
import FormatNumber from 'components/util/FormatNumber';
// Contexts/Hooks
import { SPVSimpleContext } from 'contexts/products/solarpvSimple/solarpvSimpleContext';
// Constants
import {
    SPV_SIMPLE_INPUTS as INPUT_NAMES,
    INVESTMENT_OPTIONS,
    INVESTMENT_OPTIONS_B2C,
    SIMULATION_CARDS_ENTRIES,
    SOLUTIONS_TO_PRESENT_B2C,
} from 'constants/products/solarpvSimple';
import { REMUNERATION_MODELS_TYPE } from 'constants/products/spvPro';
// Services
import { calcConvertionToUnit, isFieldDefined } from 'services/util/auxiliaryUtils';
import { Button } from '@save2compete/efz-design-system';

const SimulationCard = ({ solution, contactsRef }) => {
    let { title, description, entries, remuneration_type_id } = SOLUTIONS_TO_PRESENT_B2C.find(
        (el) => el.remuneration_type_id === solution.remuneration_type_id && el.has_battery === solution.is_recomended_with_battery
    );

    const {
        spvSimpleState: {
            simulations,
            options: { battery },
        },
        isOArea,
        selectedCard,
        setSelectedCard,
        solutionCardProps,
        setSolutionCardProps,
    } = useContext(SPVSimpleContext);

    const isDesktop = useMediaQuery('(min-width: 1367px)');

    const [investmentOption, setInvestmentOption] = useState(INVESTMENT_OPTIONS_B2C.find((i) => i.default)?.id);
    const [paymentModel, setPaymentModel] = useState(null);
    const [showPaymentModelError, setShowPaymentModelError] = useState(false);

    let has_battery = solution.is_recomended_with_battery;
    const investmentDiscountPercentage = 0.9;
    const VAT = 24;
    const showInvestmentOptions = true;

    const SolutionIcon = has_battery ? BatteryIcon : NoBatteryIcon;

    const getEntryLabel = (entry) => {
        switch (entry) {
            case SIMULATION_CARDS_ENTRIES.PANELS:
                return !has_battery ?
                        <IntlMessages id="page.spvSimple.simulation.card.panels.title" />
                    :   <IntlMessages id="page.spvSimple.simulation.card.panelsAndBattery.title" />;
            case SIMULATION_CARDS_ENTRIES.FOOTPRINT:
                return (
                    <>
                        <IntlMessages id="page.spvSimple.simulation.card.carbon_footprint.title" whitespaceEnd />
                        {'CO'}
                        <sub>2</sub>
                    </>
                );
            case SIMULATION_CARDS_ENTRIES.PRODUCTION:
                return <IntlMessages id="label.annual_production" />;
            case SIMULATION_CARDS_ENTRIES.PAYBACK:
                return <IntlMessages id="page.spvSimple.simulation.card.payback.title" />;
            case SIMULATION_CARDS_ENTRIES.INVESTMENT:
                return (
                    (!showInvestmentOptions || (showInvestmentOptions && investmentOption === INVESTMENT_OPTIONS.CASH.id)) && (
                        <>
                            <div>
                                <IntlMessages id="page.spvSimple.simulation.card.investment.title" />
                            </div>
                            <div className="subtitle">
                                <IntlMessages
                                    id={
                                        isDesktop ?
                                            'page.spvSimple.simulation.card.investment.info'
                                        :   'page.spvSimple.simulation.card.investment.info.mobile'
                                    }
                                    values={{ vat: VAT }}
                                />
                            </div>
                        </>
                    )
                );
            case SIMULATION_CARDS_ENTRIES.SAVINGS:
                return remuneration_type_id === REMUNERATION_MODELS_TYPE.FEED_IN_TARIFF ?
                        <IntlMessages id={`page.spvSimple.simulation.card.revenues.title`} />
                    :   <IntlMessages id={`page.spvSimple.simulation.card.savings.title`} />;
            default:
                return;
        }
    };

    const getEntryValue = (entry) => {
        switch (entry) {
            case SIMULATION_CARDS_ENTRIES.PANELS:
                return (
                    <>
                        <div className="text-bold">
                            {solution?.n_paineis} <IntlMessages id="label.panels" /> (
                            <FormatNumber
                                number={simulations?.panel_peak_power * solution?.n_paineis}
                                unit={'kwp'}
                                numberOfDecimalPlaces={2}
                            />
                            )
                        </div>
                        {has_battery && (
                            <div className="text-bold">
                                1 <IntlMessages id="label.battery" /> (
                                <FormatNumber number={battery?.battery_capacity_selected_kwh} unit={'kwh'} numberOfDecimalPlaces={0} />)
                            </div>
                        )}
                        <div>
                            <IntlMessages id="label.recommended" />
                        </div>
                    </>
                );
            case SIMULATION_CARDS_ENTRIES.FOOTPRINT:
                return (
                    <div className="text-bold">
                        <FormatNumber number={solution?.carbon_footprint_reduction * 100} numberOfDecimalPlaces={0} unit={'percentage'} />
                    </div>
                );
            case SIMULATION_CARDS_ENTRIES.PRODUCTION:
                return (
                    <div className="text-bold">
                        <FormatNumber
                            number={calcConvertionToUnit(solution?.production, 'currencyMWhTokWh', 3)}
                            numberOfDecimalPlaces={1}
                            unit={'mwh'}
                        />
                    </div>
                );
            case SIMULATION_CARDS_ENTRIES.PAYBACK: {
                // margin: [payback - 20%, payback + 20%]
                let payback = !has_battery ? solution?.payback_without_battery ?? solution?.payback : solution?.payback_with_battery;
                return (
                    <div className="text-bold">
                        <IntlMessages whitespaceEnd id="label.from" />
                        <FormatNumber number={payback * 0.8} numberOfDecimalPlaces={1} />
                        <IntlMessages whitespaceStart whitespaceEnd id="label.until" />
                        <FormatNumber number={payback * 1.2} numberOfDecimalPlaces={1} unit={'year'} />
                    </div>
                );
            }
            case SIMULATION_CARDS_ENTRIES.INVESTMENT: {
                let investment = !has_battery ? solution?.investment_without_battery : solution?.investment_with_battery;
                return !showInvestmentOptions || (showInvestmentOptions && investmentOption === INVESTMENT_OPTIONS.CASH.id) ?
                        <div className="d-flex flex-row justify-content-end align-items-center">
                            <DiscountIcon className="me-2" />
                            <div>
                                <div>
                                    <s>
                                        <FormatNumber
                                            number={investment / investmentDiscountPercentage}
                                            numberOfDecimalPlaces={0}
                                            unit={'currency'}
                                        />
                                    </s>
                                </div>
                                <div className="text-bold">
                                    <FormatNumber number={investment} numberOfDecimalPlaces={0} unit={'currency'} />
                                </div>
                            </div>
                        </div>
                    :   <Fragment />;
            }
            case SIMULATION_CARDS_ENTRIES.SAVINGS: {
                // margin: [savings - 20%, savings + 20%]
                let savings = !has_battery ? solution?.savings_without_battery ?? solution?.savings : solution?.savings_with_battery;
                return (
                    <div className="text-bold">
                        <FormatNumber number={savings * 0.8} numberOfDecimalPlaces={0} unit={'currency'} />
                        <IntlMessages whitespaceStart whitespaceEnd id="label.until" />
                        <FormatNumber number={savings * 1.2} numberOfDecimalPlaces={0} unit={'currency'} />
                    </div>
                );
            }
            default:
                return;
        }
    };

    const getSpecialEntry = (entry, placement) => {
        switch (entry) {
            case SIMULATION_CARDS_ENTRIES.PANELS:
            case SIMULATION_CARDS_ENTRIES.FOOTPRINT:
            case SIMULATION_CARDS_ENTRIES.PRODUCTION:
            case SIMULATION_CARDS_ENTRIES.PAYBACK:
            case SIMULATION_CARDS_ENTRIES.SAVINGS:
            default:
                return <Fragment />;
            case SIMULATION_CARDS_ENTRIES.INVESTMENT:
                switch (placement) {
                    case 'top':
                        return (
                            showInvestmentOptions && (
                                <RadioGroup
                                    className="radio-group"
                                    row
                                    onChange={(_, newValue) => setInvestmentOption(parseInt(newValue))}
                                    aria-label="solarpv INVESTMENT_OPTION"
                                >
                                    {INVESTMENT_OPTIONS_B2C.map((inv) => (
                                        <FormControlLabel
                                            key={`investment-option-${inv.id}`}
                                            value={parseInt(inv.id)}
                                            defaultChecked={INVESTMENT_OPTIONS_B2C.find((i) => i.default)?.id === inv.id}
                                            checked={investmentOption === inv.id}
                                            className={`radio-button ${investmentOption === inv.id ? 'checked' : 'unchecked'}`}
                                            control={<Radio />}
                                            label={
                                                <>
                                                    {investmentOption === inv.id ?
                                                        <CheckboxChecked className="checkbox" />
                                                    :   <CheckboxUnchecked className="checkbox" />}
                                                    <span className="radio-group-label">
                                                        <IntlMessages id={inv.label} />
                                                    </span>
                                                </>
                                            }
                                        />
                                    ))}
                                </RadioGroup>
                            )
                        );
                    case 'bottom':
                        return (
                            <>
                                {showInvestmentOptions && investmentOption === INVESTMENT_OPTIONS.INSTALLMENTS.id && (
                                    <RadioGroup
                                        className="radio-group-prices"
                                        row
                                        onChange={(_, newValue) => setPaymentModel(parseInt(newValue))}
                                        aria-label="solarpv PAYMENT_MODEL"
                                    >
                                        {solution?.monthly_payments_number_arr?.map((el, ind) => (
                                            <FormControlLabel
                                                key={`payment-model-${ind}`}
                                                value={parseInt(el)}
                                                className={`radio-button ${paymentModel === el ? 'checked' : 'unchecked'}`}
                                                //defaultChecked={ind === 0}
                                                checked={paymentModel === el}
                                                control={<Radio />}
                                                label={
                                                    <div className="label-composed">
                                                        <span className="label-small">
                                                            <IntlMessages
                                                                id="page.spvSimple.simulation.card.investment.paymentModel.top"
                                                                values={{
                                                                    value: (
                                                                        <FormatNumber
                                                                            number={
                                                                                !has_battery ?
                                                                                    solution?.upfront_installments_without_battery
                                                                                :   solution?.upfront_installments_with_battery
                                                                            }
                                                                            unit={'currency'}
                                                                            numberOfDecimalPlaces={0}
                                                                        />
                                                                    ),
                                                                }}
                                                            />
                                                        </span>
                                                        <div className="label-big">
                                                            <DiscountIcon />
                                                            <FormatNumber
                                                                number={
                                                                    (!has_battery ?
                                                                        solution?.installments_without_battery
                                                                    :   solution?.installments_with_battery) / el
                                                                }
                                                                unit={'mc'}
                                                                numberOfDecimalPlaces={0}
                                                            />
                                                        </div>
                                                        <span className="label-small">
                                                            <FormatNumber number={el} unit={'month'} numberOfDecimalPlaces={0} />
                                                        </span>
                                                        <span className="label-small">
                                                            <IntlMessages
                                                                id="page.spvSimple.simulation.card.investment.info"
                                                                values={{
                                                                    vat: VAT,
                                                                }}
                                                            />
                                                        </span>
                                                    </div>
                                                }
                                            />
                                        ))}
                                    </RadioGroup>
                                )}
                                {showPaymentModelError && (
                                    <p className="error">
                                        <IntlMessages id="page.spvSimple.simulation.card.investment.paymentModel.error" />
                                    </p>
                                )}
                            </>
                        );
                    default:
                        return <Fragment />;
                }
        }
    };

    const onPropsChangeHandler = () => {
        const newProps = solutionCardProps?.map((sol) => {
            if (sol.id === solution?.id) {
                sol = {
                    ...sol,
                    [INPUT_NAMES.INVESTMENT_TYPE_ID]: investmentOption,
                    [INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: investmentOption === INVESTMENT_OPTIONS.INSTALLMENTS.id ? paymentModel : null,
                    [INPUT_NAMES.UPFRONT_INSTALLMENTS_VALUE]:
                        investmentOption === INVESTMENT_OPTIONS.INSTALLMENTS.id ?
                            has_battery ? solution?.upfront_installments_with_battery
                            :   solution?.upfront_installments_without_battery
                        :   null,
                    [INPUT_NAMES.INSTALLMENTS_MONTHLY_FEE]:
                        investmentOption === INVESTMENT_OPTIONS.INSTALLMENTS.id ?
                            (!has_battery ? solution?.installments_without_battery : solution?.installments_with_battery) / paymentModel
                        :   null,
                };
                return sol;
            } else return sol;
        });
        setSolutionCardProps(newProps);
    };

    useEffect(() => {
        onPropsChangeHandler();
    }, [investmentOption, paymentModel]); // eslint-disable-line

    useEffect(() => {
        if (investmentOption === INVESTMENT_OPTIONS.INSTALLMENTS.id && !isFieldDefined(paymentModel)) {
            selectedCard === solution.id && setSelectedCard(null);
        }
    }, [investmentOption]); // eslint-disable-line

    useEffect(() => {
        isFieldDefined(paymentModel) && showPaymentModelError && setShowPaymentModelError(false);
    }, [paymentModel]); // eslint-disable-line

    const handleClick = () => {
        if (investmentOption === INVESTMENT_OPTIONS.INSTALLMENTS.id && !isFieldDefined(paymentModel)) {
            setShowPaymentModelError(true);
        } else {
            setSelectedCard(solution.id);
            if (isOArea && typeof contactsRef?.current?.scrollIntoView === 'function') {
                if (!contactsRef?.current)
                    // debounce; we need the ref component to be mounted!
                    setTimeout(() => {
                        contactsRef.current.scrollIntoView({ behavior: 'smooth' });
                    }, 200);
                else contactsRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    return (
        <div className="simulation-card">
            <div className="simulation-card-banner">
                <IntlMessages
                    id={`label.remunerationModel.${remuneration_type_id === REMUNERATION_MODELS_TYPE.FEED_IN_TARIFF ? 'feedin_tariff' : 'net_metering'}`}
                />
            </div>
            <div className="simulation-card-header">
                <div className="image">
                    <img alt="icon" src={SolutionIcon} />
                </div>
                <div className="title">
                    <PPCIcon />
                    {title}
                </div>
                <div className="description">
                    <IntlMessages id={description} />
                </div>
            </div>
            <div className="simulation-card-body">
                {entries.map((entry, ind) => (
                    <Fragment key={`simulation-card-entry-${ind}`}>
                        <div className="simulation-card-body-special">{getSpecialEntry(entry, 'top')}</div>
                        <div
                            className={classNames({
                                'simulation-card-body-entry': true,
                                bordered:
                                    entry === SIMULATION_CARDS_ENTRIES.INVESTMENT &&
                                    (!showInvestmentOptions || (showInvestmentOptions && investmentOption === INVESTMENT_OPTIONS.CASH.id)),
                            })}
                        >
                            <span className="label">{getEntryLabel(entry)}</span>
                            <span className="value">{getEntryValue(entry)}</span>
                        </div>
                        <div className="simulation-card-body-special">{getSpecialEntry(entry, 'bottom')}</div>
                        {ind + 1 < entries.length && <hr />}
                    </Fragment>
                ))}
            </div>
            {/* <div className="simulation-card-footer">
                <div>{getEntryValue(SIMULATION_CARDS_ENTRIES.SAVINGS)}</div>
                <div>{getEntryLabel(SIMULATION_CARDS_ENTRIES.SAVINGS)}</div>
            </div> */}
            <Button onClick={handleClick} dataTestId="simulation-card">
                <IntlMessages id="page.spvSimple.simulation.card.button" />
            </Button>
        </div>
    );
};

export default memo(SimulationCard);
