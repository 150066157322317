import { createContext, useReducer } from 'react';

import { initialProcessesState, ProcessesReducer } from 'contexts/processes/processesReducer';
import { TProcessesContext } from 'interfaces/processes';

const ProcessesContext = createContext<TProcessesContext>({
    processesState: initialProcessesState,
    processesDispatcher: () => {},
});

const ProcessesProvider: React.FC<React.ReactNode> = ({ children }) => {
    const [processesState, processesDispatcherHandler] = useReducer(ProcessesReducer, initialProcessesState);

    const processesDispatcher = (type: any, payload: any) => {
        processesDispatcherHandler({
            type: type,
            payload: payload,
        });
    };

    const processesContextValues = {
        processesState,
        processesDispatcher,
    };

    return <ProcessesContext.Provider value={processesContextValues}>{children}</ProcessesContext.Provider>;
};

export { ProcessesContext, ProcessesProvider };
