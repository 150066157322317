import { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

// Components
import CookieHeader from './Header';
import CookieBody from './Body';

// Services
import { routeoLoogin, routeToCookiePolicy } from 'services/cookies';
import { isFieldDefined } from 'services/util/auxiliaryUtils';

// Constants
import { USE_POLICY_BODY, PRIVACY_POLICY_BODY, COOKIES_POLICY_BODY } from 'constants/cookies';
import { isOpenArea } from 'services/settings';
import { useAuthStore } from 'store/auth';
import { StyledCookieConsent } from 'components/OArea/StylesCookie';

const CookieConsent = ({ type, showPolicyHandler }) => {
    const { isUserAuthenticated } = useAuthStore();
    const location = useLocation();
    const history = useHistory();
    const scrollRef = useRef(null);
    const scrollToTop = () => scrollRef.current.scrollIntoView();
    const isOArea = useMemo(() => isOpenArea(), []);

    let title;
    let body;
    let updatedDate;
    let clickMethods = {};

    switch (location?.state?.type ?? type) {
        case 'use-conditions':
            isFieldDefined(scrollRef.current) && scrollToTop(); // Scrolling when chaning policies
            clickMethods.link1 = () => routeToCookiePolicy(history, 'privacy-policy', isUserAuthenticated);
            clickMethods.link2 = () => routeToCookiePolicy(history, 'cookies-policy', isUserAuthenticated);
            title = USE_POLICY_BODY(clickMethods.link1, clickMethods.link2).title;
            body = USE_POLICY_BODY(clickMethods.link1, clickMethods.link2).body;
            updatedDate = USE_POLICY_BODY(clickMethods.link1, clickMethods.link2).updated;
            break;
        case 'privacy-policy':
        default:
            isFieldDefined(scrollRef.current) && scrollToTop(); // Scrolling when chaning policies
            clickMethods.link1 = () => routeToCookiePolicy(history, 'use-conditions', isUserAuthenticated);
            clickMethods.link2 = () => routeToCookiePolicy(history, 'cookies-policy', isUserAuthenticated);
            title = PRIVACY_POLICY_BODY(clickMethods.link1, clickMethods.link2).title;
            body = PRIVACY_POLICY_BODY(clickMethods.link1, clickMethods.link2).body;
            updatedDate = PRIVACY_POLICY_BODY(clickMethods.link1, clickMethods.link2).updated;
            break;

        case 'cookies-policy':
            isFieldDefined(scrollRef.current) && scrollToTop(); // Scrolling when chaning policies
            clickMethods.link1 = () => routeToCookiePolicy(history, 'privacy-policy', isUserAuthenticated);
            title = COOKIES_POLICY_BODY(clickMethods.link1, false).title;
            body = COOKIES_POLICY_BODY(clickMethods.link1, false).body;
            updatedDate = COOKIES_POLICY_BODY(clickMethods.link1, false).updated;
            break;
    }

    const closeHandler = () => {
        if (showPolicyHandler) showPolicyHandler(null);
        routeoLoogin(history, isOArea);
    };

    return (
        <StyledCookieConsent
            className={`cookie-consent cookie-consent-${!isUserAuthenticated ? 'not' : ''}-authenticated `}
            ref={scrollRef}
        >
            {!isUserAuthenticated && <CookieHeader title={title} close={closeHandler} />}
            <CookieBody body={body} updatedDate={updatedDate} />
        </StyledCookieConsent>
    );
};

CookieConsent.propTypes = {
    type: PropTypes.string,
    close: PropTypes.func,
    showPolicyHandler: PropTypes.func,
};

export default CookieConsent;
