import { css, styled } from 'styled-components';
import { media } from 'styles/utils/mediaQueries';
import Dialog from '@mui/material/Dialog';
import { themeBase } from 'constants/styles/global';

const widthValue = '33%';
const inputContainer = css`
    font-size: 0.875rem;
    line-height: 19px;
    font-weight: 400;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    label {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;

        .mini-label {
            font-size: 0.75rem;
        }
    }

    .MuiOutlinedInput-root {
        width: 250px;

        ${media.mobile`
                        width: 100%;
                    `}
    }
`;

export const StyledBusinessModelsPro = styled.div`
    &-subtitle {
        text-align: center;
        font-weight: 600;
        padding: 1rem 2.813rem;
        margin-bottom: 0;
    }

    &-container {
        .MuiSkeleton-root {
            border-radius: 10px;
        }
    }

    .business-models-cards {
        margin-bottom: 1.25rem;

        ${media.tablet`
            margin-bottom: 3rem;
        `}

        &-skeletons {
            display: flex;
            justify-content: space-evenly;
            width: 100%;
        }

        &-skeleton {
            display: flex;
            flex-direction: column;
            align-items: center;

            .skeleton-header {
                border-radius: 10px 10px 0 0;
                margin-bottom: 2rem;
            }

            .skeleton-btn {
                border-radius: 5px;
            }
        }

        &-caroussel {
            margin-inline: auto;
            width: 100%;

            .slick {
                &-slide {
                    display: flex;
                    justify-content: center;
                    height: inherit !important;

                    ${media.desktop`
                        margin-top: 2re
                    `}

                    > div {
                        height: 100%;
                        margin: 0 0.75rem;
                    }

                    .business-models-card {
                        height: 100%;
                        margin: 0;
                    }
                }

                &-list {
                    padding-top: 1.815rem;
                    margin-top: -1.815rem;
                }

                &-dots {
                    position: relative;
                }

                &-track {
                    display: flex !important;
                }

                &-disabled {
                    svg path {
                        fill: ${themeBase.color.neutral300};
                        transition: all 0.3s ease;
                    }
                }

                &-arrow {
                    svg {
                        width: 1.125rem;
                    }
                }

                &-prev,
                &-next {
                    position: absolute;
                    width: auto;
                    height: auto;
                    // padding: 1rem;
                    z-index: 40;
                    top: calc(50% - 0.813rem); // to make up for the top padding

                    &:before {
                        display: none;
                    }

                    &:not(.slick-disabled):hover {
                        background-color: transparent !important;

                        svg,
                        svg path {
                            fill: var(--primary-color);
                        }
                    }

                    &.slick-disabled {
                        &:hover {
                            background-color: transparent !important;
                        }
                    }
                }
            }
        }

        .slick-scrollbar {
            margin-top: 30px;
            appearance: none;
            -webkit-appearance: none;
            width: 100%;
            height: 1rem;
            background: rgba(67, 66, 68, 0.1);
            outline: none;
            border-radius: 10px;
            max-width: calc(65.25rem - 1.5rem); // 348px of each card * 3
            margin-inline: auto;
            box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.08);
            transition: all 0.3s ease;

            &:hover {
                opacity: 1;
            }

            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: ${widthValue};
                height: 1rem;
                background: #434244;
                cursor: pointer;
                border-radius: 10px;
            }

            &::-moz-range-thumb {
                width: var(--width-value);
                height: 1rem;
                background: #434244;
                cursor: pointer;
                border-radius: 10px;
            }
        }
    }

    .business-models-card {
        position: relative;
        max-width: calc(20.25rem + 1.5rem); // 324px + 24px of margin
        border-radius: 10px 10px;
        margin: 0 0.75rem;
        width: 100%;
        min-height: 700px;

        &.not-eligible {
            pointer-events: none;

            .business-models-card-container {
                background-color: ${themeBase.color.neutral100};
            }
        }

        ${media.tablet`
            height: 70rem;
        `}

        .isRecommended {
            border-radius: 0 !important;
        }

        &-container {
            position: relative;

            .business-models-card-not-eligile {
                display: flex;
                flex-direction: column;
                align-items: center;

                .not-eligible {
                    &-title {
                        font-size: 0.875rem;
                        font-weight: 500;
                        margin-bottom: 0.5rem;
                        margin-top: 0.875rem;
                    }

                    &-reason {
                        font-size: 0.875rem;
                    }

                    &-button {
                        pointer-events: auto;
                    }

                    hr {
                        width: 1.5rem;
                    }
                }
            }
        }

        &-wrapper {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;

            .business-models-card-header {
                padding: 1.5rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: #6c757d;
                border-radius: 10px 10px 0 0;
                position: relative;
                width: 100%;
                flex: 1 1 15%;

                &::after {
                    content: '';
                    width: 1rem;
                    height: 1rem;
                    background-color: #6c757d;
                    position: absolute;
                    transform: rotate(45deg);
                    bottom: -0.5rem;
                }

                .header {
                    color: ${themeBase.color.neutral50};
                    text-transform: uppercase;
                    font-weight: 600;
                    margin-bottom: 0.25rem;
                    text-align: center;
                }

                .subheader {
                    color: ${themeBase.color.neutral50};
                    font-weight: 400;
                    font-size: 0.875rem;
                    text-align: center;
                }
            }

            .business-models-card-container {
                border-style: solid;
                border-color: ${themeBase.color.neutral200};
                border-width: 0 1px 1px;
                border-radius: 0 0 10px 10px;
                padding: 2.5rem 1.5rem 1.5rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex: 1 1 85%;
                width: 100%;

                .business-models-card-body {
                    margin-bottom: 1rem;
                }

                .business-models-card-actions {
                    div {
                        margin-top: 2rem;
                    }
                }

                .business-models-card-body,
                .business-models-card-summary,
                .business-models-card-actions {
                    h5 {
                        font-size: 0.875rem;
                        font-weight: 600;
                        color: #6c757d;
                        margin: 0.25rem 0 1.5rem;
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            height: 1px;
                            background-color: #6c757d;
                            left: 0;
                            right: 0;
                            bottom: -0.5rem;
                        }
                    }

                    .business-models-card-section {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 1rem;

                        div {
                            margin-bottom: 0.5rem;
                        }

                        label {
                            font-weight: 600;
                            font-size: 0.75rem;
                            color: ${themeBase.color.neutral600};
                            margin: 0 0 0.25rem;
                            text-transform: uppercase;
                        }

                        .owner {
                            font-weight: 400;
                            font-size: 0.875rem;
                            color: ${themeBase.color.neutral800};
                        }

                        .item {
                            font-weight: 400;
                            font-size: 0.75rem;
                            color: ${themeBase.color.neutral800};
                        }

                        .return {
                            font-weight: 600;
                            font-size: 1rem;
                            color: ${themeBase.color.neutral800};
                        }
                        .info-label {
                            margin-left: 10px;
                            font-size: 0.7rem;
                            color: ${themeBase.color.neutral600};
                        }

                        .business-models-card-check {
                            margin-right: 0.5rem;
                            margin-top: 0.125rem;
                            min-width: 0.938rem;
                            min-height: 0.75rem;

                            path {
                                fill: ${themeBase.color.success600};
                                stroke: ${themeBase.color.success600};
                            }
                        }
                    }
                }
            }
        }

        .recommended {
            position: absolute;
            background: var(--primary-color-bleached);
            border: 1px solid var(--primary-color);
            border-radius: 10px 10px 0px 0px;
            text-align: center;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            text-transform: uppercase;
            top: -29px;
            padding: 5px 0;
            width: 100%;
            color: var(--primary-color);
        }

        &.selected {
            .business-models-card-container {
                border-color: var(--primary-color);
            }

            .business-models-card-header {
                background-color: var(--primary-color);

                &::after {
                    background-color: var(--primary-color);
                }
            }

            .business-models-card-actions {
                button {
                    background-color: var(--primary-color-bleached);
                }
            }
        }
    }

    .bm-grants {
        width: 100%;
        background-color: var(--paper-color);
        border: 1px solid ${themeBase.color.neutral200};
        border-radius: 10px;
        padding: 2rem 3.5rem;
        margin-top: 2rem;
        margin-bottom: 2rem;

        display: flex;
        flex-direction: column;

        ${media.mobile`
            padding: 2rem;
        `}

        &-title {
            &:not(.collapsed) {
                margin-bottom: 1.5rem;
            }

            span {
                color: ${themeBase.color.neutral800};
                font-weight: ${themeBase.fontWeight.semibold};
                font-size: 1rem;
                line-height: 22px;
            }

            hr {
                background-color: var(--primary-color);
                border-radius: 1.5px;
                border-top: initial;
                height: 3px;
                margin: calc(0.5rem - 3px) 0 0;
                width: 70px;
                opacity: 1;
            }

            .bm-grants-icon {
                svg {
                    width: 20px;
                    height: 12px;

                    path {
                        fill: var(--primary-color);
                    }
                }
            }

            .bm-grants-customAction {
                color: black;
                font-size: 0.875rem;
                font-weight: 400;
                text-decoration: underline;
                text-transform: uppercase;
            }
        }

        &-table {
            &-add {
                display: flex;
                align-items: center;
                padding: 8px;

                ${media.mobile`
                    padding-top: 1rem;
                `}
            }

            .remote-dialog-table {
                ${media.tablet`
                    overflow-x: scroll;
                `}

                .table-hover {
                    ${media.tablet`
                        min-height: 0 !important;
                    `}
                }

                .tbody {
                    ${media.tablet`
                        min-height: 0 !important;
                    `}
                }

                .thead .th,
                .tbody .td {
                    padding: 0.5rem 1rem;

                    &:last-of-type {
                        justify-content: center;

                        div {
                            width: auto !important;
                        }
                    }
                }
            }
        }

        .delete-icon {
            height: 22px !important;
            width: auto !important;

            path {
                fill: var(--primary-color);
            }
        }
    }

    .bm-project-summary {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        row-gap: 2rem;
        padding-top: 2rem;

        &-title {
            margin-bottom: 1.5rem;

            span {
                color: ${themeBase.color.neutral800};
                font-weight: ${themeBase.fontWeight.semibold};
                font-size: 1rem;
                line-height: 22px;
            }

            hr {
                background-color: var(--primary-color);
                border-radius: 1.5px;
                border-top: initial;
                height: 3px;
                margin: calc(0.5rem - 3px) 0 0;
                max-width: 70px;
                opacity: 1;
            }
        }

        &-card {
            width: 100%;
            background-color: var(--paper-color);
            border: 1px solid ${themeBase.color.neutral200};
            border-radius: 10px;
            padding: 2rem 3.5rem;

            display: flex;
            flex-direction: column;
            row-gap: 1.5rem;

            ${media.mobile`
                padding: 2rem;
            `}

            .adicional-cost-container {
                display: flex;
                justify-content: space-between;

                ${media.mobile`
                    flex-direction: column;
                `}
            }

            .label-container {
                font-size: 0.875rem;
                line-height: 19px;
                font-weight: 400;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 50%;
            }

            .input-container {
                ${inputContainer};

                &-allowance {
                    ${inputContainer};
                    display: flex;
                    flex-flow: column nowrap;
                    align-items: flex-start;
                    row-gap: 1rem;

                    .MuiFormControl-root {
                        .MuiFormControlLabel-label {
                            font-size: 0.875rem;
                        }

                        .MuiInputBase-root {
                            font-size: 0.875rem;
                        }

                        .unique-input {
                            width: 320px;
                        }

                        .yearly-value-input {
                            width: 280px;
                        }

                        .yearly-years-input {
                            width: 240px;
                        }

                        .unit {
                            font-size: 0.875rem;
                        }
                    }

                    &-inner {
                        .Mui-error {
                            margin-left: 0;
                            margin-right: 0;
                        }
                    }
                }

                &.bold {
                    font-weight: ${themeBase.fontWeight.semibold};
                }

                &.total {
                    font-size: 1rem;
                }

                &-info {
                    max-width: fit-content;
                    display: flex;
                    align-items: center;
                    column-gap: 0.5rem;
                    padding-top: 0.5rem;
                    margin-left: auto;
                }
            }
        }

        &-installation-prices {
            display: flex;
            flex-direction: column;
            gap: 2rem;

            label {
                font-weight: ${themeBase.fontWeight.semibold};
                font-size: 0.875rem;
                line-height: 19px;
            }

            .MuiOutlinedInput-root {
                width: 300px;
            }

            .info-container {
                display: flex;
                align-items: center;
                gap: 4px;
                margin-top: 4px;

                svg,
                path {
                    fill: ${themeBase.color.info600};
                }

                span {
                    font-size: 0.75rem;
                    line-height: 1px;
                    color: ${themeBase.color.info600};
                }
            }
        }

        &-commercial-margin {
            .bm-margin {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;

                ${media.tablet`
                    flex-direction: column;
                    align-items: start;`}

                &-label {
                    color: ${themeBase.color.neutral800};
                    font-weight: ${themeBase.fontWeight.semibold};
                    font-size: 0.875rem;
                    line-height: 19px;
                }

                &-slider {
                    flex: 1;
                    padding: 0 5.25rem;

                    .MuiSlider-rail {
                        background-color: var(--primary-color);
                        opacity: 0.2;
                        height: 3px;
                    }

                    .MuiSlider-track {
                        background-color: var(--primary-color);
                        height: 3px;
                    }

                    .MuiSlider-valueLabelCircle {
                        font-size: 0.75rem;
                    }

                    ${media.tablet`
                        width: 100%;
                        padding: 0;
                    `}

                    .MuiSlider-markLabel {
                        display: none;
                    }
                }

                &.new {
                    flex-direction: column;

                    .bm-margin-label,
                    .bm-margin-slider {
                        width: 100%;
                    }

                    .bm-margin-slider {
                        padding: 0;
                        display: flex;
                        justify-content: space-between;

                        .MuiSlider-root {
                            width: 60%;
                        }

                        .MuiSlider-markLabel {
                            display: none;
                        }
                    }

                    .margin-slider-container {
                        display: flex;
                        align-items: center;

                        &-controller {
                            position: relative;

                            .MuiFormHelperText-root {
                                position: absolute;
                                bottom: -2.65rem;
                            }
                        }

                        .margin-marks {
                            .MuiSlider-mark {
                                background-color: gray;
                            }

                            .MuiSlider-markActive {
                                background-color: var(--primary-color);
                            }
                        }

                        .MuiInputBase-root {
                            width: 11.875rem;
                            margin-left: 3rem;
                        }

                        p {
                            margin-left: 3rem;
                        }

                        .MuiSlider-mark {
                            height: 17px !important;
                            width: 3px !important;
                            background-color: var(--primary-color) !important;
                            opacity: 0.2;
                        }

                        .MuiSlider-markActive {
                            background-color: var(--primary-color) !important;
                            opacity: 1;
                        }

                        .MuiSlider-markLabel {
                            top: 22px !important;
                        }
                    }
                }
            }

            .bm-kpis-margin {
                display: flex;

                padding: 0.875rem;
                background-color: var(--primary-color-bleached);
                border: 1px solid var(--primary-color);
                border-radius: 3px;
                width: 100%;

                ${media.tablet`
                    flex-direction: column;
                `}

                &-kpi {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-right: 1px solid var(--primary-color);

                    &:last-of-type {
                        border-right: none;
                    }

                    color: var(--primary-color);
                    font-size: 0.875rem;

                    ${media.tablet`
                        border-right: none;
                        border-bottom: 1px solid var(--primary-color);
                        padding: 1rem 0;

                        &:last-of-type {
                            border-bottom: none;
                            padding-bottom: 0;
                        }

                        &:first-of-type {
                            padding-top: 0 !important;
                        }
                    `}

                    label {
                        text-transform: uppercase;
                    }

                    span {
                        font-weight: 700;
                    }
                }
            }
        }

        &-offer-details {
            .offer-details-container {
                display: flex;
                width: 50%;
                align-items: center;

                .label-container {
                    font-size: 0.875rem;
                    line-height: 19px;
                    font-weight: 400;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                }

                .input-container {
                    display: flex;
                    flex-direction: column;
                }
            }

            .bm-inputs-extra {
                position: relative;
                display: flex;
                flex-direction: column;

                &-input {
                    display: flex;
                    flex-direction: column;

                    label {
                        font-weight: ${themeBase.fontWeight.semibold};
                        font-size: 0.875rem;
                        line-height: 19px;

                        margin-bottom: 0.5rem;
                    }
                }

                &-default-button {
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin: 2.375rem;
                }
            }

            &-header {
                display: flex;

                h1 {
                    font-size: 0.875rem;
                    font-weight: 600;
                    color: ${themeBase.color.neutral800};
                    margin-bottom: 0;
                }

                h2 {
                    margin-left: 0.3125rem;
                    margin-bottom: 0;
                    font-size: 0.875rem;
                    font-weight: 400;
                    text-transform: capitalize;
                    color: ${themeBase.color.neutral800};
                }
            }

            .bm-project-summary-base-cost,
            .bm-project-summary-total-cost {
                display: flex;
                justify-content: space-between;

                span {
                    font-size: 0.875rem;
                    font-weight: 600;
                    color: ${themeBase.color.neutral800};
                }
            }

            .offer-details-title {
                font-weight: 600;
                font-size: 1rem;
                color: ${themeBase.color.neutral800};
                position: relative;
                width: max-content;

                &::after {
                    content: '';
                    width: 65%;
                    height: 3px;
                    border-radius: 2px;
                    background-color: ${themeBase.color.info};
                    position: absolute;
                    bottom: -0.5rem;
                    left: 0;
                }
            }

            .offer-details-subtitle {
                display: inline-block;
                margin: 1.5rem 0 1rem;
                font-weight: 600;
                font-size: 0.875rem;
                color: var(--primary-color);
            }

            &-separator {
                background-color: ${themeBase.color.neutral400};
                height: 1px;
                width: 100%;
                margin: 0 0 1rem !important;
                border-radius: 2px;
            }

            .offer-details-inputs-additional-cost {
                .label,
                .cost {
                    font-size: 0.875rem;
                    color: ${themeBase.color.neutral800};
                    font-weight: 400;
                }
            }

            .offer-details-inputs-warranties {
                .warranties-btn {
                    min-width: 0;
                    width: 19px;
                    height: 19px;
                    margin: 0 1rem;
                    padding: 0;
                    padding-bottom: 0.09rem;

                    .MuiTypography-root {
                        color: ${themeBase.color.neutral600};
                    }
                }

                .warranties-text {
                    margin: 0 0.5rem;

                    span {
                        margin-right: 0.3125rem;
                    }
                }
            }

            .offer-details-inputs-optional {
                .label-container {
                    max-width: max-content;
                }
            }

            .summary_offer {
                overflow-x: auto;
            }
        }

        &-contact-info {
            .info-details {
                display: flex;
                gap: 2rem;
                width: 100%;

                ${media.tablet`
                    flex-direction: column;
                    gap: 1rem;
                `}
            }

            label {
                font-weight: ${themeBase.fontWeight.semibold};
                font-size: 0.875rem;
                line-height: 19px;
            }

            .MuiInputBase-root {
                width: 100%;

                ${media.tablet`
                    width: 270px;
                `}

                ${media.mobile`
                                    width: 100%;`}
            }
        }

        .bm-proposal-generation {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            margin-top: 3.125rem;
            margin-bottom: 2rem;

            &-back {
                margin-right: auto;
            }

            &-button {
                margin-left: auto;
            }
        }

        &-submit {
            margin-top: 2.5rem;
            display: flex;
            justify-content: flex-end;
            position: relative;

            button {
                position: absolute;
                margin: 0.5rem;
            }
        }
    }

    .bm-proposal-generation {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-bottom: 2rem;
        margin-top: 1rem;

        &-back {
            margin-right: auto;
        }

        &-button {
            margin: auto;
        }
    }

    .bm-kpis {
        display: flex;
        padding: 0.875rem;
        background-color: var(--primary-color-bleached);
        border: 1px solid var(--primary-color);
        border-radius: 3px;
        width: 100%;
        margin-top: 1.5rem;

        ${media.tablet`
            flex-direction: column;
        `}

        &-kpi {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-right: 1px solid var(--primary-color);

            &:last-of-type {
                border-right: none;
            }

            ${media.tablet`
                border-right: none;
                border-bottom: 1px solid var(--primary-color);
                padding: 1rem 0;

                &:last-of-type {
                    border-bottom: none;
                    padding-bottom: 0;
                }

                &:first-of-type {
                    padding-top: 0 !important;
                }
            `}

            color: var(--primary-color);
            font-size: 0.875rem;

            label {
                text-transform: uppercase;
                text-align: center;
            }

            span {
                font-weight: 700;
            }
        }
    }

    .bm-detailed {
        &-offer-summary {
            width: 100%;
            background-color: var(--paper-color);
            border: 1px solid ${themeBase.color.neutral200};
            border-radius: 10px;
            padding: 2rem 3.5rem;
            margin-top: 2rem;
            margin-bottom: 2rem;

            display: flex;
            flex-direction: column;
            position: relative;

            ${media.mobile`
                padding: 2rem;
            `}

            &-finance {
                padding-bottom: 1.5rem;
                border-bottom: 1px dashed ${themeBase.color.neutral400};
            }

            &-costs {
                padding-top: 1.5rem;
            }

            .title-actions-container {
                display: flex;
                justify-content: space-between;

                ${media.mobile`
                    flex-direction: column;
                    gap: 1rem;
                `}
            }

            .input-container {
                font-size: 0.875rem;
                line-height: 19px;
                font-weight: 400;

                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &:not(:last-child) {
                    margin-bottom: 1rem;
                }

                label {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                    align-items: center;

                    .mini-label {
                        font-size: 0.75rem;
                    }
                }

                .MuiOutlinedInput-root {
                    width: 190px;
                }

                &-allowance {
                    ${inputContainer};
                    display: flex;
                    flex-flow: column nowrap;
                    align-items: flex-start;
                    row-gap: 1rem;

                    .MuiFormControl-root {
                        .MuiFormControlLabel-label {
                            font-size: 0.875rem;
                        }

                        .MuiInputBase-root {
                            font-size: 0.875rem;
                        }

                        .unique-input {
                            width: 320px;
                        }

                        .yearly-value-input {
                            width: 280px;
                        }

                        .yearly-years-input {
                            width: 240px;
                        }

                        .unit {
                            font-size: 0.875rem;
                        }
                    }

                    &-inner {
                        .Mui-error {
                            margin-left: 0;
                            margin-right: 0;
                        }
                    }
                }

                &.bold {
                    font-weight: ${themeBase.fontWeight.semibold};
                }

                &.total {
                    font-size: 1rem;
                }

                &-info {
                    max-width: fit-content;
                    display: flex;
                    align-items: center;
                    column-gap: 0.5rem;
                    padding-top: 0.5rem;
                    margin-left: auto;
                }

                ${media.mobile`
                    flex-direction: column;
                    gap: 0.5rem;

                    //target if not class .total
                    &.total-container {
                        flex-direction: row;
                    }

                    .position-relative {
                        width: 100%;
                    }

                    label {
                        width: 100%;
                    }

                    .MuiOutlinedInput-root {
                        width: 100%;
                    }
                `}

                &-decimal-buttons {
                    display: flex;
                    flex-direction: column;
                    gap: 0.3rem;
                    margin-left: 0.5rem;
                }
            }

            .offer-details {
                &-container {
                    display: flex;
                    width: 50%;
                    align-items: center;

                    .label-container {
                        font-size: 0.875rem;
                        line-height: 19px;
                        font-weight: 400;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 50%;
                    }

                    .input-container {
                        display: flex;
                        flex-direction: column;
                    }
                }

                &-title {
                    font-weight: 600;
                    font-size: 1rem;
                    color: ${themeBase.color.neutral800};
                    position: relative;
                    width: max-content;

                    &::after {
                        content: '';
                        width: 65%;
                        height: 3px;
                        border-radius: 2px;
                        background-color: ${themeBase.color.info};
                        position: absolute;
                        bottom: -0.5rem;
                        left: 0;
                    }
                }

                &-subtitle {
                    display: inline-block;
                    margin: 0 0 1rem;
                    font-weight: 600;
                    font-size: 0.875rem;
                }
            }
        }

        &-negotiation {
            width: 100%;
            background-color: var(--paper-color);
            border: 1px solid ${themeBase.color.neutral200};
            border-radius: 10px;
            padding: 2rem 3.5rem;
            margin-top: 2rem;
            margin-bottom: 2rem;

            display: flex;
            flex-direction: column;

            ${media.mobile`
                padding: 2rem;
            `}

            &-margin {
                &.new {
                    flex-direction: column;
                    margin-top: 24px;

                    label,
                    .margin-slider-container {
                        width: 100%;
                    }

                    label {
                        font-weight: ${themeBase.fontWeight.semibold};
                    }

                    .margin-slider-title {
                        font-size: 0.875rem;
                        font-weight: 500;
                        margin: 0;
                        display: flex;
                        gap: 8px;
                        align-items: center;
                    }

                    .margin-slider-container {
                        display: flex;
                        align-items: center;
                        padding: 0;
                        display: flex;

                        ${media.mobile`
                                                    flex-direction: column;
                                                        `}

                        .MuiSlider-root {
                            width: 60%;

                            ${media.mobile`
                                width: 100%;
                                margin: 1rem 0 2rem;
                            `}
                        }

                        &-controller {
                            position: relative;

                            ${media.mobile`
                                width: 100%;
                            `}
                        }

                        .margin-marks {
                            .MuiSlider-mark {
                                background-color: gray;
                            }

                            .MuiSlider-markActive {
                                background-color: var(--primary-color);
                            }
                        }

                        .MuiInputBase-root {
                            width: 11.875rem;
                            margin-left: 3rem;

                            ${media.mobile`
                                width: 100%;
                                margin: 0;
                            `}
                        }

                        p {
                            margin-left: 3rem;
                        }

                        .MuiSlider-mark {
                            height: 17px !important;
                            width: 3px !important;
                            background-color: var(--primary-color) !important;
                            opacity: 0.2;
                        }

                        .MuiSlider-markActive {
                            background-color: var(--primary-color) !important;
                            opacity: 1;
                        }

                        .MuiSlider-markLabel {
                            top: 1.875rem !important;

                            ${media.mobile`
                                top: 2.5rem !important;
                            `}
                        }
                    }
                }
            }

            &-kpis {
                color: var(--primary-color);
                margin-top: 1.5rem;

                label {
                    font-size: 0.875rem;
                    font-weight: 600;
                    margin-bottom: 0.625rem;
                }

                .kpis-table {
                    width: 100%;
                    background-color: var(--primary-color-bleached);
                    padding: 0.875rem;
                    border: 1px solid var(--primary-color);
                    border-radius: 3px;

                    display: flex;
                    align-items: center;
                    justify-content: space-around;

                    ${media.mobile`
                        flex-direction: column;
                    `}

                    .kpi {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;

                        .label {
                            text-transform: uppercase;
                        }

                        .value {
                            font-weight: 700;
                        }
                    }

                    .divider {
                        min-height: 74px;
                        width: 1px;
                        background-color: var(--primary-color);

                        ${media.mobile`
                            width: 100%;
                            min-height: 1px;
                            margin: 10px 0;
                        `}
                    }
                }

                .action-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 1rem;

                    ${media.mobile`
                        flex-direction: column;
                        align-items: flex-start;
                    `}
                }
            }
        }

        &-proposal {
            gap: 1.5rem;
        }
    }

    .bm-common {
        &-title {
            span {
                color: ${themeBase.color.neutral800};
                font-weight: ${themeBase.fontWeight.semibold};
                font-size: 1rem;
                line-height: 22px;
            }

            .efz-tooltip-icon-info {
                height: 22px;
                margin-top: -4px;

                svg {
                    margin: 0 !important;
                    margin-left: 0.5rem !important;
                }
            }

            hr {
                background-color: var(--primary-color);
                border-radius: 1.5px;
                border-top: initial;
                height: 3px;
                margin: calc(0.5rem - 3px) 0 0;
                max-width: 70px;
                opacity: 1;
            }
        }
    }
`;

export const StyledBusinessModelsProContainter = styled(StyledBusinessModelsPro)`
    padding: var(--spacing-600) var(--spacing-750);
`;

export const StyledBMDialog = styled(Dialog)`
    .MuiPaper-root {
        padding: 1.5rem;
        min-width: 552px;
    }

    .bm-dialog-header {
        display: flex;
        flex-direction: column;

        .bm-dialog-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            h1 {
                margin: 0;
                color: ${themeBase.color.info600};
                font-family: 'Poppins';
                font-size: 1rem;
            }

            svg {
                path {
                    fill: ${themeBase.color.info600};
                }
            }
        }

        hr {
            border-top: 1px solid ${themeBase.color.info200};
            width: 100%;
            margin: 0.25rem 0 1rem;
            opacity: 1;
        }
    }

    .bm-dialog-body {
        label {
            font-size: 0.875rem;
            font-weight: ${themeBase.fontWeight.semibold};
            line-height: 1.5rem;
            margin-bottom: 1rem;
        }

        .bm-dialog-grant-item {
            width: 100%;
            display: flex;
            align-items: flex-start;
            flex: 1;

            padding: 0.875rem;
            background-color: ${themeBase.color.neutral100};
            border: 1px solid ${themeBase.color.neutral200};
            border-radius: 5px;
            margin-bottom: 0.5rem;

            svg {
                margin-right: 0.625rem;
                min-width: 1.25rem;
            }
        }
    }
`;
