import { themeBase } from 'constants/styles/global';
import { styled } from 'styled-components';
import { media } from 'styles/utils/mediaQueries';

export const StyledApp = styled.div`
    .default-svg {
        ~ svg {
            path {
                fill: #202020;
            }
        }
        &--disabled {
            svg {
                path {
                    fill: var(--disabled-color-icon);
                }
            }
        }
        &--open {
            svg {
                path {
                    fill: var(--primary-color);
                }
            }
        }
    }

    .r-icons {
        &-center-group {
            font-size: 1rem;
            .local-circle {
                display: none;
            }
        }
        &-center-group:hover {
            .local-circle {
                display: block;
            }
        }
    }
    .plug-icon {
        fill: ${themeBase.color.info600};
        stroke: ${themeBase.color.info600};
    }
    .power-icon {
        fill: ${themeBase.color.info600};
    }
    .add-Box-outlined-icon,
    .restaurant-icon,
    .hotel-icon,
    .clock-icon,
    .parking-icon,
    .electric-car-icon {
        path {
            fill: var(--primary-color);
        }
    }

    .selected-checkbox-icon {
        #rect2 {
            stroke: var(--primary-color);
        }
        #check {
            stroke: var(--primary-color);
            fill: var(--primary-color);
        }
    }

    .check-icon,
    .close-icon {
        rect,
        path {
            stroke: var(--primary-color);
        }
    }

    .jr-card {
        padding: ${themeBase.cardPadding};
        margin-bottom: ${themeBase.cardMargin};
        position: relative;

        & > .jr-card-body:first-child {
            margin-top: 6px;
        }

        &.p-0 > .jr-card-header:first-child,
        &.p-0 > .jr-card-body:first-child {
            margin-top: 0;
        }

        & .jr-card-thumb {
            margin: (-${themeBase.cardPadding}) (-${themeBase.cardPadding}) 0;
        }

        @media screen and (max-width: 575px) {
            margin-bottom: 18px;
            padding: 5px 10px;
        }

        &-full {
            padding: 0;
        }
    }

    .jr-card-header {
        margin-bottom: ${themeBase.cardMargin};

        .card-heading {
            margin-bottom: calc(${themeBase.cardMargin} / 12);
            font-size: 1rem;
        }

        &:first-child {
            margin-top: -4px;
        }

        & .badge {
            padding: 2px 5px;
            margin-bottom: 0;
            &:last-child {
                margin-left: 10px;
            }
        }

        @media screen and (max-width: 575px) {
            margin-bottom: 18px;
        }

        & .icon-btn:last-child {
            margin-top: -12px;
            margin-right: -12px;
            -ms-flex-align: start;
            align-self: flex-start;
        }
    }

    .card-img {
        border-radius: ${themeBase.borderRadius.base};
    }

    .card-img-top {
        border-top-left-radius: ${themeBase.borderRadius.base};
        border-top-right-radius: ${themeBase.borderRadius.base};
    }

    .card-img-bottom {
        border-bottom-right-radius: ${themeBase.borderRadius.base};
        border-bottom-left-radius: ${themeBase.borderRadius.base};
    }

    .card-header:first-child {
        border-top-left-radius: ${themeBase.borderRadius.base};
        border-top-right-radius: ${themeBase.borderRadius.base};
    }

    .card-footer:last-child {
        border-bottom-right-radius: ${themeBase.borderRadius.base};
        border-bottom-left-radius: ${themeBase.borderRadius.base};
    }

    .table {
        > :not(:first-child) {
            border-top: inherit;
        }

        .redirect-option {
            color: inherit;
            cursor: pointer;

            &:hover {
                color: $info;
                text-decoration-color: ${themeBase.color.info};
                text-decoration: underline;
            }

            &-disabled {
                pointer-events: none;
            }
        }
        .tolltip-option {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    ${media.tablet`
    .me-lg-2 {
        margin-right: 1rem
    }`}
`;
