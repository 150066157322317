import { SET_BUSINESS_MODEL_MONTHLY_PAYMENT, SET_BUSINESS_MODEL_OP_AND_MAN_DURATION } from './../../constants/businessModels';
import {
    RESET_BUSINESS_MODEL,
    SET_BUSINESS_MODEL_ANNUAL_FEE_UPDATE,
    SET_BUSINESS_MODEL_EXTRA_COST,
    SET_BUSINESS_MODEL_FINAL_KPIS,
    SET_BUSINESS_MODEL_KPIS,
    SET_BUSINESS_MODEL_KPIS_ADD_ON_HELPER,
    SET_BUSINESS_MODEL_MARGIN,
    SET_BUSINESS_MODEL_PROPOSAL_READY,
    SET_BUSINESS_MODEL_RUBRIC,
    SET_BUSINESS_MODEL_SLIDER_READY,
    SET_BUSINESS_MODEL_VALUES,
    RESET_BUSINESS_MODEL_KPIS_ADD_ON_HELPER,
} from 'constants/businessModels';
import copy from 'fast-copy';
import { DetailedOfferEditionEvents, IDetailedBMAction, TDetailedState } from 'interfaces/businessModels/detailed';
import { TRubric } from 'interfaces/rubric';
import { isDefined, removeFieldEmptyInObj } from 'services/util/auxiliaryUtils';

export const initialBMLoadings = {
    general: false,
    simpleSimulation: false,
    detailedSimulation: false,
    auditFilesDownload: false,
    proposal: false,
    getProposalNoKpis: false,
    addOnHelper: false,
};

export const initialBmState: TDetailedState = {
    businessModelSelected: null,
    businessModelBody: null,
    offerEdition: null,
    kpis: null,
    addOnHelper: {
        formData: {
            simulator_var: '',
            value: '',
        },
        info: {
            text: null,
            min_monthly_fee: null,
            max_monthly_fee: null,
            new_min_monthly_fee: null,
            new_max_monthly_fee: null,
        },
    },
    defaultNegotiation: null,
    action: null,
    offerEditionValues: {
        monthly_payment_option_id: null,
        monthlyPayment: null,
        extraCost: null,
        annualUpdateFee: null,
        opAndManDuration: null,
        allowance: {},
        rubrics: [],
    },
    elements: {
        monthlyPayments: null,
        annualUpdateMonthlyFee: null,
        uniqueAllowance: null,
        yearlyAllowance: null,
        omContractDuration: null,
        recOwnership: null,
        ownershipTransfer: null,
        itcRate: null,
    },
    sliderReady: true,
    proposalReady: false,
    reqsHash: null,
    loadings: initialBMLoadings,
};

export const bmReducer = (state = initialBmState, action: IDetailedBMAction) => {
    const currentKpis = state.kpis ?? {};
    // console.log("efz-> action", action)
    switch (action.type) {
        case DetailedOfferEditionEvents.SET_BUSINESS_MODEL_SELECTED: {
            let costRubrics: TRubric[] = [];
            if (action?.payload?.kpis?.kpis) {
                const { system } = action.payload.kpis.kpis;
                const { costs } = system;
                const costRubricsAll = Object.keys(costs);
                costRubricsAll.forEach((item) => {
                    let rubrics = costs?.[item]?.details?.filter((el) => el?.editable_cost) ?? [];
                    if (rubrics?.length > 0) {
                        rubrics = rubrics.map((elm, key) => {
                            elm.key = key;
                            elm.name = item;
                            elm.default_selected = elm.included_cost; //o costo esta incluido no custo base?
                            elm[item] = elm.included_cost ? parseInt(elm.final_cost) : 0;
                            return elm;
                        });
                        costRubrics = [...costRubrics, ...rubrics];
                    }
                });
            }
            return {
                ...state,
                businessModelSelected: action?.payload?.businessModelSelected,
                offerEdition: action?.payload?.offerEdition,
                businessModelBody: action?.payload?.businessModelBody,
                reqsHash: action?.payload?.kpis?.req_id_arr,
                kpis: {
                    ...action?.payload?.kpis,
                    kpis: {
                        ...action?.payload?.kpis?.kpis,
                        system: {
                            ...action?.payload?.kpis?.kpis?.system,
                            rubricsCost: costRubrics,
                        },
                    },
                    negotiation: {
                        ...(action?.payload?.kpis?.negotiation ?? {}),
                        max_monthly_fee: action.payload?.kpis?.negotiation?.max_monthly_fee,
                    },
                },
                defaultNegotiation: {
                    ...(action?.payload?.kpis?.negotiation ?? {}),
                    max_monthly_fee: action.payload?.kpis?.negotiation?.max_monthly_fee,
                },
                elements: action.payload.elements,
            };
        }
        case SET_BUSINESS_MODEL_VALUES: {
            const {
                monthly_payment_option_id = '',
                annualEvoFee = 0,
                extraCost = 0,
                rubrics = [],
                opAndManDuration = '',
                allowance = '',
                unique_allowance_currency = '',
                yearly_allowance_currency = '',
                yearly_allowance_duration_years = '',
                recOwnership = '',
                ownershipTransfer = '',
                itcRate = '',
            } = action.payload.values;

            const newRubrics: TRubric[] = copy(rubrics);
            if (isDefined(rubrics)) {
                newRubrics.forEach((rubric) => {
                    if (rubric?.included_cost) rubric[rubric.name] = parseInt(rubric?.final_cost);
                    else rubric[rubric.name] = 0;
                });
            }
            return {
                ...state,
                sliderReady: action.payload.sliderReady,
                offerEditionValues: {
                    ...state.offerEditionValues,
                    monthly_payment_option_id,
                    annualUpdateFee: annualEvoFee,
                    extraCost,
                    opAndManDuration,
                    monthlyPayment: state?.offerEditionValues?.monthlyPayment ?? state.kpis?.negotiation?.default ?? null,
                    allowance: {
                        unique_allowance_currency: allowance === 'unique' ? Number(unique_allowance_currency) : -1,
                        yearly_allowance_currency: allowance === 'yearly' ? Number(yearly_allowance_currency) : -1,
                        yearly_allowance_duration_years: allowance === 'yearly' ? Number(yearly_allowance_duration_years) * 12 : -1,
                    },
                    boolean_rec_ownership: recOwnership,
                    boolean_ownership_transfer: ownershipTransfer,
                    itc_rate_prc: itcRate,
                    rubrics: newRubrics,
                },
            };
        }
        case SET_BUSINESS_MODEL_MARGIN: {
            return {
                ...state,
                offerEditionValues: {
                    ...state.offerEditionValues,
                    monthlyPayment: Number(action.payload),
                },
                kpis: {
                    ...currentKpis,
                    finalKpis: null,
                },
            };
        }
        case SET_BUSINESS_MODEL_KPIS: {
            return {
                ...state,
                action: null,
                kpis: {
                    ...state.kpis,
                    ...action.payload.kpis,
                    negotiation: {
                        ...state.kpis?.negotiation,
                        ...(action?.payload?.kpis?.negotiation ?? {}),
                        max_monthly_fee: action?.payload?.kpis?.negotiation?.max_monthly_fee,
                    },
                },
                sliderReady: action.payload.sliderReady,
                reqsHash: action.payload.reqsHash,
                loadings: initialBMLoadings,
            };
        }
        case SET_BUSINESS_MODEL_KPIS_ADD_ON_HELPER: {
            const add_on_helper_pre_selected =
                !action?.payload?.hasError ? action?.payload?.kpis?.negotiation?.efective_min_monthly_fee : null;

            let newNegotiation = {};
            if (action?.payload?.info?.text) {
                newNegotiation = {
                    ...action?.payload?.kpis?.negotiation,
                    min_monthly_fee: Math.min(action?.payload?.info?.new_min_monthly_fee, state?.defaultNegotiation?.min_monthly_fee),
                    max_monthly_fee: Math.max(action?.payload?.info?.new_max_monthly_fee, state?.defaultNegotiation?.max_monthly_fee),
                    add_on_helper_pre_selected: action?.payload?.kpis?.negotiation?.efective_min_monthly_fee,
                };
            }

            return {
                ...state,
                addOnHelper: {
                    formData: action?.payload?.formData,
                    info: {
                        ...action?.payload?.info,
                        min_monthly_fee: state.defaultNegotiation?.min_monthly_fee,
                        max_monthly_fee: state.defaultNegotiation?.max_monthly_fee,
                    },
                },
                kpis: {
                    ...state.kpis,
                    ...action.payload.kpis,
                    negotiation: {
                        ...state.kpis?.negotiation,
                        // update    //
                        add_on_helper_pre_selected,
                        ...newNegotiation,
                    },
                },
                loadings: initialBMLoadings,
            };
        }
        case RESET_BUSINESS_MODEL_KPIS_ADD_ON_HELPER: {
            return {
                ...state,
                addOnHelper: initialBmState.addOnHelper,
                kpis: {
                    ...state?.kpis,
                    negotiation: {
                        ...state?.defaultNegotiation,
                        // update //
                        add_on_helper_pre_selected: action?.payload?.kpis?.negotiation?.pre_selected,
                    },
                },
                loadings: initialBMLoadings,
            };
        }
        case SET_BUSINESS_MODEL_PROPOSAL_READY: {
            return {
                ...state,
                proposalReady: action.payload,
            };
        }
        case SET_BUSINESS_MODEL_FINAL_KPIS: {
            const inputs = removeFieldEmptyInObj({
                monthly_fee: action?.payload?.inputs?.monthly_fee ?? null,
            });

            const currentHash = state.reqsHash ? state.reqsHash[0] : [];

            const _finalKpis =
                action.payload?.status ?
                    {
                        ...(action?.payload?.finalKpis ?? {}),
                        ...inputs,
                    }
                :   null;
            return {
                ...state,
                action: null,
                kpis: {
                    ...currentKpis,
                    finalKpis: _finalKpis,
                },
                reqsHash: [currentHash, ...action.payload.reqHash],
                loadings: action.payload.showLoading ? initialBMLoadings : { ...state.loadings },
            };
        }
        case DetailedOfferEditionEvents.SET_BUSINESS_MODEL_LOADINGS:
            return {
                ...state,
                loadings: {
                    ...state.loadings,
                    ...action.payload.reduce((obj, cur) => ({ ...obj, [cur.name]: cur.value }), {}),
                },
            };
        case DetailedOfferEditionEvents.RESET_BUSINESS_MODEL_LOADINGS:
            return {
                ...state,
                action: null,
                loadings: initialBMLoadings,
            };
        case DetailedOfferEditionEvents.SET_DETAILED_ACTION: {
            return {
                ...state,
                action: action.payload,
            };
        }
        // @ts-ignore
        case SET_BUSINESS_MODEL_MONTHLY_PAYMENT:
            return {
                ...state,
                offerEditionValues: {
                    ...state.offerEditionValues,
                    monthly_payment_option_id: action.payload,
                },
            };
        // @ts-ignore

        case SET_BUSINESS_MODEL_OP_AND_MAN_DURATION:
            return {
                ...state,
                offerEditionValues: {
                    ...state.offerEditionValues,
                    opAndManDuration: action.payload,
                },
            };
        // @ts-ignore

        case SET_BUSINESS_MODEL_ANNUAL_FEE_UPDATE:
            return {
                ...state,
                offerEditionValues: {
                    ...state.offerEditionValues,
                    annualUpdateFee: action.payload,
                },
            };
        // @ts-ignore

        case SET_BUSINESS_MODEL_EXTRA_COST:
            return {
                ...state,
                offerEditionValues: {
                    ...state.offerEditionValues,
                    extraCost: action.payload,
                },
            };
        // @ts-ignore

        case SET_BUSINESS_MODEL_RUBRIC: {
            const { rubrics } = action.payload;
            const newRubrics = copy(rubrics);
            newRubrics.forEach((rubric) => {
                if (rubric?.selected) rubric[rubric?.name] = rubric?.default_cost;
                else rubric[rubric?.name] = 0;
            });

            return {
                ...state,
                offerEditionValues: {
                    ...state.offerEditionValues,
                    rubrics: newRubrics,
                },
            };
        }
        // @ts-ignore

        case SET_BUSINESS_MODEL_SLIDER_READY:
            return {
                ...state,
                sliderReady: action.payload,
            };
        case RESET_BUSINESS_MODEL:
            return initialBmState;
        default:
            return state;
    }
};

export const bmLoadingsReducer = (state = initialBMLoadings, action) => {
    switch (action) {
        case 'simpleSimulation':
            return {
                ...state,
                general: true,
                simpleSimulation: true,
            };
        case 'detailedSimulation':
            return {
                ...state,
                general: true,
                detailedSimulation: true,
            };
        case 'auditFilesDownload':
            return {
                ...state,
                general: true,
                auditFilesDownload: true,
            };
        case 'proposal':
            return {
                ...state,
                general: true,
                proposal: true,
            };
        case 'reset':
            return initialBMLoadings;
        default:
            return initialBMLoadings;
    }
};
