import { displayFlex, themeBase } from 'constants/styles/global';
import { css, styled } from 'styled-components';
import { media } from 'styles/utils/mediaQueries';

import headerBg from 'assets/images/products/spv-simple/landing-page/header-bg.png';

const headerContainerCSS = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: ${themeBase.headerHeight};
    min-height: ${themeBase.headerHeight};

    background-color: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
    padding: 0rem 1.5rem;
    z-index: 1000;
`;

const headerContainerPPCCSS = css`
    ${headerContainerCSS};

    &.landing {
        background-image: url(${headerBg});
        background-size: cover;
        background-repeat: no-repeat;

        .company-description {
            color: var(--paper-color);
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
        }

        .menu-item {
            background-color: inherit !important;

            &-large {
                background-color: inherit !important;

                &:hover,
                &:focus {
                    .menu-item-content {
                        h5,
                        h6 {
                            color: var(--paper-color) !important;
                        }
                    }
                }
            }

            &-large,
            &-large-expanded,
            &-expanded,
            &:hover,
            &:focus {
                background-color: inherit !important;
                & .default-svg svg path,
                & .default-svg--open svg path {
                    fill: var(--paper-color) !important;
                }
            }

            &-content {
                border-right-color: var(--paper-color);

                .menu-item-text > h5,
                .menu-item-text > h6 {
                    color: var(--paper-color);
                }

                .language-icon {
                    font-weight: 500;
                    color: var(--paper-color);
                }

                &-last {
                    svg {
                        path {
                            fill: var(--paper-color);
                        }
                    }
                }

                &.only-one-card {
                    .language-icon {
                        border: 1px solid white;
                        border-radius: 50%;
                        padding: 0.5rem;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }

    &:not(.landing) {
        background: var(--paper-color);

        .company-description {
            color: var(--text-color);
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
        }

        .menu-item {
            &-large,
            &-large-expanded,
            &-expanded,
            &:hover,
            &:focus {
                background-color: inherit !important;
                & .default-svg svg path,
                & .default-svg--open svg path {
                    fill: var(--paper-color) !important;
                }
            }
            &-content {
                .menu-item-text > h5,
                .menu-item-text > h6 {
                    color: var(--text-color);
                }

                .language-icon {
                    font-weight: 500;
                    color: var(--text-color);
                }

                &-last {
                    svg {
                        path {
                            fill: var(--text-color);
                        }
                    }
                }

                &.only-one-card {
                    .language-icon {
                        border: 1px solid var(--text-color);
                        border-radius: 50%;
                        padding: 0.5rem;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }

    &.open-area {
        justify-content: space-between;

        .header-menu {
            flex: unset;
        }
    }
`;

const menuItemLarge = css`
    ${displayFlex('flex', 'row', 'nowrap')};
    min-width: 250px;
    ${media.tablet`
                    min-width: unset !important;
                `}

    .dropdown {
        display: flex;
        height: 100%;
        width: 100%;
    }
`;

const menuItem = css`
    ${displayFlex('flex', 'row', 'nowrap')};
    display: flex;
    align-items: center;
    margin: 0px;
    list-style: none;

    .dropdown {
        display: flex;
        height: 100%;
    }
`;

const menuItemContentCSS = css`
    ${displayFlex('flex', 'row', 'nowrap')};
    height: 100%;
    padding: 0 1rem;
    align-items: center;
    border-right: 1px solid var(--divider-color);
    position: relative;
    pointer-events: none;
    // icon
    & > span:not(.language-icon) {
        align-self: center;
        ${media.min.tablet`
            margin-right: 1rem;
        `}
    }

    & h5 {
        color: var(--text-color);
        margin: 0;
        font-weight: ${themeBase.fontWeight.normal};
        align-self: center;
    }

    &-last {
        ${displayFlex('flex', 'row', 'nowrap')};
        height: 100%;
        padding: 0 1rem;
        align-items: center;
        border-right: 1px solid var(--divider-color);
        position: relative;
        pointer-events: none;
        // icon
        & > span {
            align-self: center;
            ${media.min.tablet`
            margin-right: 1rem;
        `}
        }

        & h5 {
            color: var(--text-color);
            margin: 0;
            font-weight: ${themeBase.fontWeight.normal};
            align-self: center;
        }
        border-right: unset;
    }
    .language-icon {
        color: var(--primary-color);
    }
`;

const menuItemLabelCSS = css`
    color: var(--text-color);
    margin: 0;
    font-weight: ${themeBase.fontWeight.normal};
    align-self: flex-start;
`;

const testCSS = css`
    ul.menu-list {
        ${displayFlex('flex', 'row', 'nowrap')};
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;

        & li.menu-item {
            ${menuItem};

            &-large {
                ${menuItemLarge};

                &-expanded {
                    ${menuItemLarge};
                    background-color: ${themeBase.bodyBg};

                    .menu-item-text h6 {
                        line-height: 0;
                    }
                }

                &:last-child .menu-item-content .menu-item-content-button-form-forward {
                    border: none;
                }

                &:hover,
                &:focus {
                    background-color: ${themeBase.bodyBg};
                    cursor: pointer;

                    & span svg:not(.main-header-flag) path {
                        fill: var(--primary-color);
                    }

                    .menu-item-text h5 {
                        color: var(--primary-color);
                    }
                }

                .dropdown {
                    display: flex;
                    height: 100%;
                    width: 100%;
                }
            }

            &-expanded {
                ${menuItem};
                background-color: ${themeBase.bodyBg};

                .menu-item-text h6 {
                    line-height: 0;
                }
            }

            &:last-child .menu-item-content .menu-item-content-button-form-forward {
                border: none;
            }

            &:hover,
            &:focus {
                background-color: ${themeBase.bodyBg};
                cursor: pointer;

                & span svg:not(.main-header-flag) path {
                    fill: var(--primary-color);
                }

                .menu-item-text h5 {
                    color: var(--primary-color);
                }
            }

            .dropdown {
                display: flex;
                height: 100%;
            }
        }
    }
    .header-dropdown-toggle {
        width: 100%;
        padding: 1rem 0rem;
    }
`;

export const StyledHeader = styled.div`
    ${headerContainerCSS};

    .header {
        &-efz-logo {
            max-height: 20px;
            height: 100%;
        }

        &-small-efz-logo {
            max-height: 30px;
            height: 100%;
            margin-right: 1rem;
        }

        &-logo {
            height: 100%;
            margin-left: 1.5rem;
            max-height: 2.875rem;
            img {
                height: 100%;
                max-height: 34px;
            }

            &-placeholder {
                margin: 0;
                width: 50px !important;
                height: 50px !important;
                border-radius: 50%;
            }
        }
    }

    .company-description {
        font-size: 1.125rem;
        font-weight: ${themeBase.fontWeight.semibold};

        /* ${media.mobile`
            display: none;
        `} */
    }

    .header-menu {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        align-items: center;
    }

    ${testCSS};

    // Displays the icon and text
    .only-one-card {
        border-right: none !important;
    }
    .menu-item-content {
        ${menuItemContentCSS};
    }

    .menu-item-content-forward {
        ${menuItemContentCSS};
        padding: 0rem !important;

        .button-form {
            min-height: ${themeBase.headerHeight};
            max-height: ${themeBase.headerHeight};
            ${media.mobile`
                min-height: 60px;
                max-height: 60px;
            `}
            text-transform: none;
            padding: 0 1rem !important;
            box-shadow: none;
            background-color: var(--paper-color);
            border-radius: 0px;

            &:disabled {
                color: none;
                box-shadow: none;
                background-color: var(--paper-color);
            }

            &:active,
            &:hover {
                background-color: ${themeBase.bodyBg} !important;
                box-shadow: none;
            }
        }
    }

    // Text of each dropdown menu item displayed after icon
    .menu-item-text {
        display: flex;
        flex-flow: column nowrap;
        margin-left: 0.5rem;

        .menu-item-label {
            ${menuItemLabelCSS};

            &--open {
                ${menuItemLabelCSS};
                color: var(--primary-color);
            }
            &--disabled {
                ${menuItemLabelCSS};
                cursor: default;
                color: var(--disabled-color-icon);
            }
        }

        & > h6 {
            color: var(--text-color-secondary);
            margin: 0;
            font-weight: ${themeBase.fontWeight.normal};
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 9rem;
        }

        &:hover {
            color: var(--paper-color) !important;
        }

        ${media.tablet`
                    & > h5 {
                display: none;
            }

            & > h6 {
                display: none;
            }
        `};
    }

    // Arrow of each menu item displayed after icon
    .menu-item-arrow {
        color: ${themeBase.silverLight};
        margin-left: 0.5rem;
        margin-right: -0.75rem;
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 1rem;

        svg {
            font-size: 1.2rem;
            transition: all linear 0.2s;
        }

        &--closed {
            align-items: center;
            color: #b1b1b1;
            display: flex;
            height: 1.5rem;
            justify-content: center;
            margin-left: 0.5rem;
            margin-right: -0.75rem;
            position: absolute;
            right: 1rem;
            width: 1.5rem;
            transition: all 0.2s linear;
        }

        &--open {
            align-items: center;
            color: #b1b1b1;
            display: flex;
            height: 1.5rem;
            justify-content: center;
            margin-left: 0.5rem;
            margin-right: -0.75rem;
            position: absolute;
            right: 1rem;
            width: 1.5rem;
            transition: all 0.2s linear;
            transform: rotate(180deg);
        }
    }

    // Generic header action button (inside dropdown)
    button.facility-arrow {
        border-radius: 50%;
        background-color: var(--primary-color);
        min-width: 25px;
        max-width: 25px;
        max-height: 25px;
        font-size: 1.25rem;
        padding: 0;

        i {
            color: var(--paper-color);
        }

        &:hover {
            i {
                color: var(--primary-color) !important;
            }
        }
    }

    // Container for the facility dropdown buttons
    .facility-dropdown-buttons {
        ${displayFlex('flex', 'row', 'nowrap')};
        align-items: center;
        border-top: 1px solid var(--divider-color);
        flex-grow: 1;
        justify-content: center;
        background-color: #f5f5f5;
        height: 40px;
    }

    .section-btn-add {
        border-top: 1px solid var(--divider-color);

        .MuiButton-root {
            color: var(--text-color);
        }
    }

    // languages dropdown
    .language {
        &-icon {
            text-transform: uppercase;
            font-size: 0.875rem;
            font-weight: ${themeBase.fontWeight.semibold};
            line-height: 1.05rem;
        }
        &-italic {
            font-style: Italic;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.313rem;
            margin-top: 0.125rem;
        }
        &-list {
            li {
                padding: 0rem 1rem;

                & > div {
                    padding: 0.75rem 0rem;
                    border-bottom: 1px solid var(--divider-color);
                    line-height: 1.313rem;
                }
                &:first-child {
                    padding-top: 0.25rem;
                }
            }

            li:last-child {
                padding-bottom: 0.25rem;

                & > div {
                    border-bottom-color: transparent;
                }
            }

            li:hover {
                background-color: var(--primary-color);

                h5,
                .language-icon,
                .language-italic,
                span {
                    color: var(--paper-color) !important;
                }

                .default-svg--open svg path {
                    fill: var(--paper-color);
                }
            }
            li.active {
                color: var(--primary-color);

                h5 {
                    color: var(--primary-color);
                }
            }

            h5 {
                color: var(--text-color);
                font-size: 0.875rem;
                font-weight: ${themeBase.fontWeight.semibold};
                line-height: 1.313rem;
            }
            .language-icon {
                line-height: 1.313rem;
            }
        }
    }

    // the flag that appears in the header
    .main-header-flag {
        font-size: 1.5rem !important;
        height: auto !important;
        box-shadow: 0px 0px 0px 1px var(--divider-color);
    }

    .dropdown-menu {
        width: 22rem;
        border-radius: 0;
        padding: 0;
        background-color: var(--paper-color);
        margin: 0;
        border-top: none !important;
        cursor: default;
        transform: translate3d(0px, 70px, 0px) !important;
        top: 1px !important;
    }

    .sidebar-opener {
        border-radius: 2px;
        padding: 0;
        display: flex;
        align-items: center;

        span {
            color: var(--primary-color);
            font-size: 0.75rem;
            font-weight: ${themeBase.fontWeight.semibold};
            margin-right: 0.5rem;
        }

        svg {
            path {
                stroke: var(--primary-color);
            }
        }
    }

    .header-search-card-text {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        /* identical to box height */

        color: #202020;
    }
    // Autocomplete Header \\
    .autocomplete-header {
        margin-top: 0px;

        .MuiAutocomplete-root {
            padding: 0.5rem 1rem 1rem 1rem !important;
        }

        .MuiTextField-root {
            width: 100%;
        }

        .MuiInputBase-root {
            padding-right: 10px;

            i.zmdi.zmdi-search {
                font-size: 1.5rem;
            }
        }

        .MuiAutocomplete-popper {
            width: 100% !important;
            position: inherit !important;
            border-top: 1px solid var(--divider-color);
            background-color: #f5f5f5;
            transform: inherit !important;

            .MuiAutocomplete-paper {
                margin: 0 !important;
            }

            .MuiAutocomplete-listbox {
                overflow-x: hidden !important;
                padding: 0;

                &::-webkit-scrollbar-track,
                &::-webkit-scrollbar-track-piece {
                    background-color: #f5f5f5;
                }
            }
        }

        .MuiAutocomplete-option {
            padding: 0.75rem 1rem !important;
            color: initial;
            border-bottom: 1px solid var(--divider-color);

            &:last-child {
                border-bottom: 0;
            }

            &:hover {
                background-color: var(--primary-color) !important;

                h5,
                h6 {
                    color: var(--paper-color) !important;
                }
            }

            span {
                color: var(--text-color);
                text-overflow: ellipsis !important;
                overflow: hidden !important;
                white-space: nowrap;
                font-weight: ${themeBase.fontWeight.normal};
            }
        }

        .MuiAutocomplete-noOptions,
        .MuiAutocomplete-loading {
            font-size: 0.875rem;
            line-height: 1.25rem;
        }

        .autocomplete-header-option-wrapper {
            display: flex;
            flex-flow: column nowrap;

            ${media.min.tablet`
                max-width: 100%;

            `}

            & > h5 {
                color: var(--text-color);
                margin: 0;
                font-weight: 400;
                align-self: flex-start;
                font-size: 0.875rem;
            }

            & > h6 {
                color: var(--text-color-secondary);
                margin: 0;
                font-weight: 400;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 0.75rem;
            }

            &:hover {
                color: var(--paper-color) !important;
            }
        }

        .autocomplete-loader {
            flex: unset;
            min-width: 0;
        }
    }

    .dropdown-header-section {
        ${displayFlex('flex', 'row', 'nowrap')};
        padding: 1rem;
        align-items: center;
        border-bottom: 1px solid var(--divider-color);

        .btn-actions {
            margin-top: 0.75rem !important;
        }

        ${media.tablet`
            .btn-actions {
                .MuiButton-root {
                    font-size: .75rem !important;
                }
            }
        `};

        .header-section-text {
            ${displayFlex('flex', 'column', 'nowrap')};
            flex: 1;

            h5 {
                color: var(--text-color);
                font-size: 0.875rem;
            }

            h6 {
                color: var(--text-color-secondary);
                font-size: 0.75rem;
            }

            .clear-selection-button {
                display: flex;
                align-items: center;
                text-transform: uppercase;
                font-size: 0.75rem;
                color: var(--text-color);
                text-decoration: underline;

                &:hover {
                    color: var(--primary-color);
                    cursor: pointer;
                }
            }

            .hidde-button {
                visibility: hidden;
                display: flex;
                align-items: center;
                text-transform: uppercase;
                font-size: 0.75rem;
                color: var(--text-color);

                &:hover {
                    color: var(--primary-color);
                    cursor: pointer;
                }
            }

            .client-info {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
            }
        }

        svg {
            fill: var(--primary-color);
            path {
                fill: var(--primary-color);
            }
        }

        /* button {
            display: flex;
            flex-grow: 1;
            min-width: 30px;
            max-width: 45px;
            max-height: 50px;
            padding: 10px 5px;
            margin-left: 0.5rem;
            font-size: 1.5rem;
        } */

        &.clickable {
            cursor: pointer;
        }

        &:hover {
            .facility-arrow {
                background-color: var(--paper-color) !important;

                i {
                    color: var(--primary-color) !important;
                }
            }
        }
    }

    .sales-segment {
        margin-left: 1.5rem;
    }
    .sales-segment-button {
        height: 35px !important;
        font-family: 'Save2Compete' !important;
        color: var(--primary-color) !important;
        padding-left: 0 !important;
        padding-right: 0 !important;

        &:hover {
            background: var(--paper-color) !important;
        }

        .arrow-icon {
            path {
                fill: var(--primary-color);
            }

            &.selected {
                transition: 0.3s transform;
                transform: rotate(180deg);
            }

            &.unselected {
                transition: 0.3s transform;
                transform: rotate(0deg);
            }
        }
    }

    .sales-segment-menu {
        .MuiList-root {
            padding: 0 !important;

            .MuiMenuItem-root {
                font-family: 'Save2Compete' !important;
                font-size: 1rem;
                color: var(--primary-color) !important;
                &:hover,
                &.Mui-selected {
                    background-color: var(--primary-color) !important;
                    color: var(--paper-color) !important;
                }
            }
        }
    }

    .user-card {
        align-items: unset;

        .tertiary-button {
            cursor: pointer;
            margin: 0 !important;
            text-transform: uppercase;
            font-size: 0.75rem !important;

            &:hover {
                color: var(--primary-color);
            }
        }
    }

    &.ppc {
        background: linear-gradient(90deg, #045931, #05784b 52.6%, #058f5c);
        color: var(--paper-color);

        .menu-item {
            background-color: inherit !important;

            &-large {
                background-color: inherit !important;

                &:hover,
                &:focus {
                    .menu-item-content {
                        h5,
                        h6 {
                            color: var(--paper-color) !important;
                        }
                    }
                }
            }

            &-large,
            &-large-expanded,
            &-expanded,
            &:hover,
            &:focus {
                background-color: inherit !important;
                & .default-svg svg path,
                & .default-svg--open svg path {
                    fill: var(--paper-color) !important;
                }
            }

            &-content {
                border-right-color: var(--paper-color);

                .menu-item-text > h5,
                .menu-item-text > h6 {
                    color: var(--paper-color);
                }

                .language-icon {
                    font-weight: 500;
                    color: var(--paper-color);
                }

                &-last {
                    svg {
                        path {
                            fill: var(--paper-color);
                        }
                    }
                }

                &.only-one-card {
                    .language-icon {
                        border: 1px solid white;
                        border-radius: 50%;
                        padding: 0.5rem;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .sidebar-opener {
            span {
                color: var(--paper-color);
            }

            svg path {
                stroke: var(--paper-color);
            }
        }
    }
`;

export const StyledPPCHeader = styled(StyledHeader)`
    font-family: 'Ping LCG', 'Open Sans';
    &.b2c {
        ${headerContainerPPCCSS};

        ${media.tablet`
            padding: 0;
        `};

        .sidebar-opener {
            span {
                color: var(--paper-color);
            }

            svg path {
                stroke: var(--paper-color);
            }
        }

        .company-description {
            color: var(--paper-color);
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
        }

        .menu-item {
            background-color: inherit !important;

            &-large {
                background-color: inherit !important;

                &:hover,
                &:focus {
                    .menu-item-content {
                        h5,
                        h6 {
                            color: var(--paper-color) !important;
                        }
                    }
                }
            }

            &-large,
            &-large-expanded,
            &-expanded,
            &:hover,
            &:focus {
                background-color: inherit !important;
                & .default-svg svg path,
                & .default-svg--open svg path {
                    fill: var(--paper-color) !important;
                }
            }

            &-content {
                border-right-color: var(--paper-color);

                .menu-item-text > h5,
                .menu-item-text > h6 {
                    color: var(--paper-color);
                }

                .language-icon {
                    font-weight: 500;
                    color: var(--paper-color);
                }

                &-last {
                    svg {
                        path {
                            fill: var(--paper-color);
                        }
                    }
                }

                &.only-one-card {
                    .language-icon {
                        border: 1px solid white;
                        border-radius: 50%;
                        padding: 0.5rem;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }

    &.b2b {
        ${headerContainerPPCCSS};
    }
`;
