import MuiTooltip from '@mui/material/Tooltip';
import { CSSProperties } from 'react';
import IntlMessages from 'components/util/IntlMessages';
import { ReactComponent as InfoIcon } from 'assets/images/icons/svg/info-tooltip.svg';
import { StyledTooltipIcon } from 'components/@efz/Tooltip/StylesTooltip';

type ITooltip = {
    children?: React.ReactElement;
    title: React.ReactChild;
    type?: 'info' | 'error' | 'warning' | 'default' | 'success';
    placement?:
        | 'top'
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | undefined;
    defaultStyle?: CSSProperties;
    style?: CSSProperties;
    className?: string;
    iconClassName?: string;
    arrowPlacement?:
        | 'default'
        | 'bottom-end'
        | 'bottom-start'
        | 'left-end'
        | 'left-start'
        | 'right-end'
        | 'right-start'
        | 'top-end'
        | 'top-start';
    enterDelay?: number;
    disableHoverListener?: boolean;
    open?: boolean | undefined;
    disablePortal?: boolean;
};

//TODO: ajustar o tipo de children, se for um button, provocará problema. ver documentação de MUI corrigir o disabledIconButton
const Tooltip: React.FC<ITooltip> = ({
    title,
    children,
    type,
    placement,
    defaultStyle,
    style,
    className,
    iconClassName,
    arrowPlacement,
    enterDelay,
    disableHoverListener,
    open,
    disablePortal,
}) => {
    return (
        <MuiTooltip
            disableHoverListener={disableHoverListener}
            placement={placement}
            title={<div className="efz-tooltip-content">{title}</div>}
            enterTouchDelay={0}
            enterDelay={enterDelay ?? undefined}
            arrow
            open={open ?? undefined}
            PopperProps={{
                disablePortal,
                className: `efz-tooltip MuiTooltip-popper MuiTooltip-popperInteractive MuiTooltip-popperArrow efz-tooltip-${type} ${className} efz-tooltip-arrow-${arrowPlacement}`,
                style: {
                    ...defaultStyle,
                    ...style,
                },
            }}
        >
            <span>
                {children ?
                    children
                :   <StyledTooltipIcon
                        $type={type}
                        size={'small'}
                        className={`efz-tooltip-icon-${type ? type : 'info'} ${iconClassName}`}
                        disableFocusRipple
                        disableRipple
                    >
                        <InfoIcon width={12} height={12} />
                    </StyledTooltipIcon>
                }
            </span>
        </MuiTooltip>
    );
};
//defaultProps
Tooltip.defaultProps = {
    title: <IntlMessages id="efz.title.loremIpsum" />,
    type: 'info',
    placement: 'top',
    defaultStyle: Object.freeze({
        // insert more default styling props here;
        // otherwise, pass them via 'style' prop
        maxWidth: '210px',
    }),
    style: {},
    className: '',
    iconClassName: '',
    arrowPlacement: 'default',
    open: undefined,
    disablePortal: false,
};

export default Tooltip;
