// Constants
import { BACKOFFICE_PRODUCTS, BACKOFFICE_SECTIONS } from 'constants/backoffice';

// Interfaces
import { IBOMenu, IBOMenuState, IOnDiscardHandlers, IOnSaveHandlers } from 'interfaces/backoffice/menus';
import { Nullable } from './../../types/utils';

export interface BOReducerAction {
    type: BOActions;
    payload: any;
}

export enum BOLoadings {
    templatesProductID = 'templatesProductID',
    templatesBMs = 'templatesBMs',
    templatesDocs = 'templatesDocs',
    templatesDownloadDocs = 'templatesDownloadDocs',
    templatesUploadDocs = 'templatesUploadDocs',
    templatesProposalDetails = 'templatesProposalDetails',
    templatesPostSimulation = 'templatesPostSimulation',
    templatesSupportDocs = 'templatesSupportDocs',
    templatesPutDoc = 'templatesPutDoc',
    templatesProposalList = 'templatesProposalList',
    templatesProposalClients = 'templatesProposalClients',
    menusGeneral = 'menusGeneral',
    menusMounting = 'menusMounting',
    menusUpdating = 'menusUpdating',
    menusRequesting = 'menusRequesting',
    menusSaving = 'menusSaving',
    menusPreviewing = 'menusPreviewing',
    menusPublishing = 'menusPublishing',
    menusDiscarding = 'menusDiscarding',
}
export type TBOLoadingsValues = (typeof BOLoadings)[keyof typeof BOLoadings];
export type BOLoadingsType = {
    [key in TBOLoadingsValues]: boolean | null;
};

export interface BOInitialState {
    product: IBOContextProduct;
    templates: IBOContextsTemplates;
    menus: IBOContextMenus;
    loadings: BOLoadingsType;
}

export interface IBOContextProduct {
    productID: number | null;
    tag: string | null;
}

export interface IBOContextsTemplates {
    businessModels: Nullable<any[]>;
    selectedBM: any | null;
    docs: any[];
    selectedDoc: any | null;
    originalUploadedDocName: any | null;
    uploadedDocAzureName: any | null;
    proposal: any | null;
    simId: any | null;
    proposalList: {
        clientList: any[];
        original: any[];
        filtered: any[];
    };
}

export interface IBOContextMenus {
    availableMenus: Array<IBOMenu> | [];
    availableStates: Array<IBOMenuState> | [];
    currentMenu: Nullable<IBOMenu>;
    currentMenuState: Nullable<IBOMenuState>;
    currentMenuPublishedStateInfo: Nullable<IBOMenuState>;
    changeToSavedState(handlers: IOnSaveHandlers, comments?: string): Promise<void>;
    changeToPreviewState(comments?: string): Promise<void>;
    changeToPublishState(comments?: string): Promise<void>;
    discardChanges(handlers: IOnDiscardHandlers): Promise<void>;
}

export enum BOActions {
    SET_LOADINGS = 'SET_LOADINGS',
    SET_PRODUCT_ID = 'SET_PRODUCT_ID',
    SET_BUSINESS_MODELS = 'SET_BUSINESS_MODELS',
    SET_SELECTED_BM = 'SET_SELECTED_BM',
    SET_DOCUMENT_TEMPLATES = 'SET_DOCUMENT_TEMPLATES',
    SET_SELECTED_DOC = 'SET_SELECTED_DOC',
    SET_UPLOAD_DOC_AZURE_NAME = 'SET_UPLOAD_DOC_AZURE_NAME',
    SET_TEMPLATE_PROPOSAL_DETAILS = 'SET_TEMPLATE_PROPOSAL_DETAILS',
    SET_SIMULATION_ID = 'SET_SIMULATION_ID',
    SET_PROPOSAL_LIST = 'SET_PROPOSAL_LIST',
    SET_FILTERED_PROPOSAL_LIST = 'SET_FILTERED_PROPOSAL_LIST',
    SET_PROPOSAL_CLIENT_LIST = 'SET_PROPOSAL_CLIENT_LIST',
    SET_RESET_TEMPLATES = 'SET_RESET_TEMPLATES',
    SET_RESET_ALL = 'SET_RESET_ALL',
    SET_MENUS = 'SET_MENUS',
    UPDATE_MENUS = 'UPDATE_MENUS',
}

export interface IBOProduct {
    title: (typeof BACKOFFICE_PRODUCTS)[keyof typeof BACKOFFICE_PRODUCTS];
    name: (typeof BACKOFFICE_SECTIONS)[keyof typeof BACKOFFICE_SECTIONS];
    label: string;
    visible(bdFlag: boolean): boolean;
    visibleFlag: string;
    icon(props: { disabled?: boolean; selected?: boolean }): JSX.Element | undefined;
    disabled(): boolean;
    sections: Array<{
        title: string;
        name: string;
        label: string;
        navigationLabel: string;
        visible(bdFlag: boolean): boolean;
    }>;
}

export interface IBOSpecificProduct<
    Product extends (typeof BACKOFFICE_PRODUCTS)[keyof typeof BACKOFFICE_PRODUCTS],
    Section extends (typeof BACKOFFICE_SECTIONS)[keyof typeof BACKOFFICE_SECTIONS],
> {
    title: Product;
    name: Section;
    label: string;
    visible(bdFlag: boolean): boolean;
    visibleFlag: string;
    icon(props: { disabled?: boolean; selected?: boolean }): JSX.Element | undefined;
    disabled(): boolean;
    sections: Array<{
        title: string;
        name: string;
        label: string;
        navigationLabel: string;
        visible(bdFlag: boolean): boolean;
    }>;
}

export interface IBOContext {
    activeBackofficeProducts: Array<IBOProduct>;
    solarConfigs: IBOSpecificProduct<'pv', 'solar'>;
    hpConfigs: IBOSpecificProduct<'hp', 'heatpumps'>;
    boState: BOInitialState;
    boDispatchHandler(type: BOActions, payload: any): void;
}
