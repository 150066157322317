import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@save2compete/efz-design-system';
import { StyledNewUpdateDialog } from 'components/core/newUpdateDialog/StylesNewUpdateDialog';

// Compoenents
import IntlMessages from 'components/util/IntlMessages';
import { SettingsActions } from 'constants/settings';
import { SettingsContext } from 'contexts/settingsContext';
import { useContext } from 'react';

const NewUpdateDialog = () => {
    const {
        settingsState: { hasNewVersion, platformLatestVersion },
        settingsDispatch,
    } = useContext(SettingsContext);

    const handleClose = () => {
        settingsDispatch({
            type: SettingsActions.SET_HAS_NEW_VERSION,
            payload: {
                hasNewVersion: false,
            },
        });
    };

    return (
        <StyledNewUpdateDialog open={!!hasNewVersion} maxWidth="md" onClose={() => handleClose()}>
            {/* @ts-ignore */}
            <DialogTitle className=" text-center">
                {/* @ts-ignore */}
                <IntlMessages id={`dialog.newUpdate.title`} /> <sup>v{platformLatestVersion}</sup>
            </DialogTitle>

            <DialogContent className="justify-content-center text-center ws-break mb-4">
                {/* @ts-ignore */}
                <IntlMessages id={`dialog.newUpdate.description`} />
            </DialogContent>

            <DialogActions className="d-flex gap-4 justify-content-center align-items-center">
                <Button variant="tertiary" onClick={handleClose} size="bg" className="cancel-btn" dataTestId="new-update-postpone">
                    {/* @ts-ignore */}
                    <IntlMessages id={`label.postpone`} />
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        localStorage.setItem('Platform-Version', platformLatestVersion);
                        window.location.reload();
                    }}
                    size="bg"
                    dataTestId="new-update-reloadNow"
                >
                    {/* @ts-ignore */}
                    <IntlMessages id={`label.reloadNow`} />
                </Button>
            </DialogActions>
        </StyledNewUpdateDialog>
    );
};

export default NewUpdateDialog;
