import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import IntlMessages from 'components/util/IntlMessages';
import { StyledRadioLabel } from '../LabelRadio/StylesRadioLabel';

export default function RadioWithLabel({
    row,
    disabled,
    labelYes,
    labelNo,
    field,
    valueYes,
    valueNo,
    watchableValue = field.value,
    onChange = field.onChange,
    onClick = (_event) => {},
    className = '',
    disableYes = false,
    disableNo = false,
}) {
    return (
        <StyledRadioLabel row={row} className={`radio-with-label  two-options-only ${className}`} {...field} onChange={onChange}>
            <FormControlLabel
                value={valueYes}
                control={<Radio onClick={onClick} />}
                disabled={disabled || disableYes}
                className={`radio-button-${watchableValue === valueYes ? 'checked' : 'unchecked'}`}
                label={<IntlMessages id={labelYes} />}
            />
            <FormControlLabel
                value={valueNo}
                control={<Radio onClick={onClick} />}
                disabled={disabled || disableNo}
                className={`radio-button-${watchableValue === valueNo ? 'checked' : 'unchecked'}`}
                label={<IntlMessages id={labelNo} />}
            />
        </StyledRadioLabel>
    );
}

//defaultProps
RadioWithLabel.defaultProps = {
    row: true,
    labelYes: 'label.yes',
    labelNo: 'label.no',
    valueYes: 'true',
    valueNo: 'false',
};
