import { ReactComponent as SolutionIcon } from 'assets/@efz/icons/solutions.svg';
import { ReactComponent as ProcessesIcon } from 'assets/@efz/icons/processes.svg';
import { ReactComponent as BackofficeIcon } from 'assets/@efz/icons/backoffice.svg';
import {
    BASENAME_URL_BACKOFFICE,
    BASENAME_URL_PROCESSES_OM,
    BASENAME_URL_PROCESSES_PROPOSALS,
    BASENAME_URL_PROCESSES_SITE_SURVEY,
    BASENAME_URL_SOLUTIONS,
} from './settings';

export const SIDEBAR_BUTTONS = {
    OPEN_SIDEBAR: 'openSidebar',
    SOLUTIONS: 'solutions',
    PROCESSES: 'processes',
    BACKOFFICE: 'backoffice',
};

export const SIDEBAR_BUTTONS_SECTIONS = {
    PROPOSALS: 'proposals',
    SITE_SURVEY: 'siteSurvey',
    ASSET_MANAGEMENT: 'assetManagement',
};

export const SIDEBAR_BUTTONS_CONFIG = {
    DEFAULT: [
        {
            name: SIDEBAR_BUTTONS.SOLUTIONS,
            label: 'sidebar.solutions',
            icon: <SolutionIcon />,
            disabled: function () {
                return false;
            },
            navigationLabel: BASENAME_URL_SOLUTIONS,
            visible: true,
            canBeAccessed: () => true,
        },
        {
            name: SIDEBAR_BUTTONS.PROCESSES,
            label: 'sidebar.processes',
            icon: <ProcessesIcon />,
            disabled: function () {
                return false;
            },
            sections: [
                {
                    name: SIDEBAR_BUTTONS_SECTIONS.PROPOSALS,
                    label: 'sidebar.processes.proposals',
                    navigationLabel: BASENAME_URL_PROCESSES_PROPOSALS,
                    visible: (_bdFlag) => true,
                },
                {
                    name: SIDEBAR_BUTTONS_SECTIONS.SITE_SURVEY,
                    label: 'sidebar.processes.siteSurvey',
                    navigationLabel: BASENAME_URL_PROCESSES_SITE_SURVEY,
                    visible: (bdFlag) => bdFlag,
                },
                {
                    name: SIDEBAR_BUTTONS_SECTIONS.ASSET_MANAGEMENT,
                    label: 'sidebar.processes.OM',
                    navigationLabel: BASENAME_URL_PROCESSES_OM,
                    visible: (bdFlag) => bdFlag,
                },
            ],
            navigationLabel: BASENAME_URL_PROCESSES_PROPOSALS,
            visible: true,
            canBeAccessed: () => true,
        },
        {
            name: SIDEBAR_BUTTONS.BACKOFFICE,
            label: 'sidebar.backoffice',
            icon: <BackofficeIcon />,
            disabled: function (hasSolutionsButton = false) {
                return !hasSolutionsButton;
            },
            navigationLabel: BASENAME_URL_BACKOFFICE,
            visible: function (bdFlag) {
                return bdFlag;
            },
            canBeAccessed: (flag) => flag,
        },
    ],
};
