import { memo, useContext, useEffect, useRef } from 'react';
import IntlMessages from 'components/util/IntlMessages';
import { SPV_SIMPLE_QUESTIONS as questions, SPV_SIMPLE_INPUTS as INPUT_NAMES, spvSimpleActions } from 'constants/products/solarpvSimple';
import ErrorMessage from 'components/errors/ErrorMessage';
import { Controller, useFormContext } from 'react-hook-form';
import Skeleton from '@mui/material/Skeleton';
import ToggleButton from '@mui/material/ToggleButton';
import { SPVSimpleContext } from 'contexts/products/solarpvSimple/solarpvSimpleContext';
import useSimulation from 'hooks/products/solarpvSimple/useSimulation';
import SimulationContactsCard from './SimulationContactsCard';
import { intlMessages, isFieldDefined } from 'services/util/auxiliaryUtils';
import Alert from 'components/@efz/Alert';
import Lottie2 from 'components/@efz/Lottie/Lottie2';
import RobotAnimation from 'assets/lottie/PPC_Robot_Measuring_Calculating';
import SimulationCard from './SimulationCard';
import { Helmet } from 'react-helmet';
import { getHeadTitle } from 'services/settings';
import { useBusinessModelsStore } from 'store/businessModels';
import { Button } from '@save2compete/efz-design-system';
import { StyledToggleRadioLabel } from 'components/@efz/LabelRadio/StylesRadioLabel';

const Simulation = () => {
    // hooks
    const { requestSimulationProposal, isProposalSent, isRequestingProposal, hasError } = useSimulation();
    const {
        actions: { resetBusinessModelsStore },
    } = useBusinessModelsStore();

    const {
        spvSimpleState: { isOArea, isRequestingSims, solutions },
        spvSimpleDispatchHandler: dispatch,
        selectedCard,
        handleStartOver,
    } = useContext(SPVSimpleContext);

    const {
        control,
        formState: { errors },
    } = useFormContext();

    const contactsRef = useRef();

    useEffect(() => {
        resetBusinessModelsStore();
        dispatch(spvSimpleActions.RESET_PROPOSAL);
    }, []); // eslint-disable-line

    return (
        <div className="simulation-container">
            <Helmet>
                <title>{`${getHeadTitle()} · ${intlMessages('page.spvSimple.open.b2b.step.6')}`}</title>
            </Helmet>

            <h2>
                <IntlMessages id={'page.spvSimple.simulation.title'} />
                <hr />
            </h2>
            <div className="simulation-form-wrapper">
                {isRequestingSims ?
                    <>
                        <div className="skeletons">
                            <Lottie2
                                options={{
                                    animationData: RobotAnimation,
                                }}
                                width={300}
                                height={250}
                            />
                        </div>
                        {(!solutions?.length || isRequestingSims) && (
                            <div className="skeletons-right">
                                <Skeleton className="mt-4" variant="wave" animation="wave" height={350} />
                            </div>
                        )}
                    </>
                : solutions?.length > 0 && (!isFieldDefined(hasError) || typeof hasError === 'boolean') ?
                    <div className="simulation-form">
                        {/* Remuneration models */}
                        <div className="input-container">
                            <Controller
                                name={questions[INPUT_NAMES.REMUNERATION_TYPE_ID].name}
                                control={control}
                                error={!!errors?.[INPUT_NAMES.REMUNERATION_TYPE_ID]}
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                render={({ field: { value, ref, ...field } }) => (
                                    <StyledToggleRadioLabel
                                        {...field}
                                        value={parseInt(value)}
                                        exclusive
                                        aria-label="solarpv  REMUNERATION_MODEL"
                                        className={`remuneration-models-group-button radio-with-label`}
                                    >
                                        {solutions?.map((solution, ind) => {
                                            return (
                                                isFieldDefined(solution) && (
                                                    <ToggleButton
                                                        disabled={solution.id === selectedCard || isRequestingProposal || isProposalSent}
                                                        disableRipple
                                                        className={`remuneration-models-button-b2b ${solution.id === selectedCard ? 'selected' : ''}`}
                                                        key={`remuneration-model-${solution.id}`}
                                                        value={ind}
                                                        aria-label={`${solution.id} aligned`}
                                                        selected={solution.id === selectedCard}
                                                    >
                                                        {isFieldDefined(solution) ?
                                                            <SimulationCard solution={solution} contactsRef={contactsRef} />
                                                        :   <></>}
                                                    </ToggleButton>
                                                )
                                            );
                                        })}
                                    </StyledToggleRadioLabel>
                                )}
                            />
                            {!!errors?.[INPUT_NAMES.REMUNERATION_TYPE_ID] && (
                                <ErrorMessage error={errors[INPUT_NAMES.REMUNERATION_TYPE_ID]} />
                            )}
                        </div>

                        {/* Disclaimer */}
                        <p className="disclaimer">
                            <IntlMessages id="page.spvSimple.simulation.card.solution.disclaimer" />
                        </p>
                    </div>
                :   typeof hasError === 'string' &&
                    hasError && (
                        <Alert severity={'error'}>
                            <IntlMessages id={hasError} />
                        </Alert>
                    )
                }
                {isOArea && !isRequestingSims && solutions?.length > 0 && isFieldDefined(selectedCard) && (
                    <>
                        <SimulationContactsCard
                            ref={contactsRef}
                            sendRequestHandler={requestSimulationProposal}
                            requestSent={isProposalSent}
                            isRequestingProposal={isRequestingProposal}
                            hasError={hasError}
                            solutions={solutions}
                            selectedCard={selectedCard}
                        />
                    </>
                )}
            </div>
            <Button
                variant="secondary"
                icon="startRoundArrowReset"
                disabled={isRequestingProposal}
                onClick={handleStartOver}
                dataTestId="restart-button"
            >
                <IntlMessages id="label.restart" />
            </Button>
        </div>
    );
};

export default memo(Simulation);
