import { styled } from 'styled-components';

export const StyledImageCustom = styled.img`
    .gc-img-hw-36 {
        height: 36px;
        width: 36px;
    }

    .gc-img-hw-24 {
        height: 24px;
        width: 24px;
    }

    .gc-img-hw-30 {
        height: 30px;
        width: 30px;
    }
`;
