import { useContext, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
//SVG
import { ReactComponent as InfoIcon } from 'assets/images/icons/svg/info-circle.svg';
//MUI
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
//Components
import ProjectSummaryTitle from 'components/BusinessModels/ProjectSummary/Title';
import CustomUnit from 'components/util/CustomUnit';
import IntlMessages from 'components/util/IntlMessages';
import LabelRadio from 'components/@efz/LabelRadio';
//Constants, Interfaces and Services
import {
    INSTALLATION_PRICES_INPUT_NAMES,
    INSTALLATION_PRICES_QUESTIONS,
    INSTALLATION_FIXED_PRICE_IDS,
    INSTALLATION_PRICES_VALIDATIONS,
} from 'constants/businessModelsPro';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TUpFront, UpFrontEvents } from 'interfaces/businessModels/upfront';
import { intlMessages } from 'services/util/auxiliaryUtils';
//Context
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { HPContext } from 'contexts/products/hp/hpContext';
import { TIntl } from 'types/utils';

const InstallationPricesDetailed = () => {
    const questions = useMemo(() => INSTALLATION_PRICES_QUESTIONS, []);

    const { setBMEventHandler } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TUpFront>;

    const history = useHistory();

    const {
        hpState: {
            options: {
                installationPrices: { installation_fixed_price, installation_labor_price_per_hour },
            },
        },
    } = useContext(HPContext);

    // const { companyProfileId, userTypeID } = useContext(UserContext);

    const { control, watch } = useForm({
        defaultValues: {
            [INSTALLATION_PRICES_INPUT_NAMES.INSTALLATION_LABOR_HOURS]: 0,
            [INSTALLATION_PRICES_INPUT_NAMES.INCLUDE_FIXED_PRICE]: 0,
        },
    });

    const watchLabourHours = watch(INSTALLATION_PRICES_INPUT_NAMES.INSTALLATION_LABOR_HOURS);

    const calculateLaborCost = () => {
        if (!watchLabourHours) return 0;
        if (isNaN(Number(watchLabourHours))) return 0;
        return Number(watchLabourHours) * Number(installation_labor_price_per_hour);
    };

    const onChangeLabourHours = (laborHours: number) => {
        const installationLaborPrice = Number(laborHours) * Number(installation_labor_price_per_hour);
        setBMEventHandler(UpFrontEvents.SET_INSTALLATION_HOURS_AND_PRICE, {
            [INSTALLATION_PRICES_INPUT_NAMES.INSTALLATION_LABOR_PRICE]: installationLaborPrice,
            [INSTALLATION_PRICES_INPUT_NAMES.INSTALLATION_LABOR_HOURS]: laborHours,
        });
    };

    const onChangeInlcudeFixedPrice = (fixedPriceId: string /* , laborCostPerHour: number */) => {
        if (fixedPriceId === INSTALLATION_FIXED_PRICE_IDS.INCLUDE) {
            setBMEventHandler(UpFrontEvents.SET_INSTALLATION_FIXED_PRICE, {
                [INSTALLATION_PRICES_INPUT_NAMES.INSTALLATION_FIXED_PRICE]: installation_fixed_price,
            });
        } else if (fixedPriceId === INSTALLATION_FIXED_PRICE_IDS.NOT_INCLUDE) {
            //Send fixed price at 0 if user selects not include
            setBMEventHandler(UpFrontEvents.SET_INSTALLATION_FIXED_PRICE, {
                [INSTALLATION_PRICES_INPUT_NAMES.INSTALLATION_FIXED_PRICE]: 0,
            });
        }
    };

    const tooltipContent: React.JSX.Element = (
        <div className="d-flex flex-column gap-2">
            <article>
                <IntlMessages id={'page.businessModels.installationPrices.label.tooltip.1'} />
                <ul>
                    <li>
                        <IntlMessages
                            id={'page.businessModels.installationPrices.label.tooltip.2'}
                            values={{
                                value: installation_fixed_price,
                                // @ts-ignore
                                unit: <CustomUnit unit={'currency'} />,
                            }}
                        />
                    </li>
                    <li>
                        <IntlMessages
                            id={'page.businessModels.installationPrices.label.tooltip.3'}
                            values={{
                                value: installation_labor_price_per_hour,
                                // @ts-ignore
                                unit: <CustomUnit unit={'ch'} />,
                            }}
                        />
                    </li>
                </ul>
            </article>
            <p>
                <IntlMessages id={'page.businessModels.installationPrices.label.tooltip.4'} />
                <span className="link" onClick={() => history.push('/app/backoffice/heatpumps/instalation')}>
                    {' '}
                    <IntlMessages id="label.here" />
                </span>
                .
            </p>
        </div>
    );

    return (
        <div className="bm-project-summary-card">
            <div className="">
                <ProjectSummaryTitle label="label.installationPrices" tooltipTitle={tooltipContent} />
            </div>

            <div className="bm-project-summary-installation-prices">
                <div className="d-flex flex-column gap-2">
                    <>
                        <label>
                            <IntlMessages id={questions[INSTALLATION_PRICES_INPUT_NAMES.INSTALLATION_LABOR_HOURS].label as TIntl} />
                        </label>
                        <Controller
                            name={questions[INSTALLATION_PRICES_INPUT_NAMES.INSTALLATION_LABOR_HOURS].name}
                            control={control}
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            render={({ field: { ref, ...field } }) => (
                                <NumberFormat
                                    {...field}
                                    customInput={OutlinedInput}
                                    placeholder={intlMessages(
                                        questions[INSTALLATION_PRICES_INPUT_NAMES.INSTALLATION_LABOR_HOURS].placeholder
                                    )}
                                    onChange={(evt) => {
                                        const value = evt.target.value;
                                        field.onChange(value);
                                        onChangeLabourHours(Number(value));
                                    }}
                                    isAllowed={(values) => {
                                        const { floatValue } = values;
                                        if (floatValue === undefined) {
                                            return true;
                                        }
                                        return (
                                            floatValue >= INSTALLATION_PRICES_VALIDATIONS.MIN_LABOR_HOURS &&
                                            floatValue <= INSTALLATION_PRICES_VALIDATIONS.MAX_LABOR_HOURS
                                        );
                                    }}
                                    decimalScale={0}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {/* @ts-ignore */}
                                            <CustomUnit unit={'hours'} />
                                        </InputAdornment>
                                    }
                                />
                            )}
                        />
                    </>
                    <div className="info-container">
                        <InfoIcon width={15} height={15} />
                        <span>
                            <IntlMessages
                                id="label.totalLabourCost"
                                values={{
                                    value: calculateLaborCost(),
                                    // @ts-ignore
                                    unit: <CustomUnit unit={'currency'} />,
                                }}
                            />
                        </span>
                    </div>
                </div>
                <div className="d-flex flex-column gap-2">
                    <label>
                        <IntlMessages id={questions[INSTALLATION_PRICES_INPUT_NAMES.INCLUDE_FIXED_PRICE].label as TIntl} />
                    </label>
                    <Controller
                        name={questions[INSTALLATION_PRICES_INPUT_NAMES.INCLUDE_FIXED_PRICE].name}
                        control={control}
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        render={({ field: { ref, ...field } }) => (
                            <LabelRadio
                                field={field}
                                isRow
                                // @ts-ignore
                                options={questions[INSTALLATION_PRICES_INPUT_NAMES.INCLUDE_FIXED_PRICE]?.options?.map((option) => ({
                                    ...option,
                                    label: intlMessages(option.label),
                                }))}
                                value={watch(questions[INSTALLATION_PRICES_INPUT_NAMES.INCLUDE_FIXED_PRICE].name)}
                                onChange={(evt) => {
                                    const value = evt.target.value;
                                    field.onChange(value);
                                    onChangeInlcudeFixedPrice(value);
                                }}
                            />
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

export default InstallationPricesDetailed;
