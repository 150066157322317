import { PRODUCT_IDS } from 'constants/products';
import { ReactComponent as SolarIcon } from 'assets/@efz/icons/products/solar.svg';
import { ReactComponent as EVCIcon } from 'assets/@efz/icons/products/evc.svg';
// import { ReactComponent as BatteryIcon } from 'assets/@efz/icons/products/battery.svg';
import { ReactComponent as GBHPIcon } from 'assets/@efz/icons/products/gbhp.svg';
import { ReactComponent as IEIcon } from 'assets/@efz/icons/products/ie.svg';
import { ReactComponent as CFPIcon } from 'assets/@efz/icons/products/cfp.svg'; // CSS
import { ReactComponent as CEIcon } from 'assets/@efz/icons/products/ce.svg';
import { ReactComponent as HVACIcon } from 'assets/@efz/icons/products/hvac.svg';
// import { ReactComponent as MSIcon } from 'assets/@efz/icons/products/ms.svg';
import { ReactComponent as SANTSIcon } from 'assets/@efz/icons/products/ms.svg';
import { ReactComponent as MARIcon } from 'assets/@efz/icons/products/mar.svg';
import { ReactComponent as INTEGRAIcon } from 'assets/@efz/icons/products/mar.svg';
import { ReactComponent as CASIcon } from 'assets/@efz/icons/products/cas.svg';
import { ReactComponent as TREIcon } from 'assets/@efz/icons/products/tre.svg';
import { ReactComponent as GNSIcon } from 'assets/@efz/icons/products/gns.svg';
import { ReactComponent as VEVIcon } from 'assets/@efz/icons/products/vev.svg';
import { ReactComponent as EPIcon } from 'assets/@efz/icons/products/ep.svg';
import { ReactComponent as HeatpumpsIcon } from 'assets/@efz/icons/products/heatpumps.svg';
import { ReactComponent as RRIcon } from 'assets/@efz/icons/products/rr.svg';
import { ReactComponent as SPVMaintenanceIcon } from 'assets/@efz/icons/products/spvmaintenance.svg';

import { themeBase } from 'constants/styles/global';
import { StyledIconsCssOne } from './iconsStyled';
import styled from 'styled-components';

export const StyledIconSolar = styled(SolarIcon)`
    ${StyledIconsCssOne}
`;

export const StyledIEIcon = styled(IEIcon)`
    path {
        &:first-of-type {
            fill: ${themeBase.color.neutral800};
        }
    }
    &.selected {
        path {
            &:first-of-type {
                fill: var(--primary-color);
            }
        }
    }

    &.disabled {
        path {
            &:first-of-type {
                fill: var(--disabled-color);
            }
        }
    }
`;

export const StyledGBHPIcon = styled(GBHPIcon)`
    path {
        fill: ${themeBase.color.neutral800};
        stroke: ${themeBase.color.neutral800};
    }
    rect {
        stroke: ${themeBase.color.neutral800};
    }

    &.selected {
        path {
            fill: var(--primary-color);
            stroke: var(--primary-color);
        }
        rect {
            stroke: var(--primary-color);
        }
    }

    &.disabled {
        path {
            fill: var(--disabled-color);
            stroke: var(--disabled-color);
        }
        rect {
            stroke: var(--disabled-color);
        }
    }
`;

export const StyledHVACIcon = styled(HVACIcon)`
    ${StyledIconsCssOne}
`;

export const StyledCEIcon = styled(CEIcon)`
    ${StyledIconsCssOne}
`;

export const StyledCFPIcon = styled(CFPIcon)`
    path {
        &:nth-of-type(1) {
            fill: ${themeBase.color.neutral800};
            stroke: ${themeBase.color.neutral800};
        }

        &:nth-of-type(n + 1) {
            fill: ${themeBase.color.neutral800};
        }
    }

    &.selected {
        path {
            &:nth-of-type(1) {
                fill: var(--primary-color);
                stroke: var(--primary-color);
            }

            &:nth-of-type(n + 1) {
                fill: var(--primary-color);
            }
        }
    }
    &.disabled {
        path {
            &:nth-of-type(1) {
                fill: var(--disabled-color);
                stroke: var(--disabled-color);
            }

            &:nth-of-type(n + 1) {
                fill: var(--disabled-color);
            }
        }
    }
`;

export const StyledMARIcon = styled(MARIcon)`
    path {
        &:first-of-type,
        &:last-of-type {
            stroke: ${themeBase.color.neutral800};
        }
        fill: ${themeBase.color.neutral800};
    }

    &.selected {
        path {
            &:first-of-type,
            &:last-of-type {
                stroke: var(--primary-color);
            }
            fill: var(--primary-color);
        }
    }
    &.disabled {
        path {
            &:first-of-type,
            &:last-of-type {
                stroke: var(--disabled-color);
            }
            fill: var(--disabled-color);
        }
    }
`;

export const StyledEVCIcon = styled(EVCIcon)`
    ${StyledIconsCssOne}
`;

export const StyledHeatpumpsIcon = styled(HeatpumpsIcon)`
    path {
        &:nth-of-type(1) {
            fill: ${themeBase.color.neutral800};
            stroke: ${themeBase.color.neutral800};
        }

        &:nth-of-type(n + 1) {
            stroke: ${themeBase.color.neutral800};
        }
    }

    &.selected {
        path {
            &:nth-of-type(1) {
                fill: var(--primary-color);
                stroke: var(--primary-color);
            }

            &:nth-of-type(n + 1) {
                stroke: var(--primary-color);
            }
        }
    }
    &.disabled {
        path {
            &:nth-of-type(1) {
                fill: var(--disabled-color);
                stroke: var(--disabled-color);
            }

            &:nth-of-type(n + 1) {
                stroke: var(--disabled-color);
            }
        }
    }
`;

export const StyledINTEGRAIcon = styled(INTEGRAIcon)`
    ${StyledIconsCssOne}
`;

export const StyledSANTSIcon = styled(SANTSIcon)`
    ${StyledIconsCssOne}
`;

export const StyledCASIcon = styled(CASIcon)`
    ${StyledIconsCssOne}
`;

export const StyledTREIcon = styled(TREIcon)`
    ${StyledIconsCssOne}
`;

export const StyledGNSIcon = styled(GNSIcon)`
    ${StyledIconsCssOne}
`;

export const StyledVEVIcon = styled(VEVIcon)`
    path {
        stroke: ${themeBase.color.neutral800};
    }

    &.selected {
        path {
            stroke: var(--primary-color);
        }
    }

    &.disabled {
        path {
            stroke: var(--disabled-color);
        }
    }
`;

export const StyledEPIcon = styled(EPIcon)`
    ${StyledIconsCssOne}
`;

export const StyledRRIcon = styled(RRIcon)`
    ${StyledIconsCssOne}
`;

export const StyledSPVMaintenanceIcon = styled(SPVMaintenanceIcon)`
    g {
        path {
            fill: ${themeBase.color.neutral800};
        }
    }

    &.selected {
        g {
            path {
                fill: var(--primary-color);
            }
        }
    }

    &.disabled {
        g {
            path {
                fill: var(--disabled-color);
            }
        }
    }
`;

export const efzProductIcon = (id, isSelected, isDisabled, height = 30, width = 30) => {
    const iconClassName = (iconName) => `efz-${iconName} ${isSelected ? ' selected ' : ''}${isDisabled ? ' disabled ' : ''}`;

    let icon = null;
    switch (id) {
        case PRODUCT_IDS.SPV:
        case PRODUCT_IDS.SPVSB:
        case PRODUCT_IDS.SPV_SIMPLE:
            icon = <StyledIconSolar className={iconClassName('solar')} height={height} width={width} />;
            break;
        case PRODUCT_IDS.IE:
        case PRODUCT_IDS.IE_BR:
            icon = <StyledIEIcon className={iconClassName('ie')} height={height} width={width} />;
            break;
        case PRODUCT_IDS.GBHP: // FALTA
            icon = <StyledGBHPIcon className={iconClassName('gbhp')} height={height} width={width} />;
            break;
        case PRODUCT_IDS.HVAC:
        case PRODUCT_IDS.HVAC_BR:
            icon = <StyledHVACIcon className={iconClassName('hvac')} height={height} width={width} />;
            break;
        case PRODUCT_IDS.CE:
            icon = <StyledCEIcon className={iconClassName('ce')} height={height} width={width} />;
            break;
        case PRODUCT_IDS.CFP:
            icon = <StyledCFPIcon className={iconClassName('cfp')} height={height} width={width} />;
            break;
        case PRODUCT_IDS.MAR:
            icon = <StyledMARIcon className={iconClassName('battery')} height={height} width={width} />;
            break;
        case PRODUCT_IDS.EVC:
        case PRODUCT_IDS.EVCSB:
            icon = <StyledEVCIcon className={iconClassName('evc')} height={height} width={width} />;
            break;
        case PRODUCT_IDS.HP:
            icon = <StyledHeatpumpsIcon className={iconClassName('heatpumps')} height={height} width={width} />;
            break;
        case PRODUCT_IDS.INTEGRA:
        case PRODUCT_IDS.MPT:
            icon = <StyledINTEGRAIcon className={iconClassName('integra')} height={height} width={width} />;
            break;
        case PRODUCT_IDS.SANTS:
        case PRODUCT_IDS.SANTT:
            icon = <StyledSANTSIcon className={iconClassName('sants')} height={height} width={width} />;
            break;
        case PRODUCT_IDS.CAS:
            icon = <StyledCASIcon className={iconClassName('cas')} height={height} width={width} />;
            break;
        case PRODUCT_IDS.TRE:
            icon = <StyledTREIcon className={iconClassName('tre')} height={height} width={width} />;
            break;
        case PRODUCT_IDS.GNS:
            icon = <StyledGNSIcon className={iconClassName('gns')} height={height} width={width} />;
            break;
        case PRODUCT_IDS.VEV:
            icon = <StyledVEVIcon className={iconClassName('vev')} height={height} width={width} />;
            break;
        case PRODUCT_IDS.EP:
            icon = <StyledEPIcon className={iconClassName('ep')} height={height} width={width} />;
            break;
        case PRODUCT_IDS.RR:
            icon = <StyledRRIcon className={iconClassName('rr')} height={height} width={width} />;
            break;
        case PRODUCT_IDS.SPV_MAINTENANCE:
            icon = <StyledSPVMaintenanceIcon className={iconClassName('spv-maintenance')} height={height} width={width} />;
            break;
        default:
            break;
    }

    return icon;
};
