import { Dialog } from '@mui/material';
import styled from 'styled-components';

export const StyledNewUpdateDialog = styled(Dialog)`
    background: rgba(255, 255, 255, 0);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;

    .MuiPaper-root {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 37px 35px;
        overflow: hidden;
        position: relative;
    }

    &.brand-dialog {
        .MuiPaper-root {
            width: 100%;
            max-width: 736px;
        }
    }

    .MuiDialogTitle-root {
        padding: 0;
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 18px;
        color: var(--primary-color);
    }

    .MuiDialogContent-root {
        margin: 12px 0 24px !important;
        padding: 0 !important;
    }

    .MuiFormControlLabel-root {
        margin-left: -9px;
    }
`;
