/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @component ResetPassword
 *
 * @version 20200707
 * @since 20200707 Initial release
 *
 */

import { memo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import IntlMessages from 'components/util/IntlMessages';
import PropTypes from 'prop-types';
import OutlinedInput from '@mui/material/OutlinedInput';
import ErrorMessage from 'components/errors/ErrorMessage';
import { intlMessages, EMAIL_REGEXP } from 'services/util/auxiliaryUtils';
import { BASENAME_URL } from 'constants/settings';
import { Button } from '@save2compete/efz-design-system';
import { StyledResetPwdForm } from 'pages/auth/StylesLogin';

/**
 * FormResetPassword
 * @param {*} param0
 */
const FormResetPassword = ({ isRequesting, handleOnSubmit, isS2CVersion }) => {
    const {
        handleSubmit,
        control,

        formState: { errors },
    } = useForm({
        defaultValues: {
            email: '',
        },
    });

    const onSubmit = (payload) => handleOnSubmit(payload);

    return (
        <StyledResetPwdForm autoComplete={'false'} onSubmit={handleSubmit(onSubmit)} className="login-form">
            {/* email */}
            <div className="col-12">
                <div className="d-flex flex-column mb-3">
                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            required: 'yup.message.required',
                            pattern: {
                                value: EMAIL_REGEXP,
                                message: 'yup.message.email',
                            },
                        }}
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        render={({ field: { ref, ...field } }) => (
                            <OutlinedInput
                                {...field}
                                disabled={isRequesting}
                                placeholder={intlMessages('placeholder.emailExample')}
                                error={errors.email}
                            />
                        )}
                    />
                    {errors?.email && <ErrorMessage error={errors.email} />}
                </div>
            </div>
            {/*actions*/}
            <div className="mb-1 d-flex justify-content-center">
                <Button type="submit" disabled={isRequesting} loading={isRequesting}>
                    <IntlMessages id={'label.resetPassword'} />
                </Button>
            </div>
            <div className={`link-back-login d-flex justify-content-center reset-password-text ${isS2CVersion ? 'isS2C' : ''}`}>
                {isRequesting ? null : (
                    <h4>
                        <Link to={`${BASENAME_URL}login`}>
                            <IntlMessages id="label.backLogin" />
                        </Link>
                    </h4>
                )}
            </div>
        </StyledResetPwdForm>
    );
};

FormResetPassword.propTypes = {
    isRequesting: PropTypes.bool.isRequired,
    handleOnSubmit: PropTypes.func.isRequired,
    isS2CVersion: PropTypes.bool.isRequired,
};

export default memo(FormResetPassword);
