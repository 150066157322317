import { themeBase } from 'constants/styles/global';
import { css, styled } from 'styled-components';
import { ReactComponent as InstalationIcon } from 'assets/@efz/icons/instalation.svg';
import { ReactComponent as ClientIcon } from 'assets/@efz/icons/client.svg';

export const StyledIconsCssOne = css`
    path {
        fill: ${themeBase.color.neutral800};
    }

    &.selected {
        path {
            fill: var(--primary-color);
        }
    }

    &.disabled {
        path {
            fill: var(--disabled-color);
        }
    }
`;

export const StyledIconsCssTwo = css`
    path {
        fill: ${themeBase.color.neutral800};
        stroke: ${themeBase.color.neutral800};
    }
    &.selected {
        path {
            fill: var(--primary-color);
            stroke: var(--primary-color);
        }
    }

    &.disabled {
        path {
            fill: var(--disabled-color);
            stroke: var(--disabled-color);
        }
    }
`;

export const StyledInstalationIcon = styled(InstalationIcon)`
    ${StyledIconsCssTwo}
`;

export const StyledClientIcon = styled(ClientIcon)`
    ${StyledIconsCssTwo}
`;
