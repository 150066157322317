import { lazy } from 'react';

// Services
import lazyLoader from 'services/lazyLoader';

// Actions

const DashboardPage = lazy(() => lazyLoader(() => import('pages/Restricted/Processes/Dashboard')));

const Dashboard = () => <DashboardPage />;
export default Dashboard;
