import { lazy, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Services
import lazyLoader from 'services/lazyLoader';
import { withTracker } from 'services/withTracker';

// Contexts
import { AutoCompleteProvider } from 'contexts/autocompleContext';
import { ReformulateProposalProvider } from 'contexts/reformulateProposalContext';

// Utils
import { isDefined } from 'services/util/auxiliaryUtils';

// Actions
import { resetSizing } from 'redux/actions/sizing';
import { resetProposal } from 'redux/actions/proposal';
import { resetBusinessModels } from 'redux/actions/businessModels';

const ProcessesContainer = lazy(() => lazyLoader(() => import('pages/Restricted/Processes/Proposals')));
const ReformulateProposalContainer = lazy(() => lazyLoader(() => import('pages/Restricted/Processes/Proposals/ReformulateProposal')));

const Processes = () => {
    let { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isDefined(id)) {
            dispatch(resetSizing());
            dispatch(resetProposal());
            dispatch(resetBusinessModels());
        }
    }, [id]); // eslint-disable-line

    let container = isDefined(id) ? <ReformulateProposalContainer /> : <ProcessesContainer />;

    return (
        <AutoCompleteProvider>
            <ReformulateProposalProvider>{container}</ReformulateProposalProvider>
        </AutoCompleteProvider>
    );
};

export default withTracker(Processes, 'Processes');
