import { styled } from 'styled-components';

export const StyledInput = styled.div`
    display: flex;
    flex-flow: row;

    .MuiInputAdornment-root {
        margin-right: 0px;
    }
`;
