//Libraries
import { memo, useState } from 'react';
import PropTypes from 'prop-types';

//Material UI Component
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
// Utils
import IntlMessages from 'components/util/IntlMessages';
import { Button, IconButton } from '@save2compete/efz-design-system';
import { StyledSalesSegmentDialog } from './StylesSalesSegmentDialog';

/**
 *  This component is used when we change the sales segment
 *
 */
const SalesSegmentDialog = ({ open, onCloseHandler, onConfirmDiscardChanges, onConfirmSaveChanges }) => {
    const [isRequestingCHistory, setIsRequestingCHistory] = useState(false);

    const handleClose = (_, reason) => {
        if (['backdropClick', 'escapeKeyDown'].includes(reason)) return false;

        if (typeof onCloseHandler === 'function') onCloseHandler();
    };

    return (
        <StyledSalesSegmentDialog open={open} onClose={handleClose} className="change-sales-segment-dialog">
            <div className="dialog-title">
                <DialogTitle className="flex-fill">
                    <IntlMessages id="label.warning" />
                </DialogTitle>
                <IconButton
                    variant="tertiary"
                    icon="xMarkExit"
                    size="bg"
                    disabled={isRequestingCHistory}
                    className="close-button"
                    onClick={onCloseHandler}
                />
                <hr />
            </div>
            <DialogContent className="dialog-content">
                <DialogContentText className="mb-3" color="textPrimary">
                    <IntlMessages id={'dialog.changeSalesSegment.description'} />
                </DialogContentText>
                <DialogActions>
                    <Button variant="tertiary" disabled={isRequestingCHistory} onClick={onConfirmDiscardChanges} dataTestId="cancel">
                        <IntlMessages id={'label.cancel'} />
                    </Button>
                    <Button
                        disabled={isRequestingCHistory}
                        type="submit"
                        onClick={() => onConfirmSaveChanges(setIsRequestingCHistory)}
                        loading={isRequestingCHistory}
                        dataTestId="forward"
                    >
                        <IntlMessages id={'label.advance'} />
                    </Button>
                </DialogActions>
            </DialogContent>
        </StyledSalesSegmentDialog>
    );
};

SalesSegmentDialog.defaultProps = {
    contentText: 'label.warning',
    discardLabel: 'label.discard',
    saveLabel: 'label.saveAndContinue',
};
//PropTypes
SalesSegmentDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onCloseHandler: PropTypes.func.isRequired,
    onConfirmDiscardChanges: PropTypes.func.isRequired,
    onConfirmSaveChanges: PropTypes.func.isRequired,
};

//Export
export default memo(SalesSegmentDialog);
