import Dialog from '@mui/material/Dialog';
import { styled } from 'styled-components';

export const StyledPopUpErrorDevice = styled(Dialog)`
    z-index: 970;
    margin: 0;
    display: block;
    height: auto;
    width: 100%;
    height: 100%;
    .efz-popup-device-error-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 450px;
        width: 100%;
        /* margin: 1rem; */
        padding: 2rem 1rem;
        border-radius: 10px;
        text-align: center;
        .backoffice-dialog-close {
            font-size: 0.5rem;
            top: 8px;
        }
        p {
            margin-bottom: 2rem;
        }
        h3 {
            font-size: 1.3rem;
            color: var(--primary-color);
        }
    }
`;
