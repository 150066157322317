import { styled } from 'styled-components';
import { StyledInput } from 'styles/styled/common/StylesInput';

export const StyledPhoneInput = styled(StyledInput)`
    .MuiOutlinedInput-root {
        width: 100%;
    }

    .react-tel-input {
        height: 100%;

        .form-control {
            width: 100%;
            height: 100%;
            border-color: transparent;

            &:focus {
                box-shadow: none;
                border: 2px solid black;
                padding: 4px 10px 4px 47px;
            }

            &.invalid-number {
                border-color: #d32f2f;
            }
        }

        .flag-dropdown {
            border-color: transparent;

            .selected-flag {
                width: 40px;
                display: grid;
                place-items: center;
                padding: 0;
            }

            .country-list {
                margin-top: 2px;
            }

            &.open {
                border: 1px solid rgba(0, 0, 0, 0.23);
                border-right-color: transparent;
                border-radius: 4px;

                &:hover {
                    border-color: rgba(0, 0, 0, 0.87);
                }

                &.invalid-number {
                    border-color: #d32f2f;
                    border-right-color: transparent;
                    background-color: #faf0f0;

                    .selected-flag {
                        background-color: #faf0f0;
                    }
                }
            }

            &.invalid-number {
                .selected-flag:hover {
                    background-color: #fad4d4;
                }
            }
        }
    }
`;
