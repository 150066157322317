import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';

// Components
import IntlMessages from 'components/util/IntlMessages';

// Contexts
import { SidebarContext } from 'contexts/sidebarContext';
import { useUserStore } from 'store/user';
import { StyledSidebarTooltip } from '../../StylesSidebar';

const SidebarButton = ({ button }) => {
    const history = useHistory();

    const {
        setButtonSelected,
        buttonSelected,
        isSidebarPermanent,
        hasSolutionsButton,
        sidebarOpen,
        setShowErrorDevice,
        sectionSelected,
        setSectionSelected,
    } = useContext(SidebarContext);

    const { fieldUser } = useUserStore();

    const isMobileDevice =
        /Mobile|Android|iPhone|iPad|iPod|Windows Phone|BlackBerry|webOS/i.test(navigator.userAgent) ||
        (/Macintosh/i.test(navigator.userAgent) && 'ontouchstart' in window);

    const clickHandler = () => {
        const clickFlag = !isMobileDevice;
        if (button.canBeAccessed(clickFlag)) {
            if (button?.sections?.length > 0 && !!fieldUser) {
                if (!sidebarOpen) {
                    history.push(button?.sections[0].navigationLabel);
                    setButtonSelected(button.name);
                    setSectionSelected(button?.sections[0].name);
                } else return;
            } else {
                history.push(button.navigationLabel);
                setButtonSelected(button.name);
                setSectionSelected(null);
            }
        } else {
            setShowErrorDevice({
                title: 'popup.backoffice.error.device.title',
                content: 'popup.backoffice.error.device.content',
            });
        }
    };

    const sectionClickHandler = (section) => {
        history.push(section.navigationLabel);
        setButtonSelected(button.name);
        setSectionSelected(section.name);
    };

    return (
        <StyledSidebarTooltip
            title={<IntlMessages id={button.label} />}
            style={{ display: sidebarOpen ? 'none' : 'auto' }}
            type="default"
            placement="top-start"
            arrowPlacement="bottom-start"
        >
            <span>
                <IconButton
                    className={`sidebar-button${isSidebarPermanent ? '-permanent' : ''}${buttonSelected === button.name ? '-selected' : ''}${button.disabled(hasSolutionsButton) ? '-disabled' : ''} ${sidebarOpen ? 'open' : ''} w-100`}
                    disabled={button.disabled(hasSolutionsButton)}
                    onClick={clickHandler}
                    disableRipple
                >
                    <div className="w-100 d-flex flex-column align-items-start">
                        <div className="w-100 d-flex flex-row align-items-center">
                            <div
                                className={`sidebar-icon-${button.name}${buttonSelected === button.name ? '-selected' : ''}${button.disabled(hasSolutionsButton) ? '-disabled' : ''}`}
                            >
                                {button.icon}
                            </div>
                            <span>
                                <IntlMessages id={button.label} />
                            </span>
                        </div>
                        {button?.sections?.length > 0 && sidebarOpen && !!fieldUser && (
                            <div className="sidebar-sections">
                                {button?.sections.map((section) => (
                                    <span
                                        className={`sidebar-section${sectionSelected === section.name ? '-selected' : ''}`}
                                        onClick={() => sectionClickHandler(section)}
                                    >
                                        <IntlMessages id={section.label} />
                                    </span>
                                ))}
                            </div>
                        )}
                    </div>
                </IconButton>
            </span>
        </StyledSidebarTooltip>
    );
};

export default SidebarButton;
