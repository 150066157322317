import { themeBase } from 'constants/styles/global';
import { ToastContainer } from 'react-toastify';
import { css, styled } from 'styled-components';

const toastIconCSS = css`
    min-width: 20px;
    max-width: 20px;
`;
export const StyledToaster = styled(ToastContainer)`
    .Toastify__toast-container,
    .Toastify__toast {
        border-radius: 3px !important;
        font-family: inherit !important;
        font-weight: ${themeBase.fontWeight.semibold};
        font-size: 0.875rem;
    }

    .Toastify__toast-container--top-right {
        top: calc(60px + 1rem) !important;
    }

    .Toastify__toast {
        padding: 0.875rem !important;
    }

    .Toastify__toast-body {
        color: var(--text-color);
    }

    .Toastify__close-button {
        color: var(--text-color) !important;
        margin-left: 5px;
    }

    .toast-icon {
        ${toastIconCSS};
    }

    // States
    .Toastify__toast--success {
        background: #f0fffb !important;
        border: 1px solid #48c4a1;

        svg {
            ${toastIconCSS};
            path {
                fill: #3ec09c;
            }
        }
    }

    .Toastify__progress-bar--success {
        background: #07bc0c !important;
    }

    .Toastify__toast--error {
        background: #ffeff1 !important;
        border: 1px solid #f46f7e;

        svg {
            ${toastIconCSS};
            path {
                fill: #ee162d;
            }
        }
    }

    .Toastify__progress-bar--error {
        background: #e47d72 !important;
    }

    .Toastify__toast--warning {
        background: #f9f6df !important;
        border: 1px solid #d4c528;

        svg {
            ${toastIconCSS};
            path {
                fill: #d6c228;
            }
        }
    }

    .Toastify__progress-bar--warning {
        background: #f0d97e !important;
    }

    .Toastify__toast--info {
        background: #eff7ff !important;
        border: 1px solid #6ba4da;

        svg {
            ${toastIconCSS};
            path {
                fill: #3885cd;
            }
        }
    }

    .toast-content {
        margin-top: 4px;
        font-weight: ${themeBase.fontWeight.normal} !important;
    }

    .toast-close-button {
        right: 0;
        top: 0;
    }
`;
