import { containString, getBoolean, isDefined, isSolarpvPPCOAreaB2B, isSolarpvPPCOAreaB2C } from 'services/util/auxiliaryUtils';
import {
    SUBDOMAINS,
    ENVIRONMENTS_FE,
    BASENAME_URL_OAREA_PPC_B2B,
    BASENAME_URL_OAREA_PPC_B2C,
    BASENAME_URL_SOLUTIONS,
} from 'constants/settings';
import defaultTheme from 'pages/themes/defaultTheme';
import { PRODUCT_IDS } from 'constants/products';
import { getCompanyProfileIds } from './user';

export function isOpenArea() {
    return isSolarpvPPCOAreaB2C() || isSolarpvPPCOAreaB2B();
}
/**
 * isS2CPlatformVersion
 *
 */
export const isS2CPlatformVersion = () => {
    const subdomain = document.location.host.split('.');
    const subdomainS2C = [SUBDOMAINS.S2C, SUBDOMAINS.EDP];
    return subdomain.find((item) => subdomainS2C.includes(item));
};
/**
 * getDomain
 *
 * @param {*} env (DEV, TST, PRE, PRD)
 */
export const getDomain = (env) => {
    const locationHost = document.location.host;

    //1. in FE env development  (DEV: only env DEV (FE & BE))
    if (process.env.NODE_ENV === 'development') {
        return process.env[`REACT_APP_API_URL_${env}`];
    }

    // 2. testing (REACT_APP_IS_DEBUGGER)
    if (getBoolean(process.env.REACT_APP_IS_DEBUGGER)) {
        return env === ENVIRONMENTS_FE.TST ? process.env.REACT_APP_API_URL_TST : process.env.REACT_APP_API_URL_PRD;
    }

    //All on servers
    return locationHost;
};

export const getFieldDomain = (env) => {
    //FIXME: Locally check how to get field api
    return process.env[`REACT_APP_FIELD_URL_${env}`];
};
export function getHeadTitle() {
    if (window.location.pathname.includes(`/${BASENAME_URL_OAREA_PPC_B2B}`)) return 'myEnergySolarPro';
    if (window.location.pathname.includes(`/${BASENAME_URL_OAREA_PPC_B2C}`)) return 'myEnergySolar';
    return `${getBrand()} - a new way to sell energy services`;
}
export function getBrand() {
    return isS2CPlatformVersion() ? 'Save To Compete' : 'effizency';
}
// Add additional rules for header theming, if necessary
export const styleHeaderByCompanyID = (companyProfileId) => {
    switch (companyProfileId) {
        case getCompanyProfileIds().PPC:
            return ' ppc';
        default:
            return '';
    }
};
/**
 * handleFavicon
 *
 */
export function handleFavicon(companyProfileId) {
    const htmlIDFavicon = document.getElementById('favicon');
    const isS2CVersion = isS2CPlatformVersion();
    let favicon = 'favicon.ico';

    // PPC
    if (isOpenArea()) companyProfileId = getCompanyProfileIds().PPC;

    // s2c
    if (isS2CVersion) favicon = 'favicon-s2c.ico';

    // companyProfileId
    if (
        !!companyProfileId &&
        ([getCompanyProfileIds().ESB, getCompanyProfileIds().NRG, getCompanyProfileIds().CEZ, getCompanyProfileIds().TELEFONICA].includes(
            parseInt(companyProfileId)
        ) ||
            [getCompanyProfileIds().PPC].includes(parseInt(companyProfileId)))
    )
        favicon = `favicon-${companyProfileId}.ico`;

    // @ts-ignore
    if (isDefined(htmlIDFavicon.href)) htmlIDFavicon.href = `/favicons/${favicon}`;
}
/**
 * isEnvProduction
 * @returns
 */
export function isEnvProduction() {
    return ENVIRONMENTS_FE.PRD === process.env.REACT_APP_ENV;
}
/**
 * isEnvPreAndProd
 * @returns
 */
export function isEnvPreAndProd() {
    return [ENVIRONMENTS_FE.PRD, ENVIRONMENTS_FE.PRE].includes(process.env.REACT_APP_ENV!);
}
/**
 * isEnvDevelopment
 * @returns
 */
export function isEnvDevelopment() {
    return [ENVIRONMENTS_FE.DEV, ENVIRONMENTS_FE.TST].includes(process.env.REACT_APP_ENV!);
}
/**
 *
 * @param {*} featureFlag
 * @returns
 */
export function isEnvDevFlag(featureFlag, testAsPRD = false) {
    if (!isDefined(featureFlag)) {
        console.error('featureFlag is not defined');
        featureFlag = {
            ...featureFlag,
            error: 'featureFlag is not defined',
        };
    }
    if (isEnvDevelopment() && testAsPRD) return false;
    return (
        // isEnvDevelopment() ||
        featureFlag
    );
}
export function isDevelopment() {
    return process.env.NODE_ENV === 'development';
}
export function getAppTheme(companyProfileId = null, apiTheme = null) {
    if (isDefined(apiTheme)) return apiTheme;

    if (!isDefined(companyProfileId) || !isDefined(apiTheme)) return defaultTheme;
}
//Check KPIs visibility
export const kpisVisibility = (array, name) => array?.find((element) => element?.designacao_fe === name);
//Check KPIs visibility
export const isKpiVisible = (array, name) => array?.find((element) => element?.designacao_fe === name);
export const showFacilityAndClientCards = (location) =>
    containString(
        [
            'proposal',
            'sub-products',
            'modules',
            'business-models',
            'client',
            'facilities',
            'facility',
            'detailed',
            `/${PRODUCT_IDS.SPV_SIMPLE}`,
            `/${PRODUCT_IDS.HP}`,
            `/${PRODUCT_IDS.SPV}`,
            `/${PRODUCT_IDS.SPVSB}`,
        ],
        location
    ) && !containString(['processes'], location);

export function goBackBModels(history, productId) {
    switch (productId) {
        case PRODUCT_IDS.SPV:
        case PRODUCT_IDS.SPVSB: {
            const pvVersion = localStorage.getItem('pv_version');
            history.push(`${BASENAME_URL_SOLUTIONS}/${productId}${pvVersion === 'v3' ? '' : '/modules/simple'}`);
            // history.goBack(2);
            break;
        }
        default:
            history.goBack();
            break;
    }
}
