import { themeBase } from 'constants/styles/global';
import { styled } from 'styled-components';
import { media } from 'styles/utils/mediaQueries';

export const StyledRemoteTable = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .table-hover {
        width: 100%;
        ${media.tablet`
            min-width: 720px !important;
            min-height: 500px !important;
        `}
    }

    .thead {
        border-radius: ${themeBase.borderRadius};
        border: 1px solid #2020203c;
        background-color: #e5e5e5;
        color: var(--text-color);
        font-weight: ${themeBase.fontWeight.semibold};
        min-height: 2.125rem;
        //margin-right: 6px; // because of scrollbar

        ${media.tablet`
            min-width: 700px !important;
        `}

        .th {
            display: flex;
            align-items: center;
            padding: 0.5rem;
            font-size: 0.875rem;
            font-weight: ${themeBase.fontWeight.semibold};
            line-height: 19px;
        }
    }

    .tbody {
        position: relative;
        border-bottom-left-radius: ${themeBase.borderRadius};
        border-bottom-right-radius: ${themeBase.borderRadius};

        ${media.tablet`
            min-height: calc(85vh - 220px - 0.5rem);
            .td {
                align-items: center !important;
            }
        `}

        .tr {
            margin: 0.5rem 0;
            border-radius: ${themeBase.borderRadius};
            border: 1px solid #e5e5e5;
            background-color: var(--paper-color);
            font-size: 0.875rem;

            ${media.tablet`
                min-width: 700px !important;
            `}
        }

        .td {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0.5rem;
            min-height: 3.75rem;
        }

        .tr:hover {
            background-color: rgba(${themeBase.color.neutral900}, 0.05);
        }
    }

    .pagination {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        column-gap: 1rem;
        font-size: 0.75rem;
    }

    h5 {
        margin: 0;
    }

    .text-break {
        font-size: 0.875rem;

        ${media.tablet`
            font-size: 1rem;
        `}

        &-disabled {
            color: ${themeBase.silverChalice};
        }
    }
`;

export const StyledRemoteTableNew = styled(StyledRemoteTable)`
    .tbody .tr:hover {
        background-color: var(--paper-color);
    }

    .tr {
        .th:first-child,
        .td:first-child {
            padding-left: 1.5rem;
        }

        .th:last-child,
        .td:last-child {
            justify-content: flex-end;
        }
    }

    .tr-selected {
        border: 1px solid var(--primary-color) !important;
        background-color: var(--primary-color-bleached) !important;
    }

    .table-input {
        width: 100%;
    }

    .clickable {
        cursor: pointer;
    }

    .MuiOutlinedInput-root,
    .MuiInputAdornment-root {
        font-size: 0.875rem;
        background-color: var(--paper-color) !important;
    }

    .delete-icon,
    .duplicate-icon {
        path {
            fill: var(--primary-color);
        }

        &.disabled {
            path {
                fill: var(--disabled-color);
            }
        }
    }

    .remote-dialog-table-new-add {
        span {
            font-size: 0.875rem;
            color: var(--text-color);
            text-transform: uppercase;
        }

        .disabled {
            span {
                color: var(--disabled-color);
            }
        }
    }
`;
