import { MenuItem, Select } from '@mui/material';
import IntlMessages from 'components/util/IntlMessages';
import { OFFER_DETAILS_INPUT_NAMES } from 'constants/businessModelsPro';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TUpFront, UpFrontEvents } from 'interfaces/businessModels/upfront';
import { useContext, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { isDefined } from 'services/util/auxiliaryUtils';
import validate from 'services/util/validate';
import { TIntl } from 'types/utils';

const UpfrontOMDuration = () => {
    const {
        state: {
            selected: { elements },
        },
        setBMEventHandler,
        isRequestingBM,
    } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TUpFront>;

    const {
        control,
        setValue,
        watch,
        formState: { errors },
    } = useFormContext();

    const watchFixedInput = watch(OFFER_DETAILS_INPUT_NAMES.PAYMENT_METHOD);
    const watchOmDuraction = watch(OFFER_DETAILS_INPUT_NAMES.OM_DURATION);

    const fixedInputDefault = elements?.[OFFER_DETAILS_INPUT_NAMES.PAYMENT_METHOD]?.options?.default;
    const omDurationOptions = (fixedInput) =>
        fixedInput ?
            elements?.[OFFER_DETAILS_INPUT_NAMES.OM_DURATION]?.options?.options?.find((option) => option.input_fixed_text === fixedInput)
        :   elements?.[OFFER_DETAILS_INPUT_NAMES.OM_DURATION]?.options?.options?.[0];

    useEffect(() => {
        setValue(OFFER_DETAILS_INPUT_NAMES.PAYMENT_METHOD, fixedInputDefault);
        setValue(OFFER_DETAILS_INPUT_NAMES.OM_DURATION, omDurationOptions(fixedInputDefault)?.om_durations_default);
    }, []); // eslint-disable-line

    useEffect(() => {
        if (isDefined(watchOmDuraction)) {
            setBMEventHandler(UpFrontEvents.SET_OM_DURATION, {
                [OFFER_DETAILS_INPUT_NAMES.PAYMENT_METHOD]: watchFixedInput,
                [OFFER_DETAILS_INPUT_NAMES.OM_DURATION]: watchOmDuraction,
            });
        }
    }, [watchOmDuraction, watchFixedInput]); // eslint-disable-line

    return (
        <>
            {elements?.[OFFER_DETAILS_INPUT_NAMES.PAYMENT_METHOD] && (
                <div className="d-flex justify-content-between mb-3">
                    <div className="label-container">
                        <label>
                            <IntlMessages id="page.businessModels.editOffer.paymentMethod" />
                        </label>
                    </div>
                    <div className="input-container">
                        <Controller
                            name={OFFER_DETAILS_INPUT_NAMES.PAYMENT_METHOD}
                            control={control}
                            rules={validate(['required', 'number', 'positive'])}
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            render={({ field: { ref, ...field } }) => (
                                <Select
                                    {...field}
                                    inputProps={{ className: 'd-flex align-items-center' }}
                                    disabled={
                                        elements?.[OFFER_DETAILS_INPUT_NAMES.PAYMENT_METHOD]?.options?.options?.length === 1 ||
                                        isRequestingBM
                                    }
                                    error={!!errors[OFFER_DETAILS_INPUT_NAMES.PAYMENT_METHOD]}
                                    defaultValue={fixedInputDefault}
                                    onChange={(event) => {
                                        field.onChange(event);
                                        setValue(
                                            OFFER_DETAILS_INPUT_NAMES.OM_DURATION,
                                            // @ts-ignore
                                            elements?.[OFFER_DETAILS_INPUT_NAMES.OM_DURATION]?.options?.options?.find(
                                                (option) => option.input_fixed_text === event.target.value
                                            )?.om_durations_default,
                                            { shouldValidate: true }
                                        );
                                    }}
                                >
                                    <MenuItem value={-1} disabled={true}>
                                        <IntlMessages id={'label.selectOption'} />
                                    </MenuItem>
                                    {/* @ts-ignore */}
                                    {elements?.[OFFER_DETAILS_INPUT_NAMES.PAYMENT_METHOD]?.options?.options?.map((label) => {
                                        return (
                                            <MenuItem key={`fixed_input${label}`} value={label}>
                                                <IntlMessages id={`label.${label}` as TIntl} />
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            )}
                        />
                    </div>
                </div>
            )}
            <div className="d-flex justify-content-between mb-3">
                <div className="label-container">
                    <label>
                        <IntlMessages id="page.businessModels.editOffer.o&m" />
                    </label>
                </div>
                <div className="input-container">
                    <Controller
                        name={OFFER_DETAILS_INPUT_NAMES.OM_DURATION}
                        control={control}
                        rules={validate(['required', 'number', 'positive'])}
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        render={({ field: { ref, ...field } }) => (
                            <Select
                                {...field}
                                inputProps={{ className: 'd-flex align-items-center' }}
                                disabled={omDurationOptions(watchFixedInput)?.om_durations.length === 1 || isRequestingBM}
                                error={!!errors[OFFER_DETAILS_INPUT_NAMES.OM_DURATION]}
                                value={`${watchOmDuraction}`}
                            >
                                <MenuItem value={-1} disabled={true}>
                                    <IntlMessages id={'label.selectOption'} />
                                </MenuItem>
                                {omDurationOptions(watchFixedInput)?.om_durations?.map((duration) => {
                                    return (
                                        <MenuItem key={`om_duration_${duration}`} value={`${duration}`}>
                                            <IntlMessages
                                                id={`label.nYear${duration / 12 === 1 ? '' : 's'}`}
                                                values={{ number: duration / 12 }}
                                            />
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        )}
                    />
                </div>
            </div>
        </>
    );
};

export default UpfrontOMDuration;
