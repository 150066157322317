import { GRANTS_TABLE_CONFIG, MAX_GRANTS } from 'constants/businessModelsPro/grants';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { useContext } from 'react';
import { useFlexLayout, useTable } from 'react-table';
import IntlMessages from 'components/util/IntlMessages';
import Alert from 'components/@efz/Alert';
import { TPaP } from 'interfaces/businessModels/pap';
import usePaPGrants from 'hooks/businessModelsPro/PaP/usePaPGrants';
import { Button } from '@save2compete/efz-design-system';
import { StyledRemoteTable } from 'styles/styled/common/StylesRemoteTable';

const GrantsTablePaP = () => {
    const { bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TPaP>;

    const columns = GRANTS_TABLE_CONFIG;
    const data = bmSelected.values.grants?.grants;
    const isRequesting = false;

    const { handleChangeAmmount, handleChangeDescription, handleDelete, handleAddGrant } = usePaPGrants();

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
        {
            columns,
            data,
            handleChangeDescription,
            handleChangeAmmount,
            handleDelete,
        },
        useFlexLayout
    );

    const conditionsToDisableAddGrant =
        bmSelected.values.grants.grants.length === MAX_GRANTS || bmSelected.values.grants.totalGrants.prc >= 100;

    return (
        <div className="bm-grants-table">
            <StyledRemoteTable>
                <div {...getTableProps()} className={isRequesting ? 'table-loading-overlay' : 'table-hover'}>
                    <div className="thead">
                        {headerGroups.map((headerGroup) => (
                            <div {...headerGroup.getHeaderGroupProps()} className="tr d-flex align-self-center">
                                {headerGroup.headers.map((column) => (
                                    <div {...column.getHeaderProps()} className={`th ${column.className ?? ''}`}>
                                        {column.render('Header')}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>

                    <div className="tbody" {...getTableBodyProps()}>
                        {data.length > 0 &&
                            rows.map((row) => {
                                prepareRow(row);
                                return (
                                    <div {...row.getRowProps()} className={`tr d-flex align-self-center`}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <div {...cell.getCellProps()} className={`td d-flex ${cell.column.className ?? ''}`}>
                                                    {cell.render('Cell')}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </StyledRemoteTable>
            <div className="bm-grants-table-add">
                <Button
                    variant="tertiary"
                    icon="startAdd"
                    onClick={handleAddGrant}
                    disabled={conditionsToDisableAddGrant}
                    dataTestId="add-grant"
                >
                    <IntlMessages id="page.bm.grants.add" />
                </Button>
            </div>
            {bmSelected.values.grants.totalGrants.prc > 100 && (
                <Alert severity="warning" className="mb-2 mt-2 w-50">
                    <IntlMessages id="page.bm.grants.prcExcceeded" />
                </Alert>
            )}
            {bmSelected.values.grants.grants.length === MAX_GRANTS && (
                <Alert severity="error" className="mb-2 mt-2 w-50">
                    <IntlMessages id="page.bm.grants.grantWarningLimit" values={{ max: MAX_GRANTS }} />
                </Alert>
            )}
        </div>
    );
};

export default GrantsTablePaP;
