import { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { uniqBy } from 'lodash';
import { StatusCodes } from 'http-status-codes';
import clone from 'fast-copy';
import { SIMPLE_MODE_STEPS, SpvActions, SpvLoadings, SPV_SIMPLE_ROOF_TYPES_IDS } from 'constants/products/solarpv';
import { Nullable } from 'types/utils';
import {
    getCoordinatesConversionFactor,
    getStructureForRoofType,
    postDetectBuilding,
    postDetectRoofType,
    postRoofSplit,
    postAutoExclusions,
    postSPVUsefulArea,
    getPVRowRatio,
    getInvertersCombination,
    postOptimizer,
    postDSTrainingData,
} from 'api/products/solarpv';

import { errorCatchOnHandler } from 'services/@efz/axios';
import { USER_COUNTRY_LatLng } from 'constants/user';
import { getDetectRoofTop, postSimulation } from 'api/products/solarpvSimple';

import { getRoofDatabyPaths, solarPvLimitPower } from 'services/products/solarpv/solarpvV3';
import { postPVEquipmentsEstStructuresAspect } from 'api/product';
import { isDefined, isFieldDefined, isNumberDefined } from 'services/util/auxiliaryUtils';
import {
    MOUNTING_STRUCTURES_TYPES,
    PANEL_FRAMING,
    PANEL_REPRESENTATION_INPUT_NAMES,
    OPTIMIZATION_PRODUCT_IDS,
    MAX_SIZE_OPTIONS,
} from 'constants/products/spvPro';
import { getDefaultSlope, calculateAreaPeakPower, calculateValidPanelsKits } from 'services/products/solarpv';
import { PAYMENT_MODELS_IDS } from 'constants/businessModels';
import {
    TAreaItem,
    TCoordConvFactorData,
    TCoordConvFactorPayload,
    TCoordinatesDS,
    TCoordsData,
    TDetectBuildingAPIResponse,
    TDetectBuildingPayload,
    TDetectRooftopPayload,
    TDetectRoofTypeData,
    TDetectRoofTypePayload,
    TEstimatedStructureAspectData,
    TEstimatedStructureAspectPayload,
    TExclusionAreaADetectedData,
    TExclusionAreaADetectedPayload,
    TExclusionsData,
    TInputs,
    TInputsKits,
    TInvertersCombAPIData,
    TInvertersCombData,
    TInvertersCombPayload,
    TKits,
    TLatLng,
    TOptimizationInputs,
    TOptimizationItem,
    TOptimizations,
    TOptimizerAPIData,
    TOptimizerPayload,
    TOptimizerWithKitsPayload,
    TPanelItem,
    TProdParams,
    TPvOptions,
    TRangeDataItem,
    TRoofSplitAPIData,
    TRoofSplitPayload,
    TRowRatioPayload,
    TSimulationData,
    TSimulationPayload,
    TSolutionData,
    TStructureForRoofTypeItemData,
    TStructureItem,
    TStructuresForRoofTypeData,
    TUsefulAreaAPIData,
    TUsefulAreaData,
    TUsefulAreaPayload,
    TUseSpvSimple,
} from 'hooks/products/solarpv/simple/store/types';
import {
    coordConvFactorDataSchema,
    coordConvFactorPayloadSchema,
    detectBuildingAPIRspSchema,
    detectBuildingPayloadSchema,
    detectRooftopDataSchema,
    detectRooftopPayloadSchema,
    detectRoofTypeDataSchema,
    detectRoofTypePayloadSchema,
    estimatedStructureAspectPayloadSchema,
    estStructuresAspectDataSchema,
    exclusionAreaADetectedPayloadSchema,
    inverterCombAPIDataSchema,
    invertersCombPayloadSchema,
    optimizerAPIDataByKitsSchema,
    optimizerAPIDataSchema,
    optimizerPayloadSchema,
    optimizerWithKitsPayloadSchema,
    roofSplitAPIDataSchema,
    roofSplitPayloadSchema,
    rowRatioDataschema,
    rowRatioPayloadSchema,
    simulationDataSchema,
    simulationPayloadSchema,
    structuresForRoofTypeDataSchema,
    trainingDSSchema,
    usefulAreaPayloadSchema,
} from 'hooks/products/solarpv/simple/store/schemas';
import { useCacheRequests } from 'hooks/products/solarpv/simple/store/cacheRequests';
import { isEnvDevFlag } from 'services/settings';
import { useFeatureFlags } from 'store/featureFlags';

let sinalGetStructureForRoofTypeAController: AbortController;
let signalGetDetectRoofTopAController: AbortController;
let signalPostDetectBuildingAController: AbortController;
let signalPostDetectRoofTypeAController: AbortController;
let signalGetCoordConvFactorAController: AbortController;
let signalPostRoofSplitAController: AbortController;
let signalPostEstimatedStructureAController: AbortController;
let sinalPostExclusionsAreaADetectedAController: AbortController[] = [];
let sinalPostUsefulAreasAController: AbortController[] = [];
let signalGetRowRatioAController: AbortController;
let sinalPostInvertersCombinationAController: AbortController;
let signalPostOptimizerAController: AbortController;
let signalPostSimulationAController: AbortController;
let signalPostDSTrainingDataController: AbortController;

function cleanup() {
    sinalGetStructureForRoofTypeAController && sinalGetStructureForRoofTypeAController.abort();
    signalGetDetectRoofTopAController && signalGetDetectRoofTopAController.abort();
    signalPostDetectBuildingAController && signalPostDetectBuildingAController.abort();
    signalPostDetectRoofTypeAController && signalPostDetectRoofTypeAController.abort();
    signalGetCoordConvFactorAController && signalGetCoordConvFactorAController.abort();
    signalPostRoofSplitAController && signalPostRoofSplitAController.abort();
    signalPostEstimatedStructureAController && signalPostEstimatedStructureAController.abort();
    sinalPostExclusionsAreaADetectedAController.forEach((el) => el.abort());
    sinalPostExclusionsAreaADetectedAController = [];
    sinalPostUsefulAreasAController.forEach((el) => el.abort());
    sinalPostUsefulAreasAController = [];
    signalGetRowRatioAController && signalGetRowRatioAController.abort();
    sinalPostInvertersCombinationAController && sinalPostInvertersCombinationAController.abort();
    signalPostOptimizerAController && signalPostOptimizerAController.abort();
    signalPostSimulationAController && signalPostSimulationAController.abort();
    signalPostDSTrainingDataController && signalPostDSTrainingDataController.abort();
}

/**
 * Calculates the "day rank" of a given date relative to the closest solstice based on the provided latitude.
 * The "day rank" is a measure of the number of days from the given date to the most recent past solstice, plus one.
 * For latitudes above 0 (northern hemisphere), the winter solstice (December 21st) is used.
 * For latitudes below 0 (southern hemisphere), the summer solstice (June 21st) is used.
 *
 * @param {Date} date - The date for which to calculate the day rank.
 * @param {number} lat - The latitude which determines the relevant solstice. Positive for northern hemisphere, negative for southern.
 * @returns {number} The day rank of the given date.
 */
const convertDateToDayRank = (date: Date, lat: TLatLng['lat']) => {
    let selectedDate = date;
    let dateDif = 1;

    if (selectedDate) {
        selectedDate = new Date(date);
        if (lat > 0)
            dateDif = Math.min(
                Math.abs(Number(new Date(new Date().getFullYear(), 11, 21)) - selectedDate.getTime()),
                Math.abs(selectedDate.getTime() - Number(new Date(new Date().getFullYear() - 1, 11, 21)))
            );
        else
            dateDif = Math.min(
                Math.abs(Number(new Date(new Date().getFullYear(), 5, 21)) - selectedDate.getTime()),
                Math.abs(selectedDate.getTime() - Number(new Date(new Date().getFullYear() + 1, 5, 21)))
            );

        dateDif = Math.ceil(dateDif / (1000 * 60 * 60 * 24)) + 1;
    }

    return dateDif;
};

/**
 * Calculates and updates the number of valid panels for each area based on minimum and maximum technical panel limits.
 *
 * This function takes an array of area items, each potentially containing a number of possible panels (`panels_number_possible`),
 * and adjusts these numbers to ensure the total count of panels across all areas falls within specified minimum and maximum limits.
 * If the total possible panels are below the minimum, no adjustments are made. If above the maximum, panels are proportionally excluded
 * from areas until the total is within the allowed range.
 *
 * @param {TAreaItem[]} areas - An array of area items. Each area item is expected to have a `panels_number_possible` property.
 * @param {number} minTecPanels - The minimum number of panels allowed across all areas.
 * @param {number} maxTecPanels - The maximum number of panels allowed across all areas.
 * @returns {TAreaItem[]} The same array of area items, but with `panels_number` and `panels_number_excluded` properties updated to reflect
 *                         the valid number of panels after adjustments.
 */
const calculateValidPanels = (areas: TAreaItem[], minTecPanels: number, maxTecPanels: number): TAreaItem[] => {
    //#region local vars
    const areasCurrent = clone(areas); // Clone the input array to avoid mutating the original data.
    let totalPanelsExcluded = 0; // Initialize a counter for the total number of panels to be excluded.
    // Calculate the total number of possible panels across all areas.
    const totalDrawnPanels = areasCurrent.reduce((total, area) => {
        return (total += area?.panels_number_possible ?? 0);
    }, 0);
    //#endregion  local vars

    // If the total possible panels are below the minimum, return the areas as is.
    if (totalDrawnPanels < minTecPanels) return areasCurrent;

    // If the total possible panels exceed the maximum, calculate how many need to be excluded.
    if (totalDrawnPanels > maxTecPanels) {
        totalPanelsExcluded = totalDrawnPanels - maxTecPanels;
    }

    // Iterate over each area to adjust the number of valid and excluded panels based on the total to be excluded.
    areasCurrent.map((area) => {
        let panels_number = 0; // Initialize the number of valid panels in this area.
        let panels_number_excluded = 0; // Initialize the number of excluded panels in this area.
        const panels_number_possible = area?.panels_number_possible; // The initial number of possible panels in this area.

        if (totalPanelsExcluded > 0) {
            // If there are panels to exclude, calculate the new number of valid panels.
            panels_number = totalPanelsExcluded >= panels_number_possible ? 0 : panels_number_possible - totalPanelsExcluded;
        } else {
            // If no more panels need to be excluded, the valid number equals the initial possible number.
            panels_number = panels_number_possible;
        }

        // Calculate the number of excluded panels in this area.
        panels_number_excluded = panels_number_possible - panels_number;

        // Adjust the total number of panels to be excluded based on this area's exclusions.
        totalPanelsExcluded -= panels_number_excluded;

        // Update the area's properties to reflect the new numbers of valid and excluded panels.
        area.panels_number = panels_number;
        area.panels_number_excluded = parseInt(panels_number_excluded.toString());

        return area;
    });

    return areasCurrent;
};

const TAGS_ERRORS = ['NO_SOLUTIONS', 'MISSING_INVESTMENT'];

export const useSpvSimple = ({ userData, clientData, facilityData, options }: TUseSpvSimple) => {
    const { featureFlags } = useFeatureFlags();

    const {
        cacheStructureForRoofType,
        cacheDetectRooftop,
        cacheDetectBuilding,
        cacheDetectRoofType,
        cacheConvFactors,
        cacheEstimatedStructuresAspect,
        cacheRoofSplit,
        cacheExclusions,
        cacheRowRatio,
        cacheUsefulAreas,
        cacheInvertersComb,
        cacheSimulation,
        cacheOptimizer,
        actions: {
            resetCache,
            setCacheStructureForRoofType,
            setCacheDetectRoofTop,
            setCacheDetectBuilding,
            setCacheDetectRoofType,
            setCacheConvFactors,
            setCacheEstimatedStructuresAspect,
            setCacheRoofSplit,
            setCacheExclusions,
            setCacheRowRatio,
            setCacheUsefulAreas,
            setCacheInvertersComb,
            setCacheSimulation,
            setCacheOptimizer,
        },
    } = useCacheRequests();

    const { userID, companyProfileId, userLocale } = userData;
    const { risco_energia_id } = clientData;
    const { facilityID, isB2C, potencia_contratada, potencia_requisitada, nivel_tensao_descricao, tarifa_equivalente_ac } = facilityData;
    const {
        productID,
        facilityCoordinates,
        sInputs,
        ranges,
        structures,
        pvOptions,
        optimizationsData,
        isManualDrawing,
        roofByDrawingManual,
        roofType,
        roof,
        kits,
        //fnc
        setInfoTagsHandler,
        solarpvDispatchHandler,
    } = options;

    const roofTypeStructuresRef = useRef<TStructureForRoofTypeItemData[]>();

    const powerLimit = useMemo(
        () =>
            solarPvLimitPower(companyProfileId, {
                potencia_contratada,
                potencia_requisitada,
                nivel_tensao_descricao,
            }).value,
        [potencia_contratada, potencia_requisitada, nivel_tensao_descricao, companyProfileId]
    );

    //#region step ROOF_IDENTIFICATION
    const fetchStructureForRoofType = useCallback(
        ({ productID }: { productID: number }): Promise<TStructureForRoofTypeItemData[]> => {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        sinalGetStructureForRoofTypeAController && sinalGetStructureForRoofTypeAController.abort();
                        sinalGetStructureForRoofTypeAController = new AbortController();

                        // check cache store data | get request cache
                        if (
                            JSON.stringify(productID) === JSON.stringify(cacheStructureForRoofType.payload) &&
                            cacheStructureForRoofType?.data?.length > 0
                        ) {
                            return resolve(cacheStructureForRoofType.data);
                        }

                        const rspRoofTypeStructures = await getStructureForRoofType(productID, sinalGetStructureForRoofTypeAController);

                        if (rspRoofTypeStructures?.status !== StatusCodes.OK) {
                            return reject('fail getStructureForRoofType');
                        }
                        const roofTypeStructures = structuresForRoofTypeDataSchema.parse(
                            rspRoofTypeStructures?.data?.data
                        ) as TStructuresForRoofTypeData;

                        // set cache store data
                        setCacheStructureForRoofType({ payload: productID, data: roofTypeStructures });
                        return resolve(roofTypeStructures);
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        [cacheStructureForRoofType, setCacheStructureForRoofType]
    );

    const fetchDetectRooftop = useCallback(
        ({ facilityCoordinates }: { facilityCoordinates: TLatLng }): Promise<TCoordsData | []> => {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const detectRooftopPayload = detectRooftopPayloadSchema.parse({
                            center: {
                                lat: facilityCoordinates.lat,
                                lng: facilityCoordinates.lng,
                                zoom: 20,
                            },
                            image_name: `autodetect-roof-${facilityCoordinates.lat}_${facilityCoordinates.lng}.png`,
                        }) as TDetectRooftopPayload;

                        // check cache store data | get request cache
                        if (JSON.stringify(detectRooftopPayload) === JSON.stringify(cacheDetectRooftop.payload)) {
                            return resolve(cacheDetectRooftop.data);
                        }
                        signalGetDetectRoofTopAController && signalGetDetectRoofTopAController.abort();
                        signalGetDetectRoofTopAController = new AbortController();

                        const rspDetectRoofTop = await getDetectRoofTop(detectRooftopPayload, false, signalGetDetectRoofTopAController);

                        if (rspDetectRoofTop?.status !== StatusCodes.OK) {
                            return reject('fail rspDetectRoofTop');
                        }
                        const roofTopData = detectRooftopDataSchema.parse(rspDetectRoofTop?.data?.data?.[0] ?? []) as TCoordsData;
                        setCacheDetectRoofTop({ payload: detectRooftopPayload, data: roofTopData });
                        return resolve(roofTopData);
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        [cacheDetectRooftop, setCacheDetectRoofTop]
    );

    const fetchDetectBuilding = useCallback(
        ({
            userLocale,
            facilityID,
            facilityCoordinates,
        }: {
            userLocale: string;
            facilityID: number;
            facilityCoordinates: TLatLng;
        }): Promise<TCoordsData> => {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const detectBuildingPayload = detectBuildingPayloadSchema.parse({
                            facilityID: facilityID?.toString() ?? `${facilityCoordinates.lat}_${facilityCoordinates.lng}`,
                            lat: facilityCoordinates.lat,
                            lng: facilityCoordinates.lng,
                            zoom: 20,
                            locale: USER_COUNTRY_LatLng?.[userLocale]?.carto,
                        }) as TDetectBuildingPayload;

                        // check cache store data | get request cache
                        if (JSON.stringify(detectBuildingPayload) === JSON.stringify(cacheDetectBuilding.payload)) {
                            return resolve(cacheDetectBuilding.data);
                        }
                        signalPostDetectBuildingAController && signalPostDetectBuildingAController.abort();
                        signalPostDetectBuildingAController = new AbortController();
                        const rspDetectBuilding = await postDetectBuilding(detectBuildingPayload, signalPostDetectBuildingAController);

                        if (rspDetectBuilding?.status !== StatusCodes.OK) {
                            return reject('fail postDetectBuilding');
                        }
                        const detectBuilding = detectBuildingAPIRspSchema.parse(
                            rspDetectBuilding?.data?.data
                        ) as TDetectBuildingAPIResponse;
                        const roofData = Object.values(detectBuilding?.roof_selected)?.[0]?.roof.map(([lat, lng]) => ({
                            lat,
                            lng,
                        })) as TCoordsData;

                        setCacheDetectBuilding({ payload: detectBuildingPayload, data: roofData });

                        return resolve(roofData);
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        [cacheDetectBuilding, setCacheDetectBuilding]
    );

    const trainingDSHandler = useCallback(
        ({ parameter, roof }: { parameter: 'roof_coordinates' | 'roof_type'; roof: TCoordsData }) => {
            if (!isEnvDevFlag(featureFlags['fe-2358']) || !isFieldDefined(userID) || roof.length === 0) return;

            signalPostDSTrainingDataController && signalPostDSTrainingDataController.abort();
            signalPostDSTrainingDataController = new AbortController();

            const body: any = {
                user_id: Number(userID),
                parameter,
            };

            switch (parameter) {
                case 'roof_coordinates':
                    body.values = { original_roof: roof };
                    if (roofByDrawingManual.length > 0) {
                        body.values = { ...body.values, redrawn_roof: roofByDrawingManual };
                    }
                    break;
                case 'roof_type':
                    body.values = {
                        original_roof: {
                            roof_type_id: roofType?.default?.roof_type_id,
                            roof_type: roofType?.default?.roof_type,
                        },
                        roof_coordinates: roof,
                    };
                    if (roofType?.default?.roof_type_id !== roofType.roof_type_id) {
                        body.values = {
                            ...body.values,
                            selected_roof: { roof_type_id: roofType.roof_type_id, roof_type: roofType.roof_type },
                        };
                    }
                    break;
                default:
                    break;
            }
            trainingDSSchema.parse(body);
            postDSTrainingData(body, signalPostDSTrainingDataController);
        },
        [userID, roofByDrawingManual, roofType, featureFlags]
    );

    const fetchDetectRoofType = useCallback(
        ({
            userLocale,
            productID,
            facilityID,
            facilityCoordinates,
            isManualDrawing,
            roof,
        }: {
            userLocale: string;
            productID: number;
            facilityID: number;
            facilityCoordinates: TLatLng;
            isManualDrawing: boolean;
            roof: TCoordsData;
        }): Promise<TDetectRoofTypeData> => {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const detectRoofTypePayload = detectRoofTypePayloadSchema.parse({
                            facilityID: `${facilityID}`,
                            buildingID: '0',
                            lat: facilityCoordinates.lat,
                            lng: facilityCoordinates.lng,
                            coords: roof,
                            zoom: 20,
                            locale: USER_COUNTRY_LatLng?.[userLocale]?.carto,
                            is_manual: isManualDrawing,
                            productID,
                        }) as TDetectRoofTypePayload;

                        // check cache store data | get request cache
                        if (JSON.stringify(detectRoofTypePayload) === JSON.stringify(cacheDetectRoofType.payload)) {
                            return resolve(cacheDetectRoofType.data);
                        }
                        signalPostDetectRoofTypeAController && signalPostDetectRoofTypeAController.abort();
                        signalPostDetectRoofTypeAController = new AbortController();
                        const rspDetectRoofType = await postDetectRoofType(detectRoofTypePayload, signalPostDetectRoofTypeAController);

                        if (rspDetectRoofType?.status !== StatusCodes.OK) {
                            return reject('fail postDetectRoofType');
                        }
                        trainingDSHandler({ parameter: 'roof_coordinates', roof });
                        const detectRoofType = detectRoofTypeDataSchema.parse(rspDetectRoofType?.data?.data) as TDetectRoofTypeData;

                        // set cache store data
                        setCacheDetectRoofType({ payload: detectRoofTypePayload, data: detectRoofType });
                        return resolve(detectRoofType);
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        [cacheDetectRoofType, setCacheDetectRoofType, trainingDSHandler]
    );

    const autoRoofDetailsOnHandler = useCallback(
        ({
            userLocale,
            isB2C,
            facilityCoordinates,
            facilityID,
            productID,
            isManualDrawing,
            roofTypeStructures,
        }: {
            userLocale: string;
            isB2C: boolean;
            facilityCoordinates: TLatLng;
            facilityID: number;
            productID: number;
            isManualDrawing: boolean;
            roofTypeStructures: TStructureForRoofTypeItemData[];
        }): Promise<void> =>
            new Promise((resolve, reject) => {
                (async () => {
                    try {
                        let roofDetected = [] as TCoordsData;
                        if (isB2C) {
                            roofDetected = (await fetchDetectRooftop({ facilityCoordinates })) as TCoordsData | [];
                        } else {
                            roofDetected = (await fetchDetectBuilding({
                                userLocale,
                                facilityID,
                                facilityCoordinates,
                            })) as TCoordsData;
                        }
                        const roofType = (await fetchDetectRoofType({
                            userLocale,
                            productID,
                            isManualDrawing,
                            facilityID,
                            facilityCoordinates,
                            roof: roofDetected,
                        })) as TDetectRoofTypeData;

                        solarpvDispatchHandler('SET_SIMPLE_MODE_ROOF_IDENTIFICATION', {
                            stepToGo: SIMPLE_MODE_STEPS.ROOF_DETAILS,
                            roof: roofDetected,
                            roofType,
                            roofTypeStructures,
                            loadings: [{ name: SpvLoadings.spvSimpleRoofIdentification, value: false }],
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                })();
            }),
        [fetchDetectRooftop, fetchDetectBuilding, fetchDetectRoofType, solarpvDispatchHandler]
    );

    const manualDrawingRoofDetailsOnHandler = useCallback(
        ({
            userLocale,
            facilityCoordinates,
            facilityID,
            productID,
            isManualDrawing,
            roofTypeStructures,
            roof,
        }: {
            userLocale: string;
            facilityCoordinates: TLatLng;
            facilityID: number;
            productID: number;
            isManualDrawing: boolean;
            roofTypeStructures: TStructureForRoofTypeItemData[];
            roof: TCoordsData;
        }): Promise<void> =>
            new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const roofType = (await fetchDetectRoofType({
                            userLocale,
                            productID,
                            isManualDrawing,
                            facilityID,
                            facilityCoordinates,
                            roof,
                        })) as TDetectRoofTypeData;

                        solarpvDispatchHandler(SpvActions.SET_SIMPLE_MODE_ROOF_IDENTIFICATION, {
                            stepToGo: SIMPLE_MODE_STEPS.ROOF_DETAILS,
                            roof,
                            roofType,
                            roofTypeStructures,
                            loadings: [{ name: SpvLoadings.spvSimpleRoofIdentification, value: false }],
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                })();
            }),
        [fetchDetectRoofType, solarpvDispatchHandler]
    );

    const roofIdentificationOnHandler = useCallback(
        ({
            isManualDrawing,
            isB2C,
            facilityCoordinates,
            facilityID,
            productID,
            userLocale,
            roofByDrawingManual,
        }: {
            isManualDrawing: boolean;
            isB2C: boolean;
            facilityCoordinates: TLatLng;
            facilityID: number;
            productID: number;
            userLocale: string;
            roofByDrawingManual: TCoordsData;
        }): Promise<void> =>
            new Promise((resolve) => {
                (async () => {
                    try {
                        solarpvDispatchHandler(SpvActions.SET_IS_REQUEST_ROOF_FAIL, { isRequestRoofFail: false });
                        solarpvDispatchHandler(SpvActions.SET_LOADINGS, {
                            loadings: [{ name: SpvLoadings.spvSimpleRoofIdentification, value: true }],
                        });
                        roofTypeStructuresRef.current = (await fetchStructureForRoofType({ productID })) as TStructureForRoofTypeItemData[];

                        if (isManualDrawing && roofByDrawingManual?.length > 0 && roofTypeStructuresRef.current) {
                            await manualDrawingRoofDetailsOnHandler({
                                userLocale,
                                facilityCoordinates,
                                facilityID,
                                productID,
                                isManualDrawing,
                                roofTypeStructures: roofTypeStructuresRef.current,
                                roof: roofByDrawingManual as TCoordsData,
                            });
                        } else if (roofTypeStructuresRef.current) {
                            await autoRoofDetailsOnHandler({
                                userLocale,
                                isB2C,
                                facilityCoordinates,
                                facilityID,
                                productID,
                                isManualDrawing,
                                roofTypeStructures: roofTypeStructuresRef.current,
                            });
                        }
                        resolve();
                    } catch (error) {
                        solarpvDispatchHandler(SpvActions.SET_IS_REQUEST_ROOF_FAIL, { isRequestRoofFail: true });
                        errorCatchOnHandler({ error, fnc: 'roofIdentificationOnHandler', tag: `STEP.ROOF_IDENTIFICATION` });
                    }
                })();
            }),
        [fetchStructureForRoofType, manualDrawingRoofDetailsOnHandler, autoRoofDetailsOnHandler, solarpvDispatchHandler]
    );
    //#endregion step ROOF_IDENTIFICATION

    //#region step KPIS_DISPLAY
    const fetchCoordinatesConversionFactor = useCallback(
        ({
            facilityCoordinates,
            facilityID,
            userLocale,
        }: {
            userLocale: string;
            facilityCoordinates: TLatLng;
            facilityID: number;
        }): Promise<TCoordConvFactorData> => {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const coordConvFactorPayload = coordConvFactorPayloadSchema.parse({
                            lat: facilityCoordinates.lat,
                            lng: facilityCoordinates.lng,
                            facilityID: `${facilityID}`,
                            userLocale,
                        }) as TCoordConvFactorPayload;

                        // check cache store data | get request cache
                        if (JSON.stringify(coordConvFactorPayload) === JSON.stringify(cacheConvFactors.payload)) {
                            return resolve(cacheConvFactors.data);
                        }

                        signalGetCoordConvFactorAController && signalGetCoordConvFactorAController.abort();
                        signalGetCoordConvFactorAController = new AbortController();

                        const rspCoordConvFactorPayload = await getCoordinatesConversionFactor(
                            coordConvFactorPayload,
                            false,
                            signalGetCoordConvFactorAController
                        );

                        if (rspCoordConvFactorPayload?.status !== StatusCodes.OK) {
                            return reject('fail getCoordinatesConversionFactor');
                        }
                        const coordConvFactorData = coordConvFactorDataSchema.parse(
                            rspCoordConvFactorPayload?.data?.data
                        ) as TCoordConvFactorData;

                        // set cache store data
                        setCacheConvFactors({ payload: coordConvFactorPayload, data: coordConvFactorData });
                        return resolve(coordConvFactorData);
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        [cacheConvFactors, setCacheConvFactors]
    );

    const flatRoofOnHandler = useCallback(
        ({
            facilityID,
            userLocale,
            mounting_structure_id,
            mounting_structure_type_id,
            slope,
            roof,
            coordConvFactor,
        }: {
            userLocale: string;
            facilityID: number;
            mounting_structure_id: number;
            mounting_structure_type_id: number;
            slope: number;
            roof: TCoordsData;
            coordConvFactor: TCoordConvFactorData;
        }): Promise<TAreaItem[]> => {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const paths: google.maps.LatLng[] = [];
                        const newAreas: TAreaItem[] = [];

                        roof?.forEach((el) => {
                            paths.push(new google.maps.LatLng(el.lat, el.lng));
                        });

                        const roofData = getRoofDatabyPaths(paths);
                        const initialArea = {
                            id: 1,
                            coordinates: roof,
                            area: roofData.area,
                            perimeter_area: roofData.perimeter_area,
                            coordinates_avg: {
                                lat: roofData.centroid.lat,
                                long: roofData.centroid.lng,
                            },
                            lat: roofData.centroid.lat,
                            long: roofData.centroid.lng,
                            mounting_structure_id,
                            mounting_structure_type_id,
                            slope,
                            panels_number: 0,
                            panels_number_excluded: 0,
                            panels_number_possible: 0,
                        } as TAreaItem;

                        const estStructureAspectPayload = estimatedStructureAspectPayloadSchema.parse({
                            convFactors: coordConvFactor,
                            facility_id: `${facilityID}`,
                            country_alpha_2: userLocale.split('-')[1],
                            areas: [
                                {
                                    ...initialArea,
                                    //centroid: roofData.centroid,
                                },
                            ],
                            get_best_aspect: true,
                            use_carto: false,
                        }) as TEstimatedStructureAspectPayload;

                        // check cache store data | get request cache
                        if (JSON.stringify(estStructureAspectPayload) === JSON.stringify(cacheEstimatedStructuresAspect.payload)) {
                            return resolve(cacheEstimatedStructuresAspect.data);
                        }

                        signalPostEstimatedStructureAController && signalPostEstimatedStructureAController.abort();
                        signalPostEstimatedStructureAController = new AbortController();

                        const rspEstStructuresAspect = await postPVEquipmentsEstStructuresAspect(
                            estStructureAspectPayload,
                            false,
                            signalPostEstimatedStructureAController
                        );

                        if (rspEstStructuresAspect?.status !== StatusCodes.OK) {
                            return reject('fail EstStructuresAspect');
                        }

                        const estStructuresAspect = estStructuresAspectDataSchema.parse(
                            rspEstStructuresAspect?.data?.data?.[0]
                        ) as TEstimatedStructureAspectData;

                        // build new areas
                        newAreas.push({
                            ...initialArea,
                            edges: estStructuresAspect?.edges,
                            aspect: estStructuresAspect?.aspect,
                            orientation: Number(estStructuresAspect?.aspect.toFixed(2)),
                        } as TAreaItem);

                        // set cache store data
                        setCacheEstimatedStructuresAspect({ payload: estStructureAspectPayload, data: newAreas });

                        return resolve(newAreas);
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        [cacheEstimatedStructuresAspect, setCacheEstimatedStructuresAspect]
    );

    const pitchedRoofOnHandler = useCallback(
        ({
            facilityID,
            userLocale,
            isB2C,
            panelSelected,
            roof,
            coordConvFactor,
            structureSelected,
            mounting_structure_id,
            mounting_structure_type_id,
            slope,
        }: {
            userLocale: string;
            facilityID: number;
            isB2C: boolean;
            panelSelected: TPanelItem;
            mounting_structure_id: number;
            mounting_structure_type_id: number;
            roof: TCoordsData;
            coordConvFactor: TCoordConvFactorData;
            structureSelected: TStructureItem;
            slope: number;
        }): Promise<TAreaItem[]> => {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const paths: google.maps.LatLng[] = [];
                        const newAreas: TAreaItem[] = [];

                        const roofCoordinates = roof && Array.isArray(roof) ? roof.map((obj) => [obj.lat, obj.lng]) : [];

                        const roofSplitPayload = roofSplitPayloadSchema.parse({
                            facilityID: `${facilityID}`,
                            buildingID: 1,
                            isB2C,
                            locale: USER_COUNTRY_LatLng?.[userLocale]?.carto,
                            convFactors: coordConvFactor,
                            coords: roofCoordinates,
                            panelDimensions: { panel_w: panelSelected?.largura, panel_l: panelSelected?.altura },
                            defaultRoofSlope: structureSelected?.default_roof_slope,
                            defaultSlope: structureSelected?.default_roof_slope,
                            zoom: 20,
                        }) as TRoofSplitPayload;

                        // check cache store data | get request cache
                        if (JSON.stringify(roofSplitPayload) === JSON.stringify(cacheRoofSplit.payload)) {
                            return resolve(cacheRoofSplit.data);
                        }
                        signalPostRoofSplitAController && signalPostRoofSplitAController.abort();
                        signalPostRoofSplitAController = new AbortController();
                        const rspRoofSplit = await postRoofSplit(roofSplitPayload, signalPostRoofSplitAController);

                        if (rspRoofSplit?.status !== StatusCodes.OK) {
                            return reject('fail postRoofSplit');
                        }
                        const roofSplitData = roofSplitAPIDataSchema.parse(rspRoofSplit?.data?.data) as TRoofSplitAPIData;

                        const roofs = Object.entries(roofSplitData?.facets ?? []).map((roof) => roof[1]);

                        // build new areas
                        roofs.forEach((roof, idx) => {
                            const areaId = idx + 1;
                            const roofCoords = roof.facet_poly.map(([lat, lng]) => ({ lat, lng }));
                            roof.facet_poly.forEach(([lat, lng]) => {
                                paths.push(new google.maps.LatLng(lat, lng));
                            });
                            // const centroid = getCentroid(roofCoords);
                            const bounds = new window.google.maps.LatLngBounds();
                            paths?.forEach((path) => bounds.extend(new window.google.maps.LatLng(path.lat(), path.lng())));
                            const roofData = getRoofDatabyPaths(paths);

                            newAreas.push({
                                id: areaId,
                                coordinates: roofCoords,
                                coordinates_avg: {
                                    lat: roofData.centroid.lat,
                                    long: roofData.centroid.lng,
                                },
                                aspect: roof.orientation_guess?.[0]?.aspect ?? null,
                                lat: roofData.centroid.lat,
                                long: roofData.centroid.lng,
                                orientation: Number(roof.orientation_guess?.[0].aspect.toFixed(2)),
                                perimeter_area: roofData.perimeter_area,
                                area: roofData.area,
                                edges: roof.orientation_guess?.[0]?.edges,
                                mounting_structure_id,
                                mounting_structure_type_id,
                                slope,
                                panels_number: 0,
                                panels_number_excluded: 0,
                                panels_number_possible: 0,
                            } as TAreaItem);
                        });

                        // set cache store data
                        setCacheRoofSplit({ payload: roofSplitPayload, data: newAreas });
                        return resolve(newAreas);
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        [cacheRoofSplit, setCacheRoofSplit]
    );

    const fetchExclusionAreaADetectedOnHandler = useCallback(
        (options: { areas: TAreaItem[] }): Promise<TExclusionsData[]> => {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const { areas } = options;
                        const exclusions: TExclusionsData[] = [];
                        const exclusionsRequests: Promise<any>[] = [];
                        const exclusionsPayloads: TExclusionAreaADetectedPayload[] = [];

                        areas.forEach((area) => {
                            const exclusionAreaADetectedPayload = exclusionAreaADetectedPayloadSchema.parse({
                                areaId: area.id,
                                roof: [area.coordinates],
                                center: {
                                    lat: area.coordinates_avg.lat,
                                    lng: area.coordinates_avg.long,
                                    zoom: 20,
                                },
                                angle: area.aspect ?? null,
                                image_name: `exclusions-${area.id}-${area.coordinates_avg.lat}_${area.coordinates_avg.long}.png`,
                            }) as TExclusionAreaADetectedPayload;
                            exclusionsPayloads.push(exclusionAreaADetectedPayload);
                        });

                        // check cache store data | get request cache
                        if (JSON.stringify(exclusionsPayloads) === JSON.stringify(cacheExclusions.payload)) {
                            return resolve(cacheExclusions.data);
                        }
                        //prepare requests
                        exclusionsPayloads.forEach((exclusionAreaADetectedPayload, idx) => {
                            sinalPostExclusionsAreaADetectedAController.push(new AbortController());
                            exclusionsRequests.push(
                                postAutoExclusions(
                                    exclusionAreaADetectedPayload,
                                    sinalPostExclusionsAreaADetectedAController[idx]
                                ) as Promise<TExclusionAreaADetectedData>
                            );
                        });

                        // request all
                        const responses = (await Promise.all(exclusionsRequests)) as [TExclusionAreaADetectedData];

                        if (!responses?.[0]) return reject('RESPONSE_CANCELED_BY_USER');

                        let exclusionId = 1;
                        responses.forEach((response, _idx) => {
                            const rpsData = response?.data?.data;
                            // Consider only areas with response.data > 0
                            if (rpsData?.length > 0) {
                                for (let i = 0; i !== rpsData?.length; i++) {
                                    const excludedArea = rpsData[i] as TCoordsData;
                                    exclusions.push({
                                        id: exclusionId,
                                        isPolygon: true,
                                        coordinates: excludedArea.map((coord) => [coord.lat, coord.lng]),
                                    } as TExclusionsData);
                                    exclusionId++;
                                }
                            }
                        });

                        // set cache store data
                        setCacheExclusions({ payload: exclusionsPayloads, data: exclusions });
                        return resolve(exclusions as TExclusionsData[]);
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        [cacheExclusions, setCacheExclusions]
    );

    const fetchUsefulAreasOnHandler = useCallback(
        (options: {
            isB2C: boolean;
            facility_id: number | string;
            areas: TAreaItem[];
            exclusions: TExclusionsData[];
            coordinates_conversion_factor: TCoordConvFactorData;
            structureSelected: TStructureItem;
            panelSelected: TPanelItem;
            default_panel_alignment_mode_id: number;
            kits: TKits;
            tecLimits: {
                min_tec_panels: number;
                max_tec_panels: number;
            };
        }): Promise<TAreaItem[]> => {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const EAST_WEST_PANELS_UP_DEFAULT = 2;
                        const EAST_WEST_IS_ROW_SPACING_INSERT_DEFAULT = true;
                        const usefulAreas: TUsefulAreaData[] = [];
                        let newAreas: TAreaItem[] = [];
                        const usefulAreasPayloads: TUsefulAreaPayload[] = [];

                        const {
                            isB2C,
                            facility_id,
                            areas,
                            exclusions,
                            coordinates_conversion_factor,
                            panelSelected,
                            structureSelected,
                            default_panel_alignment_mode_id,
                            kits,
                            tecLimits,
                        } = options;
                        const holes_coordinates: TCoordinatesDS[] = [];

                        exclusions.forEach((el) => {
                            if (el.coordinates?.every((coordinate) => coordinate.length > 0 && coordinate.every((c) => isDefined(c))))
                                holes_coordinates.push(el.coordinates);
                        });

                        const defaultInputs = structureSelected?.fe_elements_arr ?? [];
                        const isGroundStructure = structureSelected?.tipo_estrutura_id === MOUNTING_STRUCTURES_TYPES.GROUND;
                        const isEastWest = structureSelected?.tipo_estrutura_id === MOUNTING_STRUCTURES_TYPES.EAST_WEST;

                        //#region PANEL_FRAMING BY EAST_WEST
                        const panelFramingData = structureSelected.fe_elements_arr?.find(
                            (input) => input.designacao_fe === PANEL_REPRESENTATION_INPUT_NAMES.PANEL_FRAMING
                        );
                        let panelFramingByEastWest = PANEL_FRAMING.LANDSCAPE;
                        if (isEastWest && panelFramingData?.visivel) {
                            panelFramingByEastWest = Number(panelFramingData?.valor_default);
                        }
                        //#endregion PANEL_FRAMING BY EAST_WEST

                        //#region define var's
                        // If we don't have the property, revert to default
                        const dayRank = structureSelected?.[PANEL_REPRESENTATION_INPUT_NAMES.REFERENCE_DAY];
                        const hourWindow = structureSelected?.[PANEL_REPRESENTATION_INPUT_NAMES.HOUR_WINDOW];
                        const panelFraming =
                            isEastWest ? panelFramingByEastWest : structureSelected?.[PANEL_REPRESENTATION_INPUT_NAMES.PANEL_FRAMING];
                        const panels_up =
                            isEastWest ? EAST_WEST_PANELS_UP_DEFAULT : (
                                Number(
                                    structureSelected?.[PANEL_REPRESENTATION_INPUT_NAMES.PANELS_UP] ??
                                        defaultInputs?.find((element) => element.designacao_fe === 'panels_up')?.valor_default ??
                                        1
                                )
                            );
                        const panels_wide = Number(
                            defaultInputs?.find((element) => element.designacao_fe === PANEL_REPRESENTATION_INPUT_NAMES.PANELS_WIDE)
                                ?.valor_default ?? 1
                        );
                        const setback = Number(
                            defaultInputs?.find((element) => element.designacao_fe === PANEL_REPRESENTATION_INPUT_NAMES.SETBACK)
                                ?.valor_default ?? 0.0
                        );
                        const space_btw_sets = Number(
                            defaultInputs?.find((element) => element.designacao_fe === PANEL_REPRESENTATION_INPUT_NAMES.SPACE_BTW_SETS)
                                ?.valor_default ?? 1.0
                        );
                        // rowSpace
                        const is_row_spacing_insert =
                            isEastWest ?
                                EAST_WEST_IS_ROW_SPACING_INSERT_DEFAULT
                            :   structureSelected[PANEL_REPRESENTATION_INPUT_NAMES.IS_ROW_SPACING_INSERT];
                        const rowSpaceDefault =
                            structureSelected[PANEL_REPRESENTATION_INPUT_NAMES.ROW_SPACE] ??
                            Number(
                                defaultInputs?.find((element) => element.designacao_fe === PANEL_REPRESENTATION_INPUT_NAMES.ROW_SPACE)
                                    ?.valor_default ?? 0.0
                            );
                        // roofSlope
                        let roofSlope =
                            structureSelected[PANEL_REPRESENTATION_INPUT_NAMES.ROOF_SLOPE] ?? structureSelected.default_roof_slope;

                        if (isGroundStructure) roofSlope = 0; //Ground, (on the ground, There is no slope of the roof)
                        // Prepare get row ratio payload
                        let difPanelSlope = areas[0].slope - roofSlope;
                        //fallback
                        difPanelSlope = difPanelSlope < 0 ? 0 : difPanelSlope; //DS difPanelSlope mandatory >= 0

                        //#endregion define var's

                        // Request rowRatio if we need an estimate
                        let estimateRowSpace = rowSpaceDefault;
                        if (!is_row_spacing_insert && difPanelSlope > 0) {
                            let rowRatio = 0;

                            const rowRatioRequestParams = rowRatioPayloadSchema.parse({
                                dayRank: convertDateToDayRank(dayRank, areas[0].coordinates_avg.lat),
                                lat: areas[0].coordinates_avg.lat,
                                long: areas[0].coordinates_avg.long,
                                hourWindow,
                                roofSlope,
                                difPanelSlope,
                                panelOrientation: areas[0].orientation,
                                elevation: 10,
                            }) as TRowRatioPayload;

                            // check cache store data | get request cache
                            if (JSON.stringify(rowRatioRequestParams) === JSON.stringify(cacheRowRatio.payload)) {
                                rowRatio = cacheRowRatio.data;
                            } else {
                                signalGetRowRatioAController && signalGetRowRatioAController.abort();
                                signalGetRowRatioAController = new AbortController();
                                const rspRowRatio = await getPVRowRatio(rowRatioRequestParams, signalGetRowRatioAController);
                                if (rspRowRatio?.status !== StatusCodes.OK) {
                                    return reject(rspRowRatio);
                                }
                                rowRatio = rowRatioDataschema.parse(rspRowRatio).data as number;
                                // set cache store data
                                setCacheRowRatio({ payload: rowRatioRequestParams, data: rowRatio });
                            }

                            estimateRowSpace =
                                rowRatio *
                                (panelFraming === PANEL_FRAMING.LANDSCAPE ? panelSelected.largura : panelSelected.altura) *
                                panels_up;

                            const row_spacing_min = Number(
                                defaultInputs?.find((element) => element.designacao_fe === PANEL_REPRESENTATION_INPUT_NAMES.ROW_SPACING_MIN)
                                    ?.valor_default
                            );
                            if (estimateRowSpace < row_spacing_min) {
                                estimateRowSpace = row_spacing_min;
                            }
                        }
                        const row_space = !is_row_spacing_insert && isNumberDefined(estimateRowSpace) ? estimateRowSpace : rowSpaceDefault;

                        areas.forEach((area) => {
                            const uasefulAreadPayload = usefulAreaPayloadSchema.parse({
                                facility_id: `${facility_id}`,
                                roof_coordinates: area.coordinates.map((coordinates) => [coordinates.lat, coordinates.lng]),
                                panel_w: panelSelected.largura,
                                panel_l: panelSelected.altura,
                                row_space,
                                panelOrientation: area.orientation,
                                difPanelSlope,
                                holes_coordinates,
                                roofSlope,
                                setback,
                                panels_wide,
                                panels_up,
                                space_btw_sets,
                                alignment_optimizer: isB2C,
                                area: area.area,
                                draggabled: false,
                                reference_point: [],
                                coordinates_conversion_factor,
                                output_on_cache: false,
                                area_id: area.id,
                                isV3: true,
                                manual_removed_idxs: [],
                                manual_added_idxs: [],
                                alignment_mode_id: default_panel_alignment_mode_id,
                                mounting_structure_type_id: structureSelected?.tipo_estrutura_id,
                            }) as TUsefulAreaPayload;

                            usefulAreasPayloads.push(uasefulAreadPayload);
                        });

                        // check cache store data | get request cache
                        if (JSON.stringify(usefulAreasPayloads) === JSON.stringify(cacheUsefulAreas.payload)) {
                            return resolve(cacheUsefulAreas.data);
                        }
                        //prepare requests
                        const usefulAreaRequests: Promise<Nullable<TUsefulAreaAPIData>>[] = [];
                        usefulAreasPayloads.forEach((uasefulAreadPayload) => {
                            const postUsefulAreadAController = new AbortController();
                            sinalPostUsefulAreasAController.push(postUsefulAreadAController);
                            usefulAreaRequests.push(
                                postSPVUsefulArea(uasefulAreadPayload, postUsefulAreadAController) as Promise<TUsefulAreaAPIData>
                            );
                        });

                        // request all
                        const responses = (await Promise.all(usefulAreaRequests)) as [TUsefulAreaAPIData];
                        if (!responses?.[0]) return reject('RESPONSE_CANCELED_BY_USER');
                        responses.forEach((response, idx) => {
                            const panels_number = response.data?.panels_number;
                            newAreas.push({
                                ...areas[idx],
                                panels_number: panels_number,
                                panels_number_excluded: 0,
                                panels_number_possible: panels_number,
                                peak_power: Number((panelSelected?.potencia_pico * panels_number).toFixed(2)),
                            });
                            usefulAreas.push({ ...response.data } as TUsefulAreaData);
                        });

                        if (kits?.length > 0) {
                            newAreas = calculateValidPanelsKits(newAreas, { kits });
                        } else {
                            const total_panels = newAreas.reduce((acc, elm) => acc + elm.panels_number, 0) as number;
                            if (total_panels > tecLimits.max_tec_panels) {
                                newAreas = calculateValidPanels(newAreas, tecLimits.min_tec_panels, tecLimits.max_tec_panels);
                            }
                        }

                        // set cache store data
                        setCacheUsefulAreas({ payload: usefulAreasPayloads, data: newAreas });
                        return resolve(newAreas as TAreaItem[]);
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        [cacheRowRatio, setCacheRowRatio, cacheUsefulAreas, setCacheUsefulAreas]
    );

    const fetchInvertersCombination = useCallback(
        ({ inputs }: { inputs: TInputs }): Promise<TInvertersCombData> => {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const inverterCombPayload = invertersCombPayloadSchema.parse(inputs) as TInvertersCombPayload;

                        // check cache store data | get request cache
                        if (JSON.stringify(inverterCombPayload) === JSON.stringify(cacheInvertersComb.payload)) {
                            return resolve(cacheInvertersComb.data);
                        }

                        sinalPostInvertersCombinationAController && sinalPostInvertersCombinationAController.abort();
                        sinalPostInvertersCombinationAController = new AbortController();
                        const rpsInvertersCombination = (await getInvertersCombination(
                            { inputs: inverterCombPayload },
                            sinalPostInvertersCombinationAController
                        )) as TInvertersCombAPIData;

                        const invertersCombinationList = inverterCombAPIDataSchema.parse(rpsInvertersCombination) as TInvertersCombAPIData;

                        // set cache store data
                        setCacheInvertersComb({
                            payload: inverterCombPayload,
                            data: invertersCombinationList.data.data.recommended,
                        });
                        return resolve(invertersCombinationList.data.data.recommended as TInvertersCombData);
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        [cacheInvertersComb, setCacheInvertersComb]
    );

    const fetchOptimizerOnHandler = useCallback(
        (options: {
            inputs: TInputs;
            optimizationInputs: TOptimizationInputs;
            facilityID: number;
            potencia_pico: number;
            kits: TKits;
        }): Promise<TSolutionData> =>
            new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const { inputs, optimizationInputs, facilityID, potencia_pico, kits } = options;
                        const newInputs = clone(inputs);

                        const optimizerPayload =
                            kits?.length > 0 ?
                                (optimizerWithKitsPayloadSchema.parse({
                                    facility: { id: facilityID },
                                    inputs,
                                    ...optimizationInputs,
                                    kits,
                                    register_simulation: false,
                                }) as TOptimizerWithKitsPayload)
                            :   (optimizerPayloadSchema.parse({
                                    facility: { id: facilityID },
                                    inputs,
                                    ...optimizationInputs,
                                    register_simulation: false,
                                }) as TOptimizerPayload);

                        // check cache store data | get request cache
                        if (JSON.stringify(optimizerPayload) === JSON.stringify(cacheOptimizer.payload)) {
                            return resolve(cacheOptimizer.data);
                        }

                        signalPostOptimizerAController && signalPostOptimizerAController.abort();
                        signalPostOptimizerAController = new AbortController();
                        //optimizer request
                        const rspOptimizer = await postOptimizer(optimizerPayload, signalPostOptimizerAController);

                        if (rspOptimizer?.status !== StatusCodes.OK) {
                            setInfoTagsHandler([{ message: rspOptimizer?.data?.message ?? null }]);
                            return reject(rspOptimizer?.data?.message ?? 'fail postOptimizer');
                        }

                        // check optimizer data
                        if (kits?.length > 0) {
                            optimizerAPIDataByKitsSchema.parse(rspOptimizer?.data?.data) as TOptimizerAPIData;
                        } else {
                            optimizerAPIDataSchema.parse(rspOptimizer?.data?.data) as TOptimizerAPIData;
                        }

                        const optimizerData = rspOptimizer?.data?.data;

                        const newAreas = [] as TAreaItem[];

                        newInputs.areas.forEach((area) => {
                            const areaByOptimizer = optimizerData.solution.areas.find((el) => el.id === area.id) ?? area;
                            newAreas.push({
                                ...area,
                                max_type_area_panels: MAX_SIZE_OPTIONS.PANELS,
                                max_tec_area_panels: areaByOptimizer?.panels_number,
                                panels_number: areaByOptimizer?.panels_number,
                                max_kwp_area: null,
                                panels_number_excluded: parseInt(
                                    (
                                        parseInt(areaByOptimizer.panels_number_possible.toString()) -
                                        parseInt(areaByOptimizer.panels_number.toString())
                                    ).toString()
                                ),
                                panels_number_possible: area.panels_number_possible,
                                peak_power: Number((potencia_pico * areaByOptimizer?.panels_number).toFixed(2)),
                            });
                        });

                        //set inputs total_panels && areas
                        newInputs.areas = newAreas;
                        newInputs.inverters_combination = optimizerData.solution.inverters_combination;
                        newInputs.total_panels = newInputs.areas.reduce((acc, elm) => acc + elm.panels_number, 0) as number;
                        newInputs.inverters_combination = optimizerData.solution.inverters_combination;

                        const solutionData = {
                            inputs: newInputs,
                            kpis: optimizerData.solution.kpis,
                        };

                        // set cache store data
                        setCacheOptimizer({
                            payload: optimizerPayload,
                            data: solutionData,
                        });
                        return resolve(solutionData as TSolutionData);
                    } catch (error) {
                        setInfoTagsHandler([{ message: 'MISSING_INVESTMENT' ?? null }]);
                        reject(error);
                    }
                })();
            }),
        [cacheOptimizer, setCacheOptimizer, setInfoTagsHandler]
    );

    const fetchSimulationOnHandler = useCallback(
        (options: { inputs: TInputs; facilityID: number; productID: number }): Promise<TSolutionData> =>
            new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const { inputs, facilityID, productID } = options;

                        const simPayload = simulationPayloadSchema.parse({
                            facility: { id: facilityID },
                            inputs,
                            tipo_produto_id: productID,
                            is_scaling: true,
                        }) as TSimulationPayload;

                        // check cache store data | get request cache
                        if (JSON.stringify(simPayload) === JSON.stringify(cacheSimulation.payload)) {
                            return resolve(cacheSimulation.data);
                        }

                        signalPostSimulationAController && signalPostSimulationAController.abort();
                        signalPostSimulationAController = new AbortController();
                        //sims request
                        const rspSimulation = await postSimulation(simPayload, false, signalPostSimulationAController);

                        if (rspSimulation?.status !== StatusCodes.OK) {
                            setInfoTagsHandler([{ message: rspSimulation?.data?.message }]);
                            return reject('fail postSimulation');
                        }

                        //check simulation data
                        simulationDataSchema.parse(rspSimulation?.data?.data) as TSimulationData;
                        const simulationData = rspSimulation?.data?.data;

                        const solutionData = {
                            inputs,
                            kpis: simulationData.kpis,
                        } as TSolutionData;
                        // set cache store data
                        setCacheSimulation({
                            payload: simPayload,
                            data: solutionData,
                        });
                        return resolve({
                            inputs,
                            kpis: simulationData.kpis,
                        } as TSolutionData);
                    } catch (error) {
                        reject(error);
                    }
                })();
            }),
        [cacheSimulation, setCacheSimulation, setInfoTagsHandler]
    );

    const kpisDisplayOnHandler = useCallback(
        ({
            isManualDrawing,
            isB2C,
            facilityCoordinates,
            facilityID,
            productID,
            userLocale,
            roofType,
            roofTypeStructures,
            structures,
            ranges,
            roof,
            pvOptions,
            optimizationsData,
            powerLimit,
            risco_energia_id,
            tarifa_equivalente_ac,
            kits,
            sInputs,
        }: {
            isManualDrawing: boolean;
            isB2C: boolean;
            facilityCoordinates: TLatLng;
            facilityID: number;
            productID: number;
            userLocale: string;
            roofTypeStructures: TStructureForRoofTypeItemData[];
            roofType: TDetectRoofTypeData;
            structures: TStructureItem[];
            ranges: TRangeDataItem[];
            roof: TCoordsData;
            sInputs: TInputs;
            pvOptions: TPvOptions;
            optimizationsData: TOptimizations[];
            powerLimit: number;
            risco_energia_id: Nullable<number>;
            tarifa_equivalente_ac: Nullable<number>;
            kits: TKits;
        }): Promise<void> =>
            new Promise(() => {
                (async () => {
                    try {
                        //reset loading state for Kpis screen
                        solarpvDispatchHandler(SpvActions.SET_LOADINGS, {
                            loadings: [{ name: SpvLoadings.spvSimpleKpisRequests, value: true }],
                        });
                        trainingDSHandler({ parameter: 'roof_type', roof });
                        let solutionData: TSolutionData = {} as TSolutionData;
                        let newAreas: TAreaItem[] = [];
                        const {
                            prod_params,
                            default_network_sale,
                            injection_tariff_default,
                            default_panel_alignment_mode_id,
                            limit_network_sale,
                        } = pvOptions as TPvOptions;
                        const rangeSelected = ranges?.find((range) => range?.is_default) as TRangeDataItem;
                        const panelSelected = rangeSelected?.paineis?.find((panel) => panel.is_default) as TPanelItem;
                        const { mounting_structure_id, roof_type_id: mounting_structure_type_id } = roofTypeStructures?.find(
                            (el) => el.roof_type_id === roofType.roof_type_id
                        ) as TStructureForRoofTypeItemData;
                        const structureSelected = structures?.find((el) => el.id === mounting_structure_id) as TStructureItem;

                        const slopeDefault = getDefaultSlope(structures, mounting_structure_id);
                        const inputs = {
                            ...sInputs,
                            range_id: rangeSelected?.id,
                            panel_id: panelSelected?.id,
                            coordinates_avg: {
                                lat: facilityCoordinates.lat,
                                long: facilityCoordinates.lng,
                            },
                            prod_params,
                            is_manual: isManualDrawing,
                            injection_tariff: injection_tariff_default,
                            limit_network_sale,
                            network_sale: default_network_sale,
                            is_kits: kits?.length > 0,
                        } as TInputs;

                        let coordConvFactor = sInputs?.coordinates_conversion_factor ?? null;
                        if (!coordConvFactor) {
                            coordConvFactor = (await fetchCoordinatesConversionFactor({
                                facilityCoordinates,
                                facilityID,
                                userLocale,
                            })) as TCoordConvFactorData;
                        }

                        //set inputs coordinates_conversion_factor
                        inputs.coordinates_conversion_factor = coordConvFactor as TCoordConvFactorData;

                        if (mounting_structure_type_id === SPV_SIMPLE_ROOF_TYPES_IDS.FLAT_ROOF) {
                            newAreas = (await flatRoofOnHandler({
                                facilityID,
                                userLocale,
                                roof,
                                coordConvFactor,
                                mounting_structure_id,
                                mounting_structure_type_id,
                                slope: slopeDefault,
                            })) as TAreaItem[];
                        }

                        if (mounting_structure_type_id === SPV_SIMPLE_ROOF_TYPES_IDS.PITCHED_ROOF) {
                            newAreas = (await pitchedRoofOnHandler({
                                facilityID,
                                userLocale,
                                isB2C,
                                panelSelected,
                                roof,
                                coordConvFactor,
                                structureSelected,
                                mounting_structure_id,
                                mounting_structure_type_id,
                                slope: slopeDefault,
                            })) as TAreaItem[];
                        }

                        //set areas
                        inputs.areas = newAreas;

                        // exclusions
                        const exclusions: TExclusionsData[] = await fetchExclusionAreaADetectedOnHandler({
                            areas: newAreas,
                        });

                        // set Inputs exclusions
                        inputs.exclusions = exclusions;

                        inputs.areas = await fetchUsefulAreasOnHandler({
                            isB2C,
                            facility_id: facilityID ?? `${facilityCoordinates.lat}_${facilityCoordinates.lng}`,
                            areas: inputs.areas,
                            exclusions,
                            coordinates_conversion_factor: coordConvFactor,
                            structureSelected,
                            panelSelected,
                            default_panel_alignment_mode_id,
                            kits,
                            tecLimits: {
                                min_tec_panels: inputs.min_tec_panels,
                                max_tec_panels: inputs.max_tec_panels,
                            },
                        });
                        // set inputs
                        inputs.total_panels = inputs.areas.reduce((acc, elm) => acc + elm.panels_number, 0) as number;
                        inputs.total_areas = inputs.areas.reduce((acc, elm) => acc + elm.area, 0) as number;
                        inputs.total_perimeter_areas = inputs.areas.reduce((acc, elm) => acc + elm.perimeter_area, 0) as number;

                        if (inputs.is_kits) {
                            const areasUniqueKitIds = uniqBy(inputs.areas, (el) => el.kit_id);
                            const inputsKits = [] as TInputsKits;
                            areasUniqueKitIds.forEach((el) => {
                                if (el?.kit_id)
                                    inputsKits.push({
                                        id: el?.kit_id,
                                        areas: [...new Set(inputs?.areas.map((item) => item.id))],
                                    });
                            });

                            inputs.kits = inputsKits;
                        }

                        //validate inputs total_panels
                        if (inputs.total_panels === 0) {
                            setInfoTagsHandler([{ message: 'NO_AREAS_WITH_PANELS', type: 'error' }]);
                            return;
                        }
                        const totalPanelsNumberPossible = inputs.areas.reduce((total, item) => (total += item.panels_number_possible), 0);
                        let totalPeakPower: number = 0;
                        inputs.areas.forEach((area) => {
                            totalPeakPower += calculateAreaPeakPower(area.id, inputs, ranges);
                        });

                        if (totalPanelsNumberPossible < inputs.min_tec_panels) {
                            setInfoTagsHandler([
                                {
                                    message: 'TOTAL_PANELS_NUMBER_EXCEED_MIN_LIMIT',
                                    data: {
                                        kwp: Math.round(inputs.min_tec_panels * totalPeakPower),
                                        num_panels: inputs.min_tec_panels,
                                        total_panels_number_possible: totalPanelsNumberPossible,
                                    },
                                },
                            ]);
                            return;
                        }

                        //optimizer
                        if (optimizationsData?.length > 0) {
                            const optimizationByBmodel = optimizationsData.find(
                                (el) => el.payment_method_id === PAYMENT_MODELS_IDS.UP_FRONT
                            ) as TOptimizations;

                            const optimizationData = optimizationByBmodel?.optimizations?.find(
                                (el) => el.id === OPTIMIZATION_PRODUCT_IDS.MIN_PAYBACK_RESTRICTIONS
                            ) as TOptimizationItem;

                            const optimizationInputs = {
                                tipo_produto_id: productID,
                                tipo_modelo_negocio_id: optimizationByBmodel?.bm_id,
                                payment_method_id: optimizationByBmodel?.payment_method_id,
                                limit_inj_prc: optimizationData?.limit_inj_prc,
                                sys_deg_an_prc: optimizationData?.sys_deg_an_prc,
                                orientation_range: optimizationData?.orientation_range,
                                tipo_optimizacao: optimizationData.id,
                                contracted_power: powerLimit,
                                power_limit: powerLimit,
                                risco_energia_id,
                                tarifa_equivalente_ac,
                            } as TOptimizationInputs;

                            solutionData = await fetchOptimizerOnHandler({
                                inputs,
                                optimizationInputs,
                                facilityID,
                                kits,
                                potencia_pico: Number(panelSelected?.potencia_pico),
                            });
                        } else {
                            if (!inputs?.is_kits) {
                                //get inverters_combination by optimizer
                                const invertersCombination = await fetchInvertersCombination({
                                    inputs,
                                });
                                //ser inputs inverters_combination
                                inputs.inverters_combination = invertersCombination as TInvertersCombData;
                            }

                            solutionData = await fetchSimulationOnHandler({
                                inputs,
                                facilityID,
                                productID: Number(productID),
                            });
                        }

                        solarpvDispatchHandler(SpvActions.SET_SIMPLE_SIMULATIONS, {
                            ...solutionData,
                        });
                    } catch (error: any) {
                        if (!TAGS_ERRORS.includes(error)) setInfoTagsHandler([{ message: '500' }]);
                        errorCatchOnHandler({ error, fnc: 'kpisDisplayOnHandler', tag: `STEP.KPIS_DISPLAY` });
                    }
                })();
            }),
        [
            fetchCoordinatesConversionFactor,
            flatRoofOnHandler,
            pitchedRoofOnHandler,
            fetchExclusionAreaADetectedOnHandler,
            fetchUsefulAreasOnHandler,
            fetchInvertersCombination,
            fetchOptimizerOnHandler,
            fetchSimulationOnHandler,
            setInfoTagsHandler,
            solarpvDispatchHandler,
            trainingDSHandler,
        ]
    );
    //#endregion step KPIS_DISPLAY

    const setSimpleModeStepOnHandler = useCallback(
        (options: { stepToGo: number }): void => {
            const { stepToGo } = options;
            switch (stepToGo) {
                case SIMPLE_MODE_STEPS.ROOF_IDENTIFICATION: {
                    roofIdentificationOnHandler({
                        isManualDrawing,
                        isB2C,
                        facilityCoordinates,
                        facilityID,
                        productID,
                        userLocale,
                        roofByDrawingManual,
                    });

                    break;
                }
                case SIMPLE_MODE_STEPS.KPIS_DISPLAY: {
                    if (roofType && roofTypeStructuresRef.current && structures && ranges && roof) {
                        kpisDisplayOnHandler({
                            isManualDrawing,
                            isB2C,
                            facilityCoordinates,
                            facilityID,
                            productID,
                            userLocale,
                            roofTypeStructures: roofTypeStructuresRef.current,
                            roofType: roofType as TDetectRoofTypeData,
                            structures: structures as TStructureItem[],
                            ranges: ranges as TRangeDataItem[],
                            roof,
                            sInputs,
                            optimizationsData: optimizationsData as TOptimizations[],
                            powerLimit,
                            pvOptions: {
                                prod_params: pvOptions?.prod_params as TProdParams,
                                injection_tariff_default: pvOptions?.injection_tariff_default as number,
                                default_network_sale: pvOptions?.default_network_sale as boolean,
                                default_panel_alignment_mode_id: pvOptions?.default_panel_alignment_mode_id as number,
                                limit_network_sale: pvOptions?.default_limit_injection ? pvOptions?.default_limit_value ?? 0 : false,
                            } as TPvOptions,
                            risco_energia_id,
                            tarifa_equivalente_ac: Number(tarifa_equivalente_ac),
                            kits,
                        });
                    }
                    break;
                }
                default:
                    break;
            }
        },
        [
            isManualDrawing,
            facilityID,
            productID,
            userLocale,
            facilityCoordinates,
            isB2C,
            roofIdentificationOnHandler,
            roofByDrawingManual,
            kpisDisplayOnHandler,
            powerLimit,
            optimizationsData,
            roofType,
            structures,
            ranges,
            roof,
            sInputs,
            pvOptions,
            risco_energia_id,
            tarifa_equivalente_ac,
            roofTypeStructuresRef,
            kits,
        ]
    );

    useLayoutEffect(() => {
        return () => {
            //cleanup
            resetCache();
            cleanup();
        };
    }, []); //eslint-disable-line

    return { setSimpleModeStepOnHandler, cleanupSPVSimple: cleanup, resetCacheSPVSimple: resetCache };
};
