import { css } from 'styled-components';

export const themeBase = {
    //media-breakpoint-down
    breakpoint: {
        lg: '992px',
        md: '768px',
        sm: '576px',
        xs: '575px',
    },

    //Question Elements Defaults
    // Question Elements Defaults
    maxWidthInput: '325px', // Max Width of Input
    topMarginQuestion: '0px', // Top Margin Question
    bottomMarginQuestion: '6%', // Bottom Margin Question
    topMarginText: '30px', // Top Margin Text
    bottomMarginText: '0px', // Bottom Margin Question
    topMarginLine: '2px', // Top Margin Line
    bottomMarginLine: '10px', // Bottom Margin Question

    // Sidebar
    sidebarWidth: '14.5rem',
    // Header and Footer Heights
    headerHeight: '70px',
    footerHeight: '52px',
    footerHeightLg: '65px',

    // Additional Colors
    gray300: 'var(--bs-gray-300)',
    gray500: '#8a92a5',
    danger: '#f44336',
    lightGray: '#888',
    bodyLightGray: '#f4f4f7',
    mediumGray: '#393939',
    mediumGray2: '#6c757d',
    mediumGray3: '#434244',
    grayDefault: '#808080',
    silverChalice: '#a3a3a3',
    silverLight: '#b1b1b1',
    bodyBg: '#fafafa',
    bodyColor: '#575757',
    black: '#000000',

    // Border Radius
    borderRadius: {
        sm: '0.125rem', //borderRadiusSm
        base: '0.375rem', //borderRadius
        lg: '0.5rem', //borderRadiusLg
        xl: '1rem',
        xxl: '1.5rem',
        circle: '50%', //borderRadiusCircle
    },

    // Shadows
    shadow: {
        sm: '0 0px 2px 0 rgba(0, 0, 0, 0.26)', //shadowSm
        md: '0 1px 4px 0 rgba(0, 0, 0, 0.25)', //shadow
        lg: '0 1px 8px 0px rgba(0, 0, 0, 0.2)', //
        xl: '0px 9px 14px 2px rgba(0, 0, 0, 0.07)', //shadowXl
    },

    fontFamilyBase: "'Open Sans', sans-serif",
    fontFamilyAccent: "'Poppins', sans-serif",

    // Font weights
    fontWeight: {
        light: 300,
        normal: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
        bolder: 800,
    },
    // Typography
    // Font sizes for headings
    fontSize: {
        base: '1rem', // 16px
        lg: '1.25rem', // 20px
        sm: '0.875rem', // 14px
        h1: '1.5rem', // 24px
        h2: '1.25rem', // 20px
        h3: '1.125rem', // 18px
        h4: '1rem', // 16px
        h5: '0.875rem', // 14px
        h6: '0.75rem', // 12px
    },
    // Font styles
    fontStyle: {
        normal: 'normal',
        italic: 'italic',
        oblique: 'oblique',
    },

    headingsMarginBottom: 'calc(1.5rem / 2)', // calc($h1-font-size / 2)
    headingsFontWeight: 400, // $font-weight-normal

    // Bootstrap
    btnPadding: '0.375rem 0.75rem',

    tableCellPaddingY: '0.75rem',
    tableCellPaddingX: '0.75rem',
    tableCellPaddingYSm: '0.25rem',
    tableCellPaddingXSm: '0.25rem',

    // Cards
    cardShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)',
    cardMargin: '1.875rem',
    cardPadding: '30px 150px',
    subHeadingColor: '#888',

    color: {
        // Status Colors
        error: '#d0433c',
        warning: '#d6c228',
        success: '#3ec09c',
        info: '#3885cd',

        // edp red
        edpRed50: '#fef3f4',
        edpRed100: '#feeff0',
        edpRed200: '#fcd0d5',
        edpRed300: '#f8a2ab',
        edpRed400: '#f57381',
        edpRed500: '#f14557',
        edpRed600: '#ee162d',
        edpRed700: '#d61429',
        edpRed800: '#be1224',
        edpRed900: '#a70f1f',

        // efz red
        efzRed50: '#fdf6f5',
        efzRed100: '#faecec',
        efzRed200: '#f6d9d8',
        efzRed300: '#f1c7c5',
        efzRed400: '#e8a19e',
        efzRed500: '#de7b77',
        efzRed600: '#d0433c',
        efzRed700: '#bb3c36',
        efzRed800: '#a63630',
        efzRed900: '#922f2a',

        // Info
        info50: '#f1f8ff',
        info100: '#d7e7f5',
        info200: '#c3daf0',
        info300: '#9cc2e6',
        info400: '#74aadc',
        info500: '#609dd7',
        info600: '#3885cd',
        info700: '#2d6aa4',
        info800: '#22507b',
        info900: '#163552',

        // Neutrals
        neutral50: '#fafafa',
        neutral100: '#f5f5f5',
        neutral200: '#e5e5e5',
        neutral300: '#dcdcdc',
        neutral400: '#c4c4c4',
        neutral500: '#b1b1b1',
        neutral550: '#a4a4a4',
        neutral600: '#a3a3a3',
        neutral700: '#484447',
        neutral800: '#202020',
        neutral900: '#000000',
        neutral950: '#0D0D0D',

        // Steel
        steel50: '#f4f5f5',
        steel100: '#eaeced',
        steel200: '#c6cdd2',
        steel300: '#a8b3bb',
        steel400: '#949ca2',
        steel500: '#79838b',
        steel600: '#5d656b',
        steel700: '#49555f',
        steel800: '#3c4d5a',
        steel900: '#2f4455',

        // Success
        success50: '#eefaf6',
        success100: '#d8f2eb',
        success200: '#c5ece1',
        success300: '#9fe0ce',
        success400: '#8bd9c4',
        success500: '#65cdb0',
        success600: '#3ec09c',
        success700: '#39ba96',
        success800: '#329a7d',
        success900: '#25735e',

        // Error
        error50: '#fdf6f5',
        error100: '#faecec',
        error200: '#f6d9d8',
        error300: '#f1c7c5',
        error400: '#e8a19e',
        error500: '#de7b77',
        error600: '#d0433c',
        error700: '#bb3c36',
        error800: '#a63630',
        error900: '#922f2a',

        // Warning
        warning50: '#fbf9ea',
        warning100: '#f7f3d4',
        warning200: '#efe7a9',
        warning300: '#ebe194',
        warning400: '#e6da7e',
        warning500: '#dece53',
        warning600: '#d6c228',
        warning700: '#c1af24',
        warning800: '#ab9b20',
        warning900: '#96881c',

        gray50: '#f8f9fa',
        gray100: '#f1f3f5',
        gray200: '#e9ecef',
        gray300: '#dee2e6',
        gray400: '#ced4da',
        gray500: '#adb5bd',
        gray600: '#6c757d',
        gray700: '#495057',
        gray800: '#343a40',
        gray900: '#212529',

        efzLemon50: '#fbf9ea',
        efzLemon100: '#f7f3d4',
        efzLemon200: '#efe7a9',
        efzLemon300: '#ebe194',
        efzLemon400: '#e6da7e',
        efzLemon500: '#dece53',
        efzLemon600: '#d6c228',
        efzLemon700: '#c1af24',
        efzLemon800: '#ab9b20',
        efzLemon900: '#96881c',

        //ChalkBoard
        efzChalkboard50: '#edeced',
        efzChalkboard100: '#dadada',
        efzChalkboard200: '#c8c7c8',
        efzChalkboard300: '#918f91',
        efzChalkboard400: '#6d696c',
        efzChalkboard500: '#5a5759',
        efzChalkboard600: '#484447',
        cefzChalkboard700: '#413d40',
        efzChalkboard800: '#323032',
        efzChalkboard900: '#242224',
    },
};

// @mixin
export const displayFlex = (flex = 'flex', flexDirection = 'row', flexWrap = 'wrap') => css`
    display: -webkit-${flex};
    display: -moz-${flex};
    display: -ms-${flex};
    display: -o-${flex};
    display: ${flex};
    -webkit-flex-direction: ${flexDirection};
    -ms-flex-direction: ${flexDirection};
    flex-direction: ${flexDirection};
    -webkit-flex-wrap: ${flexWrap};
    -ms-flex-wrap: ${flexWrap};
    flex-wrap: ${flexWrap};
`;

export const justifyContent = (justifyContent) => css`
    -webkit-justify-content: ${justifyContent};
    justify-content: ${justifyContent};
`;
