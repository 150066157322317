import { css, styled } from 'styled-components';
import RadioGroup from '@mui/material/RadioGroup';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const disabledCheckedCSS = css`
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    background-color: rgb(245, 245, 245) !important;
    color: rgba(0, 0, 0, 0.38) !important;
`;

const baseCSS = css`
    gap: 0.5rem 1rem;

    .MuiTypography-root {
        min-width: 9.5rem;
    }

    .MuiRadio-root {
        display: none !important;
    }

    .disabled-checked {
        ${disabledCheckedCSS};
    }

    .disabled-unchecked {
        ${disabledCheckedCSS};
        background-color: inherit !important;
    }

    .radio-button {
        &-checked {
            margin-left: 0;
            margin-right: 0;

            .MuiFormControlLabel-label,
            .radio-text,
            .MuiToggleButton-root {
                width: 100%;
                min-height: 40px;
                border: 1px solid var(--primary-color);
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: var(--primary-color-bleached);
                color: var(--primary-color);
                font-size: 0.875rem !important;
            }

            &.Mui-selected,
            &.MuiToggleButton-root {
                color: inherit !important;
                border: inherit !important;
                background-color: inherit !important;
                padding: 0 !important;
            }
        }

        &-unchecked {
            margin-left: 0;
            margin-right: 0;

            .MuiFormControlLabel-label,
            .radio-text {
                width: 100%;
                min-height: 40px;
                border: 1px solid #c4c4c4;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 0.875rem !important;
            }

            &.Mui-disabled,
            &.Mui-selected,
            &.MuiToggleButton-root {
                color: inherit !important;
                border: inherit !important;
                background-color: inherit !important;
                padding: 0 !important;
            }
        }
    }

    // add new classes for specific cases
    &.method-selection,
    &.option-selection {
        .radio-button-checked,
        .radio-button-unchecked {
            .MuiFormControlLabel-label,
            .radio-text {
                min-width: 260px !important;
                text-transform: initial;

                span {
                    padding: 0 1rem;
                }
            }
        }
    }

    &.align-left {
        .MuiFormControlLabel-label {
            justify-content: left;
            padding-left: 1.5rem;
        }
    }

    .label-with-info {
        position: relative;

        &-text {
            position: absolute;
            bottom: -10px;
            font-size: 11px;
            left: 6px;
            color: var(--primary-color);
        }
    }
`;
export const StyledRadioLabel = styled(RadioGroup)`
    ${baseCSS};
`;

export const StyledToggleRadioLabel = styled(ToggleButtonGroup)`
    ${baseCSS};
`;
