import { getMessageNetworkError } from 'services/statusCodeHttp';
import { SentryCaptureException } from 'services/@efz/sentry';
import { isDefined } from 'services/util/auxiliaryUtils';
import { getEndpoint, URL_DS, URL_PRODUCTS } from 'constants/endpoints';
import axios from 'services/@efz/axios';
import { PRODUCT_IDS } from 'constants/products';

// STEP 1. Property
export const getHPPropertyTypes = (product, abort) => {
    //#region sentry warn
    if (!isDefined(product)) {
        SentryCaptureException('getHPPropertyTypes');
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + '/property-type-options'}`,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getHPPropertyAges = (product, abort) => {
    //#region sentry warn
    if (!isDefined(product)) {
        SentryCaptureException('getHPPropertyAges');
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + '/property-age-options'}`,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getHPPropertyStatus = (product, abort) => {
    //#region sentry warn
    if (!isDefined(product)) {
        SentryCaptureException('getHPPropertyStatus');
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + '/property-state-options'}`,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getHPPropertyHeatingSources = (product, abort) => {
    //#region sentry warn
    if (!isDefined(product)) {
        SentryCaptureException('getHPPropertyHeatingSources');
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + '/heat-source-options'}`,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getHPPropertyOdtHeightFactors = (product, abort) => {
    //#region sentry warn
    if (!isDefined(product)) {
        SentryCaptureException('getHPPropertyOdtHeightFactors');
        return null;
    }
    //#endregion
    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + '/odt-height-factors'}`,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getHPPropertyRegionMetadata = (product, abort, params: { fid?: number } = {}) => {
    //#region sentry warn
    if (!isDefined(product)) {
        SentryCaptureException('getHPPropertyRegionMetadata');
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + '/geo-region-metadata'}`,
            params,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

// STEP 2. Heat Loss
export const getHPDivisionTypes = (product, abort) => {
    //#region sentry warn
    if (!isDefined(product)) {
        SentryCaptureException('getHPDivisionTypes');
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + '/division-type-options'}`,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getHPGroundTypes = (product, abort) => {
    //#region sentry warn
    if (!isDefined(product)) {
        SentryCaptureException('getHPGroundTypes');
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + '/ground-type-options'}`,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getHPExposureOptions = (product, abort) => {
    //#region sentry warn
    if (!isDefined(product)) {
        SentryCaptureException('getHPExposureOptions');
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + '/ground-exposure-options'}`,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getHPExposureValues = (product, abort) => {
    //#region sentry warn
    if (!isDefined(product)) {
        SentryCaptureException('getHPExposureValues');
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + '/ground-exposure-values'}`,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getHPGroundInsulationOptions = (product, abort) => {
    //#region sentry warn
    if (!isDefined(product)) {
        SentryCaptureException('getHPGroundInsulationOptions');
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + '/ground-insulation-options'}`,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

// STEP 3. Selection
export const getHPSelectionTransferFluid = (product, abort) => {
    //#region sentry warn
    if (!isDefined(product)) {
        SentryCaptureException('getHPSelectionTransferFluid');
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + '/heat-transmission-fluid-options'}`,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getHPDistanceReduction = (product, abort) => {
    //#region sentry warn
    if (!isDefined(product)) {
        SentryCaptureException('getHPDistanceReduction');
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + '/sound-check-distance-reduction-options'}`,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getHPSoundPressure = (product, abort) => {
    //#region sentry warn
    if (!isDefined(product)) {
        SentryCaptureException('getHPSoundPressure');
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + '/sound-check-sound-pressure-options'}`,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getHPDecibelCorrection = (product, abort) => {
    //#region sentry warn
    if (!isDefined(product)) {
        SentryCaptureException('getHPDecibelCorrection');
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + '/sound-check-db-correction-options'}`,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getAssociatedBrandsHPs = (abortController: AbortController) => {
    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS) + '/' + PRODUCT_IDS.HP + '/associated-brand'}`,
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

// Step 4. EXTRAS
export const getHPCatModel = (product, abort) => {
    //#region sentry warn
    if (!isDefined(product)) {
        SentryCaptureException('getHPCatModel');
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + `/extra-equipment-cat-model`}`,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

// Step 5. EMITTER SIZING
export const getHPEmitterRadiatorTypes = (product, abort) => {
    //#region sentry warn
    if (!isDefined(product)) {
        SentryCaptureException('getHPEmitterRadiatorTypes');
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + `/radiator-types`}`,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getHPEmitterFanCoilTypes = (product, abort) => {
    //#region sentry warn
    if (!isDefined(product)) {
        SentryCaptureException('getHPEmitterFanCoilTypes');
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + `/fancoil-types`}`,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getHPEmitterUFHFloorCoverageTypes = (product, abort) => {
    //#region sentry warn
    if (!isDefined(product)) {
        SentryCaptureException('getHPEmitterUFHFloorCoverageTypes');
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + `/ufh-floor-coverage-types`}`,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getHPEmitterUFHPipeCentres = (product, abort) => {
    //#region sentry warn
    if (!isDefined(product)) {
        SentryCaptureException('getHPEmitterUFHPipeCentres');
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + `/ufh-pipe-centres`}`,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getHPEmitterUFHPipeSizes = (product, abort) => {
    //#region sentry warn
    if (!isDefined(product)) {
        SentryCaptureException('getHPEmitterUFHPipeSizes');
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + `/ufh-pipe-sizes`}`,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getHPEmitterUFHSurfaceMaxTemps = (product, abort) => {
    //#region sentry warn
    if (!isDefined(product)) {
        SentryCaptureException('getHPEmitterUFHSurfaceMaxTemps');
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + `/ufh-surface-max-temps`}`,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const postEmitterOptions = (product, payload, abort) => {
    //#region sentry warn
    if (!isDefined(product)) {
        SentryCaptureException('postEmitterOptions');
        return null;
    }
    //#endregion

    // FIXME: This request config may be wrong
    return axios
        .request({
            method: 'post',
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + `/emitter-options`}`,
            data: payload,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const postInnerWalls = (payload, abortController) => {
    //#region sentry warn
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }
    //#endregion

    return axios
        .request({
            method: 'POST',
            url: `${getEndpoint(URL_DS)}/inner-walls`,
            data: payload,
            signal: abortController?.signal,
            // @ts-ignore
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getElementOptions = (product, abortController) => {
    //#region sentry warn
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }
    //#endregion

    return axios
        .request({
            method: 'GET',
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + `/element-options`}`,
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const postGetElementTypeOptions = (product, payload, abortController) => {
    //#region sentry warn
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }
    //#endregion

    return axios
        .request({
            method: 'POST',
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + `/element-type-options`}`,
            data: payload ?? {},
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getInstallationPrices = (product, abortController) => {
    //#region sentry warn
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }
    //#endregion

    return axios
        .request({
            method: 'GET',
            url: `${getEndpoint(URL_PRODUCTS) + '/' + product + `/installation-prices`}`,
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};
