import MuiTooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
// import { IconButton } from '@save2compete/efz-design-system';
import { themeBase } from 'constants/styles/global';
import { css, styled } from 'styled-components';

const iconColor = (type: 'default' | 'warning' | 'error' | 'info' | 'success' | undefined) => {
    switch (type) {
        case 'info':
            return themeBase.color.info600;
        case 'warning':
            return themeBase.color.efzLemon600;
        case 'error':
            return themeBase.color.edpRed500;
        case 'success':
            return themeBase.color.success600;
        default:
            return themeBase.color.neutral400;
    }
};
const tooltipIconCSS = css`
    padding: 0 !important;
    cursor: help !important;
    pointer-events: auto;
    margin-top: -0.3125rem;

    svg {
        height: 1.1875rem;
        width: 1.1875rem;
        margin: 5px;

        path {
            fill: $steel-200;
        }
    }
`;
const tooltipArrowColors = (type: 'default' | 'warning' | 'error' | 'info' | 'success' | undefined) => {
    let arrowBorderColor = `${themeBase.color.neutral400}`;
    let arrowColor = '#ffffff';

    switch (type) {
        case 'default':
            arrowBorderColor = `${themeBase.color.neutral400}`;
            arrowColor = '#ffffff';
            break;
        case 'info':
            arrowBorderColor = `${themeBase.color.info600}`;
            arrowColor = `${themeBase.color.info50}`;
            break;
        case 'warning':
            arrowBorderColor = `${themeBase.color.efzLemon600}`;
            arrowColor = `${themeBase.color.efzLemon50}`;
            break;
        case 'error':
            arrowBorderColor = `${themeBase.color.edpRed500}`;
            arrowColor = `${themeBase.color.edpRed50}`;
            break;
        case 'success':
            arrowBorderColor = `${themeBase.color.success600}`;
            arrowColor = `${themeBase.color.success50}`;
            break;
        default:
            arrowBorderColor = `${themeBase.color.neutral400}`;
            arrowColor = '#ffffff';
            break;
    }
    return { arrowBorderColor, arrowColor };
};
export const arrowTooltip = (type: 'default' | 'warning' | 'error' | 'info' | 'success' | undefined) => ({
    [`.efz-tooltip-arrow`]: {
        '&-bottom-end, &-bottom-start, &-top-end, &-top-start': {
            '.MuiTooltip-arrow': {
                borderTop: '0.75rem solid transparent !important',
                borderBottom: '0.75rem solid transparent !important',
                overflow: 'visible !important',

                '&::before': {
                    border: 'inherit !important',
                    backgroundColor: 'inherit !important',
                    borderTop: '0.75rem solid transparent !important',
                    borderBottom: '0.75rem solid transparent !important',
                    transform: 'translate(-50%, -50%) !important',
                },
            },
        },

        '&-bottom-end, &-bottom-start': {
            '.MuiTooltip-arrow': {
                marginBottom: '-0.813rem !important',
                bottom: '0 !important',
                top: 'auto !important',

                '&::before': {
                    marginTop: '-0.125rem !important',
                },
            },
        },

        '&-bottom-end, &-top-end': {
            '.MuiTooltip-arrow': {
                borderLeft: '0.75rem solid transparent !important',
                borderRight: `0.75rem solid ${tooltipArrowColors(type).arrowBorderColor} !important`,
                transform: 'translate(1px, 0px) !important',

                '&::before': {
                    borderLeft: '0.75rem solid transparent !important',
                    borderRight: `0.75rem solid ${tooltipArrowColors(type).arrowColor} !important`,
                    marginLeft: '-0.063rem !important',
                },
            },
        },

        '&-bottom-start,  &-top-start': {
            '.MuiTooltip-arrow': {
                borderLeft: `0.75rem solid ${tooltipArrowColors(type).arrowBorderColor} !important`,
                borderRight: '0.75rem solid transparent !important',
                transform: 'translate(-1px, 0px) !important',

                '&::before': {
                    borderLeft: `0.75rem solid ${tooltipArrowColors(type).arrowColor} !important`,
                    borderRight: '0.75rem solid transparent !important',
                    marginLeft: '0.063rem !important',
                },
            },
        },

        '&-top-end,  &-top-start': {
            '.MuiTooltip-arrow': {
                marginTop: '-0.813rem !important',

                '&::before': {
                    marginTop: '0.125rem !important',
                },
            },
        },

        '&-left-end, &-left-start, &-right-start, &-right-end': {
            '.MuiTooltip-arrow': {
                borderLeft: '0.75rem solid transparent !important',
                borderRight: '0.75rem solid transparent !important',
                overflow: 'visible !important',

                '&::before': {
                    border: 'inherit !important',
                    backgroundColor: 'inherit !important',
                    borderLeft: '0.75rem solid transparent !important',
                    borderRight: '0.75rem solid transparent !important',
                    transform: 'translate(-50%, -50%) !important',
                },
            },
        },

        '&-left-end, &-left-start': {
            '.MuiTooltip-arrow::before': {
                marginLeft: '0.125rem !important',
            },
        },

        '&-right-start, &-right-end': {
            '.MuiTooltip-arrow::before': {
                marginLeft: '-0.125rem !important',
            },
        },

        '&-left-end, &-right-end': {
            '.MuiTooltip-arrow': {
                borderTop: '0.75rem solid transparent !important',
                borderBottom: `0.75rem solid ${tooltipArrowColors(type).arrowBorderColor} !important`,
                transform: 'translate(1px, 0px) !important',
                marginBottom: '-0.063rem !important',

                '&::before': {
                    borderTop: '0.75rem solid transparent !important',
                    borderBottom: `0.75rem solid ${tooltipArrowColors(type).arrowColor} !important`,
                    marginTop: '-0.063rem !important',
                },
            },
        },

        '&-left-end': {
            '.MuiTooltip-arrow': {
                marginLeft: '-0.875rem !important',
            },
        },

        '&-right-end': {
            '.MuiTooltip-arrow': {
                marginRight: '-0.75rem !important',
            },
        },

        '&-left-start, &-right-start': {
            '.MuiTooltip-arrow': {
                borderTop: `0.75rem solid ${tooltipArrowColors(type).arrowBorderColor} !important`,
                borderBottom: '0.75rem solid transparent !important',
                transform: 'translate(-1px, 0px) !important',
                marginTop: '-0.063rem !important',

                '&::before': {
                    borderTop: `0.75rem solid ${tooltipArrowColors(type).arrowColor} !important`,
                    borderBottom: '0.75rem solid transparent !important',
                    marginTop: '0.063rem !important',
                },
            },
        },

        '&-left-start': {
            '.MuiTooltip-arrow': {
                marginLeft: '-0.688rem !important',
            },
        },

        '&-right-start': {
            '.MuiTooltip-arrow': {
                marginRight: '-0.875rem !important',
            },
        },

        '&-bottom-end, &-top-end, &-right-end, &-right-start': {
            '.MuiTooltip-arrow': {
                right: '0px !important',
                left: 'auto !important',
            },
        },

        '&-left-start, &-left-end, &-top-start, &-bottom-start': {
            '.MuiTooltip-arrow': {
                right: 'auto !important',
                left: '0px !important',
            },
        },

        '&-top-start, &-bottom-start': {
            '.MuiTooltip-arrow': {
                marginLeft: '0px !important',
            },
        },

        '&-bottom-end, &-bottom-start, &-right-end, &-left-end': {
            '.MuiTooltip-arrow': {
                bottom: '0px !important',
                top: 'auto !important',
            },
        },

        '&-left-start, &-right-start, &-top-start, &-top-end': {
            '.MuiTooltip-arrow': {
                bottom: 'auto !important',
                top: '0px !important',
            },
        },
    },
});
export const StyledTooltip = styled(MuiTooltip).attrs<{ $type?: 'default' | 'warning' | 'error' | 'info' | 'success' }>((props) => ({
    $type: props?.$type ?? 'info',
}))`
    div.MuiTooltip-tooltip {
        padding: 0 !important;
    }

    .efz-tooltip-content {
        font-size: 0.75rem;
        color: ${themeBase.color.neutral800};
        padding: 0.75rem 1rem;
        text-align: center;

        p,
        ul {
            margin-top: 0;
            margin-bottom: 0;
        }

        .link {
            color: ${themeBase.color.info600};
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .efz-tooltip-default {
        div.MuiTooltip-tooltip {
            background-color: #ffffff !important;
            border: 1px solid ${themeBase.color.neutral400};
        }

        .MuiTooltip-arrow::before {
            background-color: #ffffff !important;
            border: 1px solid ${themeBase.color.neutral400};
        }
    }

    .efz-tooltip-info {
        div.MuiTooltip-tooltip {
            background-color: ${themeBase.color.info50} !important;
            border: 1px solid ${themeBase.color.info600};
        }

        .MuiTooltip-arrow::before {
            background-color: ${themeBase.color.info50} !important;
            border: 1px solid ${themeBase.color.info600};
        }
    }

    .efz-tooltip-warning {
        div.MuiTooltip-tooltip {
            background-color: ${themeBase.color.efzLemon50} !important;
            border: 1px solid ${themeBase.color.efzLemon600};
        }

        .MuiTooltip-arrow::before {
            background-color: ${themeBase.color.efzLemon50} !important;
            border: 1px solid ${themeBase.color.efzLemon600};
        }
    }

    .efz-tooltip-error {
        div.MuiTooltip-tooltip {
            background-color: ${themeBase.color.edpRed50} !important;
            border: 1px solid ${themeBase.color.edpRed500};
        }

        .MuiTooltip-arrow::before {
            background-color: ${themeBase.color.edpRed50} !important;
            border: 1px solid ${themeBase.color.edpRed500};
        }
    }

    .efz-tooltip-success {
        div.MuiTooltip-tooltip {
            background-color: ${themeBase.color.success50} !important;
            border: 1px solid ${themeBase.color.success600};
        }

        .MuiTooltip-arrow::before {
            background-color: ${themeBase.color.success50} !important;
            border: 1px solid ${themeBase.color.success600};
        }
    }
`;

export const StyledTooltipIcon = styled(IconButton).attrs<{ $type?: 'default' | 'warning' | 'error' | 'info' | 'success' }>((props) => ({
    $type: props?.$type ?? 'info',
}))`
    ${tooltipIconCSS};
    &.hovered {
        svg {
            path {
                fill: ${(props) => iconColor(props.$type)};
            }
        }
    }

    &:hover {
        svg {
            path {
                fill: ${(props) => iconColor(props.$type)};
            }
        }
    }
`;
