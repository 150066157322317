import { lazy } from 'react';
//selectors
import lazyLoader from 'services/lazyLoader';
import { withTracker } from 'services/withTracker';

const BusinessModelsProContainer = lazy(() => lazyLoader(() => import('pages/Restricted/BusinessModelsPro')));

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */

const ReformulationBusinessModels = () => {
    return <BusinessModelsProContainer />;
};
export default withTracker(ReformulationBusinessModels, 'Business Models');
