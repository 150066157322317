// Components
import { InfoOPCSharing } from 'components/Products/EVCPro/Sections/InstallationDetails/OnCompany/Public/Partnership';

// Constants
import { getBaseURL, getStaticFiles } from 'constants/endpoints';
import { USERS_TYPE_ID } from 'constants/user';

// Icons
import { ReactComponent as DownloadIcon } from 'assets/images/facility/download.svg';
import { ReactComponent as ParkingIcon } from 'assets/images/icons/svg/parking.svg';
import { ReactComponent as ElectricCarIcon } from 'assets/images/icons/svg/electric-car.svg';
import { ReactComponent as ClockIcon } from 'assets/images/icons/svg/wall-clock.svg';
import { ReactComponent as HotelIcon } from 'assets/images/icons/svg/hotel.svg';
import { ReactComponent as RestaurantIcon } from 'assets/images/icons/svg/restaurant.svg';

// Interfaces
import {
    SectionQuestions,
    TFormQuestions,
    TFormQuestionsSettings,
    TEVCProInputs,
    TEVCProLoadings,
    TEVCProKpisConfig,
} from 'interfaces/products/evcPro';

// Services
import validate from 'services/util/validate';
import { getCompanyProfileIds } from 'services/user';

export const EVCProFormID = 'efz-EVCPro-form';

export enum EVCProFlows {
    on_company_private = 'on_company_private',
    on_company_public = 'on_company_public',
    on_company_public_other = 'on_company_public_other',
    on_company_public_hotel = 'on_company_public_hotel',
    on_company_public_hotel_no_partnership = 'on_company_public_hotel_no_partnership',
    on_company_public_restaurant = 'on_company_public_restaurant',
    on_company_public_restaurant_no_partnership = 'on_company_public_restaurant_no_partnership',
    on_company_public_no_partnership = 'on_company_public_no_partnership',
    on_other = 'on_other',
    not_selected = 'not_selected',
}

//#region Options
export enum CHARGING_LOCATION_OPTIONS {
    PRIVATE = 0,
    PUBLIC = 1,
}

export enum INSTALLATION_LOCATION_OPTIONS {
    COMPANY = 1,
    OTHER = 2,
}

export enum ENTRANCE_TYPE_OPTIONS {
    PRIVATE = 2,
    PUBLIC = 1,
}

export enum MANAGEMENT_OPTIONS {
    NO_MANAGEMENT = 99,
    EDP_CHARGE = 1,
    DPC = 2,
}

export enum PLACE_OF_INSTALLATION_OPTIONS {
    INTERIOR = 1,
    EXTERIOR = 2,
}

export enum APPLIANCE_TYPE_OPTIONS {
    GROUND = 1,
    WALL = 2,
}

export enum FACILITY_TYPE_IDS {
    HOTEL = 1,
    RESTAURANT = 2,
    OTHERESTABLISHMENTS = 3,
}

export enum NUMBER_OF_ROOMS_RADIO_OPTIONS {
    TWENTY_TO_HUNDRED = 1,
    MORE_THAN_HUNDRED = 2,
}

export enum SHARING_MODEL_ID_OPTIONS {
    FIXED_SHARE = 1,
    VARIABLE_SHARE = 2,
}

export enum SIZING_ERRORS {
    REMOVE_ONE_OR_MORE_CHARGERS = 'REMOVE_ONE_OR_MORE_CHARGERS',
}

//#endregion Options

//#region State Constants

export enum KPIS { //TODO: DUMMY
    PRICE = 'preco',
    PRICE_MONTHLY_FEE = 'valor_mensalidade_num',
    NR_MONTHLY_FEES = 'numero_mensalidades_num',
    TOTAL_PLATFORM_PRICE_MONTH = 'total_platform_price_month',
    TOTAL_UPFRONT_PRICE = 'total_upfront_price',
    TOTAL_UPFRONT_PRICE_PARTNERSHIP = 'total_upfront_price_partnership',
    PARTNERSHIP_PRICE = 'partnership_price',
    ERROR = 'ERROR',
}

export const KPIS_CONFIG: TEVCProKpisConfig = {
    [KPIS.PRICE]: {
        visible: true,
        unit: 'currency', //UNITS_AVAILABLE.CURRENCY, não pode ser a constante porque no ficheiro JS não tem 'as const'
        label: 'label.price',
    },
    [KPIS.PARTNERSHIP_PRICE]: {
        visible: true,
        label: 'page.evc.resume.partnership',
    },
    [KPIS.TOTAL_UPFRONT_PRICE]: {
        visible: true,
        unit: 'currency',
        label: 'page.evc.resume.total_upfront_price',
    },
    [KPIS.TOTAL_UPFRONT_PRICE_PARTNERSHIP]: {
        visible: false,
        unit: 'currency',
        label: 'page.evc.resume.total_upfront_price',
    },
    [KPIS.PRICE_MONTHLY_FEE]: {
        visible: true,
        unit: 'mc',
        label: 'label.monthlyPaymentsFee',
    },
    [KPIS.NR_MONTHLY_FEES]: {
        visible: true,
        unit: 'symbol',
        label: 'label.monthlyPaymentsNumber',
    },
    [KPIS.TOTAL_PLATFORM_PRICE_MONTH]: {
        visible: true,
        unit: 'mc',
        label: 'page.evc.resume.management_platform',
    },
    [KPIS.ERROR]: {
        visible: false,
        label: 'label.error',
    },
} as const;

export enum LOADINGS {
    GENERAL = 'general',
    GETTING_CHARGERS = 'getting_chargers',
    UPLOADING_FILE = 'uploading_file',
    DOWNLOADING_FILE = 'downloading_file',
    GETTINGS_KPIS = 'getting_kpis',
}

export const DEFAULT_LOADINGS: TEVCProLoadings = {
    [LOADINGS.GENERAL]: false,
    [LOADINGS.GETTING_CHARGERS]: false,
    [LOADINGS.UPLOADING_FILE]: false,
    [LOADINGS.DOWNLOADING_FILE]: false,
    [LOADINGS.GETTINGS_KPIS]: false,
};

//#endregion State Constants

//#region Inputs

export enum SECTION_NAMES {
    INSTALLATION_DETAILS = 'INSTALLATION_DETAILS',
    MANAGEMENT = 'MANAGEMENT',
    CHARGERS = 'CHARGERS',
    CHARGER_SETTINGS_ELECTRICAL_PANEL = 'electrical_panel',
    CHARGER_SETTINGS_INSTALLATION_DETAILS = 'installation_details',
    CHARGER_SETTINGS_EXTRAS = 'extras',
}

export const INPUT_NAMES_INSTALLATION_DETAILS = {
    charging_location_option_id: 'charging_location_option_id',
    installation_location_option_id: 'installation_location_option_id',
    entrance_type_id: 'entrance_type_id',
    facility_type_id: 'facility_type_id',
    public_access_private_parking: 'is_private_domain',
    has_min_parking_spaces: 'has_min_parking_spaces',
    has_min_open_schedule: 'has_min_open_schedule',
    has_min_seated_positions: 'has_min_seated_positions',
    number_of_rooms_radio: 'number_of_rooms_radio',
    number_of_rooms_between_20_and_100: 'number_of_rooms_between_20_and_100',
    number_of_rooms_greater_than_20: 'number_of_rooms_greater_than_20',
    number_of_rooms_greater_than_100: 'number_of_rooms_greater_than_100',
    has_partnership_requirements: 'has_partnership_requirements',
    wants_partnership: 'wants_partnership',
    sharing_model_id: 'sharing_model_id',
    ready_to_continue: 'ready_to_continue',
} as const;

export const INPUT_NAMES_MANAGEMENT = {
    management_option_id: 'management_option_id',
    has_plafond: 'has_plafond',
    plafond_smart: 'plafond_smart',
    plafond_premium: 'plafond_premium',
} as const;

export const INPUT_NAMES_CHARGERS = {
    install_charger_points: 'install_charger_points',
    place_of_installation_id: 'place_of_installation_id',
    appliance_type_id: 'appliance_type_id',
    selected_chargers: 'chargers_selected',
    charger_points_number: 'charger_points_number',
    cpe_details_file: 'cpe_details_file',
} as const;

// export enum CHARGER_SETTINGS_SECTIONS {
//     ELECTRICAL_PANEL = 'electrical_panel',
//     INSTALLATION_DETAILS = 'installation_details',
//     EXTRAS = 'extras',
// }

export const CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL = {
    has_electrical_panel: 'has_qve',
    qve_corrugated_pipe: 'has_corrugated_pipe',
    qve_vd_tube: 'has_vd_tube',
    qve_cable_conveyor: 'has_cable_conveyor',
    qve_cable_conveyor_distance: 'cable_conveyor_distance',
    // is_solid: 'is_solid',
    // has_corrugated_pipe: 'has_corrugated_pipe',
    // has_vd_pipe: 'has_vd_pipe',
    // has_cable_trays: 'has_cable_trays',
    // cable_trays_length: 'cable_trays_length',
    // has_trench: 'has_trench',
    // trench_type: 'trench_type',
    // trench_distance: 'trench_distance',
} as const;

export const CHARGER_SETTINGS_INPUT_NAMES_INSTALLATION_DETAILS = {
    //Need to check with API the names
    charging_type: 'is_quick',
    electric_panel_to_charging_station_distance: 'distance_to_pole_qe_to_charger',
    electric_vehicle_panel_to_charging_station_distance: 'distance_to_pole_qve_to_charger',
} as const;

export const CHARGER_SETTINGS_INPUT_NAMES_EXTRAS = {
    vd_tube: 'has_vd_tube_extra',
    cable_conveyor: 'has_cable_conveyor_extra',
    cable_conveyor_distance: 'cable_conveyor_distance_extra',
    qve_massive: 'has_massive',
    pedestal_massive: 'has_pedestal_massive',
    qve_trench: 'has_trench',
    qve_trench_type: 'trench_type',
    qve_trench_distance: 'trench_distance',
    cutting_panel: 'has_cutting_panel',
    mechanic_protection: 'has_mechanic_protection',
    vertical_signs: 'has_vertical_signs',
    horizontal_signs: 'has_horizontal_signs',
    // has_vd_pipe: 'has_vd_pipe',
    // has_cable_trays: 'has_cable_trays',
    // cable_trays_length: 'cable_trays_length',
    // is_solid_with_stand: 'is_solid_with_stand',
    // has_concrete_mixer: 'has_concrete_mixer',
    // has_mechanical_protection: 'has_mechanical_protection',
    // has_vertical_signs: 'has_vertical_signs',
    // has_horizontal_signs: 'has_horizontal_signs',
} as const;

export const INPUT_NAMES = {
    [SECTION_NAMES.INSTALLATION_DETAILS]: {
        ...INPUT_NAMES_INSTALLATION_DETAILS,
    },
    [SECTION_NAMES.MANAGEMENT]: {
        ...INPUT_NAMES_MANAGEMENT,
    },
    [SECTION_NAMES.CHARGERS]: {
        ...INPUT_NAMES_CHARGERS,
    },
    [SECTION_NAMES.CHARGER_SETTINGS_ELECTRICAL_PANEL]: {
        ...CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL,
    },
    [SECTION_NAMES.CHARGER_SETTINGS_INSTALLATION_DETAILS]: {
        ...CHARGER_SETTINGS_INPUT_NAMES_INSTALLATION_DETAILS,
    },
    [SECTION_NAMES.CHARGER_SETTINGS_EXTRAS]: {
        ...CHARGER_SETTINGS_INPUT_NAMES_EXTRAS,
    },
} as const;

export const sectionNamesMap = {
    [SECTION_NAMES.INSTALLATION_DETAILS]: INPUT_NAMES_INSTALLATION_DETAILS,
    [SECTION_NAMES.MANAGEMENT]: INPUT_NAMES_MANAGEMENT,
    [SECTION_NAMES.CHARGERS]: INPUT_NAMES_CHARGERS,
    [SECTION_NAMES.CHARGER_SETTINGS_ELECTRICAL_PANEL]: CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL,
    [SECTION_NAMES.CHARGER_SETTINGS_INSTALLATION_DETAILS]: CHARGER_SETTINGS_INPUT_NAMES_INSTALLATION_DETAILS,
    [SECTION_NAMES.CHARGER_SETTINGS_EXTRAS]: CHARGER_SETTINGS_INPUT_NAMES_EXTRAS,
};

//#endregion Inputs

//#region Questions

export const QUESTIONS_INSTALLATION_DETAILS: SectionQuestions<SECTION_NAMES.INSTALLATION_DETAILS> = {
    [INPUT_NAMES_INSTALLATION_DETAILS.charging_location_option_id]: {
        name: INPUT_NAMES_INSTALLATION_DETAILS.charging_location_option_id,
        label: '',
        placeholder: '',
        disabled: false,
        visible: false,
        type: 'number',
        validation: {},
        valueType: 'number',
    },
    [INPUT_NAMES_INSTALLATION_DETAILS.installation_location_option_id]: {
        name: INPUT_NAMES_INSTALLATION_DETAILS.installation_location_option_id,
        label: 'page.evc.selectFacility',
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'radio-label',
        validation: validate(['notDefaultNull']),
        options: {
            labelYes: 'page.evc.selectFacility.label.yes',
            labelNo: 'page.evc.selectFacility.label.no',
            valueYes: INSTALLATION_LOCATION_OPTIONS.COMPANY,
            valueNo: INSTALLATION_LOCATION_OPTIONS.OTHER,
        },
        valueType: 'number',
    },
    [INPUT_NAMES_INSTALLATION_DETAILS.entrance_type_id]: {
        name: INPUT_NAMES_INSTALLATION_DETAILS.entrance_type_id,
        label: 'page.evc.label.entranceType',
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'radio-image',
        validation: validate(['notDefaultNull']),
        options: [
            {
                label: 'page.evc.privateAccess.title',
                description: 'page.evc.privateAccess.description',
                value: ENTRANCE_TYPE_OPTIONS.PRIVATE,
                imageSrc: `${getStaticFiles('private-access.jpg', 'ME/')}`,
            },
            {
                label: 'page.evc.publicAccess.title',
                description: 'page.evc.publicAccess.description',
                value: ENTRANCE_TYPE_OPTIONS.PUBLIC,
                imageSrc: `${getStaticFiles('public-access.jpg', 'ME/')}`,
            },
        ],
        valueType: 'number',
    },
    [INPUT_NAMES_INSTALLATION_DETAILS.facility_type_id]: {
        name: INPUT_NAMES_INSTALLATION_DETAILS.facility_type_id,
        label: 'page.evc.question.facility_type_id',
        placeholder: 'page.evc.label.select',
        disabled: false,
        visible: [
            EVCProFlows.on_company_public,
            EVCProFlows.on_company_public_other,
            EVCProFlows.on_company_public_hotel,
            EVCProFlows.on_company_public_restaurant,
            EVCProFlows.on_company_public_no_partnership,
            EVCProFlows.on_company_public_hotel_no_partnership,
            EVCProFlows.on_company_public_restaurant_no_partnership,
        ],
        type: 'select',
        validation: validate(['notDefaultNull']),
        options: [
            {
                label: 'hotel',
                value: FACILITY_TYPE_IDS.HOTEL,
            },
            {
                label: 'restaurant',
                value: FACILITY_TYPE_IDS.RESTAURANT,
            },
            {
                label: 'otherEstablishments',
                value: FACILITY_TYPE_IDS.OTHERESTABLISHMENTS,
            },
        ],
        valueType: 'number',
    },
    [INPUT_NAMES_INSTALLATION_DETAILS.public_access_private_parking]: {
        name: INPUT_NAMES_INSTALLATION_DETAILS.public_access_private_parking,
        label: 'page.evc.question.info.characteristicsEstablishment',
        placeholder: '',
        disabled: false,
        visible: [
            EVCProFlows.on_company_public_other,
            EVCProFlows.on_company_public_hotel,
            EVCProFlows.on_company_public_restaurant,
            EVCProFlows.on_company_public_no_partnership,
            EVCProFlows.on_company_public_hotel_no_partnership,
            EVCProFlows.on_company_public_restaurant_no_partnership,
        ],
        type: 'checkbox',
        validation: {},
        options: [
            {
                label: {
                    label: 'page.evc.question.is_private_domain',
                    icon: ParkingIcon,
                },
            },
        ],
        valueType: 'boolean',
    },
    [INPUT_NAMES_INSTALLATION_DETAILS.has_min_parking_spaces]: {
        name: INPUT_NAMES_INSTALLATION_DETAILS.has_min_parking_spaces,
        label: '',
        placeholder: '',
        disabled: false,
        visible: [
            EVCProFlows.on_company_public_other,
            EVCProFlows.on_company_public_hotel,
            EVCProFlows.on_company_public_restaurant,
            EVCProFlows.on_company_public_no_partnership,
            EVCProFlows.on_company_public_hotel_no_partnership,
            EVCProFlows.on_company_public_restaurant_no_partnership,
        ],
        type: 'checkbox',
        validation: {},
        options: [
            {
                label: {
                    label: 'page.evc.question.has_min_parking_spaces',
                    icon: ElectricCarIcon,
                },
            },
        ],
        valueType: 'boolean',
    },
    [INPUT_NAMES_INSTALLATION_DETAILS.has_min_open_schedule]: {
        name: INPUT_NAMES_INSTALLATION_DETAILS.has_min_open_schedule,
        label: '',
        placeholder: '',
        disabled: false,
        visible: [
            EVCProFlows.on_company_public_other,
            EVCProFlows.on_company_public_hotel,
            EVCProFlows.on_company_public_restaurant,
            EVCProFlows.on_company_public_no_partnership,
            EVCProFlows.on_company_public_hotel_no_partnership,
            EVCProFlows.on_company_public_restaurant_no_partnership,
        ],
        type: 'checkbox',
        validation: {},
        options: [
            {
                label: {
                    label: 'page.evc.question.has_min_open_schedule',
                    icon: ClockIcon,
                },
            },
        ],
        valueType: 'boolean',
    },
    [INPUT_NAMES_INSTALLATION_DETAILS.number_of_rooms_radio]: {
        name: INPUT_NAMES_INSTALLATION_DETAILS.number_of_rooms_radio,
        label: 'page.evc.question.info.number_of_rooms',
        placeholder: '',
        disabled: false,
        visible: [EVCProFlows.on_company_public_hotel, EVCProFlows.on_company_public_hotel_no_partnership],
        type: 'radio',
        validation: {},
        options: [
            {
                value: NUMBER_OF_ROOMS_RADIO_OPTIONS.TWENTY_TO_HUNDRED,
                label: { label: 'page.evc.question.number_of_rooms_between_20_and_100' },
                disabled: false,
                visible: true,
            },
            {
                value: NUMBER_OF_ROOMS_RADIO_OPTIONS.MORE_THAN_HUNDRED,
                label: { label: 'page.evc.question.number_of_rooms_greater_than_100' },
                disabled: false,
                visible: true,
            },
        ],
        valueType: 'number',
    },
    [INPUT_NAMES_INSTALLATION_DETAILS.number_of_rooms_greater_than_20]: {
        name: INPUT_NAMES_INSTALLATION_DETAILS.number_of_rooms_greater_than_20,
        label: '',
        placeholder: '',
        disabled: false,
        visible: [EVCProFlows.on_company_public_hotel, EVCProFlows.on_company_public_hotel_no_partnership],
        type: 'checkbox',
        validation: {},
        options: [
            {
                label: {
                    label: 'page.evc.question.number_of_rooms_greater_than_20',
                    icon: HotelIcon,
                },
            },
        ],
        valueType: 'boolean',
    },
    [INPUT_NAMES_INSTALLATION_DETAILS.has_min_seated_positions]: {
        name: INPUT_NAMES_INSTALLATION_DETAILS.has_min_seated_positions,
        label: '',
        placeholder: '',
        disabled: false,
        visible: [EVCProFlows.on_company_public_restaurant, EVCProFlows.on_company_public_restaurant_no_partnership],
        type: 'checkbox',
        validation: {},
        options: [
            {
                label: {
                    label: 'page.evc.question.has_min_seated_positions',
                    icon: RestaurantIcon,
                },
            },
        ],
        valueType: 'boolean',
    },
    [INPUT_NAMES_INSTALLATION_DETAILS.wants_partnership]: {
        name: INPUT_NAMES_INSTALLATION_DETAILS.wants_partnership,
        label: {
            label: 'page.evc.question.wants_partnership',
            // values: { companyName: 'EDP' }
        },
        placeholder: '',
        disabled: false,
        visible: [
            EVCProFlows.on_company_public_other,
            EVCProFlows.on_company_public_hotel,
            EVCProFlows.on_company_public_restaurant,
            EVCProFlows.on_company_public_no_partnership,
            EVCProFlows.on_company_public_hotel_no_partnership,
            EVCProFlows.on_company_public_restaurant_no_partnership,
        ],
        type: 'radio',
        validation: {},
        options: [
            {
                value: 'true',
                label: { label: 'label.yes' },
                disabled: false,
                visible: true,
            },
            {
                value: 'false',
                label: { label: 'page.evc.question.wants_partnership.no' },
                disabled: false,
                visible: true,
            },
        ],
        valueType: 'boolean',
        tooltip: {
            label: 'page.evc.question.wants_partnership.info',
        },
    },
    [INPUT_NAMES_INSTALLATION_DETAILS.sharing_model_id]: {
        name: INPUT_NAMES_INSTALLATION_DETAILS.sharing_model_id,
        label: 'page.evc.question.sharing_model_id',
        placeholder: '',
        disabled: false,
        visible: [EVCProFlows.on_company_public_other, EVCProFlows.on_company_public_hotel, EVCProFlows.on_company_public_restaurant],
        type: 'radio',
        validation: {},
        options: [
            {
                value: SHARING_MODEL_ID_OPTIONS.FIXED_SHARE,
                label: { label: 'page.evc.question.sharing_model_id.Id1' },
                disabled: false,
                visible: true,
            },
            {
                value: SHARING_MODEL_ID_OPTIONS.VARIABLE_SHARE,
                label: { label: 'page.evc.question.sharing_model_id.Id2' },
                tooltip: {
                    label: '',
                    title: InfoOPCSharing,
                    className: 'info-OPC-sharing',
                    type: 'default',
                    disablePortal: true,
                },
                disabled: false,
                visible: true,
            },
        ],
        valueType: 'number',
        tooltip: {
            label: 'page.evc.question.sharing_model_id.info',
        },
    },
};

export const QUESTIONS_MANAGEMENT: SectionQuestions<SECTION_NAMES.MANAGEMENT> = {
    [INPUT_NAMES_MANAGEMENT.management_option_id]: {
        name: INPUT_NAMES[SECTION_NAMES.MANAGEMENT].management_option_id,
        label: 'page.evc.label.management_option_id',
        placeholder: '',
        disabled: false,
        visible: [
            EVCProFlows.on_company_private,
            EVCProFlows.on_other,
            EVCProFlows.on_company_public,
            EVCProFlows.on_company_public_other,
            EVCProFlows.on_company_public_hotel,
            EVCProFlows.on_company_public_restaurant,
            EVCProFlows.on_company_public_no_partnership,
            EVCProFlows.on_company_public_hotel_no_partnership,
            EVCProFlows.on_company_public_restaurant_no_partnership,
        ],
        type: 'radio-list',
        validation: validate(['notDefaultNull']),
        options: [
            {
                value: MANAGEMENT_OPTIONS.NO_MANAGEMENT,
                label: 'page.evc.managementOption.noManagement',
                list: [
                    { label: 'page.evc.managementOption.noManagement.bullet1' },
                    { label: 'page.evc.managementOption.noManagement.bullet2' },
                ],
                visible: EVCProFlows.on_company_private,
            },
            {
                value: MANAGEMENT_OPTIONS.DPC,
                label: 'page.evc.managementOption.dpc',
                list: [
                    { label: 'page.evc.managementOption.dpc.bullet1' },
                    { label: 'page.evc.managementOption.dpc.bullet2' },
                    { label: 'page.evc.managementOption.dpc.bullet3' },
                ],
                visible: [
                    EVCProFlows.on_other,
                    EVCProFlows.on_company_public_other,
                    EVCProFlows.on_company_public_hotel,
                    EVCProFlows.on_company_public_restaurant,
                ],
            },
            {
                value: MANAGEMENT_OPTIONS.DPC,
                label: 'page.evc.managementOption.opc',
                list: [
                    { label: 'page.evc.managementOption.dpc.bullet1' },
                    { label: 'page.evc.managementOption.dpc.bullet2' },
                    { label: 'page.evc.managementOption.dpc.bullet3' },
                ],
                visible: [
                    EVCProFlows.on_company_public,
                    EVCProFlows.on_company_public_no_partnership,
                    EVCProFlows.on_company_public_hotel_no_partnership,
                    EVCProFlows.on_company_public_restaurant_no_partnership,
                ],
            },
            {
                value: MANAGEMENT_OPTIONS.EDP_CHARGE,
                label: 'page.evc.managementOption.edpCharge',
                list: [{ label: 'page.evc.managementOption.edpCharge.bullet1' }, { label: 'page.evc.managementOption.edpCharge.bullet2' }],
                visible: [EVCProFlows.on_company_private, EVCProFlows.on_other],
            },
        ],
        valueType: 'number',
    },
    [INPUT_NAMES_MANAGEMENT.has_plafond]: {
        name: INPUT_NAMES[SECTION_NAMES.MANAGEMENT].has_plafond,
        label: 'page.evc.label.includePlafond',
        placeholder: '',
        disabled: false,
        visible: [EVCProFlows.on_company_private, EVCProFlows.on_other],
        type: 'radio-label',
        validation: validate(['notDefaultNull']),
        options: {
            labelYes: 'label.yes',
            labelNo: 'label.no',
            valueYes: true,
            valueNo: false,
        },
        valueType: 'boolean',
    },
    [INPUT_NAMES_MANAGEMENT.plafond_smart]: {
        name: INPUT_NAMES_MANAGEMENT.plafond_smart,
        label: 'page.evc.nrOfPlafondCards.smart',
        placeholder: 'page.evc.nrOfPlafondCards.placeholder',
        disabled: false,
        visible: true,
        type: 'number',
        validation: validate(['required']),
        step: 1,
        valueType: 'number',
    },
    [INPUT_NAMES_MANAGEMENT.plafond_premium]: {
        name: INPUT_NAMES_MANAGEMENT.plafond_premium,
        label: 'page.evc.nrOfPlafondCards.premium',
        placeholder: 'page.evc.nrOfPlafondCards.placeholder',
        disabled: false,
        visible: true,
        type: 'number',
        validation: validate(['required']),
        step: 1,
        valueType: 'number',
    },
};
// @ts-ignore
export const QUESTIONS_CHARGERS: SectionQuestions<SECTION_NAMES.CHARGERS> = {
    [INPUT_NAMES_CHARGERS.install_charger_points]: {
        name: INPUT_NAMES_CHARGERS.install_charger_points,
        label: 'page.evc.wantsToInstallChargers',
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'radio-label',
        validation: validate(['notDefaultNull']),
        options: {
            labelYes: 'label.yes',
            labelNo: 'page.evc.wantsToInstallChargers.label.no',
            valueYes: true,
            valueNo: false,
            disableNo: true,
        },
        valueType: 'boolean',
    },
    [INPUT_NAMES_CHARGERS.place_of_installation_id]: {
        name: INPUT_NAMES_CHARGERS.place_of_installation_id,
        label: 'page.evc.question.place_of_installation_id',
        placeholder: '',
        disabled: false,
        visible: [
            EVCProFlows.on_company_private,
            EVCProFlows.on_company_public,
            EVCProFlows.on_company_public_other,
            EVCProFlows.on_company_public_hotel,
            EVCProFlows.on_company_public_restaurant,
            EVCProFlows.on_company_public_hotel_no_partnership,
            EVCProFlows.on_company_public_restaurant_no_partnership,
            EVCProFlows.on_company_public_no_partnership,
        ],
        type: 'radio-label',
        validation: validate(['notDefaultNull', 'required']),
        options: {
            labelYes: 'page.evc.question.place_of_installation_id.option_1',
            labelNo: 'page.evc.question.place_of_installation_id.option_2',
            valueYes: PLACE_OF_INSTALLATION_OPTIONS.INTERIOR,
            valueNo: PLACE_OF_INSTALLATION_OPTIONS.EXTERIOR,
        },
        valueType: 'number',
    },
    [INPUT_NAMES_CHARGERS.appliance_type_id]: {
        name: INPUT_NAMES_CHARGERS.appliance_type_id,
        label: 'page.evc.question.appliance_type_id',
        placeholder: '',
        disabled: false,
        visible: [
            EVCProFlows.on_company_private,
            EVCProFlows.on_company_public,
            EVCProFlows.on_company_public_other,
            EVCProFlows.on_company_public_hotel,
            EVCProFlows.on_company_public_restaurant,
            EVCProFlows.on_company_public_hotel_no_partnership,
            EVCProFlows.on_company_public_restaurant_no_partnership,
            EVCProFlows.on_company_public_no_partnership,
        ],
        type: 'radio-label',
        validation: validate(['notDefaultNull', 'required']),
        options: {
            labelYes: 'page.evc.question.appliance_type_id.option_1',
            labelNo: 'page.evc.question.appliance_type_id.option_2',
            valueYes: APPLIANCE_TYPE_OPTIONS.GROUND,
            valueNo: APPLIANCE_TYPE_OPTIONS.WALL,
        },
        valueType: 'number',
    },
    [INPUT_NAMES_CHARGERS.selected_chargers]: {
        name: INPUT_NAMES_CHARGERS.selected_chargers,
        label: '',
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'array',
        validation: {},
    },
    [INPUT_NAMES_CHARGERS.charger_points_number]: {
        name: INPUT_NAMES_CHARGERS.charger_points_number,
        label: 'page.evc.nrFacilitiesWhereToInstall',
        placeholder: 'page.evc.nrFacilitiesWhereToInstall.placeholder',
        disabled: false,
        visible: true,
        type: 'number',
        validation: validate(['notDefaultNull']),
        step: 1,
        valueType: 'number',
    },
    [INPUT_NAMES_CHARGERS.cpe_details_file]: {
        name: INPUT_NAMES_CHARGERS.cpe_details_file,
        label: 'page.evc.CPEDetailsFile',
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'file',
        validation: {},
        config: {
            template: {
                label: 'question.label.templateLoadCurve',
                files: [
                    {
                        // Nota: deixar o template sempre como primeiro elemento do array, ou mudar a lógica no overwrite da question em Sections/Chargers/OnOther
                        label: 'page.evc.CPEDetailsFile.template',
                        href: getBaseURL() + process.env.REACT_APP_API_URL_TEMPLATE_CPE_DETAILS,
                        icon: DownloadIcon,
                    },
                ],
            },
            upload: {
                accept: { 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] },
                label: 'label.upload',
                messagePart1: 'page.facility.button.uploadLoadCurve.part1',
                messagePart2: 'page.facility.button.uploadLoadCurve.part2',
            },
        },
    },
};

export const QUESTIONS_CHARGER_SETTINGS_ELECTRICAL_PANEL: SectionQuestions<SECTION_NAMES.CHARGER_SETTINGS_ELECTRICAL_PANEL> = {
    [CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.has_electrical_panel]: {
        name: CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.has_electrical_panel,
        label: 'page.evc.question.has_electrical_panel',
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'radio-label',
        validation: validate(['required']),
        options: {
            labelYes: 'label.yes',
            labelNo: 'label.no',
            valueYes: true,
            valueNo: false,
        },
    },
    [CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.qve_corrugated_pipe]: {
        name: CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.qve_corrugated_pipe,
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'switch',
        validation: {},
        options: {
            value: '',
            label: { label: 'page.evc.question.qve_corrugated_pipe' },
            disabled: false,
            visible: true,
        },
    },
    [CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.qve_vd_tube]: {
        name: CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.qve_vd_tube,
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'switch',
        validation: {},
        options: {
            value: '',
            label: { label: 'page.evc.question.qve_vd_tube' },
            disabled: false,
            visible: true,
        },
    },
    [CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.qve_cable_conveyor]: {
        name: CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.qve_cable_conveyor,
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'switch',
        validation: {},
        options: {
            value: '',
            label: { label: 'page.evc.question.qve_cable_conveyor' },
            disabled: false,
            visible: true,
        },
    },
    [CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.qve_cable_conveyor_distance]: {
        name: CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.qve_cable_conveyor_distance,
        label: 'page.evc.question.qve_cable_conveyor_distance',
        placeholder: 'label.value',
        disabled: false,
        visible: true,
        type: 'number',
        validation: validate(['required']),
    },
};

export const QUESTIONS_CHARGER_SETTINGS_INSTALLATION_DETAILS: SectionQuestions<SECTION_NAMES.CHARGER_SETTINGS_INSTALLATION_DETAILS> = {
    [CHARGER_SETTINGS_INPUT_NAMES_INSTALLATION_DETAILS.charging_type]: {
        name: CHARGER_SETTINGS_INPUT_NAMES_INSTALLATION_DETAILS.charging_type,
        label: 'page.evc.question.charging_type',
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'radio',
        validation: {},
        options: [
            {
                value: 'false',
                label: { label: 'page.evc.question.is_quick.option_1' },
                disabled: false,
                visible: true,
            },
            {
                value: 'true',
                label: { label: 'page.evc.question.is_quick.option_2' },
                disabled: false,
                visible: true,
            },
        ],
    },
    [CHARGER_SETTINGS_INPUT_NAMES_INSTALLATION_DETAILS.electric_panel_to_charging_station_distance]: {
        name: CHARGER_SETTINGS_INPUT_NAMES_INSTALLATION_DETAILS.electric_panel_to_charging_station_distance,
        label: 'page.evc.question.electric_panel_to_charging_station_distance',
        placeholder: 'label.value',
        disabled: false,
        visible: true,
        type: 'number',
        validation: validate(['required', 'number']),
    },
    [CHARGER_SETTINGS_INPUT_NAMES_INSTALLATION_DETAILS.electric_vehicle_panel_to_charging_station_distance]: {
        name: CHARGER_SETTINGS_INPUT_NAMES_INSTALLATION_DETAILS.electric_vehicle_panel_to_charging_station_distance,
        label: 'page.evc.question.electric_vehicle_panel_to_charging_station_distance',
        placeholder: 'label.value',
        disabled: false,
        visible: true,
        type: 'number',
        validation: validate(['required', 'number']),
    },
};

export const QUESTIONS_CHARGER_SETTINGS_EXTRAS: SectionQuestions<SECTION_NAMES.CHARGER_SETTINGS_EXTRAS> = {
    [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.vd_tube]: {
        name: CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.vd_tube,
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'switch',
        validation: {},
        options: {
            value: '',
            label: { label: 'page.evc.question.qve_vd_tube' },
            disabled: false,
            visible: true,
        },
    },
    [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.cable_conveyor]: {
        name: CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.cable_conveyor,
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'switch',
        validation: {},
        options: {
            value: '',
            label: { label: 'page.evc.question.qve_cable_conveyor' },
            disabled: false,
            visible: true,
        },
    },
    [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.cable_conveyor_distance]: {
        name: CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.cable_conveyor_distance,
        label: 'page.evc.question.qve_cable_conveyor_distance',
        placeholder: 'label.value',
        disabled: false,
        visible: true,
        type: 'number',
        validation: validate(['required']),
    },
    [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_massive]: {
        name: CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_massive,
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'switch',
        validation: {},
        options: {
            value: '',
            label: { label: 'page.evc.question.qve_massive' },
            disabled: false,
            visible: true,
        },
    },
    [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.pedestal_massive]: {
        name: CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.pedestal_massive,
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'switch',
        validation: {},
        options: {
            value: '',
            label: { label: 'page.evc.question.pedestal_massive' },
            disabled: false,
            visible: true,
        },
    },
    [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_trench]: {
        name: CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_trench,
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'switch',
        validation: {},
        options: {
            value: '',
            label: { label: 'page.evc.question.qve_trench' },
            disabled: false,
            visible: true,
        },
    },
    [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_trench_type]: {
        name: CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_trench_type,
        label: 'page.evc.question.qve_trench_type',
        placeholder: 'page.evc.label.select',
        disabled: false,
        visible: true,
        type: 'select',
        validation: {},
        options: [],
    },
    [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_trench_distance]: {
        name: CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_trench_distance,
        label: 'page.evc.question.qve_trench_distance',
        placeholder: 'label.value',
        disabled: false,
        visible: true,
        type: 'number',
        validation: validate(['required']),
    },
    [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.cutting_panel]: {
        name: CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.cutting_panel,
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'switch',
        validation: {},
        options: {
            value: '',
            label: { label: 'page.evc.question.cutting_panel' },
            disabled: false,
            visible: true,
        },
    },
    [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.mechanic_protection]: {
        name: CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.mechanic_protection,
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'switch',
        validation: {},
        options: {
            value: '',
            label: { label: 'page.evc.question.mechanic_protection' },
            disabled: false,
            visible: true,
        },
    },
    [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.vertical_signs]: {
        name: CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.vertical_signs,
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'switch',
        validation: {},
        options: {
            value: '',
            label: { label: 'page.evc.question.vertical_signs' },
            disabled: false,
            visible: true,
        },
    },
    [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.horizontal_signs]: {
        name: CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.horizontal_signs,
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'switch',
        validation: {},
        options: {
            value: '',
            label: { label: 'page.evc.question.horizontal_signs' },
            disabled: false,
            visible: true,
        },
    },
};

export const QUESTIONS: TFormQuestions = {
    [SECTION_NAMES.INSTALLATION_DETAILS]: QUESTIONS_INSTALLATION_DETAILS,
    [SECTION_NAMES.MANAGEMENT]: QUESTIONS_MANAGEMENT,
    [SECTION_NAMES.CHARGERS]: QUESTIONS_CHARGERS,
    [SECTION_NAMES.CHARGER_SETTINGS_ELECTRICAL_PANEL]: QUESTIONS_CHARGER_SETTINGS_ELECTRICAL_PANEL,
    [SECTION_NAMES.CHARGER_SETTINGS_INSTALLATION_DETAILS]: QUESTIONS_CHARGER_SETTINGS_INSTALLATION_DETAILS,
    [SECTION_NAMES.CHARGER_SETTINGS_EXTRAS]: QUESTIONS_CHARGER_SETTINGS_EXTRAS,
};

export const QUESTIONS_SETTINGS: TFormQuestionsSettings = {
    [getCompanyProfileIds().EDP_PT]: {
        [USERS_TYPE_ID.MANAGER]: QUESTIONS,
    },
};

export const MAX_NUMBER_OF_CHARGERS = {
    [getCompanyProfileIds().EDP_PT]: 2,
    DEFAULT: 2,
} as const;

export const MAX_NUMBER_OF_CHARGERS_ON_OTHER = {
    [getCompanyProfileIds().EDP_PT]: 1,
    DEFAULT: 1,
} as const;

export const MAX_NUMBER_OF_CHARGERS_ON_PARTNERSHIPS = {
    [getCompanyProfileIds().EDP_PT]: 1,
    DEFAULT: 1,
} as const;

//#endregion Questions

export enum EVCProActionTypes {
    SET_MOUNT_DATA = 'SET_MOUNT_DATA',
    SET_LOADINGS = 'SET_LOADINGS',
    SET_KPIS = 'SET_KPIS',
    SET_INPUTS = 'SET_INPUTS',
}

export const DEFAULT_FORM_VALUES: TEVCProInputs = {
    [SECTION_NAMES.INSTALLATION_DETAILS]: {
        [INPUT_NAMES_INSTALLATION_DETAILS.charging_location_option_id]: null,
        [INPUT_NAMES_INSTALLATION_DETAILS.installation_location_option_id]: null,
        [INPUT_NAMES_INSTALLATION_DETAILS.entrance_type_id]: null,
        // @ts-ignore
        [INPUT_NAMES_INSTALLATION_DETAILS.facility_type_id]: undefined,
        [INPUT_NAMES_INSTALLATION_DETAILS.has_min_open_schedule]: null,
        [INPUT_NAMES_INSTALLATION_DETAILS.has_min_parking_spaces]: null,
        [INPUT_NAMES_INSTALLATION_DETAILS.has_min_seated_positions]: null,
        [INPUT_NAMES_INSTALLATION_DETAILS.has_partnership_requirements]: false,
        [INPUT_NAMES_INSTALLATION_DETAILS.number_of_rooms_between_20_and_100]: true,
        [INPUT_NAMES_INSTALLATION_DETAILS.number_of_rooms_greater_than_100]: false,
        [INPUT_NAMES_INSTALLATION_DETAILS.number_of_rooms_greater_than_20]: null,
        [INPUT_NAMES_INSTALLATION_DETAILS.number_of_rooms_radio]: NUMBER_OF_ROOMS_RADIO_OPTIONS.TWENTY_TO_HUNDRED,
        [INPUT_NAMES_INSTALLATION_DETAILS.wants_partnership]: false,
        [INPUT_NAMES_INSTALLATION_DETAILS.sharing_model_id]: null,
        [INPUT_NAMES_INSTALLATION_DETAILS.ready_to_continue]: false,
    },
    [SECTION_NAMES.MANAGEMENT]: {
        [INPUT_NAMES_MANAGEMENT.management_option_id]: null,
        [INPUT_NAMES_MANAGEMENT.has_plafond]: null,
        [INPUT_NAMES_MANAGEMENT.plafond_smart]: null,
        [INPUT_NAMES_MANAGEMENT.plafond_premium]: null,
    },
    [SECTION_NAMES.CHARGERS]: {
        [INPUT_NAMES_CHARGERS.install_charger_points]: null,
        [INPUT_NAMES_CHARGERS.place_of_installation_id]: null,
        [INPUT_NAMES_CHARGERS.appliance_type_id]: null,
        [INPUT_NAMES_CHARGERS.selected_chargers]: [],
        [INPUT_NAMES_CHARGERS.charger_points_number]: undefined,
        [INPUT_NAMES_CHARGERS.cpe_details_file]: undefined,
    },
    currentFlow: EVCProFlows.not_selected,
};
