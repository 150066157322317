import { getEndpoint, URL_COUNTIES, URL_DS, URL_PRODUCTIONS, URL_PRODUCTS, URL_SIMULATIONS, URL_GRANTS } from 'constants/endpoints';
import axios, { axiosOpenAreaInstance } from 'services/@efz/axios';
import { getMessageNetworkError } from 'services/statusCodeHttp';

export const getDetectRoofTop = (payload, isOArea, abortController) => {
    let requestCfg = {
        url: `${getEndpoint(URL_DS)}/detect-rooftop`,
        method: 'POST',
        data: payload,
        signal: abortController?.signal,
    };

    if (isOArea) {
        return axiosOpenAreaInstance
            .request(requestCfg)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    } else {
        return axios
            .request(requestCfg)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    }
};
export const getAnalyzeAreaB2C = (payload, isOArea, abortController) => {
    let requestCfg = {
        url: `${getEndpoint(URL_DS)}/analyze-roof`,
        method: 'POST',
        data: payload,
        signal: abortController?.signal,
    };
    if (isOArea) {
        return axiosOpenAreaInstance
            .request(requestCfg)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    } else {
        return axios
            .request(requestCfg)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    }
};

export const postSimulation = (payload, isOArea = false, abortController) => {
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }

    let requestCfg = {
        url: `${getEndpoint(URL_SIMULATIONS)}`,
        method: 'POST',
        data: payload,
        signal: abortController?.signal,
    };

    if (isOArea) {
        return axiosOpenAreaInstance
            .request(requestCfg)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    } else {
        return axios
            .request(requestCfg)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    }
};

export const postProductions = (payload, params, isOpenArea, abortController) => {
    const requestConfig = {
        url: `${getEndpoint(URL_PRODUCTIONS)}`,
        method: 'POST',
        data: payload,
        signal: abortController?.signal,
        params,
    };

    if (isOpenArea) {
        return axiosOpenAreaInstance
            .request(requestConfig)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    } else {
        return axios
            .request(requestConfig)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    }
};

export const getCounties = (params, isOArea = false, abortController) => {
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }

    let requestCfg = {
        url: `${getEndpoint(URL_COUNTIES)}`,
        method: 'GET',
        params,
        signal: abortController?.signal,
    };

    if (isOArea) {
        return axiosOpenAreaInstance
            .request(requestCfg)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    } else {
        return axios
            .request(requestCfg)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    }
};

export const getBatteryPowers = (isOArea, options, abortController) => {
    const { range_id = null, is_kits = false, productId } = options;
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }

    let params = { range_id, is_kits };

    let requestCfg = {
        url: `${getEndpoint(URL_PRODUCTS)}/${productId}/battery-powers`,
        method: 'GET',
        signal: abortController?.signal,
        params: params,
    };

    if (isOArea) {
        return axiosOpenAreaInstance
            .request(requestCfg)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    } else {
        return axios
            .request(requestCfg)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    }
};

export const getGrants = (isOArea = true, params, abortController) => {
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }

    let requestCfg = {
        url: `${getEndpoint(URL_GRANTS)}`,
        method: 'GET',
        signal: abortController?.signal,
        params: params,
    };

    if (isOArea) {
        return axiosOpenAreaInstance
            .request(requestCfg)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    } else {
        return axios
            .request(requestCfg)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    }
};

/**
 * postSendToCRM
 * \
 * @returns {Promise<AxiosResponse<T>>}
 */
let postSendToCRMAbortController;
export const postSendToCRMByOA = ({ processId }, abortController = postSendToCRMAbortController) => {
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }

    return axiosOpenAreaInstance
        .request({
            url: `${getEndpoint(URL_SIMULATIONS)}/${processId}/support-docs/crm`,
            method: 'POST',
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};
