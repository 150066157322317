import { useContext, useState } from 'react';

import IntlMessages from 'components/util/IntlMessages';
import { MenuItem, Select } from '@mui/material';
import { isFieldDefined } from 'services/util/auxiliaryUtils';

import { SPV_SIMPLE_QUESTIONS as questions, SPV_SIMPLE_INPUTS as INPUT_NAMES } from 'constants/products/solarpvSimple';

//icons
import { ReactComponent as WarningIcon } from 'assets/@efz/icons/warning.svg';
import { ReactComponent as DiscountIcon } from 'assets/images/products/spv-simple/discount.svg';
import FormatNumber from 'components/util/FormatNumber';
import useGrants from 'hooks/products/solarpvSimple/useGrants';
import { Controller, useFormContext } from 'react-hook-form';
import { SPVSimpleContext } from 'contexts/products/solarpvSimple/solarpvSimpleContext';
import { Button } from '@save2compete/efz-design-system';
import { StyledSPVSimpleGrants } from 'pages/Restricted/Products/SolarpvSimple/styles';

const OAgrants = ({ solution, isRequestingProposal }) => {
    const [useGrant, setUseGrant] = useState(null);

    const {
        spvSimpleState: {
            simulations,
            options: { battery },
        },
    } = useContext(SPVSimpleContext);

    const { control, watch, setValue } = useFormContext();

    let investment =
        !solution.is_recomended_with_battery ? solution?.investment_without_battery / 0.9 : solution?.investment_with_battery / 0.9;
    let battery_price = solution.investment_with_battery - solution.investment_without_battery;

    const watchGrantId = watch(questions[INPUT_NAMES.GRANT_ID].name);

    const resetValues = () => {
        setValue(INPUT_NAMES.GRANT_SP_BATTERY, '');
        setValue(INPUT_NAMES.GRANT_SP_PV_STATION, '');
        setValue(INPUT_NAMES.GRANT_SV_BATTERY, '');
        setValue(INPUT_NAMES.GRANT_SV_PV_STATION, '');
    };

    const { getKpisGrants, fetchGrants, options } = useGrants();

    const handleConstruct = async () => {
        setUseGrant(true);
        if (options?.length === 0) {
            fetchGrants(
                simulations.panel_peak_power,
                battery.battery_capacity_selected_kwh,
                solution.investment_without_battery,
                battery_price
            );
        }
    };

    return (
        <>
            <StyledSPVSimpleGrants className="spv-simple-grants">
                <h2>
                    <IntlMessages id="component.simulations.OA.grants.title" />
                </h2>
                <div className="spv-simple-grants-question">
                    <p>
                        <IntlMessages id="component.simulations.OA.grants.question" />
                    </p>
                    <div className="spv-simple-grants-question_options">
                        {/* TODO: trocar este btn para ser controlador pelo form */}
                        <Button variant="secondary" disabled={isRequestingProposal} onClick={handleConstruct} dataTestId="useGrant">
                            <IntlMessages id="label.yes" />
                        </Button>
                        <Button
                            variant="secondary"
                            disabled={isRequestingProposal}
                            onClick={() => {
                                setUseGrant(false);
                                setValue(INPUT_NAMES.GRANT_ID, '');
                                resetValues();
                            }}
                            dataTestId="notUseGrant"
                        >
                            <IntlMessages id="label.no" />
                        </Button>
                    </div>
                </div>
                {useGrant && (
                    <>
                        <div className="spv-simple-grants-options">
                            <p>
                                <IntlMessages id="component.simulations.OA.grants.select.category" />
                            </p>
                            <Controller
                                name={questions[INPUT_NAMES.GRANT_ID].name}
                                control={control}
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                render={({ field: { ref, ...field } }) => (
                                    <Select
                                        {...field}
                                        displayEmpty={true}
                                        disabled={isRequestingProposal}
                                        inputProps={{
                                            className: 'spv-simple-grants-options_select d-flex align-items-center',
                                        }}
                                        variant="outlined"
                                        placeholder="Select Itens"
                                        defaultValue=""
                                        onChange={(evt) => {
                                            field.onChange(`${evt.target.value}`);
                                            resetValues();
                                        }}
                                    >
                                        <MenuItem key={`SelectCustom_MenuItem_null`} value="" disabled={true} style={{ display: 'none' }}>
                                            <IntlMessages id="label.select.category" />
                                        </MenuItem>
                                        {options?.map((option) => {
                                            return (
                                                <MenuItem key={`SelectCustom_MenuItem_${option.grant_id}`} value={option.grant_id}>
                                                    <IntlMessages id={`component.simulations.OA.grants.${option.grant_tag}`} />
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                )}
                            />
                        </div>

                        {isFieldDefined(watch(INPUT_NAMES.GRANT_SV_BATTERY)) && (
                            <>
                                <div className="spv-simple-grants-data">
                                    <div className="spv-simple-grants-data_tab">
                                        <p>
                                            <IntlMessages id="page.bm.grants.originalInvestment" />
                                        </p>
                                        <span>
                                            <FormatNumber number={investment} numberOfDecimalPlaces={0} unit={'currency'} />
                                        </span>
                                    </div>
                                    <div className="spv-simple-grants-data_tab grant">
                                        <p>
                                            <IntlMessages id="page.bm.grants.investmentWithGrant" />
                                        </p>
                                        <span>
                                            <DiscountIcon />
                                            <FormatNumber
                                                number={parseFloat(
                                                    investment -
                                                        (watch(INPUT_NAMES.GRANT_SV_BATTERY) + watch(INPUT_NAMES.GRANT_SV_PV_STATION))
                                                )}
                                                numberOfDecimalPlaces={0}
                                                unit={'currency'}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="spv-simple-grants-warning">
                            <WarningIcon />
                            <p>
                                <IntlMessages id="component.simulations.OA.grants.warning" />
                            </p>
                        </div>

                        <Button
                            className={`spv-simple-grants-calculate`}
                            variant="contained"
                            disabled={isFieldDefined(watch(INPUT_NAMES.GRANT_SV_BATTERY)) || isRequestingProposal}
                            onClick={() => {
                                getKpisGrants({ options, watchGrantId, setValue });
                            }}
                        >
                            <IntlMessages id="label.calculate" />
                        </Button>
                    </>
                )}
            </StyledSPVSimpleGrants>
        </>
    );
};

export default OAgrants;
