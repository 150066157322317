import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import IntlMessages from 'components/util/IntlMessages';
import { withTracker } from 'services/withTracker';

import { ReactComponent as BrokenCharger } from 'assets/images/icons/svg/broken-charger.svg';
import { ReactComponent as BackgroundLeft } from 'assets/images/home/svg/left-graph.svg';
import { ReactComponent as BackgroundRight } from 'assets/images/home/svg/right-graph.svg';
import { ReactComponent as BackgroundBottom } from 'assets/images/home/svg/bottom-graph.svg';
import { BASENAME_URL_APP } from 'constants/settings';
import { Button } from '@save2compete/efz-design-system';
import { StyledErrorPage } from '../StylesErrorPage';

const Error403 = ({ props }) => {
    const history = useHistory();
    const message = props?.message ?? 'page.error.403.unexpected';
    const url = props?.url ?? BASENAME_URL_APP + 'solutions';
    const buttonText = props?.buttonText ?? 'page.404.button.goSolutions';

    return (
        <StyledErrorPage className="content-wrapper-fluid">
            <div className="ornaments-svg">
                <div className="ornament-left">
                    <BackgroundLeft />
                </div>
                <div className="ornament-bottom-container">
                    <div className="ornament-bottom">
                        <BackgroundBottom />
                    </div>
                </div>
                <div className="ornament-right">
                    <BackgroundRight />
                </div>
            </div>
            <>
                <section className="page404-content-container">
                    <div className="content-container">
                        <div className="page404-content-image">
                            <BrokenCharger />
                        </div>
                        <div className="page404-content-text">
                            <h3 className="text-center mt-3">
                                <IntlMessages id="page.error.403" />
                            </h3>
                            <span className="text-center">
                                <IntlMessages id={message} />
                            </span>
                        </div>
                    </div>
                    <div className="page404-content-button">
                        <Button onClick={() => history.push(url)} dataTestId="button-go-home">
                            <IntlMessages id={buttonText} />
                        </Button>
                    </div>
                </section>
            </>
        </StyledErrorPage>
    );
};

Error403.propTypes = {
    props: PropTypes.shape({
        url: PropTypes.string.isRequired,
        buttonText: PropTypes.string.isRequired,
        message: PropTypes.string,
    }).isRequired,
};

export default withTracker(Error403, 'Error 403');
