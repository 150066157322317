import { IBusinessModel } from './../index';
import { TRubric } from 'interfaces/rubric';
import { TNegotiationProfile } from 'interfaces/user';
import { Nullable } from 'types/utils';
import { BmDetailedActions, BmLoadings, FixedInputOptions } from 'constants/businessModelsPro/index';
import { Dispatch } from 'react';
import { OFFER_DETAILS_INPUT_NAMES } from 'constants/businessModelsPro';

export interface IDetailedOfferEdition {
    bmState: TDetailedState;
    negotiationProfile: TNegotiationProfile;
    lowNegotiationProfile: boolean;
    setBMEventHandler: <Actions extends DetailedOfferEditionEvents>(type: Actions, payload: TDetailedBMDispatcherValues<Actions>) => void;
    companyProfileId: any;
    productID: number;
    bmDispatch: Dispatch<IDetailedBMAction>;
}

export interface IDetailedBMAction {
    type: DetailedOfferEditionEvents;
    payload: any;
}

export type TDetailedState = {
    businessModelSelected: Nullable<IBusinessModel>;
    offerEdition: any;
    businessModelBody: any;
    action: Nullable<BmDetailedActions>;
    kpis: Nullable<{
        [key: string]: number | boolean | Nullable<TFinalKpis> | any;
        kpis: {
            negotiation: any;
        };
        negotiation: any;
        finalKpis: Nullable<TFinalKpis>;
    }>;
    addOnHelper: {
        formData: {
            simulator_var: string;
            value: string | number;
        };
        info: {
            text: Nullable<string>;
            min_monthly_fee: Nullable<number>;
            max_monthly_fee: Nullable<number>;
            new_min_monthly_fee: Nullable<number>;
            new_max_monthly_fee: Nullable<number>;
        };
    };
    defaultNegotiation: any;
    offerEditionValues: TOfferEditionValues;
    sliderReady: boolean;
    proposalReady: boolean;
    reqsHash: Nullable<string[]>;
    loadings: TBmLoadings;
    elements: {
        monthlyPayments: Nullable<TElements>;
        annualUpdateMonthlyFee: Nullable<TElements>;
        uniqueAllowance: Nullable<TElements>;
        yearlyAllowance: Nullable<TElements>;
        omContractDuration: Nullable<TElements>;
        recOwnership: Nullable<TElements>;
        ownershipTransfer: Nullable<TElements>;
        itcRate: Nullable<TElements>;
    };
};

type TFinalKpis = {
    [P in
        | 'irr'
        | 'irr_wacc'
        | 'npv'
        | 'irr_target_min'
        | 'total_comission'
        | 'fixed_comission'
        | 'variable_comission'
        | 'preventive_maintenance_cost'
        | 'inverters_extension_cost'
        | 'corrective_maintenance_cost'
        | 'prod_warranty_cost'
        | 'civil_insurance_cost'
        | 'multi_risk_insurance_cost'
        | 'opex_with_tax'
        | 'production_during_contract'
        | 'market_value'
        | 'yearly_depreciation'
        | 'co2_economics'
        | 'first_year_economics'
        | 'adjustment_value']: Nullable<number>;
} & {
    [P in 'estimated_price_per_year_arr' | 'prod_per_year_arr' | 'buyout_price_per_year_arr' | 'net_saving_price_per_year_arr']: number[];
} & { risk_details: TRiskDetails };

type TRiskDetails = {
    risk_premium_value_month: number;
    monthly_payments_number: number;
    rating_trigger_clause: number;
    failure_to_pay_clause: number;
    bank_warranty_current_risk: number[];
    bank_warranty_revised_risk: number[];
};

export type TOfferEditionValues = {
    [P in
        | 'monthly_payment_option_id'
        | 'monthlyPayment'
        | 'extraCost'
        | 'annualUpdateFee'
        | 'opAndManDuration'
        | 'itc_rate_prc']?: Nullable<number>;
} & { rubrics: TRubric[] } & {
    allowance?: {
        selected_allowance?: string;
        unique_allowance_currency?: number;
        yearly_allowance_currency?: number;
        yearly_allowance_duration_years?: number;
    };
} & {
    [P in 'boolean_rec_ownership' | 'boolean_ownership_transfer']?: Nullable<boolean>;
};

export type TElements = {
    criteria: any;
    description: string;
    options: {
        default: number | string;
        options: number[];
        unity: 'years' | 'months';
        step?: number;
    };
    section_description: string;
    section_id: number;
    tag: string;
};

export type TUpFrontElements = {
    [OFFER_DETAILS_INPUT_NAMES.PAYMENT_METHOD]: Nullable<{
        tag: string;
        description: string;
        options: {
            default: FixedInputOptions;
            options: FixedInputOptions;
        };
        unity: string;
    }>;
    [OFFER_DETAILS_INPUT_NAMES.OM_DURATION]: Nullable<{
        description: string;
        tag: string;
        section_id: number;
        section_description: string;
        options: TOmDurationsType;
        criteria: {
            parceiro_id: number;
        };
    }>;
    [OFFER_DETAILS_INPUT_NAMES.INVERTERS_WARATY]: Nullable<{
        description: string;
        tag: string;
        section_id: number;
        section_description: string;
        options: TOmDurationsType;
        criteria: Nullable<{
            parceiro_id: number;
        }>;
    }>;
};

type TOmDurationsType = {
    pv_tipo_rubrica_id: number;
    categoria_custos_id: number;
    options: TOMDurationOptionType[];
    unity: string;
};

export type TOMDurationOptionType = {
    input_fixed_text?: FixedInputOptions;
    om_durations: number[];
    om_durations_default: number;
};

export enum DetailedOfferEditionEvents {
    SET_BUSINESS_MODEL_SELECTED = 'SET_BUSINESS_MODEL_SELECTED',
    SET_BUSINESS_MODEL_MARGIN = 'SET_BUSINESS_MODEL_MARGIN',
    SET_BUSINESS_MODEL_KPIS = 'SET_BUSINESS_MODEL_KPIS',
    SET_BUSINESS_MODEL_VALUES = 'SET_BUSINESS_MODEL_VALUES',
    SET_BUSINESS_MODEL_PROPOSAL_READY = 'SET_BUSINESS_MODEL_PROPOSAL_READY',
    SET_BUSINESS_MODEL_FINAL_KPIS = 'SET_BUSINESS_MODEL_FINAL_KPIS',
    RESET_BUSINESS_MODEL = 'RESET_BUSINESS_MODEL',
    SET_BUSINESS_MODEL_LOADINGS = 'SET_BUSINESS_MODEL_LOADINGS',
    RESET_BUSINESS_MODEL_LOADINGS = 'RESET_BUSINESS_MODEL_LOADINGS',
    SET_DETAILED_ACTION = 'SET_DETAILED_ACTION',
    SET_BUSINESS_MODEL_KPIS_ADD_ON_HELPER = 'SET_BUSINESS_MODEL_KPIS_ADD_ON_HELPER',
    RESET_BUSINESS_MODEL_KPIS_ADD_ON_HELPER = 'RESET_BUSINESS_MODEL_KPIS_ADD_ON_HELPER',
}

export type TDetailedBMDispatcherValues<Action> =
    Action extends typeof DetailedOfferEditionEvents.SET_BUSINESS_MODEL_SELECTED ?
        {
            businessModelSelected: any;
            kpis: any;
            businessModelBody: any;
            offerEdition?: any;
            elements?: any;
        }
    : Action extends typeof DetailedOfferEditionEvents.SET_BUSINESS_MODEL_MARGIN ? number
    : Action extends typeof DetailedOfferEditionEvents.SET_BUSINESS_MODEL_VALUES ? { values: TOfferEditionValues; sliderReady?: boolean }
    : Action extends typeof DetailedOfferEditionEvents.SET_BUSINESS_MODEL_KPIS ?
        { kpis: any; sliderReady: boolean; reqsHash: string[] } // API
    : Action extends typeof DetailedOfferEditionEvents.SET_BUSINESS_MODEL_PROPOSAL_READY ? boolean
    : Action extends typeof DetailedOfferEditionEvents.SET_BUSINESS_MODEL_FINAL_KPIS ?
        any // API
    : Action extends typeof DetailedOfferEditionEvents.RESET_BUSINESS_MODEL ? null
    : Action extends typeof DetailedOfferEditionEvents.SET_BUSINESS_MODEL_LOADINGS ? { name: BmLoadings; value: boolean }[]
    : Action extends typeof DetailedOfferEditionEvents.RESET_BUSINESS_MODEL_LOADINGS ? null
    : Action extends typeof DetailedOfferEditionEvents.SET_DETAILED_ACTION ? BmDetailedActions
    : Action extends typeof DetailedOfferEditionEvents.SET_BUSINESS_MODEL_KPIS_ADD_ON_HELPER ? any
    : Action extends typeof DetailedOfferEditionEvents.RESET_BUSINESS_MODEL_KPIS_ADD_ON_HELPER ? null
    : any;

export type TBmLoadings = {
    [key in BmLoadings]: boolean;
};
