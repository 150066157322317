import { isDefined } from './util/auxiliaryUtils';
import { COMPANY_IDS } from 'constants/settings';
import { EDIT_USER_FORM_QUESTIONS, GRUPO_CANAL_IDS, STATE_IDS, USERS_TYPE_ALL, USERS_TYPE_ID } from 'constants/user';
import clone from 'fast-copy';

/**
 *
 * @returns {{EDP_PT: number,
 * EDP_ES: number,
 *  DYNAMIC: number,
 *  ESB: number,
 *  EDP_IT: number,
 *  EFZ: number,
 *  EDP_PL: number,
 *  EDP_BR: number,
 *  TELEFONICA: number,
 *  NRG: number,
 *  MONS: number,
 *  SUNSEAP: number,
 *  PPC: number,
 *  EDEN: number,
 *  ROMANDE: number,
 *  BASE_UK_B2C: number,
 *  BASE_UK_B2B: number,
 *  FREEDOM: number,
 *  SECON: number,
 *  THUEGA: number,
 *  PROFILE_EDP_ES: number,
 *  PROFILE_BASE_UK: number,
 *  PROFILE_BASE_DE: number,
 *  PROFILE_EDP_FR: number,
 *  PROFILE_BASE_IRELAND: number,
 *  PROFILE_EDP_IT: number,
 *  PROFILE_EDP_PL: number,
 *  PROFILE_BASE_SWISS: number,
 *  PROFILE_SUNSEAP: number,
 *  PROFILE_PPC: number,
 *  PROFILE_ROMANDE: number,
 *  PROFILE_EFZ: number
 *  PROFILE_BASE_IT: number
 *  EVOLO: number
 *  PROFILE_BASE_USA: number
 *  CEZ: number
 * }}
 *
 */
export function getCompanyProfileIds() {
    if (!!localStorage && !!localStorage.getItem('CPID')) return JSON.parse(window.atob(localStorage.getItem('CPID')));
    return COMPANY_IDS;
}
/**
 * getUserType
 *
 * @param typeID
 * @returns {string}
 */
export const getUserType = (typeID) => {
    switch (parseInt(typeID)) {
        case USERS_TYPE_ID.MANAGER:
            return 'Manager';
        case USERS_TYPE_ID.CHANNEL:
            return 'Channel';
        case USERS_TYPE_ID.CLIENT:
            return 'Client';
        case USERS_TYPE_ID.ADMIN:
            return 'Admin';
        case USERS_TYPE_ID.COMMERCIAL_SUPPORT:
            return 'Commercial Support';
        default:
            return 'DEFAULT';
    }
};

/**
 * isBackofficeUser
 *
 * @param userTypeId
 * @returns {boolean}
 */
export function isBackofficeUser(userTypeId) {
    return [USERS_TYPE_ID.ADMIN, USERS_TYPE_ID.COMMERCIAL_SUPPORT].includes(parseInt(userTypeId));
}

/**
 * getStateIDCurrent
 *
 * @returns {null|*}
 */
const getStateIDCurrent = () => {
    const userToken = localStorage.getItem('userToken');
    const userTypeId = localStorage.getItem('userTypeId');
    return isDefined(userToken) && isDefined(userTypeId) && USERS_TYPE_ALL.includes(parseInt(userTypeId)) ? STATE_IDS.PLATFORM : null;
};

/**
 * showStateCurrent
 *
 * @param stateID
 * @returns {boolean}
 */
export const showStateCurrent = (stateID) => {
    const stateIDCurrent = getStateIDCurrent();
    return isDefined(stateIDCurrent) && [STATE_IDS.PLATFORM, STATE_IDS.BACKOFFICE].includes(stateID) && stateIDCurrent === stateID;
};

/**
 * getLoggedUserTypeID
 *
 * @returns {number}
 */
export const getLoggedUserTypeID = () => parseInt(localStorage.getItem('userTypeId'));

/**
 * isTokenValid
 *
 * @returns {boolean}
 */
export const isTokenValid = () => isDefined(getStateIDCurrent());

/**
 * isUserClient
 *
 * @param userTypeID
 * @returns {boolean}
 */
export const isUserClient = (userTypeID) => parseInt(userTypeID) === USERS_TYPE_ID.CLIENT;

/**
 * isUserChannel
 *
 * @param userTypeID
 * @returns {boolean}
 */
export const isUserChannel = (userTypeID) => userTypeID === USERS_TYPE_ID.CHANNEL;

/**
 * isUserManager
 *
 * @param userTypeID
 * @returns {boolean}
 */
export const isUserManager = (userTypeID) => userTypeID === USERS_TYPE_ID.MANAGER;

/**
 *
 * @param {*} companyProfileId
 * @returns boolean
 */
export const hasCompanyProfileId = (companyProfileId) => !!Object.values({ ...getCompanyProfileIds() }).includes(companyProfileId);

/**
 * channelGroupRules
 *
 */

export function channelGroupRules(grupoCanalID) {
    switch (grupoCanalID) {
        case GRUPO_CANAL_IDS.TELEFONICA:
            return {
                isChannelGroup: true,
            };
        case GRUPO_CANAL_IDS.LIBERBANK:
        case GRUPO_CANAL_IDS.BBVA:
        case GRUPO_CANAL_IDS.SELECTRA:
        case GRUPO_CANAL_IDS.BANCOSABADELL:
        case GRUPO_CANAL_IDS.OUTBOUND:
            return {
                isChannelGroup: true,
            };

        case GRUPO_CANAL_IDS.ESE:
            return {
                isChannelGroup: true,
            };
        default:
            return {
                isChannelGroup: false,
            };
    }
}
/**
 * userQuestions
 */
export function userQuestions(inputs) {
    //let _inputs = clone(buildInputs(inputs, options));
    let _questions = clone(buildUserQuestions(inputs));

    return {
        //inputs: _inputs,
        questions: _questions,
    };
}

const buildUserQuestions = () => {
    const questions = clone(EDIT_USER_FORM_QUESTIONS);

    return questions;
};

export const companyIdToCompanyCode = (companyId) => {
    switch (parseInt(companyId)) {
        case COMPANY_IDS.EDP_PT:
            return 'edp_pt';
        case COMPANY_IDS.EDP_ES:
            return 'edp_es';
        case COMPANY_IDS.DYNAMIC:
            return 'dynamic';
        case COMPANY_IDS.ESB:
            return 'esb';
        case COMPANY_IDS.EDP_IT:
            return 'edp_it';
        case COMPANY_IDS.EFZ:
            return 'efz';
        case COMPANY_IDS.EDP_PL:
            return 'edp_pl';
        case COMPANY_IDS.EDP_BR:
            return 'edp_br';
        case COMPANY_IDS.TELEFONICA:
            return 'tlf';
        case COMPANY_IDS.NRG:
            return 'grange';
        case COMPANY_IDS.MONS:
            return 'mons';
        case COMPANY_IDS.SUNSEAP:
            return 'edp_sg';
        case COMPANY_IDS.PPC:
            return 'ppc';
        case COMPANY_IDS.ROMANDE:
            return 'romande';
        case COMPANY_IDS.BASE_UK_B2C:
            return 'base_ukb2c';
        case COMPANY_IDS.BASE_UK_B2B:
            return 'base_ukb2b';
        case COMPANY_IDS.FREEDOM:
            return 'freedom';
        case COMPANY_IDS.SECON:
            return 'secon';
        case COMPANY_IDS.THUEGA:
            return 'thuega';
        default:
            return 'N.A.';
    }
};
