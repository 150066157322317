import { themeBase } from 'constants/styles/global';
import styled from 'styled-components';

export const StyledRedefinePasswordDialog = styled.form`
    width: 600px;
    .dialog-title {
        padding: 1.5rem 2rem 0 2rem;

        h2 {
            font-size: 1.6rem;
        }

        hr {
            opacity: 1;
            margin: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            width: 70px;
            height: 2px;
            background: var(--primary-color);
        }
    }

    .dialog-body {
        padding: 1.5rem 3rem;
    }

    .dialog-buttons {
        padding: 1.5rem 1rem;
    }
    .inputs-container-main {
        margin-top: 1.5rem;
    }
    .input-container {
        display: flex;
        flex-flow: column nowrap;
        padding: 0 1rem 1rem;
        position: relative;
        margin-bottom: 0.5rem;

        label {
            margin-bottom: 0.5rem;
            font-weight: ${themeBase.fontWeight.bold};
            color: var(--text-color);
            font-size: 0.875rem;
            flex: 1 1;
        }
    }

    .info-text {
        display: flex;
        margin-bottom: 1.5rem;
        text-align: center;
        padding: 0 1rem;

        svg {
            fill: var(--text-color-secondary);
            height: 1rem;
            width: auto;
        }

        span {
            font-size: 0.75rem;
            font-weight: ${themeBase.fontWeight.light};
        }
    }

    .reset-pwd-title {
        font-size: 0.5;
        font-weight: ${themeBase.fontWeight.bold};
        margin-bottom: 1rem;
    }
    .dialog-bullets {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;

        .dialog-bullet {
            display: flex;
            flex-direction: row;
            column-gap: 0.5rem;
            align-items: center;
            font-size: 0.875rem;
        }
        .bullet-icon {
            svg {
                width: 16px;
                height: 16px;
            }
        }
    }
`;
