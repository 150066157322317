import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import GenericDialog from 'components/core/dialogs/GenericDialog';
import IntlMessages from 'components/util/IntlMessages';
import CookieConsent from 'components/Cookies/CookieConsent';
// import Alert from "components/@efz/Alert";
import useCookies from 'hooks/cookies';
import { routeToCookiePolicy } from 'services/cookies';
import { isS2CPlatformVersion } from 'services/settings';
import { useAuthStore } from 'store/auth';
import { Button } from '@save2compete/efz-design-system';
import { StyledCustomizeCookiesDialog } from 'components/OArea/StylesCookie';

const CustomizeCookiesDialog = ({ isOpen, setIsOpen, cookieBannerHandler }) => {
    const history = useHistory();
    const isS2CVersion = isS2CPlatformVersion();

    const { isUserAuthenticated } = useAuthStore();
    const {
        setCookie,
        // getCookie,
        // removeGACookies,
        // CONSENT_COOKIE, TODO: Este não é o ´Essential cookies´ e que deveria estar sempre on?
        PLATFORM_CUSTOMIZATION_COOKIE,
        PERSONALIZED_ADVERTISING_COOKIE,
    } = useCookies();

    // const _personalizedAdvertisingCookie = getCookie(PERSONALIZED_ADVERTISING_COOKIE) ?? false;
    // const _consentCookie = getCookie(CONSENT_COOKIE);

    const [showPolicy, setShowPolicy] = useState(false);
    // const [showWarning, setShowWarning] = useState(false);
    const [policyTypeToShow, setPolicyTypeToShow] = useState(null);

    // const [personalizedAdvertising, setPersonalizedAdvertising] = useState(_personalizedAdvertisingCookie);

    const showPolicyHandler = (type = null) => {
        if (type) {
            if (!isUserAuthenticated) {
                setPolicyTypeToShow(type);
            } else {
                setIsOpen(false);
                routeToCookiePolicy(history, type);
            }
        } else {
            setShowPolicy(false);
        }
    };

    useEffect(() => {
        if (policyTypeToShow) {
            setShowPolicy(true);
        } else {
            setShowPolicy(false);
        }
    }, [policyTypeToShow]);

    useEffect(() => {
        if (!showPolicy) {
            setPolicyTypeToShow(null);
        }
    }, [showPolicy]);

    const switchHandler = () => {
        setCookie(PLATFORM_CUSTOMIZATION_COOKIE, true, 365);
        setCookie(PERSONALIZED_ADVERTISING_COOKIE, true, 730);

        /* if (!personalizedAdvertising) {
            removeGACookies()
        } */

        setIsOpen(false);
        cookieBannerHandler?.accept();
        window.location.reload();
    };

    /* useEffect(() => {
        if ((!personalizedAdvertising && _personalizedAdvertisingCookie)) {
            setShowWarning(true)
        } else {
            setShowWarning(false)
        }
    }, [personalizedAdvertising, _personalizedAdvertisingCookie]); // eslint-disable-line react-hooks/exhaustive-deps */

    const dialogTitle = <IntlMessages id="label.cookies.privacySettings" />;

    const dialogBody = (
        <StyledCustomizeCookiesDialog>
            <section className="privacy-section">
                <div className="privacy-section-content">
                    <div>
                        <IntlMessages id="dialog.privacy.1" />
                        <br className={`${isS2CVersion ? 'isS2C' : ''}`} />
                        <ul>
                            <li>
                                <IntlMessages id="dialog.privacy.1.1" />
                            </li>
                            <li>
                                <IntlMessages id="dialog.privacy.1.2" />
                            </li>
                            <li>
                                <IntlMessages id="dialog.privacy.1.3" />
                            </li>
                        </ul>
                        <IntlMessages id="dialog.privacy.2.1" whitespaceEnd />{' '}
                        <span className="link" onClick={() => showPolicyHandler('privacy-policy')}>
                            <IntlMessages id="label.here" />
                        </span>
                        <IntlMessages id="dialog.privacy.2.2" whitespaceEnd />{' '}
                        <span className="link" onClick={() => showPolicyHandler('cookies-policy')}>
                            <IntlMessages id="label.here" />
                        </span>
                        .
                    </div>
                    <div className="label-category" />
                </div>
            </section>

            <section className="privacy-section">
                <div className="privacy-section-title">
                    <div className="privacy-section-title-first-line">
                        <span className={`${isS2CVersion ? 'isS2C' : ''}`}>
                            <IntlMessages id="dialog.privacy.essential" />
                        </span>
                        <span className="label-category">
                            <IntlMessages id="label.alwaysOn" />
                        </span>
                    </div>
                    <hr />
                </div>
                <div className="privacy-section-content">
                    <div>
                        <IntlMessages id="dialog.privacy.essential.1" />
                    </div>
                    <div className="label-category" />
                </div>
            </section>

            <section className="privacy-section">
                <div className="privacy-section-title">
                    <div className="privacy-section-title-first-line">
                        <span className={`${isS2CVersion ? 'isS2C' : ''}`}>
                            <IntlMessages id="dialog.privacy.customization" />
                        </span>
                        <span className="label-category">
                            <IntlMessages id="label.alwaysOn" />
                        </span>
                    </div>
                    <hr />
                </div>
                <div className="privacy-section-content">
                    <div>
                        <IntlMessages id="dialog.privacy.customization.1" />
                        <br />
                        <ul>
                            <li>
                                <IntlMessages id="dialog.privacy.customization.1.1" />
                            </li>
                            <li>
                                <IntlMessages id="dialog.privacy.customization.1.2" />
                            </li>
                        </ul>
                        <IntlMessages id="dialog.privacy.customization.2" />
                    </div>
                    <div className="label-category" />
                </div>
            </section>

            {/* <section className='privacy-section'> // Removed
                <div className='privacy-section-title'>
                    <div className='privacy-section-title-first-line'>
                        <span><IntlMessages id='dialog.privacy.add' /></span>
                        <div className='label-category'>
                            <Switch
                                checked={personalizedAdvertising}
                                onChange={(e) => setPersonalizedAdvertising(e.target.checked)}
                            />
                        </div>
                    </div>
                    <hr />
                </div>
                <div className='privacy-section-content'>
                    <div>
                        <IntlMessages id='dialog.privacy.add.1' />
                        <br />
                        <ul>
                            <li><IntlMessages id='dialog.privacy.add.1.1' /></li>
                            <li><IntlMessages id='dialog.privacy.add.1.2' /></li>
                            <li><IntlMessages id='dialog.privacy.add.1.3' /></li>
                            <li><IntlMessages id='dialog.privacy.add.1.4' /></li>
                            <li><IntlMessages id='dialog.privacy.add.1.5' /></li>
                        </ul>
                        <IntlMessages id='dialog.privacy.add.2' />
                    </div>
                    <div className='label-category' />
                </div>
                {
                    showWarning && _consentCookie && (
                        <Alert
                            severity='warning'
                            className='mt-2'
                        >
                            <span><IntlMessages id='dialog.privacy.alert' /></span>
                        </Alert>
                    )
                }
            </section> */}
        </StyledCustomizeCookiesDialog>
    );

    const dialogButtons = (
        <div className="w-100 d-flex justify-content-center privacy-dialog-actions">
            <Button type={'button'} onClick={switchHandler} dataTestId="privacy-dialog-consent">
                <IntlMessages id="label.consent" />
            </Button>
        </div>
    );

    return (
        <>
            {!showPolicy && (
                <GenericDialog
                    open={isOpen}
                    handleClose={() => setIsOpen(false)}
                    title={dialogTitle}
                    body={dialogBody}
                    buttons={dialogButtons}
                    hasLine
                    className={'cookies-dialog'}
                />
            )}
            {showPolicy && <CookieConsent type={policyTypeToShow} close={showPolicyHandler} showPolicyHandler={showPolicyHandler} />}
        </>
    );
};

export default memo(CustomizeCookiesDialog);
