import { styled } from 'styled-components/macro';
import * as SolarPvStyles from 'constants/styles/solarpv';
import { Dialog } from '@mui/material';

export const DialogStyle = styled(Dialog)`
    .close-button-v3 {
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;
    }
    .close-btn-fullscreen {
        margin-right: 2rem;
    }
`;

export const RemoteDialogStyle = styled(DialogStyle)`
    .dialog-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.813rem 2rem;
        margin-bottom: 2rem;
        background-color: rgba(245, 245, 245, 1);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);

        &-left {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 2rem;

            h2 {
                font-size: 1.125rem;
                font-weight: ${SolarPvStyles.fontWeightSemibold};
                margin-bottom: 0;
                color: var(--primary-color);
            }
        }
    }

    .dialog-tabs {
        flex-wrap: nowrap;
        background-color: inherit !important;
        box-shadow: inherit !important;
        margin-bottom: 2rem;
        max-height: 44px;

        &-unselected {
            border-bottom: 2px solid ${SolarPvStyles.colorNeutral200} !important;
            background-color: ${SolarPvStyles.colorNeutral100} !important;
            color: #000000 !important;
            box-sizing: border-box !important;
        }

        &-selected {
            color: var(--primary-color) !important;
            font-weight: ${SolarPvStyles.fontWeightSemibold} !important;
            background-color: var(--primary-color-bleached) !important;
            box-sizing: border-box !important;
            border-bottom: 2px solid var(--primary-color) !important;
        }

        .tab-title-container {
            width: 100%;
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;

            > :first-child {
                flex: 1 1 auto;
            }
        }

        .MuiTabs-flexContainer {
            justify-content: space-between;

            button {
                font-size: 0.875rem;
                text-transform: none !important;
                height: 3rem;
            }
        }

        .MuiTabs-indicator {
            background-color: var(--primary-color) !important;
        }
    }

    .dialog-content {
        padding: 0 83px 2rem;

        .MuiTabPanel-root {
            padding: 0;

            .tab-container {
                display: flex;
                flex-flow: column nowrap;
                row-gap: 1rem;

                .input-container {
                    display: flex;
                    flex-flow: column nowrap;
                    flex: 0 0 33.33333%;
                    max-width: 33.33333%;
                    position: relative;
                    margin-bottom: 0.5rem;
                    font-size: 0.875rem;

                    label {
                        margin-bottom: 0.5rem;
                        font-weight: ${SolarPvStyles.fontWeightSemibold};
                        color: var(--text-color);
                        flex: 1;
                    }

                    &.static-input {
                        label {
                            margin-bottom: 0.25rem !important;
                            color: ${SolarPvStyles.colorNeutral600} !important;
                        }
                    }
                }
            }
        }
    }
`;

export const ChartsDialogStyle = styled(DialogStyle)`
    z-index: 1000 !important;

    .MuiDialog-paper {
        height: auto;
        max-height: unset;
        min-height: 330px;
        min-width: 900px;
    }

    .graphs-title {
        padding: 1.5rem 3rem;
        margin-bottom: 0.5rem;

        h2 {
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 0.4rem;
            color: var(--text-color);
        }

        hr {
            opacity: 1;
            margin: 0;
            width: 70px;
            height: 2px;
            background: var(--primary-color);
        }

        .close-button {
            position: absolute;
            right: 1rem;
            top: 1rem;
        }
    }

    .graphs-content {
        overflow-y: hidden;
        padding: 0 3rem 1.5rem !important;

        .summary-section-graphs {
            display: flex;
            flex-flow: column nowrap;
            flex: 1 1 100%;
            max-height: 100%;
            padding: 0 1rem;

            /* @include media-breakpoint-down(lg) {
                min-height: 350px;
            }

            @media (max-height: 899.98px) {
                flex: 1 1 calc(45% + 30px);
                max-height: calc(45% + 30px);
            }

            @media (min-height: 900px) {
                flex: 1 1 50%;
                max-height: 50%;
            } */

            .graph-buttons {
                height: fit-content;
                display: flex;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                grid-gap: 1rem;
                margin-bottom: 0.5rem;

                .graph-button {
                    flex: 1;
                    position: relative;

                    &-tooltip {
                        position: absolute;
                        right: 0.5rem;
                        top: 0.5rem;

                        svg {
                            margin: 0;
                        }
                    }

                    .icon-alert {
                        &-info:hover {
                            svg path {
                                fill: ${SolarPvStyles.colorInfo};
                            }
                        }

                        &-error:hover {
                            svg path {
                                fill: ${SolarPvStyles.colorError};
                            }
                        }
                    }
                }
            }

            .input-container {
                display: flex;
                flex-flow: column nowrap;

                label {
                    margin-bottom: 0.5rem;
                    font-weight: ${SolarPvStyles.fontWeightSemibold};
                    color: var(--text-color);
                    font-size: 0.875rem;
                    flex: 1;
                }
            }

            .charts-filters {
                justify-content: flex-end;
                min-height: 69px;

                .MuiInputBase-root {
                    min-width: initial;
                    max-height: initial;

                    .MuiSelect-select {
                        padding: 0.625rem 2.5rem 0.625rem 0.75rem !important;
                        font-size: 0.875rem;
                        line-height: normal;
                    }
                }
            }

            .charts-container {
                height: 400px;
                width: 100%;

                .svg-disabled {
                    path {
                        fill: var(--disabled-color);
                    }
                }

                &.dark {
                    filter: brightness(2);
                }

                .select-dropdown {
                    .MuiOutlinedInput-root {
                        height: 25px;
                    }

                    .MuiSelect-select {
                        padding: 6px 20px 6px 8px;
                        font-size: 0.75rem;
                        line-height: 13px;
                        min-height: inherit;
                    }

                    .MuiSelect-iconOutlined {
                        right: 8px;
                        width: 0.75rem;
                    }
                }
            }
        }
    }
`;

export const ClientsDesignDialogStyle = styled(DialogStyle)`
    .MuiDialogContent-root {
        img {
            width: 28.125rem;
            height: auto;
        }
    }
`;

export const ConfirmationDialogStyle = styled(DialogStyle)`
    .MuiDialog-paper {
        padding: 2.31rem 2.19rem;
        row-gap: var(--spacing-200, 0.75rem);
    }

    .MuiDialogTitle-root {
        width: 100%;
        padding: 0;
        color: var(--primary-color);
        font-family: ${SolarPvStyles.fontFamilyAccent};
        font-size: ${SolarPvStyles.fontSize500};
        font-weight: ${SolarPvStyles.fontWeightNormal};
        line-height: normal;
        text-align: center;
    }

    .close-button {
        position: absolute;
        top: var(--spacing-600, 1.5rem);
        right: var(--spacing-600, 1.5rem);
        padding: 0;
    }

    .MuiDialogContent-root {
        display: flex;
        flex-flow: column nowrap;
        row-gap: var(--spacing-600, 1.5rem);
        padding: 0;

        .MuiDialogContentText-root {
            margin: 0;
            color: var(--text-color);
            font-family: ${SolarPvStyles.fontFamilyBase};
            font-size: ${SolarPvStyles.fontSize400};
            font-weight: ${SolarPvStyles.fontWeightNormal};
            line-height: normal;
            text-align: center;
        }

        .MuiDialogActions-root {
            justify-content: center;
            column-gap: 2.5rem;
            padding: 0;
        }
    }
`;

export const StyledFullScreenDialog = styled(DialogStyle)`
    .dialog-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.813rem 2rem;
        background-color: ${SolarPvStyles.colorNeutral100};
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);

        &-left {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 2rem;

            h2 {
                font-size: 1.125rem;
                font-weight: ${SolarPvStyles.fontWeightSemibold};
                margin-bottom: 0;
                color: var(--primary-color);
            }
        }
    }
`;
