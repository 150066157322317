import { ReactComponent as ErrorBackground } from 'assets/@efz/backgrounds/error-background.svg';
import Loading from 'components/core/Loading';
import { StyledErrorBoundary } from 'components/errors/ErrorBoundary/StylesErrorBoundary';
import IntlMessages from 'components/util/IntlMessages';
import useLogout from 'hooks/auth/useLogout';
import { useUserStore } from 'store/user';

const Logout = () => {
    const { user } = useUserStore();

    useLogout();

    return (
        <StyledErrorBoundary className="clock">
            <ErrorBackground className="error-boundary-background" preserveAspectRatio="none" />
            <section>
                <Loading />
                <h2>
                    {user?.is_logged_by_sso ?
                        <IntlMessages id="page.logout.redictecting" />
                    :   <IntlMessages id="page.logout.loggingOut" />}
                </h2>
            </section>
        </StyledErrorBoundary>
    );
};

export default Logout;
