import { getCounties, postSendToCRMByOA, postSimulation } from 'api/products/solarpvSimple';
import { INVESTMENT_OPTIONS, spvSimpleActions } from 'constants/products/solarpvSimple';
import { DEFAULTS_PROPS_MAP } from 'constants/products/spvPro';
import { SPVSimpleContext } from 'contexts/products/solarpvSimple/solarpvSimpleContext';
import useStaticMap from 'hooks/utils/useStaticMap';
import { StatusCodes } from 'http-status-codes';
import { useCallback, useContext, useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import { getCountyFallback } from 'services/facilityPro/facilityData';
import { getGeoCodeInfo } from 'services/gMap';
import {
    getPayloadToProposalB2B,
    getPayloadToProposalB2C,
    getPayloadToSimsB2B,
    getPayloadToSimsB2C,
} from 'services/products/solarpvSimple';
import { isEnvDevFlag } from 'services/settings';
import { useFeatureFlags } from 'store/featureFlags';

//#region AbortControllers+
let getSimsAbortController;
let getCoordinatesCFAbortController;
let postSPVUsefulAreaAbortController;
let postSimulationWithBatteryAbortController;
let postSimulationAbortController;
let getCountiesAbortController;
let postSendToCRMByOAAbortController;

function cleanup() {
    getSimsAbortController && getSimsAbortController.abort();
    getCoordinatesCFAbortController && getCoordinatesCFAbortController.abort();
    postSPVUsefulAreaAbortController && postSPVUsefulAreaAbortController.abort();
    postSimulationWithBatteryAbortController && postSimulationWithBatteryAbortController.abort();
    postSimulationAbortController && postSimulationAbortController.abort();
    getCountiesAbortController && getCountiesAbortController.abort();
    postSendToCRMByOAAbortController && postSendToCRMByOAAbortController.abort();
}
//#endregion AbortControllers

const useSimulation = () => {
    const { featureFlags } = useFeatureFlags();

    let { fetchPostFileUploadImgContract } = useStaticMap();
    const {
        spvSimpleState: { companyProfileId, isOArea, productID, inputs, options, isRequestingSims, pvOptions },
        spvSimpleDispatchHandler: dispatch,
        userLocale,
        isB2C,
        setSelectedCard,
    } = useContext(SPVSimpleContext);

    const { battery } = options;

    const [isRequestingProposal, setIsRequestingProposal] = useState(false);
    const [hasError, setHasError] = useState(null);
    const [isProposalSent, setIsProposalSent] = useState(false);

    const fetchSimulationsOnHandler = useCallback(async () => {
        try {
            setHasError(null);
            setSelectedCard(null);
            if (!isRequestingSims) dispatch(spvSimpleActions.SET_IS_REQUESTING_SIMS, true);
            let simsPayload =
                isB2C ?
                    getPayloadToSimsB2C({
                        productID,
                        isOArea,
                        inputs,
                        battery,
                        pvOptions,
                    })
                :   getPayloadToSimsB2B({
                        productID,
                        isOArea,
                        inputs,
                        battery,
                        hasBattery: true,
                        pvOptions,
                    });

            postSimulationAbortController && postSimulationAbortController.abort();
            postSimulationAbortController = new AbortController();
            postSimulationWithBatteryAbortController && postSimulationWithBatteryAbortController.abort();
            postSimulationWithBatteryAbortController = new AbortController();

            let requests = [
                postSimulation(simsPayload, isOArea, postSimulationAbortController), //sims pv+bateria, (B2c & B2B)
                isB2C ? null : (
                    postSimulation(
                        getPayloadToSimsB2B({
                            productID,
                            isOArea,
                            inputs,
                            battery,
                            hasBattery: false,
                        }),
                        isOArea,
                        postSimulationWithBatteryAbortController
                    )
                ), //sims pv+bateria,
            ];
            let responses = await Promise.all(requests);

            // validate status (B2C+B2B)
            if (
                (isB2C && responses?.[0]?.status !== StatusCodes.OK) ||
                (!isB2C && responses?.filter((el) => el?.status !== StatusCodes.OK)?.length === 2)
            )
                throw new Error(JSON.stringify(responses));

            /* B2C */
            if (isB2C && responses?.[0]?.data.data?.kits?.length > 0) {
                dispatch(spvSimpleActions.SET_SIMULATION_SUCCESS, {
                    simsPayload,
                    simulations: responses?.[0]?.data?.data,
                });
            } /* B2B */ else if (!isB2C && (!!responses?.[0]?.data?.data || !!responses?.[1]?.data?.data)) {
                let simulations = [];

                if (responses?.[0]?.data?.data)
                    simulations.push({
                        id: 1,
                        ...responses?.[0]?.data?.data,
                    });
                if (responses?.[1]?.data?.data)
                    simulations.push({
                        id: 2,
                        ...responses?.[1]?.data?.data,
                    });

                dispatch(spvSimpleActions.SET_SIMULATION_SUCCESS, {
                    simsPayload,
                    simulations,
                });
            } else {
                setHasError('page.spvSimple.simulation.error.kits');
                dispatch(spvSimpleActions.SET_IS_REQUESTING_SIMS, false);
            }
        } catch (error) {
            setHasError('page.spvSimple.simulation.error.kits');
            dispatch(spvSimpleActions.SET_IS_REQUESTING_SIMS, false);
        }
    }, [options?.rangeSelected, options?.structures]); //eslint-disable-line

    const requestSimulationProposal = async ({ solution, contacts, grant }) => {
        try {
            setHasError(false);
            setIsRequestingProposal(true);

            //#region get county_id
            let {
                facility: { location },
            } = inputs;
            getCountiesAbortController && getCountiesAbortController.abort();
            getCountiesAbortController = new AbortController();
            //TODO: necessario guardar os dados no reducer. dos counties
            let rspCounties = await getCounties({}, true, getCountiesAbortController);
            let counties = rspCounties?.data.data ?? [];
            Geocode.setApiKey(DEFAULTS_PROPS_MAP.settings.apiKey);
            Geocode.setLanguage('en-GB');
            Geocode.setRegion(DEFAULTS_PROPS_MAP.country?.[userLocale].region);
            let resultsGeoCodeEngGB = await Geocode.fromAddress(location.address);
            let { county_abrev } = getGeoCodeInfo([], resultsGeoCodeEngGB?.results, companyProfileId, {
                evt: null,
                marker: null,
            });
            let countySelected = counties?.find((el) => el.abrev === county_abrev)?.id;
            let county_id = countySelected ?? getCountyFallback(companyProfileId, counties)?.id;
            Geocode.setApiKey(null);
            //#endregion county_id

            let img_contract_filename = await fetchPostFileUploadImgContract(
                {
                    ...inputs.center,
                    image_name: 'img-contract',
                },
                inputs.roofCoordinates,
                isOArea
            );

            let { cardProps } = solution;
            if (cardProps?.investment_type_id === INVESTMENT_OPTIONS.CASH.id) cardProps = {};

            let proposalPayload =
                isB2C ?
                    getPayloadToProposalB2C(contacts, {
                        inputs: {
                            ...inputs,
                            remuneration_type_id: solution.remuneration_type_id,
                            img_contract_filename,
                            facility: {
                                ...inputs.facility,
                                location: {
                                    ...location,
                                    county_id,
                                },
                            },
                            cardProps,
                        },
                        isOArea,
                        battery,
                        solution,
                        grant,
                        isInstallments: isOArea && Object.keys(cardProps).length > 0,
                    })
                :   getPayloadToProposalB2B(contacts, {
                        inputs: {
                            ...inputs,
                            remuneration_type_id: solution.remuneration_type_id,
                            img_contract_filename,
                            facility: {
                                ...inputs.facility,
                                location: {
                                    ...location,
                                    county_id,
                                },
                            },
                        },
                        isOArea,
                        battery,
                        solution,
                    });

            postSimulationAbortController && postSimulationAbortController.abort();
            postSimulationAbortController = new AbortController();
            let rspSims = await postSimulation(proposalPayload, isOArea, postSimulationAbortController);
            if (rspSims?.status !== StatusCodes.OK) {
                throw new Error(JSON.stringify(rspSims?.data));
            }

            // send to crm
            if (isEnvDevFlag(featureFlags['fe-2397']) && !rspSims.data?.data?.can_send_to_crm) {
                postSendToCRMByOAAbortController && postSendToCRMByOAAbortController.abort();
                postSendToCRMByOAAbortController = new AbortController();
                await postSendToCRMByOA({ processId: rspSims.data?.data?.sim_id }, postSendToCRMByOAAbortController);
            }

            dispatch(spvSimpleActions.SUCCESSFUL_REQUEST, rspSims?.data?.data);
            setIsProposalSent(true);
            setIsRequestingProposal(false);
        } catch (error) {
            setIsRequestingProposal(false);
            setHasError(true);
        }
    };

    useEffect(() => {
        if (isRequestingSims) fetchSimulationsOnHandler();
        return () => {
            cleanup();
        };
    }, [fetchSimulationsOnHandler]); //eslint-disable-line

    return {
        requestSimulationProposal,
        isProposalSent,
        isRequestingProposal,
        hasError,
    };
};

export default useSimulation;
