import { styled } from 'styled-components';
import Dialog from '@mui/material/Dialog';
import { media } from 'styles/utils/mediaQueries';
import { themeBase } from 'constants/styles/global';

export const StyledPrivacyDialog = styled(Dialog)`
    .MuiPaper-root {
        position: relative;
        padding: 2rem 3.5rem;
        min-width: 46rem; // 736px

        ${media.min.tablet`
            min-width: 50rem;
        `}

        ${media.tablet`
                    min-width: 25rem; // 400px
            padding: 2rem;`}

        ${media.mobile`
            min-width: 18rem; // 288px
            padding: 2rem;
        `}

        .close-dialog-icon {
            position: absolute;
            right: 1.5rem;
            top: 1.5rem;
        }
    }

    .privacy-data-dialog-title {
        padding: 0 !important;
        margin-bottom: 1.5rem !important;

        h2 {
            font-size: 1rem;
            font-weight: ${themeBase.fontWeight.semibold};
            line-height: 24px;
            color: var(--primary-color);
            margin: 0;
            margin-bottom: 0.5rem;
        }

        hr {
            margin: 0;
            color: var(--primary-color);
        }
    }

    .privacy-data-dialog-body {
        a {
            color: var(--secondary-color);
            text-decoration: underline;
        }

        &-bullets {
            padding: 1rem;
            background-color: ${themeBase.color.neutral100};
            border: 1px solid ${themeBase.color.neutral200};
            border-radius: 5px;

            li {
                margin-bottom: 1.5rem;
            }
        }

        &-info {
            margin-top: 0.75rem;
        }
    }
`;
