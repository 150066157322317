import Dialog from '@mui/material/Dialog';
import { themeBase } from 'constants/styles/global';
import { styled } from 'styled-components';

export const StyledGenericDialog = styled(Dialog)`
    .MuiDialog-paper {
        min-width: 784px;

        @media only screen and (max-width: 768px) {
            min-width: 410px;
        }
    }

    .MuiDialogTitle-root {
        padding: 2rem 3.75rem 1.25rem 3.75rem;
    }

    .MuiDialogContent-root {
        padding: 0 3.75rem;

        @media only screen and (max-width: 768px) {
            padding: 1.5rem;
        }
    }

    .MuiDialogActions-root {
        padding: 0;
        margin: 2.5rem 0;
    }

    .generic-dialog-header {
        font-weight: ${themeBase.fontWeight.semibold};
        font-size: 1rem;

        &-line {
            height: 3px;
            background-color: var(--primary-color);
            width: 4.375rem;
            border-radius: 1rem;

            &.isS2C {
                background-color: ${themeBase.color.efzRed600};
            }
        }
    }

    .generic-dialog-close {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
    }

    &.spv-client-max-dialog {
        .MuiTypography-root {
            min-width: 9.5rem;
        }
    }
`;
