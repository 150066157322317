import { createContext, useCallback, useContext, useMemo, useRef, useState, useReducer, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import clone from 'fast-copy';
import { useSelector } from 'react-redux';
import {
    SYSTEM_SETTINGS_GROUPS,
    BATTERY_COMPATIBLE_RANGES_ID,
    SPV_ERROR_TAGS,
    SPV_INFO_TAGS,
    DEFAULTS_PROPS_MAP,
    REMUNERATION_MODELS_TYPE,
} from 'constants/products/spvPro';
import { getFacilityID, getFacilityInfo, getFacilityLatitude, getFacilityLongitude } from 'redux/selectors/facility';
import { getSPVIsIncreasePower, getProductId, getProposalDetails } from 'redux/selectors/product';
import { getLocaleCode } from 'redux/selectors/settings';
import { isDefined, isFieldDefined, isNumberDefined } from 'services/util/auxiliaryUtils';
import { getBrand } from 'services/settings';
import FormatNumber from 'components/util/FormatNumber';
import { toggleExclusionsDrawingMode } from 'services/products/solarpv';
import { initialSPVProReducer, spvProReducer } from './spvProReducer';
import { UserContext } from 'contexts/userContext';
import { debounce } from 'lodash';
import IntlMessages from 'components/util/IntlMessages';
import { useHistory } from 'react-router-dom';
import { BASENAME_URL_FACILITY } from 'constants/settings';
import { RemoteProvider } from './remote/remoteContext';
import { isSomeModeSelected } from 'services/products/solarpv/solarpvV3';
import { SolarPvToolbarOptions, SpvActions } from 'constants/products/solarpv';
import { TSpvContext, IHasChangedExclusionInputConfig, IToolbarData } from 'interfaces/products/solarpv/index';
import { initialSpvContextValues } from 'interfaces/products/solarpv/state/context';
import { useProjects } from 'store/solutions/projects';
import { useUserStore } from 'store/user';
import { useProposalStore } from 'store/proposal';
import { AVAILABLE_USER_LOCALES } from 'constants/user';
import { Nullable } from 'types/utils';
import { ReduxContext } from 'contexts/redux/reduxContext';
import { useSpvSimple } from 'hooks/products/solarpv/simple/useSpvSimple';

const SPVProContext = createContext<TSpvContext>(initialSpvContextValues);
SPVProContext.displayName = 'efz-SPVProContext';

const SPVProContextProvider: React.FC<React.ReactNode> = ({ children }) => {
    const facilityID = useSelector((state: any) => getFacilityID(state?.facility));
    const {
        client: { risco_energia_id },
    } = useContext(ReduxContext);

    const { module } = useParams();
    const history = useHistory();
    const { user } = useUserStore();
    const userLocale = user?.locale ?? AVAILABLE_USER_LOCALES.enGB;
    // state global
    const proposalDetails = useSelector((state: any) => getProposalDetails(state?.product));
    const facilityLatitude = useSelector((state: any) => getFacilityLatitude(state?.facility));
    const facilityLongitude = useSelector((state: any) => getFacilityLongitude(state?.facility));
    const productID = useSelector((state: any) => getProductId(state?.product) ?? null);
    const is_power_increase = useSelector((state: any) => getSPVIsIncreasePower(state?.product));
    const facilityInfo: any = useSelector((state: any) => getFacilityInfo(state?.facility) ?? null);
    const {
        configs,
        actions: { setSelected },
    } = useProjects();

    // context's
    const { isB2C, userTypeID, companyProfileId, userChannelIDGroup } = useContext(UserContext);

    // useReducer
    // @ts-ignore
    const [solarpvState, solarpvDispatch] = useReducer(spvProReducer, initialSPVProReducer);
    const [saved, setSaved] = useState(true);
    const [blockerPath, setBlockerPath] = useState({
        location: null,
        submit: () => {},
        type: null,
    });
    const solarpvDispatchHandler = (type, _payload) => {
        //@ts-ignore
        solarpvDispatch({ type, payload: { ..._payload, isV3: !isDefined(module) } });
    };

    // state local
    const [dispatchEvt, setDispatchEvent] = useState({ evt: null, groupId: null, values: null }); //comunicação entre os eventos fo map e a app.
    const isValidGPU = useRef(true);
    const polygonGroups = useRef([]);
    const exclusionZonesPolygons = useRef([]);
    const googleMapsOverlay = useRef();
    const threejsOverlay = useRef();
    const groupTabRef = useRef(null);
    const productSelectionRef = useRef(null);
    const [threeJSMenu, setThreeJSMenu] = useState({
        type: null,
        visible: false,
        x: null,
        y: null,
        intersections: [],
        selected: false,
    });
    const [isOpenMap, setIsOpenMap] = useState(false);
    const [canDrawPolygon, setCanDrawPolygon] = useState(false);
    const [zoom, setZoom] = useState({
        current: DEFAULTS_PROPS_MAP.mapOptions.zoom,
        max: DEFAULTS_PROPS_MAP.mapOptions.zoom,
    });
    const [projectSummaryExpanded, setProjectSummaryExpanded] = useState(false);
    const [remunerationModelOptions, setRemunerationModelOptions] = useState(null);
    // remote
    const [hasChangesInRemote, setHasChangesInRemote] = useState(false);
    // exclusions
    const [hasChangesInExclusions, setHasChangesInExclusions] = useState(false);
    const [hasChangedExcusionInputs, setHasChangedExcusionInputs] = useState<Map<number, IHasChangedExclusionInputConfig>>(new Map());
    const [isDrawingExclusions, setIsDrawingExclusions] = useState(false);
    const [exclusionsRefs, setExclusionsRefs] = useState(null);
    const exclusionsAutoSplit = useRef([]);
    // buildings
    const [isDrawingBuildings, setIsDrawingBuildings] = useState(false);
    const [duplicateMode, setDuplicateMode] = useState(false);
    const [duplicatePolygonID, setDuplicatePolygonID] = useState(null);

    // alignment
    const [alignmentOverlay, setAlignmentOverlay] = useState(null);

    // changeAddress
    const [isChangeAddressOverlayOpen, setIsChangeAddressOverlayOpen] = useState(false);
    const [canDragMarker, setCanDragMarker] = useState(false);

    // split shape
    const [splitGroupPolylines, setSplitGroupPolylines] = useState([]);
    const [isSplitingShape, setIsSplitingShape] = useState(null);
    const [isDrawingPolyline, setIsDrawingPolyline] = useState(false);

    //#region Exclusions
    const isEditingExclusions = useMemo(
        () => solarpvState?.productSelection === SYSTEM_SETTINGS_GROUPS.EXCLUSIONS,
        [solarpvState?.productSelection]
    );
    const toggleExclusionsDrawingModeHandler = useCallback(
        () => toggleExclusionsDrawingMode(isDrawingExclusions, setIsDrawingExclusions),
        [isDrawingExclusions]
    );
    //#endregion

    // Overlays
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const [toolbarValue, setToolbarValue] = useState<Nullable<SolarPvToolbarOptions>>(null);
    const [toolbarData, setToolbarData] = useState<Nullable<IToolbarData>>(null);

    // Move group
    const [isMovingGroup, setIsMovingGroup] = useState(false);
    const [movingCounter, setMovingCounter] = useState(0);

    // Orientations
    const [isOrientationOverlayOpen, setIsOrientationOverlayOpen] = useState(false);
    const [isEditingOrientation, setisEditingOrientation] = useState(null);
    // Change Address | Marker
    const [marker, setMarker] = useState(null);

    const hasGroups = solarpvState.inputs?.areas?.length > 0;
    const isBatteryCompatibleRangeID =
        BATTERY_COMPATIBLE_RANGES_ID?.[companyProfileId] ?
            BATTERY_COMPATIBLE_RANGES_ID[companyProfileId].includes(solarpvState?.inputs?.range_id)
        :   true;
    const kpisSelected = isFieldDefined(solarpvState?.bundleSims) ? solarpvState?.bundleSims : solarpvState?.standaloneSims;

    useEffect(() => {
        // V3 only; this is a // FIXME: for later
        if (!isDefined(module)) {
            exclusionZonesPolygons.current = solarpvState.exclusionGroups;
        }
    }, [solarpvState?.exclusionGroups]); // eslint-disable-line

    const handleSimulations = useCallback((responses, newInputs, options) => {
        const { bundleSims, standaloneSims } = responses;
        if (options.project) setSelected(options.project.project.projecto_id);
        solarpvDispatchHandler(SpvActions.SET_SIMULATIONS, {
            simulations: standaloneSims /* TODO: remover */,
            standaloneSims,
            bundleSims,
            inputs: newInputs,
            newProject: options?.project ?? {},
        });
    }, []); // eslint-disable-line

    const setInfoTagsHandler = useCallback((newTags = [], isWarning = false) => {
        const currentMode = solarpvState.isSimpleMode ? solarpvState.simpleMode : solarpvState;
        //fazer o reset no momento do request da simulations...
        let cInfoTags = clone(currentMode.tags.info);
        let cErrorTags = clone(currentMode.tags.error);

        for (let i = 0; i < newTags.length; i++) {
            const { message = null, data = null, battery = false } = newTags[i];

            if (
                isDefined(message) &&
                ((isWarning && !cInfoTags.find((item) => item.tag === message)) ||
                    (!isWarning && !cErrorTags.find((item) => item.tag === message)))
            ) {
                let values = { ...data };
                if (
                    [
                        'TOTAL_PEAK_POWER_EXCEEDED',
                        'MIN_CONTRACTED_POWER_NOT_CHECKED',
                        'TOTAL_AREAS_EXCEED_LIMIT',
                        'INVERTER_POWER_EXCEEDED_REQUIRED_POWER',
                        'INVERTER_POWER_EXCEEDED_CONTRACTED_POWER',
                        'BREAKER_RATING_EXCEEDED_MCB_RATING',
                        SPV_ERROR_TAGS.BUSINESS_MODEL_WITHOUT_DATA,
                        SPV_ERROR_TAGS.INVERTER_POWER_EXCEEDED_MIN_KIT_POWER,
                        SPV_ERROR_TAGS.LECS_POWER_LIMIT_EXCEEDED,
                        SPV_ERROR_TAGS.MISSING_INVALID_REGION_GD_INFO,
                        SPV_INFO_TAGS.MIN_CLUSTER_POWER_LIMIT,
                        SPV_INFO_TAGS.NO_SOLUTION_WITHIN_LIMITS,
                        SPV_INFO_TAGS.INVERTER_POWER_EXCEEDED_CONTRACTED_POWER,
                        SPV_INFO_TAGS.INVERTER_POWER_EXCEEDED_REQUIRED_POWER,
                        SPV_INFO_TAGS.TOTAL_PANELS_NUMBER_EXCEED_MAX_LIMIT_INFO,
                    ].includes(message)
                ) {
                    values = {
                        // @ts-ignore
                        total_peak_power: <FormatNumber unit="kwp" number={data?.peak_power_limit} numberOfDecimalPlaces={1} />,
                        // @ts-ignore
                        peak_power_limit: <FormatNumber unit="kwp" number={data?.total_peak_power} numberOfDecimalPlaces={1} />,
                        // @ts-ignore
                        maxTecAreas: <FormatNumber unit="sm" number={data?.maxTecAreas} numberOfDecimalPlaces={0} />,
                        // @ts-ignore
                        totalAreas: <FormatNumber unit="sm" number={data?.totalAreas} numberOfDecimalPlaces={0} />,
                        // @ts-ignore
                        maxInverterPower: <FormatNumber unit="kw" number={data?.inverter_power} numberOfDecimalPlaces={0} />,
                        // @ts-ignore
                        maxContractedPower: <FormatNumber unit="kw" number={data?.contracted_power} numberOfDecimalPlaces={0} />,
                        // @ts-ignore
                        contracted_power: <FormatNumber unit="kw" number={data?.contracted_power} numberOfDecimalPlaces={0} />,
                        // @ts-ignore
                        min_contracted: <FormatNumber unit="kw" number={data?.min_contracted} numberOfDecimalPlaces={0} />,
                        // @ts-ignore
                        maxRequiredPower: <FormatNumber unit="kw" number={data?.required_power} numberOfDecimalPlaces={0} />,
                        // @ts-ignore
                        total_panels: <FormatNumber number={data?.total_panels} numberOfDecimalPlaces={0} />,
                        // @ts-ignore
                        num_panels: <FormatNumber number={data?.num_panels} numberOfDecimalPlaces={0} />,
                        // @ts-ignore
                        breaker_rating: <FormatNumber unit="a" number={data?.breaker_rating} numberOfDecimalPlaces={0} />,
                        // @ts-ignore
                        mcb_rating: <FormatNumber unit="a" number={data?.mcb_rating} numberOfDecimalPlaces={0} />,
                        // @ts-ignore
                        nominal_power: <FormatNumber unit="kw" number={data?.total_nominal_power} numberOfDecimalPlaces={0} />,
                        // @ts-ignore
                        nominal_power_limit: <FormatNumber unit="kw" number={data?.nominal_power_limit} numberOfDecimalPlaces={0} />,
                        facilityDetails: (
                            <span className="link" onClick={() => history.push(BASENAME_URL_FACILITY)}>
                                <IntlMessages id={`label.installationDetails`} />
                            </span>
                        ),
                    };
                }

                if (isWarning) cInfoTags.push({ tag: message, values, battery });
                else cErrorTags.push({ tag: message, values, battery });
            }
        }

        if (currentMode.tags.info.length === cInfoTags.length) cInfoTags = []; // No messages
        if (currentMode.tags.error.length === cErrorTags.length) cErrorTags = []; // No messages

        if (isWarning) {
            solarpvDispatchHandler(SpvActions.SET_TAGS, { type: 'info', tags: cInfoTags });
        } else {
            solarpvDispatchHandler(SpvActions.SET_TAGS, { type: 'error', tags: cErrorTags });
        }
    }, []); // eslint-disable-line

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setDispatchEvt = useCallback(
        debounce((data) => setDispatchEvent(data), 0),
        []
    ); // eslint-disable-line react-hooks/exhaustive-deps

    const { setSimpleModeStepOnHandler, cleanupSPVSimple, resetCacheSPVSimple } = useSpvSimple({
        userData: {
            companyProfileId: Number(companyProfileId),
            userLocale,
            userID: Number(user?.id),
        },
        clientData: {
            risco_energia_id: isNumberDefined(risco_energia_id) ? Number(risco_energia_id) : null,
        },
        facilityData: {
            facilityID: Number(facilityID),
            isB2C,
            potencia_contratada: Number(facilityInfo?.potencia_contratada),
            nivel_tensao_descricao: facilityInfo?.nivel_tensao_descricao,
            potencia_requisitada: Number(facilityInfo?.potencia_requisitada),
            tarifa_equivalente_ac:
                isNumberDefined(facilityInfo?.tarifa_equivalente_ac) ? Number(facilityInfo?.tarifa_equivalente_ac) : null,
        },
        options: {
            solarpvDispatchHandler,
            setInfoTagsHandler,
            productID: Number(productID),
            sInputs: solarpvState?.simpleMode?.inputs,
            facilityCoordinates: solarpvState?.simpleMode?.facilityCoordinates,
            roofType: solarpvState?.simpleMode?.roofType,
            roofByDrawingManual:
                solarpvState?.simpleMode?.buildingGroups?.[0]?.getPaths()?.map((p) => ({ lat: p.lat(), lng: p.lng() })) ?? [],
            isManualDrawing: solarpvState?.simpleMode?.isManualDrawing,
            roof: solarpvState?.simpleMode?.roof ?? [],
            structures: solarpvState?.structures,
            ranges: solarpvState?.ranges,
            pvOptions: solarpvState?.pvOptions,
            optimizationsData: solarpvState?.optimizationsData,
            kits:
                solarpvState?.pvKitsB2C?.find((el) => el.remuneration_id === solarpvState?.simpleMode?.inputs?.remuneration_type_id)
                    ?.kits ?? [],
        },
    });

    const solarpvContextValues: TSpvContext = {
        userLocale,
        isB2C,
        solarpvState: {
            ...solarpvState,
            setSimpleModeStepOnHandler,
            cleanupSPVSimple,
            resetCacheSPVSimple,
        },
        solarpvDispatch /* TODO: remover */,
        solarpvDispatchHandler,

        localeCode: useSelector((state: any) => getLocaleCode(state?.settings)) ?? 'en',
        companyProfileId,
        userTypeID,
        productID,
        coordinates_avg: { lat: facilityLatitude, long: facilityLongitude },
        userChannelIDGroup,
        facilityID,
        headBrand: getBrand() ?? null,
        imageName: useProposalStore.getState().img_contract_filename,
        is_power_increase /* TODO: remover */,
        isIncreasePower: is_power_increase,
        facilityInfo,
        potencia_contratada: facilityInfo?.potencia_contratada,
        potencia_requisitada: facilityInfo?.potencia_requisitada,
        nivel_tensao_descricao: facilityInfo?.nivel_tensao_descricao,
        risco_energia_id,
        proposalDetails,

        // simulation
        standaloneSims: solarpvState.standaloneSims,
        bundleSims: solarpvState.bundleSims,

        simulation: solarpvState.simulations /* TODO: remover consumir reducer */,
        handleSimulations,
        setInfoTagsHandler,
        kpisSelected,

        // Expanded Menu
        projectSummaryExpanded,
        setProjectSummaryExpanded,

        // gmap
        isOpenMap,
        setIsOpenMap,
        polygonGroups: polygonGroups?.current,
        exclusionZonesPolygons: exclusionZonesPolygons?.current,
        canDrawPolygon,
        setCanDrawPolygon,
        zoom,
        setZoom,

        // dispatchEvt
        dispatchEvt,
        setDispatchEvt,

        hasGroups: solarpvState.inputs?.areas?.length > 0,
        remunerationModelOptions,
        setRemunerationModelOptions,
        // ranges

        rangeId: solarpvState.inputs?.range_id,
        rangeInvestDescription:
            solarpvState.ranges?.find((item) => BATTERY_COMPATIBLE_RANGES_ID?.[companyProfileId]?.includes(item.id))?.descricao ?? null,
        isBatteryCompatibleRangeID,
        rangePanelId: solarpvState.inputs?.panel_id ?? null,
        // range panel types
        rangePanelTypes: solarpvState.ranges?.find((range) => range.id === solarpvState.inputs?.range_id)?.paineis ?? [],

        // remote
        hasChangesInRemote,
        setHasChangesInRemote,
        isDisabledRemote:
            !isFieldDefined(kpisSelected?.inverter_power) ||
            kpisSelected?.inverter_power > 100 ||
            isFieldDefined(solarpvState?.inputs?.battery?.id),

        // exclusions
        isEditingExclusions,
        hasChangesInExclusions,
        setHasChangesInExclusions,
        hasChangedExcusionInputs,
        setHasChangedExcusionInputs,
        hasChangesOnExcursionsInputs:
            Array.from(hasChangedExcusionInputs?.values()).find((v) => Object.values(v).find((va) => va === true)) !== undefined,
        isDrawingExclusions,
        setIsDrawingExclusions,
        exclusionsRefs,
        setExclusionsRefs,
        exclusionsAutoSplit,
        toggleExclusionsDrawingModeHandler,

        // buildings
        isDrawingBuildings,
        setIsDrawingBuildings,

        // ChangeAddressOverlayOpen
        isChangeAddressOverlayOpen,
        setIsChangeAddressOverlayOpen,
        canDragMarker,
        setCanDragMarker,

        duplicateMode,
        setDuplicateMode,
        duplicatePolygonID,
        setDuplicatePolygonID,

        // Overlays
        isOverlayOpen,
        setIsOverlayOpen,

        // Toolbar
        toolbarValue,
        setToolbarValue,
        toolbarData,
        setToolbarData,

        // Move group
        isMovingGroup,
        setIsMovingGroup,
        movingCounter,
        setMovingCounter,

        // Orientation
        isEditingOrientation,
        // @ts-ignore
        setisEditingOrientation,
        isOrientationOverlayOpen,
        setIsOrientationOverlayOpen,

        // Alignment
        alignmentOverlay,
        setAlignmentOverlay,

        // Split
        isSplitingShape,
        setIsSplitingShape,
        isDrawingPolyline,
        setIsDrawingPolyline,
        splitGroupPolylines,
        setSplitGroupPolylines,

        // Refs
        groupTabRef,
        productSelectionRef,
        isV3: !isDefined(module),

        // Battery
        isDisabledBattery:
            !hasGroups ||
            !isBatteryCompatibleRangeID ||
            solarpvState?.inputs?.remuneration_type_id === REMUNERATION_MODELS_TYPE.FEED_IN_TARIFF ||
            isFieldDefined(solarpvState?.inputs?.remote?.coefficient),
        hasBatteryAdded: isDefined(solarpvState?.inputs?.battery),
        isHybridBattery: solarpvState?.inputs?.battery?.hybrid_inverter,
        showBatteryVisibility: !is_power_increase && solarpvState?.pvOptions?.mostra_bateria,

        googleMapsOverlay,
        threejsOverlay,

        threeJSMenu,
        setThreeJSMenu,

        isValidGPU,

        hasModeSelected: isSomeModeSelected(solarpvState.modes),

        // Marker
        marker,
        setMarker,
        saved,
        setSaved,
        blockerPath,
        setBlockerPath,
        hasProjectFeature:
            !proposalDetails ?
                !isDefined(module) && configs?.[productID]?.feature
            :   configs && Object.keys(configs).includes(`${productID}`), // if is editing, enable for all products that have defined configs
    };

    return (
        <SPVProContext.Provider value={solarpvContextValues}>
            <RemoteProvider>{children}</RemoteProvider>
        </SPVProContext.Provider>
    );
};

export { SPVProContext, SPVProContextProvider };
