import IntlMessages from 'components/util/IntlMessages';
import { Button } from '@save2compete/efz-design-system';
import { StyledPopUpErrorDevice } from 'components/@efz/PopUpErrorDevice/StylesPopUpErrorDevice';

const PopUpErrorDevice = ({ setShowErrorDevice, title, content }) => {
    const clickHandler = () => {
        setShowErrorDevice(null);
    };

    return (
        //@ts-ignore
        <StyledPopUpErrorDevice open onClose={clickHandler}>
            <div className="efz-popup-device-error-content">
                <h3>
                    <IntlMessages id={title} />
                </h3>
                <p>
                    <IntlMessages id={content} />
                </p>
                <Button onClick={clickHandler} dataTestId="close-popup">
                    <IntlMessages id="label.close" />
                </Button>
            </div>
        </StyledPopUpErrorDevice>
    );
};

export default PopUpErrorDevice;
