import { Dialog } from '@mui/material';
import styled from 'styled-components/macro';

export const StyledSendEmail = styled(Dialog)`
    .MuiDialog-paper {
        padding: 1rem;
    }

    .MuiDialogTitle-root {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--primary-color);
    }

    .close-btn {
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;
    }

    .actions-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
    }

    .email-dialog-content {
        .input-element > div {
            width: 21.961932650073205vw;
        }
    }
`;
