// #region Imports
/* eslint-disable no-unsafe-optional-chaining */
// Libs
// @ts-ignore
import { SVGLoader } from 'three/addons/loaders/SVGLoader.js';
import { MeshLine, MeshLineGeometry, MeshLineMaterial } from '@lume/three-meshline';
import Stats from 'three/examples/jsm/libs/stats.module';
import copy from 'fast-copy';
import * as html2canvas from 'html2canvas';
import * as simplepolygon from 'simplepolygon';
// Three.js
import {
    AmbientLight,
    Box3,
    BoxGeometry,
    BufferGeometry,
    Color,
    DoubleSide,
    EdgesGeometry,
    Group,
    InstancedMesh,
    Line,
    LineBasicMaterial,
    LineSegments,
    MathUtils,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    Object3DEventMap,
    PerspectiveCamera,
    PlaneGeometry,
    Quaternion,
    Shape,
    ShapeGeometry,
    TextureLoader,
    Vector2,
    Vector3,
    WebGLRenderer,
} from 'three';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { latLngToXY, ThreeJSOverlayView, xyToLatLng } from '@googlemaps/three';
// Components
import { Polygon } from 'components/Products/solarpv/v3/map/Polygon';
import { isLatLngLiteral, LatLng } from 'components/Products/solarpv/v3/map/Polygon/Coordinates';
// Services
import { isEnvDevelopment, isEnvDevFlag } from 'services/settings';
import { notify } from 'services/@efz/notify';
import { getNextID, hasDuplicateVertices, intlMessages, isDefined, isFieldDefined, isNumberDefined } from 'services/util/auxiliaryUtils';
import { DragControls } from 'services/products/solarpv/DragControls';
import { initialOverlayModes } from 'contexts/products/solarpv/spvProReducer';
import { isEqual } from 'lodash';
import { GeoTiff } from 'services/products/solarpv/geotiff';
// Constants
import {
    DISPATCH_EVT,
    GROUP_INPUTS_NAME,
    MESH_TYPES,
    ORIENTATION_ARROW_SVG_ATTRIBUTES,
    ORIENTATION_DECIMAL_CFG,
    SYSTEM_SETTINGS_GROUPS,
} from 'constants/products/spvPro';
import {
    SIMPLE_MODE_STEPS,
    SolarPvToolbarOptions,
    SpvActions,
    SPV_OVERLAY_MODES,
    THREEJS_MENU_TYPES,
    SolarpvShortcuts,
    GOOGLE_SOLAR_LAYERS,
} from 'constants/products/solarpv';
// Types
import {
    TLine,
    TPanelInstanceMesh,
    TPanelPoints,
    TPointMesh,
    TPolygonMesh,
    TRectangleOrientationMesh,
} from 'interfaces/products/solarpv/map';
import { Nullable, TCursor } from 'types/utils';
// Icons
import orientationArrow from 'assets/@efz/icons/orientation-arrow.svg';
import MarkerIcon from 'assets/@efz/icons/marker-map-v3.svg';
// Store
import { useFeatureFlags } from 'store/featureFlags';
import { TVisibilityElements } from 'interfaces/products/solarpv/index';
import { hideToolbarOptionsNonMode } from 'services/products/solarpv/solarpvV3';
import HelvetikerFont from 'three/examples/fonts/helvetiker_regular.typeface.json';

// #endregion Imports

//#region Vars and CONSTs
const MIN_VERTICES = 3;
const MIN_EXCLUSIONS_VERTICES = 1;
const EARTH_RADIUS_METERS = 6371008.8;
const THREE_JS_MENU_ACTIVE = true;
const DEFAULT_CURSOR: TCursor = 'grab';
const POINT_RADIUS = 0.6; // Raio ajustado para 2.5 pixels
let allScenePoints: any[] = []; //Every point clicked on screen
let polygonsScene: any[] = [];
let panelsScene: any[] = [];
let exclusionsScene: any[] = [];
let buildingsScene: TPolygonMesh[] = [];
let actualPolygonScene: Nullable<TPolygonMesh> = null;
let actualPolygonGroup: Nullable<TPolygonMesh> = null;
let lastValidPointUuid = '';
let sceneObjects: {
    polygonPoints: any[];
    lines: any[];
    middlePoints: any[];
    drawningLine: any;
    closingLine: any;
} = {
    polygonPoints: [],
    lines: [],
    middlePoints: [],
    drawningLine: {},
    closingLine: {},
};
let mousePosition: { x: number; y: number } = new Vector2();
let actualCursorCenter: Vector3 = new Vector3();
const moveTranslation: { start: Vector3; end: Vector3 } = { start: new Vector3(), end: new Vector3() };
let renderer;
let isDraging = false;
let dragPoint: any = null;
let dragStartPoint: any = null;
let dragError: any = null;
let polygonIdToUpdate: Nullable<number> = null;
let polygonToCopy: any = null;
let polygonMoved = false;
let isStraightLine = false;
let straightLinePosition: Nullable<Vector3> = null;
let gMarker: Nullable<google.maps.Marker> = null;

let _map;
let _exclusionsMap;
let _imgContractMap;
let maxZoomService;
let _threejsOverlay;
let orientationVerticesAndAspect: { vector: { x: number; y: number; z: number }; aspect: number }[] = [];
let originalCenter;
let _stats;
let _roofDetailsSimpleMap;
let tooltip;
let threejsBlocked = false;
let _roofDetailsThreejsOverlay;
// Panel Edition
let okPanels: string[] = [];
let nokPanels: string[] = [];
let excludedPanelsCounterClick: number = 0;
let undoMesh: Nullable<TPolygonMesh> = null;
export const styles = {
    polygon: {
        strokeOpacity: 0.9,
        strokeWeight: 2,
        fillOpacity: 0.4,
        moveFillOpacity: 0.3,
        strokeColor: {
            selected: 'rgb(227,224,183)',
            notSelected: 'rgb(72, 68, 71)',
            panelSelected: 'rgb(255, 255, 255)',
            panelNotSelected: 'rgb(255, 255, 255)',
        },
        fillColor: {
            selected: 'rgb(214, 194, 20)',
            notSelected: 'rgb(251, 247, 208)',
            panelSelected: 'rgb(1, 23, 61)',
            panelNotSelected: 'rgb(1, 23, 61)',
            panelInvalid: 'rgb(1, 23, 61)',
            inactive: 'rgb(60, 77, 90)',
        },
        setback: 'rgb(208, 67, 60)',
        pointSize: {
            vertice: 15,
            middlePoint: 10,
        },
    },
    orientationPolygon: {
        strokeOpacity: 1,
        strokeWeight: 8,
        fillOpacity: 0.5,
        strokeColor: 'rgba(56, 133, 205, 1)',
        fillColor: 'rgba(56, 133, 205, 1)',
    },
    exclusionZone: {
        strokeOpacity: {
            selected: 0.9,
            notSelected: 0.5,
        },
        strokeWeight: 2,
        fillOpacity: {
            selected: 0.8,
            notSelected: 0.2,
        },
        strokeColor: {
            selected: 'rgb(56, 133, 205)',
            notSelected: 'rgb(56, 133, 205)',
        },
        fillColor: {
            selected: 'rgb(195, 218, 240)',
            notSelected: 'rgb(195, 218, 240)',
        },
    },
};
const raycasterParameters = (map) => ({
    Mesh: {},
    Line: { threshold: 2 },
    LOD: {},
    Sprite: {},
    Points: { threshold: computeThresholdValue(map.getZoom()) },
});
const guidelinesRaycasterParameters = (map) => ({
    ...raycasterParameters(map),
    Line: { threshold: 1 },
});
//#endregion Vars and CONSTs

// #region Map Options
export const mapOptions = (inputs, coordinates_avg = { lat: 0, long: 0 }) => {
    return {
        mapId: process.env.REACT_APP_GMAP_MAP_ID_2,
        center: {
            lat: inputs?.coordinates_avg?.lat ?? coordinates_avg?.lat,
            lng: inputs?.coordinates_avg?.long ?? coordinates_avg?.long,
        },
        zoom: 17,
        minZoom: 10,
        disableDefaultUI: true,
        heading: 0,
        tilt: 0,
        mapType: 'satellite',
        mapTypeId: 'satellite',
    };
};
export const exclusionsMapOptions = (center) => {
    return {
        mapId: 'exclusionsMap',
        center,
        zoom: 20,
        disableDefaultUI: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        zoomControl: true,
        heading: 0,
        tilt: 0,
        mapType: 'satellite',
        mapTypeId: 'satellite',
    };
};
export const imgContractMapOptions = (center) => {
    return {
        mapId: process.env.REACT_APP_GMAP_MAP_ID,
        center,
        zoom: 20,
        disableDefaultUI: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        zoomControl: true,
        heading: 0,
        tilt: 0,
        mapType: 'satellite',
        mapTypeId: 'satellite',
    };
};
export const roofDetailsMapOptions = (center) => {
    return {
        mapId: process.env.REACT_APP_GMAP_SIMPLE_MAP_ID,
        center,
        zoom: 18,
        disableDefaultUI: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        zoomControl: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        gestureHandling: 'none',
        heading: 0,
        tilt: 0,
        mapType: 'satellite',
        mapTypeId: 'satellite',
    };
};
// #endregion Map Options

// #region Overlay Creations and Start/Close Handlers
export const createOverlay = (
    map,
    inputs,
    { threejsOverlay, overlay, coordinates_avg },
    polygonGroups,
    exclusionGroups,
    buildingGroups,
    setIsOpenMap
) => {
    const scene = threejsOverlay.scene;
    let camera;
    const mousePosition = new Vector2();
    let highlightedObject: Nullable<TPolygonMesh> = null;
    const DEFAULT_COLOR = 0xffffff;
    const HIGHLIGHT_COLOR = 0xff0000;
    _map = map;
    _threejsOverlay = threejsOverlay;
    overlay.onBeforeDraw = () => {
        const intersections = overlay.raycast(mousePosition);
        if (highlightedObject) {
            // @ts-ignore
            highlightedObject.material.color.setHex(DEFAULT_COLOR);
        }

        if (intersections.length === 0) return;

        highlightedObject = intersections[0].object;
        // @ts-ignore
        highlightedObject.material.color.setHex(HIGHLIGHT_COLOR);
    };

    overlay.onAdd = async () => {
        try {
            camera = new PerspectiveCamera();
            const light = new AmbientLight(0xffffff, 0.9);
            scene.add(light);
            camera = threejsOverlay.camera;
            camera.position.set(0, 0, 0);
            camera.zoom = map.zoom;

            maxZoomService = new window.google.maps.MaxZoomService();

            const maxZoom = await maxZoomService.getMaxZoomAtLatLng(map.getCenter());
            const minZoom = 15;

            camera.maxZoom = maxZoom.zoom + 2;
            camera.minZoom = minZoom;
            // add FPS/latency/memory stats (in DEV)
            if (isEnvDevelopment()) {
                const stats = new Stats();
                stats.dom.style.position = 'absolute';
                // @ts-ignore
                stats.dom.style.left = 0;
                stats.dom.style.top = 'unset';
                // @ts-ignore
                stats.dom.style.bottom = 0;
                document.body.appendChild(stats.dom);
                _stats = stats;
            }

            // add previous saved exclusions to map (from exclusionGroups)
            if (exclusionGroups?.length > 0) {
                exclusionGroups.forEach((exclusion) => {
                    buildDefaultAreaMeshFromPolygon(
                        exclusion,
                        { threejsOverlay: threejsOverlay, scene: scene },
                        true,
                        MESH_TYPES.EXCLUSION
                    );
                });
            }

            // add previous saved buildings to map (from buildingGroups)
            if (buildingGroups?.length > 0) {
                buildingGroups.forEach((building) => {
                    const mesh = buildDefaultAreaMeshFromPolygon(
                        building,
                        { threejsOverlay: threejsOverlay, scene: scene },
                        true,
                        MESH_TYPES.BUILDING
                    );
                    mesh.visible = false;
                });
            }

            //add previous saved polygons to map (from polygonGroups)
            if (polygonGroups?.length > 0) {
                polygonGroups.forEach((polygon) => {
                    buildDefaultAreaMeshFromPolygon(polygon, { threejsOverlay: threejsOverlay, scene: scene }, true);
                });

                // Center and fit camera on first area
                focusOnSelectedPolygon(polygonGroups, { map, initial: true });
            } else {
                // Center and fit camera on Facility
                const instalationCenter = mapOptions(inputs, coordinates_avg).center;
                focusOnLatLng({
                    lat: instalationCenter.lat,
                    lng: instalationCenter.lng,
                });
            }

            //#region tooltip creation
            tooltip = document.createElement('div');
            tooltip.style.position = 'absolute';
            tooltip.style.background = 'rgba(255,255,255,0.7)';
            tooltip.style.color = '#FF0000';
            tooltip.style.padding = '5px';
            tooltip.style.border = '1px solid #FF0000';
            tooltip.style.borderRadius = '5px';
            tooltip.style.display = 'none';
            document.body.appendChild(tooltip);
            //#endregion

            setIsOpenMap(true);
        } catch (e) {
            console.log('error on: createOverlay -> onAdd ', e);
            notify('error on: createOverlay -> onAdd', 'error');
        }
    };

    overlay.onContextRestored = ({ gl }) => {
        renderer = new WebGLRenderer({
            ...gl.getContextAttributes(),
            canvas: gl.canvas,
            context: gl,
            antialias: true,
            precision: 'highp',
        });
        renderer.autoClear = false;
    };

    overlay.onDraw = () => {
        try {
            overlay.requestRedraw();
            renderer.render(scene, camera);
            renderer.resetState();
            !!_stats && _stats.update();
        } catch (e) {
            console.log('error', e);
        }
    };

    overlay.onRemove = () => {
        resetSolarProduct();
    };

    overlay.setMap(map);

    return overlay;
};
export const createRoofDetailsOverlay = (map, { threejsOverlay, overlay, roofCoordinates }, solarpvDispatchHandler) => {
    const scene = threejsOverlay.scene;
    let camera;
    const mousePosition = new Vector2();
    let highlightedObject: Nullable<TPolygonMesh> = null;
    const DEFAULT_COLOR = 0xffffff;
    const HIGHLIGHT_COLOR = 0xff0000;

    overlay.onBeforeDraw = () => {
        const intersections = overlay.raycast(mousePosition);
        if (highlightedObject) {
            // @ts-ignore
            highlightedObject.material.color.setHex(DEFAULT_COLOR);
        }

        if (intersections.length === 0) return;

        highlightedObject = intersections[0].object;
        // @ts-ignore
        highlightedObject.material.color.setHex(HIGHLIGHT_COLOR);
    };

    overlay.onAdd = async () => {
        try {
            camera = new PerspectiveCamera();
            const light = new AmbientLight(0xffffff, 0.9);
            scene.add(light);
            camera = threejsOverlay.camera;
            camera.position.set(0, 0, 1);
            camera.zoom = map.zoom;

            const polygon = buildDefaultPolygon(roofCoordinates, false, [], {});

            solarpvDispatchHandler(SpvActions.SET_SIMPLE_ROOF_POLYGON, { polygon });

            buildDefaultAreaMeshFromPolygon(polygon, { threejsOverlay: threejsOverlay, scene: scene }, false);
        } catch (e) {
            console.log('error on: createRoofDetailsOverlay -> onAdd ', e);
            notify('error on: createRoofDetailsOverlay -> onAdd', 'error');
        }
    };

    overlay.onContextRestored = ({ gl }) => {
        renderer = new WebGLRenderer({
            ...gl.getContextAttributes(),
            canvas: gl.canvas,
            context: gl,
            antialias: true,
            precision: 'highp',
        });
        renderer.autoClear = false;
    };

    overlay.onDraw = () => {
        try {
            overlay.requestRedraw();
            renderer.render(scene, camera);
            renderer.resetState();
        } catch (e) {
            console.log('error', e);
        }
    };

    overlay.onRemove = () => {
        deleteMarkerOnHandler();
    };

    overlay.setMap(map);

    return overlay;
};
export const createMiniOverlay = (map, inputs, { threejsOverlay, overlay }, usefulAreaData) => {
    const scene = threejsOverlay.scene;
    let camera;
    const mousePosition = new Vector2();
    let highlightedObject: Nullable<TPolygonMesh> = null;
    const DEFAULT_COLOR = 0xffffff;
    const HIGHLIGHT_COLOR = 0xff0000;

    overlay.onBeforeDraw = () => {
        const intersections = overlay.raycast(mousePosition);
        if (highlightedObject) {
            // @ts-ignore
            highlightedObject.material.color.setHex(DEFAULT_COLOR);
        }

        if (intersections.length === 0) return;

        highlightedObject = intersections[0].object;
        // @ts-ignore
        highlightedObject.material.color.setHex(HIGHLIGHT_COLOR);
    };

    overlay.onAdd = async () => {
        try {
            camera = new PerspectiveCamera();
            const light = new AmbientLight(0xffffff, 0.9);
            scene.add(light);
            camera = threejsOverlay.camera;
            camera.position.set(0, 0, 1);
            camera.zoom = map.zoom;

            maxZoomService = new window.google.maps.MaxZoomService();

            const maxZoom = await maxZoomService.getMaxZoomAtLatLng(map.getCenter());
            const minZoom = 15;

            camera.maxZoom = maxZoom.zoom + 2;
            camera.minZoom = minZoom;

            drawPanelsInMiniOverlay(usefulAreaData, inputs?.areas, null, inputs?.max_tec_panels, false, {
                threejsOverlay,
            });
        } catch (e) {
            console.log('error on: createMiniOverlay -> onAdd ', e);
            notify('error on: createMiniOverlay -> onAdd', 'error');
        }
    };

    overlay.onContextRestored = ({ gl }) => {
        renderer = new WebGLRenderer({
            ...gl.getContextAttributes(),
            canvas: gl.canvas,
            context: gl,
            antialias: true,
            precision: 'highp',
        });
        renderer.autoClear = false;
    };

    overlay.onDraw = () => {
        try {
            overlay.requestRedraw();
            renderer.render(scene, camera);
            renderer.resetState();
        } catch (e) {
            console.log('error', e);
        }
    };

    overlay.onRemove = () => {
        resetSolarProduct();
    };

    overlay.setMap(map);

    return overlay;
};
export const handleSetSPV3Orientation = (areaData, polygonGroups) => {
    // Center and fit camera on Facility
    const scene = _threejsOverlay.scene;

    focusOnSelectedPolygon(polygonGroups, { map: _map, isOrientation: false });

    //Remove points and lines from selected polygon/mesh
    polygonsScene.forEach((mesh) => {
        if (mesh.selected) {
            // TODO: POINTS
            mesh.children.forEach((child) => {
                if (child.type === 'Points') {
                    child.visible = false;
                }
                if (child.type === 'LineSegments') {
                    child.visible = false;
                }
            });
        }
    });

    const orientationMesh = buildOrientationMeshFromPolygon({
        threejsOverlay: _threejsOverlay,
        scene,
        edges: areaData.edges,
        orientation: areaData.orientation,
        polygonGroups,
        areaData,
    });
    orientationMesh.translateZ(0.01);
};
export const handleCloseSPV3Orientation = () => {
    orientationVerticesAndAspect = [];
    //Add points and lines back to selected mesh
    polygonsScene.forEach((mesh) => {
        if (mesh.selected) {
            if (mesh.geometry.parameters.shapes.length === 1) {
                mesh.children.forEach((child) => {
                    // TODO: POINTS
                    if (child.type === 'Points') {
                        child.visible = true;
                    }
                    if (child.type === 'LineSegments') {
                        child.visible = true;
                    }
                });
            } else {
                mesh.getObjectsByProperty('name', 'middlePoint').forEach((mp) => (mp.visible = true));
                mesh.getObjectsByProperty('name', 'point').forEach((v) => (v.visible = true));
            }
        }
    });

    //Remove orientation mesh from scene
    const meshToDelete = polygonsScene.find((p) => p?.name === 'orientationMesh');
    // set the mesh invisible
    if (meshToDelete) meshToDelete.visible = false;
    // remove from the scene
    _threejsOverlay.scene.remove(meshToDelete);
    // clean polygonsScene
    polygonsScene = polygonsScene.filter((p) => p?.name !== 'orientationMesh');
};
export const createRoofDetailsSimpleMap = async (options) => {
    const { roof, solarpvDispatchHandler, roofDetailsOverlayRef, roofDetailsThreeJsRef } = options;

    roofDetailsOverlayRef.current = new window.google.maps.WebGLOverlayView();

    roofDetailsThreeJsRef.current = new ThreeJSOverlayView({
        map: _roofDetailsSimpleMap,
        upAxis: 'Z',
        anchor: _roofDetailsSimpleMap?.getCenter()?.toJSON(),
    });

    window.google.maps.event.addListenerOnce(_roofDetailsSimpleMap, 'idle', function () {
        roofDetailsOverlayRef.current = createRoofDetailsOverlay(
            _roofDetailsSimpleMap,
            {
                threejsOverlay: roofDetailsThreeJsRef.current,
                overlay: roofDetailsOverlayRef.current,
                roofCoordinates: roof,
            },
            solarpvDispatchHandler
        );
    });

    //fit bounds
    handleRoofDetailsMapListeners(roof);

    _roofDetailsThreejsOverlay = roofDetailsThreeJsRef.current;
};
export const handleRoofDetailsMapListeners = (roof) => {
    const bounds = new google.maps.LatLngBounds();

    roof?.forEach((c) => bounds.extend(c));

    _roofDetailsSimpleMap.fitBounds(bounds);
};
export const closeStampModeHandler = (setIsOverlayOpen, spvEventDispatchHandler, threejsOverlay) => {
    setIsOverlayOpen(false);
    spvEventDispatchHandler(SpvActions.SET_MODES, { modes: [{ name: SPV_OVERLAY_MODES.STAMP, value: false }] });
    threejsOverlay.current.scene.remove(...threejsOverlay.current.scene.getObjectsByProperty('name', 'tempMesh'));
};
export const loadNewProjectMap = (groups) => {
    const { exclusionGroups, buildingGroups, polygonGroups } = groups;
    resetSolarProduct();
    if (exclusionGroups?.length > 0) {
        exclusionGroups.forEach((exclusion) => {
            buildDefaultAreaMeshFromPolygon(
                exclusion,
                { threejsOverlay: _threejsOverlay, scene: _threejsOverlay.scene },
                true,
                MESH_TYPES.EXCLUSION
            );
        });
    }

    // add previous saved buildings to map (from buildingGroups)
    if (buildingGroups?.length > 0) {
        buildingGroups.forEach((building) => {
            const mesh = buildDefaultAreaMeshFromPolygon(
                building,
                { threejsOverlay: _threejsOverlay, scene: _threejsOverlay.scene },
                true,
                MESH_TYPES.BUILDING
            );
            mesh.visible = false;
        });
    }

    //add previous saved polygons to map (from polygonGroups)
    if (polygonGroups?.length > 0) {
        polygonGroups.forEach((polygon) => {
            buildDefaultAreaMeshFromPolygon(polygon, { threejsOverlay: _threejsOverlay, scene: _threejsOverlay.scene }, true);
        });

        // Center and fit camera on first area
        focusOnSelectedPolygon(polygonGroups, { _map, initial: true });
    }
};
export const handleAddImgContractMapListeners = (areas) => {
    const bounds = new google.maps.LatLngBounds();
    areas.forEach((area) => {
        area.coordinates.forEach((c) => bounds.extend(c));
    });
    !!maxZoomService &&
        maxZoomService.getMaxZoomAtLatLng(bounds.getCenter(), function () {
            _imgContractMap.fitBounds(bounds);
        });
};
export const handleAddExclusionMapListeners = (callback, areas) => {
    if (typeof maxZoomService?.getMaxZoomAtLatLng === 'function') {
        maxZoomService.getMaxZoomAtLatLng(_exclusionsMap.getCenter(), function (response) {
            const maxZoomAtLatLng = response.zoom + 2;
            _exclusionsMap.setZoom(maxZoomAtLatLng);
            !!callback &&
                window.google.maps.event.addListenerOnce(_exclusionsMap, 'idle', function () {
                    callback(response?.zoom + 1 ?? 19, areas);
                });
        });
    }
};
export const toolsListenerHandler = (vars, removeEvents = false) => {
    const { abortController } = vars;

    if (abortController.signal.aborted) return;

    // FIXME: can we use 'ref' again in the future?
    // const ref = document.getElementById('solarpv-wrapper')!;

    const keydownWrapper = (e: KeyboardEvent) => keydownListenerFunction(e, vars);

    if (!removeEvents) {
        window.addEventListener('keydown', keydownWrapper, { signal: abortController.signal });
        window.addEventListener('keyup', keyupListenerFunction, { signal: abortController.signal });
    } else {
        abortController.abort();
        // trigger a fake event (these listeners won't be called again)
        window.dispatchEvent(new KeyboardEvent('keydown'));
    }
};
export const setExclusionsMap = (map) => {
    _exclusionsMap = map;
};
export const setImgContractMap = (map) => {
    _imgContractMap = map;
};
export const setRoofDetailsSimpleMap = (map) => {
    _roofDetailsSimpleMap = map;
};
// #endregion Overlay Creations and Start/Close Handlers

// #region Map Events

export const mouseDownDefault = async (mapsMouseEvent, threeVar) => {
    const {
        threejsOverlay,
        canDrawPolygon /* scene, */,
        isDrawingExclusions,
        map,
        isOrientationOverlayOpen,
        setThreeJSMenu,
        inputs,
        usefulAreaData,
        modes,
        panels,
        selectedArea,
        toolbarValue,
        setToolbarValue,
        setToolbarData,
    } = threeVar;
    const { domEvent } = mapsMouseEvent;
    const { left, top } = map.getDiv().getBoundingClientRect();
    const positionVector = getVectorFromLatLng(mapsMouseEvent, threejsOverlay);
    hideToolbarOptionsNonMode(toolbarValue, setToolbarValue);

    if (THREE_JS_MENU_ACTIVE) {
        setThreeJSMenu({ type: null, visible: false, x: null, y: null, intersections: [] });
        threejsOverlay?.overlay?.map.setOptions({ gestureHandling: 'auto' });
    }

    if (!canDrawPolygon && !isDrawingExclusions && !isOrientationOverlayOpen && domEvent.button === 0) {
        let dragableObjets: Object3D[] = [];
        if (allScenePoints?.length >= MIN_VERTICES) {
            dragableObjets = sceneObjects.polygonPoints?.flatMap((p) => p?.point);
        }
        dragableObjets = polygonsScene.length > 0 ? dragableObjets.concat(polygonsScene) : dragableObjets;
        dragableObjets = exclusionsScene.length > 0 ? dragableObjets.concat(exclusionsScene) : dragableObjets;
        dragableObjets = buildingsScene.length > 0 ? dragableObjets.concat(buildingsScene) : dragableObjets;
        dragableObjets =
            // @ts-ignore
            Object.keys(actualPolygonScene ?? {}).length > 0 ? dragableObjets.concat(actualPolygonScene ?? {}) : dragableObjets;

        const intersections = threejsOverlay.raycast(mousePosition, dragableObjets, {
            recursive: true,
            raycasterParameters: raycasterParameters(_map),
        });

        if (modes?.[SPV_OVERLAY_MODES.MOVE] && !!intersections.find((i) => i.object?.isMesh)) {
            moveTranslation.start.set(positionVector.newPos.lat, positionVector.newPos.lng, 0);
            return;
        }

        if (!!intersections.find((i) => i.object?.isTempPanels) && modes?.[SPV_OVERLAY_MODES.PANEL_EDITOR] && !threejsBlocked) {
            const instanceMesh = intersections.find((i) => i.object?.isTempPanels).object;
            const panelHovered = identifyPanelHandler(positionVector.vector2, instanceMesh.panelPoints);
            if (!isDefined(panelHovered)) forceMapRender();

            const _excludedPanels = [...new Set(nokPanels)];
            const canAddPanel = _excludedPanels.includes(panelHovered?.id);

            if ((canAddPanel && panels.number - excludedPanelsCounterClick < panels.max) || !canAddPanel) {
                await drawEditorPanels(usefulAreaData, inputs, {
                    panel: { id: identifyPanelHandler(positionVector.vector2, instanceMesh.panelPoints)?.id },
                    selectedArea,
                });

                setToolbarData((old) => ({
                    ...old,
                    [SolarPvToolbarOptions.PANEL_EDITOR]: {
                        nokPanels,
                        okPanels,
                        excludedPanelsCounterClick,
                    },
                }));
            }
        }
        if (modes?.[SPV_OVERLAY_MODES.PANEL_EDITOR]) return;

        dragStartPoint = intersections.find((i) => ['point', 'middlePoint'].includes(i.object.name) && i.object.visible)?.object;

        if (intersections.length > 0 && !!dragStartPoint) {
            // remove google's drag event
            map.setOptions({
                gestureHandling: 'none',
            });

            isDraging = true;
            mapsMouseEvent.domEvent.stopPropagation();
        }

        if (mapsMouseEvent?.domEvent?.target) {
            const cursor: TCursor =
                mapsMouseEvent.domEvent.target.style.cursor !== DEFAULT_CURSOR ? mapsMouseEvent.domEvent.target.style.cursor : 'grabbing';
            mapsMouseEvent.domEvent.target.style.cursor = cursor;
        }
    }
    // if right click of mouse - context menu
    if (THREE_JS_MENU_ACTIVE && domEvent.button === 2) {
        const polSelected = polygonsScene?.find((p) => p.selected);
        const buildSelected = buildingsScene?.find((b) => b.selected);
        const exclusionSelected = exclusionsScene?.find((e) => e.selected);
        const menuPosition = {
            x: domEvent.clientX - left,
            y: domEvent.clientY - top - 64,
        };

        const intersectionsMenu = threejsOverlay.raycast(mousePosition, [...polygonsScene, ...exclusionsScene, ...buildingsScene], {
            recursive: true,
            raycasterParameters: raycasterParameters(_map),
        });

        const elementsIntersection = intersectionsMenu
            .filter((i) => ['area', 'exclusion', 'building'].includes(i.object?.element) && i.object?.visible)
            .map((i) => {
                return {
                    id: i.object?.name,
                    element: i.object?.element,
                };
            });

        if (elementsIntersection.length > 0)
            setThreeJSMenu({
                type:
                    polSelected ? THREEJS_MENU_TYPES.PANELS
                    : buildSelected ? THREEJS_MENU_TYPES.BUILDINGS
                    : exclusionSelected ? THREEJS_MENU_TYPES.EXCLUSIONS
                    : THREEJS_MENU_TYPES.PANELS, // fallback to show menu
                visible: true,
                x: menuPosition.x,
                y: menuPosition.y,
                intersections: elementsIntersection,
                selected: !!polSelected || !!buildSelected || !!exclusionSelected,
            });
    }
    forceMapRender();
};
export const mouseDownHandler = (mapsMouseEvent, threeVar) => {
    const { threejsOverlay, canDrawPolygon, map, toolbarValue, setToolbarValue } = threeVar;
    const { domEvent } = mapsMouseEvent;
    hideToolbarOptionsNonMode(toolbarValue, setToolbarValue);
    if (canDrawPolygon && domEvent.button === 1) {
        // Only left mouse click
        let dragableObjets: Object3D[] = [];
        if (allScenePoints?.length >= MIN_VERTICES) {
            dragableObjets = sceneObjects.polygonPoints?.flatMap((p) => p?.point);
        }
        dragableObjets = polygonsScene.length > 0 ? dragableObjets.concat(polygonsScene) : dragableObjets;
        if (actualPolygonScene !== null && Object.keys(actualPolygonScene).length > 0) {
            dragableObjets = dragableObjets.concat(actualPolygonScene ?? {});
            dragableObjets = dragableObjets.concat(actualPolygonScene.children ?? {});
        }

        const intersections = threejsOverlay.raycast(!isStraightLine ? mousePosition : straightLinePosition, dragableObjets, {
            recursive: false,
            raycasterParameters: raycasterParameters(_map),
        });

        //check if is a point
        dragPoint = intersections.find((i) => i.object.name === 'point')?.object;

        if (intersections.length > 0 && !!dragPoint) {
            // remove google's drag event
            map.setOptions({
                gestureHandling: 'none',
            });

            isDraging = true;
            domEvent.stopPropagation();
        }
    }
    forceMapRender();
};
export const mouseUpDefault = (mapsMouseEvent, threeVar) => {
    const {
        threejsOverlay,
        canDrawPolygon,
        isDrawingExclusions,
        isDrawingBuildings,
        map,
        polygonGroups,
        exclusionGroups,
        buildingGroups,
        spvEventDispatchHandler,
        setCanDrawPolygon,
        setIsDrawingExclusions,
        setHasChangesInExclusions,
        setIsDrawingBuildings,
        meshType,
        modes,
        visibility,
    } = threeVar;

    const positionVector = getVectorFromLatLng(mapsMouseEvent, threejsOverlay);
    if (modes?.[SPV_OVERLAY_MODES.MOVE] && moveTranslation.start.length() > 0) {
        moveTranslation.end.set(positionVector.newPos.lat, positionVector.newPos.lng, 0);
        return;
    }

    if (!canDrawPolygon && !isDrawingExclusions && !isDrawingBuildings && isDraging && (!!dragPoint || !!dragError)) {
        if (!!dragPoint || !!dragError) {
            if (isPolygonSelfIntersected(allScenePoints))
                undoPreviousPolygon({ polygonGroups, exclusionGroups, buildingGroups }, meshType, spvEventDispatchHandler, { visibility });
            else {
                switch (meshType) {
                    case MESH_TYPES.POLYGON:
                    default: {
                        closeCurrentPolygon(polygonGroups, spvEventDispatchHandler, setCanDrawPolygon, {
                            isUpdated: true,
                            visibility,
                        });

                        break;
                    }
                    case MESH_TYPES.EXCLUSION:
                        closeCurrentExclusion(exclusionGroups, spvEventDispatchHandler, setIsDrawingExclusions, setHasChangesInExclusions, {
                            isUpdated: true,
                            visibility,
                        });
                        break;
                    case MESH_TYPES.BUILDING:
                        closeCurrentBuilding(buildingGroups, spvEventDispatchHandler, setIsDrawingBuildings, {
                            isUpdated: true,
                            visibility,
                        });
                        break;
                }
            }
            isDraging = false;
            dragPoint = null;
            dragStartPoint = null;
            actualPolygonGroup = null;
            dragError = null;
        }

        map.setOptions({
            gestureHandling: 'auto',
        });
    }
};
export const mouseUpHandler = (mapsMouseEvent, threeVar) => {
    const { scene, threejsOverlay, overlay, canDrawPolygon, map } = threeVar;
    const { domEvent } = mapsMouseEvent;

    if (canDrawPolygon && isDraging) {
        const positionVector = getVectorFromLatLng(mapsMouseEvent, threejsOverlay);
        if (dragPoint) {
            isDraging = false;

            //find the current point
            const indexPointToRemove = allScenePoints.findIndex((p) => p?.point?.uuid === dragPoint?.uuid);
            //remove the point if exist
            if (indexPointToRemove !== -1) {
                scene.remove(allScenePoints[indexPointToRemove]?.point);
            }

            //Redraw the current point or add a new point(move middle point)
            const pointToDraw = drawPoint(scene, positionVector.vector3, {});
            if (indexPointToRemove !== -1) {
                allScenePoints[indexPointToRemove] = {
                    point: pointToDraw,
                    center: pointToDraw?.center,
                    coords: new LatLng(positionVector.newPos),
                };
                lastValidPointUuid = allScenePoints[indexPointToRemove].point.uuid;
            } else {
                allScenePoints.push({
                    point: pointToDraw,
                    center: pointToDraw?.center,
                    coords: new LatLng(positionVector.newPos),
                });
                lastValidPointUuid = allScenePoints[allScenePoints.length - 1].point.uuid;
            }

            //Redraw the polygon
            const polygonPoints = allScenePoints;

            drawPolygon(scene, overlay, polygonPoints);
            dragPoint = null;
            dragStartPoint = null;
        }

        map.setOptions({
            gestureHandling: 'auto',
        });
    }

    if (canDrawPolygon && allScenePoints.length >= MIN_VERTICES - 1) {
        domEvent.target.style.cursor = 'pointer';
        _map.setOptions({
            gestureHandling: 'none',
        });
    }
};

export const mouseMoveDefault = (mapsMouseEvent, threeVar) => {
    try {
        const {
            mapDiv,
            threejsOverlay,
            scene,
            overlay,
            polygonGroups,
            exclusionGroups,
            buildingGroups,
            meshType,
            isMovingGroup,
            activeMode,
            modes,
            panels,
        } = threeVar;
        const { domEvent } = mapsMouseEvent;
        const positionVector = getVectorFromLatLng(mapsMouseEvent, threejsOverlay);
        const { left, top, width, height } = mapDiv.getBoundingClientRect();

        const x = domEvent.clientX - left;
        const y = domEvent.clientY - top;

        mousePosition.x = 2 * (x / width) - 1;
        mousePosition.y = 1 - 2 * (y / height);

        const intersections = threejsOverlay.raycast(
            mousePosition,
            meshType === MESH_TYPES.POLYGON ? polygonsScene
            : meshType === MESH_TYPES.EXCLUSION ? exclusionsScene
            : buildingsScene,
            {
                recursive: true,
                raycasterParameters: raycasterParameters(_map),
            }
        );
        let cursor: TCursor = DEFAULT_CURSOR;
        const hoveringPoints = intersections.filter((i) => ['point', 'middlePoint'].includes(i.object.name) && i.object.visible).length > 0;
        if (domEvent?.target) {
            if (!isMovingGroup) {
                if (hoveringPoints) {
                    cursor = 'pointer';
                } else if (intersections.filter((i) => i.object.type === 'Mesh' && i.object.name === 'orientationButtonMesh').length > 0) {
                    cursor = 'pointer';
                } else if (
                    intersections.filter((i) => ['Mesh'].includes(i.object.type) && i.object.visible && i.object.selected).length > 0 &&
                    !hoveringPoints
                ) {
                    cursor = 'default';
                } else if (
                    intersections.filter(
                        (i) =>
                            ['Mesh', meshType === MESH_TYPES.EXCLUSIONS ? 'LineSegments' : ''].includes(i.object.type) &&
                            i.object.visible &&
                            !i.object.isMeasurement
                    ).length > 0
                ) {
                    cursor = activeMode ? 'default' : 'pointer';
                } else {
                    _map.setOptions({
                        gestureHandling: 'auto',
                    });
                }
            } else {
                if (intersections.filter((i) => ['Mesh'].includes(i.object.type) && i.object.visible && i.object.selected).length > 0) {
                    cursor = 'move';
                }
                _map.setOptions({
                    gestureHandling: 'auto',
                });
            }
            domEvent.target.style.cursor = cursor;
        }

        if (!!intersections.find((i) => i.object?.isTempPanels) && modes?.[SPV_OVERLAY_MODES.PANEL_EDITOR]) {
            const instanceMesh = intersections.find((i) => i.object?.isTempPanels).object;
            const panelHovered = identifyPanelHandler(positionVector.vector2, instanceMesh.panelPoints);
            const _excludedPanels = [...new Set(nokPanels)];
            const canAddPanel = _excludedPanels.includes(panelHovered?.id);
            domEvent.target.style.cursor = 'pointer';

            if (canAddPanel && panels.number - excludedPanelsCounterClick === panels.max) {
                tooltip.style.display = 'block';
                tooltip.style.left = `${domEvent.clientX + 10}px`;
                tooltip.style.top = `${domEvent.clientY - 20}px`;
                tooltip.style.zIndex = 100000;
                tooltip.innerHTML = `${panels.label}`;
                domEvent.target.style.cursor = 'not-allowed';
            } else {
                if (isDefined(tooltip)) tooltip.style.display = 'none';
            }
        } else {
            if (isDefined(tooltip)) tooltip.style.display = 'none';
        }

        if (dragStartPoint) {
            const meshToModify = dragStartPoint.parent;
            preparingMeshToUndo(meshToModify);

            actualPolygonGroup = meshToModify?.getObjectByProperty('isGroup', true);
            !!actualPolygonGroup && actualPolygonGroup.position.set(0, 0, actualPolygonGroup.position.z);

            let sceneArray: any = null,
                groupArray: any = null;
            switch (meshType) {
                case MESH_TYPES.POLYGON:
                default:
                    sceneArray = polygonsScene;
                    groupArray = polygonGroups;
                    break;
                case MESH_TYPES.EXCLUSION:
                    sceneArray = exclusionsScene;
                    groupArray = exclusionGroups;
                    break;
                case MESH_TYPES.BUILDING:
                    sceneArray = buildingsScene;
                    groupArray = buildingGroups;
                    break;
            }
            const polygonUpdating = groupArray?.find((polygon) => polygon._id === meshToModify.name);
            sceneArray = sceneArray.filter((polygon) => polygon.name !== meshToModify.name);
            switch (meshType) {
                case MESH_TYPES.POLYGON:
                default:
                    polygonsScene = sceneArray;
                    break;
                case MESH_TYPES.EXCLUSION:
                    exclusionsScene = sceneArray;
                    break;
                case MESH_TYPES.BUILDING:
                    buildingsScene = sceneArray;
                    break;
            }
            polygonIdToUpdate = polygonUpdating?.id;
            const polygonPointsCoords = polygonUpdating?.paths.map((path) => {
                return { lat: path._lat, lng: path._lng, altitude: 0 };
            });
            const polygonPointss = meshToModify.children
                .filter((point) => point.name === 'point')
                .map((point, idx) => {
                    if (polygonPointsCoords?.[idx]) {
                        const positionVector = {
                            newPos: polygonPointsCoords[idx],
                            vector3: threejsOverlay.latLngAltitudeToVector3(polygonPointsCoords[idx]),
                        };

                        const pointToDraw = drawPoint(scene, positionVector.vector3, { meshType });

                        const pointt = {
                            point: pointToDraw,
                            center: point?.drawingPoint,
                            coords: new LatLng(positionVector.newPos),
                            id: point.uuid,
                        };
                        allScenePoints.push(pointt);
                        return pointt;
                    }
                });
            const newPolygonPoints = copy(polygonPointss);
            const simplePoly = {
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: [
                        newPolygonPoints
                            ?.filter((p) => isDefined(p?.center?.x) && isDefined(p?.center?.y))
                            ?.map((p) => [p?.center?.x, p?.center?.y]),
                    ] as [][],
                },
            };
            const spPoints =
                hasDuplicateVertices(simplePoly?.geometry?.coordinates?.[0] ?? []) ? { features: [true] } : simplepolygon(simplePoly);

            _threejsOverlay.scene.remove(meshToModify);
            drawPolygon(scene, overlay, newPolygonPoints, meshType);
            const pointToRemove = polygonPointss?.find(
                (point) => point?.center?.x === dragStartPoint?.drawingPoint?.x && point?.center?.y === dragStartPoint?.drawingPoint?.y
            )?.point;

            const isMiddlePoint = !isDefined(pointToRemove);
            //find the current point
            const indexPointToRemove = isMiddlePoint ? dragStartPoint?.middlePointIndex : dragStartPoint?.pointIndex;

            //remove the point
            scene.remove(allScenePoints[indexPointToRemove]?.point);

            // //Reraw the current point
            const pointToDraw = drawPoint(scene, positionVector.vector3, { meshType });

            if (isDefined(pointToRemove) && !pointToRemove.isMiddlePoint) {
                allScenePoints[indexPointToRemove] = {
                    point: pointToDraw,
                    center: positionVector.vector2,
                    coords: new LatLng(positionVector.newPos),
                };
            } else if (isMiddlePoint) {
                allScenePoints.splice(dragStartPoint.middlePointIndex, 0, {
                    point: pointToDraw,
                    center: positionVector.vector2,
                    coords: new LatLng(positionVector.newPos),
                });
            }

            //Redraw the polygon
            const polygonPoints = allScenePoints;
            if (spPoints.features.length === 1) {
                !!pointToRemove && drawPolygon(scene, overlay, polygonPoints, meshType);
            } else if (spPoints.features.length > 1) {
                drawPolygonSelfIntersecting(
                    scene,
                    overlay,
                    getSelfIntersectingPolygonPoints(spPoints.features, newPolygonPoints, positionVector),
                    meshType
                );
            }
            dragStartPoint = null;
            dragPoint = pointToDraw;
            dragError = !isDefined(pointToRemove) ? true : null;
        } else if (dragPoint) {
            //find the current point
            const indexPointToRemove = allScenePoints.findIndex((p) => p?.point?.uuid === dragPoint?.uuid);
            //remove the point
            scene.remove(allScenePoints[indexPointToRemove]?.point);

            let pointToDraw;
            // Redraw the current point
            if (!isStraightLine) {
                pointToDraw = drawPoint(scene, positionVector.vector3, {
                    meshType,
                });
                allScenePoints[indexPointToRemove] = {
                    point: pointToDraw,
                    center: positionVector.vector2,
                    coords: new LatLng(positionVector.newPos),
                };
            } else {
                const POINTS_OFFSET = 1;
                let isMagnetic = false;

                const prevPointIndex = indexPointToRemove !== 0 ? indexPointToRemove - 1 : allScenePoints?.length - 1;
                const prevPoint = allScenePoints[prevPointIndex]?.center;
                const prevPrevPoint = allScenePoints[prevPointIndex - 1 >= 0 ? prevPointIndex - 1 : allScenePoints?.length - 1]?.center;
                const nextPointIndex = indexPointToRemove !== allScenePoints?.length - 1 ? indexPointToRemove + 1 : 0;
                const nextPoint = allScenePoints[nextPointIndex]?.center;
                const nextNextPoint = allScenePoints[nextPointIndex + 1 <= allScenePoints?.length - 1 ? nextPointIndex + 1 : 0]?.center;

                // closest straight point (using previous index)
                const { point, vector } = getClosestStraightPoint(
                    new Vector3(prevPoint.x, prevPoint.y, 0),
                    new Vector3().copy(positionVector.vector3),
                    new Vector3(prevPrevPoint.x, prevPrevPoint.y, 0)
                );
                // closest straight point (using next index)
                const { point: otherPoint, vector: otherVector } = getClosestStraightPoint(
                    new Vector3(nextPoint.x, nextPoint.y, 0),
                    new Vector3().copy(positionVector.vector3),
                    new Vector3(nextNextPoint.x, nextNextPoint.y, 0)
                );
                if (isEnvDevFlag(useFeatureFlags.getState().featureFlags['fe-2339'])) {
                    const allGuidelines: Line[] = _threejsOverlay.scene.getObjectsByProperty('name', 'guideline');
                    const linesIntersection = _threejsOverlay.raycast(
                        mousePosition,
                        [...allGuidelines, ...sceneObjects.polygonPoints?.flatMap((p) => p.point)],
                        { raycasterParameters: guidelinesRaycasterParameters(_map) }
                    );
                    allGuidelines.forEach((line) => {
                        if (linesIntersection.find((l) => l?.object?.isPoints)) line.visible = false;
                        else line.visible = !!linesIntersection?.find((l) => l?.object?.uuid === line.uuid);
                    });
                }
                // only execute the magnetism algorithm if the two points are close enough to each other
                if (point.distanceToSquared(otherPoint) < POINTS_OFFSET) {
                    const vectorsIntersection = getStraightPointVectorsIntersection(
                        { point, vector },
                        { point: otherPoint, vector: otherVector }
                    );
                    if (vectorsIntersection) {
                        isMagnetic = true;
                        straightLinePosition = new Vector3().copy(vectorsIntersection);
                        pointToDraw = drawPoint(scene, vectorsIntersection, { meshType });
                        allScenePoints[indexPointToRemove] = {
                            point: pointToDraw,
                            center: vectorsIntersection,
                            coords: new LatLng(vector3ToLatLngAlt(vectorsIntersection)),
                        };
                    }
                }
                if (!isMagnetic) {
                    straightLinePosition = new Vector3().copy(point);
                    pointToDraw = drawPoint(scene, point, { meshType });
                    allScenePoints[indexPointToRemove] = {
                        point: pointToDraw,
                        center: point,
                        coords: new LatLng(vector3ToLatLngAlt(point)),
                    };
                }
            }
            //Redraw the polygon
            const polygonPoints = allScenePoints;
            const simplePoly = {
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: [
                        polygonPoints
                            .filter((p) => isDefined(p?.center?.x) && isDefined(p?.center?.y))
                            .map((p) => [p.center.x, p.center.y]),
                    ] as [][],
                },
            };

            const spPoints =
                hasDuplicateVertices(simplePoly?.geometry?.coordinates?.[0] ?? []) ? { features: [true] } : simplepolygon(simplePoly);
            if (spPoints.features.length === 1) {
                drawPolygon(scene, overlay, polygonPoints, meshType);
            } else if (spPoints.features.length > 1) {
                drawPolygonSelfIntersecting(
                    scene,
                    overlay,
                    getSelfIntersectingPolygonPoints(spPoints.features, polygonPoints, positionVector),
                    meshType
                );
            }
            dragPoint = pointToDraw;
        }
    } catch (error) {
        console.error('efz-> mouseMoveDefault error', error);
    }
};
export const mouseMoveHandler = (mapsMouseEvent, threeVar) => {
    const { getMousePosition, threejsOverlay, scene, overlay, meshType = MESH_TYPES.POLYGON } = threeVar;
    const { domEvent } = mapsMouseEvent;

    mousePosition = getMousePosition(domEvent);

    const intersections = threejsOverlay.raycast(
        mousePosition,
        sceneObjects.polygonPoints?.flatMap((p) => p.point),
        {
            recursive: true,
            raycasterParameters: raycasterParameters(_map),
        }
    );

    if (domEvent?.target) {
        let cursor = 'crosshair';
        if (
            intersections.filter((i) => i.object.name === 'point' && i.object.visible).length > 0 &&
            allScenePoints.length >= MIN_VERTICES
        ) {
            cursor = 'pointer';
            _map.setOptions({
                gestureHandling: 'none',
            });
        } else {
            _map.setOptions({
                gestureHandling: 'auto',
            });
        }
        domEvent.target.style.cursor = cursor;
    }

    //remove the mouse move line to redraw after
    scene.remove(sceneObjects.drawningLine);
    scene.remove(sceneObjects.closingLine);

    sceneObjects.drawningLine = undefined;
    sceneObjects.closingLine = undefined;

    const positionVector = getVectorFromLatLng(mapsMouseEvent, threejsOverlay);

    //if is not draging
    if (!isDraging) {
        if (allScenePoints.length > 0) {
            //flow when we have two or less points on screen
            // if (allScenePoints.length <= 2) {
            lastValidPointUuid = allScenePoints?.[allScenePoints?.length - 1]?.point?.uuid;
            // }

            const previousCenter = allScenePoints?.find((p) => p.point.uuid === lastValidPointUuid)?.center;
            actualCursorCenter = positionVector.vector3;
            if (isStraightLine) {
                let isMagnetic = false;
                const { point, vector } = getClosestStraightPoint(
                    previousCenter,
                    new Vector3().copy(actualCursorCenter as Vector3),
                    allScenePoints.length >= 2 ? allScenePoints?.[allScenePoints?.length - 2]?.center : null
                );
                if (isEnvDevFlag(useFeatureFlags.getState().featureFlags['fe-2339'])) {
                    const allGuidelines: Line[] = _threejsOverlay.scene.getObjectsByProperty('name', 'guideline');
                    const linesIntersection = _threejsOverlay.raycast(
                        mousePosition,
                        [...allGuidelines, ...sceneObjects.polygonPoints?.flatMap((p) => p.point)],
                        { raycasterParameters: guidelinesRaycasterParameters(_map) }
                    );
                    allGuidelines.forEach((line) => {
                        if (linesIntersection.find((l) => l?.object?.isPoints)) line.visible = false;
                        else line.visible = !!linesIntersection?.find((l) => l?.object?.uuid === line.uuid);
                    });
                }
                if (allScenePoints.length >= 2) {
                    const POINTS_OFFSET = 1;
                    const { point: otherPoint, vector: otherVector } = getClosestStraightPoint(
                        allScenePoints[0].center,
                        new Vector3().copy(actualCursorCenter as Vector3),
                        allScenePoints[1].center
                    );
                    // only execute the magnetism algorithm if the two points are close enough to each other
                    if (point.distanceToSquared(otherPoint) < POINTS_OFFSET) {
                        const vectorsIntersection = getStraightPointVectorsIntersection(
                            { point, vector },
                            { point: otherPoint, vector: otherVector }
                        );
                        if (vectorsIntersection) {
                            isMagnetic = true;
                            actualCursorCenter = vectorsIntersection;
                            straightLinePosition = new Vector3().copy(actualCursorCenter as Vector3);
                        }
                    }
                }
                if (!isMagnetic) {
                    actualCursorCenter = point;
                    straightLinePosition = new Vector3().copy(actualCursorCenter as Vector3);
                }
            }
            const linePoints = [previousCenter, actualCursorCenter];
            const prevCoords = allScenePoints?.find((p) => p.point.uuid === lastValidPointUuid).coords;
            const actualCoords = positionVector.newPos;
            const lineCoords = [{ lat: prevCoords.lat() as number, lng: prevCoords.lng() as number }, actualCoords];

            //draw cursor Line
            const line = drawLine(scene, linePoints, meshType, { lineCoords });
            sceneObjects.drawningLine = line;

            if (allScenePoints.length >= 2) {
                // draw closing line
                const closingCoords = [vector3ToLatLngAlt(actualCursorCenter), vector3ToLatLngAlt(allScenePoints[0].center)];
                const closingLine = drawLine(scene, [actualCursorCenter, allScenePoints[0].center], meshType, {
                    lineCoords: closingCoords,
                });
                sceneObjects.closingLine = closingLine;
            }
        }
    } else {
        if (dragPoint) {
            //find the current point
            const indexPointToRemove = allScenePoints.findIndex((p) => p?.point?.uuid === dragPoint?.uuid);
            //remove the point
            if (indexPointToRemove !== -1) {
                scene.remove(allScenePoints[indexPointToRemove]?.point);
            }

            //Reraw the current point
            const pointToDraw = drawPoint(scene, positionVector.vector3, {});
            if (indexPointToRemove !== -1) {
                allScenePoints[indexPointToRemove] = {
                    point: pointToDraw,
                    center: pointToDraw?.center,
                    coords: new LatLng(positionVector.newPos),
                };
                lastValidPointUuid = allScenePoints[indexPointToRemove].point.uuid;
            } else {
                allScenePoints.push({
                    point: pointToDraw,
                    center: pointToDraw?.center,
                    coords: new LatLng(positionVector.newPos),
                });
                lastValidPointUuid = allScenePoints[allScenePoints.length - 1].point.uuid;
            }

            //Redraw the polygon
            const polygonPoints = allScenePoints;
            drawPolygon(scene, overlay, polygonPoints, meshType);

            dragPoint = pointToDraw;
        }
    }
};
export const defaultClickHandler = (mapsMouseEvent, threeVar) => {
    const {
        threejsOverlay,
        polygonGroups,
        exclusionGroups,
        buildingGroups,
        selectedExclusion,
        selectedBuilding,
        meshType,
        spvEventDispatchHandler,
        setselectCounter,
        canDragMarker,
        setMarker,
        modes,
        visibility,
    } = threeVar;

    // prevent selection of different mesh
    if (polygonMoved && meshType !== MESH_TYPES.POLYGON) {
        polygonMoved = false;
        return;
    }

    if (canDragMarker) {
        const location = mapsMouseEvent.latLng.toJSON();
        setMarker(location);
        updateMarkerOnHandler({ location });
        return;
    }

    if (modes?.[SPV_OVERLAY_MODES.STAMP]) {
        //do nothing ignore the click, action is being made on DragControls
        return;
    }

    const polygonIntersections = threejsOverlay
        .raycast(mousePosition, polygonsScene)
        .filter((i) => i?.object?.isMesh)
        .map((i) => i?.object);
    const exclusionIntersections = threejsOverlay
        .raycast(mousePosition, exclusionsScene, {
            recursive: true,
            raycasterParameters: raycasterParameters(_map),
        })
        .filter((i) => (i?.object?.isMesh || i?.object?.isLine) && i?.object?.element === 'exclusion')
        .map((i) => (i?.object?.isMesh ? i?.object : i?.object?.parent));
    const buildingIntersections = threejsOverlay
        .raycast(mousePosition, buildingsScene)
        .filter((i) => i?.object?.isMesh)
        .map((i) => i?.object);

    if (polygonIntersections.length === 0) {
        if (polygonGroups?.find((p) => p?.getSelected())) {
            !modes[SPV_OVERLAY_MODES.PANEL_EDITOR] && unselectAllPolygons(polygonGroups, spvEventDispatchHandler);
        }
    }
    if (exclusionIntersections.length === 0) {
        if (!!selectedExclusion && !threejsOverlay.scene.getObjectByName('tempMesh')) {
            unselectAllExclusions(exclusionGroups, spvEventDispatchHandler);
        }
    }
    if (buildingIntersections.length === 0) {
        if (!!selectedBuilding && !threejsOverlay.scene.getObjectByName('tempMesh')) {
            unselectAllBuildings(buildingGroups, spvEventDispatchHandler);
        }
    }
    if ([...exclusionIntersections, ...polygonIntersections, ...buildingIntersections].length > 0) {
        if (meshType === MESH_TYPES.EXCLUSION && exclusionIntersections.length > 0) {
            const meshClicked = exclusionIntersections?.at(0);
            if (meshClicked?.selected === true) return;

            if (meshClicked?.name) {
                selectExclusion(meshClicked?.name, exclusionGroups, selectedExclusion, spvEventDispatchHandler, { visibility });
                return;
            }
        } else if (meshType === MESH_TYPES.BUILDING && buildingIntersections.length > 0) {
            const meshClicked = buildingIntersections?.at(0);
            if (meshClicked?.selected === true) return;

            if (meshClicked?.name) {
                selectBuilding(meshClicked?.name, buildingGroups, selectedBuilding, spvEventDispatchHandler, { visibility });
                return;
            }
        } else if (meshType === MESH_TYPES.POLYGON && polygonIntersections.length > 0) {
            const meshClicked = polygonIntersections?.at(0);
            if (meshClicked?.selected === true) return;
            !modes?.[SPV_OVERLAY_MODES.PANEL_EDITOR] &&
                setselectCounter((prev) => {
                    return {
                        mesh: meshClicked?.name,
                        count: prev.count + 1,
                    };
                });
        }
    }
};
export const drawingClickHandler = (mapsMouseEvent, threeVar, setCanDrawPolygon, polygonGroups, spvEventDispatchHandler) => {
    const { threejsOverlay, scene, canDrawPolygon, visibility, getMousePosition } = threeVar;
    //check if can draw
    if (allScenePoints.length > MIN_VERTICES && !canDrawPolygon) {
        return;
    }

    // update mouse position (mainly bcuz touch does not trigger mousemove)
    mousePosition = getMousePosition(mapsMouseEvent?.domEvent);

    //get actual position of the event
    const positionVector = getVectorFromLatLng(mapsMouseEvent, threejsOverlay);

    //Close the Area by clicking in a point
    if (allScenePoints?.length >= MIN_VERTICES) {
        //search only the point visible in the actual polygon
        const intersections = threejsOverlay.raycast(
            mousePosition,
            sceneObjects.polygonPoints?.flatMap((p) => p.point),
            {
                recursive: false,
                raycasterParameters: raycasterParameters(_map),
            }
        );
        if (intersections.filter((i) => i.object.name === 'point').length > 0) {
            closeCurrentPolygon(polygonGroups, spvEventDispatchHandler, setCanDrawPolygon, {
                creatingPolygon: true,
                visibility,
            });
            return;
        }
    }

    // only draw new point if it isn't already drawn
    const positionVectorLatLng = new LatLng(positionVector.newPos.lat, positionVector.newPos.lng);
    if (!allScenePoints.find((p) => p?.coords?.equals(positionVectorLatLng))) {
        const pointToDraw = drawPoint(scene, !isStraightLine || !allScenePoints.length ? positionVector.vector3 : straightLinePosition, {});
        allScenePoints.push({
            point: pointToDraw,
            center: pointToDraw?.center,
            coords:
                !isStraightLine || !allScenePoints.length ?
                    new LatLng(positionVector.newPos)
                :   new LatLng(vector3ToLatLngAlt(straightLinePosition!)),
        });
    }

    const polygonPoints = allScenePoints?.length > 2 ? allScenePoints : allScenePoints;

    sceneObjects.polygonPoints = polygonPoints;

    if (polygonPoints.length > 1) {
        const lineCoords = [
            vector3ToLatLngAlt(polygonPoints[polygonPoints.length - 2].center),
            vector3ToLatLngAlt(polygonPoints[polygonPoints.length - 1].center),
        ];
        const line = drawLine(
            scene,
            [polygonPoints[polygonPoints.length - 2].center, polygonPoints[polygonPoints.length - 1].center],
            MESH_TYPES.POLYGON,
            { lineCoords }
        );
        sceneObjects.lines.push(line);
    }
    _threejsOverlay.scene.remove(..._threejsOverlay.scene.getObjectsByProperty('name', 'guideline'));
};
export const drawingDblClickHandler = (polygonGroups, { spvEventDispatchHandler, setCanDrawPolygon, visibility }) => {
    if (allScenePoints?.length >= MIN_VERTICES) {
        closeCurrentPolygon(polygonGroups, spvEventDispatchHandler, setCanDrawPolygon, {
            creatingPolygon: true,
            visibility,
        });
        removeStraightLineHandler();
    }
    _threejsOverlay.scene.remove(..._threejsOverlay.scene.getObjectsByProperty('name', 'guideline'));
};
export const mouseDownExclusionsHandler = (mapsMouseEvent, threeVar) => {
    const { threejsOverlay, isDrawingExclusions, map, toolbarValue, setToolbarValue } = threeVar;
    hideToolbarOptionsNonMode(toolbarValue, setToolbarValue);

    if (isDrawingExclusions && isDraging) {
        let dragableObjets: Object3D[] = [];
        if (allScenePoints?.length >= MIN_VERTICES) {
            dragableObjets = sceneObjects.polygonPoints?.flatMap((p) => p?.point);
        }
        dragableObjets = exclusionsScene.length > 0 ? dragableObjets.concat(exclusionsScene) : dragableObjets;
        // @ts-ignore
        dragableObjets =
            actualPolygonScene !== null && Object.keys(actualPolygonScene).length > 0 && dragableObjets.concat(actualPolygonScene ?? {});

        const intersections = threejsOverlay.raycast(mousePosition, dragableObjets, {
            recursive: false,
            raycasterParameters: raycasterParameters(_map),
        });

        //check if is a point
        dragPoint = intersections.find((i) => i.object.name === 'point')?.object;
        if (intersections.length > 0 && !!dragPoint) {
            // remove google's drag event
            map.setOptions({
                gestureHandling: 'none',
            });

            isDraging = true;
            mapsMouseEvent.domEvent.stopPropagation();
        }
    }
};
export const drawingExclusionsClickHandler = (
    mapsMouseEvent,
    threeVar,
    setIsDrawingExclusions,
    setHasChangesInExclusions,
    exclusionGroups,
    spvEventDispatchHandler
) => {
    const { threejsOverlay, scene, isDrawingExclusions, visibility, getMousePosition } = threeVar;
    //check if can draw
    if (allScenePoints.length > MIN_EXCLUSIONS_VERTICES && !isDrawingExclusions) {
        return;
    }

    // update mouse position (mainly bcuz touch does not trigger mousemove)
    mousePosition = getMousePosition(mapsMouseEvent?.domEvent);

    //get actual position of the event
    const positionVector = getVectorFromLatLng(mapsMouseEvent, threejsOverlay);

    //Close the Area by clicking in a point
    if (allScenePoints?.length >= MIN_EXCLUSIONS_VERTICES) {
        //search only the point visible in the actual polygon
        const intersections = threejsOverlay.raycast(
            mousePosition,
            sceneObjects.polygonPoints?.flatMap((p) => p.point),
            {
                recursive: false,
                raycasterParameters: raycasterParameters(_map),
            }
        );
        if (intersections.filter((i) => i.object.name === 'point').length > 0) {
            closeCurrentExclusion(exclusionGroups, spvEventDispatchHandler, setIsDrawingExclusions, setHasChangesInExclusions, {
                visibility,
            });
            return;
        }
    }

    // only draw new point if it isn't already drawn
    const positionVectorLatLng = new LatLng(positionVector.newPos.lat, positionVector.newPos.lng);
    if (!allScenePoints.find((p) => p?.coords?.equals(positionVectorLatLng))) {
        const pointToDraw = drawPoint(scene, !isStraightLine || !allScenePoints.length ? positionVector.vector3 : straightLinePosition, {
            meshType: MESH_TYPES.EXCLUSION,
        });
        allScenePoints.push({
            point: pointToDraw,
            center: pointToDraw?.center,
            coords:
                !isStraightLine || !allScenePoints.length ?
                    new LatLng(positionVector.newPos)
                :   new LatLng(vector3ToLatLngAlt(straightLinePosition!)),
        });
    }

    const polygonPoints = allScenePoints;

    sceneObjects.polygonPoints = polygonPoints;

    if (allScenePoints?.length > MIN_EXCLUSIONS_VERTICES) {
        const lineCoords = [
            vector3ToLatLngAlt(polygonPoints[polygonPoints.length - 2].center),
            vector3ToLatLngAlt(polygonPoints[polygonPoints.length - 1].center),
        ];
        const line = drawLine(
            scene,
            [polygonPoints[polygonPoints.length - 2].center, polygonPoints[polygonPoints.length - 1].center],
            MESH_TYPES.EXCLUSION,
            { lineCoords }
        );
        sceneObjects.lines.push(line);
    }
};
export const mouseUpExclusionsHandler = (mapsMouseEvent, threeVar) => {
    const { scene, threejsOverlay, overlay, isDrawingExclusions, map } = threeVar;
    const { domEvent } = mapsMouseEvent;

    if (isDrawingExclusions && isDraging) {
        const positionVector = getVectorFromLatLng(mapsMouseEvent, threejsOverlay);
        if (dragPoint) {
            isDraging = false;

            //find the current point
            const indexPointToRemove = allScenePoints.findIndex((p) => p?.point?.uuid === dragPoint?.uuid);
            //remove the point
            scene.remove(allScenePoints[indexPointToRemove]?.point);

            //Redraw the current point or add a new point(move middle point)
            const pointToDraw = drawPoint(scene, positionVector.vector3, {});
            if (indexPointToRemove !== -1) {
                allScenePoints[indexPointToRemove] = {
                    point: pointToDraw,
                    center: positionVector.vector2,
                    coords: new LatLng(positionVector.newPos),
                };
                lastValidPointUuid = allScenePoints[indexPointToRemove].point.uuid;
            } else {
                allScenePoints.push({
                    point: pointToDraw,
                    center: positionVector.vector2,
                    coords: new LatLng(positionVector.newPos),
                });
                lastValidPointUuid = allScenePoints[allScenePoints.length - 1].point.uuid;
            }

            //Redraw the polygon
            const polygonPoints = allScenePoints;

            drawPolygon(scene, overlay, polygonPoints, MESH_TYPES.EXCLUSION);
            dragPoint = null;
            dragStartPoint = null;
        }

        map.setOptions({
            gestureHandling: 'auto',
        });
    }

    if (isDrawingExclusions && allScenePoints.length >= MIN_EXCLUSIONS_VERTICES) {
        domEvent.target.style.cursor = 'pointer';
        _map.setOptions({
            gestureHandling: 'none',
        });
    }
};
export const drawingDblClickExclusionHandler = (
    exclusionGroups,
    { spvEventDispatchHandler, setIsDrawingExclusions, setHasChangesInExclusions, visibility }
) => {
    if (allScenePoints?.length >= MIN_EXCLUSIONS_VERTICES) {
        closeCurrentExclusion(exclusionGroups, spvEventDispatchHandler, setIsDrawingExclusions, setHasChangesInExclusions, { visibility });
        removeStraightLineHandler();
    }
};
export const mouseDownBuildingsHandler = (mapsMouseEvent, threeVar) => {
    const { threejsOverlay, isDrawingBuildings, map, toolbarValue, setToolbarValue } = threeVar;
    hideToolbarOptionsNonMode(toolbarValue, setToolbarValue);

    if (isDrawingBuildings && isDraging) {
        let dragableObjets: Object3D[] = [];
        if (allScenePoints?.length >= MIN_VERTICES) {
            dragableObjets = sceneObjects.polygonPoints?.flatMap((p) => p?.point);
        }
        dragableObjets = buildingsScene.length > 0 ? dragableObjets.concat(buildingsScene) : dragableObjets;
        // @ts-ignore
        dragableObjets =
            actualPolygonScene !== null && Object.keys(actualPolygonScene).length > 0 && dragableObjets.concat(actualPolygonScene ?? {});

        const intersections = threejsOverlay.raycast(mousePosition, dragableObjets, {
            recursive: false,
            raycasterParameters: raycasterParameters(_map),
        });

        //check if is a point
        dragPoint = intersections.find((i) => i.object.name === 'point')?.object;
        if (intersections.length > 0 && !!dragPoint) {
            // remove google's drag event
            map.setOptions({
                gestureHandling: 'none',
            });

            isDraging = true;
            mapsMouseEvent.domEvent.stopPropagation();
        }
    }
};
export const mouseUpBuildingsHandler = (mapsMouseEvent, threeVar) => {
    const { scene, threejsOverlay, overlay, isDrawingBuildings, map } = threeVar;
    const { domEvent } = mapsMouseEvent;

    if (isDrawingBuildings && isDraging) {
        const positionVector = getVectorFromLatLng(mapsMouseEvent, threejsOverlay);
        if (dragPoint) {
            isDraging = false;

            //find the current point
            const indexPointToRemove = allScenePoints.findIndex((p) => p?.point?.uuid === dragPoint?.uuid);
            //remove the point
            scene.remove(allScenePoints[indexPointToRemove]?.point);

            //Redraw the current point or add a new point(move middle point)
            const pointToDraw = drawPoint(scene, positionVector.vector3, {});
            if (indexPointToRemove !== -1) {
                allScenePoints[indexPointToRemove] = {
                    point: pointToDraw,
                    center: positionVector.vector2,
                    coords: new LatLng(positionVector.newPos),
                };
                lastValidPointUuid = allScenePoints[indexPointToRemove].point.uuid;
            } else {
                allScenePoints.push({
                    point: pointToDraw,
                    center: positionVector.vector2,
                    coords: new LatLng(positionVector.newPos),
                });
                lastValidPointUuid = allScenePoints[allScenePoints.length - 1].point.uuid;
            }

            //Redraw the polygon
            const polygonPoints = allScenePoints;

            drawPolygon(scene, overlay, polygonPoints, MESH_TYPES.BUILDING);
            dragPoint = null;
            dragStartPoint = null;
        }

        map.setOptions({
            gestureHandling: 'auto',
        });
    }

    if (isDrawingBuildings && allScenePoints.length >= MIN_VERTICES) {
        domEvent.target.style.cursor = 'pointer';
    }
};
export const drawingDblClickBuildingHandler = (buildingGroups, { spvEventDispatchHandler, setIsDrawingBuildings, visibility }) => {
    if (allScenePoints?.length >= MIN_VERTICES) {
        closeCurrentBuilding(buildingGroups, spvEventDispatchHandler, setIsDrawingBuildings, { visibility });
        removeStraightLineHandler();
    }
};
export const drawingBuildingsClickHandler = (mapsMouseEvent, threeVar, setIsDrawingBuildings, buildingGroups, spvEventDispatchHandler) => {
    const { threejsOverlay, scene, isDrawingBuildings, visibility, getMousePosition } = threeVar;
    //check if can draw
    if (allScenePoints.length > MIN_VERTICES && !isDrawingBuildings) {
        return;
    }

    // update mouse position (mainly bcuz touch does not trigger mousemove)
    mousePosition = getMousePosition(mapsMouseEvent?.domEvent);

    //get actual position of the event
    const positionVector = getVectorFromLatLng(mapsMouseEvent, threejsOverlay);

    //Close the Area by clicking in a point
    if (allScenePoints?.length >= MIN_VERTICES) {
        const intersections = threejsOverlay.raycast(
            mousePosition,
            allScenePoints?.flatMap((p) => p.point),
            {
                recursive: false,
            }
        );
        if (intersections.filter((i) => i.object.name === 'point').length > 0) {
            closeCurrentBuilding(buildingGroups, spvEventDispatchHandler, setIsDrawingBuildings, { visibility });
            return;
        }
    }

    // only draw new point if it isn't already drawn
    const positionVectorLatLng = new LatLng(positionVector.newPos.lat, positionVector.newPos.lng);
    if (!allScenePoints.find((p) => p?.coords?.equals(positionVectorLatLng))) {
        const pointToDraw = drawPoint(scene, !isStraightLine || !allScenePoints.length ? positionVector.vector3 : straightLinePosition, {
            meshType: MESH_TYPES.BUILDING,
        });
        allScenePoints.push({
            point: pointToDraw,
            center: pointToDraw?.center,
            coords:
                !isStraightLine || !allScenePoints.length ?
                    new LatLng(positionVector.newPos)
                :   new LatLng(vector3ToLatLngAlt(straightLinePosition!)),
        });
    }

    const polygonPoints = allScenePoints;

    sceneObjects.polygonPoints = polygonPoints;

    if (allScenePoints?.length > MIN_EXCLUSIONS_VERTICES) {
        const lineCoords = [
            vector3ToLatLngAlt(polygonPoints[polygonPoints.length - 2].center),
            vector3ToLatLngAlt(polygonPoints[polygonPoints.length - 1].center),
        ];
        const line = drawLine(
            scene,
            [polygonPoints[polygonPoints.length - 2].center, polygonPoints[polygonPoints.length - 1].center],
            MESH_TYPES.BUILDING,
            { lineCoords }
        );
        sceneObjects.lines.push(line);
    }
};
export const orientationClickHandler = (threejsOverlay, polygonGroups, spvEventDispatchHandler, currentOrientation) => {
    const selectedPolygon = polygonGroups?.find((p) => p?.getSelected());
    const orientationMesh = polygonsScene?.find((mesh) => mesh?.name === 'orientationMesh');
    //get intersections from orientationMesh Buttons
    const intersections = threejsOverlay
        .raycast(mousePosition, orientationMesh?.children)
        ?.filter((i) => i?.object?.isMesh)
        ?.map((i) => i?.object);
    //get the orientation from the intersection
    const orientation = parseFloat(intersections?.[0]?.aspect?.toFixed(ORIENTATION_DECIMAL_CFG.decimal));

    //return if clicked on the button already selected
    if (isNumberDefined(orientation) && orientation === currentOrientation) return;

    if (typeof orientationMesh?.getObjectsByProperty !== 'function') return;

    if (isFieldDefined(selectedPolygon?.id) && intersections?.length > 0 && isNumberDefined(orientation)) {
        spvEventDispatchHandler(SpvActions.SET_ORIENTATION, {
            groupId: selectedPolygon?.id,
            [GROUP_INPUTS_NAME.ORIENTATION]: orientation,
            evt: DISPATCH_EVT.SET_ORIENTATION,
        });

        orientationMesh.getObjectsByProperty('isMeshLine', true).forEach((meshLine) => {
            meshLine.material.color.setHex(0x3885cd);
        });

        //change the color of the selected button
        orientationMesh?.children?.forEach((child) => {
            if (child.name === 'orientationButtonMesh') {
                const childParsedAspect = parseFloat((child?.aspect).toFixed(ORIENTATION_DECIMAL_CFG.decimal));
                if (childParsedAspect === orientation) {
                    child.material.color.setHex(0x01173d);
                    const allIntersections = threejsOverlay.raycaster.intersectObjects(
                        [child, ...orientationMesh.getObjectsByProperty('isMeshLine', true)],
                        false
                    );
                    const meshLineSelected = allIntersections.map((i) => i.object).find((el) => el.isMeshLine);
                    if (meshLineSelected) meshLineSelected.material.color.setHex(0x01173d);
                } else {
                    child.material.color.setHex(0x3885cd);
                }
            }
        });
    }
};
export const onDocumentMouseWheel = () => {
    adjustedScaleAllVertexOnHandler(_map);
};
// #endregion Map Events

// #region Drag Controls
export const createDragControls = (
    domElement,
    polygonGroups,
    exclusionGroups,
    buildingGroups,
    meshType,
    { isMovingGroup = false, setIsMovingGroup },
    { useAsStamp = false },
    setHasChangesInExclusions,
    spvEventDispatchHandler
) => {
    // we need to obtain the original center in order to always have the right coords
    if (!originalCenter) originalCenter = _map.getCenter()?.toJSON();

    let dragControls: Nullable<DragControls> = null;

    switch (meshType) {
        case MESH_TYPES.EXCLUSION:
            if (useAsStamp) {
                dragControls = new DragControls(
                    exclusionsScene.filter((exc) => exc.selected),
                    _threejsOverlay.camera,
                    domElement,
                    {
                        overlay: _threejsOverlay,
                        polygons: exclusionGroups,
                        opacity: styles.exclusionZone.fillOpacity.notSelected,
                        isDuplicateBehavior: false,
                        startDragUseAsStamp: true,
                        isExclusionControl: true,
                    }
                );
                dragControls.recursive = false;

                dragControls.addEventListener('dragend', function (event) {
                    const newPoints = event.tempMeshPoints.map((p) => vector3ToLatLngAlt(p));
                    const overwrites = {
                        draggable: true,
                        editable: true,
                        zIndex: 20,
                        height: exclusionGroups?.find((e) => e.getSelected())?.getHeight(),
                    };
                    unselectAllExclusions(exclusionGroups, spvEventDispatchHandler);
                    const newPolygon = buildDefaultExclusionPolygon(newPoints, true, exclusionGroups, overwrites);
                    exclusionGroups.push(newPolygon);
                    buildDefaultAreaMeshFromPolygon(
                        newPolygon,
                        { threejsOverlay: _threejsOverlay, scene: _threejsOverlay.scene },
                        true,
                        MESH_TYPES.EXCLUSION
                    );
                    spvEventDispatchHandler(SpvActions.UPDATE_EXCLUSION_GROUPS, {
                        exclusionGroups: exclusionGroups,
                        setHasChangesInExclusions,
                        selectedExclusion: newPolygon?._id,
                    });
                });
            } else {
                dragControls = new DragControls(
                    exclusionsScene.filter((exc) => exc.selected),
                    _threejsOverlay.camera,
                    domElement,
                    {
                        overlay: _threejsOverlay,
                        polygons: exclusionGroups,
                        opacity: styles.exclusionZone.fillOpacity.notSelected,
                        isDuplicateBehavior: false,
                        canDuplicate: !isMovingGroup,
                    }
                );
                dragControls.recursive = false;
                dragControls.addEventListener('keydown', function () {
                    if (!polygonMoved) polygonMoved = true;
                });
                dragControls.addEventListener('keyup', function () {
                    polygonMoved = false;
                });
                dragControls.addEventListener('dragstart', function () {
                    setIsMovingGroup(true);
                });
                dragControls.addEventListener('dragend', function (event) {
                    setTimeout(() => {
                        if (!dragStartPoint && !dragPoint && event.hasMoved) {
                            const translationGeoCoords = new Vector3().subVectors(moveTranslation.end, moveTranslation.start);
                            exclusionGroups = exclusionGroups.map((pg) => {
                                if (pg.id === event.object.name) {
                                    pg.setOptions({
                                        paths: pg
                                            .getPaths()
                                            .map((p) => new LatLng(p.lat() + translationGeoCoords.x, p.lng() + translationGeoCoords.y)),
                                    });
                                }
                                return pg;
                            });
                            // 🔨: remove all points, and add them again in order to update their position on a new drag
                            event.object.remove(
                                ...event.object.getObjectsByProperty('name', 'point'),
                                ...event.object.getObjectsByProperty('name', 'middlePoint')
                            );
                            addPointsAndMiddlePointsToMesh(event.object, true, MESH_TYPES.EXCLUSION, {
                                points: event.points,
                            });
                            setIsMovingGroup(false);
                            moveTranslation.start.set(0, 0, 0);
                            moveTranslation.end.set(0, 0, 0);
                            spvEventDispatchHandler(SpvActions.UPDATE_EXCLUSION_GROUPS, {
                                exclusionGroups: exclusionGroups,
                                setHasChangesInExclusions,
                            });
                        }
                    }, 100);
                });
                // @ts-ignore
                dragControls.addEventListener('advancedDuplication', function (event) {
                    if (!dragStartPoint && !dragPoint && event.hasMoved) {
                        const newLatLngPoints = event.points.map((p) => vector3ToLatLngAlt(p));
                        const overwrites = {
                            draggable: true,
                            editable: true,
                            zIndex: 20,
                            height: exclusionGroups?.find((e) => e.getSelected())?.getHeight(),
                        };
                        unselectAllExclusions(exclusionGroups, spvEventDispatchHandler);
                        const newPolygon = buildDefaultExclusionPolygon(newLatLngPoints, true, exclusionGroups, overwrites);
                        exclusionGroups.push(newPolygon);
                        buildDefaultAreaMeshFromPolygon(
                            newPolygon,
                            { threejsOverlay: _threejsOverlay, scene: _threejsOverlay.scene },
                            true,
                            MESH_TYPES.EXCLUSION
                        );
                        setIsMovingGroup(false);
                        spvEventDispatchHandler(SpvActions.UPDATE_EXCLUSION_GROUPS, {
                            exclusionGroups: exclusionGroups,
                            setHasChangesInExclusions,
                            selectedExclusion: newPolygon?._id,
                        });
                    }
                });
            }
            break;

        case MESH_TYPES.POLYGON:
            if (isMovingGroup) {
                dragControls = new DragControls(
                    polygonsScene.filter((pol) => pol.selected),
                    _threejsOverlay.camera,
                    domElement,
                    {
                        overlay: _threejsOverlay,
                        polygons: polygonGroups,
                        opacity: styles.polygon.moveFillOpacity,
                        isDuplicateBehavior: true,
                    }
                );
                dragControls.recursive = false;

                // @ts-ignore
                dragControls.addEventListener('dragstart', function () {
                    setIsMovingGroup(true);
                    polygonMoved = true;
                });

                // @ts-ignore
                dragControls.addEventListener('advancedDuplication', function (event) {
                    setTimeout(() => {
                        if (!dragStartPoint && !dragPoint && event.hasMoved) {
                            const translationGeoCoords = new Vector3().subVectors(moveTranslation.end, moveTranslation.start);
                            //const newLatLngPoints = event.points.map((p) => vector3ToLatLngAlt(p));
                            polygonGroups = polygonGroups.map((pg) => {
                                if (pg.id === polygonsScene.find((mesh) => mesh.selected).name) {
                                    pg.setOptions({
                                        //paths: newLatLngPoints.map((p) => new LatLng(p.lat, p.lng)),
                                        paths: pg
                                            .getPaths()
                                            .map((p) => new LatLng(p.lat() + translationGeoCoords.x, p.lng() + translationGeoCoords.y)),
                                        fillOpacity: 1,
                                    });
                                }
                                return pg;
                            });
                            const updatedPolygon = polygonGroups.find((p) => p._id === polygonsScene.find((mesh) => mesh.selected).name);
                            polygonsScene = polygonsScene.map((mesh) => {
                                if (mesh.selected) {
                                    mesh.position.copy(event.object.position);
                                    mesh.material.opacity = styles.polygon.fillOpacity;
                                    // 🔨: remove all points, and add them again in order to update their position on a new drag
                                    mesh.remove(
                                        ...mesh.getObjectsByProperty('name', 'point'),
                                        ...mesh.getObjectsByProperty('name', 'middlePoint')
                                    );
                                    addPointsAndMiddlePointsToMesh(mesh, true, MESH_TYPES.POLYGON, {
                                        points: event.points,
                                    });
                                }
                                return mesh;
                            });
                            // focusOnSelectedPolygon(polygonGroups, { map: _map });
                            setIsMovingGroup(false);
                            polygonMoved = false;
                            moveTranslation.start.set(0, 0, 0);
                            moveTranslation.end.set(0, 0, 0);
                            spvEventDispatchHandler(SpvActions.UPDATE_GROUP, {
                                evt: SpvActions.UPDATE_GROUP,
                                polygon: updatedPolygon,
                                centroid: updatedPolygon?.getCenter()?.toJSON(),
                                draggedPolygon: true,
                                hasUpdatedKPIS: false,
                            });
                        }
                    }, 100);
                });
            }

            if (useAsStamp) {
                dragControls = new DragControls(
                    polygonsScene.filter((pol) => pol.selected),
                    _threejsOverlay.camera,
                    domElement,
                    {
                        overlay: _threejsOverlay,
                        polygons: polygonGroups,
                        opacity: styles.polygon.moveFillOpacity,
                        isDuplicateBehavior: false,
                        startDragUseAsStamp: true,
                    }
                );
                dragControls.recursive = false;

                dragControls.addEventListener('dragend', function (event) {
                    const newPoints = event.tempMeshPoints.map((p) => vector3ToLatLngAlt(p));
                    const polygonToDuplicate = polygonGroups.find((p) => p?.getSelected() === true);

                    // Create duplicate Polygon, with coordinates from temporary Mesh
                    const newPolygon = buildDefaultPolygon(newPoints, true, polygonGroups, {});

                    // Create the duplicate Mesh. Note: Mesh.clone() breaks
                    const newMesh: TPolygonMesh = buildDefaultAreaMeshFromPolygon(newPolygon);

                    // Compute the area center point and updates on the Polygon
                    const center = newMesh.geometry.boundingSphere?.center;
                    const centerLatLng = vector3ToLatLngAlt(center!);
                    newPolygon.setCenter(new LatLng(centerLatLng));

                    spvEventDispatchHandler(SpvActions.STAMP, {
                        evt: SpvActions.STAMP,
                        polygon: newPolygon,
                        idToDuplicate: polygonToDuplicate?.id,
                    });
                });
            }

            break;

        case MESH_TYPES.BUILDING:
            if (isMovingGroup) {
                dragControls = new DragControls(
                    buildingsScene.filter((b) => b.selected),
                    _threejsOverlay.camera,
                    domElement,
                    {
                        overlay: _threejsOverlay,
                        polygons: buildingGroups,
                        opacity: styles.exclusionZone.fillOpacity.notSelected,
                        isDuplicateBehavior: false,
                    }
                );

                // @ts-ignore
                dragControls.addEventListener('dragstart', function () {
                    setIsMovingGroup(true);
                });

                // @ts-ignore
                dragControls.addEventListener('dragend', function (event) {
                    setTimeout(() => {
                        if (!dragStartPoint && !dragPoint && event.hasMoved) {
                            const translationGeoCoords = new Vector3().subVectors(moveTranslation.end, moveTranslation.start);
                            buildingGroups = buildingGroups.map((pg) => {
                                if (pg.id === event.object.name) {
                                    pg.setOptions({
                                        paths: pg
                                            .getPaths()
                                            .map((p) => new LatLng(p.lat() + translationGeoCoords.x, p.lng() + translationGeoCoords.y)),
                                    });
                                }
                                return pg;
                            });
                            // 🔨: remove all points, and add them again in order to update their position on a new drag
                            event.object.remove(
                                ...event.object.getObjectsByProperty('name', 'point'),
                                ...event.object.getObjectsByProperty('name', 'middlePoint')
                            );
                            addPointsAndMiddlePointsToMesh(event.object, true, MESH_TYPES.BUILDING, {
                                points: event.points,
                            });

                            // focusOnSelectedPolygon(buildingGroups, { map: _map });
                            setIsMovingGroup(false);
                            moveTranslation.start.set(0, 0, 0);
                            moveTranslation.end.set(0, 0, 0);
                            spvEventDispatchHandler(SpvActions.UPDATE_BUILDING_GROUPS, {
                                buildingGroups: buildingGroups,
                                polygon: buildingGroups?.find((b) => b.id === event.object.name),
                                id: event.object.name,
                            });
                        }
                    }, 100);
                });
            }
            if (useAsStamp) {
                dragControls = new DragControls(
                    buildingsScene.filter((b) => b.selected),
                    _threejsOverlay.camera,
                    domElement,
                    {
                        overlay: _threejsOverlay,
                        polygons: buildingGroups,
                        opacity: styles.exclusionZone.fillOpacity.notSelected,
                        isDuplicateBehavior: false,
                        startDragUseAsStamp: true,
                    }
                );

                dragControls.addEventListener('dragend', function (event) {
                    const newPoints = event.tempMeshPoints.map((p) => vector3ToLatLngAlt(p));
                    unselectAllBuildings(buildingGroups, spvEventDispatchHandler);
                    const newPolygon = buildDefaultExclusionPolygon(newPoints, true, buildingGroups, {});
                    buildDefaultAreaMeshFromPolygon(
                        newPolygon,
                        { threejsOverlay: _threejsOverlay, scene: _threejsOverlay.scene },
                        true,
                        MESH_TYPES.BUILDING
                    );
                    spvEventDispatchHandler(SpvActions.UPDATE_BUILDING_GROUPS, {
                        buildingGroups: [...buildingGroups, newPolygon],
                        polygon: newPolygon,
                        id: newPolygon.id,
                    });
                });
            }

            break;

        default:
            break;
    }

    return dragControls;
};
// #endregion Drag Controls

// #region Three.js Elements
export const buildDefaultPolygon = (points, isSelected, polygonGroups, overwrites) => {
    try {
        const id = getNextID(polygonGroups, 0, 'id');
        const polygon = new Polygon({
            id,
            paths: points,
            selected: isSelected,
            ...computePolygonStyle(isSelected),
            ...{
                ...overwrites,
            },
        });

        return polygon;
    } catch (error) {
        console.log(error);
        throw error;
    }
};
export const buildDefaultExclusionPolygon = (points, isSelected, polygonGroups, overwrites) => {
    try {
        const id = getNextID(polygonGroups, 0, 'id');
        const polygon = new Polygon({
            id,
            paths: points,
            selected: isSelected,
            ...computeExclusionStyle(isSelected),
            ...{
                zIndex:
                    overwrites?.zIndex ? overwrites?.zIndex
                    : overwrites?.id ? overwrites?.id
                    : id,
                ...overwrites,
            },
        });

        return polygon;
    } catch (error) {
        console.log(error);
        throw error;
    }
};
export const buildDefaultAreaMeshFromPolygon = (polygon, threeVar = {}, addToPolygonScene = true, meshType = MESH_TYPES.POLYGON) => {
    // @ts-ignore
    const threejsOverlay: any = threeVar?.threejsOverlay ?? _threejsOverlay;
    // @ts-ignore
    const scene: any = threeVar?.threejsOverlay?.scene ?? _threejsOverlay?.scene;

    // Get the style from Polygon
    const polygonStyles = polygon.getStyles();

    // Compute points (use unique values only)
    const points = [...new Set(polygon?.getPaths()?.map(JSON.stringify))]?.map((point: any) => {
        const parsedPoint = JSON.parse(point);
        const newPos = {
            lat: parsedPoint.lat,
            lng: parsedPoint.lng,
            altitude: 0,
        };
        const positionVector = threejsOverlay.latLngAltitudeToVector3(newPos);
        return new Vector2(positionVector.x, positionVector.y);
    });
    const polyShape = new Shape(points);

    // Build geometry
    const geometry = new ShapeGeometry(polyShape);
    geometry?.computeBoundingSphere();
    geometry?.computeBoundingBox();

    // Build material
    const material = new MeshBasicMaterial({
        color: polygonStyles?.fillColor ?? styles.polygon.fillColor.selected,
        transparent: true,
        opacity: polygonStyles?.fillOpacity ?? styles.polygon.fillOpacity,
    });

    // Build mesh
    const mesh: TPolygonMesh = new Mesh(geometry, material);
    mesh.name = polygon.id;

    let element: 'area' | 'exclusion' | 'building' = 'area';
    switch (meshType) {
        case MESH_TYPES.EXCLUSION:
            element = 'exclusion';
            break;
        case MESH_TYPES.BUILDING:
            element = 'building';
            break;
        default:
            element = 'area';
            break;
    }
    mesh.element = element;

    // IMPORTANT: set mesh position by doing the following:
    // 1) define the mesh position as the bounding box centre;
    // 2) after that, center the geometry.
    // Polygons will be drawn in the correct places
    geometry.boundingBox?.getCenter(mesh.position);
    geometry.center();

    mesh.selected = polygon?.getSelected();

    // Build wireframe
    const geo = new EdgesGeometry(mesh.geometry);
    const mat = new LineBasicMaterial({
        color: polygonStyles?.strokeColor ?? styles.polygon.strokeColor.selected,
        opacity: polygonStyles?.strokeOpacity ?? styles.polygon.strokeOpacity,
        linewidth: polygonStyles?.strokeWeight ?? styles.polygon.strokeWeight,
    });
    const wireframe = new LineSegments(geo, mat);
    wireframe.name = 'wireframe';
    mesh.add(wireframe);

    // Add Points
    addPointsAndMiddlePointsToMesh(mesh, polygon?.getSelected(), meshType, { points, wireframe });

    // Add to Scene
    scene.add(mesh);

    // Add to array of Area/Group polygons on the scene
    if (addToPolygonScene) {
        switch (meshType) {
            case MESH_TYPES.POLYGON:
                polygonsScene.push(mesh);
                break;
            case MESH_TYPES.EXCLUSION:
                exclusionsScene.push(mesh);
                break;
            case MESH_TYPES.BUILDING:
                buildingsScene.push(mesh);
                break;
            default:
                break;
        }
    }

    // Translates the mesh if selected, to simulate zIndex
    mesh.translateZ(computeMeshZIndexFromPolygon(polygon));

    return mesh;
};
export const buildOrientationMeshFromPolygon = (threeVar, addToPolygonScene = true) => {
    const {
        threejsOverlay = _threejsOverlay,
        scene = threejsOverlay ? threejsOverlay?.scene : _threejsOverlay?.scene,
        edges,
        orientation,
        areaData,
    } = threeVar;

    // Build geometry
    const points = areaData.coordinates.map((edge) => {
        const newPos = {
            lat: edge.lat,
            lng: edge.lng,
            altitude: 0,
        };
        const positionVector = threejsOverlay.latLngAltitudeToVector3(newPos);
        const aspect = edges.find((edgeDS) => edge.lat === edgeDS.points[0].lat && edge.lng === edgeDS.points[0].lng).aspect;
        orientationVerticesAndAspect.push({
            vector: new Vector3(positionVector.x, positionVector.y, 0),
            aspect,
        });
        return new Vector2(positionVector.x, positionVector.y);
    });

    const polyShape = new Shape(points);

    const geometry = new ShapeGeometry(polyShape);
    geometry?.computeBoundingSphere();

    // Build material
    const material = new MeshBasicMaterial({
        color: styles.orientationPolygon.fillColor,
        transparent: true,
        opacity: 1,
    });

    // Build mesh
    const mesh = new Mesh(geometry, material);
    mesh.name = 'orientationMesh';

    // Add rectangles to the middle of each side, adjusting position to the outside of the polygon
    for (let i = 0; i < orientationVerticesAndAspect.length; i++) {
        const currentPoint = orientationVerticesAndAspect[i].vector;
        const nextPoint = orientationVerticesAndAspect[(i + 1) % orientationVerticesAndAspect.length].vector;

        const rectangleAspect = orientationVerticesAndAspect[i].aspect;
        const roundedRectangleAspect = parseFloat((orientationVerticesAndAspect[i]?.aspect).toFixed(ORIENTATION_DECIMAL_CFG.decimal));

        // Calculate the middle point of the side using the getPointInBetweenByPerc function
        const polyLineMiddlePos = getPointInBetweenByPerc(currentPoint, nextPoint, 0.5);

        // Create a square geometry
        const rectangleMesh: TRectangleOrientationMesh = createRectangleOrientationMesh(
            polyLineMiddlePos,
            currentPoint,
            nextPoint,
            orientation,
            roundedRectangleAspect
        );

        // Create a thicker edge
        // @ts-ignore
        const lineMesh = createMeshLine(currentPoint, nextPoint, rectangleMesh.material.color);

        rectangleMesh.aspect = rectangleAspect;

        // Add the square to the mesh
        mesh.add(rectangleMesh);

        // Add the line to the mesh
        mesh.add(lineMesh);
    }

    // Add to Scene
    scene.add(mesh);

    // Add to the array of Area/Group polygons on the scene
    if (addToPolygonScene) polygonsScene.push(mesh);

    return mesh;
};
const drawPoint = (scene, vector3, options) => {
    const { isMiddlePoint = false, middlePointIndex = null, visible = true, meshType = MESH_TYPES.POLYGON } = options;

    const color = meshTypeColor(meshType);

    const pointOpacity = isMiddlePoint ? 0.5 : 1;

    const outerCircle = new Shape();
    outerCircle.absarc(0, 0, isMiddlePoint ? 0.4 : POINT_RADIUS, 0, Math.PI * 2, false);

    // Create the inner circle (hole) path
    const innerCircle = new Shape();
    innerCircle.absarc(0, 0, isMiddlePoint ? 0.3 : POINT_RADIUS - 0.2, 0, Math.PI * 2, true);

    const outlinePointGeometry = new ShapeGeometry(outerCircle);
    const inlinePointGeometry = new ShapeGeometry(innerCircle);
    outerCircle.holes.push(innerCircle);
    outlinePointGeometry?.computeBoundingSphere();
    outlinePointGeometry?.computeBoundingBox();
    inlinePointGeometry?.computeBoundingSphere();
    inlinePointGeometry?.computeBoundingBox();
    const outLinePointMaterial = new MeshBasicMaterial({ color: color, transparent: isMiddlePoint, opacity: pointOpacity });
    const inLinePointMaterial = new MeshBasicMaterial({ color: 0xffffff, transparent: isMiddlePoint, opacity: pointOpacity });
    const point: TPointMesh = new Mesh(outlinePointGeometry, outLinePointMaterial);
    const inLinePoint = new Mesh(inlinePointGeometry, inLinePointMaterial);
    point.add(inLinePoint);
    point.dragging = true;
    point.isPoints = true;
    point.name = 'point';
    point.isMiddlePoint = isMiddlePoint;
    point.position.set(vector3?.x, vector3?.y, vector3?.z);
    const scaleFactor = getAdjustedScaleFactor(_map);
    point.scale.set(scaleFactor, scaleFactor, 1);
    point.center = vector3;
    if (isMiddlePoint) point.middlePointIndex = middlePointIndex;
    point.visible = visible;
    point.translateZ(0.05);

    scene.add(point);

    return point;
};
const drawLine = (
    scene,
    linePoints,
    meshType = MESH_TYPES.POLYGON,
    options: { lineCoords?: Nullable<{ lat: number; lng: number; altitude?: any }[]> }
) => {
    const { lineCoords = null } = options;

    if (
        linePoints?.find((p) => !isDefined(p?.x) || !isDefined(p?.y)) ||
        linePoints?.length < 2 ||
        !isDefined(linePoints?.[0]?.x) ||
        !isDefined(linePoints?.[0]?.y) ||
        !isDefined(linePoints?.[1]?.x) ||
        !isDefined(linePoints?.[1]?.y)
    )
        return;

    const geometryLine = new BufferGeometry().setFromPoints(linePoints);
    const line: TLine = new Line(geometryLine, meshType === MESH_TYPES.POLYGON ? materialLine : materialExclusionLine);
    if (lineCoords) createDimension(lineCoords, linePoints, line, { type: meshType, isSelected: true });
    line.translateZ(0.02);
    scene.add(line);

    return line;
};
const drawPolygon = (scene, overlay, polygonPoints, meshType = MESH_TYPES.POLYGON) => {
    //remove the "previous" polygon drawned
    scene.remove(actualPolygonScene);

    //remove lines and middle points
    sceneObjects.lines?.forEach((line) => scene.remove(line));
    sceneObjects.middlePoints?.forEach((point) => scene.remove(point));
    sceneObjects.lines = [];
    sceneObjects.middlePoints = [];

    scene.remove(sceneObjects.closingLine);
    scene.remove(sceneObjects.drawningLine);
    sceneObjects.closingLine = undefined;
    sceneObjects.drawningLine = undefined;

    //build the mesh

    const newPolygonPoints = polygonPoints?.filter((elm) => isFieldDefined(elm?.center?.x) && isFieldDefined(elm?.center?.y));
    const polyShape = new Shape(newPolygonPoints.map((point) => new Vector2(point?.center?.x, point?.center?.y)));
    const geometry = new ShapeGeometry(polyShape);
    geometry.computeBoundingSphere();
    const material = new MeshBasicMaterial({
        color: meshType === MESH_TYPES.POLYGON ? 'rgb(251, 247, 208)' : 'rgb(195, 218, 240)',
        transparent: true,
        opacity: 0.5,
    });
    const mesh: TPolygonMesh = new Mesh(geometry, material);
    actualPolygonScene = mesh;

    // //add points used to form the polygon
    allScenePoints
        ?.flatMap((p) => p.point)
        ?.filter((i) => polygonPoints?.flatMap((p) => p.point.uuid).includes(i.uuid))
        ?.forEach((pt) => mesh.add(pt));

    //draw the new lines and middle points
    polygonPoints?.forEach((p, index) => {
        const previousCenter = index === 0 ? p.center : polygonPoints?.[index - 1]?.center;
        const previousCoords = index === 0 ? p.coords : polygonPoints?.[index - 1]?.coords;
        const currentCenter = index === 0 ? polygonPoints?.[polygonPoints.length - 1]?.center : p.center;
        const currentCoords = index === 0 ? polygonPoints?.[polygonPoints.length - 1]?.coords : p.coords;
        const linePoints = [previousCenter, currentCenter];
        const coords = [
            { lat: previousCoords.lat(), lng: previousCoords.lng() },
            { lat: currentCoords.lat(), lng: currentCoords.lng() },
        ];

        //draw new line
        const line = drawLine(mesh, linePoints, meshType, { lineCoords: coords });
        sceneObjects.lines.push(line);
    });
    mesh.isSelfIntersected = false;

    let element: 'area' | 'exclusion' | 'building' = 'area';
    switch (meshType) {
        case MESH_TYPES.EXCLUSION:
            element = 'exclusion';
            break;
        case MESH_TYPES.BUILDING:
            element = 'building';
            break;
        default:
            element = 'area';
            break;
    }
    mesh.element = element;

    !!actualPolygonGroup && mesh.add(actualPolygonGroup);

    //draw the polygon on the scene
    mesh.translateZ(0.01);
    scene.add(mesh);
};

const drawPolygonSelfIntersecting = (scene, overlay, polygonPoints, meshType = MESH_TYPES.POLYGON) => {
    //remove the "previous" polygon drawned
    scene.remove(actualPolygonScene);

    //remove lines and middle points
    sceneObjects.lines?.forEach((line) => scene.remove(line));
    sceneObjects.middlePoints?.forEach((point) => scene.remove(point));
    sceneObjects.lines = [];
    sceneObjects.middlePoints = [];

    scene.remove(sceneObjects.closingLine);
    scene.remove(sceneObjects.drawningLine);
    sceneObjects.closingLine = undefined;
    sceneObjects.drawningLine = undefined;

    //build the mesh
    const polyShapes = polygonPoints?.map(
        (subPolygon) => new Shape(subPolygon?.map((point) => new Vector2(point?.center?.x, point?.center?.y)))
    );
    const geometry = new ShapeGeometry(polyShapes);
    geometry.computeBoundingSphere();
    const material = new MeshBasicMaterial({
        color: meshType === MESH_TYPES.POLYGON ? 'rgb(251, 247, 208)' : 'rgb(195, 218, 240)',
        transparent: true,
        opacity: 0.5,
    });
    const mesh: TPolygonMesh = new Mesh(geometry, material);
    actualPolygonScene = mesh;

    // //add points used to form the polygon
    allScenePoints
        ?.flatMap((p) => p.point)
        ?.filter((i) =>
            polygonPoints
                ?.flat()
                ?.flatMap((p) => p.point.uuid)
                .includes(i.uuid)
        )
        ?.forEach((pt) => mesh.add(pt));

    mesh.isSelfIntersected = true;

    !!actualPolygonGroup && mesh.add(actualPolygonGroup);

    //draw the polygon on the scene
    scene.add(mesh);
};

export const createRectangleOrientationMesh = (polyLineMiddlePos, currentPoint, nextPoint, orientation, roundedRectangleAspect) => {
    const isCurrentOrientationRectangle = orientation === roundedRectangleAspect;
    let rectangleCenter;

    // Define the width and height for the rectangular shape
    const rectangleWidth = 2.5; // Adjust the width as needed
    const rectangleHeight = 1.5; // Adjust the height as needed
    const cornerRadius = 0.25; // Adjust the radius for rounded corners

    // Calculate the direction of the side
    const sideDirection = nextPoint.clone().sub(currentPoint).normalize();

    // Calculate the perpendicular direction to the side
    const perpendicularDirection = new Vector2(-sideDirection.y, sideDirection.x);

    // Offset the rectangle from the middle point in the perpendicular direction
    const offset = perpendicularDirection.clone().multiplyScalar(rectangleHeight / 2);

    // Offset the rectangle center outside the polygon
    rectangleCenter = polyLineMiddlePos.clone().add(offset);

    const isInside = isPointInsidePolygon(rectangleCenter, orientationVerticesAndAspect);

    // If the rectangle is inside the polygon, flip the perpendicular direction and offset the rectangle again
    if (isInside) {
        perpendicularDirection.negate();
        // Offset the rectangle from the middle point in the perpendicular direction
        const offset = perpendicularDirection.clone().multiplyScalar(rectangleHeight / 2);
        // Offset the rectangle center outside the polygon
        rectangleCenter = polyLineMiddlePos.clone().add(offset);
    }

    // Calculate the rotation angle to align one side with the side of the polygon
    const angle = Math.atan2(sideDirection.y, sideDirection.x);

    // Create a rectangle shape with rounded top corners
    const rectangleShape = new Shape();
    if (!isInside) {
        rectangleShape.moveTo(-rectangleWidth / 2, -rectangleHeight / 2);
        rectangleShape.lineTo(rectangleWidth / 2, -rectangleHeight / 2);
        rectangleShape.lineTo(rectangleWidth / 2, rectangleHeight / 2 - cornerRadius);
        rectangleShape.quadraticCurveTo(rectangleWidth / 2, rectangleHeight / 2, rectangleWidth / 2 - cornerRadius, rectangleHeight / 2);
        rectangleShape.lineTo(-rectangleWidth / 2 + cornerRadius, rectangleHeight / 2);
        rectangleShape.quadraticCurveTo(-rectangleWidth / 2, rectangleHeight / 2, -rectangleWidth / 2, rectangleHeight / 2 - cornerRadius);
    } else {
        rectangleShape.moveTo(-rectangleWidth / 2, rectangleHeight / 2);
        rectangleShape.lineTo(rectangleWidth / 2, rectangleHeight / 2);
        rectangleShape.lineTo(rectangleWidth / 2, -rectangleHeight / 2 + cornerRadius);
        rectangleShape.quadraticCurveTo(rectangleWidth / 2, -rectangleHeight / 2, rectangleWidth / 2 - cornerRadius, -rectangleHeight / 2);
        rectangleShape.lineTo(-rectangleWidth / 2 + cornerRadius, -rectangleHeight / 2);
        rectangleShape.quadraticCurveTo(
            -rectangleWidth / 2,
            -rectangleHeight / 2,
            -rectangleWidth / 2,
            -rectangleHeight / 2 + cornerRadius
        );
    }
    rectangleShape.closePath();

    // Create a geometry for the shape
    const rectangleGeometry = new ShapeGeometry(rectangleShape);

    // Create a material for the rectangle
    const rectangleMaterial = new MeshBasicMaterial({
        color: isCurrentOrientationRectangle ? 0x01173d : 0x3885cd,
        transparent: true,
        opacity: 1,
    });

    // Create a mesh for the rectangle
    const rectangleMesh = new Mesh(rectangleGeometry, rectangleMaterial);
    rectangleMesh.name = `orientationButtonMesh`;

    // Position and rotate the rectangle mesh
    rectangleMesh.position.set(rectangleCenter.x, rectangleCenter.y, 0.3);
    rectangleMesh.rotation.z = angle;

    // Load and position the SVG content
    const loader = new SVGLoader();
    loader.load(
        // resource URL
        orientationArrow,
        // called when the resource is loaded
        function (data) {
            const paths = data.paths;
            const group = new Group();

            let arrowWidth = 9;
            let arrowHeight = 0;

            for (let i = 0; i < paths.length; i++) {
                const path = paths[i];

                const material = new MeshBasicMaterial({
                    color: ORIENTATION_ARROW_SVG_ATTRIBUTES.fillColor,
                });

                const shapes = SVGLoader.createShapes(path);

                for (let j = 0; j < shapes.length; j++) {
                    const shape = shapes[j];
                    const geometry = new ShapeGeometry(shape);
                    geometry.computeBoundingBox();
                    const mesh = new Mesh(geometry, material);
                    group.add(mesh);
                }
            }
            //scale the SVG content to fit the square
            group.scale.set(0.07, 0.04, 0);

            const groupBoundingBox = new Box3().setFromObject(group);
            arrowWidth = groupBoundingBox.max.x - groupBoundingBox.min.x;
            arrowHeight = groupBoundingBox.max.y - groupBoundingBox.min.y;

            //rotate svg 180 degrees if the polygon is clockwise
            group.rotation.set(0, 0, !isInside ? Math.PI : 0);

            //group position inside the square
            !isInside ?
                group.position.set(rectangleWidth / 2 - arrowWidth / 2, rectangleHeight / 2 - arrowHeight / 2, 0.31)
            :   group.position.set(-rectangleWidth / 2 + arrowWidth / 2, -rectangleHeight / 2 + arrowHeight / 2, 0.31);

            //add the SVG content to the square
            rectangleMesh.add(group);
        }
    );

    return rectangleMesh;
};
const materialLine = new LineBasicMaterial({
    color: styles.polygon.strokeColor.selected,
    linewidth: 5,
    side: DoubleSide,
});
const materialExclusionLine = new LineBasicMaterial({
    color: styles.exclusionZone.strokeColor.selected,
    linewidth: 5,
    side: DoubleSide,
});
export const createMeshLine = (currentPoint, nextPoint, color) => {
    const meshGeo = new MeshLineGeometry();
    meshGeo.setPoints([currentPoint, nextPoint]);

    // @ts-ignore
    const materialLine = new MeshLineMaterial({
        color: new Color(color),
        transparent: true,
        opacity: 0.8,
        lineWidth: 0.5,
        resolution: new Vector2(window.innerWidth, window.innerHeight),
    });

    // @ts-ignore
    const line = new MeshLine(meshGeo, materialLine);
    // line.translateZ(0.02);

    return line;
};
export const drawGroupPanels = (usefulAreaData, areas, maxTecPanels, isEditingExclusions, { threejsOverlay, selectedArea, visibility }) => {
    try {
        const scene = threejsOverlay?.scene;

        if (panelsScene?.length !== 0) {
            //remove points from actaul polygon
            panelsScene.forEach((p) => {
                scene?.remove(p);
            });
            panelsScene = [];
        }

        const areasPanelsSum = areas.reduce((acc, area) => acc + area.panels_number_possible, 0);
        const invalidPanelColor =
            maxTecPanels >= areasPanelsSum ? styles.polygon.fillColor.panelNotSelected : styles.polygon.fillColor.panelInvalid;

        const solarPanels: Polygon[] = [];

        if (usefulAreaData?.length > 0) {
            usefulAreaData
                .sort((a, b) => a.id - b.id)
                .map((data, aIdx) => {
                    // row of panels
                    data.panel_rows.forEach((group, rIdx) => {
                        const paths: LatLng[] = [];
                        group.position.forEach((p) => {
                            paths.push(new LatLng(p[0], p[1]));
                        });

                        const isSelected = areas?.find((el) => el.selected)?.id === data.id;
                        const isValid = group?.valid ?? true;
                        const polygon = new Polygon({
                            id: 'panel-' + rIdx + '-' + data?.id ?? aIdx,
                            selected: isSelected,
                            paths: paths,
                            // options
                            ...computePanelStyle(isEditingExclusions, isValid, invalidPanelColor),
                            isValid: isValid,
                            visible: !group.excluded,
                        });

                        solarPanels.push(polygon);
                    });
                    return data;
                });
        }

        if (usefulAreaData?.length > 0) {
            usefulAreaData
                .sort((a, b) => a.id - b.id)
                .map((data) => {
                    const foundArea = areas.find((area) => area.id === data.id);
                    if (isDefined(data?.coordinates_setbacks) && isFieldDefined(foundArea)) {
                        createSetBack(data.coordinates_setbacks, {
                            mesh: polygonsScene.find((pol) => pol.name === data.id),
                            area: foundArea,
                            selectedArea,
                            visibility,
                        });
                    }
                });
        }

        // Create 'n' groups on demand (n = # areas), so that
        // polygonsScene.length === allGroups.length
        const allGroups: Group[] = [];
        for (let i = 0; i < polygonsScene?.length ?? 0; i++) {
            allGroups.push(new Group());
        }

        if (polygonsScene?.length > 0) {
            polygonsScene.forEach((pol, index) => {
                const filterValid = solarPanels.filter(
                    (p) => Number(p._id.toString().split('-').pop()) === pol?.name && p.isValid && p.visible
                );
                const filterInvalid = solarPanels.filter(
                    (p) => Number(p._id.toString().split('-').pop()) === pol?.name && !p.isValid && p.visible
                );

                filterValid.length > 0 && computePanelsInstancedMesh(filterValid, pol, allGroups[index], true, { threejsOverlay });
                filterInvalid.length > 0 &&
                    computePanelsInstancedMesh(filterInvalid, pol, allGroups[index], false, {
                        threejsOverlay,
                    });
                const prevGroup = pol.children.find((ch) => ch.isGroup);
                solarPanels.length === 0 && !!prevGroup && pol.remove(prevGroup);
            });
        }

        solarPanels?.length > 0 &&
            polygonsScene
                .sort((a, b) => a.name - b.name)
                .forEach((pol, i) => {
                    // check if there was a group before; remove it if true
                    const prevGroup = pol.children.find((ch) => ch.isGroup);
                    if (prevGroup) pol.remove(prevGroup);
                    // set group position
                    allGroups[i].position.copy(pol.position.clone().negate());
                    // set panels 'z' position to be the same as the area panel
                    allGroups[i].children?.forEach((panel) => (panel.position.z = pol.position.z));
                    // add group to area mesh
                    allGroups[i].visible = visibility.panels;
                    pol.add(allGroups[i]);
                });

        return solarPanels;
    } catch (error) {
        console.log(error);
        throw error;
    }
};
export const drawEditorPanels = (useFullArea, inputs, options) => {
    return new Promise<void>((resolve, reject) => {
        try {
            const { areas } = inputs;
            const { panel, selectedArea } = options ?? {};
            const isSelected = selectedArea;
            const _useFullArea = useFullArea.sort((a, b) => a.id - b.id).filter((ua) => ua.id === isSelected);
            const panelsExcludedInInputs = areas?.find((el) => el.id === isSelected)?.manual_removed_idxs ?? [];
            const panelsAddedInInputs = areas?.find((el) => el.id === isSelected)?.manual_added_idxs ?? [];

            if (isDefined(panel)) {
                if (okPanels.includes(panel.id)) {
                    okPanels = okPanels.filter((okPanel) => okPanel !== panel.id);
                    nokPanels.push(panel.id);
                    excludedPanelsCounterClick++;
                } else {
                    nokPanels = nokPanels.filter((nokPanel) => nokPanel !== panel.id);
                    okPanels.push(panel.id);
                    excludedPanelsCounterClick--;
                }
            } else {
                okPanels = [];
                nokPanels = [];
                excludedPanelsCounterClick = 0;
                _useFullArea?.[0]?.panel_rows?.forEach((panel) => {
                    const isExcluded = panel?.excluded;
                    const isValid = isDefined(panel?.valid) ? panel.valid : true;

                    if (!isExcluded && isValid) okPanels.push(JSON.stringify(panel?.id));
                    if (isExcluded || !isValid) nokPanels.push(JSON.stringify(panel?.id));
                });
                okPanels = [...new Set([...panelsAddedInInputs, ...okPanels.filter((panel) => !panelsExcludedInInputs.includes(panel))])];
                nokPanels = [...new Set([...panelsExcludedInInputs, ...nokPanels.filter((panel) => !panelsAddedInInputs.includes(panel))])];
            }

            const polygonScene = polygonsScene?.find((ps) => ps.name === isSelected);
            const currentGroup = polygonScene?.children?.find((ch) => ch.isGroup);

            if (currentGroup && typeof polygonScene.remove === 'function') polygonScene.remove(currentGroup);

            const tempSolarPanels: Polygon[] = [];

            if (_useFullArea?.length > 0) {
                _useFullArea.map((data) => {
                    data.panel_rows.forEach((group) => {
                        const id = JSON.stringify(group.id);
                        const paths: LatLng[] = [];
                        group.position.forEach((p) => {
                            paths.push(new LatLng(p[0], p[1]));
                        });

                        const isSelected = areas?.find((el) => el.selected)?.id === data.id;
                        const isExcluded = nokPanels.includes(id);
                        const polygon = new Polygon({
                            id: id,
                            selected: isSelected,
                            paths: paths,
                            // options
                            ...computePanelStyle(false, !isExcluded),
                            isExcluded,
                            isValid: group.valid,
                        });

                        tempSolarPanels.push(polygon);
                    });

                    return data;
                });
            }

            const tempGroup = new Group();
            const panelsNotExcluded = tempSolarPanels.filter((panel) => !panel.isExcluded);
            const panelsExcluded = tempSolarPanels.filter((panel) => panel.isExcluded);
            if (panelsNotExcluded.length > 0)
                computeTempPanelsInstancedMesh(panelsNotExcluded, polygonScene, tempGroup, true, {
                    threejsOverlay: _threejsOverlay,
                });
            if (panelsExcluded.length > 0)
                computeTempPanelsInstancedMesh(panelsExcluded, polygonScene, tempGroup, false, {
                    threejsOverlay: _threejsOverlay,
                });

            if (tempSolarPanels?.length > 0) {
                tempGroup.position.copy(polygonScene.position.clone().negate());
                tempGroup.children?.forEach((panel) => (panel.position.z = polygonScene.position.z));
                polygonScene.add(tempGroup);
            }
            threejsBlocked = true;
            setTimeout(() => {
                threejsBlocked = false;
            }, 25);

            return resolve();
        } catch (e) {
            reject(e);
        }
    });
};
export const drawPanelsInMiniOverlay = (usefulAreaData, areas, PanelGroups, maxTecPanels, isEditingExclusions, { threejsOverlay }) => {
    try {
        const scene = threejsOverlay?.scene;

        const areasPanelsSum = areas.reduce((acc, area) => acc + area.panels_number_possible, 0);
        const invalidPanelColor =
            maxTecPanels >= areasPanelsSum ? styles.polygon.fillColor.panelNotSelected : styles.polygon.fillColor.panelInvalid;

        const solarPanels: Polygon[] = [];

        if (usefulAreaData?.length > 0) {
            usefulAreaData
                .sort((a, b) => a.id - b.id)
                .map((data, aIdx) => {
                    // row of panels
                    data.panel_rows.forEach((group, rIdx) => {
                        const paths: LatLng[] = [];
                        group.position.forEach((p) => {
                            paths.push(new LatLng(p[0], p[1]));
                        });

                        const isSelected = areas?.find((el) => el.selected)?.id === data.id;
                        const isValid = group?.valid ?? true;
                        const polygon = new Polygon({
                            id: 'panel-' + rIdx + '-' + data?.id ?? aIdx,
                            selected: isSelected,
                            paths: paths,
                            // options
                            ...computePanelStyle(isEditingExclusions, isValid, invalidPanelColor),
                            isValid: isValid,
                            visible: !group.excluded,
                        });

                        solarPanels.push(polygon);
                    });
                    return data;
                });
        }

        // Create 'n' groups on demand (n = # areas), so that
        // polygonsScene.length === allGroups.length
        const allGroups: Group[] = [];
        for (let i = 0; i < polygonsScene?.length ?? 0; i++) {
            allGroups.push(new Group());
        }

        if (polygonsScene?.length > 0) {
            polygonsScene.forEach((pol, index) => {
                const filterValid = solarPanels.filter(
                    (p) => Number(p._id.toString().split('-').pop()) === pol?.name && p.isValid && p.visible
                );
                const filterInvalid = solarPanels.filter(
                    (p) => Number(p._id.toString().split('-').pop()) === pol?.name && !p.isValid && p.visible
                );

                filterValid.length > 0 && computePanelsInstancedMesh(filterValid, pol, allGroups[index], true, { threejsOverlay });
                filterInvalid.length > 0 &&
                    computePanelsInstancedMesh(filterInvalid, pol, allGroups[index], false, {
                        threejsOverlay,
                    });
            });
        }

        allGroups.forEach((group) => scene.add(group));

        return solarPanels;
    } catch (error) {
        console.log(error);
        throw error;
    }
};
const createSetBack = (setbackCoordinates, { mesh, area, selectedArea, visibility }) => {
    // Compute points (use unique values only)
    // Build geometry
    if (isDefined(mesh)) {
        const previousSetback = mesh.getObjectsByProperty('name', 'setback');
        previousSetback.forEach((setback) => {
            mesh?.remove(setback);
        });
        if (setbackCoordinates.length > 0) {
            const points = area?.coordinates.map((edge) => {
                const newPos = {
                    lat: edge.lat,
                    lng: edge.lng,
                    altitude: 0,
                };
                const positionVector = _threejsOverlay.latLngAltitudeToVector3(newPos);
                // const aspect = firstAreaId === areaData.id ? edge.aspect : edges[idx === 0 ? edges.length - 1 : idx - 1].aspect;
                return new Vector2(positionVector.x, positionVector.y);
            });

            const setBackPosition = setbackCoordinates[0].map((coord) => {
                const newPos = {
                    lat: coord[0],
                    lng: coord[1],
                    altitude: 0,
                };
                const positionVector = _threejsOverlay.latLngAltitudeToVector3(newPos);
                return new Vector2(positionVector.x, positionVector.y);
            });

            const polyShape = new Shape(points);

            const innerSetBackShape = new Shape(setBackPosition?.map((point) => new Vector2(point.x, point.y)));

            polyShape.holes.push(innerSetBackShape);

            // polyShape.holes.push(innerSetBackShape);
            const geometry = new ShapeGeometry(polyShape);
            geometry?.computeBoundingSphere();
            geometry?.computeBoundingBox();

            // Build material
            const material = new MeshBasicMaterial({
                color: styles.polygon.setback,
                transparent: true,
                opacity: 0.1,
            });

            // Build mesh
            const setBackMesh: Mesh & { isSetBack?: boolean } = new Mesh(geometry, material);
            setBackMesh.name = 'setback';

            setBackMesh.translateZ(0.04);
            setBackMesh.visible = selectedArea === area.id && visibility.areas;
            setBackMesh.isSetBack = true;
            if (isDefined(mesh?.position)) setBackMesh?.position?.sub(mesh?.position);
            mesh.add(setBackMesh);
        }
    }
};
const createDimension = (coords, points, line, { type = MESH_TYPES.POLYGON, isSelected }) => {
    const distance = getGeographicalyDistance(coords[0], coords[1]).toFixed(2);
    const midpoint = new Vector3();
    midpoint.copy(points[0]);
    midpoint.add(points[1]).multiplyScalar(0.5);

    const loader = new FontLoader();
    const font = loader.parse(HelvetikerFont);
    const textGeometry = new TextGeometry(`${distance} m`, { font: font, size: 0.8, height: 0.1 });
    textGeometry?.computeBoundingBox();
    textGeometry?.computeBoundingSphere();
    const textColor = type === MESH_TYPES.POLYGON ? 0x000 : styles.polygon.strokeColor.panelNotSelected;
    const textMaterial = new MeshBasicMaterial({ color: textColor });
    const textMesh: Mesh<TextGeometry, MeshBasicMaterial, Object3DEventMap> & {
        points?: Vector3[];
        midpoint?: Vector3;
        originalPosition?: Vector3;
        isMeasurement?: boolean;
    } = new Mesh(textGeometry, textMaterial);
    const adjustedScaleFactor = getAdjustedScaleFactor(_map);

    textMesh.scale.set(adjustedScaleFactor, adjustedScaleFactor, 1);
    if (!textGeometry?.boundingBox) return;
    const backgroundGeometry = new BoxGeometry(
        textGeometry.boundingBox.max.x - textGeometry.boundingBox.min.x + 1,
        textGeometry.boundingBox.max.y - textGeometry.boundingBox.min.y + 1,
        0
    );
    backgroundGeometry.computeBoundingBox();
    backgroundGeometry.computeBoundingSphere();
    const backgroundColor = type === MESH_TYPES.POLYGON ? styles.polygon.strokeColor.selected : styles.exclusionZone.strokeColor.selected;

    const backgroundMaterial = new MeshBasicMaterial({ color: backgroundColor });
    const backgroundMesh: Mesh<BoxGeometry, MeshBasicMaterial, Object3DEventMap> & { isMeasurement?: boolean } = new Mesh(
        backgroundGeometry,
        backgroundMaterial
    );

    const angle = Math.atan2(points[1].y - points[0].y, points[1].x - points[0].x);
    const distanceFromMidpoint = -2 * adjustedScaleFactor; // Adjust the distance as needed
    const perpendicularVector = new Vector3(points[1].y - points[0].y, -(points[1].x - points[0].x), 0).normalize();
    const offsetVector = perpendicularVector.multiplyScalar(distanceFromMidpoint);
    textMesh.points = [points[0], points[1]];
    textMesh.midpoint = midpoint;
    textMesh.position.set(midpoint.x + offsetVector.x, midpoint.y + offsetVector.y, 0);
    textMesh.originalPosition = textMesh.position;
    textMesh.rotation.z = angle + (points[0].x < points[1].x ? 0 : Math.PI);

    // @ts-ignore
    backgroundMesh.boundingBox?.getCenter(textMesh.position);
    textMesh.geometry.center();
    textMesh.translateZ(0.02);

    textMesh.add(backgroundMesh);
    backgroundMesh.isMeasurement = true;
    textMesh.isMeasurement = true;
    textMesh.name = 'measurement';
    textMesh.visible = isSelected;
    line.add(textMesh);
};
// endregion Three.js Elements

// #region Three.js Elements Compute
const computePanelsInstancedMesh = (solarPanels, polygonScene, group, isValid, { threejsOverlay = _threejsOverlay }) => {
    const panelStyles = solarPanels[0].getStyles();

    // this array will contain the vertices of every single panel
    const allPanelsPoints: Vector3[][] = [];
    // this array will contain the vertices of every single panel, with a small internal spacing
    const allInteriorPanelsPoints: Vector3[][] = [];
    // the offset corresponding to the internal spacing
    const INTERNAL_PANEL_SPACING_OFFSET = 0.03;

    solarPanels.forEach((panel) => {
        const panelPoints: Vector3[] = [];

        panel.getPaths().forEach((point) => {
            const newPos = {
                lat: point.lat(),
                lng: point.lng(),
                altitude: 0,
            };
            // cartesian coordinates for the vertice
            const positionVector: Vector3 = threejsOverlay.latLngAltitudeToVector3(newPos);

            panelPoints.push(positionVector);
        });

        allPanelsPoints.push(panelPoints);
    });

    allPanelsPoints.forEach((panel) => {
        const interiorPanelPoints: Vector3[] = [];
        // get the panel center point
        const center = getCenterPointFromCoords(panel);

        // obtain the interior points. the algorithm is as follows:
        // 1) compute the direction vector [point -> center];
        // 2) normalize this vector (length = 1);
        // 3) multiply the normalized vector by the offset desired.
        // this vector corresponds to the direction vector [point -> interiorPoint].
        // 4) the interior point will be the result of adding [point] and this vector.
        panel.forEach((coord) => {
            const vector = new Vector3().subVectors(center, coord).normalize().multiplyScalar(INTERNAL_PANEL_SPACING_OFFSET);
            const interiorPoint = new Vector3().addVectors(coord, vector);
            interiorPanelPoints.push(interiorPoint);
        });

        allInteriorPanelsPoints.push(interiorPanelPoints);
    });

    // the base shape for a single panel
    const polyShape = new Shape(allPanelsPoints[0]?.map((point) => new Vector2(point.x, point.y)));
    // the base shape for the outline of a panel:
    // 1) the outline is the same as the base shape;
    // 2) then, we subtract the inner shape computed before.
    const outerShape = copy(polyShape);
    const innerShape = new Shape(allInteriorPanelsPoints[0]?.map((point) => new Vector2(point.x, point.y)));
    outerShape.holes.push(innerShape);

    const baseGeometry = new ShapeGeometry(polyShape);
    const outlineGeometry = new ShapeGeometry(outerShape);

    const baseMaterial = new MeshBasicMaterial({
        color: panelStyles.fillColor,
        transparent: true,
        opacity: panelStyles.fillOpacity,
    });
    const outlineMaterial = new MeshBasicMaterial({
        color: panelStyles.strokeColor,
        transparent: true,
        opacity: panelStyles.strokeOpacity,
    });

    // the mesh that generates N panels similar to the one created in baseGeometry
    const instancedMesh: TPanelInstanceMesh = new InstancedMesh(baseGeometry, baseMaterial, allPanelsPoints.length);
    // the mesh that generates N panel outlines similar to the one created in outlineGeometry
    const outlineInstancedMesh: TPanelInstanceMesh = new InstancedMesh(outlineGeometry, outlineMaterial, allPanelsPoints.length);

    // this is a trick. we instance an Object3D in order to update its matrix and ease our computations.
    const dummy = new Object3D();
    for (let i = 0; i < allPanelsPoints.length; i++) {
        // get the center of the panel
        const { x, y, z } = getCenterPointFromCoords(allPanelsPoints[i]);

        // we position each panel (and, subsequently, its outline) aligned to its real center
        dummy.position.x = x;
        dummy.position.y = y;
        dummy.position.z = z;

        // then, we update the object matrix
        dummy.updateMatrix();

        // after that, we update the matrix for each panel in our mesh
        instancedMesh.setMatrixAt(i, dummy.matrix);
        outlineInstancedMesh.setMatrixAt(i, dummy.matrix);
    }

    [instancedMesh, outlineInstancedMesh].forEach((mesh) => {
        // this flag needs to be set to 'true', since we have updated it in the above 'for' loop.
        mesh.instanceMatrix.needsUpdate = true;

        mesh.computeBoundingBox();
        polygonScene.boundingBox?.getCenter(mesh.position);
        mesh.geometry.center();

        mesh.isValid = isValid;

        panelsScene.push(mesh);
        group.add(mesh);
    });

    // these flags are used for styling changes; keep them
    instancedMesh.isPanels = true;
    outlineInstancedMesh.isPanelsOutline = true;
};
const computeTempPanelsInstancedMesh = (tempSolarPanels, polygonScene, group, isValid, { threejsOverlay = _threejsOverlay }) => {
    const panelStyles = tempSolarPanels[0].getStyles();

    const allPanelsPoints: TPanelPoints[][] = [];
    const allInteriorPanelsPoints: Vector3[][] = [];
    const INTERNAL_PANEL_SPACING_OFFSET = 0.03;

    tempSolarPanels.forEach((panel) => {
        const panelPoints: TPanelPoints[] = [];

        panel.getPaths().forEach((point) => {
            const newPos = {
                lat: point.lat(),
                lng: point.lng(),
                altitude: 0,
            };
            const positionVector: Vector3 = threejsOverlay.latLngAltitudeToVector3(newPos);

            panelPoints.push({ vector: positionVector, panelId: panel.id });
        });

        allPanelsPoints.push(panelPoints);
    });

    allPanelsPoints.forEach((panel) => {
        const points = panel.map((coord) => coord.vector);
        const interiorPanelPoints: Vector3[] = [];
        // get the panel center point
        const center = getCenterPointFromCoords(points);

        points.forEach((coord) => {
            const vector = new Vector3().subVectors(center, coord).normalize().multiplyScalar(INTERNAL_PANEL_SPACING_OFFSET);
            const interiorPoint = new Vector3().addVectors(coord, vector);
            interiorPanelPoints.push(interiorPoint);
        });

        allInteriorPanelsPoints.push(interiorPanelPoints);
    });

    const polyShape = new Shape(allPanelsPoints[0]?.map((point) => new Vector2(point.vector.x, point.vector.y)));
    const outerShape = copy(polyShape);
    const innerShape = new Shape(allInteriorPanelsPoints[0]?.map((point) => new Vector2(point.x, point.y)));
    outerShape.holes.push(innerShape);

    const baseGeometry = new ShapeGeometry(polyShape);
    const outlineGeometry = new ShapeGeometry(outerShape);

    const baseMaterial = new MeshBasicMaterial({
        color: panelStyles.fillColor,
        transparent: true,
        opacity: panelStyles.fillOpacity,
    });
    const outlineMaterial = new MeshBasicMaterial({
        color: panelStyles.strokeColor,
        transparent: true,
        opacity: panelStyles.strokeOpacity,
    });

    const instancedMesh: TPanelInstanceMesh = new InstancedMesh(baseGeometry, baseMaterial, allPanelsPoints.length);
    const outlineInstancedMesh: TPanelInstanceMesh = new InstancedMesh(outlineGeometry, outlineMaterial, allPanelsPoints.length);

    const dummy = new Object3D();
    for (let i = 0; i < allPanelsPoints.length; i++) {
        const points = allPanelsPoints[i].map((coord) => coord.vector);
        const { x, y, z } = getCenterPointFromCoords(points);

        dummy.position.x = x;
        dummy.position.y = y;
        dummy.position.z = z;

        dummy.updateMatrix();

        instancedMesh.setMatrixAt(i, dummy.matrix);
        outlineInstancedMesh.setMatrixAt(i, dummy.matrix);
    }

    [instancedMesh, outlineInstancedMesh].forEach((mesh) => {
        // this flag needs to be set to 'true', since we have updated it in the above 'for' loop.
        mesh.instanceMatrix.needsUpdate = true;

        mesh.computeBoundingBox();
        polygonScene.boundingBox?.getCenter(mesh.position);
        mesh.geometry.center();

        mesh.isValid = isValid;

        panelsScene.push(mesh);
        group.add(mesh);
    });

    // these flags are used for styling changes; keep them
    instancedMesh.isPanels = true;
    instancedMesh.isTempPanels = true;
    instancedMesh.panelPoints = allPanelsPoints.map((panel) => {
        return {
            id: panel[0].panelId,
            points: panel.map((coord) => coord.vector),
        };
    });
    outlineInstancedMesh.isPanelsOutline = true;
};
const computeMeshZIndexFromPolygon = (polygon: Polygon, polygonGroups: Polygon[] = []) => {
    const INCREMENTER = 0.01;
    const polygonZIndex = polygon?.getZIndex();
    const highestZIndex =
        (polygonGroups?.length > 0 ? Math.max(...polygonGroups?.map((p) => p?.getZIndex())) : polygonZIndex) ?? polygonZIndex;

    if (polygon?.getSelected() === true) {
        return highestZIndex * INCREMENTER + INCREMENTER;
    }

    return polygonZIndex * INCREMENTER;
};
// #endregion Three.js Elements Compute

// #region Three.js Elements Editors
export const setPanelsOpacity = (isEditingExclusions, myValue: Nullable<number> = null) => {
    polygonsScene.forEach((mesh) => {
        mesh
            .getObjectByProperty('isGroup', true)
            ?.getObjectsByProperty('isPanels', true)
            ?.forEach((panel) => {
                const panelStyle = computePanelStyle(isEditingExclusions, panel.isValid);
                panel.material.opacity = myValue ? myValue : panelStyle.fillOpacity;
            });
    });
};
export const setMeshesVisibility = (meshType) => {
    switch (meshType) {
        case MESH_TYPES.POLYGON:
        case MESH_TYPES.EXCLUSION:
            polygonsScene.forEach((p) => (p.visible = true));
            exclusionsScene.forEach((e) => (e.visible = true));
            buildingsScene.forEach((b) => (b.visible = false));
            return;
        case MESH_TYPES.BUILDING:
            polygonsScene.forEach((p) => (p.visible = false));
            exclusionsScene.forEach((e) => (e.visible = false));
            buildingsScene.forEach((b) => (b.visible = true));
            return;
        default:
            return;
    }
};
export const setElementsVisibility = (elements: TVisibilityElements, tab) => {
    const { areas, exclusions, buildings, panels } = elements;
    const inactiveColor = styles.polygon.fillColor.inactive;

    _threejsOverlay?.scene?.traverse((element) => {
        if (element.element === 'area') {
            element.material.opacity = areas ? styles.polygon.fillOpacity : 0;
            element.material.color.set(
                tab !== SYSTEM_SETTINGS_GROUPS.EXCLUSIONS && tab !== SYSTEM_SETTINGS_GROUPS.BUILDINGS ?
                    element.selected ?
                        styles.polygon.fillColor.selected
                    :   styles.polygon.fillColor.notSelected
                :   inactiveColor
            );
            element.children?.forEach((subElement) => {
                if (subElement.name === 'wireframe') subElement.visible = areas;
                if (subElement.name === 'point' && element.selected) subElement.visible = areas;
                if (subElement.name === 'middlePoint' && element.selected) subElement.visible = areas;
                if (subElement.name === 'setback' && element.selected) subElement.visible = areas;
                if (subElement.isGroup) subElement.visible = panels;
            });
        }

        if (element.element === 'exclusion') {
            const line = element.children?.find((el) => el.name === 'wireframe');
            line?.material.color.set(
                tab === SYSTEM_SETTINGS_GROUPS.EXCLUSIONS ? styles.exclusionZone.strokeColor.selected : styles.polygon.fillColor.inactive
            );
            element.material.color.set(
                tab === SYSTEM_SETTINGS_GROUPS.EXCLUSIONS ?
                    element.selected ?
                        styles.exclusionZone.fillColor.selected
                    :   styles.exclusionZone.fillColor.notSelected
                :   inactiveColor
            );
            element.visible = exclusions;
        }
        if (element.element === 'building') {
            element.material.color.set(
                tab === SYSTEM_SETTINGS_GROUPS.BUILDINGS ?
                    element.selected ?
                        styles.exclusionZone.fillColor.selected
                    :   styles.exclusionZone.fillColor.notSelected
                :   inactiveColor
            );
            element.visible = buildings;
        }
    });
};
export const focusOnSelectedPolygon = (polygonGroups, options) => {
    const { shouldZoom = true, map = _map, isOrientation = false, initial = false } = options;
    if (!map) return;

    const bounds = new window.google.maps.LatLngBounds();
    polygonGroups
        ?.find((p) => p?.getSelected())
        ?.getPaths()
        ?.forEach((p) => bounds.extend(p.toJSON()));

    const currentZoom = map.getZoom();

    let newZoom;

    if (!shouldZoom || isOrientation || initial) {
        if (!shouldZoom) newZoom = currentZoom;
        if (isOrientation) newZoom = 18;
        if (initial) newZoom = _threejsOverlay.camera?.maxZoom - 3 ?? 20;
    }

    map.fitBounds(bounds);
    if (newZoom) map.setZoom(newZoom);
    _threejsOverlay.camera.zoom = map.zoom;
};
export const acceptPanelsEditorHandler = (dispatch, { useFullArea, areas, selectedArea, setToolbarData }) => {
    const _useFullArea = useFullArea?.find((ua) => ua.id === selectedArea);
    const panelsExcludedInInputs = areas?.find((el) => el.id === selectedArea)?.manual_removed_idxs ?? [];
    const panelsAddedInInputs = areas?.find((el) => el.id === selectedArea)?.manual_added_idxs ?? [];

    let manual_removed_idxs: string[] = [];
    const manual_added_idxs: string[] = [];

    _useFullArea?.panel_rows?.forEach((panel) => {
        const panelId = JSON.stringify(panel.id);
        if (panel.excluded && okPanels.includes(panelId)) manual_added_idxs.push(panelId);
        if (!panel.excluded && nokPanels.includes(panelId)) manual_removed_idxs.push(panelId);
        if (panel.excluded && nokPanels.includes(panelId) && panelsExcludedInInputs.includes(panelId)) manual_removed_idxs.push(panelId);
        if (!panel.excluded && okPanels.includes(panelId) && panelsAddedInInputs.includes(panelId)) manual_added_idxs.push(panelId);

        if (isDefined(panel?.valid)) {
            if (panel.valid && nokPanels.includes(panelId)) manual_removed_idxs.push(panelId);
            if (!panel.valid && okPanels.includes(panelId)) manual_added_idxs.push(panelId);

            if (!panel.valid && !panel.excluded && nokPanels.includes(panelId)) {
                manual_removed_idxs = manual_removed_idxs.filter((panel) => panel !== panelId);
            }
        }
    });

    dispatch(SpvActions.PANEL_EDITOR, {
        manual_removed_idxs: Array.from(new Set([...manual_removed_idxs])),
        manual_added_idxs: Array.from(new Set([...manual_added_idxs])),
    });
    okPanels = [];
    nokPanels = [];

    setToolbarData((old) => ({
        ...old,
        [SolarPvToolbarOptions.PANEL_EDITOR]: {
            nokPanels,
            okPanels,
            excludedPanelsCounterClick: 0,
        },
    }));
};
// #endregion Three.js Elements Editors

// #region Three.js Elements Close
export const closeCurrentPolygon = (
    polygonGroups,
    spvEventDispatchHandler,
    setCanDrawPolygon,
    { isUpdated = false, creatingPolygon = false, visibility }
) => {
    if (allScenePoints.length < 3) {
        setCanDrawPolygon(false);

        if (allScenePoints.length > 0) {
            !!sceneObjects.closingLine && _threejsOverlay.scene.remove(sceneObjects.closingLine);
            !!sceneObjects.drawningLine && _threejsOverlay.scene.remove(sceneObjects.drawningLine);
            sceneObjects.polygonPoints?.forEach((point) => {
                _threejsOverlay.scene.remove(point.point);
            });
            sceneObjects.lines?.forEach((line) => {
                _threejsOverlay.scene.remove(line);
            });
        }
        return;
    }

    const simplePoly = {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                allScenePoints?.filter((p) => isDefined(p?.center?.x) && isDefined(p?.center?.y)).map((p) => [p.center.x, p.center.y]),
            ] as [][],
        },
    };
    const spPoints = hasDuplicateVertices(simplePoly?.geometry?.coordinates?.[0] ?? []) ? { features: [true] } : simplepolygon(simplePoly);

    if (creatingPolygon && spPoints.features.length > 1) {
        setCanDrawPolygon(false);
        cleanupSelfIntersectingPolygonPoints();
        return;
    }

    const center = actualPolygonScene?.geometry?.boundingSphere?.center;
    const centerLatLng = vector3ToLatLngAlt(center!);
    // @ts-ignore
    const overwrites: TOverwrite = { draggable: true, editable: true, center: new LatLng(centerLatLng) };
    drawPolygon(_threejsOverlay.scene, _threejsOverlay, allScenePoints);

    if (isUpdated) overwrites.id = polygonIdToUpdate;
    const points = copy(allScenePoints).map((point) => point?.coords);
    const polygon = buildDefaultPolygon(points, true, polygonGroups, overwrites);

    if (actualPolygonScene !== null) {
        // @ts-ignore
        actualPolygonScene!.name = polygon.id;
        // IMPORTANT: set mesh position by doing the following:
        // 1) define the mesh position as the bounding box centre;
        // 2) after that, center the geometry;
        // 3) don't forget to remove all previous Points and Line
        // (wireframe will replace them).
        // Polygons will be drawn in the correct places
        actualPolygonScene.geometry.computeBoundingBox();
        actualPolygonScene.geometry.boundingBox!.getCenter(actualPolygonScene.position)!;
        actualPolygonScene.geometry.center();
        // @ts-ignore
        actualPolygonScene.remove(...actualPolygonScene.children.filter((c) => c.isPoints || c.isLine));
        !!sceneObjects.closingLine && _threejsOverlay.scene.remove(sceneObjects.closingLine);

        if (actualPolygonGroup) {
            actualPolygonGroup.position.copy(actualPolygonScene.position.clone().negate());
            actualPolygonScene.add(actualPolygonGroup);
        }

        const geo = new EdgesGeometry(actualPolygonScene.geometry);
        const mat = new LineBasicMaterial({
            color: styles.polygon.strokeColor.selected,
            opacity: styles.polygon.strokeOpacity,
            linewidth: styles.polygon.strokeWeight,
        });
        const wireframe = new LineSegments(geo, mat);
        wireframe.name = 'wireframe';

        actualPolygonScene.add(wireframe);
        actualPolygonScene.selected = true;

        addPointsAndMiddlePointsToMesh(actualPolygonScene, true, MESH_TYPES.POLYGON, {
            points: copy(allScenePoints).map((point) => point?.center),
            wireframe,
        });

        actualPolygonScene.translateZ(computeMeshZIndexFromPolygon(polygon, polygonGroups));
        applyPolygonStylesToMesh(actualPolygonScene, polygon, visibility);

        setCanDrawPolygon(false);
        polygonIdToUpdate = null;
        actualPolygonGroup = null;
        resetScene(_threejsOverlay.scene);

        // dispatch new group v3
        spvEventDispatchHandler(isUpdated ? SpvActions.UPDATE_GROUP : SpvActions.ADD_NEW_GROUP, {
            evt: isUpdated ? SpvActions.UPDATE_GROUP : DISPATCH_EVT.NEW_GROUP,
            polygon,
            centroid: polygon.getCenter().toJSON(),
        });
    }
};
export const closeCurrentExclusion = (
    exclusionGroups,
    spvEventDispatchHandler,
    setIsDrawingExclusions,
    setHasChangesInExclusions,
    { isUpdated = false, visibility }
) => {
    let isLineExclusion = false;
    switch (allScenePoints?.length) {
        case 0:
        case 1:
            return;
        case 2: {
            // add middle point
            const middlePointCoords = getPointInBetweenByPerc(allScenePoints[0]?.center, allScenePoints[1]?.center, 0.5);
            const middlePointCoordsLatLng = vector3ToLatLngAlt(middlePointCoords);
            const middlePoint = drawPoint(_threejsOverlay.scene, middlePointCoords, {
                meshType: MESH_TYPES.EXCLUSION,
            });
            allScenePoints.push({
                point: middlePoint,
                center: middlePoint?.center,
                coords: new LatLng(middlePointCoordsLatLng),
            });
            isLineExclusion = true;
            break;
        }
        default:
            break;
    }

    const simplePoly = {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                allScenePoints.filter((p) => isDefined(p?.center?.x) && isDefined(p?.center?.y)).map((p) => [p.center.x, p.center.y]),
            ] as [][],
        },
    };
    const spPoints = hasDuplicateVertices(simplePoly?.geometry?.coordinates?.[0] ?? []) ? { features: [true] } : simplepolygon(simplePoly);

    if (!isUpdated && spPoints.features.length > 1) {
        setIsDrawingExclusions(false);
        cleanupSelfIntersectingPolygonPoints();
        return;
    }

    drawPolygon(_threejsOverlay.scene, _threejsOverlay, allScenePoints, MESH_TYPES.EXCLUSION);
    const center = actualPolygonScene!.geometry.boundingSphere!.center;
    const centerLatLng = vector3ToLatLngAlt(center);
    // @ts-ignore
    const overwrites: TOverwrite = {
        draggable: true,
        editable: true,
        center: new LatLng(centerLatLng),
        zIndex: 20,
        height: exclusionGroups?.find((e) => e?.getSelected())?.getHeight() ?? 0,
    };

    drawPolygon(_threejsOverlay.scene, _threejsOverlay, allScenePoints, MESH_TYPES.EXCLUSION);

    if (isUpdated) overwrites.id = polygonIdToUpdate;
    const polygon = buildDefaultExclusionPolygon(
        copy(allScenePoints).map((point) => point?.coords),
        true,
        exclusionGroups,
        overwrites
    );

    if (actualPolygonScene !== null) {
        // @ts-ignore
        actualPolygonScene.name = polygon.id;
        // IMPORTANT: set mesh position by doing the following:
        // 1) define the mesh position as the bounding box centre;
        // 2) after that, center the geometry;
        // 3) don't forget to remove all previous Points and Line
        // (wireframe will replace them).
        // Polygons will be drawn in the correct places
        actualPolygonScene.geometry.computeBoundingBox();
        actualPolygonScene.geometry.boundingBox!.getCenter(actualPolygonScene.position);
        actualPolygonScene.geometry.center();
        // @ts-ignore
        actualPolygonScene.remove(...actualPolygonScene.children.filter((c) => c.isPoints || c.isLine));
        !!sceneObjects.closingLine && _threejsOverlay.scene.remove(sceneObjects.closingLine);

        const geo = new EdgesGeometry(actualPolygonScene.geometry);
        const mat = new LineBasicMaterial({
            color: styles.exclusionZone.strokeColor.selected,
            opacity: styles.exclusionZone.strokeOpacity.selected,
            linewidth: styles.exclusionZone.strokeWeight,
        });
        const wireframe = new LineSegments(geo, mat);
        wireframe.name = 'wireframe';

        actualPolygonScene.add(wireframe);
        actualPolygonScene.selected = true;
        actualPolygonScene.isLineExclusion = isLineExclusion;

        const points = polygon?.paths?.map((point) => {
            const newPos = {
                lat: point.lat(),
                lng: point.lng(),
                altitude: 0,
            };
            const positionVector = _threejsOverlay.latLngAltitudeToVector3(newPos);
            return new Vector2(positionVector.x, positionVector.y);
        });

        addPointsAndMiddlePointsToMesh(actualPolygonScene, true, MESH_TYPES.EXCLUSION, {
            points,
            wireframe,
        });
        isLineExclusion && actualPolygonScene.getObjectsByProperty('name', 'middlePoint').forEach((mp) => (mp.visible = false));

        actualPolygonScene.translateZ(computeMeshZIndexFromPolygon(polygon, exclusionGroups));

        applyExclusionStylesToMesh(actualPolygonScene, visibility.exclusions);

        setIsDrawingExclusions(false);
        polygonIdToUpdate = null;

        resetScene(_threejsOverlay.scene, MESH_TYPES.EXCLUSION);

        // refactor exclusionGroups object
        if (isUpdated) {
            exclusionGroups = exclusionGroups.map((exclusion) => (exclusion.id === polygon.id ? polygon : exclusion));
        }

        // dispatch new group v3
        spvEventDispatchHandler(SpvActions.UPDATE_EXCLUSION_GROUPS, {
            exclusionGroups: isUpdated ? exclusionGroups : [...exclusionGroups, polygon],
            isExclusions: true,
            setHasChangesInExclusions,
            selectedExclusion: polygon?.id,
            centroid: polygon.getCenter().toJSON(),
        });
    }
};
export const closeCurrentBuilding = (buildingGroups, spvEventDispatchHandler, setIsDrawingBuildings, { isUpdated = false, visibility }) => {
    switch (allScenePoints?.length) {
        case 0:
            return;
        case 1:
        case 2:
            notify(intlMessages('page.spvPro.label.exclusions.point.error'), 'error');
            return;
        default:
            break;
    }
    const simplePoly = {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                allScenePoints.filter((p) => isDefined(p?.center?.x) && isDefined(p?.center?.y)).map((p) => [p.center.x, p.center.y]),
            ] as [][],
        },
    };
    const spPoints = hasDuplicateVertices(simplePoly?.geometry?.coordinates?.[0] ?? []) ? { features: [true] } : simplepolygon(simplePoly);
    if (!isUpdated && spPoints?.features?.length > 1) {
        setIsDrawingBuildings(false);
        cleanupSelfIntersectingPolygonPoints();
        return;
    }
    drawPolygon(_threejsOverlay.scene, _threejsOverlay, allScenePoints, MESH_TYPES.BUILDING);
    const center = actualPolygonScene!.geometry.boundingSphere!.center;
    const centerLatLng = vector3ToLatLngAlt(center);
    // @ts-ignore
    const overwrites: TOverwrite = {
        draggable: true,
        editable: true,
        center: new LatLng(centerLatLng),
        zIndex: 20,
    };

    if (isUpdated) overwrites.id = polygonIdToUpdate;
    const polygon = buildDefaultExclusionPolygon(
        copy(allScenePoints).map((point) => point?.coords),
        true,
        buildingGroups,
        overwrites
    );

    if (actualPolygonScene !== null) {
        // @ts-ignore
        actualPolygonScene.name = polygon.id;
        // IMPORTANT: set mesh position by doing the following:
        // 1) define the mesh position as the bounding box centre;
        // 2) after that, center the geometry;
        // 3) don't forget to remove all previous Points and Line
        // (wireframe will replace them).
        // Polygons will be drawn in the correct places
        actualPolygonScene.geometry.computeBoundingBox();
        actualPolygonScene.geometry.boundingBox!.getCenter(actualPolygonScene.position);
        actualPolygonScene.geometry.center();
        // @ts-ignore
        actualPolygonScene.remove(...actualPolygonScene.children.filter((c) => c.isPoints || c.isLine));
        !!sceneObjects.closingLine && _threejsOverlay.scene.remove(sceneObjects.closingLine);

        const geo = new EdgesGeometry(actualPolygonScene.geometry);
        const mat = new LineBasicMaterial({
            color: styles.exclusionZone.strokeColor.selected,
            opacity: styles.exclusionZone.strokeOpacity.selected,
            linewidth: styles.exclusionZone.strokeWeight,
        });
        const wireframe = new LineSegments(geo, mat);
        wireframe.name = 'wireframe';

        actualPolygonScene.add(wireframe);
        actualPolygonScene.selected = true;

        const points = polygon?.paths?.map((point) => {
            const newPos = {
                lat: point.lat(),
                lng: point.lng(),
                altitude: 0,
            };
            const positionVector = _threejsOverlay.latLngAltitudeToVector3(newPos);
            return new Vector2(positionVector?.x, positionVector?.y);
        });

        addPointsAndMiddlePointsToMesh(actualPolygonScene, true, MESH_TYPES.BUILDING, {
            points,
            wireframe,
        });

        actualPolygonScene.translateZ(computeMeshZIndexFromPolygon(polygon, buildingGroups));

        applyExclusionStylesToMesh(actualPolygonScene, visibility?.buildings);

        setIsDrawingBuildings(false);
        polygonIdToUpdate = null;

        resetScene(_threejsOverlay.scene, MESH_TYPES.BUILDING);

        // refactor buildingGroups object
        if (isUpdated) {
            buildingGroups = buildingGroups.map((building) => (building.id === polygon.id ? polygon : building));
        }

        // dispatch new group v3
        spvEventDispatchHandler(SpvActions.UPDATE_BUILDING_GROUPS, {
            buildingGroups: isUpdated ? buildingGroups : [...buildingGroups, polygon],
            id: polygon?.id,
            polygon: polygon,
            selectedBuilding: polygon?.id,
            centroid: polygon.getCenter().toJSON(),
        });
    }
};
export const orientationCircleSubmitHandler = (watchAngleSelected, areaData, spvEventDispatchHandler) => {
    const orientationMesh = polygonsScene?.find((mesh) => mesh?.name === 'orientationMesh');
    const orientation = parseFloat(watchAngleSelected.toFixed(ORIENTATION_DECIMAL_CFG.decimal));

    spvEventDispatchHandler(SpvActions.SET_ORIENTATION, {
        groupId: areaData?.id,
        [GROUP_INPUTS_NAME.ORIENTATION]: orientation,
        evt: DISPATCH_EVT.SET_ORIENTATION,
    });

    if (typeof orientationMesh?.getObjectsByProperty !== 'function') return;

    orientationMesh.getObjectsByProperty('isMeshLine', true).forEach((meshLine) => {
        meshLine.material.color.setHex(0x3885cd);
    });

    //change the color of the selected button
    orientationMesh?.children?.forEach((child) => {
        if (child.name === 'orientationButtonMesh') {
            const childParsedAspect = parseFloat((child?.aspect).toFixed(ORIENTATION_DECIMAL_CFG.decimal));
            if (childParsedAspect === orientation) {
                child.material.color.setHex(0x01173d);
                const allIntersections = _threejsOverlay.raycaster.intersectObjects(
                    [child, ...orientationMesh.getObjectsByProperty('isMeshLine', true)],
                    false
                );
                const meshLineSelected = allIntersections.map((i) => i.object).find((el) => el.isMeshLine);
                if (meshLineSelected) meshLineSelected.material.color.setHex(0x01173d);
            } else {
                child.material.color.setHex(0x3885cd);
            }
        }
    });
};
// #endregion Three.js Elements Close

// #region Three.js Elements Select
export const selectPolygon = (polygonToSelectID, polygonGroups, options) => {
    const {
        /*shouldZoom = true,*/ spvEventDispatchHandler = undefined,
        map = _map,
        /*isUpdating = false*/ modes = initialOverlayModes,
        deleteGroup = false,
        visibility,
    } = options;
    const meshToSelect = polygonsScene.find((p) => p?.name === polygonToSelectID);
    const previouslySelectedMesh = polygonsScene.find((p) => p?.selected === true);
    const previouslySelectedPolygon = polygonGroups?.find((p) => p?.id === previouslySelectedMesh?.name);
    const polygonToSelect = polygonGroups?.find((p) => p?.id === polygonToSelectID);

    if (
        !meshToSelect ||
        !polygonToSelect ||
        (!!previouslySelectedMesh && meshToSelect.name === previouslySelectedMesh?.name) ||
        (!!previouslySelectedPolygon && polygonToSelect?.id === previouslySelectedPolygon?.id) ||
        modes?.[SPV_OVERLAY_MODES.PANEL_EDITOR]
    )
        return;

    // Set 'selected' state of ThreeJs Mesh's
    if (previouslySelectedMesh) previouslySelectedMesh.selected = false;
    meshToSelect.selected = true;

    // Toggle points and middlepoints
    previouslySelectedMesh?.getObjectsByProperty('name', 'point')?.forEach((v) => (v.visible = false));
    previouslySelectedMesh?.getObjectsByProperty('name', 'middlePoint')?.forEach((mp) => (mp.visible = false));
    previouslySelectedMesh?.getObjectsByProperty('name', 'setback')?.forEach((mp) => (mp.visible = false));
    previouslySelectedMesh?.getObjectsByProperty('name', 'measurement')?.forEach((mp) => (mp.visible = false));

    meshToSelect.getObjectsByProperty('name', 'point').forEach((v) => (v.visible = visibility?.areas));
    meshToSelect.getObjectsByProperty('name', 'middlePoint').forEach((v) => (v.visible = visibility?.areas));
    meshToSelect.getObjectsByProperty('name', 'setback').forEach((v) => (v.visible = visibility?.areas));
    meshToSelect?.getObjectsByProperty('name', 'measurement')?.forEach((mp) => (mp.visible = visibility?.areas));

    // Translate mesh to simulate zIndex
    if (!deleteGroup) {
        meshToSelect.translateZ(computeMeshZIndexFromPolygon(polygonToSelect, polygonGroups));
        if (previouslySelectedMesh)
            previouslySelectedMesh.translateZ(
                -previouslySelectedMesh?.position?.z + computeMeshZIndexFromPolygon(previouslySelectedPolygon, polygonGroups)
            );
    }

    // Set 'selected' state og Polygon instances
    if (previouslySelectedPolygon) previouslySelectedPolygon.unselect();
    polygonToSelect.select();

    // Update style of Meshes
    applyPolygonStylesToMesh(meshToSelect, polygonToSelect, visibility);
    if (previouslySelectedMesh) applyUnselectedStyleToMesh(previouslySelectedMesh);

    if (spvEventDispatchHandler) spvEventDispatchHandler(SpvActions.SET_SELECT_GROUP, { groupId: polygonToSelectID, polygonGroups });

    if (!map) return;
};
export const selectExclusion = (exclusionID, exclusionGroups, selectedExclusion, spvEventDispatchHandler, options) => {
    const { fromOverlay = false, visibility } = options;
    const meshToSelect = exclusionsScene.find((p) => p?.name === exclusionID);

    const previouslySelectedPolygon = exclusionGroups?.find((p) => p?.selected);
    const previouslySelectedMesh = exclusionsScene.find((p) => p?.name === previouslySelectedPolygon?.id);
    const polygonToSelect = exclusionGroups?.find((p) => p?.id === exclusionID);

    if (
        !meshToSelect ||
        !polygonToSelect ||
        (!!previouslySelectedMesh && meshToSelect.name === previouslySelectedMesh?.name) ||
        (!!previouslySelectedPolygon && polygonToSelect?.id === previouslySelectedPolygon?.id)
    )
        return;

    // Set 'selected' state of ThreeJs Mesh's
    if (previouslySelectedMesh) previouslySelectedMesh.selected = false;
    meshToSelect.selected = true;

    // Toggle points and middlepoints
    previouslySelectedMesh?.getObjectsByProperty('name', 'point')?.forEach((v) => (v.visible = false));
    previouslySelectedMesh?.getObjectsByProperty('name', 'middlePoint')?.forEach((mp) => (mp.visible = false));
    meshToSelect.getObjectsByProperty('name', 'point').forEach((v) => (v.visible = visibility?.exclusions));
    !meshToSelect.isLineExclusion &&
        meshToSelect.getObjectsByProperty('name', 'middlePoint').forEach((mp) => (mp.visible = visibility?.exclusions));
    previouslySelectedMesh?.getObjectsByProperty('name', 'measurement')?.forEach((mp) => (mp.visible = false));
    meshToSelect?.getObjectsByProperty('name', 'measurement')?.forEach((mp) => (mp.visible = visibility?.exclusions));

    // Translate mesh to simulate zIndex
    meshToSelect.translateZ(computeMeshZIndexFromPolygon(polygonToSelect, exclusionGroups));
    if (previouslySelectedMesh)
        previouslySelectedMesh.translateZ(
            -previouslySelectedMesh?.position?.z + computeMeshZIndexFromPolygon(previouslySelectedPolygon, exclusionGroups)
        );

    // Set 'selected' state og Polygon instances
    if (previouslySelectedPolygon) previouslySelectedPolygon.unselect();
    polygonToSelect.select();

    // Update style of Meshes
    applyExclusionStylesToMesh(meshToSelect, visibility.exclusions);
    if (previouslySelectedMesh) applyUnselectedExclusionStyleToMesh(previouslySelectedMesh);

    spvEventDispatchHandler(SpvActions.SET_SELECT_EXCLUSION, { exclusionId: exclusionID, fromOverlay, exclusionGroups });
};
export const selectBuilding = (buildingID, buildingGroups, selectedBuilding, spvEventDispatchHandler, { visibility }, map = _map) => {
    const meshToSelect = buildingsScene.find((p) => p?.name === buildingID);
    const previouslySelectedPolygon = buildingGroups?.find((p) => p?.selected);
    const previouslySelectedMesh = buildingsScene.find((p) => p?.name === previouslySelectedPolygon?.id);
    const polygonToSelect = buildingGroups?.find((p) => p?.id === buildingID);

    if (
        !meshToSelect ||
        !polygonToSelect ||
        (!!previouslySelectedMesh && meshToSelect.name === previouslySelectedMesh?.name) ||
        (!!previouslySelectedPolygon && polygonToSelect?.id === previouslySelectedPolygon?.id)
    )
        return;

    // Set 'selected' state of ThreeJs Mesh's
    if (previouslySelectedMesh) previouslySelectedMesh.selected = false;
    meshToSelect.selected = true;

    // Toggle points and middlepoints
    previouslySelectedMesh?.getObjectsByProperty('name', 'point')?.forEach((v) => (v.visible = false));
    previouslySelectedMesh?.getObjectsByProperty('name', 'middlePoint')?.forEach((mp) => (mp.visible = false));
    meshToSelect.getObjectsByProperty('name', 'point').forEach((v) => (v.visible = visibility?.buildings));
    previouslySelectedMesh?.getObjectsByProperty('name', 'measurement')?.forEach((mp) => (mp.visible = false));
    meshToSelect?.getObjectsByProperty('name', 'measurement')?.forEach((mp) => (mp.visible = visibility?.buildings));

    // Translate mesh to simulate zIndex
    meshToSelect.translateZ(computeMeshZIndexFromPolygon(polygonToSelect, buildingGroups));
    if (previouslySelectedMesh)
        previouslySelectedMesh.translateZ(
            -previouslySelectedMesh?.position?.z + computeMeshZIndexFromPolygon(previouslySelectedPolygon, buildingGroups)
        );

    // Set 'selected' state og Polygon instances
    if (previouslySelectedPolygon) previouslySelectedPolygon.unselect();
    polygonToSelect.select();

    // Update style of Meshes
    applyExclusionStylesToMesh(meshToSelect, visibility.buildings);
    if (previouslySelectedMesh) applyUnselectedExclusionStyleToMesh(previouslySelectedMesh);

    if (spvEventDispatchHandler) spvEventDispatchHandler(SpvActions.SET_SELECT_BUILDING, { selectedBuilding: buildingID, buildingGroups });

    if (!map) return;
};
export const unselectAllPolygons = (polygonGroups, spvEventDispatchHandler, applyStyle = true) => {
    const previouslySelectedMesh = polygonsScene.find((p) => p?.selected === true);
    const previouslySelectedPolygon = polygonGroups?.find((p) => p?.id === previouslySelectedMesh?.name);

    if (!previouslySelectedMesh || !previouslySelectedPolygon) return;

    // Set 'selected' state of ThreeJs Mesh's
    previouslySelectedMesh.selected = false;

    // Toggle points and middlepoints
    previouslySelectedMesh.getObjectsByProperty('name', 'point').forEach((v) => (v.visible = false));
    previouslySelectedMesh.getObjectsByProperty('name', 'middlePoint').forEach((mp) => (mp.visible = false));
    previouslySelectedMesh.getObjectsByProperty('name', 'setback').forEach((mp) => (mp.visible = false));
    previouslySelectedMesh.getObjectsByProperty('name', 'measurement').forEach((mp) => (mp.visible = false));

    // Translate mesh to simulate zIndex
    previouslySelectedMesh.translateZ(
        -previouslySelectedMesh?.position?.z + computeMeshZIndexFromPolygon(previouslySelectedPolygon, polygonGroups)
    );

    // Set 'selected' state og Polygon instances
    previouslySelectedPolygon.unselect();

    // Update style of Meshes
    applyUnselectedStyleToMesh(previouslySelectedMesh, applyStyle);

    if (spvEventDispatchHandler) spvEventDispatchHandler(SpvActions.SET_SELECT_GROUP, { groupId: -1 });
};
export const unselectAllExclusions = (exclusionGroups, spvEventDispatchHandler = (_action, _payload) => {}, applyStyle = true) => {
    const previouslySelectedMesh = exclusionsScene.find((p) => p?.selected === true);
    const previouslySelectedPolygon = exclusionGroups?.find((p) => p?.id === previouslySelectedMesh?.name);

    if (!previouslySelectedMesh || !previouslySelectedPolygon) return;

    // Set 'selected' state of ThreeJs Mesh's
    previouslySelectedMesh.selected = false;

    // Toggle points and middlepoints
    previouslySelectedMesh.getObjectsByProperty('name', 'point').forEach((v) => (v.visible = false));
    previouslySelectedMesh.getObjectsByProperty('name', 'middlePoint').forEach((mp) => (mp.visible = false));
    previouslySelectedMesh.getObjectsByProperty('name', 'measurement').forEach((mp) => (mp.visible = false));

    // Translate mesh to simulate zIndex
    previouslySelectedMesh.translateZ(
        -previouslySelectedMesh?.position?.z + computeMeshZIndexFromPolygon(previouslySelectedPolygon, exclusionGroups)
    );

    // Set 'selected' state og Polygon instances
    previouslySelectedPolygon.unselect();

    // Update style of Meshes
    applyUnselectedExclusionStyleToMesh(previouslySelectedMesh, applyStyle);
    if (spvEventDispatchHandler) spvEventDispatchHandler(SpvActions.SET_SELECT_EXCLUSION, { exclusionId: null });
};
export const unselectAllBuildings = (buildingGroups, spvEventDispatchHandler, applyStyle = true) => {
    const previouslySelectedMesh = buildingsScene.find((p) => p?.selected === true);
    const previouslySelectedPolygon = buildingGroups?.find((p) => p?.id === previouslySelectedMesh?.name);

    if (!previouslySelectedMesh || !previouslySelectedPolygon) return;

    // Set 'selected' state of ThreeJs Mesh's
    previouslySelectedMesh.selected = false;

    // Toggle points and middlepoints
    previouslySelectedMesh.getObjectsByProperty('name', 'point').forEach((v) => (v.visible = false));
    previouslySelectedMesh.getObjectsByProperty('name', 'middlePoint').forEach((mp) => (mp.visible = false));
    previouslySelectedMesh.getObjectsByProperty('name', 'measurement').forEach((mp) => (mp.visible = false));

    // Translate mesh to simulate zIndex
    previouslySelectedMesh.translateZ(
        -previouslySelectedMesh?.position?.z + computeMeshZIndexFromPolygon(previouslySelectedPolygon, buildingGroups)
    );

    // Set 'selected' state og Polygon instances
    previouslySelectedPolygon.unselect();

    // Update style of Meshes
    applyUnselectedExclusionStyleToMesh(previouslySelectedMesh, applyStyle);
    if (spvEventDispatchHandler) spvEventDispatchHandler(SpvActions.SET_SELECT_BUILDING, { selectedBuilding: null });
};
export const deselectDuplication = () => {
    polygonToCopy = null;
    _threejsOverlay.scene.remove(_threejsOverlay.scene.getObjectByName('tempMesh'));
    _map.setOptions({ draggableCursor: 'default' });
};
// #endregion Three.js Elements Select

// #region Three.js Elements Delete
export const deleteCurrentPolygon = (polygonGroups, spvEventDispatchHandler, options) => {
    const { id = null } = options ?? { id: null };
    const meshToDelete = isDefined(id) ? polygonsScene.find((p) => p?.name === id) : polygonsScene.find((p) => p?.selected === true);
    const polygonToDelete = isDefined(id) ? polygonGroups.find((p) => p?._id === id) : polygonGroups.find((p) => p?.getSelected() === true);

    if (!meshToDelete || !polygonToDelete || meshToDelete?.name !== polygonToDelete?.id || !_threejsOverlay.scene) {
        notify(`Error on deleteCurrentPolygon, polygonToDelete: ${polygonToDelete}, meshToDelete:, ${meshToDelete}`, 'error');
        console.error('Error on deleteCurrentPolygon, polygonToDelete: ', polygonToDelete, ' meshToDelete: ', meshToDelete);
        return;
    }

    try {
        // set the mesh invisible
        meshToDelete.visible = false;
        // remove from the scene
        _threejsOverlay.scene.remove(meshToDelete);

        // clean polygonsScene
        polygonsScene = polygonsScene.filter((p) => p?.name !== polygonToDelete?.id);

        // Dispatch
        spvEventDispatchHandler(SpvActions.DELETE_GROUP, {
            evt: DISPATCH_EVT.DELETE_GROUP,
            idToDelete: polygonToDelete?.id,
        });
    } catch (e) {
        // Something broke, re-adds polygon to ThreeJs
        meshToDelete.visible = true;

        if (!_threejsOverlay.scene?.children?.find?.((c) => c?.id === meshToDelete?.name)) _threejsOverlay?.scene.add(meshToDelete);

        if (!polygonsScene?.find?.((p) => p?.id === polygonToDelete?.id)) polygonsScene.push(meshToDelete);

        notify('Error on deleteCurrentPolygon, polygon was not deleted from scene', 'error');
        console.error('Error on deleteCurrentPolygon, polygon was not deleted from scene');
        return;
    }
};
export const deletePolygonByID = (polygonID) => {
    const meshToDelete = polygonsScene.find((p) => p?.name === polygonID);

    if (!meshToDelete || !_threejsOverlay.scene) {
        notify('page.error.403.unexpected', 'error');
        console.error('Error on deleteCurrentExclusion, meshToDelete: ', meshToDelete);
        return;
    }

    try {
        // set the mesh invisible
        meshToDelete.visible = false;
        // remove from the scene
        _threejsOverlay.scene.remove(meshToDelete);
        exclusionsScene = exclusionsScene.filter((p) => p?.name !== polygonID);
    } catch (e) {
        // Something broke, re-adds polygon to ThreeJs
        meshToDelete.visible = true;

        if (!_threejsOverlay.scene?.children?.find?.((c) => c?.id === meshToDelete?.id)) _threejsOverlay?.scene.add(meshToDelete);

        if (!exclusionsScene?.find?.((p) => p?.id === polygonID)) exclusionsScene.push(meshToDelete);

        notify(intlMessages('page.error.403.unexpected'), 'error');
        console.error('Error on deletePolygonByID, polygon was not deleted from scene');
        return;
    }
};
export const deleteExclusionByID = (exclusionID, exclusionGroups, setHasChangesInExclusions, spvEventDispatchHandler, options) => {
    const { deleteMesh = false } = options;
    const meshToDelete = exclusionsScene.find((p) => p?.name === exclusionID);
    const polygonToDelete = exclusionGroups.find((p) => p?.id === exclusionID);

    if (!meshToDelete || !polygonToDelete || meshToDelete?.name !== polygonToDelete?.id || !_threejsOverlay.scene) {
        notify(`Error on deleteCurrentPolygon, polygonToDelete: ${polygonToDelete}, meshToDelete:, ${meshToDelete}`, 'error');
        console.error('Error on deleteCurrentPolygon, polygonToDelete: ', polygonToDelete, ' meshToDelete: ', meshToDelete);
        return;
    }

    try {
        // set the mesh invisible
        meshToDelete.visible = false;
        // remove from the scene
        if (deleteMesh) {
            _threejsOverlay.scene.remove(meshToDelete);
        }

        const newExclusionGroups = exclusionGroups.map((exc) => {
            if (exc.id === exclusionID) {
                exc.setVisible(false);
            }
            return exc;
        });

        spvEventDispatchHandler(SpvActions.UPDATE_EXCLUSION_GROUPS, {
            exclusionGroups: newExclusionGroups,
            setHasChangesInExclusions,
        });

        // clean polygonsScene
        if (deleteMesh) {
            exclusionsScene = exclusionsScene.filter((p) => p?.name !== polygonToDelete?.id);
        }
    } catch (e) {
        // Something broke, re-adds polygon to ThreeJs
        meshToDelete.visible = true;

        if (!_threejsOverlay.scene?.children?.find?.((c) => c?.id === meshToDelete?.id)) _threejsOverlay?.scene.add(meshToDelete);

        if (!exclusionsScene?.find?.((p) => p?.id === polygonToDelete?.id)) exclusionsScene.push(polygonToDelete);

        notify(intlMessages('page.error.403.unexpected'), 'error');
        console.error('Error on deleteCurrentExclusion, polygon was not deleted from scene');
        return;
    }
};
export const deleteBuildingByID = (buildingID, buildingGroups, spvEventDispatchHandler, deleteMesh = false) => {
    const meshToDelete: TPolygonMesh = buildingsScene.find((p) => p?.name === buildingID)!;
    const polygonToDelete = buildingGroups.find((p) => p?.id === buildingID);

    if (!meshToDelete || !polygonToDelete || meshToDelete?.name !== polygonToDelete?.id || !_threejsOverlay.scene) {
        notify(intlMessages('page.error.403.unexpected'), 'error');
        console.error('Error on deleteCurrentBuilding, polygonToDelete: ', polygonToDelete, ' meshToDelete: ', meshToDelete);
        return;
    }

    try {
        // set the mesh invisible
        meshToDelete.visible = false;
        // remove from the scene
        if (deleteMesh) {
            _threejsOverlay.scene.remove(meshToDelete);
        }

        const newBuildingGroups = buildingGroups.filter((b) => b.id !== buildingID);

        spvEventDispatchHandler(SpvActions.UPDATE_BUILDING_GROUPS, {
            buildingGroups: newBuildingGroups,
            selectedBuilding: null,
            polygon: null,
            id: buildingID,
        });

        // clean polygonsScene
        if (deleteMesh) {
            buildingsScene = buildingsScene.filter((p) => p?.name !== polygonToDelete?.id);
        }
    } catch (e) {
        // Something broke, re-adds polygon to ThreeJs
        meshToDelete.visible = true;

        if (!_threejsOverlay.scene?.children?.find?.((c) => c?.id === meshToDelete?.id)) _threejsOverlay?.scene.add(meshToDelete);

        if (!buildingsScene?.find?.((p) => p?.id === polygonToDelete?.id)) buildingsScene.push(polygonToDelete);

        notify(intlMessages('page.error.403.unexpected'), 'error');
        console.error('Error on deleteCurrentExclusion, polygon was not deleted from scene');
        return;
    }
};
export const deleteGroupHandler = ({ showDeleteWarn, spvEventDispatchHandler, polygonGroups, id = null }) => {
    if (showDeleteWarn) {
        spvEventDispatchHandler(SpvActions.SET_DIALOG, { deleteAllPanels: true });
    } else {
        deleteCurrentPolygon(polygonGroups, spvEventDispatchHandler, { id });
    }
};
export const deleteAllExclusions = (
    productSelectionRef,
    setHasChangesInExclusions,
    setHasChangedExcusionInputs,
    spvEventDispatchHandler
) => {
    if (Array.isArray(exclusionsScene) && exclusionsScene.length > 0) {
        try {
            for (const mesh of exclusionsScene) {
                // set the mesh invisible
                mesh.visible = false;
                // remove from the scene
                _threejsOverlay.scene.remove(mesh);
            }

            // Dispatch
            spvEventDispatchHandler(SpvActions.APPLY_EXCLUSIONS_CHANGES, {
                exclusionGroups: [],
                exclusions: [],
                selectedExclusion: null,
                isExclusions: productSelectionRef === SYSTEM_SETTINGS_GROUPS.EXCLUSIONS,
                productSelectionRef,
                setHasChangesInExclusions,
                setHasChangedExcusionInputs,
            });

            exclusionsScene = [];
        } catch (e) {
            for (const mesh of exclusionsScene) {
                // set the mesh invisible
                mesh.visible = true;
                // remove from the scene
                _threejsOverlay.scene.add(mesh);
            }

            notify(intlMessages('page.error.403.unexpected'), 'error');
            console.error('Error on deleteAllExclusions, polygons were not deleted from the scene');
        }
    }
};
export const deleteAllBuildings = (spvEventDispatchHandler) => {
    if (Array.isArray(buildingsScene) && buildingsScene.length > 0) {
        try {
            for (const mesh of buildingsScene) {
                // set the mesh invisible
                mesh.visible = false;
                // remove from the scene
                _threejsOverlay.scene.remove(mesh);
            }

            // Dispatch
            spvEventDispatchHandler(SpvActions.UPDATE_BUILDING_GROUPS, {
                buildingGroups: [],
                selectedBuilding: null,
                polygon: null,
                id: null,
            });

            buildingsScene = [];
        } catch (e) {
            for (const mesh of buildingsScene) {
                // set the mesh invisible
                mesh.visible = true;
                // remove from the scene
                _threejsOverlay.scene.add(mesh);
            }
            notify(intlMessages('page.error.403.unexpected'), 'error');

            console.error('Error on deleteAllBuildings, polygons were not deleted from the scene');
        }
    }
};
// #endregion Three.js Elements Delete

// #region Three.js Elements Duplicate
export const duplicateCurrentPolygon = (polygonGroups, spvEventDispatchHandler) => {
    const meshToDuplicate = polygonsScene.find((p) => p?.selected === true);
    const polygonToDuplicate = polygonGroups.find((p) => p?.getSelected() === true);

    if (!meshToDuplicate || !polygonToDuplicate || meshToDuplicate?.name !== polygonToDuplicate?.id || !_threejsOverlay.scene) {
        notify('Error: duplicateCurrentPolygon', 'error');
        return;
    }

    const newPath: LatLng[] = [];
    const DEFAULT_SPACING = 0.0001;
    polygonToDuplicate
        .getPaths()
        .forEach((vertice) => newPath.push(new LatLng(vertice.lat() + DEFAULT_SPACING, vertice.lng() + DEFAULT_SPACING)));

    // Create duplicate Polygon, spaced from the original, so they dont fully overlap
    const newPolygon = buildDefaultPolygon(newPath, true, polygonGroups, { editable: true, draggable: true });

    // Create the duplicate Mesh. Note: Mesh.clone() breaks
    const newMesh: TPolygonMesh = buildDefaultAreaMeshFromPolygon(newPolygon);

    // Compute the area center point and updates on the Polygon
    const center = newMesh.geometry.boundingSphere?.center;
    const centerLatLng = vector3ToLatLngAlt(center!);
    newPolygon.setCenter(new LatLng(centerLatLng));

    spvEventDispatchHandler(SpvActions.STAMP, {
        evt: DISPATCH_EVT.DUPLICATE_GROUP,
        polygon: newPolygon,
        idToDuplicate: polygonToDuplicate?.id,
    });
};
export const duplicateCurrentExclusion = (
    exclusionID,
    exclusionGroups,
    selectedExclusion,
    setHasChangesInExclusions,
    spvEventDispatchHandler,
    { visibility }
) => {
    const meshToDuplicate = exclusionsScene.find((p) => p?.name === exclusionID);
    const polygonToDuplicate = exclusionGroups.find((p) => p?.id === exclusionID);

    if (!meshToDuplicate || !polygonToDuplicate || meshToDuplicate?.name !== polygonToDuplicate?.id || !_threejsOverlay.scene) {
        notify('page.error.403.unexpected', 'error');
        return;
    }

    const newPath: LatLng[] = [];
    const DEFAULT_SPACING = 0.00025;
    polygonToDuplicate
        .getPaths()
        .forEach((vertice) => newPath.push(new LatLng(vertice.lat() + DEFAULT_SPACING, vertice.lng() + DEFAULT_SPACING)));

    const overwrites = {
        draggable: true,
        editable: true,
        height: exclusionGroups?.find((e) => e.getSelected())?.getHeight(),
    };

    // unselect all exclusions before duplicating
    unselectAllExclusions(exclusionGroups, spvEventDispatchHandler);

    // Create duplicate Polygon, spaced from the original, so they dont fully overlap
    const newPolygon = buildDefaultExclusionPolygon(newPath, true, exclusionGroups, overwrites);

    // Create the duplicate Mesh. Note: Mesh.clone() breaks
    const newMesh: TPolygonMesh = buildDefaultAreaMeshFromPolygon(
        newPolygon,
        { threejsOverlay: _threejsOverlay, scene: _threejsOverlay.scene },
        true,
        MESH_TYPES.EXCLUSION
    );

    // Compute the area center point and updates on the Polygon
    const center = newMesh.geometry.boundingSphere?.center;
    const centerLatLng = vector3ToLatLngAlt(center!);
    newPolygon.setCenter(new LatLng(centerLatLng));

    const newExclusionGroups = [...exclusionGroups, newPolygon];
    selectExclusion(newPolygon?.id, newExclusionGroups, selectedExclusion, spvEventDispatchHandler, { visibility });

    spvEventDispatchHandler(SpvActions.UPDATE_EXCLUSION_GROUPS, {
        exclusionGroups: newExclusionGroups,
        setHasChangesInExclusions,
    });
};
export const startAdvancedDuplicationHandler = (selectedExclusion, exclusionGroups, map = _map) => {
    const polygonCurrent = exclusionGroups?.find((poly) => poly?.visible && poly?.id === selectedExclusion);
    if (!isDefined(polygonCurrent)) return;

    if (polygonToCopy?.id === selectedExclusion) {
        polygonToCopy = null;
        map.setOptions({ draggableCursor: 'default' });
    } else {
        polygonToCopy = { id: selectedExclusion, polygon: polygonCurrent };
        map.setOptions({ draggableCursor: 'crosshair' });
    }
};
// #endregion Three.js Elements Duplicate

// #region Three.js Utils
const identifyPanelHandler = (mousePosition, panels) => {
    const panelSelected = panels?.find((panel) => isPointInsidePolygonPanels(mousePosition, panel.points));
    return panelSelected;
};
function getAdjustedScaleFactor(map) {
    const zoomLevel = map.getZoom();

    //#region Get the map scale in meters per pixel
    const bounds = map.getBounds();
    const southWest = bounds.getSouthWest();
    const northEast = bounds.getNorthEast();

    // Calculate the distance in meters between the south and north bounds
    const distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(southWest.lat(), (southWest.lng() + northEast.lng()) / 2),
        new google.maps.LatLng(northEast.lat(), (southWest.lng() + northEast.lng()) / 2)
    );

    // Calculate the scale in meters per pixel
    const scale = distanceInMeters / map.getDiv().offsetHeight;

    const mapScale = 1 / scale;
    //#endregion  Get the map scale in meters per pixel

    // Calculate a base scale factor based on desired circle size and map scale
    const baseScaleFactor = POINT_RADIUS / mapScale;

    // Adjust the base scale factor based on normalized zoom level
    const adjustedScaleFactor = baseScaleFactor * (1 + zoomLevel * 1);

    return adjustedScaleFactor;
}
function adjustedScaleAllVertexOnHandler(map) {
    // Adjust the base scale factor based on normalized zoom level
    const adjustedScaleFactor = getAdjustedScaleFactor(map);

    // Apply the scale factor to the circle mesh
    // Set visibility of all objects in the scene to false
    _threejsOverlay.scene.traverse((obj) => {
        if (obj.isPoints) obj.scale.set(adjustedScaleFactor, adjustedScaleFactor, 1);
        if (obj.name === 'measurement') {
            const _adjustedScaleFactor = adjustedScaleFactor / 20;
            try {
                if (map.getZoom() > 19 && obj?.points?.[0]?.y && obj?.points?.[1]?.y && obj?.points?.[0]?.x && obj?.points?.[1]?.x) {
                    obj.scale.set(adjustedScaleFactor, adjustedScaleFactor, 1);

                    const distanceFromMidpoint = -2 * adjustedScaleFactor; // Adjust the distance as needed
                    const perpendicularVector = new Vector3(
                        obj?.points?.[1]?.y - obj?.points?.[0]?.y,
                        -(obj?.points?.[1]?.x - obj?.points?.[0]?.x),
                        0
                    ).normalize();
                    const offsetVector = perpendicularVector.multiplyScalar(distanceFromMidpoint);

                    obj.position.set(obj.midpoint.x + offsetVector.x, obj.midpoint.y + offsetVector.y, obj.position.z);
                } else {
                    obj.position.set(obj.originalPosition.x, obj.originalPosition.y, obj.position.z);
                }
                obj.previousScaleFactor = _adjustedScaleFactor;
            } catch (error) {
                obj.previousScaleFactor = _adjustedScaleFactor;
                obj.visible = false;
            }
        }

        //
    });
}
const getClosestStraightPoint = (startPoint: Vector3, endPoint: Vector3, prevPoint?: Vector3) => {
    // The angle variation (in degrees for easier debugging)
    const ANGLE_VARIATION = 45;

    // Calculate the normalized direction vector between startPoint and endPoint
    const directionVector = endPoint.clone().sub(startPoint).normalize();

    // If prevPoint is provided, calculate the axis based on the difference between startPoint and prevPoint
    const axis = prevPoint ? startPoint.clone().sub(prevPoint).normalize() : new Vector3(1, 0, 0);

    // The rotation axis (the z-axis, since we're working in the 2D space here)
    const rotationAxis = new Vector3(0, 0, 1);

    // Find the closest ANGLE_VARIATION multiple angle relative to the mouse position
    const angle = axis.angleTo(directionVector);
    const closestMultiple = Math.round(angle / MathUtils.degToRad(ANGLE_VARIATION)) * MathUtils.degToRad(ANGLE_VARIATION);

    // Find the cross product to determine if the angle value should be positive or negative in the rotation to perform;
    // since we're working in 2D, the cross product will only be noticed in the z-component
    const crossProduct = new Vector3().crossVectors(axis, directionVector);
    const signal = Math.sign(crossProduct.z);

    // Obtain the quaternion
    const quaternion = new Quaternion().setFromAxisAngle(rotationAxis, signal * closestMultiple);

    // Calculate the rotated direction vector using a quaternion
    const rotatedDirectionVector = axis.clone().applyQuaternion(quaternion);

    // Set the length of the rotated direction vector to the original distance
    const scaledDirectionVector = rotatedDirectionVector.setLength(endPoint.distanceTo(startPoint));

    // Calculate the new point based on the scaled direction vector
    const newPoint = startPoint.clone().add(scaledDirectionVector);

    // new guidelines algorithm
    if (
        isEnvDevFlag(useFeatureFlags.getState().featureFlags['fe-2339']) &&
        !_threejsOverlay.scene.getObjectsByProperty('name', 'guideline').length
    ) {
        getGuidelines({ angle: ANGLE_VARIATION, point: startPoint, vector: scaledDirectionVector, rotationAxis });
    }

    return { point: newPoint, vector: scaledDirectionVector.normalize() };
};
const getGuidelines = ({
    angle,
    point,
    vector,
    rotationAxis,
}: {
    angle: number;
    point: Vector3;
    vector: Vector3;
    rotationAxis: Vector3;
}) => {
    for (let i = 0; i < 180; i += angle) {
        const quaternion = new Quaternion().setFromAxisAngle(rotationAxis, MathUtils.degToRad(i));
        const rotatedVector = vector.clone().applyQuaternion(quaternion);
        generateGuideline(point, rotatedVector.normalize());
    }
    if (allScenePoints.length === 2) {
        for (let i = 0; i < 180; i += angle) {
            const quaternion = new Quaternion().setFromAxisAngle(rotationAxis, MathUtils.degToRad(i));
            const rotatedVector = vector.clone().applyQuaternion(quaternion);
            generateGuideline(allScenePoints.map((p) => p.center)[0], rotatedVector.normalize());
        }
    }
    // add guidelines in the first polygon point with the directions of the first two edges already drawn
    if (allScenePoints.length >= MIN_VERTICES) {
        const points: Vector3[] = allScenePoints.map((p) => p.center);
        for (let i = 1; i < MIN_VERTICES; i++) {
            const myVector = points[i]?.clone().sub(points[i - 1]);
            generateGuideline(points[0], myVector);
        }
    }
};
const generateGuideline = (point: Vector3, vector: Vector3) => {
    const points: Vector3[] = [];
    points.push(point.clone().add(vector.clone().multiplyScalar(100)));
    points.push(point.clone().add(vector.clone().multiplyScalar(-100)));
    const geometry = new BufferGeometry().setFromPoints(points);
    const material = new LineBasicMaterial({ color: 0x02f12c });
    const line = new Line(geometry, material);
    line.name = 'guideline';
    line.visible = false;
    _threejsOverlay.scene.add(line);
};
const getStraightPointVectorsIntersection = (first: { point: Vector3; vector: Vector3 }, second: { point: Vector3; vector: Vector3 }) => {
    const crossProduct = new Vector3().crossVectors(first.vector, second.vector);

    // Check if the lines are parallel (cross product magnitude is close to zero)
    if (crossProduct.lengthSq() < 1e-10) {
        return null;
    }

    // Vector from the origin of line 1 to the origin of line 2
    const originDelta = new Vector3().subVectors(second.point, first.point);

    // Solve for the parameters t and s in the system of linear equations
    const t = originDelta.cross(second.vector).dot(crossProduct) / crossProduct.lengthSq();
    const s = originDelta.cross(first.vector).dot(crossProduct) / crossProduct.lengthSq();

    // Calculate the intersection points
    const intersectionPoint1 = first.point.clone().add(first.vector.clone().multiplyScalar(t));
    const intersectionPoint2 = second.point.clone().add(second.vector.clone().multiplyScalar(s));

    // Check if the points are close enough (considering floating-point precision)
    const DELTA_OFFSET = 0.25;

    if (intersectionPoint1.distanceToSquared(intersectionPoint2) < DELTA_OFFSET) {
        return intersectionPoint1;
    }
    return null;
};
const removeStraightLineHandler = () => {
    if (isStraightLine) {
        isStraightLine = false;
        straightLinePosition = null;
    }
};
export const isPointInsidePolygon = (point, polygon) => {
    const x = point.x;
    const y = point.y;

    let inside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i].vector.x;
        const yi = polygon[i].vector.y;
        const xj = polygon[j].vector.x;
        const yj = polygon[j].vector.y;

        const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

        if (intersect) {
            inside = !inside;
        }
    }

    return inside;
};
export const isPointInsidePolygonPanels = (point, polygon) => {
    const x = point.x;
    const y = point.y;

    let inside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i].x;
        const yi = polygon[i].y;
        const xj = polygon[j].x;
        const yj = polygon[j].y;

        const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

        if (intersect) {
            inside = !inside;
        }
    }

    return inside;
};
const addPointsAndMiddlePointsToMesh = (
    polygonMesh,
    isSelected = true,
    meshType = MESH_TYPES.POLYGON,
    { points = [], wireframe }: { points: { x: number; y: number }[]; wireframe?: TLine }
) => {
    const scene = _threejsOverlay.scene;
    let polygonVertices = getVerticesPositionsFromGeometry(polygonMesh?.geometry);
    // check if there are repeated vertices (AKA self-intersection points)
    const repeatedVertices = polygonVertices.filter(
        (vector, index) => polygonVertices.findIndex((v, i) => i !== index && isEqual(v, vector)) !== -1
    );

    if (repeatedVertices?.length > 0)
        polygonVertices = polygonVertices.filter((p) => p.x !== repeatedVertices?.[0].x && p.y !== repeatedVertices?.[0].y);

    const simplePoly = {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            // @ts-ignore
            coordinates: [points?.filter((p) => isDefined(p?.x) && isDefined(p?.y))?.map((p) => [p.x, p.y])] as [][],
        },
    };
    const spPoints = hasDuplicateVertices(simplePoly?.geometry?.coordinates?.[0] ?? []) ? { features: [true] } : simplepolygon(simplePoly);

    // Points
    polygonVertices.forEach((pVertice, idx) => {
        const isRepeated = repeatedVertices.some((v) => v.equals(pVertice));
        const pointToDraw = drawPoint(scene, pVertice, { meshType, pointIndex: idx });
        pointToDraw.name = isRepeated ? 'intersection' : 'point';
        pointToDraw.visible = isRepeated ? false : isSelected;
        const index = spPoints?.features?.[0]?.properties?.winding === -1 ? idx : points.length - 1 - idx;
        pointToDraw.pointIndex = index;
        pointToDraw.drawingPoint = points[idx];
        polygonMesh.add(pointToDraw);
    });

    // Middle Points
    if (!repeatedVertices.length) {
        for (let index = 0; index < polygonVertices?.length; index++) {
            const indexCalculated =
                spPoints?.features.length > 1 ? index
                : spPoints?.features?.[0]?.properties?.winding === -1 ? index + 1
                : points?.length - 1 - index;
            const currentPoint = polygonVertices?.[index];
            const nextPoint = polygonVertices?.[(index + 1) % polygonVertices.length];

            const distance = Math.sqrt(Math.pow(nextPoint.x - currentPoint.x, 2) + Math.pow(nextPoint.y - currentPoint.y, 2));
            const isNear = distance <= 2;

            if (!isNear) {
                const middlePointPos = getPointInBetweenByPerc(currentPoint, nextPoint, 0.5);
                if (wireframe) {
                    const linePoints = [currentPoint, nextPoint];
                    const lineCoords = [vector3ToLatLngAlt(currentPoint), vector3ToLatLngAlt(nextPoint)];
                    createDimension(lineCoords, linePoints, wireframe, { type: meshType, isSelected });
                }
                const middlePpointToDraw = drawPoint(scene, middlePointPos, {
                    meshType,
                    isMiddlePoint: true,
                    middlePointIndex: indexCalculated,
                    isAdding: true,
                });
                middlePpointToDraw.name = 'middlePoint';
                middlePpointToDraw.visible = isSelected;

                polygonMesh.add(middlePpointToDraw);
            }
        }
    }
};
const getVerticesPositionsFromGeometry = (geometry) => {
    const position = geometry.getAttribute('position');

    if (position?.array?.length / position?.itemSize !== position?.count)
        throw new Error(
            `Invalid Geometry: arrayLength(${position?.array?.length}) / itemSize(${position.itemSize}) should be equal to count(${position.count})`
        );

    if (position?.itemSize === 1) return position?.array;

    const positions: any[] = [];
    for (let index = 0; index < position?.array.length; index += position?.itemSize) {
        switch (position?.itemSize) {
            case 2: {
                positions.push(new Vector2(position?.array[index], position?.array[index + 1]));
                break;
            }
            case 3: {
                positions.push(new Vector3(position?.array[index], position?.array[index + 1], position?.array[index + 2]));
                break;
            }
            case 4: {
                positions.push(
                    new Vector3(position?.array[index], position?.array[index + 1], position?.array[index + 2]),
                    position?.array[index + 3]
                );
                break;
            }
            default:
                break;
        }
    }

    return positions;
};
export const focusOnLatLng = (position, map = _map) => {
    if (!map) return;

    const bounds = new window.google.maps.LatLngBounds();
    if (position instanceof LatLng) {
        bounds.extend({
            lat: position.lat(),
            lng: position.lng(),
        });
    } else if (isLatLngLiteral(position)) {
        bounds.extend({
            lat: position.lat,
            lng: position.lng,
        });
    } else return;

    map.fitBounds(bounds);
    map.setZoom(map.getZoom() - 2);
    _threejsOverlay.camera.zoom = map.zoom;
};
export const zoomHandler = (zoom, overlays) => {
    let newZoom = copy(parseInt(zoom));
    const { map = _map, threejsOverlay = _threejsOverlay } = overlays;
    if (Math.abs(newZoom - threejsOverlay.camera.zoom) > 1.1) return;
    const maxZoom = threejsOverlay.camera.maxZoom;
    const minZoom = threejsOverlay.camera.minZoom;
    if (newZoom >= maxZoom) newZoom = maxZoom;
    if (newZoom <= minZoom) newZoom = minZoom;

    if (zoom <= maxZoom) {
        threejsOverlay.camera.zoom = newZoom;
        map.setZoom(newZoom);
    }

    threejsOverlay.camera.updateProjectionMatrix();
};
export const distance = (from, to) => {
    const { lat: latFrom, lng: lngFrom } = from;
    const { lat: latTo, lng: lngTo } = to;

    const dLat = MathUtils.degToRad(latTo - latFrom);
    const dLon = MathUtils.degToRad(lngTo - lngFrom);
    const lat1 = MathUtils.degToRad(latFrom);
    const lat2 = MathUtils.degToRad(latTo);

    const a = Math.pow(Math.sin(dLat / 2), 2) + Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);

    return 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) * EARTH_RADIUS_METERS;
};

const vector3ToLatLngAlt = (vec3: Vector3, anchor: google.maps.LatLngAltitude = _threejsOverlay.anchor) => {
    // apply the operation in a clone of the passed vector, so that its original value keeps unchanged
    if (typeof vec3?.clone !== 'function') return { lat: anchor.lat, lng: anchor.lng, altitude: 0 };
    const vector3 = vec3.clone();

    if (typeof vector3?.applyQuaternion !== 'function') return { lat: anchor.lat, lng: anchor.lng, altitude: 0 };

    vector3.applyQuaternion(_threejsOverlay.rotationInverse.clone().invert());

    const z = vector3.z;

    const [anchorX, anchorY] = latLngToXY(anchor);

    vector3.multiplyScalar(1 / Math.cos(MathUtils.degToRad(anchor.lat)));

    const { lat, lng } = xyToLatLng([vector3.x + anchorX, vector3.y + anchorY]);

    return { lat, lng, altitude: anchor.altitude + z };
};

const getPointInBetweenByPerc = (pointA, pointB, percentage) => {
    let dir = pointB.clone().sub(pointA);
    const len = dir.length();
    dir = dir.normalize().multiplyScalar(len * percentage);
    return pointA.clone().add(dir);
};

export const isCounterClockWise = (pointA, pointB, pointC) => {
    const area = (pointB.x - pointA.x) * (pointC.y - pointA.y) - (pointB.y - pointA.y) * (pointC.x - pointA.x);

    if (area === 0) return false; // collinear
    if (area < 0) return false; // clockwise

    return true;
};

const getVectorFromLatLng = (mapsMouseEvent, threejsOverlay) => {
    const latLng = mapsMouseEvent.latLng;
    const newPos = {
        lat: latLng.lat(),
        lng: latLng.lng(),
        altitude: 0,
    };
    const vector3 = threejsOverlay.latLngAltitudeToVector3(newPos);
    const vector2 = new Vector2(vector3.x, vector3.y);
    return { vector3, newPos, vector2 };
};

export const convertLatLngToXY = (latLng, threejsOverlay) => {
    const newPos = {
        lat: latLng.lat,
        lng: latLng.lng,
        altitude: 0,
    };
    const vector3 = threejsOverlay.latLngAltitudeToVector3(newPos);
    const vector2 = new Vector2(vector3.x, vector3.y);
    return { vector3, newPos, vector2 };
};

const computeThresholdValue = (zoom: number) => {
    // Base value at zoom 23
    const baseValue = 0.1;

    // Step size for value increase with lower zoom
    const stepSize = 0.25;

    // Calculate the zoom difference from the base zoom (23)
    const zoomDifference = 23 - zoom;

    // Calculate the value decrease based on zoom difference and step size
    const valueDecrease = zoomDifference * stepSize;

    // Resultant value based on base value and decrease
    const result = baseValue + valueDecrease;

    return result;
};

const keydownListenerFunction = (e: KeyboardEvent, vars) => {
    const { canDrawPolygon, isDrawingExclusions, isDrawingBuildings } = vars;
    switch (e.key) {
        case 'Shift':
            if (canDrawPolygon || isDrawingExclusions || isDrawingBuildings || !!dragPoint) {
                isStraightLine = true;
            }
            break;
        case 'Delete': {
            vars.spvEventDispatchHandler(SpvActions.SET_SHORTCUT, { name: SolarpvShortcuts.DELETE });
            break;
        }
        default:
            break;
    }
};

const keyupListenerFunction = (e: KeyboardEvent) => {
    switch (e.key) {
        case 'Shift':
            isStraightLine = false;
            straightLinePosition = null;
            _threejsOverlay.scene.remove(..._threejsOverlay.scene.getObjectsByProperty('name', 'guideline'));
            break;
        default:
            break;
    }
};

export const forceMapRender = () => {
    _map.panTo({ lat: _map.center.lat() + 0.000000001, lng: _map.center.lng() });
};

const isPolygonSelfIntersected = (points) => {
    const simplePoly = {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                points?.filter((p) => isDefined(p?.center?.x) && isDefined(p?.center?.y))?.map((p) => [p?.center?.x, p?.center?.y]),
            ],
        },
    };
    //aqui neste caso asumimos que se for duplicado é porque é self-intersected
    const spPoints =
        hasDuplicateVertices(simplePoly?.geometry?.coordinates?.[0] ?? []) ? { features: [true, true] } : simplepolygon(simplePoly);
    return spPoints.features.length > 1;
};

export const cleanupSelfIntersectingPolygonPoints = () => {
    !!sceneObjects.closingLine && _threejsOverlay.scene.remove(sceneObjects.closingLine);
    !!sceneObjects.drawningLine && _threejsOverlay.scene.remove(sceneObjects.drawningLine);
    sceneObjects.polygonPoints?.forEach((point) => {
        _threejsOverlay.scene.remove(point.point);
    });
    sceneObjects.lines?.forEach((line) => {
        _threejsOverlay.scene.remove(line);
    });
    sceneObjects = {
        polygonPoints: [],
        lines: [],
        middlePoints: [],
        drawningLine: {},
        closingLine: {},
    };
    allScenePoints.forEach((point) => {
        _threejsOverlay.scene.remove(point);
    });
    allScenePoints = [];
    notify(intlMessages('page.spvPro.toast.irregularNewAreaGroup'), 'error');
};

export const getSelfIntersectingPolygonPoints = (features, normalPoints, positionVector, coordsOnly = false) => {
    const modifiedPolygonPoints: any[] = [];
    for (let i = 0; i < features.length; i++) {
        modifiedPolygonPoints.push([]);
    }

    features.forEach((feature, ind) => {
        feature.geometry.coordinates[0].forEach((coord) => {
            if (
                modifiedPolygonPoints[ind].find(
                    (p) => (!coordsOnly ? p.center.x : p.x) === coord[0] && (!coordsOnly ? p.center.y : p.y) === coord[1]
                )
                // @ts-ignore
            );
            else {
                const existingPoint = normalPoints.find(
                    (p) => (!coordsOnly ? p.center.x : p.x) === coord[0] && (!coordsOnly ? p.center.y : p.y) === coord[1]
                );
                if (existingPoint) modifiedPolygonPoints[ind].push(existingPoint);
                else {
                    if (!coordsOnly) {
                        const pointToDraw = drawPoint(_threejsOverlay.scene, new Vector3(coord[0], coord[1], 0), {
                            meshType: MESH_TYPES.POLYGON,
                            visible: false,
                        });
                        const newPoint = {
                            point: pointToDraw,
                            center: pointToDraw?.center,
                            coords: new LatLng(positionVector.newPos),
                        };
                        modifiedPolygonPoints[ind].push(newPoint);
                    } else modifiedPolygonPoints[ind].push(new Vector2(coord[0], coord[1]));
                }
            }
        });
    });
    // @ts-ignore
    modifiedPolygonPoints.forEach((pol) => {
        // eslint-disable-next-line
        pol = [...new Set(pol)];
    });

    return modifiedPolygonPoints;
};

const preparingMeshToUndo = (meshToModify: TPolygonMesh) => {
    if (!meshToModify) return;
    undoMesh = meshToModify.clone();
    const pointsToRemove: TPointMesh[] = [];
    undoMesh.traverse((obj) => {
        if (['point', 'middlePoint'].includes(obj.name)) {
            // @ts-ignore
            pointsToRemove.push(obj);
        }
    });

    pointsToRemove.forEach((child) => {
        child.geometry.dispose();
        undoMesh!.remove(child);
    });
};

const undoPreviousPolygon = (groups, meshType, spvEventDispatchHandler, { visibility }) => {
    let group: Nullable<Polygon[]> = null;
    switch (meshType) {
        case MESH_TYPES.POLYGON:
            group = groups?.polygonGroups;
            break;
        case MESH_TYPES.EXCLUSION:
            group = groups?.exclusionGroups;
            break;
        case MESH_TYPES.BUILDING:
            group = groups?.buildingGroups;
            break;
        default:
            break;
    }
    if (!undoMesh) return;
    _threejsOverlay.scene.remove(dragPoint.parent);
    const prevMesh = undoMesh.clone();
    const polygon = group?.find((pol) => pol.getSelected());
    // @ts-ignore
    const points = [...new Set(polygon?.getPaths()?.map(JSON.stringify))]?.map((point: any) => {
        const parsedPoint = JSON.parse(point);
        const newPos = {
            lat: parsedPoint.lat,
            lng: parsedPoint.lng,
            altitude: 0,
        };
        const positionVector = _threejsOverlay.latLngAltitudeToVector3(newPos);
        return new Vector2(positionVector.x, positionVector.y);
    });

    addPointsAndMiddlePointsToMesh(prevMesh, true, meshType, { points });
    _threejsOverlay.scene.add(prevMesh);
    switch (meshType) {
        case MESH_TYPES.POLYGON:
            polygonsScene.push(prevMesh);
            selectPolygon(prevMesh.name, group, { visibility });
            break;
        case MESH_TYPES.EXCLUSION:
            exclusionsScene.push(prevMesh);
            selectExclusion(
                prevMesh?.name,
                group,
                group?.find((pol) => pol.getSelected()),
                spvEventDispatchHandler,
                { visibility }
            );
            group = groups?.exclusionGroups;
            break;
        case MESH_TYPES.BUILDING:
            buildingsScene.push(prevMesh);
            selectBuilding(
                prevMesh?.name,
                group,
                group?.find((pol) => pol.getSelected()),
                spvEventDispatchHandler,
                { visibility }
            );
            group = groups?.buildingGroups;
            break;
        default:
            break;
    }

    resetScene(_threejsOverlay.scene, MESH_TYPES.POLYGON, false);
    undoMesh = null;
    notify(intlMessages('page.spvPro.toast.irregularAreaGroup'), 'error');
};
const getCenterPointFromCoords = (coords) => {
    const xMin = Math.min(...coords.map((c) => c.x));
    const xMax = Math.max(...coords.map((c) => c.x));

    const yMin = Math.min(...coords.map((c) => c.y));
    const yMax = Math.max(...coords.map((c) => c.y));

    const zMin = Math.max(...coords.map((c) => c.z));
    const zMax = Math.max(...coords.map((c) => c.z));

    return new Vector3((xMin + xMax) / 2, (yMin + yMax) / 2, (zMin + zMax) / 2);
};
// endregion Three.js Utils

// #region Modes Exits
export const exitModeMove = ({ setIsOverlayOpen, spvEventDispatchHandler, hasAutoUpdateKPIs }) => {
    if (_threejsOverlay.scene.getObjectsByProperty('name', 'tempMesh').length > 0) return;
    setIsOverlayOpen(false);
    spvEventDispatchHandler(SpvActions.SET_MODES, { modes: [{ name: SPV_OVERLAY_MODES.MOVE, value: false }] });
    if (hasAutoUpdateKPIs)
        spvEventDispatchHandler(SpvActions.SET_AUTO_UPDATE_KPIS, {
            hasAutoUpdateKPIs: true,
            update: true,
        });
};
export const exitModeAddPanels = ({
    spvEventDispatchHandler,
    usefulAreaData,
    panels,
    threejsOverlay,
    inputs,
    visibility,
    setInfoTagsHandler,
    selectedArea,
    setToolbarData,
}) => {
    spvEventDispatchHandler(SpvActions.SET_MODES, {
        modes: [{ name: SPV_OVERLAY_MODES.PANEL_EDITOR, value: false }],
    });
    resetExcludedPanels({
        usefulAreaData,
        panels,
        threejsOverlay,
        inputs,
        resetExcludedPanels,
        visibility,

        setInfoTagsHandler,
        selectedArea,

        setToolbarData,
    });
};

export const exitModeAlignment = ({ setIsOverlayOpen, spvEventDispatchHandler, hasAutoUpdateKPIs }) => {
    setIsOverlayOpen(false);
    spvEventDispatchHandler(SpvActions.SET_MODES, {
        modes: [{ name: SPV_OVERLAY_MODES.ALIGNMENT, value: false }],
    });
    if (hasAutoUpdateKPIs)
        spvEventDispatchHandler(SpvActions.SET_AUTO_UPDATE_KPIS, {
            hasAutoUpdateKPIs: true,
            update: true,
        });
};
export const exitModeOrientation = ({ setIsOverlayOpen, spvEventDispatchHandler, hasAutoUpdateKPIs }) => {
    setIsOverlayOpen(false);
    spvEventDispatchHandler(SpvActions.SET_MODES, { modes: [{ name: SPV_OVERLAY_MODES.ORIENTATION, value: false }] });
    if (hasAutoUpdateKPIs)
        spvEventDispatchHandler(SpvActions.SET_AUTO_UPDATE_KPIS, {
            hasAutoUpdateKPIs: true,
            update: true,
        });
};
export const exitModeMapOptions = ({ setIsOverlayOpen, spvEventDispatchHandler }) => {
    setIsOverlayOpen(false);
    spvEventDispatchHandler(SpvActions.SET_MODES, {
        modes: [{ name: SPV_OVERLAY_MODES.MAP_OPTIONS, value: false }],
    });
};
export const exitAddressMode = ({ facilityLatitude, facilityLongitude, setToolbarValue }) => {
    centerMapOnLocation({ lat: facilityLatitude, lng: facilityLongitude });
    setToolbarValue(null);
};
// endregion Modes Exists
//
// #region Map Utils
export const blockAutoExclusionsMap = (isBlocking) => {
    _exclusionsMap.setOptions({
        gestureHandling: isBlocking ? 'none' : undefined,
        zoomControl: isBlocking ? false : undefined,
    });
};
export const blockImgContractMap = (isBlocking) => {
    _imgContractMap.setOptions({
        gestureHandling: isBlocking ? 'none' : undefined,
        zoomControl: isBlocking ? false : undefined,
    });
};
export const getExclusionsMapZoom = (areas) => {
    handleAddExclusionMapListeners(null, areas);
    return _exclusionsMap.getZoom();
};
export const addAutoExclusions = async (exclusionsPolygons, exclusionGroups, setHasChangesInExclusions, solarpvDispatchHandler) => {
    const tempExclusionGroups = copy(exclusionGroups);

    await exclusionsPolygons.forEach((exclusion) => {
        const coordinates: { lat: number; lng: number }[] = [];

        if (exclusion?.length > 1) {
            for (let i = 0; i < exclusion.length; i++) {
                coordinates.push({
                    lat: exclusion[i].lat,
                    lng: exclusion[i].lng,
                });
            }

            const polygon = buildDefaultExclusionPolygon(exclusion, false, tempExclusionGroups, {
                zIndex: 20,
                // TODO: here add height
            });
            buildDefaultAreaMeshFromPolygon(
                polygon,
                { threejsOverlay: _threejsOverlay, scene: _threejsOverlay.scene },
                true,
                MESH_TYPES.EXCLUSION
            );
            tempExclusionGroups.push(polygon);
        }
    });
    await solarpvDispatchHandler(SpvActions.SET_DIALOG, { autoExclusions: false });
    blockAutoExclusionsMap(false);
    await solarpvDispatchHandler(SpvActions.UPDATE_EXCLUSION_GROUPS, {
        exclusionGroups: tempExclusionGroups,
        setHasChangesInExclusions,
        selectedExclusion: null,
    });
};
export const onDiscardExclusionsChanges = (exclusionGroups, savedExclusions) => {
    let cExclusionGroups = copy(exclusionGroups);
    // delete all
    cExclusionGroups.forEach((exc) => {
        _threejsOverlay.scene.remove(exclusionsScene.find((mesh) => mesh.name === exc.id));
        exclusionsScene = exclusionsScene.filter((mesh) => mesh.name !== exc.id);
    });
    // rebuild all saved
    cExclusionGroups = [];
    savedExclusions.forEach((exc) => {
        const pol = buildDefaultExclusionPolygon(
            exc.coordinates.map((c) => new LatLng(c[0], c[1])),
            false,
            cExclusionGroups,
            { id: exc.id, draggable: false, editable: true, center: exc?.centroid, zIndex: 20, height: exc?.height ?? 0 }
        );
        cExclusionGroups.push(pol);
        buildDefaultAreaMeshFromPolygon(pol, {}, true, MESH_TYPES.EXCLUSION);
    });
    return cExclusionGroups;
};
export const captureSPv3ImageContract = async (threeVar, fetchPostFileUploadImgContract, options) => {
    const { inputs, miniOverlay, miniThreejsOverlay } = options;
    const { usefulAreaData } = threeVar;

    // Focus on the center of the installation.
    const installationCenter = imgContractMapOptions(inputs).center;
    focusOnLatLng({
        lat: installationCenter.lat,
        lng: installationCenter.lng,
    });

    miniOverlay.current = new window.google.maps.WebGLOverlayView();
    miniThreejsOverlay.current = new ThreeJSOverlayView({
        map: _imgContractMap,
        upAxis: 'Z',
        anchor: _imgContractMap?.getCenter()?.toJSON(),
    });
    window.google.maps.event.addListenerOnce(_imgContractMap, 'idle', function () {
        miniOverlay.current = createMiniOverlay(
            _imgContractMap,
            inputs,
            {
                threejsOverlay: miniThreejsOverlay.current,
                overlay: miniOverlay.current,
            },
            usefulAreaData
        );
    });

    // set zoom
    handleAddImgContractMapListeners(inputs?.areas);

    // Function to recursively traverse and set visibility
    function setVisibility(object, visibility) {
        object.visible = visibility;

        if (object.children) {
            object.children.forEach((child) => {
                setVisibility(child, visibility);
            });
        }
    }

    //Hide GM controls
    // @ts-ignore
    if (isDefined(document.querySelector('.gmnoprint')?.style)) document.querySelector('.gmnoprint').style.display = 'none';

    // Set visibility of all objects in the scene to false
    _threejsOverlay.scene.traverse((obj) => {
        setVisibility(obj, false);
    });

    // Wait for the map zoom, focus and polygons to be updated
    setTimeout(() => {
        // @ts-ignore
        html2canvas(document.querySelector('#imgContractMap .gm-style'), { useCORS: true }).then(
            (canvas) => {
                canvas.toBlob((blob) => {
                    fetchPostFileUploadImgContract(blob, options);
                });
            },
            'image/png',
            1
        );

        // Set visibility of all objects in the scene to true
        _threejsOverlay.scene.traverse((obj) => {
            setVisibility(obj, true);
        });

        //Reset GM controls
        // @ts-ignore
        if (document.querySelector('.gmnoprint')?.style?.display) document.querySelector('.gmnoprint').style.display = 'block';
    }, 2000);
};
export const computeStepActions = (stepToGo: number, newSimpleModeInputs: any) => {
    switch (stepToGo) {
        case SIMPLE_MODE_STEPS.ADDRESS:
            //clean threejs
            polygonsScene = [];
            _roofDetailsThreejsOverlay?.scene.clear();
            _threejsOverlay?.scene.clear();
            //update inputs ?

            break;
        case SIMPLE_MODE_STEPS.DRAW_ROOF_MANUALLY:
            _threejsOverlay?.scene.children.forEach((children) => {
                if (children.isMesh) {
                    children.visible = true;
                }
                if (children.isSprite) {
                    children.visible = false;
                }
            });
            polygonsScene = [];
            _roofDetailsThreejsOverlay?.scene.clear();
            break;
        case SIMPLE_MODE_STEPS.ROOF_IDENTIFICATION:
            _threejsOverlay?.scene.children.forEach((children) => {
                if (children.isMesh) {
                    children.visible = false;
                }
                if (children.isSprite) {
                    children.visible = false;
                }
            });
            // polygonsScene = [];

            break;
        case SIMPLE_MODE_STEPS.ROOF_DETAILS:
            _roofDetailsThreejsOverlay?.scene.clear();
            newSimpleModeInputs.areas = [];
            newSimpleModeInputs.exclusions = [];
            newSimpleModeInputs.total_areas = 0;
            newSimpleModeInputs.total_panels = 0;
            newSimpleModeInputs.inverters_combination = [];
            newSimpleModeInputs.coordinates_conversion_factor = null;
            break;
        case SIMPLE_MODE_STEPS.KPIS_DISPLAY:
            break;
        case SIMPLE_MODE_STEPS.BUSINESS_MODELS:
            break;
        default:
            break;
    }
    return newSimpleModeInputs;
};
export const prepareAutoExclusionsBounds = (boundsPolygon, gMaxZoom, polygon) => {
    _exclusionsMap.fitBounds(boundsPolygon);
    _exclusionsMap.setCenter(boundsPolygon.getCenter());
    _exclusionsMap.setZoom(gMaxZoom);
    let containes = false;
    let maxZoom = gMaxZoom;
    while (!containes && maxZoom > 15) {
        // eslint-disable-next-line no-loop-func
        containes = polygon.getPaths().every(function (path) {
            return _exclusionsMap.getBounds().contains(path.toJSON());
        });
        maxZoom = !containes ? maxZoom - 1 : maxZoom;
        if (!containes) _exclusionsMap.setZoom(maxZoom);
    }
};
export const getGeographicalyDistance = (point1: { lat: number; lng: number }, point2: { lat: number; lng: number }) => {
    const R = 6371e3; // metres
    const φ1 = MathUtils.degToRad(point1.lat);
    const φ2 = MathUtils.degToRad(point2.lat);
    const Δφ = MathUtils.degToRad(point2.lat - point1.lat);
    const Δλ = MathUtils.degToRad(point2.lng - point1.lng);

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
};
export const importGeoTIFF = (url: string, data: GeoTiff, showLayer: boolean, layerName: string) => {
    const { bounds, width, height } = data;

    const geometry = new PlaneGeometry(width * 0.1, height * 0.1);

    const loader = new TextureLoader();
    loader.load(url, (texture) => {
        const center = new LatLng((bounds.north + bounds.south) / 2, (bounds.east + bounds.west) / 2);
        const vec3Center = _threejsOverlay.latLngAltitudeToVector3({ lat: center.lat(), lng: center.lng(), altitude: 0 });

        const material = new MeshBasicMaterial({ map: texture });
        const mesh = new Mesh(geometry, material);
        mesh.position.set(vec3Center.x, vec3Center.y, 0);
        mesh.name = layerName;

        _threejsOverlay.scene.add(mesh);

        // Show layer
        if (showLayer) {
            mesh.visible = true;
        } else {
            mesh.visible = false;
        }
    });
};
export const setLayerVisible = (selectedLayerName) => {
    //We will set all meshes to invisible if there's no layer selected
    if (!isFieldDefined(selectedLayerName)) {
        _threejsOverlay.scene.children.forEach((child) => {
            if (GOOGLE_SOLAR_LAYERS.some((layer) => layer.name === child.name)) {
                child.visible = false;
            }
        });
        //We will set the selected layer to visible and the rest to invisible
    } else {
        _threejsOverlay.scene.children.forEach((child) => {
            if (child.name === selectedLayerName) {
                child.visible = true;
            } else if (GOOGLE_SOLAR_LAYERS.some((layer) => layer.name === child.name && layer.name !== selectedLayerName)) {
                child.visible = false;
            }
        });
    }
};
export const deleteAllLayers = () => {
    const childrenToRemove = _threejsOverlay.scene.children.filter((child) => {
        return GOOGLE_SOLAR_LAYERS.some((layer) => layer.name === child.name);
    });

    childrenToRemove.forEach((child) => {
        _threejsOverlay.scene.remove(child);
    });
};
export const setLabelsVisible = (visible) => {
    if (visible) _map?.setMapTypeId('hybrid');
    else _map?.setMapTypeId('satellite');
};
export const resetMapAnchor = () => {
    const currentPosition = _map.getCenter().toJSON();
    _threejsOverlay.setAnchor(currentPosition);
    _threejsOverlay.requestStateUpdate();
};
export const centerMapOnLocation = (location) => {
    if (isNumberDefined(location?.lat) && isNumberDefined(location?.lng) && typeof _map?.setCenter === 'function') {
        const latLng = new google.maps.LatLng(Number(location.lat), Number(location.lng));
        _map.setCenter(latLng);
    }
};
// #endregion Map Utils

// #region GMap Marker
export const createMarkerOnHandler = ({ location, setCanDragMarker, setMarker }) => {
    centerMapOnLocation(location);
    // const SVG_DATA_URL = getSVGMarkerUrl();

    if (!gMarker) {
        gMarker = new google.maps.Marker({
            map: _map,
            //@ts-ignore
            icon: {
                url: MarkerIcon,
                // IMPORTANT! set the anchor as the 'vertex' point of the marker,
                // otherwise its position changes when 'zoom_changed' is called
                scaledSize: new google.maps.Size(60, 80),
                anchor: new google.maps.Point(30, 70),
            } as google.maps.Icon,
            position: location,
            draggable: true,
            visible: true,
            crossOnDrag: false,
        });
        setCanDragMarker(true);

        gMarker!.addListener('dragend', (e: google.maps.MapMouseEvent) => {
            const newLocation = e.latLng!.toJSON();
            setMarker(newLocation);
            updateMarkerOnHandler({ location: newLocation });
        });
    } else {
        gMarker.setVisible(true);
        gMarker.setPosition(location);
        setCanDragMarker(true);
    }
};
export const getSVGMarkerUrl = () => {
    // Get company colors
    const bodyStyles = document.body.style;
    const primaryColor = bodyStyles.getPropertyValue('--primary-color');
    const primaryColorDark = bodyStyles.getPropertyValue('--primary-color-dark');

    const SVG_DATA_URL = `data:image/svg+xml,%3Csvg width="71" height="93" viewBox="0 0 71 93" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cg filter="url(%23filter0_d_3707_86752)"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M23.553 69.9997C17.5596 62.0176 10.8503 54.2929 7.47697 44.7528C-0.518942 22.1121 11.3977 0.107539 35.2404 0C59.0876 0.107539 71.0042 22.1121 63.0084 44.7528C58.938 56.2675 46.4645 71.0946 37.7996 80.3727C35.2404 83.1098 35.245 83.1098 32.6858 80.3727C29.6118 77.0834 26.5052 73.4198 23.553 69.9997Z" fill="${primaryColor}"/%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M37.7992 80.3726C46.4642 71.0945 58.9375 56.2673 63.008 44.7527C69.8155 25.4759 62.1892 6.66245 45.0558 1.42682C58.5165 8.5526 63.9577 25.4104 57.8754 42.6378C53.805 54.1571 41.3316 68.9842 32.6666 78.2577C32.2876 78.66 31.9695 79.0063 31.6841 79.287C32.0209 79.652 32.3531 80.0123 32.6853 80.3726C35.2446 83.1097 35.24 83.1097 37.7992 80.3726Z" fill="${primaryColorDark}"/%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M10.7941 30.6411C10.7941 43.7044 21.3819 54.2972 34.4449 54.2972C47.5124 54.2972 58.1003 43.7044 58.1003 30.6411C58.1003 17.5778 47.5124 6.98984 34.4449 6.98517C21.3819 6.9805 10.7941 17.578 10.7941 30.6411Z" fill="%23FFFFFF"/%3E%3C/g%3E%3C/svg%3E`;

    return SVG_DATA_URL;
};
export const updateMarkerOnHandler = ({ location }) => {
    if (typeof _map?.setCenter === 'function') _map.setCenter(location);
    if (typeof gMarker?.setPosition === 'function') gMarker!.setPosition(location);
};
export const deleteMarkerOnHandler = (deleteFromScene = false) => {
    if (!deleteFromScene) gMarker?.setVisible(false);
    else gMarker?.setMap(null);
};
// #endregion GMap Marker

// #region Resets
export const clearTheejsOverlay = () => {
    _threejsOverlay?.scene.clear();
};
export const resetScene = (scene = _threejsOverlay.scene, meshType = MESH_TYPES.POLYGON, add = true) => {
    if (!scene) return;

    //remove mouse moving line animation
    scene?.remove(sceneObjects.drawningLine);
    sceneObjects.drawningLine = {};

    scene?.remove(sceneObjects.closingLine);
    sceneObjects.closingLine = {};

    //if exist a polygon on actual scene, save
    if (add && actualPolygonScene !== null && Object.keys(actualPolygonScene).length > 0) {
        scene?.add(actualPolygonScene);
        switch (meshType) {
            case MESH_TYPES.POLYGON:
                polygonsScene.push(actualPolygonScene);
                break;
            case MESH_TYPES.EXCLUSION:
                exclusionsScene.push(actualPolygonScene);
                break;
            case MESH_TYPES.BUILDING:
                buildingsScene.push(actualPolygonScene);
                break;
            default:
                break;
        }
        actualPolygonScene = null;
    }
    if (!add) {
        actualPolygonScene = null;
    }

    //remove points from actual polygon
    sceneObjects.middlePoints.forEach((p) => {
        scene?.remove(p);
    });
    sceneObjects.lines.forEach((p) => {
        scene?.remove(p);
    });

    allScenePoints.forEach((p) => {
        scene?.remove(p.point);
    });

    allScenePoints.length = 0;
    lastValidPointUuid = '';
    sceneObjects.middlePoints = [];
    sceneObjects.lines = [];
    sceneObjects.polygonPoints = [];
};
export const resetSimpleSolarProduct = () => {
    _roofDetailsThreejsOverlay = undefined;
};
export const resetSolarProduct = (excludedParameters: string[] = []) => {
    allScenePoints = excludedParameters.includes('allScenePoints') ? allScenePoints : []; //Every point clicked on screen
    polygonsScene = excludedParameters.includes('polygonsScene') ? polygonsScene : [];
    panelsScene = excludedParameters.includes('panelsScene') ? panelsScene : [];
    exclusionsScene = excludedParameters.includes('exclusionsScene') ? exclusionsScene : [];
    buildingsScene = excludedParameters.includes('buildingsScene') ? buildingsScene : [];
    actualPolygonScene = excludedParameters.includes('actualPolygonScene') ? actualPolygonScene : null;
    actualPolygonGroup = excludedParameters.includes('actualPolygonGroup') ? actualPolygonGroup : null;
    lastValidPointUuid = '';
    sceneObjects =
        excludedParameters.includes('sceneObjects') ? sceneObjects : (
            { polygonPoints: [], lines: [], middlePoints: [], drawningLine: {}, closingLine: {} }
        );
    mousePosition = new Vector2();
    actualCursorCenter = new Vector3();
    !!gMarker && gMarker.setMap(null);
    gMarker = null;
    isDraging = false;
    dragPoint = null;
    dragStartPoint = null;
    dragError = null;
    polygonIdToUpdate = null;
    polygonToCopy = null;
    polygonMoved = false;
    isStraightLine = false;
    straightLinePosition = null;
    _exclusionsMap = undefined;
    _imgContractMap = undefined;
    maxZoomService = excludedParameters.includes('maxZoomService') ? maxZoomService : undefined;
    orientationVerticesAndAspect = [];
    originalCenter = undefined;
    !!_stats && document.body.removeChild(_stats.dom);
    _stats = undefined;
    _roofDetailsSimpleMap = undefined;
    _roofDetailsThreejsOverlay = undefined;
};
export const resetProjectHandler = (spvEventDispatchHandler) => {
    if (Array.isArray(polygonsScene) && polygonsScene.length > 0) {
        try {
            for (const mesh of polygonsScene) {
                // set the mesh invisible
                mesh.visible = false;
                // remove from the scene
                _threejsOverlay.scene.remove(mesh);
            }

            polygonsScene = [];
        } catch (e) {
            for (const mesh of polygonsScene) {
                // set the mesh invisible
                mesh.visible = true;
                // remove from the scene
                _threejsOverlay.scene.add(mesh);
            }
        }
    }
    if (Array.isArray(exclusionsScene) && exclusionsScene.length > 0) {
        try {
            for (const mesh of exclusionsScene) {
                // set the mesh invisible
                mesh.visible = false;
                // remove from the scene
                _threejsOverlay.scene.remove(mesh);
            }

            exclusionsScene = [];
        } catch (e) {
            for (const mesh of exclusionsScene) {
                // set the mesh invisible
                mesh.visible = true;
                // remove from the scene
                _threejsOverlay.scene.add(mesh);
            }
        }
    }
    if (Array.isArray(buildingsScene) && buildingsScene.length > 0) {
        try {
            for (const mesh of buildingsScene) {
                // set the mesh invisible
                mesh.visible = false;
                // remove from the scene
                _threejsOverlay.scene.remove(mesh);
            }

            buildingsScene = [];
        } catch (e) {
            for (const mesh of buildingsScene) {
                // set the mesh invisible
                mesh.visible = true;
                // remove from the scene
                _threejsOverlay.scene.add(mesh);
            }
        }
    }
    spvEventDispatchHandler(SpvActions.RESET_PROJECT, {});
};
export const resetExcludedPanels = (options) => {
    const { usefulAreaData, panels, threejsOverlay, inputs, selectedArea, visibility, setToolbarData } = options;

    okPanels = [];
    nokPanels = [];
    excludedPanelsCounterClick = 0;
    drawGroupPanels(usefulAreaData, inputs.areas, panels.maxTecPanels, false, {
        threejsOverlay: threejsOverlay.current,
        selectedArea,
        visibility,
    });

    setToolbarData((old) => ({
        ...old,
        [SolarPvToolbarOptions.PANEL_EDITOR]: {
            nokPanels,
            okPanels,
            excludedPanelsCounterClick,
        },
    }));
};
export const resetInputsHandler = (spvEventDispatchHandler) => {
    if (Array.isArray(polygonsScene) && polygonsScene.length > 0) {
        try {
            for (const mesh of polygonsScene) {
                // set the mesh invisible
                mesh.visible = false;
                // remove from the scene
                _threejsOverlay.scene.remove(mesh);
            }

            // Dispatch
            spvEventDispatchHandler(SpvActions.DELETE_ALL_GROUPS, {});

            polygonsScene = [];
        } catch (e) {
            for (const mesh of polygonsScene) {
                // set the mesh invisible
                mesh.visible = true;
                // remove from the scene
                _threejsOverlay.scene.add(mesh);
            }

            notify('Error on deleteAllPolygons, polygons were not deleted from the scene', 'error');
            console.error('Error on deleteAllPolygons, polygons were not deleted from the scene');
        }
    }
};
export const returnSpvAdvanced = () => {
    if (Array.isArray(buildingsScene) && buildingsScene.length > 0) {
        for (const mesh of buildingsScene) {
            // set the mesh invisible
            mesh.visible = false;
            // remove from the scene
            _threejsOverlay.scene.remove(mesh);
        }

        buildingsScene = [];
    }
};

// #endregion Resets

// #region Context Menu
export const actionClickMenuContext = (scene, overlay, positionVector, uuid, action) => {
    if (action === 'delete') {
        //find the current point
        const indexPointToRemove = allScenePoints.findIndex((p) => p?.point?.uuid === dragPoint?.uuid);
        //remove the point
        if (indexPointToRemove !== -1) {
            allScenePoints.splice(indexPointToRemove, 1);
        }

        //Redraw the polygon
        const polygonPoints = allScenePoints;

        lastValidPointUuid = polygonPoints[polygonPoints.length - 1].point.uuid;
        drawPolygon(scene, overlay, polygonPoints);

        dragPoint = null;
    }
};
// #endregion Context Menu

// #region Style
export const computePanelStyle = (isEditingExclusions, isValid = true, invalidPanelColor = styles.polygon.fillColor.panelInvalid) => {
    return {
        clickable: false,
        editable: false,
        dragable: false,
        strokeOpacity: styles.polygon.strokeOpacity,
        fillColor: isValid ? styles.polygon.fillColor.panelSelected : invalidPanelColor,
        fillOpacity:
            isEditingExclusions ?
                isValid ? 0.1
                :   0.3 * 0.1
            : isValid ? 1
            : 0.3,
        strokeWeight: 10,
        strokeColor: isEditingExclusions ? styles.polygon.strokeColor.panelNotSelected : styles.polygon.strokeColor.panelSelected,
        zIndex: 15,
    };
};
export const computePolygonStyle = (isSelected /* (futureproof) here goes things like companyID, userTypeID, etc. */) => {
    return {
        fillColor: isSelected ? styles.polygon.fillColor.selected : styles.polygon.fillColor.notSelected,
        fillOpacity: styles.polygon.fillOpacity,
        strokeColor: isSelected ? styles.polygon.strokeColor.selected : styles.polygon.strokeColor.notSelected,
        strokeOpacity: styles.polygon.strokeOpacity,
        strokeWeight: styles.polygon.strokeWeight,
        zIndex: 1,
    };
};
export const computeExclusionStyle = (isSelected /* (futureproof) here goes things like companyID, userTypeID, etc. */) => {
    return {
        fillColor: isSelected ? styles.exclusionZone.fillColor.selected : styles.exclusionZone.fillColor.notSelected,
        fillOpacity: isSelected ? styles.exclusionZone.fillOpacity.selected : styles.exclusionZone.fillOpacity.notSelected,
        strokeColor: isSelected ? styles.exclusionZone.strokeColor.selected : styles.exclusionZone.strokeColor.notSelected,
        strokeOpacity: isSelected ? styles.exclusionZone.strokeOpacity.selected : styles.exclusionZone.strokeOpacity.notSelected,
        strokeWeight: styles.exclusionZone.strokeWeight,
        zIndex: 20,
    };
};
const applyUnselectedStyleToMesh = (mesh, apply = true) => {
    const unselectedStyles = computePolygonStyle(false);

    // Polygon color
    apply && mesh.material.color.set(unselectedStyles.fillColor);

    // Wireframe color
    const wireframes = mesh.getObjectsByProperty('name', 'wireframe');
    wireframes?.forEach((w) => {
        if (w.type !== 'panel') w.material.color.set(unselectedStyles.strokeColor);
    });
};
const applyUnselectedExclusionStyleToMesh = (mesh, apply = true) => {
    const unselectedStyles = computeExclusionStyle(false);

    // Polygon color
    apply && mesh.material.color.set(unselectedStyles.fillColor);
    mesh.material.opacity = unselectedStyles.fillOpacity;

    // Wireframe color
    const wireframes = mesh.getObjectsByProperty('name', 'wireframe');
    wireframes?.forEach((w) => w.material.color.set(unselectedStyles.strokeColor));
};
const applyPolygonStylesToMesh = (mesh, polygon, visibility) => {
    const styles = polygon.getStyles();

    // Polygon color
    mesh.material.color.set(styles.fillColor);

    mesh.material.opacity = visibility?.areas ? styles?.fillOpacity : 0;

    // Wireframe color
    const wireframes = mesh.getObjectsByProperty('name', 'wireframe');
    wireframes?.forEach((w) => {
        if (w.type !== 'panel') w.material.color.set(styles.strokeColor);
    });

    mesh.children?.forEach((subElement) => {
        if (subElement?.name === 'wireframe') subElement.visible = visibility?.areas;
        if (subElement?.name === 'point') subElement.visible = visibility?.areas;
        if (subElement?.name === 'middlePoint') subElement.visible = visibility?.areas;
    });
};
const applyExclusionStylesToMesh = (mesh, visibility) => {
    const exclusionStyles = computeExclusionStyle(true);

    // Polygon color
    mesh.material.color.set(exclusionStyles?.fillColor);
    mesh.material.opacity = exclusionStyles?.fillOpacity;

    // Wireframe color
    const wireframes = mesh.getObjectsByProperty('name', 'wireframe');
    wireframes?.forEach((w) => w.material.color.set(exclusionStyles?.strokeColor));
    mesh.visible = visibility;
};
const meshTypeColor = (meshType) => {
    switch (meshType) {
        case MESH_TYPES.POLYGON:
            return styles.polygon.fillColor.selected;
        case MESH_TYPES.EXCLUSION:
        case MESH_TYPES.BUILDING:
            return styles.orientationPolygon.fillColor;
        default:
            return styles.polygon.fillColor.selected;
    }
};
// #endregion Style
