//Components
import Lottie2 from 'components/@efz/Lottie/Lottie2';

//Assets
import RobotAnimation from 'components/errors/ErrorClock/Lottie404.json';
import { ReactComponent as ErrorBackground } from 'assets/@efz/backgrounds/error-background.svg';
import { StyledErrorBoundary } from 'components/errors/ErrorBoundary/StylesErrorBoundary';

const ErrorClock = () => {
    //Cant get IntlMessages to work

    return (
        <StyledErrorBoundary className="clock">
            <ErrorBackground className="error-boundary-background" preserveAspectRatio="none" />
            <div className="error-boundary-wrapper">
                <Lottie2
                    options={{
                        animationData: RobotAnimation,
                    }}
                    width={258}
                    height={295.5}
                    isPaused={false}
                />
                <h1>It seems, you have your clock out of sync.</h1>
                <p>Please set it automatically.</p>
            </div>
        </StyledErrorBoundary>
    );
};

export default ErrorClock;
