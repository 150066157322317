import { getBrand, getHeadTitle } from 'services/settings';
import { getCompanyProfileIds } from 'services/user';
import { AVAILABLE_USER_LOCALES } from './user';

//Registry const
export const BASENAME_URL = '/';
export const BASENAME_URL_LOGIN = BASENAME_URL + 'login';
export const BASENAME_URL_APP = BASENAME_URL + 'app/';
export const BASENAME_URL_LOGOUT = BASENAME_URL_APP + 'logout';
export const BASENAME_URL_HOME = BASENAME_URL_APP + 'solutions';
export const BASENAME_URL_SOLUTIONS = BASENAME_URL_APP + 'solutions';
export const BASENAME_URL_PROCESSES = BASENAME_URL_APP + 'processes';
export const BASENAME_URL_PROCESSES_PROPOSALS = BASENAME_URL_PROCESSES + '/proposals';
export const BASENAME_URL_PROCESSES_CREATE_SITE_SURVEY = BASENAME_URL_PROCESSES + '/create/site-survey';
export const BASENAME_URL_PROCESSES_DASHBOARD_SITE_SURVEY = BASENAME_URL_PROCESSES + '/dashboard/site-survey';
export const BASENAME_URL_PROCESSES_SITE_SURVEY = BASENAME_URL_PROCESSES + '/site-survey';
export const BASENAME_URL_PROCESSES_OM = BASENAME_URL_PROCESSES + '/om';
export const BASENAME_URL_PROCESSES_CREATE_OM = BASENAME_URL_PROCESSES + '/create/om';
export const BASENAME_URL_PROCESSES_DASHBOARD_OM = BASENAME_URL_PROCESSES + '/dashboard/om';
export const BASENAME_URL_FACILITY = BASENAME_URL_APP + 'facility';
export const BASENAME_URL_FACILITIES = BASENAME_URL_APP + 'facilities';
export const BASENAME_URL_CREATE_CLIENT = BASENAME_URL_APP + 'client/create';
export const BASENAME_URL_CLIENT = BASENAME_URL_APP + 'client';
export const BASENAME_URL_COOKIES_POLICIES = BASENAME_URL_APP + 'cookie-policies';
export const BASENAME_URL_USER = BASENAME_URL_APP + 'user';
export const BASENAME_URL_BACKOFFICE = BASENAME_URL_APP + 'backoffice';
export const BASENAME_URL_OAREA_PPC_B2B = 'solarpvpro';
export const BASENAME_URL_OAREA_PPC_B2C = 'solarpv';

export const URL_BUSINESS_MODELS = 'business-models';
export const THEME_ENTITY_DUMMY = 'edp';

//ACTION
export const DARK_THEME = 'DARK_THEME';
export const WINDOW_WIDTH = 'WINDOW_WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';
export const CHANGE_DIRECTION = 'CHANGE_DIRECTION';
export const CHANGE_NAVIGATION_STYLE = 'CHANGE_NAVIGATION_STYLE';
export const HORIZONTAL_NAVIGATION = 'HORIZONTAL_NAVIGATION';
export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';
export const REQUEST_THEME_SUCCESS = 'REQUEST_THEME_SUCCESS';
export const REQUEST_THEME_FAIL = 'REQUEST_THEME_FAIL';
export const MAP_LANGUAGE = 'MAP_LANGUAGE';

// * DEFAULT OBJECTS * \\
export const ENVIRONMENTS_FE = {
    DEV: 'DEV',
    TST: 'TST',
    PRE: 'PRE',
    PRD: 'PRD',
    UAT: 'UAT',
};

export const SUBDOMAINS = {
    S2C: 's2c',
    EDP: 'edp',
    CEZ: 'cez',
    PPC: 'ppc',
    MY_ENERGY_SOLAR: 'myenergysolar',
    MY_ENERGY_SOLAR_PRO: 'myenergysolarpro',
};

export const MYENERGYSOLAR_B2C_URL_TST = 'tst.myenergysolar.dei.gr';
export const MYENERGYSOLAR_B2C_URL_PROD = 'myenergysolar.dei.gr';
export const MYENERGYSOLAR_B2B_URL_TST = 'tst-myenergysolarpro.dei.gr';
export const MYENERGYSOLAR_B2B_URL_PROD = 'myenergysolarpro.dei.gr';

//Local Storage Keys
export const LS_KEYS = {
    WEBSTOMP: {
        MQ_MESSAGE: 'MQ_MESSAGE',
    },
};

export const AVAILABLE_LOCALES = {
    PT: 'pt',
    ES: 'es',
    EN: 'en',
    CZ: 'cz',
    IT: 'it',
    PL: 'pl',
    BR: 'br',
    CH: 'ch',
    IE: 'ie',
    GR: 'gr',
    SG: 'sg',
    DE: 'de',
    FR: 'fr',
};

// https://lingohub.com/developers/supported-locales/language-designators-with-regions
export const AVAILABLE_LANGUAGES = [
    {
        languageId: 'Portuguese (Portugal)',
        locale: AVAILABLE_LOCALES.PT, //locale to file name
        userLocale: AVAILABLE_USER_LOCALES.ptPT, //TODO: @deprecate Remover em breve
        appLocale: AVAILABLE_USER_LOCALES.ptPT,
        isoLocale: AVAILABLE_LOCALES.PT,
        localePickers: AVAILABLE_LOCALES.PT,
        name: 'label.portuguese',
        nameTranslated: 'label.portugueseTranslated',
        icon: AVAILABLE_LOCALES.PT,
        flagIcon: `assets/images/icons/svg/flags/pt.svg`,
    },
    {
        languageId: 'Spanish (Spain)',
        locale: AVAILABLE_LOCALES.ES,
        userLocale: AVAILABLE_USER_LOCALES.esES, //TODO: @deprecate Remover em breve
        appLocale: AVAILABLE_USER_LOCALES.esES,
        isoLocale: AVAILABLE_LOCALES.ES,
        localePickers: AVAILABLE_LOCALES.ES,
        name: 'label.spanish',
        nameTranslated: 'label.spanishTranslated',
        icon: AVAILABLE_LOCALES.ES,
        flagIcon: `assets/images/icons/svg/flags/es.svg`,
    },
    {
        languageId: 'English (United Kingdom)',
        locale: AVAILABLE_LOCALES.EN,
        userLocale: AVAILABLE_USER_LOCALES.enGB, //TODO: @deprecate Remover em breve
        appLocale: AVAILABLE_USER_LOCALES.enGB,
        isoLocale: AVAILABLE_LOCALES.EN,
        localePickers: 'en-gb',
        name: 'label.english',
        nameTranslated: 'label.englishTranslated',
        icon: 'gb',
        flagIcon: `assets/images/icons/svg/flags/gb.svg`,
    },
    {
        languageId: 'czech',
        locale: AVAILABLE_LOCALES.CZ,
        userLocale: AVAILABLE_USER_LOCALES.czCZ, //TODO: @deprecate Remover em breve
        appLocale: AVAILABLE_USER_LOCALES.czCZ,
        isoLocale: AVAILABLE_LOCALES.CZ,
        localePickers: 'cs',
        name: 'label.czech',
        nameTranslated: 'label.czechTranslated',
        icon: AVAILABLE_LOCALES.CZ,
        flagIcon: `assets/images/icons/svg/flags/cz.svg`,
    },
    {
        languageId: 'italian',
        locale: AVAILABLE_LOCALES.IT,
        userLocale: AVAILABLE_USER_LOCALES.itIT, //TODO: @deprecate Remover em breve
        appLocale: AVAILABLE_USER_LOCALES.itIT,
        isoLocale: AVAILABLE_LOCALES.IT,
        localePickers: AVAILABLE_LOCALES.IT,
        name: 'label.italian',
        nameTranslated: 'label.italianTranslated',
        icon: AVAILABLE_LOCALES.IT,
        flagIcon: `assets/images/icons/svg/flags/it.svg`,
    },
    {
        languageId: 'Polish (Poland)',
        locale: AVAILABLE_LOCALES.PL,
        userLocale: AVAILABLE_USER_LOCALES.plPL, //TODO: @deprecate Remover em breve
        appLocale: AVAILABLE_USER_LOCALES.plPL,
        isoLocale: AVAILABLE_LOCALES.PL,
        localePickers: AVAILABLE_LOCALES.PL,
        name: 'label.polish',
        nameTranslated: 'label.polishTranslated',
        icon: AVAILABLE_LOCALES.PL,
        flagIcon: `assets/images/icons/svg/flags/pl.svg`,
    },
    {
        languageId: 'Portuguese (Brazil)',
        locale: AVAILABLE_LOCALES.BR,
        userLocale: AVAILABLE_USER_LOCALES.ptBR, //TODO: @deprecate Remover em breve
        appLocale: AVAILABLE_USER_LOCALES.ptBR,
        isoLocale: AVAILABLE_LOCALES.BR,
        localePickers: 'pt-br',
        name: 'label.portuguese',
        nameTranslated: 'label.portugueseTranslated',
        icon: AVAILABLE_LOCALES.BR,
        flagIcon: `assets/images/icons/svg/flags/br.svg`,
    },
    {
        languageId: 'German (Switzerland)',
        locale: AVAILABLE_LOCALES.DE,
        userLocale: AVAILABLE_USER_LOCALES.deCH, //TODO: @deprecate Remover em breve
        appLocale: AVAILABLE_USER_LOCALES.deCH,
        isoLocale: 'de',
        localePickers: 'de-ch',
        name: 'label.deutsch',
        nameTranslated: 'label.deutschTranslated',
        icon: AVAILABLE_LOCALES.DE,
        flagIcon: `assets/images/icons/svg/flags/de.svg`,
    },
    {
        languageId: 'Greek (Greece)',
        locale: AVAILABLE_LOCALES.GR,
        userLocale: AVAILABLE_USER_LOCALES.elGR, //TODO: @deprecate Remover em breve
        appLocale: AVAILABLE_USER_LOCALES.elGR,
        isoLocale: AVAILABLE_LOCALES.GR,
        localePickers: 'el',
        name: 'label.greek',
        nameTranslated: 'label.greekTranslated',
        icon: AVAILABLE_LOCALES.GR,
        flagIcon: `assets/images/icons/svg/flags/gb.svg`,
    },
    {
        languageId: 'English (Ireland)',
        locale: AVAILABLE_LOCALES.EN,
        userLocale: AVAILABLE_USER_LOCALES.enIE, //TODO: @deprecate Remover em breve
        appLocale: AVAILABLE_USER_LOCALES.enIE,
        isoLocale: AVAILABLE_LOCALES.EN,
        localePickers: 'en-ie',
        name: 'label.english',
        nameTranslated: 'label.englishTranslated',
        icon: 'gb',
        flagIcon: `assets/images/icons/svg/flags/gb.svg`,
    },
    {
        languageId: 'English (Singapore)',
        locale: AVAILABLE_LOCALES.EN,
        userLocale: AVAILABLE_USER_LOCALES.enSG, //TODO: @deprecate Remover em breve
        appLocale: AVAILABLE_USER_LOCALES.enSG,
        isoLocale: AVAILABLE_LOCALES.EN,
        localePickers: 'en-sg',
        name: 'label.english',
        nameTranslated: 'label.englishTranslated',
        icon: 'gb', //@deprecated
        flagIcon: `assets/images/icons/svg/flags/gb.svg`,
    },
    {
        languageId: 'French (Switzerland)',
        locale: AVAILABLE_LOCALES.FR,
        userLocale: AVAILABLE_USER_LOCALES.frCH, //TODO: @deprecate Remover em breve
        appLocale: AVAILABLE_USER_LOCALES.frCH,
        isoLocale: 'fr',
        localePickers: 'fr-ch',
        name: 'label.french',
        nameTranslated: 'label.frenchTranslated',
        icon: AVAILABLE_LOCALES.FR,
        flagIcon: `assets/images/icons/svg/flags/de.svg`, //TODO: update flag
    },
    {
        languageId: 'German',
        locale: AVAILABLE_LOCALES.DE,
        userLocale: AVAILABLE_USER_LOCALES.deDE, //TODO: @deprecate Remover em breve
        appLocale: AVAILABLE_USER_LOCALES.deDE,
        isoLocale: 'de',
        localePickers: 'de',
        name: 'label.deutsch',
        nameTranslated: 'label.deutschTranslated',
        icon: AVAILABLE_LOCALES.DE,
        flagIcon: `assets/images/icons/svg/flags/de.svg`,
    },
    {
        languageId: 'English (United States)',
        locale: AVAILABLE_LOCALES.EN,
        userLocale: AVAILABLE_USER_LOCALES.enUS, //TODO: @deprecate Remover em breve
        appLocale: AVAILABLE_USER_LOCALES.enUS,
        isoLocale: AVAILABLE_LOCALES.EN,
        localePickers: 'en-US',
        name: 'label.english',
        nameTranslated: 'label.englishTranslated',
        icon: 'gb',
        flagIcon: `assets/images/icons/svg/flags/gb.svg`,
    },
    {
        languageId: 'French (France)',
        locale: AVAILABLE_LOCALES.FR,
        userLocale: AVAILABLE_USER_LOCALES.frFR, //TODO: @deprecate Remover em breve
        appLocale: AVAILABLE_USER_LOCALES.frFR,
        isoLocale: AVAILABLE_LOCALES.FR,
        localePickers: 'fr-FR',
        name: 'label.french',
        nameTranslated: 'label.frenchTranslated',
        icon: AVAILABLE_LOCALES.FR,
        flagIcon: `assets/images/icons/svg/flags/gb.svg`,
    },
];

export const COMPANY_IDS = {
    EDP_PT: 1,
    EDP_ES: 2,
    ESB: 5,
    EDP_IT: 6,
    EFZ: 7,
    EDP_PL: 8,
    EDP_BR: 9,
    TELEFONICA: 10,
    NRG: 11, //antiga GRANGESOLAR
    MONS: 12,
    SUNSEAP: 14,
    PPC: 15,
    ROMANDE: 17,

    // BASE_UK
    DYNAMIC: 3, //B2B
    EDEN: 16, //B2B
    BASE_UK_B2B: 19, //B2B

    BASE_UK_B2C: 18, //B2C
    FREEDOM: 20, //B2C
    SECON: 21, //B2C
    THUEGA: 22, //B2C
};

export const COMPANY_PROFILE_IDS = {
    PROFILE_EDP_PT: 1,
    PROFILE_EDP_ES: 2,
    PROFILE_BASE_UK: 3,
    PROFILE_BASE_IRELAND: 4,
    PROFILE_EDP_IT: 5,
    PROFILE_EDP_PL: 6,
    PROFILE_BASE_SWISS: 7,
    PROFILE_SUNSEAP: 8,
    PROFILE_PPC: 9,
    PROFILE_ROMANDE: 10,
    PROFILE_EFZ: 11,
    PROFILE_BASE_DE: 12,
    PROFILE_BASE_IT: 13,
    PROFILE_EDP_FR: 14,
    PROFILE_BASE_USA: 15,
    EVOLO: 16,
};

// List of companies with white Sales logo on Header
export const CPROFILE_IDS_WITH_SALES_WHITE_LOGO = [getCompanyProfileIds().PPC];

// List of companies with description on Header
export const CPROFILE_IDS_WITH_DESCRIPTION = [getCompanyProfileIds().PPC];

// Number of characters needed to trigger a search on input (used to optimise API response speeds)
export const MIN_CHARACTERES_TO_REQUEST = 2;

// *  REDUX STATE * \\
export const RTL_LOCALES = ['ar'];
export const INITIAL_SETTINGS = {
    isPlatform: true,
    navCollapsed: false,
    darkTheme: false,
    width: window.innerWidth,
    isDirectionRTL: false,
    navigationStyle: HORIZONTAL_NAVIGATION,
    headTitle: getHeadTitle(),
    brand: getBrand(),
    locale: {
        languageId: 'english',
        locale: 'en',
        name: 'label.english',
        icon: 'gb',
    },
    mapLocaleUser: null,
};

export const ALLOW_PATHNAME_REDICT_BY_TOKEN = [
    '/login', //token magic
    '/', //token magic
    '/api/auth/sso/login', //actir
    '/app/solutions',
    '/app/solutions/1/modules/simple',
    '/app/solutions/2/modules/simple',
];

export const SENTRY_CODE_ERRO_TAGS = {
    DB_NOT_FOUND_RANGES: 'DB_NOT_FOUND_RANGES',
    FE_USEFULAREA_MISSING_DATA: 'FE_USEFULAREA_MISSING_DATA',
    BE_MISSING_COST: 'BE_MISSING_COST',
    FE_HP_MISSING_INPUT_FE: 'FE_HP_MISSING_INPUT_FE',
    FE_MISSING_PANEL_ID: 'FE_MISSING_PANEL_ID',
};

export enum SettingsActions {
    SET_IS_MOUNTING_APP = 'SET_IS_MOUNTING_APP',
    SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS',
    MOUNT_APP = 'MOUNT_APP',
    SET_HAS_NEW_VERSION = 'SET_HAS_NEW_VERSION',
    SET_PLATFORM_VERSION = 'SET_PLATFORM_VERSION',
}

export const USE_COMPANYID = [
    COMPANY_PROFILE_IDS.PROFILE_EDP_PL,
    COMPANY_PROFILE_IDS.PROFILE_EDP_ES,
    COMPANY_PROFILE_IDS.PROFILE_EDP_PL,
    COMPANY_PROFILE_IDS.PROFILE_EDP_IT,
    COMPANY_PROFILE_IDS.PROFILE_EDP_PT,
    COMPANY_PROFILE_IDS.PROFILE_BASE_IRELAND,
    COMPANY_PROFILE_IDS.PROFILE_ROMANDE,
    COMPANY_PROFILE_IDS.PROFILE_SUNSEAP,
    COMPANY_PROFILE_IDS.PROFILE_PPC,
    COMPANY_PROFILE_IDS.PROFILE_EFZ,
];
