/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>
 *
 * @description Pedidos à API no contexto BusinessModels
 *
 * @version 20191120
 * @since 20191113 Initial release
 *
 */

// import axios from 'axios';
import axios from 'services/@efz/axios';
import { getMessageNetworkError } from 'services/statusCodeHttp';
import { getEndpoint, URL_AUDIT_FILES, URL_PRODUCTS, URL_SIMULATIONS } from 'constants/endpoints';

const CancelToken = axios.CancelToken;
// Cancel executor variables
let getBusinessModelInfoCancelExecutor;
let postBusinessModelsAbortController;

//Get Business Models
export const postBusinessModels = (payload, abortController = postBusinessModelsAbortController) => {
    if (!abortController) {
        abortController = new AbortController();
    }
    return axios
        .request({
            url: getEndpoint(URL_PRODUCTS) + '/' + payload.productID + '/business-models?language=' + payload.language,
            method: 'POST',
            data: payload.body,
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};
//Get BusinessModel Info
export const getBusinessModelInfo = (payload) => {
    getBusinessModelInfoCancelExecutor && getBusinessModelInfoCancelExecutor();
    return axios
        .post(getEndpoint(URL_PRODUCTS) + '/' + payload.productID + '/business-models/' + payload.businessModelId, payload.body, {
            cancelToken: new CancelToken(function executor(c) {
                getBusinessModelInfoCancelExecutor = c; // An executor function receives a cancel function as a parameter
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Post Installments
export const postInstallmentsSimulation = (payload, abort) => {
    return axios
        .request({
            url: getEndpoint(URL_SIMULATIONS),
            method: 'POST',
            data: payload,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Post Installments
export const postAudit = (payload, abort) => {
    return axios
        .request({
            url: getEndpoint(URL_AUDIT_FILES),
            method: 'POST',
            data: payload,
            signal: abort?.signal,
            responseType: 'arraybuffer',
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};
