import { useTheme } from '@mui/material';

// Icons
import { ReactComponent as SalesLogo } from 'assets/@efz/logos/logo-sales.svg';
import { ReactComponent as SalesLogoWhite } from 'assets/@efz/logos/logo-sales-white.svg';
import { ReactComponent as EffizencySmallLogo } from 'assets/@efz/logos/effizency-logo-small.svg';

// Components
import LazyImage from 'components/util/LazyImage';

// Constants
import { getStaticFiles } from 'constants/endpoints';
import { DEFAULT_LOGO } from 'constants/theme';

// Services
import { isDefined } from 'services/util/auxiliaryUtils';
import { useMediaQuery } from '@mui/material';
import { useMemo } from 'react';
import { isOpenArea } from 'services/settings';

const Logo = ({ showLogoEfz, isAltLogo, isWhite }) => {
    const theme = useTheme();
    const logo = useMemo(() => {
        const logoProp = !isAltLogo ? theme.logo_file_name : theme.alt_logo_file_name;
        return isDefined(logoProp) ? getStaticFiles(logoProp, 'logo/') : DEFAULT_LOGO;
    }, [theme, isAltLogo]);
    const isTablet = useMediaQuery('(max-width:760px)');

    const isOArea = isOpenArea();

    return (
        <div className="d-flex flex-row align-items-center header-logo">
            {showLogoEfz && !isWhite && <SalesLogo className="header-efz-logo me-4" height={12} />}
            {!isOArea && isTablet && <EffizencySmallLogo className="header-small-efz-logo" height={12} />}
            {showLogoEfz && isWhite && <SalesLogoWhite className="header-efz-logo me-4" height={12} />}
            <LazyImage src={logo} alt="efz" sizeImg={40} customClassName="header-logo-placeholder" />
        </div>
    );
};

//defaultProps
Logo.defaultProps = {
    showLogoEfz: true,
    isAltLogo: false,
    isWhite: false,
};

export default Logo;
