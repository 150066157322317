import { Route, Switch } from 'react-router';
import ProposalProcesses from 'routes/Processes/Proposals';
import SiteSurveyProcesses from 'routes/Processes/Process/SiteSurvey';
import OMProcesses from 'routes/Processes/Process/OM';
import Dashboard from 'routes/Processes/Dashboard';
import ReformulationBusinessModels from 'routes/ReformulationBM';
import Proposal from 'routes/Proposal';
import BusinessModels from 'routes/BusinessModels';
import { ProcessesProvider } from 'contexts/processes/processesContext';
import CreateSiteSurveyProcess from 'routes/Processes/Create/CreateSiteSurvey';
import CreateOMProcess from 'routes/Processes/Create/CreateOM';
import { SolutionsProvider } from 'contexts/solutions/solutionsContext';

const ProcessesRoute = ({ url }) => {
    return (
        <SolutionsProvider>
            <ProcessesProvider>
                <Switch>
                    <Route exact path={`${url}/processes/proposals`} component={ProposalProcesses} />
                    <Route
                        exact
                        path={`${url}/processes/proposals/:id/reformulation/business-models`}
                        component={ReformulationBusinessModels}
                    />
                    <Route exact path={`${url}/processes/proposals/:id/reformulation/proposal`} component={Proposal} />
                    <Route exact path={`${url}/processes/proposals/:id/reformulation`} component={ProposalProcesses} />
                    <Route exact path={`${url}/processes/proposals/:id/business-models`} component={BusinessModels} />
                    <Route exact path={`${url}/processes/site-survey/:id`} component={SiteSurveyProcesses} />
                    <Route exact path={`${url}/processes/site-survey/:id/details`} component={SiteSurveyProcesses} />
                    <Route exact path={`${url}/processes/site-survey`} component={SiteSurveyProcesses} />
                    <Route exact path={`${url}/processes/create/site-survey`} component={CreateSiteSurveyProcess} />
                    <Route exact path={`${url}/processes/dashboard/site-survey`} component={Dashboard} />
                    <Route exact path={`${url}/processes/om/:id`} component={SiteSurveyProcesses} />
                    <Route exact path={`${url}/processes/om/:id/details`} component={SiteSurveyProcesses} />
                    <Route exact path={`${url}/processes/site-survey`} component={SiteSurveyProcesses} />
                    <Route exact path={`${url}/processes/om`} component={OMProcesses} />
                    <Route exact path={`${url}/processes/create/om`} component={CreateOMProcess} />
                    <Route exact path={`${url}/processes/dashboard/om`} component={Dashboard} />
                </Switch>
            </ProcessesProvider>
        </SolutionsProvider>
    );
};

export default ProcessesRoute;
