//Libraries
import { memo } from 'react';
import PropTypes from 'prop-types';

//Material UI Component
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { isS2CPlatformVersion } from 'services/settings';
import { IconButton } from '@save2compete/efz-design-system';
import { StyledGenericDialog } from 'components/core/dialogs/GenericDialog/StylesGenericDialog';

//GenericDialog
const GenericDialog = ({ open, title, body, buttons, handleClose, className, hasLine = false }) => {
    const isS2CVersion = isS2CPlatformVersion();

    const onCloseHandler = (_, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }

        if (reason === 'escapeKeyDown') {
            return false;
        }

        if (typeof handleClose === 'function') {
            handleClose();
        }
    };

    const content = (
        <>
            <DialogContent className="generic-dialog-body">{body}</DialogContent>

            <DialogActions className="generic-dialog-actions d-flex justify-content-center">{buttons}</DialogActions>
        </>
    );

    return (
        <StyledGenericDialog onClose={onCloseHandler} open={open} maxWidth="sm" className={`generic-dialog ${className ?? ''}`}>
            <IconButton
                variant="tertiary"
                icon="xMarkExit"
                size="bg"
                aria-label="close"
                className={'generic-dialog-close'}
                onClick={handleClose}
                dataTestId="close-dialog"
            />

            <DialogTitle onClose={handleClose}>
                <div className="generic-dialog-header">
                    {title}
                    {hasLine && <div className={`generic-dialog-header-line ${isS2CVersion ? 'isS2C' : ''}`} />}
                </div>
            </DialogTitle>
            {content}
        </StyledGenericDialog>
    );
};

//PropTypes
GenericDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    body: PropTypes.any,
    buttons: PropTypes.any,
    handleClose: PropTypes.func.isRequired,
    hasLine: PropTypes.bool.isRequired,
};

export default memo(GenericDialog);
