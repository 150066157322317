import { themeBase } from 'constants/styles/global';
import { css, styled } from 'styled-components';
import { media } from 'styles/utils/mediaQueries';

const consentRightsReserved = css`
    font-size: 0.875rem;
    line-height: 19px;
    margin-top: 0.5rem;
    margin-bottom: 2.5rem;
`;

const consentSectionContent = css`
    line-height: 19px;
    font-size: 0.875rem;

    .link {
        cursor: pointer;
        text-decoration: underline;
        color: blue;
    }
`;

const consentSectiontitle = css`
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 19px;

    span {
        font-size: 0.875rem;
        font-weight: ${themeBase.fontWeight.semibold};
        padding-bottom: 0.5rem;
        color: var(--primary-color);

        &.isS2C {
            color: ${({ theme }) => theme.efzRed600};
        }
    }

    hr {
        opacity: 1;
        width: 100%;
        background: rgba(196, 196, 196, 0.5);
        border: none;
        height: 1px;
        margin: 0;
    }
`;
const consentSection = css`
    margin-bottom: 2rem;

    &-title {
        ${consentSectiontitle}
    }

    &-content {
        ${consentSectionContent}
    }

    .privacy-policy-table {
        th {
            color: white;
            height: 40px;

            background: #6c757d;
            width: 100%;
            font-size: 0.875rem;
            border-radius: 5px 5px 0 0;
            border: 1px solid #c4c4c4;
            box-sizing: border-box;
        }

        td {
            padding: 1rem;
        }

        .first-cell {
            min-width: 200px;
            font-weight: ${themeBase.fontWeight.semibold};
            font-size: 0.875rem;
            border: 1px solid #c4c4c4;
            box-sizing: border-box;
        }

        .second-cell {
            line-height: 19px;
            border: 1px solid #c4c4c4;
            box-sizing: border-box;
        }
    }

    .cookie-policy-table {
        width: 100%;

        .head-row {
            background: #f5f5f5;
            border: 1px solid #c4c4c4;
            box-sizing: border-box;
            border-radius: 5px 5px 0px 0px;
            height: 70px;
            text-align: left;
        }

        .cookie-policy-row {
            background: rgba(245, 245, 245, 0.5);
            border: 1px solid #c4c4c4;
            box-sizing: border-box;
            border-radius: 5px 5px 0px 0px;
        }

        th {
            padding: 1rem;
        }

        td {
            vertical-align: top;
            padding: 1rem;
            width: 166px;
            white-space: pre-wrap;
        }

        .cell-spacer {
            width: 59px;
        }
    }

    .personal_data-wrap {
        list-style: none;
        margin: 0;
        padding: 24px;
        border: 1px solid ${({ theme }) => theme.neutral400};
        border-radius: 5px;
        width: 50%;
        @media screen and (max-width: 800px) {
            width: 100%;
        }
        &-list {
            border-bottom: 1px solid ${({ theme }) => theme.neutral400};
            &-item {
                display: flex;
                align-items: center;
                margin: 16px 0;
                transition: 0.7s;
                p {
                    font-size: 1rem;
                    font-weight: 600;
                    margin: 0;
                    width: 75%;
                }
                svg {
                    margin-left: auto;
                    font-size: 2rem;
                }
            }
            &:last-of-type {
                border-bottom: 0px;
                a {
                    margin-bottom: 0;
                }
            }
            &:first-of-type {
                a {
                    margin-top: 0;
                }
            }
        }
    }
`;

export const StyledCookieConsent = styled.div`
    position: absolute;
    ${media.tablet` 
        display: block;
        .cookie-banner-container {
            display: block;
        }
        .cookie-banner {
            &-content {
                margin: 2rem;
            }
        }
        `}

    /* position: fixed; */
    height: 100%;
    width: 100%;
    z-index: 1000;
    background: rgba(196, 196, 196, 0.25);

    &.not-authenticated {
        position: fixed;
        top: 0;
        left: 0;
    }
    &.cookie-consent-not-authenticated {
        top: 0;
    }

    .link {
        cursor: pointer;
        text-decoration: underline;
        color: blue;
    }

    .btn-text {
        text-transform: none;
    }

    .cookie-banner {
        position: absolute;
        bottom: 0;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        background: #f5f5f5;
        border: 1px solid #c4c4c4;
        border-radius: 10px 10px 0 0;

        &-content {
            max-width: 600px;
            margin-right: 2.5rem;
            margin-top: 2rem;
            margin-bottom: 2rem;

            .cookie-banner-title {
                display: flex;
                column-gap: 0.5rem;
                align-items: center;
                font-weight: ${themeBase.fontWeight.semibold};
                line-height: 21.79px;
                margin-bottom: 1.375rem;
                font-size: 1rem;
            }

            .cookie-banner-text {
                font-size: 0.875rem;
                line-height: 19.07px;
            }
            .link {
                color: var(--text-color);
                text-decoration: underline;
            }
        }

        &-button {
            background-color: var(--primary-color);
            color: white;
            text-transform: none;

            &.isS2C {
                background-color: ${themeBase.color.efzRed600};

                &:hover {
                    background-color: ${themeBase.color.efzRed400};
                }
            }
        }
        &-button:hover {
            background-color: var(--primary-color-dark);
        }
    }

    .cookie-consent {
        width: 100%;
        height: 100%;
        z-index: 500;
        z-index: 9999;

        &-authenticated {
            display: flex;
        }

        &-not-authenticated {
            position: fixed;
            top: 0;
            left: 0;
        }

        &-header {
            width: 100%;
            height: 70px;

            display: flex;
            align-items: center;
            justify-content: flex-start;

            background-color: #f5f5f5;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);

            span {
                font-size: 1.125rem;
                font-weight: ${themeBase.fontWeight.semibold};
                color: var(--primary-color);
                line-height: 25px;
            }

            .close-cookie {
                margin: 0 2rem 0 2rem;
            }
        }

        &-body {
            height: 100%;
            background-color: ${themeBase.bodyBg};
            display: flex;
            justify-content: center;
            overflow-y: scroll;

            &-content {
                width: 80%;
                margin-top: 3rem;
            }
        }
    }

    .consent-use-conditions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .consent-title {
        display: flex;
        flex-flow: column nowrap;
        margin-bottom: 2rem;

        span {
            font-size: 1.25rem;
            font-weight: ${themeBase.fontWeight.semibold};
            padding-bottom: 0.5rem;
        }

        hr {
            opacity: 1;
            width: 70px;
            background: var(--primary-color);
            height: 3px;
            margin: 0;
        }
    }
    .consent-section {
        ${consentSection}
    }

    .consent-rights-reserved {
        ${consentRightsReserved}
    }

    .consent-updated {
        ${consentRightsReserved}
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-bottom: 4rem !important;
    }

    .privacy-section {
        ${consentSection}
        margin-bottom: 1.5rem;

        &:last-of-type {
            margin-bottom: 0;
        }

        &-title {
            ${consentSectiontitle}

            &-first-line {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
            }
        }

        &-content {
            ${consentSectionContent};

            display: flex;
            line-height: 1.5rem;
        }

        .label-category {
            min-width: 100px;
            text-align: right;
            color: var(--text-color);
        }

        .link {
            color: var(--text-color);
            text-decoration: underline;
        }
    }

    .privacy-dialog {
        &-back-button {
            justify-content: flex-start !important;
        }
    }
`;

export const StyledCustomizeCookiesDialog = styled.section`
    .consent-section {
        ${consentSection}
    }

    .consent-rights-reserved {
        ${consentRightsReserved}
    }

    .consent-updated {
        ${consentRightsReserved}
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-bottom: 4rem !important;
    }

    .privacy-section {
        ${consentSection}
        margin-bottom: 1.5rem;

        &:last-of-type {
            margin-bottom: 0;
        }

        &-title {
            ${consentSectiontitle}

            &-first-line {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
            }
        }

        &-content {
            ${consentSectionContent};

            display: flex;
            line-height: 1.5rem;
        }

        .label-category {
            min-width: 100px;
            text-align: right;
            color: var(--text-color);
        }

        .link {
            color: var(--text-color);
            text-decoration: underline;
        }
    }

    .privacy-dialog {
        &-back-button {
            justify-content: flex-start !important;
        }
    }
`;
