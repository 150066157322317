import { GRUPO_CANAL_IDS, USER_COUNTRY_LatLng } from 'constants/user';
import validate from 'services/util/validate';
import { intlMessages } from 'services/util/auxiliaryUtils';
import { GA_LABEL_SOLAR_PV } from 'constants/gAnalytics';
import IntlMessages from 'components/util/IntlMessages';
import { ReactComponent as InvertersIcon } from 'assets/images/products/spv-pro/svg/inverters.svg';
import FormatNumber from 'components/util/FormatNumber';
import NumberFormat from 'react-number-format';
import CustomUnit from 'components/util/CustomUnit';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

// Icons
import { ReactComponent as DeleteIcon } from 'assets/images/products/spv-pro/svg/delete.svg';
import { ReactComponent as LocationIcon } from 'assets/images/icons/svg/location.svg';
import { ReactComponent as DuplicateIcon } from 'assets/images/icons/svg/duplicate.svg';
import { ReactComponent as SplitIcon } from 'assets/images/products/spv-pro/svg/split.svg';
import { ReactComponent as LeftAlignmentIcon } from 'assets/images/products/spv-pro/svg/left-alignment.svg';
import { ReactComponent as AddDeletePanelsIcon } from 'assets/images/products/spv-pro/svg/add-remove-panels.svg';
import { ReactComponent as PlusIcon } from 'assets/@efz/icons/plus.svg';
import { ReactComponent as EditIcon } from 'assets/images/icons/svg/edit-2.svg';
import { ReactComponent as WandIcon } from 'assets/@efz/icons/wand.svg';
import { ReactComponent as StampIcon } from 'assets/images/icons/svg/stamp.svg';
import { BsArrowsMove as MoveIcon } from 'react-icons/bs';
import { currencyPerPeakPowerUnit, peakPowerUnit } from 'services/products/solarpv';
import { getCompanyProfileIds } from 'services/user';
import { DEFAULTS_COMMON_SETTINGS_MAP } from 'constants/gmapSettings';
import { IconButton } from '@save2compete/efz-design-system';

//InputsDefault
export const INPUTS_DEFAULTS_SPV = {
    areas: [],
    coordinates_avg: {
        lat: null,
        long: null,
        region_gd_id: null,
    },
};
export const DEFAULTS_PROPS_MAP = {
    mapOptions: {
        id: 'map',
        zoom: 20,
        tilt: 0,
        mapType: 'satellite',
        mapTypeId: 'satellite',
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        zoomControl: true,
    },
    settings: DEFAULTS_COMMON_SETTINGS_MAP,
    country: USER_COUNTRY_LatLng,
    polygon: {
        styles: {
            strokeOpacity: 0.9,
            strokeWeight: 2,
            fillOpacity: 0.4,
            strokeColor: {
                selected: 'rgba(245, 234, 143, 1)',
                notSelected: 'rgba(72, 68, 71, 1)',
                panelSelected: 'rgba(255, 255, 255, 1)',
                panelNotSelected: 'rgba(255, 255, 255, 0.3)',
            },
            fillColor: {
                selected: 'rgba(214, 194, 20, 1)',
                notSelected: 'rgba(251, 247, 208, 0.5)',
                panelSelected: 'rgba(1, 23, 61, 1)',
                panelNotSelected: 'rgba(1, 23, 61, 0.3)',
                panelInvalid: 'rgba(1, 23, 61, 0.3)',
            },
            exclusionZone: {
                strokeOpacity: 0.9,
                strokeWeight: 2,
                fillOpacity: 0.8,
                strokeColor: {
                    selected: 'rgb(56, 133, 205)',
                    notSelected: 'rgba(56, 133, 205,0.5)',
                },
                fillColor: {
                    selected: 'rgb(195, 218, 240)',
                    notSelected: 'rgba(195, 218, 240,0.5)',
                },
            },
        },
    },
    oMarker: {
        backgroundUnselected: {
            path: `M0.0108625 5.03124C0.00486329 2.26983 2.23858 0.0290237 5 0.0262775L26.5712 0.00482488C29.3326 0.00207864 31.576 2.23842 31.582 4.99984L31.6232 23.9445L0.0520196 23.9759L0.0108625 5.03124Z`,
            fillColor: '#3885CD',
            fillOpacity: 1,
            strokeWeight: 1,
            strokeColor: '#3885CD',
        },
        backgroundSelected: {
            path: `M0.0108625 5.03124C0.00486329 2.26983 2.23858 0.0290237 5 0.0262775L26.5712 0.00482488C29.3326 0.00207864 31.576 2.23842 31.582 4.99984L31.6232 23.9445L0.0520196 23.9759L0.0108625 5.03124Z`,
            fillColor: '#01173D',
            fillOpacity: 1,
            strokeWeight: 1,
            strokeColor: '#01173D',
        },
        arrow: {
            path: `M15.0039 17.4863C15.0039 17.7625 15.2278 17.9863 15.5039 17.9863C15.78 17.9863 16.0039 17.7625 16.0039 17.4863L15.0039 17.4863ZM15.8575 6.63278C15.6622 6.43751 15.3456 6.43751 15.1504 6.63278L11.9684 9.81476C11.7731 10.01 11.7731 10.3266 11.9684 10.5219C12.1636 10.7171 12.4802 10.7171 12.6755 10.5219L15.5039 7.69344L18.3323 10.5219C18.5276 10.7171 18.8442 10.7171 19.0394 10.5219C19.2347 10.3266 19.2347 10.01 19.0394 9.81476L15.8575 6.63278ZM16.0039 17.4863L16.0039 6.98633L15.0039 6.98633L15.0039 17.4863L16.0039 17.4863Z`,
            fillColor: 'white',
            fillOpacity: 1,
            strokeWeight: 1,
            strokeColor: 'white',
        },
        LetterS: {
            path: `M16.0039 6.98633C16.0039 6.71019 15.78 6.48633 15.5039 6.48633C15.2278 6.48633 15.0039 6.71019 15.0039 6.98633L16.0039 6.98633ZM15.1504 17.8399C15.3456 18.0351 15.6622 18.0351 15.8575 17.8399L19.0394 14.6579C19.2347 14.4626 19.2347 14.1461 19.0394 13.9508C18.8442 13.7555 18.5276 13.7555 18.3323 13.9508L15.5039 16.7792L12.6755 13.9508C12.4802 13.7555 12.1636 13.7555 11.9684 13.9508C11.7731 14.1461 11.7731 14.4626 11.9684 14.6579L15.1504 17.8399ZM15.0039 6.98633L15.0039 17.4863L16.0039 17.4863L16.0039 6.98633L15.0039 6.98633Z`,
            fillColor: 'white',
            fillOpacity: 1,
            strokeWeight: 1,
            strokeColor: 'white',
        },
    },
};
export const KPIS_NPUTS_NAME = {
    RANGE_ID: 'range_id',
    INVESTMENT: 'investment',
    ANNUAL_SAVING: 'annual_saving',
    PEAK_POWER: 'peak_power',
    INVERTER_POWER: 'inverter_power',
    SPECIFIC_PRICE: 'specific_price',
    ANNUAL_PROD: 'annual_prod',
    PAYBACK: 'payback',
    CONST_REDUCTION_PERC: 'cons_reduction_perc',
    INJECTION_PERC: 'injection_perc',
    SELFCONSUMPTION_PERC: 'selfconsumption_perc',
};

export const INPUTS_NAME_SYS_COMPOSITION = {
    //remmover
    AREA: 'area',
    PANELS_NUMBER: 'panels_number',
    SLOPE: 'slope',
    ORIENTATION: 'orientation',
    MOUNTING_STRUCTURE_ID: 'mounting_structure_id',
    PANEL_REPRESENTATION: 'panel_representation',
    EXCLUDED_AREAS: 'holes_coordinates',
    REMOTE: 'remote',
};

export const INJECTION_INPUTS_NAME = {
    NETWORK_SALE: 'network_sale',
    INJECTION_TARIFF: 'injection_tariff',
    REMUNERATION_MODEL: 'remuneration_type_id',
    REMUNERATION_BY_COMPANY_ID: 'remuneration_by_company_id',
    LIMIT_NETWORK_SALE: 'limit_network_sale',
};

export const GROUP_INPUTS_NAME = {
    AREA: 'area',
    PANELS_NUMBER: 'panels_number',
    SLOPE: 'slope',
    ORIENTATION: 'orientation',
    MOUNTING_STRUCTURE_ID: 'mounting_structure_id',
    PANEL_REPRESENTATION: 'panel_representation',
    AREA_REDUCTION_ELEMENTS: 'area_reduction_elements',
};

export const PANEL_ALIGNMENT = {
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right',
};

export const PANEL_ALIGNMENT_IDS = {
    JUSTIFIED_LEFT: 1,
    JUSTIFIED_CENTER: 2,
    JUSTIFIED_RIGHT: 3,
    MAX_PANELS_LEFT: 4,
    MAX_PANELS_CENTER: 5,
    MAX_PANELS_RIGHT: 6,
};

export const DEFAULT_PANEL_ALIGNMENT = PANEL_ALIGNMENT.LEFT;

export const PANEL_ALIGNMENT_VERTICAL = {
    TOP: 'top',
    BOTTOM: 'bottom',
};

export const DEFAULT_PANEL_ALIGNMENT_VERTICAL = PANEL_ALIGNMENT_VERTICAL.BOTTOM;

export const MOUNTING_STRUCTURES = {
    ROOF: 1,
    GROUND: 2,
    COPLANAR: 3,
    TRIANGULAR: 4,
    EAST_WEST: 20,
    ROOF_TILE: 10,
    SIMPLE_SHEET: 7,
    CARPARK: 14,
    CARPARK_SIMPLE: 24,
    CARPARK_DOUBLE: 25,
    CARPARK_Y: 26,
};

export const MOUNTING_STRUCTURES_TYPES = {
    COPLANAR: 1,
    TRIANGULAR: 2,
    GROUND: 3,
    CARPARK: 4,
    EAST_WEST: 5,
};

export const PANEL_REPRESENTATION_INPUT_NAMES = {
    PANELS_WIDE: 'panels_wide', //n_paineis_horizontal
    PANELS_UP: 'panels_up', //n_paineis_vertical
    PANELS_NR: 'panels_nr', //n_paineis total -> east-west structure
    SPACE_BTW_SETS: 'space_btw_sets', //distancia_sets
    HOUR_WINDOW: 'hourWindow', //horas_sem_sombra
    REFERENCE_DAY: 'dayRank', //dia_referencia
    ROOF_SLOPE: 'roofSlope', //inclinacao_cobertura
    ROW_SPACE: 'rowSpace', //row_spacing_valor
    IS_ROW_SPACING_INSERT: 'is_row_spacing_insert', //row_spacing_insert
    PANEL_FRAMING: 'panelFraming', //pv_painel_layout_id
    SETBACK: 'setback', //setback
    ALIGNMENT: 'alignment', //panel alignment (FIXME: get rid of this variable later!)
    ALIGNMENT_MODE_ID: 'alignment_mode_id',
    ALIGNMENT_HORIZONTAL: 'alignment_horizontal', //panel alignment (horizontal)
    ALIGNMENT_VERTICAL: 'alignment_vertical', //panel alignment (vertical)
    ROW_SPACING_MIN: 'row_spacing_min',
    ESTIMATED_DISTANCE: 'estimated_distance',
    REFERENCE_POINT: 'reference_point',
};

export const PANEL_REPRESENTATION_DEFAULT_VALUES = {
    [PANEL_REPRESENTATION_INPUT_NAMES.PANELS_WIDE]: 1,
    [PANEL_REPRESENTATION_INPUT_NAMES.PANELS_UP]: 1,
    [PANEL_REPRESENTATION_INPUT_NAMES.SPACE_BTW_SETS]: 0,
    [PANEL_REPRESENTATION_INPUT_NAMES.HOUR_WINDOW]: 4,
    [PANEL_REPRESENTATION_INPUT_NAMES.REFERENCE_DAY]: null,
    [PANEL_REPRESENTATION_INPUT_NAMES.ROOF_SLOPE]: 0,
    [PANEL_REPRESENTATION_INPUT_NAMES.ROW_SPACE]: undefined,
    [PANEL_REPRESENTATION_INPUT_NAMES.IS_ESTIMATE_ON_ROW_SPACE]: false,
    [PANEL_REPRESENTATION_INPUT_NAMES.SETBACK]: 0,
    [PANEL_REPRESENTATION_INPUT_NAMES.ALIGNMENT]: PANEL_ALIGNMENT.LEFT,
    [PANEL_REPRESENTATION_INPUT_NAMES.ALIGNMENT_HORIZONTAL]: PANEL_ALIGNMENT.LEFT,
    [PANEL_REPRESENTATION_INPUT_NAMES.ALIGNMENT_VERTICAL]: PANEL_ALIGNMENT_VERTICAL.BOTTOM,
    [PANEL_REPRESENTATION_INPUT_NAMES.ROW_SPACING_MIN]: 1,
};

export const SYSTEM_COMPOSITION = {
    TRIANGULAR_GRADE_LIMIT: 15,
    SLOPE: 30,
    ORIENTATION: 0,
    COEFFICIENTS_AREAS_UTILS: {
        //SPV1.0
        OTHERS: 'others',
        PLATBANS: 'platbands',
        CHILLERS: 'chillers',
        SURROUNDING: 'surrounding',
    },
    AREA_REDUCTION_ELEMENTS: {
        //SPV1.0
        others: false,
        platbands: false,
        chillers: false,
        surrounding: false,
    },
};

export const RESET_POLYGON_OPTIONS = {
    visible: false,
    ref: null,
    id: null,
    zIndex: null,
    fillColor: null,
    fillOpacity: null,
    strokeColor: null,
    strokeOpacity: null,
    strokeWeight: null,
    latLngs: null,
    draggable: false,
    editable: null,
};

export const DEFAULT_GROUP = {
    id: null,
    area: null,
    [INPUTS_NAME_SYS_COMPOSITION.ORIENTATION]: SYSTEM_COMPOSITION.ORIENTATION,
    [INPUTS_NAME_SYS_COMPOSITION.SLOPE]: SYSTEM_COMPOSITION.SLOPE,
    [INPUTS_NAME_SYS_COMPOSITION.PANEL_REPRESENTATION]: null,
    coordinates: null,
    coordinates_avg: null,
    lat: null,
    long: null,
    panels_number: null,
    region_gd_id: null,
    mounting_structure_id: null,
    slope: null,
    orientation: 0,
    panel_representation: null,
};

export const DEFAULT_EXCLUSION_ZONE = {
    id: null,
    area: null,
    coordinates: null,
};

export const SETTINGS_SPV = {
    FATOR_AJUSTE: 1.2,
    MALHA_MATRIZ: 1.5,
    MAX_DISTANCE_IN_KMS: 25,
    MAX_INJECTION_PERCENTAGE: 0.1,
    MAX_GROUPS: 25,
    PEAK_POWER_TO_EDIT_TARIFF: 50,
};

export const SETTINGS_PROJECT_SUMMARY = (companyProfileId) => [
    //remover
    {
        name: 'investment',
        label: 'label.investment',
        styleFormat: 'currency',
        visible: true,
        numberOfDecimalPlaces: 0,
        options: null,
    },
    {
        name: 'annual_saving',
        label: 'label.saving',
        unit: 'yc',
        visible: true,
        numberOfDecimalPlaces: 0,
        options: null,
    },
    {
        name: 'peak_power',
        label: 'label.peakPower',
        unit: peakPowerUnit(companyProfileId),
        visible: true,
        numberOfDecimalPlaces: 2,
        options: null,
    },
    {
        name: 'inverter_power',
        label: 'label.invertedPower',
        unit: 'kw',
        visible: true,
        numberOfDecimalPlaces: 2,
        options: null,
    },
    {
        name: 'specific_price',
        label: 'label.specificPrice',
        unit: 'ypp',
        visible: true,
        numberOfDecimalPlaces: 0,
        options: null,
    },
    {
        name: 'annual_prod',
        label: 'label.annualProduction',
        unit: 'mwh',
        visible: true,
        numberOfDecimalPlaces: 2,
        options: null,
    },
    {
        name: 'payback',
        label: 'label.payback',
        unit: 'year',
        visible: true,
        numberOfDecimalPlaces: 1,
        options: null,
    },
    {
        name: 'cons_reduction_perc',
        label: 'label.consReductionPerc',
        styleFormat: 'percent',
        visible: true,
        numberOfDecimalPlaces: 1,
        options: null,
    },
    {
        name: 'representativeness',
        label: 'label.representativeness',
        styleFormat: 'percent',
        visible: false,
        numberOfDecimalPlaces: 1,
        options: null,
    },
    {
        name: 'injection_perc',
        label: 'label.injection',
        styleFormat: 'percent',
        visible: true,
        numberOfDecimalPlaces: 1,
        options: null,
    },
    {
        name: 'selfconsumption_perc',
        label: 'label.selfConsumption',
        styleFormat: 'percent',
        visible: false,
        numberOfDecimalPlaces: 2,
        options: null,
    },
    {
        name: 'battery_capacity_kwh',
        label: 'label.batteryCapacity',
        styleFormat: 'decimal',
        numberOfDecimalPlaces: 2,
        unit: 'kwh',
    },
    {
        name: 'battery_power_kw',
        label: 'label.batteryPower',
        styleFormat: 'decimal',
        numberOfDecimalPlaces: 2,
        unit: 'kw',
    },
];

export const MAX_SPV = {
    [INPUTS_NAME_SYS_COMPOSITION.SLOPE]: 45,
    [INPUTS_NAME_SYS_COMPOSITION.ORIENTATION]: 180,
};
export const MIN_SPV = {
    [INPUTS_NAME_SYS_COMPOSITION.SLOPE]: 0,
    [INPUTS_NAME_SYS_COMPOSITION.ORIENTATION]: -175,
};

export const SETTINGS_SYSTEM_COMPOSITION = [
    // area
    {
        name: INPUTS_NAME_SYS_COMPOSITION.AREA,
        label: 'label.area',
        unit: 'sm',
        visible: true,
        numberOfDecimalPlaces: 0,
    },
    // panels_number
    {
        name: INPUTS_NAME_SYS_COMPOSITION.PANELS_NUMBER,
        label: 'label.panels',
        unit: 'pan',
        visible: true,
        numberOfDecimalPlaces: 0,
    },
    // slope
    {
        name: INPUTS_NAME_SYS_COMPOSITION.SLOPE,
        label: 'label.slopeCoverage',
        unit: 'dg',
        visible: true,
        numberOfDecimalPlaces: 0,
        tooltipInValue: 'label.editSlope',
    },
    // ORIENTATION
    {
        name: INPUTS_NAME_SYS_COMPOSITION.ORIENTATION,
        label: 'label.orientationCoverage',
        unit: 'dg',
        visible: true,
        numberOfDecimalPlaces: 0,
        tooltipInValue: 'label.editOrientation',
    },
    // PANEL REPRESENTATION
    {
        name: INPUTS_NAME_SYS_COMPOSITION.PANEL_REPRESENTATION,
        label: 'page.spv.panelRepresentation',
        visible: true,
        tooltipInValue: 'page.spv.editPanelRepresentation',
    },
    // MOUNTING STRUCTURE
    {
        name: INPUTS_NAME_SYS_COMPOSITION.MOUNTING_STRUCTURE_ID,
        label: 'label.structure',
        visible: true,
        tooltipInValue: 'label.editStructure',
    },
    // EXCLUDED AREAS
    {
        name: INPUTS_NAME_SYS_COMPOSITION.EXCLUDED_AREAS,
        label: 'page.spv.excludedAreas',
        visible: true,
        tooltipInValue: 'page.spv.excludedAreas.tooltip',
    },
];

export const SYSTEM_COMPOSITION_GROUP_ACTIONS = {
    CENTER: 'CENTER',
    ALIGN: 'ALIGN',
    STAMP: 'STAMP',
    CUT: 'CUT',
    SPLIT: 'SPLIT',
    DUPLICATE: 'DUPLICATE',
    MOVE: 'MOVE',
    DELETE: 'DELETE',
    PANEL_EDITOR: 'PANEL_EDITOR',
};

export const SYSTEM_COMPOSITION_GROUP_ACTIONS_CONFIG = {
    [SYSTEM_COMPOSITION_GROUP_ACTIONS.CENTER]: {
        name: SYSTEM_COMPOSITION_GROUP_ACTIONS.CENTER,
        className: 'center',
        visible: false,
        disabled: false,
        icon: <LocationIcon className="fill stroke" />,
        label: 'page.spvPro.label.centerPanels',
        tooltip: false,
    },
    [SYSTEM_COMPOSITION_GROUP_ACTIONS.ALIGN]: {
        name: SYSTEM_COMPOSITION_GROUP_ACTIONS.ALIGN,
        className: 'align',
        visible: false,
        disabled: false,
        icon: <LeftAlignmentIcon className="fill" />,
        label: 'page.spvPro.label.panelALignment',
        tooltip: true,
    },
    [SYSTEM_COMPOSITION_GROUP_ACTIONS.STAMP]: {
        name: SYSTEM_COMPOSITION_GROUP_ACTIONS.STAMP,
        className: 'align',
        visible: false,
        disabled: false,
        icon: <StampIcon className="stamp" />,
        label: 'page.spvPro.label.useAsStamp',
        tooltip: true,
    },
    [SYSTEM_COMPOSITION_GROUP_ACTIONS.DUPLICATE]: {
        name: SYSTEM_COMPOSITION_GROUP_ACTIONS.DUPLICATE,
        className: 'duplicate',
        visible: false,
        disabled: false,
        icon: <DuplicateIcon className="fill stroke" />,
        label: 'label.duplicate',
        tooltip: false,
    },
    [SYSTEM_COMPOSITION_GROUP_ACTIONS.PANEL_EDITOR]: {
        name: SYSTEM_COMPOSITION_GROUP_ACTIONS.PANEL_EDITOR,
        className: 'add-remove-panels',
        visible: false,
        disabled: false,
        icon: <AddDeletePanelsIcon className="inner-fill" />,
        label: 'page.spvPro.mode.addRemovePanels.title',
        tooltip: false,
    },
    [SYSTEM_COMPOSITION_GROUP_ACTIONS.SPLIT]: {
        name: SYSTEM_COMPOSITION_GROUP_ACTIONS.SPLIT,
        className: 'split',
        visible: false,
        disabled: false,
        icon: <SplitIcon className="mask" />,
        label: 'label.split',
        tooltip: false,
    },
    [SYSTEM_COMPOSITION_GROUP_ACTIONS.MOVE]: {
        name: SYSTEM_COMPOSITION_GROUP_ACTIONS.MOVE,
        className: 'move',
        visible: false,
        disabled: false,
        icon: <MoveIcon className="fill" />,
        label: 'page.spvPro.mode.move.title',
        values: {
            panelName: <IntlMessages id="page.spvPro.label.panelGroup" />,
        },
        tooltip: true,
    },
    [SYSTEM_COMPOSITION_GROUP_ACTIONS.DELETE]: {
        name: SYSTEM_COMPOSITION_GROUP_ACTIONS.DELETE,
        className: 'delete',
        visible: false,
        disabled: false,
        icon: <DeleteIcon className="fill" />,
        label: 'page.spvPro.dialog.deletePanels.title',
        tooltip: false,
    },
};

export const PANEL_REPRESENTATION_FORM_QUESTIONS = {
    [PANEL_REPRESENTATION_INPUT_NAMES.PANELS_WIDE]: {
        name: PANEL_REPRESENTATION_INPUT_NAMES.PANELS_WIDE,
        label: 'page.spv.panelRepresentation.panelsWide',
        placeholder: 'page.spv.panelRepresentation.panelsWide.placeholder',
        inputType: 'number',
        step: 1,
        visible: (dbValue) => dbValue,
        defaultValue: (dbValue) => dbValue,
        disabled: (dbValue) => !dbValue,
        limits: (minDbValue, maxDbValue) => {
            return { min: minDbValue, max: maxDbValue };
        },
        rules: (limitValues) => validate(['required', 'integerNumber', 'min', 'max'], limitValues),
    },
    [PANEL_REPRESENTATION_INPUT_NAMES.PANELS_UP]: {
        name: PANEL_REPRESENTATION_INPUT_NAMES.PANELS_UP,
        label: 'page.spv.panelRepresentation.panelsUp',
        placeholder: 'page.spv.panelRepresentation.panelsUp.placeholder',
        inputType: 'number',
        step: 1,
        visible: (dbValue) => dbValue,
        defaultValue: (dbValue) => dbValue,
        disabled: (dbValue) => !dbValue,
        limits: (minDbValue, maxDbValue) => {
            return { min: minDbValue, max: maxDbValue };
        },
        rules: (limitValues) => validate(['required', 'integerNumber', 'min', 'max'], limitValues),
    },
    [PANEL_REPRESENTATION_INPUT_NAMES.PANELS_NR]: {
        name: PANEL_REPRESENTATION_INPUT_NAMES.PANELS_NR,
        label: 'page.spv.panelRepresentation.panelsNumber',
        placeholder: 'page.spv.panelRepresentation.panelsUp.placeholder',
        inputType: 'number',
        step: 1,
        visible: (dbValue) => dbValue,
        defaultValue: (dbValue) => dbValue,
        disabled: (dbValue) => !dbValue,
        limits: (minDbValue, maxDbValue) => {
            return { min: minDbValue, max: maxDbValue };
        },
        rules: (limitValues) => validate(['required', 'integerNumber', 'min', 'max'], limitValues),
    },
    [PANEL_REPRESENTATION_INPUT_NAMES.SPACE_BTW_SETS]: {
        name: PANEL_REPRESENTATION_INPUT_NAMES.SPACE_BTW_SETS,
        label: 'page.spv.panelRepresentation.spaceBtwSets',
        placeholder: 'page.spv.panelRepresentation.spaceBtwSets.placeholder',
        inputType: 'number',
        step: 0.01,
        visible: (dbValue) => dbValue,
        defaultValue: (dbValue) => dbValue,
        disabled: (dbValue) => !dbValue,
        limits: (minDbValue, maxDbValue) => {
            return { min: minDbValue, max: maxDbValue };
        },
        rules: (limitValues) => validate(['required', 'integerNumber', 'positive', 'min', 'max'], limitValues),
    },
    [PANEL_REPRESENTATION_INPUT_NAMES.HOUR_WINDOW]: {
        name: PANEL_REPRESENTATION_INPUT_NAMES.HOUR_WINDOW,
        label: 'page.spv.panelRepresentation.hourWindow',
        placeholder: 'page.spv.panelRepresentation.hourWindow.placeholder',
        step: 0.01,
        inputType: 'number',
        visible: (dbValue) => dbValue,
        defaultValue: (dbValue) => dbValue,
        disabled: (dbValue) => !dbValue,
        limits: (minDbValue, maxDbValue) => {
            return { min: minDbValue, max: maxDbValue };
        },
        rules: (limitValues) => validate(['required', 'integerNumber', 'min', 'max'], limitValues),
    },
    [PANEL_REPRESENTATION_INPUT_NAMES.REFERENCE_DAY]: {
        name: PANEL_REPRESENTATION_INPUT_NAMES.REFERENCE_DAY,
        label: 'page.spv.panelRepresentation.referenceDay',
        placeholder: 'page.spv.panelRepresentation.referenceDay.placeholder',
        rules: validate(['required']),
        visible: (dbValue) => dbValue,
        disabled: (dbValue) => !dbValue,
    },
    [PANEL_REPRESENTATION_INPUT_NAMES.ROOF_SLOPE]: {
        name: PANEL_REPRESENTATION_INPUT_NAMES.ROOF_SLOPE,
        label: 'page.spv.panelRepresentation.roofSlope',
        placeholder: 'page.spv.panelRepresentation.roofSlope.placeholder',
        inputType: 'number',
        rules: validate(['required', 'integerNumber']),
    },
    [PANEL_REPRESENTATION_INPUT_NAMES.ROW_SPACE]: {
        name: PANEL_REPRESENTATION_INPUT_NAMES.ROW_SPACE,
        label: 'page.spv.panelRepresentation.section.distanceBtwRows',
        placeholder: 'page.spv.panelRepresentation.rowSpace.placeholder',
        inputType: 'number',
        step: 0.01,
        visible: (dbValue) => dbValue,
        disabled: (dbValue) => !dbValue,
        limits: (minDbValue, maxDbValue) => {
            return { min: minDbValue, max: maxDbValue };
        },
        rules: (limitValues) => validate(['required', 'positive', 'min', 'max'], limitValues),
    },
    [PANEL_REPRESENTATION_INPUT_NAMES.IS_ROW_SPACING_INSERT]: {
        name: PANEL_REPRESENTATION_INPUT_NAMES.IS_ROW_SPACING_INSERT,
        visible: (dbValue) => dbValue,
        disabled: (dbValue) => !dbValue,
    },
    [PANEL_REPRESENTATION_INPUT_NAMES.PANEL_FRAMING]: {
        name: PANEL_REPRESENTATION_INPUT_NAMES.PANEL_FRAMING,
        label: 'page.spv.panelRepresentation.panelFraming',
        placeholder: 'page.spv.panelRepresentation.panelFraming.placeholder',
        inputType: 'number',
        options: [
            {
                value: 1,
                label: 'page.spv.panelRepresentation.panelFraming.horizontal',
                gAnalyticsEvt: GA_LABEL_SOLAR_PV.FRAMING_LANDSCAPE,
            },
            {
                value: 2,
                label: 'page.spv.panelRepresentation.panelFraming.vertical',
                gAnalyticsEvt: GA_LABEL_SOLAR_PV.FRAMING_PORTRAIT,
            },
        ],
        rules: validate(['required']),
        visible: (dbValue) => dbValue,
        defaultValue: (dbValue) => dbValue,
        disabled: (dbValue) => !dbValue,
    },
    [PANEL_REPRESENTATION_INPUT_NAMES.SETBACK]: {
        name: PANEL_REPRESENTATION_INPUT_NAMES.SETBACK,
        label: 'page.spv.panelRepresentation.setback',
        placeholder: 'page.spv.panelRepresentation.setback.placeholder',
        description: 'page.spv.panelRepresentation.setback.description',
        tooltip: 'page.spv.panelRepresentation.setback.tooltip',
        inputType: 'number',
        step: 0.01,
        visible: (dbValue) => dbValue,
        defaultValue: (dbValue) => dbValue,
        disabled: (dbValue) => !dbValue,
        limits: (minDbValue, maxDbValue) => {
            return { min: minDbValue, max: maxDbValue };
        },
        rules: (limitValues) => validate(['required', 'positive', 'min', 'max'], limitValues),
    },
    [PANEL_REPRESENTATION_INPUT_NAMES.ROW_SPACING_MIN]: {
        name: PANEL_REPRESENTATION_INPUT_NAMES.ROW_SPACING_MIN,
        label: 'page.spv.panelRepresentation.minDistance',
        tooltip: 'page.spv.panelRepresentation.minDistance.tooltip',
        inputType: 'number',
        step: 0.01,
        visible: (dbValue) => dbValue,
        defaultValue: (dbValue) => dbValue,
        disabled: (dbValue) => !dbValue,
        limits: (minDbValue, maxDbValue) => {
            return { min: minDbValue, max: maxDbValue };
        },
        rules: (limitValues) => validate(['required', 'integerNumber', 'min', 'max'], limitValues),
    },
    [PANEL_REPRESENTATION_INPUT_NAMES.ESTIMATED_DISTANCE]: {
        name: PANEL_REPRESENTATION_INPUT_NAMES.ESTIMATED_DISTANCE,
        label: 'page.spv.panelRepresentation.estimatedDistance',
        tooltip: 'page.spv.panelRepresentation.estimatedDistance.tooltip',
        inputType: 'number',
        step: 1,
        rules: validate(['required', 'integerNumber', 'min', 'max'], { min: 1, max: 10 }),
    },
};

export const VISUAL_GUIDE_INFORMATIONS = [
    {
        id: 'A',
        label: 'page.spv.panelRepresentation.visualGuide.distanceBetweenSets',
    },
    {
        id: 'B',
        label: 'page.spv.panelRepresentation.visualGuide.distanceBetweenRows',
    },
    {
        id: 'C',
        label: 'page.spv.panelRepresentation.visualGuide.panelsByWidth',
    },
    {
        id: 'D',
        label: 'page.spv.panelRepresentation.visualGuide.panelsByHeight',
    },
    {
        id: 'E',
        label: 'page.spv.panelRepresentation.visualGuide.panelSet',
    },
    {
        id: 'F',
        label: 'page.spv.panelRepresentation.visualGuide.setback',
    },
];

export const VISUAL_GUIDE_INFORMATIONS_EAST_WEST = [
    {
        id: 'A',
        label: 'page.spv.panelRepresentation.visualGuide.distanceBetweenSets',
    },
    {
        id: 'B',
        label: 'page.spv.panelRepresentation.visualGuide.distanceBetweenRows',
    },
    {
        id: 'C',
        label: 'page.spvPro.label.numberOfPanels',
    },
    {
        id: 'D',
        label: 'page.spv.panelRepresentation.visualGuide.panelSet',
    },
    {
        id: 'E',
        label: 'page.spv.panelRepresentation.visualGuide.setback',
    },
];

export const BUILDING_ACTIONS = {
    EDIT: 'EDIT',
    AUTO_SPLIT: 'AUTO_SPLIT',
    MOVE: 'MOVE',
    DELETE: 'DELETE',
    STAMP: 'STAMP',
};

export const BUILDING_ACTIONS_CONFIG = {
    [BUILDING_ACTIONS.EDIT]: {
        name: BUILDING_ACTIONS.EDIT,
        className: 'move',
        visible: true,
        disabled: false,
        icon: <EditIcon className="fill" width={16} height={16} />,
        label: 'page.spvPro.label.building.edit',
        tooltip: false,
    },
    [BUILDING_ACTIONS.AUTO_SPLIT]: {
        name: BUILDING_ACTIONS.AUTO_SPLIT,
        className: 'auto-split',
        visible: true,
        disabled: false,
        icon: <WandIcon className="fill stroke inner-fill inner-stroke" />,
        label: 'page.spvPro.label.applyAutomatically',
        tooltip: false,
    },
    [BUILDING_ACTIONS.MOVE]: {
        name: BUILDING_ACTIONS.MOVE,
        className: 'move',
        visible: true,
        disabled: false,
        icon: <MoveIcon className="fill" />,
        label: 'page.spvPro.label.building.move',
        tooltip: false,
    },
    [BUILDING_ACTIONS.STAMP]: {
        name: BUILDING_ACTIONS.STAMP,
        className: 'stamp',
        visible: true,
        disabled: false,
        icon: <StampIcon className="stamp" />,
        label: 'page.spvPro.label.useAsStamp',
        tooltip: false,
    },
    [BUILDING_ACTIONS.DELETE]: {
        name: BUILDING_ACTIONS.DELETE,
        className: 'delete',
        visible: true,
        disabled: false,
        icon: <DeleteIcon className="fill" />,
        label: 'page.spvPro.label.building.delete',
        tooltip: false,
    },
};

export const EXLUSIONS_ACTIONS = {
    MOVE: 'MOVE',
    STAMP: 'STAMP',
    DELETE: 'DELETE',
};

export const EXLUSIONS_ACTIONS_CONFIG = {
    [EXLUSIONS_ACTIONS.MOVE]: {
        name: EXLUSIONS_ACTIONS.MOVE,
        className: 'move',
        visible: true,
        disabled: false,
        icon: <MoveIcon className="fill" />,
        label: 'page.spvPro.label.moveExclusion',
        tooltip: false,
    },
    [EXLUSIONS_ACTIONS.STAMP]: {
        name: EXLUSIONS_ACTIONS.STAMP,
        className: 'stamp',
        visible: true,
        disabled: false,
        icon: <StampIcon className="stamp" />,
        label: 'page.spvPro.label.useAsStamp',
        tooltip: false,
    },
    [EXLUSIONS_ACTIONS.DELETE]: {
        name: EXLUSIONS_ACTIONS.DELETE,
        className: 'delete',
        visible: true,
        disabled: false,
        icon: <DeleteIcon className="fill" />,
        label: 'page.spvPro.label.deleteExclusion',
        tooltip: false,
    },
};

export const DATASET_INDEX_POINTS = {
    POINTS_STANDALONE: 0,
    POINTS_BUNDLE: 1,
    SIZED_PEAKPOWER: 2,
    MIN_PAYBACK: 3,
    MAX_CONS_RED_PRC: 4,
    MAX_CLIENT_SAVING_BELOW_LIM_PB: 5,
    MAX_IRR: 6,
    MAX_CLIENT_SAVING: 7,
    MIN_PAYBACK_BUNDLE: 8,
    MAX_CONS_RED_PRC_BUNDLE: 9,
    MAX_CLIENT_SAVING_BELOW_LIM_PB_BUNDLE: 10,
};

//Default map options
export const CHART_PRODUCTION_CONSUMPTION_DEFAULTS = {
    loadingProduction: true,
    loadingConsumption: true,
    type: 'Line',
    data: {
        labels: [],
        datasets: [
            {
                data: [],
                fill: false,
                hidden: false,
                borderColor: 'rgba(0, 90, 139, 1)',
                backgroundColor: 'rgba(0, 90, 139, 1)',
                borderWidth: 2,
                label: intlMessages('label.consumption'),
                keyword: 'label.consumption',
            },
            {
                data: [],
                fill: 2,
                hidden: false,
                borderColor: 'rgba(0, 90, 139, 0)',
                backgroundColor: '#d4ecff',
                label: intlMessages('label.maximumConsumption'),
                keyword: 'label.maximumConsumption',
            },
            {
                data: [],
                fill: false,
                hidden: false,
                borderColor: 'rgba(0, 90, 139, 0)',
                label: intlMessages('label.minimumConsumption'),
                keyword: 'label.minimumConsumption',
            },
            {
                data: [],
                fill: false,
                hidden: false,
                borderColor: 'rgba(62, 192, 155, 1)',
                backgroundColor: 'rgba(62, 192, 155, 1)',
                borderWidth: 2,
                label: intlMessages('label.selfConsumptionBattery'),
                keyword: 'label.selfConsumptionBattery',
            },
            {
                data: [],
                fill: false,
                hidden: false,
                borderColor: 'rgba(217, 83, 79, 1)',
                backgroundColor: 'rgba(217, 83, 79, 1)',
                borderWidth: 2,
                label: intlMessages('label.production'),
                keyword: 'label.production',
            },
        ],
    },
    options: {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            point: {
                radius: 0,
            },
            line: {
                tension: 0,
            },
        },
        legend: {
            position: 'bottom',
            display: true,
            align: 'center',
            onClick: function (e, legendItem) {
                let index = legendItem.datasetIndex;
                let ci = this.chart;
                let meta = ci.getDatasetMeta(index);
                meta.hidden = meta.hidden === null ? !meta.hidden : null;
                ci.update();
            },
            onHover: function (e) {
                e.target.style.cursor = 'pointer';
            },
            labels: {
                padding: 10,
                usePointStyle: true,
                boxWidth: 5,
                filter: function (legendItem, data) {
                    return [0, 3, 4].includes(legendItem?.datasetIndex) && !data.datasets[legendItem?.datasetIndex].hidden;
                },
            },
        },
        hover: {
            onHover: function (e) {
                let point = this.getElementAtEvent(e);
                if (point.length) e.target.style.cursor = 'pointer';
                else e.target.style.cursor = 'default';
            },
        },
        scales: {
            yAxes: [
                {
                    scaleLabel: {
                        display: true,
                        labelString: intlMessages('label.optimalPower_kW'),
                        keyword: 'label.optimalPower_kW',
                    },
                    gridLines: {
                        drawOnChartArea: false,
                    },
                    ticks: {
                        suggestedMin: 0,
                        padding: 5,
                        fontSize: 10,
                        maxTicksLimit: 8,
                    },
                },
            ],
            xAxes: [
                {
                    scaleLabel: {
                        display: true,
                        labelString: intlMessages('label.hour'),
                        keyword: 'label.hour',
                    },
                    gridLines: {
                        drawOnChartArea: false,
                    },
                    ticks: {
                        padding: 5,
                        fontSize: 10,
                    },
                    scaleFontSize: 5,
                },
            ],
        },
        tooltips: {
            enabled: false,
        },
        plugins: {
            filler: {
                propagate: true,
            },
        },
    },
    plugins: [
        {
            beforeUpdate: (chart) => {
                let isHidden = !!chart.getDatasetMeta(0).hidden;
                chart.getDatasetMeta(1).hidden = isHidden;
                chart.getDatasetMeta(2).hidden = isHidden;
            },
            beforeInit: function (chart) {
                // Adds margin between legend and chart
                chart.legend.afterFit = function () {
                    this.height += 5; // must use `function` and not => because of `this`
                };
            },
        },
    ],
};

export const CHART_PRODUCTION_CONSUMPTION_HIDDEN_DEFAULTS = {
    loadingProduction: true,
    loadingConsumption: true,
    type: 'Line',
    data: {
        labels: [],
        datasets: [
            {
                data: [],
                fill: false,
                hidden: true,
                borderColor: 'rgba(0, 90, 139, 1)',
                backgroundColor: 'rgba(0, 90, 139, 1)',
                borderWidth: 2,
                label: intlMessages('label.consumption'),
                keyword: 'label.consumption',
            },
            {
                data: [],
                fill: 2,
                hidden: true,
                borderColor: 'rgba(0, 90, 139, 0)',
                backgroundColor: '#d4ecff',
                label: intlMessages('label.maximumConsumption'),
                keyword: 'label.maximumConsumption',
            },
            {
                data: [],
                fill: false,
                hidden: true,
                borderColor: 'rgba(0, 90, 139, 0)',
                label: intlMessages('label.minimumConsumption'),
                keyword: 'label.minimumConsumption',
            },
            {
                data: [],
                fill: false,
                hidden: true,
                borderColor: 'rgba(62, 192, 155, 1)',
                backgroundColor: 'rgba(62, 192, 155, 1)',
                borderWidth: 2,
                label: intlMessages('label.selfConsumptionBattery'),
                keyword: 'label.selfConsumptionBattery',
            },
            {
                data: [],
                fill: false,
                hidden: true,
                borderColor: 'rgba(217, 83, 79, 1)',
                backgroundColor: 'rgba(217, 83, 79, 1)',
                borderWidth: 2,
                label: intlMessages('label.production'),
                keyword: 'label.production',
            },
        ],
    },
    options: {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            point: {
                radius: 0,
            },
            line: {
                tension: 0,
            },
        },
        legend: {
            position: 'bottom',
            display: true,
            align: 'center',
            onClick: function (e, legendItem) {
                let index = legendItem.datasetIndex;
                let ci = this.chart;
                let meta = ci.getDatasetMeta(index);
                meta.hidden = meta.hidden === null ? !meta.hidden : null;
                ci.update();
            },
            onHover: function (e) {
                e.target.style.cursor = 'pointer';
            },
            labels: {
                padding: 10,
                usePointStyle: true,
                boxWidth: 5,
                filter: function (legendItem, data) {
                    return [0, 3, 4].includes(legendItem?.datasetIndex) && !data.datasets[legendItem?.datasetIndex].hidden;
                },
            },
        },
        hover: {
            onHover: function (e) {
                let point = this.getElementAtEvent(e);
                if (point.length) e.target.style.cursor = 'pointer';
                else e.target.style.cursor = 'default';
            },
        },
        scales: {
            yAxes: [
                {
                    scaleLabel: {
                        display: true,
                        labelString: intlMessages('label.optimalPower_kW'),
                        keyword: 'label.optimalPower_kW',
                    },
                    gridLines: {
                        drawOnChartArea: false,
                    },
                    ticks: {
                        suggestedMin: 0,
                        padding: 5,
                        fontSize: 10,
                        maxTicksLimit: 8,
                    },
                },
            ],
            xAxes: [
                {
                    scaleLabel: {
                        display: true,
                        labelString: intlMessages('label.hour'),
                        keyword: 'label.hour',
                    },
                    gridLines: {
                        drawOnChartArea: false,
                    },
                    ticks: {
                        padding: 5,
                        fontSize: 10,
                    },
                    scaleFontSize: 5,
                },
            ],
        },
        tooltips: {
            enabled: false,
        },
        plugins: {
            filler: {
                propagate: true,
            },
        },
    },
    plugins: [
        {
            beforeUpdate: (chart) => {
                let isHidden = !!chart.getDatasetMeta(0).hidden;
                chart.getDatasetMeta(1).hidden = isHidden;
                chart.getDatasetMeta(2).hidden = isHidden;
            },
            beforeInit: function (chart) {
                // Adds margin between legend and chart
                chart.legend.afterFit = function () {
                    this.height += 5; // must use `function` and not => because of `this`
                };
            },
        },
    ],
};

//Default map options
export const CHART_SOLAR_SENSIBILITY_DEFAULTS = {
    loadingProduction: true,
    loadingConsumption: true,
    type: 'Line',
    data: {
        labels: [],
        datasets: [
            {
                //[0] Points PV standalone
                id: 0,
                inputName: 'pointsStandalone',
                keyword: 'page.spvPro.label.solarPV',
                data: [],
                fill: false,
                pointRadius: 0,
                pointHoverRadius: 0,
                borderColor: 'rgb(72, 68, 71)',
                backgroundColor: 'rgb(72, 68, 71)',
                borderWidth: 2,
                label: intlMessages('page.spvPro.label.solarPV'),
            },
            {
                //[1] Points PV bundle
                id: 1,
                inputName: 'pointsBundle',
                keyword: 'page.spvPro.label.solarPVBattery',
                data: [],
                fill: false,
                pointRadius: 0,
                pointHoverRadius: 0,
                borderColor: 'rgb(62, 192, 155)',
                backgroundColor: 'rgb(62, 192, 155)',
                borderWidth: 2,
                label: intlMessages('page.spvPro.label.solarPVBattery'),
            },
            {
                //[2] sizedPeakPower
                id: 2,
                inputName: 'sizedPeakPower',
                keyword: 'label.sizedPeakPower',
                data: [],
                fill: false,
                pointRadius: 0,
                pointHoverRadius: 0,
                borderColor: 'rgba(208, 67, 75, 1)',
                backgroundColor: 'rgba(208, 67, 75, 1)',
                borderWidth: 2,
                label: intlMessages('label.sizedPeakPower'),
            },
            {
                //[3] minimumPayback
                id: 3,
                inputName: 'min_payback',
                keyword: 'label.minimumPayback',
                isService: false,
                data: [],
                fill: false,
                pointRadius: 6,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBackgroundColor: 'rgba(214, 194, 40, 0.5)',
                borderColor: 'rgba(214, 194, 40, 0.9)',
                backgroundColor: 'rgba(214, 194, 40, 0.9)',
                label: intlMessages('label.minimumPayback'),
            },
            {
                //[4] optimalDimension
                id: 4,
                inputName: 'max_cons_red_prc',
                keyword: 'label.optimalDimension',
                isService: false,
                data: [],
                fill: false,
                pointRadius: 6,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBackgroundColor: 'rgba(142, 180, 216, 0.5)',
                borderColor: 'rgba(142, 180, 216, 0.9)',
                backgroundColor: 'rgba(142, 180, 216, 0.9)',
                label: intlMessages('label.optimalDimension'),
            },
            {
                //[5] Poupanca max. cliente com payback < 7.5 anos
                id: 5,
                inputName: 'max_client_saving_below_lim_pb',
                keyword: 'label.max_client_saving_below_lim_pb',
                isService: false,
                data: [],
                fill: false,
                pointRadius: 6,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBackgroundColor: 'rgba(156, 194, 230, 0.5)',
                borderColor: 'rgba(156, 194, 230, 0.9)',
                backgroundColor: 'rgba(156, 194, 230, 0.9)',
                label: intlMessages('label.max_client_saving_below_lim_pb'),
            },
            {
                //[6]  TIR máx
                id: 6,
                inputName: 'max_irr',
                keyword: 'label.max_irr',
                isService: true,
                data: [],
                fill: false,
                pointRadius: 6,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBackgroundColor: 'rgba(159, 224, 206, 0.5)',
                borderColor: 'rgba(159, 224, 206, 0.9)',
                backgroundColor: 'rgba(159, 224, 206, 0.9)',
                label: intlMessages('label.max_irr'),
            },
            {
                //[7]  Poupança máx. cliente
                id: 7,
                inputName: 'max_client_saving',
                keyword: 'label.max_client_saving',
                isService: true,
                data: [],
                fill: false,
                pointRadius: 6,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBackgroundColor: 'rgba(156, 194, 230, 0.5)',
                borderColor: 'rgba(156, 194, 230, 0.9)',
                backgroundColor: 'rgba(156, 194, 230, 0.9)',
                label: intlMessages('label.max_client_saving'),
            },
            {
                //[8] minimumPayback (with battery)
                id: 8,
                inputName: 'min_payback_bundle',
                keyword: 'label.minimumPaybackBattery',
                isService: false,
                data: [],
                fill: false,
                pointRadius: 6,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBackgroundColor: 'rgba(214, 194, 40, 0.5)',
                borderColor: 'rgba(214, 194, 40, 0.9)',
                backgroundColor: 'rgba(214, 194, 40, 0.9)',
                label: intlMessages('label.minimumPaybackBattery'),
            },
            {
                //[9] optimalDimension
                id: 9,
                inputName: 'max_cons_red_prc_bundle',
                keyword: 'label.optimalDimensionBattery',
                isService: false,
                data: [],
                fill: false,
                pointRadius: 6,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBackgroundColor: 'rgba(142, 180, 216, 0.5)',
                borderColor: 'rgba(142, 180, 216, 0.9)',
                backgroundColor: 'rgba(142, 180, 216, 0.9)',
                label: intlMessages('label.optimalDimensionBattery'),
            },
            {
                //[10] Poupanca max. cliente com payback < 7.5 anos
                id: 10,
                inputName: 'max_client_saving_below_lim_pb_battery',
                keyword: 'label.max_client_saving_below_lim_pb_battery',
                isService: false,
                data: [],
                fill: false,
                pointRadius: 6,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBackgroundColor: 'rgba(156, 194, 230, 0.5)',
                borderColor: 'rgba(156, 194, 230, 0.9)',
                backgroundColor: 'rgba(156, 194, 230, 0.9)',
                label: intlMessages('label.max_client_saving_below_lim_pb_battery'),
            },
        ],
    },
    options: {
        elements: {
            line: {
                tension: 0,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            position: 'bottom',
            display: true,
            align: 'center',
            onClick: function (e, legendItem) {
                let datasetIndex = legendItem.datasetIndex;
                let ci = this.chart;

                // Retorno mínimo
                if ([3, 8].includes(datasetIndex)) {
                    [3, 8].forEach((idx) => {
                        let meta = ci.getDatasetMeta(idx);
                        meta.hidden = meta.hidden === null ? !meta.hidden : null;
                    });
                }

                // Poupança máx. cliente com retorno < 7.5 anos
                if ([5, 10].includes(datasetIndex)) {
                    [5, 10].forEach((idx) => {
                        /* points */
                        let meta = ci.getDatasetMeta(idx);
                        meta.hidden = meta.hidden === null ? !meta.hidden : null;
                    });
                }

                // optimalDimension
                if ([4, 9].includes(datasetIndex)) {
                    [4, 9].forEach((idx) => {
                        /* points */
                        let meta = ci.getDatasetMeta(idx);
                        meta.hidden = meta.hidden === null ? !meta.hidden : null;
                    });
                }

                // TIR máx
                if ([6].includes(datasetIndex)) {
                    [6].forEach((idx) => {
                        /* points */
                        let meta = ci.getDatasetMeta(idx);
                        meta.hidden = meta.hidden === null ? !meta.hidden : null;
                    });
                }

                // TIR máx
                if ([7].includes(datasetIndex)) {
                    [7].forEach((idx) => {
                        /* points */
                        let meta = ci.getDatasetMeta(idx);
                        meta.hidden = meta.hidden === null ? !meta.hidden : null;
                    });
                }

                //curve solarPV
                if (datasetIndex === 0) {
                    [0, 3, 4, 5, 6, 7].forEach((idx) => {
                        /* points */
                        let meta = ci.getDatasetMeta(idx);
                        meta.hidden = meta.hidden === null ? !meta.hidden : null;
                    });
                }
                //curve solarPV + battery
                if (datasetIndex === 1) {
                    [1, 8, 9, 10].forEach((idx) => {
                        /* points */
                        let meta = ci.getDatasetMeta(idx);
                        meta.hidden = meta.hidden === null ? !meta.hidden : null;
                    });
                }

                ci.update();
            },
            onHover: function (e) {
                e.target.style.cursor = 'pointer';
            },
            labels: {
                padding: 10,
                usePointStyle: true,
                boxWidth: 5,
                fontFamily: 'sans-serif',
                filter: function (item, chart) {
                    var currentDataValue = chart.datasets[item.datasetIndex].data;
                    if (
                        ![DATASET_INDEX_POINTS.MIN_PAYBACK_BUNDLE, DATASET_INDEX_POINTS.MAX_CLIENT_SAVING_BELOW_LIM_PB_BUNDLE].includes(
                            item.datasetIndex
                        ) &&
                        currentDataValue.length > 0
                    )
                        return true;
                    return false;
                },
            },
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        suggestedMin: 0,
                        // stepSize: 2,
                        padding: 5,
                        fontSize: 10,
                        maxTicksLimit: 7,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: intlMessages('label.payback_years'),
                        keyword: 'label.payback_years',
                    },
                    gridLines: {
                        drawOnChartArea: false,
                    },
                },
            ],
            xAxes: [
                {
                    type: 'linear',
                    ticks: {
                        suggestedMin: 0,
                        stepSize: 20,
                        padding: 5,
                        fontSize: 10,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: intlMessages('label.power_kWp'),
                        keyword: 'label.power_kWp',
                    },
                    gridLines: {
                        drawOnChartArea: false,
                    },
                },
            ],
        },
        tooltips: {
            filter: function (tooltipItem) {
                //Shows just a singl point on hover
                return [
                    // pv standalone
                    DATASET_INDEX_POINTS.MIN_PAYBACK,
                    DATASET_INDEX_POINTS.MAX_CONS_RED_PRC,
                    DATASET_INDEX_POINTS.MAX_CLIENT_SAVING_BELOW_LIM_PB,
                    DATASET_INDEX_POINTS.MAX_IRR,
                    DATASET_INDEX_POINTS.MAX_CLIENT_SAVING,
                    // pv bundle
                    DATASET_INDEX_POINTS.MIN_PAYBACK_BUNDLE,
                    DATASET_INDEX_POINTS.MAX_CONS_RED_PRC_BUNDLE,
                    DATASET_INDEX_POINTS.MAX_CLIENT_SAVING_BELOW_LIM_PB_BUNDLE,
                ].includes(tooltipItem.datasetIndex);
            },
            callbacks: {
                title: function (tooltipItem, data) {
                    if (tooltipItem.length === 1) {
                        // PV standalone
                        if (tooltipItem[0]?.datasetIndex === DATASET_INDEX_POINTS.MIN_PAYBACK) {
                            return intlMessages('label.minimumPayback');
                        }
                        if (tooltipItem[0]?.datasetIndex === DATASET_INDEX_POINTS.MAX_CONS_RED_PRC) {
                            return intlMessages('label.optimalDimension');
                        }
                        if (tooltipItem[0]?.datasetIndex === DATASET_INDEX_POINTS.MAX_CLIENT_SAVING_BELOW_LIM_PB) {
                            return intlMessages('label.max_client_saving_below_lim_pb');
                        }
                        if (tooltipItem[0]?.datasetIndex === DATASET_INDEX_POINTS.MAX_IRR) {
                            return intlMessages('label.max_irr', { companyName: data.companyName });
                        }
                        if (tooltipItem[0]?.datasetIndex === DATASET_INDEX_POINTS.MAX_CLIENT_SAVING) {
                            return intlMessages('label.max_client_saving');
                        }
                        // PV bundle
                        if (tooltipItem[0]?.datasetIndex === DATASET_INDEX_POINTS.MIN_PAYBACK_BUNDLE) {
                            return intlMessages('label.minimumPayback');
                        }
                        if (tooltipItem[0]?.datasetIndex === DATASET_INDEX_POINTS.MAX_CONS_RED_PRC_BUNDLE) {
                            return intlMessages('label.optimalDimension');
                        }
                        if (tooltipItem[0]?.datasetIndex === DATASET_INDEX_POINTS.MAX_CLIENT_SAVING_BELOW_LIM_PB_BUNDLE) {
                            return intlMessages('label.max_client_saving_below_lim_pb');
                        }
                    }
                    if (tooltipItem.length > 1) {
                        let title = null;
                        tooltipItem.map((item) => {
                            title =
                                title === null ?
                                    intlMessages(data?.datasets[item.datasetIndex].keyword)
                                :   `${title} \n` + intlMessages(data?.datasets[item.datasetIndex].keyword) + ` `;

                            return item;
                        });
                        return title;
                    }
                },
                label: function (tooltipItem, data) {
                    // pv standalone
                    if (tooltipItem.datasetIndex === DATASET_INDEX_POINTS.MIN_PAYBACK) {
                        //min_payback
                        return data.datasets[DATASET_INDEX_POINTS.MIN_PAYBACK].data[0].tooltip ?? [];
                    }
                    if (tooltipItem.datasetIndex === DATASET_INDEX_POINTS.MAX_CONS_RED_PRC) {
                        //max_cons_red_prc
                        return data.datasets[DATASET_INDEX_POINTS.MAX_CONS_RED_PRC].data[0].tooltip ?? [];
                    }
                    if (tooltipItem.datasetIndex === DATASET_INDEX_POINTS.MAX_CLIENT_SAVING_BELOW_LIM_PB) {
                        //max_client_saving_below_lim_pb
                        return data.datasets[DATASET_INDEX_POINTS.MAX_CLIENT_SAVING_BELOW_LIM_PB].data[0].tooltip ?? [];
                    }
                    if (tooltipItem.datasetIndex === DATASET_INDEX_POINTS.MAX_IRR) {
                        //max_irr
                        return data.datasets[DATASET_INDEX_POINTS.MAX_IRR].data[0].tooltip ?? [];
                    }
                    if (tooltipItem.datasetIndex === DATASET_INDEX_POINTS.MAX_CLIENT_SAVING) {
                        //max_client_saving
                        return data.datasets[DATASET_INDEX_POINTS.MAX_CLIENT_SAVING].data[0].tooltip ?? [];
                    }
                    // pv bundler
                    if (tooltipItem.datasetIndex === DATASET_INDEX_POINTS.MIN_PAYBACK_BUNDLE) {
                        //min_payback_battery
                        return data.datasets[DATASET_INDEX_POINTS.MIN_PAYBACK_BUNDLE].data[0].tooltip ?? [];
                    }
                    if (tooltipItem.datasetIndex === DATASET_INDEX_POINTS.MAX_CONS_RED_PRC_BUNDLE) {
                        //max_client_saving_below_lim_pb_battery
                        return data.datasets[DATASET_INDEX_POINTS.MAX_CONS_RED_PRC_BUNDLE].data[0].tooltip ?? [];
                    }
                    if (tooltipItem.datasetIndex === DATASET_INDEX_POINTS.MAX_CLIENT_SAVING_BELOW_LIM_PB_BUNDLE) {
                        //max_client_saving_below_lim_pb
                        return data.datasets[DATASET_INDEX_POINTS.MAX_CLIENT_SAVING_BELOW_LIM_PB_BUNDLE].data[0].tooltip ?? [];
                    }
                },
            },
            height: 100,
            width: '100%',
            borderWidth: 1,
            backgroundColor: '#fff',
            titleFontSize: 14,
            titleFontColor: 'rgb(72, 68, 71)',
            _titleAlign: 'center',
            bodyFontColor: '#000',
            bodyFontSize: 13,
            displayColors: false,
            borderColor: '#ddd',
            opacity: 1,
            zIndex: 50000,
        },
    },
    plugins: [
        {
            // Adds margin between legend and chart
            beforeInit: function (chart) {
                chart.legend.afterFit = function () {
                    this.height += 5; // must use `function` and not => because of `this`
                };
            },
        },
    ],
};

export const CHART_SOLAR_SENSIBILITY_HIDDEN_DEFAULTS = {
    loadingProduction: true,
    loadingConsumption: true,
    type: 'Line',
    data: {
        labels: [],
        datasets: [
            {
                //[0] Points PV standalone
                id: 0,
                inputName: 'pointsStandalone',
                keyword: 'page.spvPro.label.solarPV',
                data: [],
                fill: false,
                hidden: true,
                pointRadius: 0,
                pointHoverRadius: 0,
                borderColor: 'rgb(72, 68, 71)',
                backgroundColor: 'rgb(72, 68, 71)',
                borderWidth: 2,
                label: intlMessages('page.spvPro.label.solarPV'),
            },
            {
                //[1] Points PV bundle
                id: 1,
                inputName: 'pointsBundle',
                keyword: 'page.spvPro.label.solarPVBattery',
                data: [],
                fill: false,
                hidden: true,
                pointRadius: 0,
                pointHoverRadius: 0,
                borderColor: 'rgb(62, 192, 155)',
                backgroundColor: 'rgb(62, 192, 155)',
                borderWidth: 2,
                label: intlMessages('page.spvPro.label.solarPVBattery'),
            },
            {
                //[2] sizedPeakPower
                id: 2,
                inputName: 'sizedPeakPower',
                keyword: 'label.sizedPeakPower',
                data: [],
                fill: false,
                hidden: true,
                pointRadius: 0,
                pointHoverRadius: 0,
                borderColor: 'rgba(208, 67, 75, 1)',
                backgroundColor: 'rgba(208, 67, 75, 1)',
                borderWidth: 2,
                label: intlMessages('label.sizedPeakPower'),
            },
            {
                //[3] minimumPayback
                id: 3,
                inputName: 'min_payback',
                keyword: 'label.minimumPayback',
                isService: false,
                data: [],
                fill: false,
                hidden: true,
                pointRadius: 6,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBackgroundColor: 'rgba(214, 194, 40, 0.5)',
                borderColor: 'rgba(214, 194, 40, 0.9)',
                backgroundColor: 'rgba(214, 194, 40, 0.9)',
                label: intlMessages('label.minimumPayback'),
            },
            {
                //[4] optimalDimension
                id: 4,
                inputName: 'max_cons_red_prc',
                keyword: 'label.optimalDimension',
                isService: false,
                data: [],
                fill: false,
                hidden: true,
                pointRadius: 6,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBackgroundColor: 'rgba(142, 180, 216, 0.5)',
                borderColor: 'rgba(142, 180, 216, 0.9)',
                backgroundColor: 'rgba(142, 180, 216, 0.9)',
                label: intlMessages('label.optimalDimension'),
            },
            {
                //[5] Poupanca max. cliente com payback < 7.5 anos
                id: 5,
                inputName: 'max_client_saving_below_lim_pb',
                keyword: 'label.max_client_saving_below_lim_pb',
                isService: false,
                data: [],
                fill: false,
                hidden: true,
                pointRadius: 6,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBackgroundColor: 'rgba(156, 194, 230, 0.5)',
                borderColor: 'rgba(156, 194, 230, 0.9)',
                backgroundColor: 'rgba(156, 194, 230, 0.9)',
                label: intlMessages('label.max_client_saving_below_lim_pb'),
            },
            {
                //[6]  TIR máx
                id: 6,
                inputName: 'max_irr',
                keyword: 'label.max_irr',
                isService: true,
                data: [],
                fill: false,
                hidden: true,
                pointRadius: 6,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBackgroundColor: 'rgba(159, 224, 206, 0.5)',
                borderColor: 'rgba(159, 224, 206, 0.9)',
                backgroundColor: 'rgba(159, 224, 206, 0.9)',
                label: intlMessages('label.max_irr'),
            },
            {
                //[7]  Poupança máx. cliente
                id: 7,
                inputName: 'max_client_saving',
                keyword: 'label.max_client_saving',
                isService: true,
                data: [],
                fill: false,
                hidden: true,
                pointRadius: 6,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBackgroundColor: 'rgba(156, 194, 230, 0.5)',
                borderColor: 'rgba(156, 194, 230, 0.9)',
                backgroundColor: 'rgba(156, 194, 230, 0.9)',
                label: intlMessages('label.max_client_saving'),
            },
            {
                //[8] minimumPayback (with battery)
                id: 8,
                inputName: 'min_payback_bundle',
                keyword: 'label.minimumPaybackBattery',
                isService: false,
                data: [],
                fill: false,
                hidden: true,
                pointRadius: 6,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBackgroundColor: 'rgba(214, 194, 40, 0.5)',
                borderColor: 'rgba(214, 194, 40, 0.9)',
                backgroundColor: 'rgba(214, 194, 40, 0.9)',
                label: intlMessages('label.minimumPaybackBattery'),
            },
            {
                //[9] optimalDimension
                id: 9,
                inputName: 'max_cons_red_prc_bundle',
                keyword: 'label.optimalDimensionBattery',
                isService: false,
                data: [],
                fill: false,
                hidden: true,
                pointRadius: 6,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBackgroundColor: 'rgba(142, 180, 216, 0.5)',
                borderColor: 'rgba(142, 180, 216, 0.9)',
                backgroundColor: 'rgba(142, 180, 216, 0.9)',
                label: intlMessages('label.optimalDimensionBattery'),
            },
            {
                //[10] Poupanca max. cliente com payback < 7.5 anos
                id: 10,
                inputName: 'max_client_saving_below_lim_pb_battery',
                keyword: 'label.max_client_saving_below_lim_pb_battery',
                isService: false,
                data: [],
                fill: false,
                hidden: true,
                pointRadius: 6,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBackgroundColor: 'rgba(156, 194, 230, 0.5)',
                borderColor: 'rgba(156, 194, 230, 0.9)',
                backgroundColor: 'rgba(156, 194, 230, 0.9)',
                label: intlMessages('label.max_client_saving_below_lim_pb_battery'),
            },
        ],
    },
    options: {
        elements: {
            line: {
                tension: 0,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            position: 'bottom',
            display: true,
            align: 'center',
            onClick: function (e, legendItem) {
                let datasetIndex = legendItem.datasetIndex;
                let ci = this.chart;

                // Retorno mínimo
                if ([3, 8].includes(datasetIndex)) {
                    [3, 8].forEach((idx) => {
                        let meta = ci.getDatasetMeta(idx);
                        meta.hidden = meta.hidden === null ? !meta.hidden : null;
                    });
                }

                // Poupança máx. cliente com retorno < 7.5 anos
                if ([5, 10].includes(datasetIndex)) {
                    [5, 10].forEach((idx) => {
                        /* points */
                        let meta = ci.getDatasetMeta(idx);
                        meta.hidden = meta.hidden === null ? !meta.hidden : null;
                    });
                }

                // optimalDimension
                if ([4, 9].includes(datasetIndex)) {
                    [4, 9].forEach((idx) => {
                        /* points */
                        let meta = ci.getDatasetMeta(idx);
                        meta.hidden = meta.hidden === null ? !meta.hidden : null;
                    });
                }

                // TIR máx
                if ([6].includes(datasetIndex)) {
                    [6].forEach((idx) => {
                        /* points */
                        let meta = ci.getDatasetMeta(idx);
                        meta.hidden = meta.hidden === null ? !meta.hidden : null;
                    });
                }

                // TIR máx
                if ([7].includes(datasetIndex)) {
                    [7].forEach((idx) => {
                        /* points */
                        let meta = ci.getDatasetMeta(idx);
                        meta.hidden = meta.hidden === null ? !meta.hidden : null;
                    });
                }

                //curve solarPV
                if (datasetIndex === 0) {
                    [0, 3, 4, 5, 6, 7].forEach((idx) => {
                        /* points */
                        let meta = ci.getDatasetMeta(idx);
                        meta.hidden = meta.hidden === null ? !meta.hidden : null;
                    });
                }
                //curve solarPV + battery
                if (datasetIndex === 1) {
                    [1, 8, 9, 10].forEach((idx) => {
                        /* points */
                        let meta = ci.getDatasetMeta(idx);
                        meta.hidden = meta.hidden === null ? !meta.hidden : null;
                    });
                }

                ci.update();
            },
            onHover: function (e) {
                e.target.style.cursor = 'pointer';
            },
            labels: {
                padding: 10,
                usePointStyle: true,
                boxWidth: 5,
                fontFamily: 'sans-serif',
                filter: function (item, chart) {
                    var currentDataValue = chart.datasets[item.datasetIndex].data;
                    if (
                        ![DATASET_INDEX_POINTS.MIN_PAYBACK_BUNDLE, DATASET_INDEX_POINTS.MAX_CLIENT_SAVING_BELOW_LIM_PB_BUNDLE].includes(
                            item.datasetIndex
                        ) &&
                        currentDataValue.length > 0
                    )
                        return true;
                    return false;
                },
            },
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        suggestedMin: 0,
                        // stepSize: 2,
                        padding: 5,
                        fontSize: 10,
                        maxTicksLimit: 7,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: intlMessages('label.payback_years'),
                        keyword: 'label.payback_years',
                    },
                    gridLines: {
                        drawOnChartArea: false,
                    },
                },
            ],
            xAxes: [
                {
                    type: 'linear',
                    ticks: {
                        suggestedMin: 0,
                        stepSize: 20,
                        padding: 5,
                        fontSize: 10,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: intlMessages('label.power_kWp'),
                        keyword: 'label.power_kWp',
                    },
                    gridLines: {
                        drawOnChartArea: false,
                    },
                },
            ],
        },
        tooltips: {
            filter: function (tooltipItem) {
                //Shows just a singl point on hover
                return [
                    // pv standalone
                    DATASET_INDEX_POINTS.MIN_PAYBACK,
                    DATASET_INDEX_POINTS.MAX_CONS_RED_PRC,
                    DATASET_INDEX_POINTS.MAX_CLIENT_SAVING_BELOW_LIM_PB,
                    DATASET_INDEX_POINTS.MAX_IRR,
                    DATASET_INDEX_POINTS.MAX_CLIENT_SAVING,
                    // pv bundle
                    DATASET_INDEX_POINTS.MIN_PAYBACK_BUNDLE,
                    DATASET_INDEX_POINTS.MAX_CONS_RED_PRC_BUNDLE,
                    DATASET_INDEX_POINTS.MAX_CLIENT_SAVING_BELOW_LIM_PB_BUNDLE,
                ].includes(tooltipItem.datasetIndex);
            },
            callbacks: {
                title: function (tooltipItem, data) {
                    if (tooltipItem.length === 1) {
                        // PV standalone
                        if (tooltipItem[0]?.datasetIndex === DATASET_INDEX_POINTS.MIN_PAYBACK) {
                            return intlMessages('label.minimumPayback');
                        }
                        if (tooltipItem[0]?.datasetIndex === DATASET_INDEX_POINTS.MAX_CONS_RED_PRC) {
                            return intlMessages('label.optimalDimension');
                        }
                        if (tooltipItem[0]?.datasetIndex === DATASET_INDEX_POINTS.MAX_CLIENT_SAVING_BELOW_LIM_PB) {
                            return intlMessages('label.max_client_saving_below_lim_pb');
                        }
                        if (tooltipItem[0]?.datasetIndex === DATASET_INDEX_POINTS.MAX_IRR) {
                            return intlMessages('label.max_irr', { companyName: data.companyName });
                        }
                        if (tooltipItem[0]?.datasetIndex === DATASET_INDEX_POINTS.MAX_CLIENT_SAVING) {
                            return intlMessages('label.max_client_saving');
                        }
                        // PV bundle
                        if (tooltipItem[0]?.datasetIndex === DATASET_INDEX_POINTS.MIN_PAYBACK_BUNDLE) {
                            return intlMessages('label.minimumPayback');
                        }
                        if (tooltipItem[0]?.datasetIndex === DATASET_INDEX_POINTS.MAX_CONS_RED_PRC_BUNDLE) {
                            return intlMessages('label.optimalDimension');
                        }
                        if (tooltipItem[0]?.datasetIndex === DATASET_INDEX_POINTS.MAX_CLIENT_SAVING_BELOW_LIM_PB_BUNDLE) {
                            return intlMessages('label.max_client_saving_below_lim_pb');
                        }
                    }
                    if (tooltipItem.length > 1) {
                        let title = null;
                        tooltipItem.map((item) => {
                            title =
                                title === null ?
                                    intlMessages(data?.datasets[item.datasetIndex].keyword)
                                :   `${title} \n` + intlMessages(data?.datasets[item.datasetIndex].keyword) + ` `;

                            return item;
                        });
                        return title;
                    }
                },
                label: function (tooltipItem, data) {
                    // pv standalone
                    if (tooltipItem.datasetIndex === DATASET_INDEX_POINTS.MIN_PAYBACK) {
                        //min_payback
                        return data.datasets[DATASET_INDEX_POINTS.MIN_PAYBACK].data[0].tooltip ?? [];
                    }
                    if (tooltipItem.datasetIndex === DATASET_INDEX_POINTS.MAX_CONS_RED_PRC) {
                        //max_cons_red_prc
                        return data.datasets[DATASET_INDEX_POINTS.MAX_CONS_RED_PRC].data[0].tooltip ?? [];
                    }
                    if (tooltipItem.datasetIndex === DATASET_INDEX_POINTS.MAX_CLIENT_SAVING_BELOW_LIM_PB) {
                        //max_client_saving_below_lim_pb
                        return data.datasets[DATASET_INDEX_POINTS.MAX_CLIENT_SAVING_BELOW_LIM_PB].data[0].tooltip ?? [];
                    }
                    if (tooltipItem.datasetIndex === DATASET_INDEX_POINTS.MAX_IRR) {
                        //max_irr
                        return data.datasets[DATASET_INDEX_POINTS.MAX_IRR].data[0].tooltip ?? [];
                    }
                    if (tooltipItem.datasetIndex === DATASET_INDEX_POINTS.MAX_CLIENT_SAVING) {
                        //max_client_saving
                        return data.datasets[DATASET_INDEX_POINTS.MAX_CLIENT_SAVING].data[0].tooltip ?? [];
                    }
                    // pv bundler
                    if (tooltipItem.datasetIndex === DATASET_INDEX_POINTS.MIN_PAYBACK_BUNDLE) {
                        //min_payback_battery
                        return data.datasets[DATASET_INDEX_POINTS.MIN_PAYBACK_BUNDLE].data[0].tooltip ?? [];
                    }
                    if (tooltipItem.datasetIndex === DATASET_INDEX_POINTS.MAX_CONS_RED_PRC_BUNDLE) {
                        //max_client_saving_below_lim_pb_battery
                        return data.datasets[DATASET_INDEX_POINTS.MAX_CONS_RED_PRC_BUNDLE].data[0].tooltip ?? [];
                    }
                    if (tooltipItem.datasetIndex === DATASET_INDEX_POINTS.MAX_CLIENT_SAVING_BELOW_LIM_PB_BUNDLE) {
                        //max_client_saving_below_lim_pb
                        return data.datasets[DATASET_INDEX_POINTS.MAX_CLIENT_SAVING_BELOW_LIM_PB_BUNDLE].data[0].tooltip ?? [];
                    }
                },
            },
            height: 100,
            width: '100%',
            borderWidth: 1,
            backgroundColor: '#fff',
            titleFontSize: 14,
            titleFontColor: 'rgb(72, 68, 71)',
            _titleAlign: 'center',
            bodyFontColor: '#000',
            bodyFontSize: 13,
            displayColors: false,
            borderColor: '#ddd',
            opacity: 1,
            zIndex: 50000,
        },
    },
    plugins: [
        {
            // Adds margin between legend and chart
            beforeInit: function (chart) {
                chart.legend.afterFit = function () {
                    this.height += 5; // must use `function` and not => because of `this`
                };
            },
        },
    ],
};

export const CHART_INPUTS_DEFAULTS = {
    days_type: 'work_days',
    month: new Date().toLocaleString('en', { month: 'short' }).toLowerCase(),
};

export const CHART_QUESTIONS_DEFAULTS = [
    {
        name: 'days_type',
        label: 'label.weekday',
        disabled: false,
        answers: [
            {
                label: 'label.workDays',
                value: 'work_days',
            },
            {
                label: 'label.saturdays',
                value: 'saturdays',
            },
            {
                label: 'label.sundays',
                value: 'sundays',
            },
        ],
        type: 'select',
        options: {
            emptySelectedOption: 'component.select.simple',
            translateValues: true,
            notTranslateLabel: false,
        },
    },
    {
        name: 'month',
        label: 'label.months',
        disabled: false,
        answers: [
            {
                label: 'label.jan',
                value: 'jan',
            },
            {
                label: 'label.feb',
                value: 'feb',
            },
            {
                label: 'label.mar',
                value: 'mar',
            },
            {
                label: 'label.apr',
                value: 'apr',
            },
            {
                label: 'label.may',
                value: 'may',
            },
            {
                label: 'label.jun',
                value: 'jun',
            },
            {
                label: 'label.jul',
                value: 'jul',
            },
            {
                label: 'label.aug',
                value: 'aug',
            },
            {
                label: 'label.sep',
                value: 'sep',
            },
            {
                label: 'label.oct',
                value: 'oct',
            },
            {
                label: 'label.nov',
                value: 'nov',
            },
            {
                label: 'label.dec',
                value: 'dec',
            },
        ],
        type: 'select',
        options: {
            emptySelectedOption: 'component.select.simple',
            translateValues: true,
            notTranslateLabel: false,
        },
    },
];

export const REMOTE_TABS = {
    FACILITY: 'facility',
    NEIGHBORS: 'neighbors',
    ENTERPRISES: 'enterprises',
};

export const REMOTE_TABS_CONFIGS = [
    {
        name: REMOTE_TABS.FACILITY,
        label: 'page.spvPro.remote.dialog.tab.facility',
        tooltip: null,
    },
    {
        name: REMOTE_TABS.NEIGHBORS,
        label: 'page.spvPro.remote.dialog.tab.neighbors',
        tooltip: 'page.spvPro.remote.dialog.tab.neighbors.tooltip',
    },
    {
        name: REMOTE_TABS.ENTERPRISES,
        label: 'page.spvPro.remote.dialog.tab.enterprises',
        tooltip: 'page.spvPro.remote.dialog.tab.enterprises.tooltip',
    },
];

export const REMOTE_TABS_INPUTS = {
    // first level
    COEFFICIENT: 'coefficient',
    NEIGHBORS: 'neighbors',
    ENTERPRISES: 'enterprises',
    // second level
    DESCRIPTION: 'description',
    ACTIVITY_SECTOR: 'activity_sector_code',
    ANNUAL_CONSUMPTION: 'annual_consumption_mwh',
};

export const REMOTE_TABS_QUESTIONS = {
    [REMOTE_TABS_INPUTS.COEFFICIENT]: {
        label: 'label.coefficient',
        required: true,
        min: 0,
        max: 100,
        placeholder: 'label.insertValue',
        unit: 'percentage',
    },
    [REMOTE_TABS_INPUTS.DESCRIPTION]: {
        label: 'label.companyDescription',
        required: true,
        placeholder: 'label.insertValue',
        unit: null,
    },
    [REMOTE_TABS_INPUTS.ACTIVITY_SECTOR]: {
        label: 'label.activitySector',
        required: true,
        placeholder: 'label.insertValue',
        unit: null,
    },
    [REMOTE_TABS_INPUTS.ANNUAL_CONSUMPTION]: {
        label: 'label.annual_consumption',
        required: true,
        placeholder: 'label.insertValue',
        unit: 'mwh',
    },
};

export const RemoteActions = {
    SET_INIT: 'set_init',
    OPEN_DIALOG: 'open_dialog',
    CLOSE_DIALOG: 'close_dialog',
    SELECT_TAB: 'select_tab',
    SET_SELECTED_ENTRY: 'set_selected_entry',
    SET_COEFFICIENT_SUM: 'set_coefficient_sum',
    SUBMIT_FORM: 'submit_form',
    DELETE_ENTRY: 'delete_entry',
    DELETE_ALL: 'delete_all',
};

export const REMOTE_MENU_ACTIONS = {
    ADD: 'ADD',
    EDIT: 'EDIT',
    DELETE_ALL: 'DELETE_ALL',
};

export const REMOTE_MENU_ACTIONS_CONFIG = {
    [REMOTE_MENU_ACTIONS.ADD]: {
        name: REMOTE_MENU_ACTIONS.ADD,
        className: 'add',
        visible: true,
        disabled: false,
        icon: <PlusIcon className="fill stroke" />,
        label: 'label.addFacility',
        value: 'label.facility',
    },
    [REMOTE_MENU_ACTIONS.EDIT]: {
        name: REMOTE_MENU_ACTIONS.EDIT,
        className: 'edit',
        visible: true,
        disabled: false,
        icon: <EditIcon className="fill stroke" />,
        label: 'label.editFacilities',
        value: null,
    },
    [REMOTE_MENU_ACTIONS.DELETE_ALL]: {
        name: REMOTE_MENU_ACTIONS.DELETE_ALL,
        className: 'delete',
        visible: true,
        disabled: false,
        icon: <DeleteIcon className="fill stroke" />,
        label: 'label.deleteAll',
        value: null,
    },
};

export const SPV_RESPONSE_TAGS_SUMMARY = [
    'NOT_ENOUGH_DATA_DUE_TO_CONTRACTED_POWER',
    'MISSING_TARIFF',
    'MISSING_INVESTMENT',
    'MISSING_RADIATION',
    'MISSING_REGIONS',
    'TOTAL_PANELS_NUMBER_EXCEED_LIMIT', // Max or min panels limits exceeded
    'TOTAL_PANELS_NUMBER_EXCEED_MAX_LIMIT',
    'TOTAL_PANELS_NUMBER_EXCEED_MIN_LIMIT',
    'INVERTER_POWER_EXCEEDED_CONTRACTED_POWER',
    'MISSING_OR_INVALID_REGION_ID',
    'MISSING_OR_INVALID_COUNTRY_ID',
    'MISSING_CONSUMPTION', // Gráficos
    'MISSING_PRODUCTION', // Gráficos
    'MISSING_OR_INVALID_AREA_INFO',
    'MISSING_MOUNTING_STRUCTURES_COSTS',
    'BREAKER_RATING_EXCEEDED_MCB_RATING',
    '500', //generic status error
];

// Injection Panel visibility
export const IS_INJECTION_PANEL_VISIBLE = {
    [getCompanyProfileIds().EDP_BR]: false,
};

// TYPE ACTION

export const INITIAL_STATE_INPUTS_DATA = {
    areas: [],
    coordinates_avg: null,
    range_id: null,
};

export const DISPATCH_EVT = {
    UPDATE: 'UPDATE',
    MOUNTING: 'MOUNTING',
    SET_SELECT_GROUP: 'SET_SELECT_GROUP',
    SET_CENTER_GROUP: 'SET_CENTER_GROUP',
    SET_DESELECT_ALL: 'SET_DESELECT_ALL',
    SET_MOUNTING_STRUCTURE_ID: 'SET_MOUNTING_STRUCTURE_ID',
    SET_SLOPES: 'SET_SLOPES',
    SET_ORIENTATION: 'SET_ORIENTATION',
    EDIT_ORIENTATION: 'EDIT_ORIENTATION',
    SET_PANEL_REPRESENTATION: 'SET_PANEL_REPRESENTATION',
    SET_ROW_SPACE: 'SET_ROW_SPACE',
    SET_NETWORK_SALE: 'SET_NETWORK_SALE',
    SET_INJECTION_TARIFF: 'SET_INJECTION_TARIFF',
    SET_REMUNERATION_MODEL: 'SET_REMUNERATION_MODEL',
    DELETE_ALL_PANELS: 'DELETE_ALL_PANELS',
    NEW_GROUP: 'NEW_GROUP',
    NEW_GROUP_V3: 'NEW_GROUP_V3',
    DUPLICATE_GROUP: 'DUPLICATE_GROUP',
    SET_RANGE_ID: 'SET_RANGE_ID',
    SET_MAP_DRAGEND: 'SET_MAP_DRAGEND',
    SET_MAP_SET_AT: 'SET_MAP_SET_AT',
    SET_MAP_INSERT_AT: 'SET_MAP_INSERT_AT',
    SET_MAP_REMOVE_AT: 'SET_MAP_REMOVE_AT',
    NEXT_BUSINESS_MODELS: 'NEXT_BUSINESS_MODELS' /* TODO: REMOVER */,
    SET_BATTERY: 'SET_BATTERY',
    DELETE_BATTERY: 'DELETE_BATTERY',
    SET_INVERTERS: 'SET_INVERTERS',
    SET_PRODUCTION_LOSSES: 'SET_PRODUCTION_LOSSES',
    DELETE_GROUP: 'DELETE_GROUP',
    SHOW_PANELS: 'SHOW_PANELS',
    SHOW_EXCLUSIONS: 'SHOW_EXCLUSIONS',
    MAP_SELECT_EXCLUSION_AREA: 'MAP_SELECT_EXCLUSION_AREA',
    MAP_DUPLICATE_EXCLUSION_AREA: 'MAP_DUPLICATE_EXCLUSION_AREA',
    EXCLUSION_MAP_POLYGONCOMPLETE: 'EXCLUSION_MAP_POLYGONCOMPLETE',
    ALIGNMENT: 'ALIGNMENT',
    SET_RANGE_PANEL_ID: 'SET_RANGE_PANEL_ID',
    SET_FACILITIES_REMOTE_SC: 'SET_FACILITIES_REMOTE_SC',
    DELETE_ALL_FACILITIES_REMOTE_SC: 'DELETE_ALL_FACILITIES_REMOTE_SC',
    SET_AREA_REDUCTION_ELEMENTS: 'SET_AREA_REDUCTION_ELEMENTS',
    KEYCODE_DELETE_SELECTED_POLYGON: 'KEYCODE_DELETE_SELECTED_POLYGON',
    MAX_SIZE: 'MAX_SIZE',
    OPTIMAZER: 'OPTIMAZER',
    UPDATE_EXCLUSION_SELECTION: 'UPDATE_EXCLUSION_SELECTION',
    SET_RANGES: 'SET_RANGES',
    SPLIT_POLYGON: 'SPLIT_POLYGON',
    SPLITTING: 'SPLITTING',
    SPLIT_POLYLINE_COMPLETE: 'SPLIT_POLYLINE_COMPLETE',
    TOGGLE_AREAS_VISIBILITY: 'TOGGLE_AREAS_VISIBILITY',
    AUTO_UPDATE_KPIS: 'AUTO_UPDATE_KPIS',
};

export const COMPANIES_THAT_CAN_EDIT_INJECTION_TARIFF = [getCompanyProfileIds().EDP_PL];

export const PANEL_FRAMING = {
    //painel_layout
    LANDSCAPE: 1,
    PORTRAIT: 2,
};

// START REGION -> SYSTEM SETTINGS

export const SYSTEM_SETTINGS_SECTIONS = {
    REMUNERATION: 'remuneration',
    INJECTION: 'injection',
    COMPOSITION: 'composition',
};

export const SYSTEM_SETTINGS_SECTIONS_CONFIG = {
    [getCompanyProfileIds().EDP_BR]: {
        [GRUPO_CANAL_IDS.DEFAULT]: {
            [SYSTEM_SETTINGS_SECTIONS.INJECTION]: {
                visible: false,
                label: 'label.injection',
            },
            [SYSTEM_SETTINGS_SECTIONS.COMPOSITION]: {
                visible: true,
                label: 'label.systemComposition',
            },
            [SYSTEM_SETTINGS_SECTIONS.REMUNERATION]: {
                visible: false,
                label: 'label.remunerationModel',
            },
        },
        [GRUPO_CANAL_IDS.SPV_TEST_BR]: {
            [SYSTEM_SETTINGS_SECTIONS.INJECTION]: {
                visible: false,
                label: 'label.injection',
            },
            [SYSTEM_SETTINGS_SECTIONS.COMPOSITION]: {
                visible: true,
                label: 'label.systemComposition',
            },
            [SYSTEM_SETTINGS_SECTIONS.REMUNERATION]: {
                visible: false,
                label: 'label.remunerationModel',
            },
        },
    },
    DEFAULT: {
        [SYSTEM_SETTINGS_SECTIONS.INJECTION]: {
            visible: true,
            label: 'label.injection',
        },
        [SYSTEM_SETTINGS_SECTIONS.COMPOSITION]: {
            visible: true,
            label: 'label.systemComposition',
        },
        [SYSTEM_SETTINGS_SECTIONS.REMUNERATION]: {
            visible: true,
            label: 'label.remunerationModel',
        },
    },
};

export const SYSTEM_SETTINGS_INJECTION = {
    NETWORK_SALE: 'sellToGrid',
    NETWORK_SALE_LIMIT: 'sellToGridLimit',
    NETWORK_SALE_LIMIT_VALUE: 'networkSaleLimitValue',
    INJECTION_TARIFF: 'injectionTariff',
    REMUNERATION_MODEL: 'remunerationModel',
};

export const SYSTEM_SETTINGS_INJECTION_CONFIG = {
    [getCompanyProfileIds().PPC]: {
        [SYSTEM_SETTINGS_INJECTION.NETWORK_SALE]: {
            visible: false,
            label: () => 'label.netWorkSale',
            disabled: false,
        },
        [SYSTEM_SETTINGS_INJECTION.INJECTION_TARIFF]: {
            visible: false,
            label: (isFeedInTariif) => (isFeedInTariif ? 'bo.menu.solar.tariff' : 'bo.menu.solar.injectionTariff'),
            editable: false,
            styleFormat: 'decimal',
            unit: 'ypw',
            numberOfDecimalPlaces: 3,
        },
        [SYSTEM_SETTINGS_INJECTION.REMUNERATION_MODEL]: {
            visible: true,
            label: () => 'label.remunerationModel',
            disabled: false,
        },
    },
    DEFAULT: {
        [SYSTEM_SETTINGS_INJECTION.NETWORK_SALE]: {
            visible: true,
            label: () => 'label.netWorkSale',
            disabled: false,
        },
        [SYSTEM_SETTINGS_INJECTION.INJECTION_TARIFF]: {
            visible: true,
            label: (isFeedInTariif) => (isFeedInTariif ? 'bo.menu.solar.tariff' : 'bo.menu.solar.injectionTariff'),
            editable: false,
            styleFormat: 'decimal',
            unit: 'ypw',
            numberOfDecimalPlaces: 3,
        },
        [SYSTEM_SETTINGS_INJECTION.REMUNERATION_MODEL]: {
            visible: false,
            label: () => 'label.remunerationModel',
            disabled: false,
        },
    },
};
export const SYSTEM_SETTINGS_INJECTION_CONFIG_B2C = {
    DEFAULT: {
        [SYSTEM_SETTINGS_INJECTION.NETWORK_SALE]: {
            visible: true,
            label: () => 'label.netWorkSale',
            disabled: false,
        },
        [SYSTEM_SETTINGS_INJECTION.INJECTION_TARIFF]: {
            visible: true,
            label: (isFeedInTariif) => (isFeedInTariif ? 'bo.menu.solar.tariff' : 'bo.menu.solar.injectionTariff'),
            editable: false,
            styleFormat: 'decimal',
            unit: 'ypw',
            numberOfDecimalPlaces: 3,
        },
        [SYSTEM_SETTINGS_INJECTION.REMUNERATION_MODEL]: {
            visible: true,
            label: () => 'label.remunerationModel',
            disabled: false,
        },
    },
};

export const SYSTEM_SETTINGS_COMPOSITION = {
    SYSTEM_SIZE: 'systemSize',
    SYSTEM_LIMITS: 'systemLimits',
};

export const SYSTEM_SETTINGS_COMPOSITION_CONFIG = {
    DEFAULT: {
        [SYSTEM_SETTINGS_COMPOSITION.SYSTEM_SIZE]: {
            visible: true,
            label: 'page.spvPro.label.systemSize',
            totalUnit: 'sm',
            styleFormat: 'decimal',
            numberOfDecimalPlaces: 0,
        },
        [SYSTEM_SETTINGS_COMPOSITION.SYSTEM_LIMITS]: {
            visible: true,
            label: 'page.spvPro.label.systemLimits',
        },
    },
};

export const SYSTEM_SETTINGS_TABS = {
    EQUIPMENT: 'equipment',
    ENVIRONMENT: 'environment',
};

export const SYSTEM_SETTINGS_TABS_CONFIG = {
    DEFAULT: {
        [SYSTEM_SETTINGS_TABS.EQUIPMENT]: {
            visible: true,
            value: SYSTEM_SETTINGS_TABS.EQUIPMENT,
            disabled: false,
            label: 'label.equipment',
        },
        [SYSTEM_SETTINGS_TABS.ENVIRONMENT]: {
            visible: true,
            value: SYSTEM_SETTINGS_TABS.ENVIRONMENT,
            disabled: false,
            label: 'label.environment',
        },
    },
};

export const SYSTEM_SETTINGS_GROUPS = {
    PANELS: 'panels',
    REMOTE: 'remote',
    BATTERY: 'battery',
    EXCLUSIONS: 'exclusions',
    BUILDINGS: 'buildings',
};

export const SYSTEM_SETTINGS_GROUPS_CONFIG = {
    DEFAULT: {
        [SYSTEM_SETTINGS_GROUPS.PANELS]: {
            visible: true,
            tab: SYSTEM_SETTINGS_TABS.EQUIPMENT,
            value: SYSTEM_SETTINGS_GROUPS.PANELS,
            disabled: false,
            label: 'label.panels',
        },
        [SYSTEM_SETTINGS_GROUPS.REMOTE]: {
            visible: false,
            tab: SYSTEM_SETTINGS_TABS.EQUIPMENT,
            value: SYSTEM_SETTINGS_GROUPS.REMOTE,
            disabled: false,
            label: 'label.remote',
        },
        [SYSTEM_SETTINGS_GROUPS.BATTERY]: {
            visible: false,
            tab: SYSTEM_SETTINGS_TABS.EQUIPMENT,
            value: SYSTEM_SETTINGS_GROUPS.BATTERY,
            disabled: false,
            label: 'label.battery',
        },
        [SYSTEM_SETTINGS_GROUPS.EXCLUSIONS]: {
            visible: true,
            tab: SYSTEM_SETTINGS_TABS.ENVIRONMENT,
            value: SYSTEM_SETTINGS_GROUPS.EXCLUSIONS,
            disabled: false,
            label: 'label.exclusions',
        },
        [SYSTEM_SETTINGS_GROUPS.BUILDINGS]: {
            visible: false,
            tab: SYSTEM_SETTINGS_TABS.ENVIRONMENT,
            value: SYSTEM_SETTINGS_GROUPS.BUILDINGS,
            disabled: false,
            label: 'label.buildings',
        },
    },
};

export const GROUP_ACTIONS_NAMES = {
    NEW_GROUP: 'newGroup',
    DELETE_ALL: 'deleteAll',
};

export const GROUP_ACTIONS_CONFIG = {
    DEFAULT: {
        [GROUP_ACTIONS_NAMES.NEW_GROUP]: {
            visible: true,
            disabled: false,
            label: 'label.newGroup',
        },
        [GROUP_ACTIONS_NAMES.DELETE_ALL]: {
            visible: true,
            disabled: false,
            label: 'label.deleteAll',
        },
    },
};
//#endregion

export const PANEL_GROUP_NAMES = {
    AREA: 'area',
    PANEL_NUMBER: 'panelNumber',
    STRUCTURE: 'structure',
    SLOPE: 'slope',
    ORIENTATION: 'orientation',
    REPRESENTATION: 'representation',
    PEAK_POWER: 'peakPower',
    SIZE: 'size',
};

export const PANEL_GROUP_CONFIG = {
    DEFAULT: {
        [PANEL_GROUP_NAMES.AREA]: {
            visible: true,
            unit: 'sm',
            styleFormat: 'decimal',
            numberOfDecimalPlaces: 0,
            label: 'label.area',
        },
        [PANEL_GROUP_NAMES.PEAK_POWER]: {
            visible: true,
            label: 'label.peakPower',
            styleFormat: 'decimal',
            numberOfDecimalPlaces: 2,
            unit: 'kwp',
        },
        [PANEL_GROUP_NAMES.PANEL_NUMBER]: {
            visible: true,
            label: 'page.spvPro.label.numberOfPanels',
            tooltip: {
                info: {
                    label1: 'page.spvPro.tooltip.nextSetPanels',
                    label2: 'page.spvPro.tooltip.panelsToCompleteSet',
                    label3: 'page.spvPro.tooltip.enlargeArea',
                },
                error: {
                    label: 'label.panels.tooltip',
                },
            },
        },
        [PANEL_GROUP_NAMES.STRUCTURE]: {
            allowEdit: true,
            visible: true,
            label: 'label.structure',
        },
        [PANEL_GROUP_NAMES.SIZE]: {
            visible: true,
            label: 'label.size',
        },
        [PANEL_GROUP_NAMES.SLOPE]: {
            allowEdit: true,
            visible: true,
            roofPanelLabel: 'page.spvPro.label.roofPanelSlope',
            roofLabel: 'page.spvPro.label.roofSlope',
            panelLabel: 'page.spvPro.label.panelsSlope',
        },
        [PANEL_GROUP_NAMES.ORIENTATION]: {
            allowEdit: true,
            visible: true,
            label: 'page.spvPro.label.panelsOrientation',
        },
        [PANEL_GROUP_NAMES.REPRESENTATION]: {
            allowEdit: true,
            visible: true,
            label: 'page.spv.panelRepresentation',
            props: {
                rowDistance: {
                    label: 'page.spv.panelRepresentation.distanceBtwRows',
                    unit: 'm',
                    numberOfDecimalPlaces: 2,
                },
                setback: {
                    label: 'page.spv.panelRepresentation.setback',
                    unit: 'm',
                    numberOfDecimalPlaces: 2,
                },
            },
        },
    },
};

export const EXCLUSIONS_NAMES = {
    NEW_EXCLUSION: 'newExclusion',
    DELETE_EXCLUSION: 'deleteExclusion',
    DELETE_ALL_EXCLUSIONS: 'deleteAllExclusions',
    APPLY_EXCLUSIONS_CHANGES: 'applyExclusionsChanges',
};

export const EXCLUSIONS_CONFIG = {
    DEFAULT: {
        [EXCLUSIONS_NAMES.NEW_EXCLUSION]: {
            visible: true,
            label: 'page.spvPro.label.new',
        },
        [EXCLUSIONS_NAMES.DELETE_EXCLUSION]: {
            visible: true,
        },
        [EXCLUSIONS_NAMES.DELETE_ALL_EXCLUSIONS]: {
            visible: true,
            label: 'label.deleteAll',
        },
        [EXCLUSIONS_NAMES.APPLY_EXCLUSIONS_CHANGES]: {
            visible: true,
            label: 'page.spvPro.label.applyExclusions',
        },
    },
};

export const EXCLUSIONS_MAP_DIMENSION = 640;
export const IMG_CONTRACT_MAP_DIMENSION = 640;

export const MESH_TYPES = {
    POLYGON: 1,
    EXCLUSION: 2,
    BUILDING: 3,
};

export const BUILDINGS_NAMES = {
    NEW_BUILDING: 'newBuilding',
    DELETE_ALL_BUILDINGS: 'deleteAllBuildings',
};

export const BUILDINGS_CONFIG = {
    DEFAULT: {
        [BUILDINGS_NAMES.NEW_BUILDING]: {
            visible: true,
            label: 'page.spvPro.label.new',
        },
        [BUILDINGS_NAMES.DELETE_ALL_BUILDINGS]: {
            visible: true,
            label: 'label.deleteAll',
        },
    },
};

export const PANEL_ORIENTATION_NAMES = {
    ORIENTATION_GRAPH: 'orientationGraph',
    ORIENTATION_INPUT: 'orientationInput',
    ORIENTATION_BUTTONS: 'orientationButtons',
    DECIMAL_ORIENTATION_LEGEND: 'orientationLegend',
};

export const PANEL_ORIENTATION_CONFIG = {
    [getCompanyProfileIds().EFZ]: {
        [GRUPO_CANAL_IDS.DEFAULT]: {
            step: 10,
            [PANEL_ORIENTATION_NAMES.ORIENTATION_GRAPH]: {
                visible: true,
            },
            [PANEL_ORIENTATION_NAMES.ORIENTATION_BUTTONS]: {
                visible: true,
            },
            [PANEL_ORIENTATION_NAMES.ORIENTATION_INPUT]: {
                visible: true,
                disabled: true,
            },
            [PANEL_ORIENTATION_NAMES.ORIENTATION_LEGEND]: {
                visible: true,
                label: 'page.spvPro.label.orientationLegend',
            },
        },
    },
    [getCompanyProfileIds().EDP_PT]: {
        [GRUPO_CANAL_IDS.DEFAULT]: {
            step: 5,
            [PANEL_ORIENTATION_NAMES.ORIENTATION_GRAPH]: {
                visible: true,
            },
            [PANEL_ORIENTATION_NAMES.ORIENTATION_BUTTONS]: {
                visible: true,
            },
            [PANEL_ORIENTATION_NAMES.ORIENTATION_INPUT]: {
                visible: true,
                disabled: true,
            },
            [PANEL_ORIENTATION_NAMES.ORIENTATION_LEGEND]: {
                visible: true,
                label: 'page.spvPro.label.orientationLegend',
            },
        },
        [GRUPO_CANAL_IDS.SPV_TEST]: {
            step: 10,
            [PANEL_ORIENTATION_NAMES.ORIENTATION_GRAPH]: {
                visible: true,
            },
            [PANEL_ORIENTATION_NAMES.ORIENTATION_BUTTONS]: {
                visible: true,
            },
            [PANEL_ORIENTATION_NAMES.ORIENTATION_INPUT]: {
                visible: true,
                disabled: true,
            },
            [PANEL_ORIENTATION_NAMES.ORIENTATION_LEGEND]: {
                visible: true,
                label: 'page.spvPro.label.orientationLegend',
            },
        },
    },
    DEFAULT: {
        step: 5,
        [PANEL_ORIENTATION_NAMES.ORIENTATION_GRAPH]: {
            visible: true,
        },
        [PANEL_ORIENTATION_NAMES.ORIENTATION_BUTTONS]: {
            visible: true,
        },
        [PANEL_ORIENTATION_NAMES.ORIENTATION_INPUT]: {
            visible: true,
            disabled: true,
        },
        [PANEL_ORIENTATION_NAMES.ORIENTATION_LEGEND]: {
            visible: true,
            label: 'page.spvPro.label.orientationLegend',
        },
    },
};

export const PANEL_SLOPE_NAMES = {
    SLOPE_ROOF_GRAPH: 'slopeRoofGraph',
    SLOPE_ROOF_INPUT: 'slopeRoofInput',
    SLOPE_ROOF_BUTTONS: 'slopeRoofButtons',
    SLOPE_PANELS_GRAPH: 'slopePanelsGraph',
    SLOPE_PANELS_INPUT: 'slopePanelsInput',
    SLOPE_PANELS_BUTTONS: 'slopePanelsButtons',
    SLOPE_LEGEND: 'slopeLegend',
};

export const PANEL_SLOPE_CONFIG = {
    DEFAULT: {
        step: 5,
        maxSlopeRoof: 45,
        maxSlopePanel: 45,
        roofPanelGap: 5,
        [PANEL_SLOPE_NAMES.SLOPE_ROOF_GRAPH]: {
            visible: true,
            label: 'page.spvPro.label.roofSlope',
        },
        [PANEL_SLOPE_NAMES.SLOPE_ROOF_INPUT]: {
            visible: true,
            disabled: true,
        },
        [PANEL_SLOPE_NAMES.SLOPE_ROOF_BUTTONS]: {
            visible: true,
        },
        [PANEL_SLOPE_NAMES.SLOPE_PANELS_GRAPH]: {
            visible: true,
            label: 'page.spvPro.label.panelsSlope',
        },
        [PANEL_SLOPE_NAMES.SLOPE_PANELS_INPUT]: {
            visible: true,
            disabled: true,
        },
        [PANEL_SLOPE_NAMES.SLOPE_PANELS_BUTTONS]: {
            visible: true,
        },
        [PANEL_SLOPE_NAMES.SLOPE_LEGEND]: {
            visible: true,
            label: 'page.spvPro.label.slopeLegend',
        },
    },
};

// END REGION -> SYSTEM SETTINGS

// START REGION -> PROJECT SUMMARY
export const PROJECT_SUMMARY_SECTIONS = {
    KPIS: 'kpis',
    CHART: 'chart',
};

export const PROJECT_SUMMARY_SECTIONS_CONFIG = {
    DEFAULT: {
        KPIS: {
            visible: true,
        },
        [PROJECT_SUMMARY_SECTIONS.CHART]: {
            //alterar
            visible: true,
        },
    },
};

export const PROJECT_SUMMARY_KPIS = {
    INVESTMENT: 'investment',
    SAVINGS: 'annual_saving',
    PEAK_POWER: 'peak_power',
    INVERTER_POWER: 'inverter_power',
    SPECIFIC_PRICE: 'specific_price',
    ESTIMATED_ANNUAL_PRODUCTION: 'annual_prod',
    RETURN: 'payback',
    CONSUMPTION_REDUCTION: 'cons_reduction_perc',
    INJECTION: 'injection_perc',
    BATTERY_CAPACITY_KWH: 'battery_capacity_kwh',
    BATTERY_POWER_KWH: 'battery_power_kw',
    REPRESENTATIVENESS: 'representativeness',
    SELF_CONSUMPTION: 'selfconsumption_perc',
};

export const PROJECT_SUMMARY_KPIS_CONFIG = {
    [getCompanyProfileIds().EDP_BR]: {
        [GRUPO_CANAL_IDS.DEFAULT]: {
            [PROJECT_SUMMARY_KPIS.INVESTMENT]: {
                visible: true,
                label: 'label.investment',
                styleFormat: 'currency',
                numberOfDecimalPlaces: 0,
                unit: () => null,
            },
            [PROJECT_SUMMARY_KPIS.SAVINGS]: {
                visible: true,
                label: 'label.saving',
                styleFormat: 'decimal',
                numberOfDecimalPlaces: 0,
                unit: () => 'yc',
            },
            [PROJECT_SUMMARY_KPIS.PEAK_POWER]: {
                visible: true,
                label: 'label.peakPower',
                styleFormat: 'decimal',
                numberOfDecimalPlaces: 2,
                unit: (companyProfileId) => peakPowerUnit(companyProfileId),
            },
            [PROJECT_SUMMARY_KPIS.INVERTER_POWER]: {
                visible: true,
                label: 'label.invertedPower',
                styleFormat: 'decimal',
                numberOfDecimalPlaces: 0,
                unit: () => 'kw',
            },
            [PROJECT_SUMMARY_KPIS.SPECIFIC_PRICE]: {
                visible: true,
                label: 'label.specificPrice',
                styleFormat: 'decimal',
                numberOfDecimalPlaces: 0,
                unit: () => 'ypp',
            },
            [PROJECT_SUMMARY_KPIS.ESTIMATED_ANNUAL_PRODUCTION]: {
                visible: true,
                label: 'label.annualProduction',
                styleFormat: 'decimal',
                numberOfDecimalPlaces: 2,
                unit: () => 'mwh',
            },
            [PROJECT_SUMMARY_KPIS.RETURN]: {
                visible: true,
                label: 'label.payback',
                styleFormat: 'decimal',
                numberOfDecimalPlaces: 1,
                unit: () => 'year',
            },
            [PROJECT_SUMMARY_KPIS.CONSUMPTION_REDUCTION]: {
                visible: true,
                label: 'label.consReductionPerc',
                styleFormat: 'percent',
                numberOfDecimalPlaces: 1,
                unit: () => null,
            },
            [PROJECT_SUMMARY_KPIS.REPRESENTATIVENESS]: {
                visible: true,
                label: 'label.representativeness',
                styleFormat: 'percent',
                numberOfDecimalPlaces: 1,
                unit: () => null,
            },
            [PROJECT_SUMMARY_KPIS.INJECTION]: {
                visible: true,
                label: 'label.injection',
                styleFormat: 'percent',
                numberOfDecimalPlaces: 1,
                unit: () => null,
            },
            [PROJECT_SUMMARY_KPIS.BATTERY_CAPACITY_KWH]: {
                visible: false,
                label: 'label.batteryCapacity',
                styleFormat: 'decimal',
                numberOfDecimalPlaces: 0,
                unit: () => 'kwh',
            },
            [PROJECT_SUMMARY_KPIS.BATTERY_POWER_KWH]: {
                visible: false,
                label: 'label.batteryPower',
                styleFormat: 'decimal',
                numberOfDecimalPlaces: 0,
                unit: () => 'kw',
            },
        },
        [GRUPO_CANAL_IDS.SPV_TEST_BR]: {
            [PROJECT_SUMMARY_KPIS.INVESTMENT]: {
                visible: true,
                label: 'label.investment',
                styleFormat: 'currency',
                numberOfDecimalPlaces: 0,
                unit: () => null,
            },
            [PROJECT_SUMMARY_KPIS.SAVINGS]: {
                visible: true,
                label: 'label.saving',
                styleFormat: 'decimal',
                numberOfDecimalPlaces: 0,
                unit: () => 'yc',
            },
            [PROJECT_SUMMARY_KPIS.PEAK_POWER]: {
                visible: true,
                label: 'label.peakPower',
                styleFormat: 'decimal',
                numberOfDecimalPlaces: 2,
                unit: (companyProfileId) => peakPowerUnit(companyProfileId),
            },
            [PROJECT_SUMMARY_KPIS.INVERTER_POWER]: {
                visible: true,
                label: 'label.invertedPower',
                styleFormat: 'decimal',
                numberOfDecimalPlaces: 0,
                unit: () => 'kw',
            },
            [PROJECT_SUMMARY_KPIS.SPECIFIC_PRICE]: {
                visible: true,
                label: 'label.specificPrice',
                styleFormat: 'decimal',
                numberOfDecimalPlaces: 0,
                unit: () => 'ypp',
            },
            [PROJECT_SUMMARY_KPIS.ESTIMATED_ANNUAL_PRODUCTION]: {
                visible: true,
                label: 'label.annualProduction',
                styleFormat: 'decimal',
                numberOfDecimalPlaces: 2,
                unit: () => 'mwh',
            },
            [PROJECT_SUMMARY_KPIS.RETURN]: {
                visible: true,
                label: 'label.payback',
                styleFormat: 'decimal',
                numberOfDecimalPlaces: 1,
                unit: () => 'year',
            },
            [PROJECT_SUMMARY_KPIS.CONSUMPTION_REDUCTION]: {
                visible: true,
                label: 'label.consReductionPerc',
                styleFormat: 'percent',
                numberOfDecimalPlaces: 1,
                unit: () => null,
            },
            [PROJECT_SUMMARY_KPIS.REPRESENTATIVENESS]: {
                visible: true,
                label: 'label.representativeness',
                styleFormat: 'percent',
                numberOfDecimalPlaces: 1,
                unit: () => null,
            },
            [PROJECT_SUMMARY_KPIS.INJECTION]: {
                visible: true,
                label: 'label.injection',
                styleFormat: 'percent',
                numberOfDecimalPlaces: 1,
                unit: () => null,
            },
            [PROJECT_SUMMARY_KPIS.BATTERY_CAPACITY_KWH]: {
                visible: false,
                label: 'label.batteryCapacity',
                styleFormat: 'decimal',
                numberOfDecimalPlaces: 0,
                unit: () => 'kwh',
            },
            [PROJECT_SUMMARY_KPIS.BATTERY_POWER_KWH]: {
                visible: false,
                label: 'label.batteryPower',
                styleFormat: 'decimal',
                numberOfDecimalPlaces: 0,
                unit: () => 'kw',
            },
        },
    },
    DEFAULT: {
        [PROJECT_SUMMARY_KPIS.INVESTMENT]: {
            visible: true,
            label: 'label.investment',
            styleFormat: 'currency',
            numberOfDecimalPlaces: 0,
            unit: () => null,
        },
        [PROJECT_SUMMARY_KPIS.SAVINGS]: {
            visible: true,
            label: 'label.saving',
            styleFormat: 'decimal',
            numberOfDecimalPlaces: 0,
            unit: () => 'yc',
        },
        [PROJECT_SUMMARY_KPIS.PEAK_POWER]: {
            visible: true,
            label: 'label.peakPower',
            styleFormat: 'decimal',
            numberOfDecimalPlaces: 2,
            unit: (companyProfileId) => peakPowerUnit(companyProfileId),
        },
        [PROJECT_SUMMARY_KPIS.INVERTER_POWER]: {
            visible: true,
            label: 'label.invertedPower',
            styleFormat: 'decimal',
            numberOfDecimalPlaces: 0,
            unit: () => 'kw',
        },
        [PROJECT_SUMMARY_KPIS.SPECIFIC_PRICE]: {
            visible: true,
            label: 'label.specificPrice',
            styleFormat: 'decimal',
            numberOfDecimalPlaces: 0,
            unit: (companyProfileId) => currencyPerPeakPowerUnit(companyProfileId),
        },
        [PROJECT_SUMMARY_KPIS.ESTIMATED_ANNUAL_PRODUCTION]: {
            visible: true,
            label: 'label.annualProduction',
            styleFormat: 'decimal',
            numberOfDecimalPlaces: 2,
            unit: () => 'mwh',
        },
        [PROJECT_SUMMARY_KPIS.RETURN]: {
            visible: true,
            label: 'label.payback',
            styleFormat: 'decimal',
            numberOfDecimalPlaces: 1,
            unit: () => 'year',
        },
        [PROJECT_SUMMARY_KPIS.CONSUMPTION_REDUCTION]: {
            visible: true,
            label: 'label.consReductionPerc',
            styleFormat: 'percent',
            numberOfDecimalPlaces: 1,
            unit: () => null,
        },
        [PROJECT_SUMMARY_KPIS.REPRESENTATIVENESS]: {
            visible: false,
            label: 'label.representativeness',
            styleFormat: 'percent',
            numberOfDecimalPlaces: 1,
            unit: () => null,
        },
        [PROJECT_SUMMARY_KPIS.INJECTION]: {
            visible: true,
            label: 'label.injection',
            styleFormat: 'percent',
            numberOfDecimalPlaces: 1,
            unit: () => null,
        },
        [PROJECT_SUMMARY_KPIS.SELF_CONSUMPTION]: {
            visible: true,
            label: 'label.selfConsumption',
            styleFormat: 'percent',
            numberOfDecimalPlaces: 1,
            unit: () => null,
        },
        [PROJECT_SUMMARY_KPIS.BATTERY_CAPACITY_KWH]: {
            visible: false,
            label: 'label.batteryCapacity',
            styleFormat: 'decimal',
            numberOfDecimalPlaces: 0,
            unit: () => 'kwh',
        },
        [PROJECT_SUMMARY_KPIS.BATTERY_POWER_KWH]: {
            visible: false,
            label: 'label.batteryPower',
            styleFormat: 'decimal',
            numberOfDecimalPlaces: 0,
            unit: () => 'kw',
        },
    },
};

// CHARTS
export const PROJECT_SUMMARY_CHART = {
    CONSUMPTION_PRODUCTION: 'consumptionProduction',
    SENSIBILITY_ANALYSIS: 'sensivityAnalysis',
};

export const PROJECT_SUMMARY_CHART_CONFIG = {
    [getCompanyProfileIds().PROFILE_BASE_SWISS]: {
        [PROJECT_SUMMARY_CHART.CONSUMPTION_PRODUCTION]: {
            visible: true,
            value: 0,
            label: {
                productionConsumption: 'page.spvPro.projectSummary.chart.consumptionProduction',
                production: 'label.production',
                consumption: 'label.consumption',
            },
        },
        [PROJECT_SUMMARY_CHART.SENSIBILITY_ANALYSIS]: {
            visible: false,
            value: 1,
            label: 'page.spvPro.projectSummary.chart.sensitivityAnalisys',
        },
    },
    [getCompanyProfileIds().PPC]: {
        [PROJECT_SUMMARY_CHART.CONSUMPTION_PRODUCTION]: {
            visible: true,
            value: 0,
            label: {
                productionConsumption: 'page.spvPro.projectSummary.chart.consumptionProduction',
                production: 'label.production',
                consumption: 'label.consumption',
            },
        },
        [PROJECT_SUMMARY_CHART.SENSIBILITY_ANALYSIS]: {
            visible: false,
            value: 1,
            label: 'page.spvPro.projectSummary.chart.sensitivityAnalisys',
        },
    },
    [getCompanyProfileIds().SUNSEAP]: {
        [PROJECT_SUMMARY_CHART.CONSUMPTION_PRODUCTION]: {
            visible: true,
            value: 0,
            label: {
                productionConsumption: 'page.spvPro.projectSummary.chart.consumptionProduction',
                production: 'label.production',
                consumption: 'label.consumption',
            },
        },
        [PROJECT_SUMMARY_CHART.SENSIBILITY_ANALYSIS]: {
            visible: false,
            value: 1,
            label: 'page.spvPro.projectSummary.chart.sensitivityAnalisys',
        },
    },
    [getCompanyProfileIds().ROMANDE]: {
        [PROJECT_SUMMARY_CHART.CONSUMPTION_PRODUCTION]: {
            visible: true,
            value: 0,
            label: {
                productionConsumption: 'page.spvPro.projectSummary.chart.consumptionProduction',
                production: 'label.production',
                consumption: 'label.consumption',
            },
        },
        [PROJECT_SUMMARY_CHART.SENSIBILITY_ANALYSIS]: {
            visible: false,
            value: 1,
            label: 'page.spvPro.projectSummary.chart.sensitivityAnalisys',
        },
    },
    DEFAULT: {
        [PROJECT_SUMMARY_CHART.CONSUMPTION_PRODUCTION]: {
            visible: true,
            value: 0,
            label: {
                productionConsumption: 'page.spvPro.projectSummary.chart.consumptionProduction',
                production: 'label.production',
                consumption: 'label.consumption',
            },
        },
        [PROJECT_SUMMARY_CHART.SENSIBILITY_ANALYSIS]: {
            visible: true,
            value: 1,
            label: 'page.spvPro.projectSummary.chart.sensitivityAnalisys',
        },
    },
};

export const CHART_SENSITIBITY_ANALYSIS_NAMES = {
    TRANSATIONAL: 'transational',
    SERVICE: 'service',
};

export const CHART_SENSITIBITY_ANALYSIS_CONFIG = {
    DEFAULT: {
        [CHART_SENSITIBITY_ANALYSIS_NAMES.TRANSATIONAL]: {
            visible: true,
            value: 0,
            label: 'page.spvProp.graph.label.transactional',
            config: {
                data: [],
                fill: true,
                pointRadius: 6,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBackgroundColor: 'rgb(214, 194, 40)',
                borderColor: 'rgb(214, 194, 40)',
                backgroundColor: 'rgb(214, 194, 40)',
                label: intlMessages('label.minimumPayback'),
                keyword: 'label.minimumPayback',
            },
        },
        [CHART_SENSITIBITY_ANALYSIS_NAMES.SERVICE]: {
            visible: true,
            value: 1,
            label: 'page.spvProp.graph.label.service',
            config: {
                data: [],
                fill: true,
                pointRadius: 6,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBackgroundColor: '#9FE0CE',
                borderColor: '#9FE0CE',
                backgroundColor: '#9FE0CE',
                label: intlMessages('page.spvProp.graph.label.maxTIR'),
                keyword: 'page.spvProp.graph.label.maxTIR',
            },
        },
    },
};

export const CHART_PRODUCTION_LOSSES_CONFIG = {
    DEFAULT: {
        visible: true,
    },
};

// END REGION -> PROJECT SUMMARY
/*  */
// START REGION -> VARIABLES NAMES

export const SIMULATION_INPUT_NAMES = {
    RANGE_ID: 'range_id',
    INVESTMENT: 'investment',
    ANNUAL_SAVING: 'annual_saving',
    PEAK_POWER: 'peak_power',
    INVERTER_POWER: 'inverter_power',
    SPECIFIC_PRICE: 'specific_price',
    ANNUAL_PROD: 'annual_prod',
    PAYBACK: 'payback',
    CONST_REDUCTION_PERC: 'cons_reduction_perc',
    INJECTION_PERC: 'injection_perc',
    NETWORK_SALE: 'network_sale',
    INJECTION_TARIFF: 'injection_tariff',
};

export const BATTERY_INPUT_NAMES = {
    BATTERY_CHARGE_EFFICIENCY_PERC: 'battery_charge_efficiency_perc',
    BATTERY_DISCHARGE_EFFICIENCY_PERC: 'battery_discharge_efficiency_perc',
    BATTERY_DOD_PERC: 'battery_dod_perc',
    INJECTION_DECREASE_SELECTED_PERC: 'injection_decrease_selected_perc',
    BATTERY_CAPACITY_SELECTED_KWH: 'battery_capacity_selected_kwh',
    BATTERY_POWER_SELECTED_KW: 'battery_power_selected_kw',
};
//#endregion FORMS

export const BATTERY_FORM_QUESTIONS = {
    [BATTERY_INPUT_NAMES.BATTERY_CHARGE_EFFICIENCY_PERC]: {
        name: BATTERY_INPUT_NAMES.BATTERY_CHARGE_EFFICIENCY_PERC,
        label: 'page.spv.batteryDialog.chargeEfficiency',
        placeholder: 'page.spv.batteryDialog.chargeEfficiency.placeholder',
        inputType: 'number',
        disabled: false,
        rules: validate(['required', 'integerNumber', 'min', 'max'], { min: 1, max: 100 }),
    },
    [BATTERY_INPUT_NAMES.BATTERY_DISCHARGE_EFFICIENCY_PERC]: {
        name: BATTERY_INPUT_NAMES.BATTERY_DISCHARGE_EFFICIENCY_PERC,
        label: 'page.spv.batteryDialog.dischargeEfficiency',
        placeholder: 'page.spv.batteryDialog.dischargeEfficiency.placeholder',
        inputType: 'number',
        disabled: false,
        rules: validate(['required', 'integerNumber', 'min', 'max'], { min: 1, max: 100 }),
    },
    [BATTERY_INPUT_NAMES.BATTERY_DOD_PERC]: {
        name: BATTERY_INPUT_NAMES.BATTERY_DOD_PERC,
        label: 'page.spv.batteryDialog.depthOfDischarge',
        placeholder: 'page.spv.batteryDialog.depthOfDischarge.placeholder',
        inputType: 'number',
        disabled: false,
        rules: validate(['required', 'integerNumber', 'min', 'max'], { min: 1, max: 100 }),
    },
    [BATTERY_INPUT_NAMES.INJECTION_DECREASE_SELECTED_PERC]: {
        name: BATTERY_INPUT_NAMES.INJECTION_DECREASE_SELECTED_PERC,
        label: 'page.spv.batteryDialog.injectionDecrease',
        placeholder: 'page.spv.batteryDialog.injectionDecrease.placeholder',
        inputType: 'number',
        disabled: false,
        rules: validate(['required', 'integerNumber', 'min', 'max'], { min: 1, max: 100 }),
    },
    [BATTERY_INPUT_NAMES.BATTERY_CAPACITY_SELECTED_KWH]: {
        name: BATTERY_INPUT_NAMES.BATTERY_CAPACITY_SELECTED_KWH,
        label: 'page.spv.batteryDialog.batteryCapacity',
        placeholder: 'page.spv.batteryDialog.batteryCapacity.placeholder',
        inputType: 'number',
        disabled: false,
        rules: validate(['required']),
    },
};

export const PANEL_VISIBILITY_IDS = {
    FULL: 1,
    PARTIAL: 2,
    NULL: 3,
};

export const BATTERY_COMPATIBLE_RANGES_ID = {
    [getCompanyProfileIds().EFZ]: [10],
    [getCompanyProfileIds().EDP_PT]: [1, 9],
};
export const COMPANIES_WITH_BATTERY = [
    getCompanyProfileIds().EDP_PT,
    getCompanyProfileIds().EDP_ES,
    getCompanyProfileIds().EFZ,
    getCompanyProfileIds().EDP_IT,
];

export const CHARTS_IDS = {
    CONSUMPTIONS_PRODUCTIONS: 1,
    SENSIBILITY_ANALYSIS: 2,
};

export const PRODUCT_LOSSES_CHART_LABELS = {
    theoretical_production_system: 'page.spvPro.graph.label.theoreticalProductionSys',
    output_energy_panels: 'page.spvPro.graph.label.outputEnergyPanels',
    output_energy_panels_prc: 'page.spvPro.graph.label.outputEnergyPanelsPrc',
    energy_after_clipping: 'page.spvPro.graph.label.energyAfterClipping',
    energy_after_clipping_prc: 'page.spvPro.graph.label.energyAfterClippingPrc',
    final_energy: 'page.spvPro.graph.label.finalEnergy',
    final_energy_prc: 'page.spvPro.graph.label.finalEnergyPrc',
    total_loss_prc: 'page.spvPro.graph.label.totalLossPrc',
};

export const PRODUCT_LOSSES_CHART_SETTINGS = {
    options: {
        events: [],
        maintainAspectRatio: false,
        tooltips: { enabled: false },
        legend: { display: false },
        layout: {
            padding: {
                left: 0,
                right: 50,
            },
        },
        animation: {
            easing: 'easeInBounce',
            onComplete: function () {
                let chartInstance = this.chart;
                let ctx = chartInstance.ctx;

                ctx.font = '1rem "Open Sans", Roboto, Arial, sans-serif';
                ctx.fillStyle = 'rgba(57, 127, 192, 1)';
                ctx.textAlign = 'top';
                ctx.textBaseline = 'top';

                this.data.datasets.forEach(function (dataset, i) {
                    var meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach(function (bar, index) {
                        var data = dataset.data[index];
                        ctx.fillText(data, bar._model.x + 10, bar._model.y - 6);
                    });
                });
            },
        },
        scales: {
            display: false,
            xAxes: [
                {
                    ticks: {
                        display: false,
                        beginAtZero: true,
                    },
                    gridLines: { display: false },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        mirror: true,
                        padding: 330,
                        fontStyle: '600',
                        fontFamily: '"Open Sans", Roboto, Arial, sans-serif',
                    },
                    gridLines: { display: false },
                },
            ],
        },
    },
    height: 300,
};

export const MAX_REMOTE_NEIGHBOUR_ENTRIES = 70;
export const MAX_REMOTE_ENTERPRISE_ENTRIES = 5;

export const REMOTE_DIALOG_TABLE_COLUMNS = [
    {
        id: REMOTE_TABS_INPUTS.DESCRIPTION,
        Header: (
            <div className="w-100">
                <IntlMessages id={REMOTE_TABS_QUESTIONS[REMOTE_TABS_INPUTS.DESCRIPTION].label} whitespaceEnd />
                {REMOTE_TABS_QUESTIONS[REMOTE_TABS_INPUTS.DESCRIPTION].required && '*'}
            </div>
        ),
        minWidth: 100,
        width: 100,
        accessor: 'description',
        Cell: ({ row, handleChange }) => {
            return (
                <div className="d-flex justify-content-start w-100 me-4">
                    <OutlinedInput
                        id={`${REMOTE_TABS_INPUTS.DESCRIPTION}_row_${row.id}`}
                        className="table-input"
                        value={row?.original?.[REMOTE_TABS_INPUTS.DESCRIPTION]}
                        placeholder={intlMessages(REMOTE_TABS_QUESTIONS[REMOTE_TABS_INPUTS.DESCRIPTION].placeholder)}
                        defaultValue={row?.original?.[REMOTE_TABS_INPUTS.DESCRIPTION] ?? ''}
                        onChange={(e) =>
                            handleChange(parseInt(row.id), {
                                [REMOTE_TABS_INPUTS.DESCRIPTION]: e.target.value,
                            })
                        }
                    />
                </div>
            );
        },
    },
    {
        id: REMOTE_TABS_INPUTS.ACTIVITY_SECTOR,
        Header: (
            <div className="w-100">
                <IntlMessages id={REMOTE_TABS_QUESTIONS[REMOTE_TABS_INPUTS.ACTIVITY_SECTOR].label} whitespaceEnd />
                {REMOTE_TABS_QUESTIONS[REMOTE_TABS_INPUTS.ACTIVITY_SECTOR].required && '*'}
            </div>
        ),
        minWidth: 100,
        width: 100,
        accessor: REMOTE_TABS_INPUTS.ACTIVITY_SECTOR,
        Cell: ({ row, handleChange }) => {
            return (
                <div className="d-flex justify-content-start w-100 me-4">
                    <NumberFormat
                        id={`${REMOTE_TABS_INPUTS.ACTIVITY_SECTOR}_row_${row.id}`}
                        className="table-input"
                        value={row?.original?.[REMOTE_TABS_INPUTS.ACTIVITY_SECTOR]}
                        customInput={OutlinedInput}
                        placeholder={intlMessages(REMOTE_TABS_QUESTIONS[REMOTE_TABS_INPUTS.ACTIVITY_SECTOR].placeholder)}
                        defaultValue={row?.original?.[REMOTE_TABS_INPUTS.ACTIVITY_SECTOR] ?? ''}
                        onChange={(e) =>
                            handleChange(parseInt(row.id), {
                                [REMOTE_TABS_INPUTS.ACTIVITY_SECTOR]: e.target.value,
                            })
                        }
                    />
                </div>
            );
        },
    },
    {
        id: REMOTE_TABS_INPUTS.ANNUAL_CONSUMPTION,
        Header: (
            <div className="w-100">
                <IntlMessages id={REMOTE_TABS_QUESTIONS[REMOTE_TABS_INPUTS.ANNUAL_CONSUMPTION].label} whitespaceEnd />
                {REMOTE_TABS_QUESTIONS[REMOTE_TABS_INPUTS.ANNUAL_CONSUMPTION].required && '*'}
            </div>
        ),
        minWidth: 100,
        width: 100,
        accessor: REMOTE_TABS_INPUTS.ANNUAL_CONSUMPTION,
        Cell: ({ row, handleChange }) => {
            return (
                <div className="d-flex justify-content-start w-100 me-4">
                    <NumberFormat
                        id={`${REMOTE_TABS_INPUTS.ANNUAL_CONSUMPTION}_row_${row.id}`}
                        className="table-input"
                        value={row?.original?.[REMOTE_TABS_INPUTS.ANNUAL_CONSUMPTION]}
                        customInput={OutlinedInput}
                        placeholder={intlMessages(REMOTE_TABS_QUESTIONS[REMOTE_TABS_INPUTS.ANNUAL_CONSUMPTION].placeholder)}
                        allowNegative={false}
                        decimalScale={2}
                        endAdornment={
                            <InputAdornment position="end">
                                {/* @ts-ignore */}
                                {<CustomUnit unit={REMOTE_TABS_QUESTIONS[REMOTE_TABS_INPUTS.ANNUAL_CONSUMPTION].unit} />}
                            </InputAdornment>
                        }
                        defaultValue={row?.original?.[REMOTE_TABS_INPUTS.ANNUAL_CONSUMPTION] ?? ''}
                        onChange={(e) =>
                            handleChange(parseInt(row.id), {
                                [REMOTE_TABS_INPUTS.ANNUAL_CONSUMPTION]: e.target.value,
                            })
                        }
                    />
                </div>
            );
        },
    },
    {
        id: REMOTE_TABS_INPUTS.COEFFICIENT,
        Header: (
            <div className="w-100">
                <IntlMessages id={REMOTE_TABS_QUESTIONS[REMOTE_TABS_INPUTS.COEFFICIENT].label} whitespaceEnd />
                {REMOTE_TABS_QUESTIONS[REMOTE_TABS_INPUTS.COEFFICIENT].required && '*'}
            </div>
        ),
        minWidth: 100,
        width: 100,
        accessor: REMOTE_TABS_INPUTS.COEFFICIENT,
        Cell: ({ row, handleChange }) => {
            return (
                <div className="d-flex justify-content-start w-100 me-4">
                    <NumberFormat
                        id={`${REMOTE_TABS_INPUTS.COEFFICIENT}_row_${row.id}`}
                        className="table-input"
                        value={row?.original?.[REMOTE_TABS_INPUTS.COEFFICIENT]}
                        customInput={OutlinedInput}
                        placeholder={intlMessages(REMOTE_TABS_QUESTIONS[REMOTE_TABS_INPUTS.COEFFICIENT].placeholder)}
                        allowNegative={false}
                        endAdornment={
                            <InputAdornment position="end">
                                {/* @ts-ignore */}
                                {<CustomUnit unit={REMOTE_TABS_QUESTIONS[REMOTE_TABS_INPUTS.COEFFICIENT].unit} />}
                            </InputAdornment>
                        }
                        decimalScale={2}
                        defaultValue={row?.original?.[REMOTE_TABS_INPUTS.COEFFICIENT] ?? ''}
                        onChange={(e) =>
                            handleChange(parseInt(row.id), {
                                [REMOTE_TABS_INPUTS.COEFFICIENT]: e.target.value,
                            })
                        }
                    />
                </div>
            );
        },
    },
    {
        id: 'actions',
        Header: <></>,
        minWidth: 20,
        width: 20,
        accessor: 'actions',
        Cell: ({ row, deleteFacility, duplicateFacility, isAddDisabled }) => {
            return (
                <>
                    <IconButton
                        variant="tertiary"
                        icon="duplicate"
                        disabled={isAddDisabled}
                        onClick={() => duplicateFacility(parseInt(row.id))}
                        dataTestId="duplicate"
                    />

                    <IconButton variant="tertiary" icon="deleteBin" onClick={() => deleteFacility(parseInt(row.id))} dataTestId="delete" />
                </>
            );
        },
    },
];

export const INVERTERS_DIALOG_TABLE_COLUMNS = [
    {
        id: 'icon',
        Header: <div className="w-100" />,
        minWidth: 75,
        width: 75,
        accessor: () => (
            <div className="d-flex justify-content-center w-100 table-icon">
                <InvertersIcon />
            </div>
        ),
    },
    {
        id: 'brand',
        Header: (
            <div className="d-flex justify-content-start w-100">
                <span>
                    <IntlMessages id={`label.brand`} />
                </span>
            </div>
        ),
        minWidth: 100,
        width: 100,
        accessor: (row) => (
            <div className="d-flex justify-content-start w-100">
                <span>{row.brand}</span>
            </div>
        ),
    },
    {
        id: 'model',
        Header: (
            <div className="w-100">
                <span>
                    <IntlMessages id={`label.model`} />
                </span>
            </div>
        ),
        minWidth: 200,
        width: 200,
        accessor: (row) => <div className="d-flex justify-content-start w-100">{row.model}</div>,
    },
    {
        id: 'power',
        Header: (
            <div className="w-100">
                <span>
                    <IntlMessages id={`label.power`} />
                </span>
            </div>
        ),
        minWidth: 100,
        width: 100,
        accessor: (row) => (
            <div className="d-flex justify-content-start w-100">
                <FormatNumber number={Number(row.power)} unit="kw" numberOfDecimalPlaces={Number(row.power) % 1 !== 0 ? 1 : 0} />
            </div>
        ),
    },
    {
        id: 'cost',
        Header: (
            <div className="w-100">
                <span>
                    <IntlMessages id={`label.cost`} />
                </span>
            </div>
        ),
        minWidth: 100,
        width: 100,
        accessor: (row) => (
            <div className="d-flex justify-content-start w-100">
                <FormatNumber number={row.total_costs} unit="currency" numberOfDecimalPlaces={2} />
            </div>
        ),
    },
    {
        id: 'quantity',
        Header: (
            <div className="w-100">
                <span>
                    <IntlMessages id={`label.quantity`} />
                </span>
            </div>
        ),
        minWidth: 200,
        width: 200,
        accessor: 'quantity',
        Cell: ({ row, selectedInverters, handleChange, editInverters }) => {
            let qty = selectedInverters?.find((inv) => inv.id === row.original?.id)?.qt || 0;
            return (
                <div className="d-flex justify-content-start w-100 me-4">
                    <OutlinedInput
                        id={`quantity_row_${row.id}`}
                        className="table-input"
                        value={qty}
                        onChange={(e) => handleChange(row.original, e.target.value)}
                        disabled={!editInverters}
                    />
                </div>
            );
        },
    },
];

export const OPTIMIZATION_OPTIONS_NAMES = {
    SOLAR: 'Solar',
    STORAGE: 'Solar + Storage',
    TRANSATIONAL: 'Transational',
    NON_TRANSATIONAL: 'Non Transational',
    OPTIMIZATION_PRODUCT: 'optimizationProduct',
    OPTIMIZATION_FINANCIAL_MODEL: 'optimizationFinancialModel',
    OPTIMIZATION_BUSINESS_MODEL: 'optimizationBusinessModel',
    OPTIMIZATION_CONTRACT_DURATION: 'optimizationContractDuration',
};

export const OPTIMIZATION_PRODUCT_IDS = {
    MIN_CLIENT_TARIFF: 1, //only solarpv
    MAX_CLIENT_SAVINGS: 2, //solarpv+battery
    MIN_PAYBACK: 3,
    MIN_PAYBACK_RESTRICTIONS: 4,
};

export const OPTIMIZATION_OPTIONS = [
    {
        name: OPTIMIZATION_OPTIONS_NAMES.OPTIMIZATION_PRODUCT,
        label: <IntlMessages id="page.processes.filters.tpid" />,
        values: [
            {
                label: <IntlMessages id="page.spvPro.label.solarPV" />,
                value: OPTIMIZATION_PRODUCT_IDS.MIN_CLIENT_TARIFF,
                with_battery: false,
            },
            {
                label: <IntlMessages id="page.spvPro.label.solarPVBattery" />,
                value: OPTIMIZATION_PRODUCT_IDS.MAX_CLIENT_SAVINGS,
                disabled: true,
                with_battery: true,
            },
        ],
        rules: {
            required: 'yup.message.required',
        },
    },
    {
        name: OPTIMIZATION_OPTIONS_NAMES.OPTIMIZATION_FINANCIAL_MODEL,
        label: <IntlMessages id="page.processes.filters.tpid" />,
        values: [
            {
                label: <IntlMessages id="page.spvProp.graph.label.transactional" />,
                value: OPTIMIZATION_OPTIONS_NAMES.TRANSATIONAL,
                // disabled: true,
                businessModels: [
                    {
                        label: <IntlMessages id="product.typeBusinessModel.UP_FRONT" />,
                        bm: 'PP',
                    },
                ],
            },
            {
                label: <IntlMessages id="page.spvProp.graph.label.nonTransactional" />,
                value: OPTIMIZATION_OPTIONS_NAMES.NON_TRANSATIONAL,
                businessModels: [
                    {
                        label: <IntlMessages id="product.typeBusinessModel.PAY_AS_PRODUCED" />,
                        bm: 'PAP',
                    },
                    {
                        label: <IntlMessages id="product.typeBusinessModel.LEC" />,
                        bm: 'LEC',
                        disabled: true,
                    },
                ],
            },
        ],
        rules: {
            required: 'yup.message.required',
        },
    },
];

export const MAX_SIZE_OPTIONS = {
    KWP: 'kwp',
    PANELS: 'pan',
};

export const SPV_ERROR_TAGS = {
    TOTAL_AREAS_EXCEED_LIMIT: 'TOTAL_AREAS_EXCEED_LIMIT',
    INVERTER_POWER_EXCEEDED_REQUIRED_POWER: 'INVERTER_POWER_EXCEEDED_REQUIRED_POWER',
    INVERTER_POWER_EXCEEDED_CONTRACTED_POWER: 'INVERTER_POWER_EXCEEDED_CONTRACTED_POWER',
    BUSINESS_MODEL_WITHOUT_DATA: 'BUSINESS_MODEL_WITHOUT_DATA',
    TOTAL_PANELS_NUMBER_EXCEED_LIMIT: 'TOTAL_PANELS_NUMBER_EXCEED_LIMIT',
    TOTAL_PANELS_NUMBER_EXCEED_MAX_LIMIT: 'TOTAL_PANELS_NUMBER_EXCEED_MAX_LIMIT',
    TOTAL_PANELS_NUMBER_EXCEED_MIN_LIMIT: 'TOTAL_PANELS_NUMBER_EXCEED_MIN_LIMIT',
    INVERTER_POWER_EXCEEDED_MIN_KIT_POWER: 'INVERTER_POWER_EXCEEDED_MIN_KIT_POWER',
    LECS_POWER_LIMIT_EXCEEDED: 'LECS_POWER_LIMIT_EXCEEDED',
    MISSING_INVALID_REGION_GD_INFO: 'MISSING_INVALID_REGION_GD_INFO',
};

export const SPV_INFO_TAGS = {
    MIN_CLUSTER_POWER_LIMIT: 'MIN_CLUSTER_POWER_LIMIT',
    NO_SOLUTION_WITHIN_LIMITS: 'NO_SOLUTION_WITHIN_LIMITS',
    TOTAL_PEAK_POWER_EXCEEDED: 'TOTAL_PEAK_POWER_EXCEEDED',
    TOTAL_PANELS_NUMBER_EXCEED_MAX_LIMIT_INFO: 'TOTAL_PANELS_NUMBER_EXCEED_MAX_LIMIT.info',
    INVERTER_POWER_EXCEEDED_CONTRACTED_POWER: 'INVERTER_POWER_EXCEEDED_CONTRACTED_POWER.info',
    INVERTER_POWER_EXCEEDED_REQUIRED_POWER: 'INVERTER_POWER_EXCEEDED_REQUIRED_POWER.info',
};
export const DEFAULT_RANGE_PANEL_TYPE = {
    marca: null,
    modelo: null,
    potencia_pico: null,
    id: null,
    is_recommended: false,
};

export const ORIENTATION_DECIMAL_CFG = {
    isvisible: true,
    decimal: 2,
};

export const SPV_LOADINGS = {
    SIMULATIONS: 'simulation',
    USEFUL_AREA: 'usefulArea',
    MIN_ROW_DISTANCE: 'minRowDistance',
    ESTIMATED_STRUCTURES: 'estimatedStructures',
    AUTO_EXCLUSIONS: 'autoExclusions',
    AUTO_BUILDINGS: 'autoBuildings',
    BUILDINGS: 'buildings',
    PRODUCTIONS: 'productions',
    CONSUMPTIONS: 'consumptions',
    SPV_DATA: 'spvData',
    SENSIBILITY_ANALYSIS: 'sensibilityAnalysis',
    SENSIBILITY_ANALYSIS_BATTERY: 'sensibilityAnalysisBattery',
    INVERTERS: 'inverters',
};

export const SPV_USEFUL_AREA_ACTIONS = {
    REMOVE_PANELS: 'REMOVE_PANELS',
    ADD_FULL_PANELS: 'ADD_FULL_PANELS',
    ADD_VALID_PANELS: 'ADD_VALID_PANELS',
    REMOVE_VALID_PANELS: 'REMOVE_VALID_PANELS',
    ADD_GROUP_PANELS: 'ADD_GROUP_PANELS',
};

export const REMUNERATION_MODELS_TYPE = {
    SELF_CONSUMPTION: 1,
    FEED_IN_TARIFF: 2,
    NET_METERING: 3,
};

export const REMUNERATION_MODELS_TYPE_OPEN_AREA = {
    SELF_CONSUMPTION: 1,
    // FEED_IN_TARIFF: 2,
    NET_METERING: 3,
};

export const REMUNERATIONS_NAMES = {
    [REMUNERATION_MODELS_TYPE.SELF_CONSUMPTION]: 'SELF_CONSUMPTION',
    [REMUNERATION_MODELS_TYPE.FEED_IN_TARIFF]: 'FEED_IN_TARIFF',
    [REMUNERATION_MODELS_TYPE.NET_METERING]: 'NET_METERING',
};

// Injection Panel tooltip link
export const AVAILABLE_LINKS_INJECTION_TARIFF = {
    [getCompanyProfileIds().ROMANDE]: 'https://www.vese.ch/wp-content/uploads/pvtarif/pvtarif2/appPvMapExpert/pvtarif-map-expert-fr.html',
};
//#region SPV1.0
export const SETTINGS = {
    FATOR_AJUSTE: 1.2,
    MALHA_MATRIZ: 1.5,
    MAX_DISTANCE_IN_KMS: 25,
    MAX_INJECTION_PERCENTAGE: 0.1,
    MAX_GROUPS: 25,
    PEAK_POWER_TO_EDIT_TARIFF: 50,
    // DEFAULT_COEFFICIENT: 0.85 @deprecated
};

export const INITIAL_GET_VALID_PANELS_RETRY_STATE = { count: 0, error: null, payload: null };
//#endregion SPV1.0

// Modos limite de potencia (permitir exceder limites)
export const POWER_LIMIT_MODE_IDS = {
    WARNING_AND_BLOCKED: 1 /* Aviso de potência excendida e não avança */,
    WARNING_AND_UNBLOCKED: 2 /* Aviso de potência excedida mas avança  */,
    NO_WARNING_AND_UNBLOCKED: 3 /* Sem aviso e avança */,
};

// Modos limite de potencia (permitir exceder limites)
export const MODO_KITS_IDS = {
    NO_KITS: 1 /* sem kits*/,
    SINGLE_KITS: 2 /* kit unico  */,
    KIT_PER_STRUCTURE: 3 /* um kit por estrutura */,
};

export const POWER_LIMIT_MODES_WITH_WARNING = [POWER_LIMIT_MODE_IDS.WARNING_AND_BLOCKED, POWER_LIMIT_MODE_IDS.WARNING_AND_UNBLOCKED];
export const POWER_LIMIT_MODES_WITH_BLOCKING = [POWER_LIMIT_MODE_IDS.WARNING_AND_BLOCKED];

// when a new group is created, it is added with an "unshift"
export const LAST_ADDED_AREA_INDEX = 0;

//Orientation Arrow SVG
export const ORIENTATION_ARROW_SVG_ATTRIBUTES = {
    paths: [
        `M15.0039 17.4863C15.0039 17.7625 15.2278 17.9863 15.5039 17.9863C15.78 17.9863 16.0039 17.7625 16.0039 17.4863L15.0039 17.4863ZM15.8575 6.63278C15.6622 6.43751 15.3456 6.43751 15.1504 6.63278L11.9684 9.81476C11.7731 10.01 11.7731 10.3266 11.9684 10.5219C12.1636 10.7171 12.4802 10.7171 12.6755 10.5219L15.5039 7.69344L18.3323 10.5219C18.5276 10.7171 18.8442 10.7171 19.0394 10.5219C19.2347 10.3266 19.2347 10.01 19.0394 9.81476L15.8575 6.63278ZM16.0039 17.4863L16.0039 6.98633L15.0039 6.98633L15.0039 17.4863L16.0039 17.4863Z`,
    ],
    fillColor: 'rgba(255, 255, 255, 1)',
    fillOpacity: 1, // Change the opacity to 1 for full visibility
    strokeWeight: 1,
    strokeColor: 'rgba(255, 255, 255, 1)',
};

export const NET_METERING_WITH_BATTERY = 1;

export const SERVER_ERROR_TAGS = [
    'NOT_ENOUGH_DATA_DUE_TO_CONTRACTED_POWER',
    'MISSING_FE_ELEMENTS_ARR',
    'MISSING_INVESTMENT',
    'MISSING_RADIATION',
    'MISSING_REGIONS',
    'TOTAL_PANELS_NUMBER_EXCEED_LIMIT', // Max or min panels limits exceeded
    'TOTAL_PANELS_NUMBER_EXCEED_MAX_LIMIT',
    'TOTAL_PANELS_NUMBER_EXCEED_MIN_LIMIT',
    'INVERTER_POWER_EXCEEDED_CONTRACTED_POWER',
    'MISSING_OR_INVALID_REGION_ID',
    'MISSING_OR_INVALID_COUNTRY_ID',
    'MISSING_CONSUMPTION', // Gráficos
    'MISSING_PRODUCTION', // Gráficos
    'MISSING_OR_INVALID_AREA_INFO',
    'MISSING_MOUNTING_STRUCTURES_COSTS',
    'BREAKER_RATING_EXCEEDED_MCB_RATING',
    '500', //generic status error
    'NOT_ENOUGH_DATA_DUE_TO_CONTRACTED_POWER',
    'MISSING_FE_ELEMENTS_ARR',
    'MISSING_CONSUMPTION',
    'MISSING_PRODUCTION',
    'MISSING_TARIFF',
    'MISSING_TARIFF_AND_OR_CONSUMPTION',
    'MISSING_INVESTMENT',
    'MISSING_RADIATION',
    'MISSING_REGIONS',
    'TOTAL_PANELS_NUMBER_EXCEED_LIMIT',
    'TOTAL_PANELS_NUMBER_EXCEED_MAX_LIMIT',
    'TOTAL_PANELS_NUMBER_EXCEED_MAX_LIMIT.info',
    'TOTAL_AREAS_EXCEED_LIMIT',
    'TOTAL_PEAK_POWER_EXCEEDED',
    'INVERTER_POWER_EXCEEDED_CONTRACTED_POWER',
    'INVERTER_POWER_EXCEEDED_REQUIRED_POWER',
    'INVERTER_POWER_EXCEEDED_MIN_KIT_POWER',
    'HIGH_PRODUCTION',
    'MISSING_OR_INVALID_REGION_ID',
    'MISSING_OR_INVALID_COUNTRY_ID',
    'MISSING_OR_INVALID_AREA_INFO',
    'MIN_CONTRACTED_POWER_NOT_CHECKED',
    'BUSINESS_MODEL_WITHOUT_DATA',
    'KIT_PANELS_NUMBER_LIMIT_MIN',
    'KIT_PANELS_NUMBER_LIMIT_MIN_ALLOWED',
    'MIN_CLUSTER_POWER_LIMIT',
    'NO_SOLUTION_WITHIN_LIMITS',
    'LECS_POWER_LIMIT_EXCEEDED',
    '500',
    '429',
    'MISSING_MOUNTING_STRUCTURES_COSTS',
    'BREAKER_RATING_EXCEEDED_MCB_RATING',
    'UA_MAX_SIZE',
    'MISSING_INVALID_REGION_GD_INFO',
    'NO_AREAS_WITH_PANELS',
];
