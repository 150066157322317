import { themeBase } from 'constants/styles/global';
import { styled } from 'styled-components';

export const StyledTermsBanner = styled.div`
    z-index: 99;
    background-color: #5a575963;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    .terms-banner-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: ${themeBase.color.neutral50};
        max-width: 469px;
        width: 100%;
        border-radius: 10px;
        padding: 37px 35px 37px 35px;
        text-align: center;
        &-options {
            display: flex;
            gap: 23px;
            justify-content: center;
            align-items: center;
            margin-top: 25px;
        }
    }
`;
