import { styled } from 'styled-components';
import ConfirmationDialog from 'components/core/dialogs/ConfirmationDialog/index';
import { ReactComponent as CheckIcon } from 'assets/images/processes/check.svg';
import { ReactComponent as DoubleCheckIcon } from 'assets/images/processes/double-check.svg';
import { media } from 'styles/utils/mediaQueries';
import { themeBase } from 'constants/styles/global';

export const StyledProcessTable = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    color: #000000 !important;
    overscroll-behavior: contain;
    // overflow: auto;

    .table-hover {
        min-width: 100% !important;
        ${media.tablet`
            min-height: 500px !important;
                `}
    }

    .min-resolution-on {
        display: none !important;
    }
    .min-resolution-off {
        display: block !important;
    }

    ${media.tablet`
        .min-resolution-on {
            display: block !important;
        }
        .min-resolution-off {
            display: none !important;
        }
    `}

    .thead {
        width: 100%;
        border-radius: ${themeBase.borderRadius.base};
        border: 1px solid #2020203c;
        background-color: #e5e5e5;
        color: var(--text-color);
        font-weight: ${themeBase.fontWeight.semibold};
        min-height: 2.125rem;
        position: sticky;
        top: 59px;
        z-index: 2;

        ${media.tablet`
            top: 39px;
        `}
        .tr {
            padding: 0;

            ${media.tablet`
                min-width: 700px !important;
            `}
        }

        .th {
            display: flex;
            align-items: center;
            padding: 0.5rem;
            font-size: 0.875rem;
            font-weight: ${themeBase.fontWeight.semibold};
            line-height: 19px;
            background-color: #e5e5e5;
        }

        .scroller {
            width: 100%;
            overflow-x: hidden;
            flex: 1;
        }
    }

    .body-scroller,
    .scroller {
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
    }

    .body-scroller {
        position: relative;
        border-bottom-left-radius: ${themeBase.borderRadius.base};
        border-bottom-right-radius: ${themeBase.borderRadius.base};
        // overflow-x: hidden;
        // overflow-y: hidden;
        min-height: calc(100vh - 330px - 2rem);
        overflow: auto;
        z-index: 1;
        // flex-direction: column;
        overflow: auto;
        // max-height: calc(100vh - 280px - 1rem);

        ${media.tablet`
            min-height: calc(85vh - 220px - 0.5rem);
            // max-height: calc(85vh - 220px - 0.5rem);

            .td {
                align-items: center !important;
            }
        `}

        .tr {
            padding: 0;
            margin: 0.5rem 0;
            border-radius: ${themeBase.borderRadius.base};
            border: 1px solid #e5e5e5;
            background-color: var(--paper-color);
            font-size: 0.875rem;
        }

        .td {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 0.5rem;
            min-height: 3.75rem;

            .unit {
                margin-left: 0.3rem; // fix €/unit bug
            }
        }

        .tr:hover {
            background-color: rgba(${themeBase.color.neutral900}, 0.05);
        }
    }

    .tfooter {
        height: 60px;
        display: flex;
        align-items: flex-start;

        font-size: 0.875rem;
        ${media.tablet`
            min-width: 700px !important;
        `}
        .MuiTablePagination-selectLabel {
            margin: 0px;
        }
    }

    .process-state-tooltip.clickable {
        cursor: pointer;
    }

    .process-action-button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        &:hover {
            color: var(--primary-color);

            svg path {
                fill: var(--primary-color);
            }
        }

        .process-action-button-disabled {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            pointer-events: none;
            color: var(--disabled-color);
            text-decoration: none;
            font-weight: inherit;
            svg path {
                fill: var(--disabled-color);
            }
        }
    }

    .process-details-button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        &:hover {
            color: var(--primary-color);

            svg path {
                fill: var(--primary-color);
            }
        }
        &:hover {
            color: var(--primary-color);

            svg path {
                fill: var(--primary-color);
                stroke: var(--primary-color);
            }
        }
        .process-details-button-disabled {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            pointer-events: none;
            color: var(--disabled-color);
            text-decoration: none;
            font-weight: inherit;
            svg path {
                fill: var(--disabled-color);
            }
            svg path {
                fill: var(--disabled-color);
                stroke: var(--disabled-color);
            }
        }
    }

    h5 {
        margin: 0;
    }

    .refresh-button {
        margin-left: 0.5rem;
        min-width: 0;
    }

    .text-break {
        font-size: 0.875rem;

        ${media.tablet`
            font-size: 0.75rem;
        `}

        .text-break-disabled {
            color: ${themeBase.silverChalice};
        }
    }

    .bottom-loading {
        display: flex;
        margin-left: 2rem;
        min-width: 50px;

        ${media.tablet`
            margin-left: 0.5rem;
        `}
    }

    .pagination-buttons {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin: 0 1rem;
        min-width: 11.25rem;

        ${media.tablet`
            margin: 0 0 0 1.5rem;
        `}

        .pagination-text {
            font-size: 0.875rem;
        }
    }

    // Adds a grayish background to the table body when it is loading
    .table-loading-overlay {
        width: 100%;
        & .tbody {
            pointer-events: none;
            background-color: var(--paper-color) !important;
            overflow: hidden;
        }

        ${media.tablet`
            min-width: 720px !important;
            min-height: 500px !important;
        `}
    }

    .loading-overlay {
        position: absolute;
        height: 10000px; // avoids cutting before end of tbody
        width: 100%;
        top: 0;
        z-index: 9500;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.1) !important;
    }

    &-row-placeholder {
        .text-block {
            padding: 0px;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            .text-row {
                min-height: 2rem !important;
            }
        }
    }
    .table > :not(caption) > * > * {
        background-color: initial;
        border-bottom-width: initial;
        border-bottom-width: initial;
        box-shadow: initial;
        box-shadow: initial;
        color: initial;
        color: initial;
        padding: initial;
    }
`;

export const StyledProcessTablePrice = styled.ul`
    padding-inline-start: 0.5rem;
    margin: 0;

    li {
        text-align: left;
    }
`;

export const StyledProcessTableConfirmationWarningRemove = styled(ConfirmationDialog)`
    .MuiDialog-paper {
        padding: 2rem;
    }

    .MuiDialogTitle-root {
        //padding: 2.813rem 0 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--primary-color);
    }

    .MuiIconButton-root {
        position: absolute;
        right: 1rem;
        top: 1rem;
    }

    .MuiDialogActions-root {
        justify-content: center !important;
        //padding-bottom: 2.75rem;
    }

    .confirmation-dialog-cancel-button {
        color: ${themeBase.silverChalice};
        text-decoration: underline;
        &:hover {
            text-decoration: underline;
        }
    }

    .confirmation-text-content {
        color: #202020;
        text-align: center;
    }
`;

export const StyledCheckIconCursorHelp = styled(CheckIcon)`
    cursor: help;
`;

export const StyledDoubleCheckIconCursorHelp = styled(DoubleCheckIcon)`
    cursor: help;
`;
