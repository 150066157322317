import { lazy } from 'react';

// Services
import lazyLoader from 'services/lazyLoader';

const CreateOMProcessPage = lazy(() => lazyLoader(() => import('pages/Restricted/Processes/O&M/CreateOM')));

const CreateOMProcess = () => {
    const container = <CreateOMProcessPage />;

    return container;
};

export default CreateOMProcess;
