import { EProcessesActions } from 'constants/processes/index';
import { TProcessesState } from 'interfaces/processes';

export const initialFilters = {
    wildcard: '',
    from_updated_at: '',
    to_updated_at: '',
    state_ids: [],
    product_type_ids: [],
    business_model_ids: [],
    partner_ids: [],
    client_name: '',
    offset: 0,
    limit: 50,
};

export const initialDashboard = {
    chartsData: null,
    kpis: null,
    schedules: null,
};

export const initialProcessesState: TProcessesState = {
    processes: [],
    filters: initialFilters,
    dashboard: initialDashboard,
    loadings: {
        filters: false,
        dashboard: false,
        download: null,
    },
    optionFilters: {
        state_ids: [],
        product_type_ids: [],
        business_model_ids: [],
        partner_ids: [],
        limit: [10, 25, 50],
    },
    filterCounter: null,
    total: null,
};

export const ProcessesReducer = (state = initialProcessesState, action: { type: EProcessesActions; payload: any }) => {
    switch (action.type) {
        case EProcessesActions.SET_MOUNT_PAGE: {
            return {
                ...state,
                processes: action.payload.processes,
                optionFilters: {
                    ...state.optionFilters,
                    ...action.payload.optionFilters,
                },
                loadings: {
                    ...state.loadings,
                    filters: false,
                },
                total: action.payload.total,
            };
        }
        case EProcessesActions.SET_PROCESSES: {
            return {
                ...state,
                processes: action.payload.processes,
                loadings: {
                    ...state.loadings,
                    filters: false,
                },
                total: action.payload.total,
            };
        }
        case EProcessesActions.SET_PROCESSES_FILTERS: {
            return {
                ...state,
                filters: {
                    wildcard: action.payload.wildcard,
                    from_updated_at: action.payload.from_updated_at,
                    to_updated_at: action.payload.to_updated_at,
                    state_ids: action.payload.state_ids.map((state) => state.id),
                    product_type_ids: action.payload.product_type_ids.map((product) => product.id),
                    business_model_ids: action.payload.business_model_ids.flatMap((bm) => bm.business_model_ids),
                    partner_ids: action.payload.partner_ids.map((partner) => partner.id),
                    client_name: action.payload.client_name,
                    offset: action.payload.offset,
                    limit: action.payload.limit,
                },
                filterCounter: (state?.filterCounter ?? 0) + 1,
            };
        }
        case EProcessesActions.SET_LOADINGS: {
            const newLoadings = {
                ...state.loadings,
                ...action.payload.reduce((acc, loading) => {
                    acc[loading.name] = loading.value;
                    return acc;
                }, {}),
            };
            return {
                ...state,
                loadings: newLoadings,
            };
        }
        case EProcessesActions.SET_PROCESS_DOWNLOAD: {
            return {
                ...state,
                loadings: {
                    ...state.loadings,
                    download: action.payload,
                },
            };
        }
        case EProcessesActions.SET_DASHBOARD_DATA: {
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    chartsData: action.payload?.chartsData ?? state.dashboard.chartsData,
                    kpis: action.payload?.kpis ?? state.dashboard.kpis,
                    schedules: action.payload?.schedules ?? state.dashboard.schedules,
                },
                optionFilters: {
                    ...state.optionFilters,
                    ...action.payload.optionFilters,
                },
                loadings: {
                    ...state.loadings,
                    dashboard: false,
                },
            };
        }
        case EProcessesActions.SET_PAYMENT_METHODS: {
            return {
                ...state,
                optionFilters: {
                    ...state.optionFilters,
                    business_model_ids: action.payload,
                },
            };
        }
        default:
            return state;
    }
};
