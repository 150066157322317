import { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';

// Contexts
import { SidebarContext } from 'contexts/sidebarContext';

// Components
import SidebarList from './List';
import BackofficeSidebar from 'components/Backoffice/Sidebar';
import PopUpErrorDevice from 'components/@efz/PopUpErrorDevice';
import { isDefined } from 'services/util/auxiliaryUtils';
import { StyledSidebar } from './StylesSidebar';

const Sidebar = () => {
    const isMobile = useMediaQuery('(max-width:600px)', { noSsr: true });

    const { isSidebarPermanent, sidebarOpen, setSidebarOpen, hasSecondarySidebar, showErrorDevice, setShowErrorDevice } =
        useContext(SidebarContext);

    return (
        <>
            <ClickAwayListener onClickAway={() => isMobile && isSidebarPermanent && setSidebarOpen(false)}>
                <StyledSidebar
                    $open={sidebarOpen}
                    anchor="left"
                    open={sidebarOpen}
                    onClose={() => setSidebarOpen(false)}
                    className="efz-sidebar-container"
                    variant={isSidebarPermanent ? 'permanent' : 'temporary'}
                >
                    <SidebarList />
                    {hasSecondarySidebar && <BackofficeSidebar />}
                </StyledSidebar>
            </ClickAwayListener>

            {isDefined(showErrorDevice) && (
                <PopUpErrorDevice setShowErrorDevice={setShowErrorDevice} title={showErrorDevice.title} content={showErrorDevice.content} />
            )}
        </>
    );
};

export default Sidebar;
