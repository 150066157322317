// Radio
import { ReactComponent as UnselectedRadioIcon } from 'assets/images/icons/svg/mui/radio/unselected-radio.svg';
import { ReactComponent as UnselectedRadioHoverIcon } from 'assets/images/icons/svg/mui/radio/unselected-hover-radio.svg';
import { ReactComponent as SelectedRadioIcon } from 'assets/images/icons/svg/mui/radio/selected-radio.svg';
import { ReactComponent as SelectedDisabledRadioIcon } from 'assets/images/icons/svg/mui/radio/selected-disabled-radio.svg';
import { ReactComponent as SelectedHoveredRadioIcon } from 'assets/images/icons/svg/mui/radio/selected-hover-radio.svg';
// Select
import { ReactComponent as DropdownArrowSelectIcon } from 'assets/images/icons/svg/mui/select/arrow-icon.svg';
// Checkbox
import { ReactComponent as UnselectedCheckboxIcon } from 'assets/images/icons/svg/mui/checkbox/unselected-checkbox.svg';
import { ReactComponent as UnselectedCheckboxHoverIcon } from 'assets/images/icons/svg/mui/checkbox/unselected-hovered-checkbox.svg';
import { ReactComponent as SelectedCheckboxIcon } from 'assets/images/icons/svg/mui/checkbox/selected-checkbox.svg';
import { ReactComponent as SelectedDisabledCheckboxIcon } from 'assets/images/icons/svg/mui/checkbox/selected-disabled-checkbox.svg';
import { ReactComponent as SelectedHoveredCheckboxIcon } from 'assets/images/icons/svg/mui/checkbox/selected-hovered-checkbox.svg';
import '../styles/fonts/fonts.css'; // Certifique-se de que o caminho está correto
import { themeBase } from 'constants/styles/global';
import { arrowTooltip } from 'components/@efz/Tooltip/StylesTooltip';

//* Material UI component styling overrides *\\
const ngContentWrapper = {
    flex: '1 1 0',
    width: '100%',
    padding: '2rem 15px 0 15px',
    marginRight: 'auto',
    marginLeft: 'auto',
};
const ngContentWrapperSidebar = {
    flex: '1 1 0',
    width: '100%',
    padding: '2rem 3.5rem 0 3.5rem',
    marginRight: 'auto',
    marginLeft: 'auto',
};

// const toastIcon = {
//     minWidth: '20px',
//     maxWidth: '20px',
// };
const muiOverrides = {
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '@import': [
                    "url('../styles/fonts/fonts.css')",
                    "url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i')",
                ],
                html: {
                    height: '100%',
                    backgroundColor: 'var(--paper-color) !important',
                },
                body: {
                    backgroundColor: 'var(--paper-color) !important',
                    fontSize: '16px',
                    fontWeight: 400,
                    height: '100%',
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#6c757d transparent',
                },
                a: {
                    color: 'var(--primary-color)',
                    textDecoration: 'none',
                    '&:hover': {
                        color: 'var(--primary-color)',
                        textDecoration: 'underline',
                    },
                },
                'h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6': {
                    fontWeight: `${themeBase.headingsFontWeight}`,
                    margin: `0 0 ${themeBase.headingsMarginBottom}`,
                    color: 'unset',
                    lineHeight: 'initial',
                },
                h1: {
                    fontSize: '1.5rem',
                },
                h2: {
                    fontSize: '1.25rem',
                },
                h3: {
                    fontSize: '1.75rem',
                },
                h4: {
                    fontSize: '1rem',
                },
                h5: {
                    fontSize: '0.875rem',
                },
                h6: {
                    fontSize: '0.75rem',
                },
                label: {
                    marginBottom: 0,
                },
                '::-webkit-input-placeholder': {
                    /* Chrome/Opera/Safari */
                    fontSize: '0.75rem',
                },
                '::-moz-placeholder': {
                    /* Firefox 19+ */
                    fontSize: '0.75rem',
                },
                ':-ms-input-placeholder': {
                    /* IE 10+ */
                    fontSize: '0.75rem',
                },
                ':-moz-placeholder': {
                    /* Firefox 18- */
                    fontSize: '0.75rem',
                },
                '.remove-input-arrows': {
                    /* Chrome, Safari, Edge, Opera */
                    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                    },
                    /* Firefox */
                    'input[type="number"]': {
                        MozAppearance: 'textfield',
                    },
                },
                legend: {
                    width: 'unset !important',
                },
                'html, body': {
                    height: '100%',
                    width: '100%',
                    margin: 0,
                    padding: 'env(safe-area-inset-top) 0 env(safe-area-inset-bottom)',
                },
                '::-webkit-scrollbar': {
                    width: '6px',
                    height: '10px',
                    zIndex: 5000,
                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    borderRadius: '5px',
                    '@media (max-width: 992px)': {
                        height: '5px',
                    },
                },
                '::-webkit-scrollbar-thumb': {
                    backgroundColor: '#6c757d', // Assuming $gray-500 is #6c757d
                    borderRadius: '10px',
                    width: '6px',
                    height: '10px',
                    '@media (max-width: 992px)': {
                        height: '5px',
                    },
                },
                // Top laoding bar
                '#nprogress .bar': {
                    zIndex: '5000 !important',
                    background: 'var(--secondary-color) !important',
                },
                '#nprogress .peg': {
                    boxShadow: '0 0 10px var(--secondary-color), 0 0 5px var(--secondary-color) !important',
                },
                '#nprogress .spinner': {
                    zIndex: '5000 !important',
                },
                '#nprogress .spinner-icon': {
                    borderTopColor: 'var(--secondary-color) !important',
                    borderLeftColor: 'var(--secondary-color) !important',
                },
                // Removes the annoying black outline on buttons, menus, etc (everything with tabindex)
                '[tabindex]': {
                    outline: 'none !important',
                    outlineStyle: 'none !important',
                },
                '.sub-heading': {
                    fontSize: '0.75rem',
                    color: `${themeBase.subHeadingColor}`,
                    marginBottom: '0',
                    '&:not(:last-child)': {
                        marginBottom: '16px !important',
                    },
                },
                // Cursor
                '.pointer': {
                    cursor: 'pointer !important',
                },
                '.disabled-cursor': {
                    pointerEvents: 'none',
                    cursor: 'default',
                },
                // Trucates text to 1 line and makes ... appear
                '.truncate': {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                '.unit': {
                    textTransform: 'none !important',
                },
                // Ripple effect
                '.ripple-effect': {
                    position: 'relative',
                    overflow: 'hidden',
                    '&:after': {
                        content: '""',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        width: '5px',
                        height: '5px',
                        background: 'rgba(255, 255, 255, 0.5)', // Assuming bootstrap.$white is #ffffff
                        opacity: 0,
                        borderRadius: '100%',
                        transform: 'scale(1, 1) translate(-50%)',
                        transformOrigin: '50% 50%',
                    },
                    '&:hover:after': {
                        animation: 'ripple 1s ease-out',
                    },
                },
                '@keyframes ripple': {
                    '0%': {
                        transform: 'scale(0, 0)',
                        opacity: 0.5,
                    },
                    '20%': {
                        transform: 'scale(60, 60)',
                        opacity: 0.3,
                    },
                    '100%': {
                        opacity: 0,
                        transform: 'scale(100, 100)',
                    },
                },
                '@-webkit-keyframes ripple': {
                    '0%': {
                        transform: 'scale(0, 0)',
                        opacity: 0.5,
                    },
                    '20%': {
                        transform: 'scale(60, 60)',
                        opacity: 0.3,
                    },
                    '100%': {
                        opacity: 0,
                        transform: 'scale(100, 100)',
                    },
                },
                '.box-shadow': {
                    boxShadow: '0px 0px 15px 0px #00000042',
                    zIndex: '10 !important',
                },
                '.productfruits--container': {
                    '@media screen and (max-width: 1200px)': {
                        display: 'none',
                    },
                },
                '.MuiOutlinedInput-root.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    //vale a pena manter?
                    borderColor: 'var(--primary-color) !important',
                },
                ':root': {
                    '@media (max-width: 960px)': {
                        // md breakpoint
                        fontSize: '15px',
                    },
                    '@media (max-width: 600px)': {
                        // sm breakpoint
                        fontSize: '14px',
                    },
                    '--text-color': '#000',
                    '--text-color-secondary': 'rgba(0, 0, 0, 0.541)',
                    '--background-color': '#fafafa',
                    '--paper-color': '#fff',
                    '--divider-color': '#fff',
                    '--disabled-color': '#a3a3a3',
                    '--disabled-bg-color': 'gray',
                    '--disabled-color-text': 'rgb(163, 163, 163)',
                    '--disabled-color-icon': '#a3a3a3',
                    '--disabled-color-hover': '#e8e8e8',

                    // font-size = 14px | mobile phones
                    '--spacing-50': '0.285714rem', // 4px
                    '--spacing-100': '0.571428rem', // 8px
                    '--spacing-150': '0.714285rem', // 10px
                    '--spacing-200': '0.857142rem', // 12px
                    '--spacing-300': '1rem', // 14px
                    '--spacing-400': '1.142857rem', // 16px
                    '--spacing-500': '1.428571rem', // 20px
                    '--spacing-600': '1.714285rem', // 24px
                    '--spacing-700': '2.285714rem', // 32px
                    '--spacing-750': '3.142857rem', // 44px
                    '--spacing-800': '4rem', // 56px
                    '--spacing-900': '5.714285rem', // 80px
                },
                '.selected-radio-icon circle:first-of-type': {
                    stroke: 'var(--primary-color)',
                },
                '.selected-radio-icon circle:last-of-type': {
                    fill: 'var(--primary-color)',
                },
                '.radio-button-disabled .selected-radio-icon circle:first-of-type': {
                    stroke: 'var(--disabled-color)',
                },
                '.radio-button-disabled .selected-radio-icon circle:last-of-type': {
                    fill: 'var(--disabled-color)',
                },
                // font-size = 15px | mobile phones landscape / tablets vertical mode
                '@media (min-width: 576px)': {
                    ':root': {
                        '--spacing-50': '0.266667rem', // 4px
                        '--spacing-100': '0.533333rem', // 8px
                        '--spacing-150': '0.666666rem', // 10px
                        '--spacing-200': '0.8rem', // 12px
                        '--spacing-300': '0.933333rem', // 14px
                        '--spacing-400': '1.066666rem', // 16px
                        '--spacing-500': '1.333333rem', // 20px
                        '--spacing-600': '1.6rem', // 24px
                        '--spacing-700': '2.133333rem', // 32px
                        '--spacing-750': '2.933333rem', // 44px
                        '--spacing-800': '3.733333rem', // 56px
                        '--spacing-900': '5.333333rem', // 80px
                    },
                },
                // font-size = 16px |  Tablets landscape and desktop
                '@media (min-width: 768px)': {
                    ':root': {
                        '--spacing-50': '0.25rem', // 4px
                        '--spacing-100': '0.5rem', // 8px
                        '--spacing-150': '0.625rem', // 10px
                        '--spacing-200': '0.75rem', // 12px
                        '--spacing-300': '0.875rem', // 14px
                        '--spacing-400': '1rem', // 16px
                        '--spacing-500': '1.25rem', // 20px
                        '--spacing-600': '1.5rem', // 24px
                        '--spacing-700': '2rem', // 32px
                        '--spacing-750': '2.75rem', // 44px
                        '--spacing-800': '3.5rem', // 56px
                        '--spacing-900': '5rem', // 80px
                    },
                },
                '#efz-app, .app-main': {
                    display: 'flex',
                    width: '100%',
                    height: '100vh',
                    '& .app-container': {
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        width: '100%',
                        '& .app-main-container': {
                            display: 'flex',
                            flexDirection: 'column',
                            flexWrap: 'nowrap',
                            width: '100%',
                            overflow: 'hidden',
                            '& .app-main-content-wrapper': {
                                display: 'block',
                                width: '100%',
                                height: '100%',
                                minHeight: `calc(100vh - ${themeBase.headerHeight})`,
                                overflowX: 'hidden',
                                overflowY: 'auto',
                                '& .app-main-content': {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexWrap: 'nowrap',
                                    flex: '1 1 100%',
                                    width: '100%',
                                    minHeight: `calc(100% - ${themeBase.footerHeight})`,
                                    '& > div:not([class])': {
                                        height: '100%',
                                        display: 'flex',
                                        flexFlow: 'column nowrap',
                                        flex: '1 1 100%',
                                    },
                                    // [media.fhd]: {
                                    //     minHeight: `calc(100% - ${themeBase.footerHeightLg})`,
                                    // },
                                },
                            },
                            '& .content-wrapper': {
                                ...ngContentWrapper,
                                '@media (min-width: 576px)': {
                                    maxWidth: '720px',
                                },
                                '@media (min-width: 992px)': {
                                    maxWidth: '960px',
                                },
                                '@media (min-width: 1200px)': {
                                    maxWidth: '1240px',
                                },
                                '&-sidebar-permanent': {
                                    ...ngContentWrapper,
                                    width: 'calc(100% - 4.875rem) !important',
                                    marginRight: 'unset !important',
                                    transition: '0.5s width, 0.5s margin-right',
                                    '&.open': {
                                        width: 'calc(100% - 15.384rem) !important',
                                        marginRight: 'unset',
                                        transition: '0.5s width, 0.5s margin-right',
                                    },
                                },
                            },
                            '& .content-wrapper-fluid': {
                                flex: '1 1 0',
                                width: '100%',
                                padding: '2rem 15px 0 15px',
                                marginRight: 'auto',
                                marginLeft: 'auto',
                            },
                            '& .ng-content-wrapper': {
                                ...ngContentWrapperSidebar,
                                '&-sidebar-permanent': {
                                    ...ngContentWrapperSidebar,
                                    width: 'calc(100% - 4.875rem) !important',
                                    marginRight: 'unset !important',
                                    transition: '0.5s width, 0.5s margin-right',
                                    '&.open': {
                                        ...ngContentWrapperSidebar,
                                        width: 'calc(100% - 15.384rem) !important',
                                        marginRight: 'unset',
                                        transition: '0.5s width, 0.5s margin-right',
                                    },
                                },
                                '@media (am-width: 992px)': {
                                    padding: '2rem 2.5rem 0 2.5rem',
                                },
                            },
                        },
                    },
                },
                '.loading-component': {
                    display: 'flex',
                    flexFlow: 'column nowrap',
                    flex: '1 1 100%',
                    minWidth: '100%',
                    minHeight: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'center',
                    top: 0,
                    left: 0,
                    '& label': {
                        marginTop: '10px',
                    },
                    '& .lf-player-container': {
                        '& #lottie:not(.download)': {
                            '& svg': {
                                "& *[clip-path*='lottie']": {
                                    '& path': {
                                        stroke: 'var(--efz-spinner-color, var(--primary-color))',
                                    },
                                },
                            },
                        },
                        '& .download': {
                            marginBlock: '-16.66% !important',
                        },
                    },
                },
                '.loading-input': {
                    minWidth: '0px',
                    marginRight: '5px',
                },
                '.loading-dialog': {
                    '& .MuiPaper-root': {
                        minWidth: '300px',
                    },
                    '& .loading-component.efz': {
                        '& #lottie': {
                            marginBlock: '-16.66% !important',
                        },
                    },
                },
                '.image-upload-wrap': {
                    border: `1px dashed ${themeBase.color.neutral400}`,
                    position: 'relative',
                    backgroundColor: `${themeBase.color.neutral100}`,
                    borderRadius: '4px',
                    display: 'flex',
                    width: '300px',
                    height: '60px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    '& p': {
                        fontSize: '0.75rem',
                        lineHeight: '1rem',
                        marginBottom: 0,
                    },
                    '&.disabled': {
                        pointerEvents: 'none',
                        '& p': {
                            color: 'var(--disabled-color) !important',
                        },
                    },
                    '&.error': {
                        borderColor: `${themeBase.color.error}`,
                    },
                },
                '.file-upload-input': {
                    position: 'absolute',
                    margin: 0,
                    padding: 0,
                    outline: 'none',
                    opacity: 0,
                    cursor: 'pointer',
                    height: '100%',
                    width: '100%',
                },
                '.disable-scroll': {
                    overflow: 'hidden !important',
                },
                '.MuiInputBase-input': {
                    fontSize: '0.875rem',
                    '&::placeholder': {
                        fontSize: '0.875rem',
                        color: 'var(--disabled-color)',
                        opacity: 1,
                    },
                },
                '.MuiTooltip-tooltip': {
                    padding: '0 !important',
                },
                '.efz-tooltip-content': {
                    fontSize: '0.75rem',
                    color: `${themeBase.color.neutral800}`,
                    padding: `0.75rem 1rem`,
                    textAlign: `center`,
                    'p,ul': {
                        marginTop: 0,
                        marginBottom: 0,
                    },
                    '.link': {
                        color: `${themeBase.color.info600}`,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                    },
                },
                '.efz-tooltip-info': {
                    'div.MuiTooltip-tooltip': {
                        backgroundColor: `${themeBase.color.info50} !important`,
                        border: `1px solid ${themeBase.color.info600}`,
                    },
                    '.MuiTooltip-arrow::before': {
                        backgroundColor: `${themeBase.color.info50} !important`,
                        border: `1px solid ${themeBase.color.info600}`,
                    },
                },
                '.efz-tooltip-default ': {
                    'div.MuiTooltip-tooltip': {
                        backgroundColor: '#ffffff !important',
                        border: `1px solid ${themeBase.color.neutral400}`,
                    },
                    '.MuiTooltip-arrow::before': {
                        backgroundColor: '#ffffff !important',
                        border: `1px solid ${themeBase.color.neutral400}`,
                    },
                },
                '.efz-tooltip-warning': {
                    'div.MuiTooltip-tooltip': {
                        backgroundColor: `${themeBase.color.efzLemon50} !important`,
                        border: `1px solid ${themeBase.color.efzLemon600}`,
                    },
                    '.MuiTooltip-arrow::before': {
                        backgroundColor: `${themeBase.color.efzLemon50} !important`,
                        border: `1px solid ${themeBase.color.efzLemon600}`,
                    },
                },
                '.efz-tooltip-error': {
                    'div.MuiTooltip-tooltip': {
                        backgroundColor: `${themeBase.color.edpRed50} !important`,
                        border: `1px solid ${themeBase.color.edpRed500}`,
                    },
                    '.MuiTooltip-arrow::before': {
                        backgroundColor: `${themeBase.color.edpRed50} !important`,
                        border: `1px solid ${themeBase.color.edpRed500}`,
                    },
                },
                '.efz-tooltip-success': {
                    'div.MuiTooltip-tooltip': {
                        backgroundColor: `${themeBase.color.success50} !important`,
                        border: `1px solid ${themeBase.color.success600}`,
                    },
                    '.MuiTooltip-arrow::before': {
                        backgroundColor: `${themeBase.color.success50} !important`,
                        border: `1px solid ${themeBase.color.success600}`,
                    },
                },
                '.no-vertical-mg': {
                    svg: {
                        margin: '0 5px',
                    },
                },
                ...arrowTooltip('default'),
                '.tertiary-button': {
                    cursor: 'pointer',
                    fontSize: '.875rem !important',
                    fontWeight: 400,
                    height: 'auto !important',
                    lineHeight: '1rem',
                    padding: '0 !important',
                    textDecoration: 'underline !important',
                },
                '.tooltip-gns': {
                    '.MuiTooltip-tooltip': {
                        maxWidth: 'unset !important',
                    },
                },
                '.popover-gns': {
                    '.popover': {
                        maxWidth: '630px',
                    },
                },
                '.spv-client-max-dialog-radio': {
                    '.MuiTypography-root': {
                        minWidth: '9.5rem',
                    },
                },
                '.sales-segment-menu': {
                    '.MuiList-root': {
                        padding: '0 !important',

                        '.MuiMenuItem-root': {
                            fontFamily: `${'Save2Compete'} !important`,
                            fontSize: '1rem',
                            color: 'var(--primary-color) !important',
                            '&:hover, &.Mui-selected': {
                                backgroundColor: 'var(--primary-color) !important',
                                color: 'var(--paper-color) !important',
                            },
                        },
                    },
                },
                '.selected-checkbox-icon': {
                    '#rect2': {
                        stroke: 'var(--primary-color)',
                    },
                    '#check': {
                        stroke: 'var(--primary-color)',
                        fill: 'var(--primary-color)',
                    },
                },
            },
        },
        // Default props to any component
        MuiInputLabel: {
            root: {
                width: 'calc(100% - 50px)',
                'white-space': 'nowrap',
                overflow: 'hidden',
                'text-overflow': 'ellipsis',
            },
            shrink: {
                width: 'unset',
                'white-space': 'unset',
                overflow: 'unset',
                'text-overflow': 'unset',
            },
        },
        MuiSwitch: {
            defaultProps: {
                color: 'default',
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    padding: '9px',
                },
                track: {
                    // when switched off (unchecked)
                    borderRadius: '15px',
                    border: '1px solid rgb(196, 196, 196)',
                    backgroundColor: 'rgb(220, 220, 220)',
                    opacity: 1,
                },
                thumb: {
                    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.08)',
                    backgroundColor: 'var(--paper-color)',
                    border: '1px solid rgb(196, 196, 196)',
                },
                switchBase: {
                    // when switched on (checked)
                    top: '5px',
                    left: '5px',
                    padding: '4px',
                    '&:hover': {
                        backgroundColor: 'rgba(155, 220, 202, 0.4)',
                    },
                    '&.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: 'rgba(62, 192, 156, 0.5)',
                        border: '1px solid rgb(62, 192, 156)',
                        opacity: 1,
                    },
                    '&$disabled': {
                        '& + $track': {
                            backgroundColor: 'rgb(229, 229, 229)',
                            opacity: 1,
                        },
                        '& $thumb': {
                            backgroundColor: 'rgb(229, 229, 229)',
                            boxShadow: 'none',
                        },
                    },
                },
            },
        },
        MuiRadio: {
            defaultProps: {
                style: {
                    backgroundColor: 'transparent',
                },
                disableRipple: true,
                checkedIcon: (
                    <>
                        <SelectedRadioIcon className="selected-radio-icon" />
                        <SelectedDisabledRadioIcon className="selected-radio-icon--disabled" />
                        <SelectedHoveredRadioIcon className="selected-radio-icon--hovered" />
                    </>
                ),
                icon: (
                    <>
                        <UnselectedRadioIcon className="unselected-radio-icon" />
                        <UnselectedRadioHoverIcon className="unselected-radio-icon--hovered" />
                    </>
                ),
            },
            styleOverrides: {
                root: {
                    '& .unselected-radio-icon--hovered': {
                        display: 'none',
                    },
                    '& .selected-radio-icon--hovered': {
                        display: 'none',
                    },
                    '& .selected-radio-icon--disabled': {
                        display: 'none',
                    },
                    '&:hover': {
                        // Unchecked (and enabled) radio button on hover
                        '& .unselected-radio-icon--hovered': {
                            display: 'initial',
                        },
                        '& .unselected-radio-icon': {
                            display: 'none',
                        },
                    },
                    '&$checked:hover': {
                        // Checked (and enabled) radio button on hover
                        '& .selected-radio-icon--hovered': {
                            display: 'initial',
                        },
                        '& .selected-radio-icon': {
                            display: 'none',
                        },
                    },
                    '&.Mui-disabled': {
                        // Checked and disabled checkbox button
                        '& .selected-radio-icon--disabled': {
                            display: 'initial',
                        },
                        '& .selected-radio-icon': {
                            display: 'none',
                        },
                    },
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                IconComponent: DropdownArrowSelectIcon,
                MenuProps: {
                    // Props passed to dropdown menu
                    // Anchor menu to bottom part of select
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    // getContentAnchorEl: null,
                    MenuListProps: {
                        disablePadding: true,
                    },
                    classes: {
                        paper: 'mui-select-menu-paper',
                    },
                },
            },
            styleOverrides: {
                root: {
                    height: '40px',
                    color: '#a3a3a3',
                    opacity: '1',
                    '&.Mui-disabled': {
                        backgroundColor: 'rgb(245, 245, 245)',
                    },
                    fontSize: '0.875rem',
                },
                icon: {
                    top: 'calc(50% - 4px)',
                    '&.Mui-disabled': {
                        fill: 'rgb(163, 163, 163)',
                    },
                },
                iconOutlined: {
                    right: '14px',
                },
                outlined: {
                    paddingRight: '2.5rem !important',
                },
                select: {
                    minHeight: 'inherit',
                    fontSize: '0.875rem',
                    '& .select-placeholder': {
                        color: 'rgb(163, 163, 163)',
                    },
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    backgroundColor: 'var(--paper-color)',
                },
            },
        },
        MuiMenuItem: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    borderTop: '1px solid rgb(196, 196, 196)',
                    minHeight: '40px !important',
                    fontSize: '0.875rem',
                    '&:hover': {
                        backgroundColor: 'var(--primary-color) !important',
                        color: 'var(--paper-color) !important',
                    },
                    '&:active': {
                        backgroundColor: 'var(--primary-color-dark) !important',
                        color: 'var(--paper-color)',
                    },
                },
            },
        },
        MuiPopover: {
            defaultProps: {
                elevation: 3,
            },
        },
        MuiCheckbox: {
            defaultProps: {
                style: {
                    backgroundColor: 'transparent',
                },
                checkedIcon: (
                    <>
                        <SelectedCheckboxIcon className="selected-checkbox-icon" />
                        <SelectedDisabledCheckboxIcon className="selected-checkbox-icon--disabled" />
                        <SelectedHoveredCheckboxIcon className="selected-checkbox-icon--hovered" />
                    </>
                ),
                icon: (
                    <>
                        <UnselectedCheckboxIcon className="unselected-checkbox-icon" />
                        <UnselectedCheckboxHoverIcon className="unselected-checkbox-icon--hovered" />
                    </>
                ),
            },
            styleOverrides: {
                root: {
                    '& .unselected-checkbox-icon--hovered': {
                        display: 'none',
                    },
                    '& .selected-checkbox-icon--hovered': {
                        display: 'none',
                    },
                    '& .selected-checkbox-icon--disabled': {
                        display: 'none',
                    },
                    '&:hover': {
                        // Unchecked (and enabled) checkbox button on hover
                        '& .unselected-checkbox-icon--hovered': {
                            display: 'initial',
                        },
                        '& .unselected-checkbox-icon': {
                            display: 'none',
                        },
                    },
                    '&$checked:hover': {
                        // Checked (and enabled) checkbox button on hover
                        '& .selected-checkbox-icon--hovered': {
                            display: 'initial',
                        },
                        '& .selected-checkbox-icon': {
                            display: 'none',
                        },
                    },
                    '&.Mui-disabled': {
                        // Checked and disabled checkbox button
                        '& .selected-checkbox-icon--disabled': {
                            display: 'initial',
                        },
                        '& .selected-checkbox-icon': {
                            display: 'none',
                        },
                    },
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    height: 40,
                    padding: '0.5rem 1rem',
                    fontWeight: 500,
                    fontSize: '1rem',
                    borderRadius: 5,
                    lineHeight: '1rem',
                    /* '@media (max-width: 992px), (max-height: 767.98px)': {
                        height: 35,
                        padding: '10px 25px',
                    },
                    '@media (max-width: 768px)': {
                        height: 30,
                        padding: '10px 20px',
                    }, */
                    '& .MuiButton-label': {
                        lineHeight: '1rem',
                    },
                    '&.Mui-disabled:not(.MuiButton-tertiary)': {
                        backgroundColor: '#E5E5E5',
                    },
                },
                text: {
                    height: 52,
                    padding: '12px 30px',
                    color: 'var(--text-color)',
                    '&.Mui-disabled': {
                        backgroundColor: '#FAFAFA',
                    },
                },
                containedPrimary: {
                    color: '#FFF',
                    '&:hover': {
                        backgroundColor: 'var(--primary-color-light)',
                    },
                },
                outlinedPrimary: {
                    border: '1px solid var(--primary-color)',
                    '&:hover': {
                        border: '1px solid var(--primary-color-light)',
                        //backgroundColor: 'var(--paper-color)'
                    },
                    '&$disabled': {
                        backgroundColor: '#FAFAFA',
                    },
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                colorDefault: {
                    backgroundColor: 'var(--primary-color)',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                rounded: {
                    borderRadius: '8px',
                },
                root: {
                    '&.mui-select-menu-paper': {
                        maxHeight: '240px', // 6 x 40px (MuiMenu Height)
                        overflowY: 'auto',
                        borderRadius: '0 0 8px 8px',
                        border: '1px solid rgb(196, 196, 196)',
                    },
                    '& > .MuiDialogContent-root > div > .PrivatePickersToolbar-root': {
                        backgroundColor: 'var(--primary-color)',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            defaultProps: {
                notched: false,
                label: null,
            },
            styleOverrides: {
                root: {
                    color: 'var(--text-color) !important',
                    height: '40px',
                    textOverflow: 'ellipsis',
                    '&:hover $notchedOutline': {
                        border: '2px solid rgba(196, 196, 196, 1)',
                        borderColor: 'rgba(196, 196, 196, 1)',
                    },
                    '&.Mui-disabled': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid rgba(0, 0, 0, 0.23)',
                        },
                    },
                    '&.Mui-focused': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #5D656B !important',
                        },
                    },
                },
                input: {
                    padding: '10px 12px',
                    '&::placeholder': {
                        color: '#A3A3A3',
                        opacity: 1,
                    },
                },
                adornedEnd: {
                    paddingRight: 0,
                },
                inputAdornedEnd: {
                    paddingRight: '0.75rem',
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    marginRight: '1rem',
                    color: '#A3A3A3',
                },
            },
            defaultProps: {
                position: 'end',
            },
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    margin: '1rem 0',
                    height: '1px',
                },
                thumb: {
                    width: '17px !important',
                    height: '17px !important',
                    marginTop: '0px !important',
                    marginLeft: '0px !important',
                },
                valueLabel: {
                    backgroundColor: 'transparent',
                    top: '0px',
                    left: 'initial',
                    '& > span': {
                        color: 'var(--text-color)',
                        fontSize: '14px',
                        width: 'inherit',
                    },
                },
                rail: {
                    backgroundColor: 'gray',
                },
                mark: {
                    backgroundColor: 'gray',
                    height: '1px',
                    width: '1px',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        backgroundColor: '#E5E5E5 !important',
                        color: 'rgba(0, 0, 0, 0.38) !important',
                    },
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                toolbar: {
                    minHeight: '40px',
                    '@media (max-width: 992px), (max-height: 767.98px)': {
                        '& .MuiIconButton-root': {
                            padding: '5px 5px',
                            margin: '0 7px',
                            '& .MuiIconButton-label': {
                                height: '24px',
                                width: '24px',
                            },
                        },
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                loading: {
                    fontSize: '0.875rem',
                },
                option: {
                    fontSize: '0.875rem',
                },
                noOptions: {
                    fontSize: '0.875rem',
                },
                listbox: {
                    padding: 0,
                },
            },
        },
    },
};

export default muiOverrides;
