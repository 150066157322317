import clone from 'fast-copy';

// Constants
import {
    ELEMENT_OPTS_IDS,
    FLOOR_DIVISION_DEFAULT_VALUES,
    formDefaultValues,
    HpActions,
    HPHeatLossRoomElements,
    HpModules,
    HpSteps,
    HpSubSteps,
    initialHpDialogs,
    initialHpInputs,
    PROPERTY_HEAT_SOURCE_TYPES,
} from 'constants/products/hp';
import { SENTRY_CODE_ERRO_TAGS } from 'constants/settings';

// Interfaces
import { IHPState } from 'interfaces/products/hp';
import { IHeatpumpsReducerAction } from 'interfaces/products/hp/reducer';

// Services
import { notify } from 'services/@efz/notify';
import { SentryCaptureException } from 'services/@efz/sentry';
import { calcDistanceToM, getDefaultValues, getPayloadToProposal, compareCoordsArrays } from 'services/products/hp';
import { getBoolean, intlMessages, isDefined, isFieldDefined, isString } from 'services/util/auxiliaryUtils';
import { useBusinessModelsStore } from 'store/businessModels';

export const initialHPReducer: IHPState = {
    isMounting: true,
    module: HpModules.NONE,
    regionMetadata: null,
    step: HpSteps.CHOOSE_STEP,
    subStep: null,
    stepBlock: [],
    facilityID: null,
    productID: null,
    companyProfileId: null,
    defaultValues: {},
    inputs: initialHpInputs,
    dialogs: initialHpDialogs,
    canvas: null,
    checkKPIsDialogOpen: false,
    proposal: null,
    options: {
        selectionDistanceReduction: [],
        selectionPressureLevel: [],
        selectionDecibelCorrection: [],
        selectionTransferFluid: [],
        propertyAges: [],
        propertyStatus: [],
        propertyHeatSources: [],
        propertyOdtHeightFactors: [],
        propertyRegionsMetadata: [],
        propertyTypes: [],
        extraEquipmentCatModel: [],
        emitters: [],
        emitterRadiatorTypes: [],
        emitterFanCoilTypes: [],
        emitterFloorCoverageTypes: [],
        emitterPipeSizes: [],
        emitterPipeCentres: [],
        emitterMaxTemps: [],
        emitterData: {},
        elementOptions: [],
        elementTypeOptions: [],
        heatLossDivisionTypes: [],
        heatLossGroundTypes: [],
        heatLossGroundExposureTypes: [],
        heatLossGroundExposureValues: [],
        heatLossGroundInsulationOptions: [],
        installationPrices: {
            installation_fixed_price: null,
            installation_labor_price_per_hour: null,
        },
    },
    kpis: {},
    errors: [],
    isUsingTMYData: false,
    hasShowWatermark: true,
};

export const hpReducer = (state = initialHPReducer, action: IHeatpumpsReducerAction) => {
    // console.log("efz-> hpReducer: type", action.type);

    switch (action.type) {
        case HpActions.SET_HEATPUMPS_MODULE: {
            return {
                ...state,
                isMounting: true,
                module: action.payload,
            };
        }
        case HpActions.SET_HP_CANVAS: {
            return {
                ...state,
                canvas: action.payload,
            };
        }
        case HpActions.SET_INIT: {
            const { payload } = action;
            const {
                facilityID,
                productID,
                postalCode,
                defaultInputs,
                selectionDistanceReduction,
                selectionPressureLevel,
                selectionDecibelCorrection,
                selectionTransferFluid,
                propertyAges,
                propertyStatus,
                propertyHeatSources,
                propertyOdtHeightFactors,
                propertyRegionsMetadata,
                propertyTypes,
                emitterRadiatorTypes,
                emitterFanCoilTypes,
                emitterFloorCoverageTypes,
                emitterPipeSizes,
                emitterPipeCentres,
                emitterMaxTemps,
                elementOptions,
                elementTypeOptions,
                heatLossDivisionTypes,
                heatLossGroundTypes,
                heatLossGroundExposureTypes,
                heatLossGroundExposureValues,
                heatLossGroundInsulationOptions,
                extraEquipmentCatModel,
                emitters,
                kpis,
                installationPrices,
                isUsingTMYData,
            } = payload;

            let regionMetadata = null;
            if (isFieldDefined(postalCode)) {
                if (!isNaN(parseInt(postalCode[1]))) {
                    regionMetadata = postalCode[0];
                } else regionMetadata = postalCode.substring(0, 2);
            }

            const defaultValues = getDefaultValues(defaultInputs, {
                regionMetadata,
                propertyRegionsMetadata,
                isInit: true,
                isUsingTMYData,
            });

            return {
                ...state,
                facilityID,
                productID,
                isMounting: false,
                regionMetadata,
                step: HpSteps.CHOOSE_STEP,
                subStep: null,
                inputs: defaultInputs,
                defaultValues,
                options: {
                    selectionDistanceReduction,
                    selectionPressureLevel,
                    selectionDecibelCorrection,
                    selectionTransferFluid,
                    propertyAges,
                    propertyStatus,
                    propertyHeatSources,
                    propertyOdtHeightFactors,
                    propertyRegionsMetadata,
                    propertyTypes,
                    emitters,
                    emitterRadiatorTypes,
                    emitterFanCoilTypes,
                    emitterFloorCoverageTypes,
                    emitterPipeSizes,
                    emitterPipeCentres,
                    emitterMaxTemps,
                    elementOptions,
                    elementTypeOptions,
                    heatLossDivisionTypes,
                    heatLossGroundTypes,
                    heatLossGroundExposureTypes,
                    heatLossGroundExposureValues,
                    heatLossGroundInsulationOptions,
                    extraEquipmentCatModel,
                    installationPrices:
                        isFieldDefined(installationPrices) ? installationPrices : initialHPReducer.options.installationPrices,
                },
                kpis,
                errors: [],
                isUsingTMYData,
            };
        }
        case HpActions.SET_HP_DETAILED_STEP: {
            return {
                ...state,
                step: action.payload,
            };
        }
        case HpActions.SET_HP_DETAILED_STEPPER: {
            const { step, subStep } = action.payload;

            return {
                ...state,
                step,
                subStep,
            };
        }
        case HpActions.SET_HP_DETAILED_SUB_STEP: {
            if (!!action.payload.step && !!action.payload.subStep) {
                return {
                    ...state,
                    step: action.payload.step,
                    subStep: action.payload.subStep,
                };
            }
            return {
                ...state,
                step: HpSteps.HP_SELECTION,
            };
        }
        case HpActions.SET_HP_PROPERTY: {
            const { finnish, saved, property, goToStep, goToSubStep } = action.payload;

            let newProperty = clone({
                ...state?.inputs?.property,
                finnish,
                saved,
                ...property,
            });
            if (getBoolean(newProperty.hasHeatSource)) {
                newProperty = {
                    ...newProperty,
                    heat_source: {
                        ...newProperty?.heat_source,
                        price:
                            parseInt(newProperty?.heat_source?.type_id) !== PROPERTY_HEAT_SOURCE_TYPES.ELECTRIC ?
                                newProperty?.heat_source?.price
                            :   null,
                    },
                };
            }
            return {
                ...state,
                inputs: {
                    ...state?.inputs,
                    property: clone({
                        ...state?.inputs?.property,
                        finnish,
                        saved,
                        ...newProperty,
                    }),
                },
                step: goToStep ?? HpSteps.HEAT_LOSS,
                subStep: goToSubStep ?? HpSubSteps.HEAT_LOSS_FLOOR_PANNING_OPTIONS,
                stepBlock: state.stepBlock.filter((step) => step.step !== HpSteps.PROPERTY),
            };
        }
        case HpActions.SET_HP_HEAT_LOSS_SUBSTEP_FLOOR_PANNING_OPTIONS: {
            const { formData, subStep, step, setValue, saveProductInputs, stepBlock = [] } = action.payload;

            const {
                inputs: { heat_loss },
            } = state;

            const newInputs = {
                ...state.inputs,
                heat_loss: {
                    ...heat_loss,
                    ...formData,
                    floors:
                        formData?.floors?.length > 0 ?
                            formData?.floors?.map((floor) => {
                                const stateFloorData = heat_loss?.floors?.find((el) => el.floor_number === floor.floor_number) ?? null;
                                const file =
                                    /* @ts-ignore */
                                    parseInt(formData?.floor_plan?.option_id) === 1 ? null : floor?.file ?? stateFloorData?.file ?? null;
                                const filename = floor?.filename ?? stateFloorData?.filename ?? null;

                                return {
                                    ...floor,
                                    // @ts-ignore
                                    file,
                                    filename,
                                    int_walls: stateFloorData?.int_walls ?? [],
                                    rooms: stateFloorData?.rooms ?? [],
                                    // @ts-ignore
                                    walls: stateFloorData?.walls ?? [],
                                };
                            })
                        :   [],
                },
            };

            // update form
            setValue('heat_loss.floors', newInputs.heat_loss.floors);
            saveProductInputs(false, newInputs);

            return {
                ...state,
                inputs: clone(newInputs),
                dialogs: initialHpDialogs,
                step,
                subStep,
                stepBlock: [...state.stepBlock, ...stepBlock],
            };
        }
        case HpActions.SET_HP_HEAT_LOSS_SUBSTEP_FPLAN_DESIGN: {
            const {
                floorsData,
                rspWallsData,
                subStep,
                step,
                isReset = false,
                saveProductInputs,
                setValue,
                watch,
                stepBlock = [],
                canvasChanged = [],
            } = action.payload;

            const {
                options: { elementOptions, elementTypeOptions },
            } = state;

            const oldFloors = watch('heat_loss.floors');
            const newFloors: any[] = [];
            // add coords to floors
            let wallId = 0;
            floorsData.forEach((floor, floorIdx) => {
                const floorChanged = canvasChanged.includes(floor?.floor_number);

                // @ts-ignore
                const { floor_number, floor_distance, floor_measurement } = floor;

                // walls
                const wallsData = rspWallsData[floorIdx]?.data?.data ?? [];

                //Colocar a loginc
                const walls: any[] = [];
                const int_walls: any[] = [];
                let wallsIndex = 0;
                ['externas', 'internas'].forEach((wallType) => {
                    const { id: element_id, tag } = elementOptions.find(
                        (el) => el.tag === (wallType === 'externas' ? 'EXTERIOR_WALL' : 'INTERNAL_WALL')
                    )!;

                    const { id: material_id, uvalue } = elementTypeOptions.find((el) => el.element_id === element_id && el.is_default)!;

                    const lines = wallsData[wallType];
                    for (let index = 0; index < lines.length; index++) {
                        wallId++;
                        const { p1, p2, d: distance } = lines[index];
                        const points = [p1, p2];

                        //if the wall is already on floor (compare by points) get the wall needed details
                        let existingWallTag;
                        let existingUValue;
                        let existingMaterialId;
                        const stateWall = floor?.walls?.find((wall) => {
                            const wallPoints = wall?.points ?? [];
                            return (
                                wallPoints[0].x === p1.x && wallPoints[0].y === p1.y && wallPoints[1].x === p2.x && wallPoints[1].y === p2.y
                            );
                        });
                        if (stateWall) {
                            existingWallTag = stateWall?.wallType ?? tag;
                            existingUValue = stateWall?.uvalue ?? uvalue;
                            existingMaterialId = stateWall?.material_id ?? material_id;
                        }

                        const wallData = {
                            id: wallId,
                            index: wallsIndex,
                            element_id,
                            material_id: existingWallTag === tag ? existingMaterialId ?? material_id : material_id,
                            width: calcDistanceToM(distance, floor_distance, floor_measurement),
                            uvalue: existingWallTag === tag ? existingUValue ?? uvalue : uvalue,
                            filename_arr: [],
                            points,
                            wallType: tag,
                            floorId: floor_number,
                            roomId: lines[index].room_id[0],
                        };

                        walls.push(wallData);

                        if (element_id === ELEMENT_OPTS_IDS.INTERNAL_WALL) {
                            int_walls.push({
                                ...wallData,
                                shared_rooms_ids: lines[index].room_id,
                            });
                        }
                        wallsIndex++;
                    }
                });

                floor?.rooms?.forEach((room, idx) => {
                    const previousRoomFormState = oldFloors
                        ?.find((f) => f?.floor_number === floor?.floor_number)
                        ?.rooms?.find((r) => r?.id === room?.id);
                    const previousRoomContextState = state.inputs.heat_loss?.floors
                        ?.find((f) => f?.floor_number === floor?.floor_number)
                        ?.rooms?.find((r) => {
                            // @ts-ignore
                            return r?.id === room?.id;
                        }) as any;

                    // if there is no previous room with the same id, the data is not copied
                    // 🔨 it needs to check both the form and the context, because the reducer is triggering the action twice for unknown reasons
                    const shouldKeepRoomData = !(!previousRoomFormState || !previousRoomContextState) && !!previousRoomContextState;

                    if (shouldKeepRoomData) {
                        floor.rooms[idx] = {
                            ...floor.rooms[idx],
                            ...previousRoomContextState,
                        };
                    }

                    if (floorChanged && (!!previousRoomContextState || !!previousRoomFormState)) {
                        const canvasRoomState = state.canvas.getObjects()?.find((r) => r?.id === room?.id);
                        const roomChanged =
                            canvasRoomState?.area !== previousRoomContextState?.area ||
                            canvasRoomState?.d_width !== previousRoomContextState?.d_width ||
                            canvasRoomState?.d_height !== previousRoomContextState?.d_height ||
                            canvasRoomState?.area !== previousRoomFormState?.area ||
                            canvasRoomState?.d_width !== previousRoomFormState?.d_width ||
                            canvasRoomState?.d_height !== previousRoomFormState?.d_height ||
                            compareCoordsArrays(canvasRoomState?.points, previousRoomFormState?.points) === false;
                        if (roomChanged) {
                            floor.rooms[idx] = {
                                ...floor.rooms[idx],
                                area: canvasRoomState?.area ?? previousRoomFormState?.area ?? previousRoomContextState?.area,
                                d_width: canvasRoomState?.d_width ?? previousRoomFormState?.d_width ?? previousRoomContextState?.d_width,
                                d_height:
                                    canvasRoomState?.d_height ?? previousRoomFormState?.d_height ?? previousRoomContextState?.d_height,
                                originalPoints:
                                    canvasRoomState?.modifiedPoints ?? previousRoomFormState?.points ?? previousRoomContextState?.points,
                                points:
                                    canvasRoomState?.modifiedPoints ?? previousRoomFormState?.points ?? previousRoomContextState?.points,
                            };
                        }
                    }

                    floor.rooms[idx] = {
                        ...floor.rooms[idx],
                        ext_walls: walls.filter(
                            (wall: { element_id: number; roomId: number; floorId: number }) =>
                                wall.element_id === ELEMENT_OPTS_IDS.EXTERIOR_WALL &&
                                wall.roomId === room.id &&
                                wall.floorId === floor_number
                        ),
                        party_walls: walls.filter(
                            (wall: { element_id: number; roomId: number; floorId: number }) =>
                                wall.element_id === ELEMENT_OPTS_IDS.PARTY_WALL && wall.roomId === room.id && wall.floorId === floor_number
                        ),
                        // #region apply default values
                        roof: {
                            ...floor.rooms[idx]?.roof,
                            [HPHeatLossRoomElements.HAS_ROOF_ABOVE]:
                                isFieldDefined(floor.rooms[idx]?.roof?.[HPHeatLossRoomElements.HAS_ROOF_ABOVE]) ?
                                    floor.rooms[idx]?.roof?.[HPHeatLossRoomElements.HAS_ROOF_ABOVE]
                                :   `${floorsData?.length === 1 || floorIdx === floorsData?.length - 1}`,
                        },
                        advanced: {
                            ...floor.rooms[idx]?.advanced,
                            ground_floor: {
                                ...floor.rooms[idx]?.advanced?.ground_floor,
                                [HPHeatLossRoomElements.CALC_MODE_ID]:
                                    isFieldDefined(floor.rooms[idx]?.advanced?.ground_floor?.[HPHeatLossRoomElements.CALC_MODE_ID]) ?
                                        floor.rooms[idx]?.advanced?.ground_floor?.[HPHeatLossRoomElements.CALC_MODE_ID]
                                    :   FLOOR_DIVISION_DEFAULT_VALUES[HPHeatLossRoomElements.CALC_MODE_ID],
                            },
                        },
                        // #endregion apply default values
                    };
                });

                newFloors.push({
                    ...floor,
                    int_walls,
                    walls,
                    rooms: floor?.rooms,
                });
            });

            const newInputs = {
                ...state.inputs,
                heat_loss: clone({
                    ...state.inputs.heat_loss,
                    floors: clone(newFloors),
                    finnish: {
                        ...state.inputs.heat_loss.finnish,
                        floorDesign: true,
                    },
                }),
            };

            if (isReset) {
                newInputs.heat_loss.finnish.walls = false;
                newInputs.heat_loss.finnish.roomElements = false;
                // @ts-ignore
                newInputs.hp_selection = formDefaultValues.hp_selection;
                // @ts-ignore
                newInputs.extras = formDefaultValues.extras;
                // @ts-ignore
                newInputs.emitters = formDefaultValues.emitters;
            }

            if (canvasChanged?.length > 0) {
                newInputs.emitters = formDefaultValues.emitters;
                setValue('emitters', formDefaultValues.emitters);
            }

            setValue('heat_loss.floors', newInputs.heat_loss.floors);
            saveProductInputs(false, newInputs);

            const newStepBlock = [
                ...stepBlock,
                ...state.stepBlock.filter((step) => step.subStep !== HpSubSteps.HEAT_LOSS_FLOOR_PANNING_DESIGN),
            ];
            return {
                ...state,
                inputs: newInputs,
                step,
                subStep,
                stepBlock: newStepBlock,
            };
        }
        case HpActions.SET_HP_HEAT_LOSS_SUBSTEP_FPLAN_DESIGN_PRINT_CANVAS_SAVED: {
            const { formDataHeatLoss, subStep, step, setValue, saveProductInputs } = action.payload;

            const newInputs = {
                ...state.inputs,
                heat_loss: {
                    ...formDataHeatLoss,
                },
            };

            // update form and productInputs
            setValue('heat_loss.floors', newInputs.heat_loss.floors);
            saveProductInputs(false, newInputs);
            return {
                ...state,
                inputs: newInputs,
                step,
                subStep,
                stepBlock: state.stepBlock.filter(
                    (step) => step.step !== HpSteps.HEAT_LOSS && step.subStep !== HpSubSteps.HEAT_LOSS_FLOOR_PANNING_DESIGN
                ),
            };
        }
        case HpActions.SET_HP_HEAT_LOSS_SUBSTEP_WALLS: {
            const { subStep, step, watchHpHeatLoss, saveProductInputs } = action.payload;
            const {
                inputs: {
                    heat_loss: { floors },
                },
            } = state;

            const newHeatLoss = {
                ...state.inputs.heat_loss,
                floors: watchHpHeatLoss?.floors?.map((newFloor) => {
                    const floorData = floors.find((el) => el.floor_number === newFloor.floor_number);

                    // Update walls
                    floorData?.rooms?.forEach((room, idx) => {
                        // @ts-ignore
                        floorData.rooms[idx] = {
                            ...room,
                            ext_walls:
                                newFloor?.walls?.filter(
                                    (wall: { element_id: number; roomId: number }) =>
                                        /* @ts-ignore */
                                        wall.roomId === room.id && wall.element_id === ELEMENT_OPTS_IDS.EXTERIOR_WALL
                                ) ?? [],
                            // @ts-ignore
                            party_walls:
                                newFloor?.walls?.filter(
                                    (wall: { element_id: number; roomId: number }) =>
                                        /* @ts-ignore */
                                        wall.roomId === room.id && wall.element_id === ELEMENT_OPTS_IDS.PARTY_WALL
                                ) ?? [],
                        };
                    });

                    return {
                        ...floorData,
                        int_walls:
                            newFloor?.walls
                                ?.filter((wall) => wall.element_id === ELEMENT_OPTS_IDS.INTERNAL_WALL)
                                .map((intWall) => {
                                    const int_wall: any = floorData?.int_walls?.find((wall: any) => wall.id === intWall.id);
                                    return {
                                        ...intWall,
                                        shared_rooms_ids: int_wall?.shared_rooms_ids ?? [],
                                    };
                                }) ?? [],
                        walls: newFloor.walls,
                    };
                }),
                finnish: {
                    ...state.inputs.heat_loss.finnish,
                    walls: true,
                },
            };
            saveProductInputs(false, {
                ...state.inputs,
                heat_loss: newHeatLoss,
            });

            return {
                ...state,
                inputs: {
                    ...state.inputs,
                    heat_loss: newHeatLoss,
                },
                step,
                subStep,
                stepBlock: state.stepBlock.filter((step) => step.subStep !== HpSubSteps.HEAT_LOSS_WALLS),
            };
        }
        case HpActions.SET_HP_HEAT_LOSS: {
            const { formHeatLoss, kpisRoomsHeatLoss, setValue, kpis } = action.payload;

            const newHeatLoss = {
                ...formHeatLoss,
                floors: state?.inputs?.heat_loss.floors?.map((stateFloor, floorInd) => {
                    const formFloorData = formHeatLoss?.floors?.find((el) => el.floor_number === stateFloor.floor_number);

                    // Update walls from state
                    formFloorData?.rooms?.forEach((formRoom, roomInd) => {
                        //#region ACPH
                        const kpis_acph = kpisRoomsHeatLoss?.find((r) => r.room_id === formRoom.id)?.acph;
                        let acph_default;
                        let acph;
                        let acph_manually_entered;
                        if (isFieldDefined(formRoom?.acph) && isFieldDefined(formRoom?.acph_default)) {
                            acph_manually_entered =
                                (
                                    !formRoom?.acph_manually_entered &&
                                    isString(formRoom?.acph) /* when the input is a string, it indicates that the value has been changed */
                                ) ?
                                    Number(formRoom?.acph) !== Number(formRoom?.acph_default)
                                :   formRoom?.acph_manually_entered;
                            // @ts-ignore
                            acph_default = acph_manually_entered ? Number(formRoom?.acph_default) : kpis_acph;

                            // @ts-ignore
                            acph = acph_manually_entered ? Number(formRoom?.acph) : Number(acph_default);
                            // @ts-ignore
                        } else {
                            acph_default = kpis_acph;
                            acph = kpis_acph;
                            acph_manually_entered = false;
                        }

                        // update form
                        setValue(`heat_loss.floors.${floorInd}.rooms.${roomInd}.acph`, acph);
                        setValue(`heat_loss.floors.${floorInd}.rooms.${roomInd}.acph_default`, acph_default);
                        setValue(`heat_loss.floors.${floorInd}.rooms.${roomInd}.acph_manually_entered`, acph_manually_entered);

                        //#endregion ACPH

                        // @ts-ignore
                        formFloorData.rooms[roomInd] = {
                            ...formRoom,
                            acph,
                            acph_default,
                            acph_manually_entered,
                            ext_walls:
                                /* @ts-ignore */
                                stateFloor?.walls?.filter(
                                    (wall) => wall.roomId === formRoom.id && wall.element_id === ELEMENT_OPTS_IDS.EXTERIOR_WALL
                                ) ?? [],
                            party_walls:
                                /* @ts-ignore */
                                stateFloor?.walls?.filter(
                                    (wall) => wall.roomId === formRoom.id && wall.element_id === ELEMENT_OPTS_IDS.PARTY_WALL
                                ) ?? [],
                        };
                    });

                    return {
                        ...formFloorData,
                        //@ts-ignore
                        int_walls: stateFloor?.int_walls ?? [],
                        //@ts-ignore
                        walls: stateFloor.walls,
                    };
                }),
                finnish: {
                    ...state.inputs.heat_loss.finnish,
                    roomElements: true,
                },
            };

            return {
                ...state,
                inputs: {
                    ...state.inputs,
                    heat_loss: newHeatLoss,
                },
                step: HpSteps.HP_SELECTION,
                subStep: null,
                stepBlock: state.stepBlock.filter((step) => step.step !== HpSteps.HEAT_LOSS),
                kpis,
            };
        }
        case HpActions.SET_HP_KPIS: {
            return {
                ...state,
                kpis: action?.payload ?? {},
            };
        }
        case HpActions.SET_HP_SELECTION: {
            const { goToStep = null, goToSubStep = null, ...hpSelection } = action.payload;
            return {
                ...state,
                inputs: {
                    ...state?.inputs,
                    hp_selection: clone(hpSelection),
                },
                step: goToStep ?? HpSteps.EXTRAS,
                subStep: goToSubStep ?? null,
                stepBlock: state.stepBlock.filter((step) => step.step !== HpSteps.HP_SELECTION),
            };
        }
        case HpActions.SET_HP_EXTRAS: {
            return {
                ...state,
                inputs: {
                    ...state?.inputs,
                    extras: clone({
                        ...state?.inputs?.extras,
                        ...action.payload,
                    }),
                },
                step: action.payload?.goToStep ?? HpSteps.EMITTER,
                subStep: action.payload?.goToSubStep ?? null,
                stepBlock: state.stepBlock.filter((step) => step.step !== HpSteps.EXTRAS),
            };
        }
        case HpActions.SET_HP_EMITTER_DATA:
            return {
                ...state,
                options: {
                    ...state?.options,
                    emitterData: action.payload,
                },
            };
        case HpActions.SET_HP_EMITTERS:
            return {
                ...state,
                inputs: {
                    ...state?.inputs,
                    emitters: clone({
                        ...state?.inputs?.emitters,
                        ...action.payload,
                    }),
                },
                proposal: null,
                stepBlock: state.stepBlock.filter((step) => step.step !== HpSteps.EMITTER),
            };
        case HpActions.SET_REDUX_INPUTS: {
            const {
                actions: { setBusinessModelsStore },
            } = useBusinessModelsStore.getState();

            getPayloadToProposal(state).then((res) => setBusinessModelsStore({ productInputs: res }));

            return {
                ...state,
                proposal: null,
            };
        }
        case HpActions.SET_HP_BUSINESS_MODELS: {
            const {
                simulationPayload,
                offer_edition,
                tipo_modelo_negocio_id,
                grants,
                margin,
                installation_labor_price,
                installation_labor_hours,
                installation_fixed_price,
            } = action.payload;

            const inputs_fe = state?.inputs ?? null;

            if (!isFieldDefined(inputs_fe)) {
                const MessageError = `FE_ERROR || CODE ERROR: ${SENTRY_CODE_ERRO_TAGS.FE_HP_MISSING_INPUT_FE}`;

                notify(intlMessages('page.error.403.unexpected'), 'error');
                SentryCaptureException({
                    level: 1,
                    message: MessageError,
                    fingerprint: MessageError,
                    extrasContext: {
                        payload: action.payload,
                    },
                });

                return state;
            }

            return {
                ...state,
                inputs: {
                    ...state?.inputs,
                    offer_edition,
                    installation_fixed_price,
                    installation_labor_price,
                    installation_labor_hours,
                },
                proposal: {
                    facility: {
                        id: Number(state.facilityID),
                    },
                    tipo_modelo_negocio_id: tipo_modelo_negocio_id,
                    inputs: {
                        ...simulationPayload,
                        offer_edition,
                        grants,
                        margin,
                        installation_labor_price,
                        installation_labor_hours,
                        installation_fixed_price,
                        inputs_fe,
                    },
                },
                step: action.payload.step,
                subStep: action.payload.subStep,
            };
        }
        case HpActions.HP_RESET: {
            const { canvas } = state;

            if (canvas?.getObjects()) {
                canvas.clear();
                canvas.renderAll();
            }

            return {
                ...state,
                isMounting: false,
                step: HpSteps.CHOOSE_STEP,
                inputs: initialHpInputs,
                dialogs: initialHpDialogs,
                subStep: null,
                canvas: null,
                proposal: null,
                kpis: {},
                errors: [],
                stepBlock: [],
            };
        }
        case HpActions.SET_HP_DIALOGS: {
            const newDialogs = action.payload
                .map((dialog) => {
                    return {
                        [dialog.name]: dialog.value,
                    };
                })
                .reduce(function (result, item) {
                    const key = Object.keys(item)[0]; //first property: a, b, c
                    result[key] = item[key];
                    return result;
                }, {});
            return {
                ...state,
                dialogs: {
                    ...state?.dialogs,
                    ...newDialogs,
                },
            };
        }
        case HpActions.SET_CHECK_KPIS_DIALOG_OPEN:
            return {
                ...state,
                checkKPIsDialogOpen: action.payload,
            };
        case HpActions.SET_ERROR: {
            const { payload } = action;

            return {
                ...state,
                errors: payload?.length > 0 ? payload : [],
            };
        }
        case HpActions.CHANGE_STEP_WITH_MODIFIED_FORM: {
            let kpis = state?.kpis ?? {};
            const {
                canvas,
                inputs: {
                    property: oldProperty,
                    heat_loss: oldHeatLoss,
                    hp_selection: oldHPSelecion,
                    extras: oldExtras,
                    emitters: oldEmitter,
                },
            } = state;
            const {
                payload: {
                    inputs: {
                        property: newProperty,
                        heat_loss: newHeatLoss,
                        hp_selection: newHPSelecion,
                        extras: newExtras,
                        emitters: newEmitter,
                    },
                    goToStep,
                    goToSubStep,
                    saveProductInputs,
                    stepBlock = null,
                },
            } = action;

            if (!!canvas?.getObjects() && [HpSteps.CHOOSE_STEP].includes(goToStep)) {
                canvas.clear();
                canvas.renderAll();
            }

            // kpis
            if ([HpSteps.CHOOSE_STEP, HpSteps.PROPERTY, HpSteps.HEAT_LOSS].includes(goToStep)) {
                kpis = {};
            }

            if ([HpSteps.HP_SELECTION, HpSteps.EMITTER].includes(goToStep)) {
                kpis = {
                    ...kpis,
                    bom: {},
                };
            }

            //update walls on reset
            let updatedNewHeatLoss = newHeatLoss;

            if (isDefined(newHeatLoss)) {
                updatedNewHeatLoss = {
                    ...newHeatLoss,
                    floors: newHeatLoss?.floors?.map((newFloor) => {
                        const floorData = newHeatLoss.floors.find((el) => el.floor_number === newFloor.floor_number);

                        floorData?.rooms?.forEach((room, idx) => {
                            // @ts-ignore
                            floorData.rooms[idx] = {
                                ...room,
                                // @ts-ignore
                                ext_walls:
                                    newFloor?.walls?.filter(
                                        (wall) => wall.roomId === room.id && wall.element_id === ELEMENT_OPTS_IDS.EXTERIOR_WALL
                                    ) ?? [],
                                // @ts-ignore
                                party_walls:
                                    newFloor?.walls?.filter(
                                        (wall) => wall.roomId === room.id && wall.element_id === ELEMENT_OPTS_IDS.PARTY_WALL
                                    ) ?? [],
                            };
                        });

                        return {
                            ...floorData,
                            walls: newFloor.walls,
                        };
                    }),
                    finnish: {
                        ...state.inputs.heat_loss.finnish,
                        walls: true,
                    },
                };
            }

            const newinputs = {
                property: {
                    ...oldProperty,
                    ...newProperty,
                },
                heat_loss: {
                    ...oldHeatLoss,
                    ...updatedNewHeatLoss,
                },
                hp_selection: {
                    ...oldHPSelecion,
                    ...newHPSelecion,
                },
                extras: {
                    ...oldExtras,
                    ...newExtras,
                },
                emitters: {
                    ...oldEmitter,
                    ...newEmitter,
                },
            };

            saveProductInputs(false, newinputs);
            const newState = {
                ...state,
                inputs: newinputs,
                kpis,
                step: goToStep,
                subStep: goToSubStep,
                dialogs: initialHpDialogs,
            };

            if (stepBlock) newState.stepBlock = [...newState.stepBlock, stepBlock];
            if (!isDefined(stepBlock)) newState.stepBlock = newState.stepBlock.filter((step) => step.step !== state.step);

            return newState;
        }

        case HpActions.RESET_VALUES_AHEAD: {
            let kpis = state?.kpis ?? {};
            const {
                canvas,
                inputs: {
                    property: oldProperty,
                    heat_loss: oldHeatLoss,
                    hp_selection: oldHPSelecion,
                    extras: oldExtras,
                    emitters: oldEmitter,
                },
            } = state;
            const {
                formData: {
                    property: newProperty,
                    heat_loss: newHeatLoss,
                    hp_selection: newHPSelecion,
                    extras: newExtras,
                    emitters: newEmitter,
                },
                goToStep,
                goToSubStep,
                saveProductInputs,
            } = action.payload;

            if (!!canvas?.getObjects() && [HpSteps.CHOOSE_STEP].includes(goToStep)) {
                canvas.clear();
                canvas.renderAll();
            }

            // kpis
            if ([HpSteps.CHOOSE_STEP, HpSteps.PROPERTY, HpSteps.HEAT_LOSS].includes(goToStep)) {
                kpis = {};
            }

            if ([HpSteps.HP_SELECTION, HpSteps.EMITTER].includes(goToStep)) {
                kpis = {
                    ...kpis,
                    bom: {},
                };
            }

            const resetHeatLoss = goToStep === HpSteps.HEAT_LOSS && goToSubStep === HpSubSteps.HEAT_LOSS_FLOOR_PANNING_DESIGN;

            //update walls on reset
            const updatedNewHeatLoss = {
                ...newHeatLoss,
                floors:
                    !resetHeatLoss ?
                        newHeatLoss?.floors?.map((newFloor) => {
                            const floorData = newHeatLoss.floors.find((el) => el.floor_number === newFloor.floor_number);

                            floorData?.rooms?.forEach((room, idx) => {
                                // @ts-ignore
                                floorData.rooms[idx] = {
                                    ...room,
                                    // @ts-ignore
                                    ext_walls:
                                        newFloor?.walls?.filter(
                                            (wall) => wall.roomId === room.id && wall.element_id === ELEMENT_OPTS_IDS.EXTERIOR_WALL
                                        ) ?? [],
                                    // @ts-ignore
                                    party_walls:
                                        newFloor?.walls?.filter(
                                            (wall) => wall.roomId === room.id && wall.element_id === ELEMENT_OPTS_IDS.PARTY_WALL
                                        ) ?? [],
                                };
                            });

                            return {
                                ...floorData,
                                walls: newFloor.walls,
                            };
                        })
                    :   newHeatLoss.floors,
                finnish:
                    !resetHeatLoss ?
                        {
                            ...state.inputs.heat_loss.finnish,
                            walls: true,
                        }
                    :   {
                            ...initialHpInputs.heat_loss.finnish,
                            floorOptions: true,
                        },
            };

            const newinputs = {
                property: {
                    ...oldProperty,
                    ...newProperty,
                },
                heat_loss: {
                    ...oldHeatLoss,
                    ...updatedNewHeatLoss,
                },
                hp_selection: {
                    ...oldHPSelecion,
                    ...newHPSelecion,
                },
                extras: {
                    ...oldExtras,
                    ...newExtras,
                },
                emitters: {
                    ...oldEmitter,
                    ...newEmitter,
                },
            };

            saveProductInputs(false, newinputs);
            const newState = {
                ...state,
                inputs: newinputs,
                kpis,
                step: goToStep,
                subStep: goToSubStep,
                dialogs: initialHpDialogs,
                stepBlock: state.stepBlock.filter((step) => step.step !== state.step && step.subStep !== state.subStep),
            };

            return newState;
        }

        case HpActions.SET_ELEMENT_TYPE_OPTIONS: {
            return {
                ...state,
                options: {
                    ...state?.options,
                    elementTypeOptions: action?.payload,
                },
            };
        }
        case HpActions.SET_EXTRA_EQUIPMENT_CAT_MODEL: {
            const { payload } = action;

            return {
                ...state,
                options: {
                    ...state.options,
                    extraEquipmentCatModel: payload,
                },
            };
        }
        case HpActions.SET_HAS_SHOW_WATERMARK: {
            const { payload } = action;

            return {
                ...state,
                hasShowWatermark: payload,
            };
        }

        default:
            return state;
    }
};
