import { isDefined, isFieldDefined, isNumberDefined } from 'services/util/auxiliaryUtils';
import { PAYMENT_MODELS_IDS } from './../../../constants/businessModels';
import { useContext, useCallback, useEffect } from 'react';
import { IBusinessModel, IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { LECEvents, Tlec } from 'interfaces/businessModels/lec';
import { BusinessModelsProContext, BusinessModelsProWebstompContext } from 'contexts/businessModelsPro/businessModelsContext';
import { BusinessModelsActions } from 'interfaces/businessModels/reducer';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { DetailedOfferEditionEvents } from 'interfaces/businessModels/detailed';
import { ReduxContext } from 'contexts/redux/reduxContext';
import { GrantsAmmountsType } from 'interfaces/businessModels/upfront';
import { grantCurrencyAmmount, grantPrcAmmount, updateTotalGrantsHandler } from 'services/businessModels/upfront';
import { computeRubricCostsPayload, simulationCost } from 'services/businessModels/index';

const useLECPBM = (bm: IBusinessModel) => {
    const {
        client: { contacto_email: clientEmail, contacto_nome: clientName },
        product: { productInputs },
    } = useContext(ReduxContext);

    const { state, bmEvent, bmSelected, dispatch, productPayload, setBMEventHandler, negotiationProfile, productID } = useContext(
        BusinessModelsProContext
    ) as IBusinessModelsContext<TProductInputs, Tlec>;

    const {
        isLoadingWebStomp,
        // fetchSimulations,
        mqMessages,
    } = useContext(BusinessModelsProWebstompContext);

    const { bmState, setBMEventHandler: syncSetBMEventHandler } = useContext(OfferEditionContext);

    const fetchNewLECSimulationHandler = (offerEdition) => {
        dispatch({
            type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
            payload: { kpis_per_margin: [], offerEdition: offerEdition, marginSelected: undefined },
        });
    };
    const updateLECValues = (newKpis) => {
        dispatch({
            type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
            payload: {
                ...state.selected.values,
                kpis_per_margin: newKpis,
                proposalReady: true,
            },
        });
    };

    const leCEvents = useCallback(() => {
        if (state.selected.paymentModelID === PAYMENT_MODELS_IDS.LEC && isDefined(bmEvent?.name)) {
            const currentValues = state.selected.values;
            const currentGrants = state.selected.values?.grants?.grants;
            const syncKpis = productPayload?.productInputs?.syncKpis?.find((bm) => bm.tipo_modelo_negocio_id === state.selected.id);

            const margin =
                state.selected.values?.marginSelected ??
                state.businessModels[0].kpis_previews[0].kpis_per_margin.find((kpi) => kpi.is_default)?.margin ??
                0;

            switch (bmEvent?.name) {
                case LECEvents.SET_LEC_BM: {
                    let clientContactInfo;
                    if (negotiationProfile?.mostra_formulario && state?.selected?.options?.has_form) {
                        clientContactInfo =
                            (
                                !isDefined(productInputs?.SIMPLES_Dimensionamento?.inputs?.contact_info) &&
                                !isDefined(productInputs?.SIMPLES_Dimensionamento?.inputs?.contact_info?.name)
                            ) ?
                                {
                                    name: clientName,
                                    email: clientEmail,
                                }
                            :   productInputs?.SIMPLES_Dimensionamento?.inputs?.contact_info;
                    }

                    if (clientContactInfo) bmEvent.payload.clientContactInfo = clientContactInfo;

                    if (isDefined(syncKpis)) {
                        // detailed
                        const businessModelSelected = state.businessModels.find(
                            (bm) => bm.payment_model_id === state.selected.paymentModelID
                        );
                        syncSetBMEventHandler(DetailedOfferEditionEvents.SET_BUSINESS_MODEL_SELECTED, {
                            businessModelSelected: businessModelSelected,
                            businessModelBody: productPayload,
                            kpis: syncKpis,
                            offerEdition: productPayload?.productInputs?.offerEdition,
                            elements: {
                                monthlyPayments:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'monthly_payment_option'
                                    ) ?? null,
                                omContractDuration:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'om_contract_duration'
                                    ) ?? null,
                                annualUpdateMonthlyFee:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'tariff_annual_update'
                                    ) ?? null,
                                uniqueAllowance:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'unique_allowance'
                                    ) ?? null,
                                yearlyAllowance:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'yearly_allowance'
                                    ) ?? null,
                                recOwnership:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'boolean_rec_ownership'
                                    ) ?? null,
                                ownershipTransfer:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'boolean_ownership_transfer'
                                    ) ?? null,
                                itcRate:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'itc_rate_prc'
                                    ) ?? null,
                            },
                        });
                        const rubrics = state?.simulation?.rubricsCosts?.find(
                            (rubric) => rubric?.isSync && state?.selected.id === rubric?.tipo_modelo_negocio_id
                        );

                        // FIXME: Deve-se usar a versão do Capex ou a das simulations?
                        const showCapex = state?.selected?.options?.show_capex;
                        const costValue = simulationCost(productID, syncKpis, showCapex);

                        const baseCost = rubrics?.rubrics.length > 0 ? costValue - rubrics?.defaultRubricCost : costValue;
                        const extraCost = state?.simulation?.offerEdition?.cost?.extra ?? 0;
                        const rubricsCost = rubrics?.defaultRubricCost ?? 0;

                        const offerDetailsCost = baseCost + extraCost + rubricsCost;
                        const totalCost = offerDetailsCost;

                        syncSetBMEventHandler(DetailedOfferEditionEvents.SET_BUSINESS_MODEL_SELECTED, {
                            businessModelSelected: businessModelSelected,
                            businessModelBody: productPayload,
                            kpis: syncKpis,
                            offerEdition: {
                                ...productPayload?.productInputs?.offerEdition,
                                system: productPayload?.productInputs?.system,
                                is_customize: productPayload?.productInputs?.inputs?.is_customize ?? false,
                            },
                            elements: {
                                monthlyPayments:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'monthly_payment_option'
                                    ) ?? null,
                                omContractDuration:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'om_contract_duration'
                                    ) ?? null,
                                annualUpdateMonthlyFee:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'tariff_annual_update'
                                    ) ?? null,
                                uniqueAllowance:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'unique_allowance'
                                    ) ?? null,
                                yearlyAllowance:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'yearly_allowance'
                                    ) ?? null,
                                recOwnership:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'boolean_rec_ownership'
                                    ) ?? null,
                                ownershipTransfer:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'boolean_ownership_transfer'
                                    ) ?? null,
                                itcRate:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'itc_rate_prc'
                                    ) ?? null,
                            },
                        });

                        dispatch({
                            type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                            payload: {
                                ...bmEvent.payload,
                                syncKpis: syncKpis,
                                clientContactInfo,
                                costs: {
                                    ...currentValues?.costs,
                                    offerDetailsCost,
                                    baseCost,
                                    marginCost:
                                        bmSelected.isDiscount ?
                                            offerDetailsCost - offerDetailsCost * (margin ?? 0)
                                        :   offerDetailsCost / (1 - margin),
                                    grantsCost: 0,
                                    totalCost,
                                },
                                grants: {
                                    hasGrants: state.selected.options?.has_subsidy ?? false,
                                    totalGrants: {
                                        currency: 0,
                                        prc: 0,
                                    },
                                    grants: [
                                        {
                                            id: 1,
                                            description: '',
                                            ammounts: {
                                                prc: 0,
                                                currency: 0,
                                            },
                                        },
                                    ],
                                },
                                rubrics: {
                                    values: rubrics?.rubrics ?? [],
                                    cost: rubrics?.defaultRubricCost ?? 0,
                                },
                            },
                        });
                    } else {
                        // simple
                        dispatch({
                            type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                            payload: bmEvent.payload,
                        });
                    }
                    break;
                }

                case LECEvents.SET_LEC_MARGIN:
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: { ...currentValues, marginSelected: bmEvent.payload },
                    });
                    break;

                case LECEvents.SET_LEC_EXTRA_COST: {
                    const newOfferDetailsCost =
                        currentValues?.costs?.baseCost + parseFloat(bmEvent.payload.extra_cost) + state.selected.values?.rubrics?.cost;

                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            offerEdition: {
                                ...currentValues.offerEdition,
                                ...bmEvent.payload,
                            },
                            costs: {
                                ...currentValues.costs,
                                offerDetailsCost: newOfferDetailsCost,
                                totalCost: newOfferDetailsCost,
                            },
                        },
                    });
                    break;
                }

                case LECEvents.UPDATE_LEC_VALUES_BM:
                    fetchNewLECSimulationHandler(bmEvent.payload);
                    break;

                case LECEvents.SET_LEC_PROPOSAL_READY:
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: { kpis_per_margin: [], proposalReady: bmEvent.payload },
                    });
                    break;

                case LECEvents.SET_LEC_PROPOSAL: {
                    const payload = {
                        ...state.simulation,
                        inputs: {
                            ...state.simulation.inputs,
                            offer_edition: {
                                ...state.selected.values?.offerEdition,
                            },
                            lec_tariff: state.selected.values.marginSelected,
                            contact_info: state.selected.values?.clientContactInfo,
                            id_crm: state.selected.values?.id_crm,
                        },
                        tipo_modelo_negocio_id: state.selected.id,
                    };

                    if (isDefined(syncKpis)) {
                        payload.inputs = {
                            ...payload.inputs,
                            offer_edition: {
                                ...payload.inputs.offer_edition,
                                extra_cost:
                                    isNumberDefined(bmState.offerEditionValues?.extraCost) ?
                                        parseFloat(`${bmState.offerEditionValues?.extraCost}`)
                                    :   0,
                            },
                            annual_evo_fee:
                                isFieldDefined(parseFloat(`${bmState.offerEditionValues?.annualUpdateFee}`)) ?
                                    parseFloat(`${bmState.offerEditionValues?.annualUpdateFee}`) / 100
                                :   payload.inputs?.annual_evo_fee ?? undefined,
                            monthly_fee:
                                isFieldDefined(parseFloat(`${bmState.offerEditionValues?.monthlyPayment}`)) ?
                                    parseFloat(`${bmState.offerEditionValues?.monthlyPayment}`)
                                :   payload.inputs?.monthly_fee ?? undefined,
                            monthly_payment_option:
                                isFieldDefined(parseFloat(`${bmState.offerEditionValues?.monthly_payment_option_id}`)) ?
                                    parseInt(`${bmState.offerEditionValues?.monthly_payment_option_id}`)
                                :   payload.inputs?.monthly_payment_option_id ?? undefined,
                            op_and_man_duration:
                                isFieldDefined(bmState?.offerEditionValues?.opAndManDuration) ?
                                    bmState?.offerEditionValues?.opAndManDuration
                                :   undefined,
                            boolean_rec_ownership:
                                isFieldDefined(bmState?.offerEditionValues?.boolean_rec_ownership) ?
                                    bmState?.offerEditionValues?.boolean_rec_ownership
                                :   undefined,
                            boolean_ownership_transfer:
                                isFieldDefined(bmState?.offerEditionValues?.boolean_ownership_transfer) ?
                                    bmState?.offerEditionValues?.boolean_ownership_transfer
                                :   undefined,
                            itc_rate_prc:
                                isFieldDefined(parseFloat(`${bmState.offerEditionValues?.itc_rate_prc}`)) ?
                                    parseFloat(`${bmState.offerEditionValues?.itc_rate_prc}`) / 100
                                :   payload.inputs?.itcRate ?? undefined,
                            fin_kpis:
                                isFieldDefined(bmState?.kpis?.finalKpis) ? bmState.kpis?.finalKpis : payload.inputs?.fin_kpis ?? undefined,
                            negotiation:
                                isFieldDefined(bmState?.kpis?.negotiation) ? bmState.kpis?.negotiation
                                : isFieldDefined(syncKpis?.negotiation) ? syncKpis?.negotiation
                                : payload.inputs?.negotiation ?? undefined,
                            isChangeBattery:
                                isFieldDefined(bmState?.businessModelBody?.inputs?.isChangeBattery) ?
                                    bmState?.businessModelBody?.inputs?.isChangeBattery
                                :   payload?.inputs?.isChangeBattery ?? undefined,
                            service_energy_price: 0, // TODO: FIXME: não consigo encontrar este valor, mas parece-me ir sempre a 0 (default)
                        };

                        const reqIDSet = new Set<string>();
                        syncKpis.req_id_arr?.forEach(reqIDSet.add, reqIDSet);
                        bmState?.reqsHash?.forEach(reqIDSet.add, reqIDSet);
                        const reqIDArr = Array.from(reqIDSet);
                        payload.req_id_arr = reqIDArr ?? [];
                    }

                    if (state?.selected?.values?.grants?.totalGrants?.currency > 0) {
                        payload.inputs = {
                            ...payload.inputs,
                            grants: state?.selected?.values?.grants?.grants.map((grant) => {
                                return {
                                    description: grant?.description,
                                    prc: grant?.ammounts?.prc / 100,
                                };
                            }),
                        };
                    }

                    dispatch({ type: BusinessModelsActions.SET_PROPOSAL, payload });

                    break;
                }

                case LECEvents.UPDATE_LEC_ADDITIONAL_DATA:
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            clientContactInfo: {
                                ...currentValues?.clientContactInfo,
                                ...bmEvent.payload?.clientContactInfo,
                            },
                            id_crm: bmEvent.payload?.id_crm ? bmEvent.payload?.id_crm : currentValues?.id_crm ?? null,
                        },
                    });
                    break;

                case LECEvents.ADD_LEC_GRANT: {
                    const higherGrantId =
                        currentGrants.length > 0 ?
                            currentGrants.reduce((acc, value) => {
                                return (acc = acc > value.id ? acc : value.id);
                            }, 0)
                        :   1;
                    const updatedGrants = [
                        ...currentGrants,
                        {
                            id: higherGrantId + 1,
                            description: '',
                            ammounts: {
                                prc: 0,
                                currency: 0,
                            },
                        },
                    ];
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            grants: {
                                ...state.selected.values.grants,
                                grants: updatedGrants,
                            },
                        },
                    });

                    break;
                }

                case LECEvents.DELETE_LEC_GRANT: {
                    const grantsWithoutTheSelectedToDelete = currentGrants.filter((grant) => grant.id !== bmEvent.payload);
                    const updatedTotalGrantsWithoutTheSelectedToDelete = {
                        currency: grantsWithoutTheSelectedToDelete?.reduce(
                            (accumulator, currentValue) => accumulator + currentValue.ammounts.currency,
                            0
                        ),
                        prc: grantsWithoutTheSelectedToDelete?.reduce(
                            (accumulator, currentValue) => accumulator + currentValue.ammounts.prc,
                            0
                        ),
                    };
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            grants: {
                                ...state.selected.values.grants,
                                totalGrants: updatedTotalGrantsWithoutTheSelectedToDelete,
                                grants: grantsWithoutTheSelectedToDelete,
                            },
                        },
                    });
                    break;
                }

                case LECEvents.SET_DESCRIPTION_GRANT: {
                    const grantsWithUpdatedDescriptions = currentGrants.map((grant) => {
                        if (grant.id === bmEvent.payload.id) grant.description = bmEvent.payload.description;
                        return grant;
                    });
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            grants: {
                                ...state.selected.values.grants,
                                grants: grantsWithUpdatedDescriptions,
                            },
                        },
                    });
                    break;
                }

                case LECEvents.SET_AMMOUNT_GRANT: {
                    //FIXME: Qual versão utilizar aqui, Capex ou offerEdition?
                    // const costSelected = bmState.businessModelSelected?.show_capex
                    //     ? (bmState?.kpis?.kpis?.system?.costs?.original_capex ?? bmState?.kpis?.kpis?.system?.costs?.capex)
                    //     : bmState.kpis?.kpis?.cost

                    const currentInvestment = /* costSelected */ state?.selected?.values?.costs?.offerDetailsCost;
                    const grantsWithUpdatedAmmounts = currentGrants.map((grant) => {
                        if (grant.id === bmEvent.payload.id)
                            grant.ammounts =
                                bmEvent.payload.ammountType === GrantsAmmountsType.CURRENCY ?
                                    grantCurrencyAmmount(currentInvestment, bmEvent.payload.amount)
                                :   grantPrcAmmount(currentInvestment, bmEvent.payload.amount);
                        return grant;
                    });

                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            grants: {
                                ...state.selected.values.grants,
                                totalGrants: updateTotalGrantsHandler(grantsWithUpdatedAmmounts),
                                grants: grantsWithUpdatedAmmounts,
                            },
                        },
                    });

                    break;
                }

                case LECEvents.UPDATE_LEC_GRANTS: {
                    //FIXME: Qual versão utilizar aqui, Capex ou offerEdition?
                    // const costSelected = bmState.businessModelSelected?.show_capex
                    //     ? (bmState?.kpis?.kpis?.system?.costs?.original_capex ?? bmState?.kpis?.kpis?.system?.costs?.capex)
                    //     : bmState.kpis?.kpis?.cost

                    const currentInvestment = /* costSelected */ state?.selected?.values?.costs?.offerDetailsCost;
                    const newCurrentGrants = currentGrants?.length > 0 ? [...currentGrants] : [];
                    const updatedGrants = newCurrentGrants.map((grant) => {
                        grant.ammounts = grantPrcAmmount(currentInvestment, grant.ammounts.prc);
                        return grant;
                    });

                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            grants: {
                                ...state.selected.values.grants,
                                totalGrants: updateTotalGrantsHandler(updatedGrants),
                                grants: updatedGrants,
                            },
                        },
                    });
                    break;
                }

                case LECEvents.SET_LEC_RUBRIC_COST: {
                    const newRubrics = state.selected.values.rubrics.values.map((rubric) => {
                        if (rubric.cost_id === bmEvent.payload.id) {
                            rubric.included_cost = bmEvent.payload.selected;
                        }
                        return rubric;
                    });

                    const rubricsCost = newRubrics?.reduce((acc, curr) => {
                        if (curr?.included_cost) return acc + parseInt(curr?.final_cost);
                        else return acc;
                    }, 0);

                    const newOfferDetailsCost =
                        currentValues.costs.baseCost + (state.selected.values?.offerEdition?.extra_cost ?? 0) + rubricsCost;

                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            rubrics: {
                                values: newRubrics,
                                cost: rubricsCost,
                            },
                            offerEdition: {
                                ...currentValues.offerEdition,
                                costs: computeRubricCostsPayload(state.simulation.system?.costs, newRubrics),
                            },
                            costs: {
                                ...currentValues.costs,
                                offerDetailsCost: newOfferDetailsCost,
                                marginCost: newOfferDetailsCost,
                                totalCost: newOfferDetailsCost,
                            },
                        },
                    });

                    break;
                }
            }
        }
    }, [bmEvent]); // eslint-disable-line

    useEffect(() => {
        leCEvents();
    }, [leCEvents]);

    useEffect(() => {
        if (state.selected.paymentModelID === PAYMENT_MODELS_IDS.LEC) {
            // simple and detailed
            setBMEventHandler(LECEvents.SET_LEC_BM, {
                kpis_per_margin: productPayload.lec_aux_kpis_arr,
                proposalReady: true,
            });
        }
    }, [state.selected.paymentModelID]); // eslint-disable-line

    useEffect(() => {
        if (!isLoadingWebStomp && mqMessages.length > 0) {
            // @ts-ignore
            if (mqMessages[0]?.data.length > 0) {
                // @ts-ignore
                updateLECValues(mqMessages[0].data);
            }
        }
    }, [isLoadingWebStomp, mqMessages]); // eslint-disable-line

    return {
        pitch: bm.pitch,
        projectSummary: bm.kpis_previews,
        selected: bm.selected,
        id: bm.payment_model_id,
        sizingTypeId: bm.sizing_type_id,
        syncKpis: productPayload?.productInputs?.syncKpis?.find((sincBm) => sincBm.tipo_modelo_negocio_id === bm.business_model_id),
    };
};

export default useLECPBM;
