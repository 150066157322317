import { useContext, useEffect } from 'react';

import { AppConfigurationClient } from '@azure/app-configuration';
import { BASENAME_URL, SettingsActions } from 'constants/settings';
import { isEnvDevelopment } from 'services/settings';
import { useHistory } from 'react-router-dom';
import { forceCleanup, isDefined, isFieldDefined } from 'services/util/auxiliaryUtils';
import { TFeatureFlags, useFeatureFlags, FEATURE_FLAGS, FEATURE_FLAGS_TST } from 'store/featureFlags';
import { SettingsContext } from 'contexts/settingsContext';
import { SentryCaptureException } from 'services/@efz/sentry';
import { notify } from 'services/@efz/notify';
import { axiosPublicInstance } from 'services/@efz/axios';

// @ts-ignore
const client = new AppConfigurationClient(process.env.REACT_APP_AZR_FEATURE_FLAG_CONNECTION_STR);

function getIssueIdAfterReqError(inputString) {
    // Find the index of 'fe-'
    const feIndex = inputString.indexOf('fe-');

    // Check if 'fe-' is present in the string
    if (feIndex !== -1) {
        // Get the substring starting from the index after 'fe-'
        const nextFourCharacters = inputString.substring(feIndex + 3, feIndex + 7);
        return nextFourCharacters;
    } else {
        // 'fe-' not found in the string
        return null;
    }
}

const useAppSettings = () => {
    const history = useHistory();
    const { settingsState, settingsDispatch } = useContext(SettingsContext);
    const { id, setFeatureFlags, featureFlags } = useFeatureFlags();

    const fetchFeatureFlags = async () => {
        const _featureFlags: TFeatureFlags = { ...featureFlags };
        let _id = null;
        const debugId: number | undefined = undefined;
        const reqFeatureFlags: any[] = [];
        for await (const issue of FEATURE_FLAGS) {
            if (!isEnvDevelopment() || FEATURE_FLAGS_TST.includes(issue)) {
                reqFeatureFlags.push(
                    client.getConfigurationSetting({
                        key: `.appconfig.featureflag/fe-${issue.toString().trim()}-${process.env.REACT_APP_ENV?.toLowerCase()}`,
                        label: `efz-sales-fe`,
                    })
                );
            }
        }

        try {
            const featureFlags = await Promise.allSettled(reqFeatureFlags);
            let _401error = false;
            featureFlags.forEach((fFlag) => {
                if (fFlag?.status === 'fulfilled') {
                    if (fFlag && typeof fFlag === 'object') {
                        // @ts-ignore
                        const rspParsed = JSON.parse(fFlag.value.value);

                        _featureFlags[rspParsed.id.slice(0, -4)] = rspParsed.enabled;
                    }
                } else {
                    const issueId = getIssueIdAfterReqError(fFlag.reason.request.url);
                    _featureFlags[`fe-${issueId}`] = debugId === Number(issueId) ? true : false;
                }
            });
            featureFlags
                .filter((fFlag) => fFlag.status === 'rejected')
                .forEach((fFlag) => {
                    if (!_401error && fFlag?.reason?.statusCode === 401) {
                        _401error = true;
                        SentryCaptureException({
                            level: 3,
                            message: 'FF401',
                        });
                        history.push(`${BASENAME_URL}app/error`);
                    }
                });
        } catch (error) {
            console.error('PLEASE ADD FEATURE FLAG MISSING');
        }

        if (isEnvDevelopment()) {
            FEATURE_FLAGS.filter((issue) => !FEATURE_FLAGS_TST.includes(issue)).forEach((issue) => {
                _featureFlags[`fe-${issue}`] = true;
            });
        }
        try {
            _id = await getFFid();
        } catch (error) {
            _id = null;
        }

        return { featureFlags: _featureFlags, id: _id };
    };

    const mountApp = async () => {
        try {
            const { featureFlags, id } = await fetchFeatureFlags();
            setFeatureFlags(featureFlags, id);
            if (localStorage.getItem('efz-cleanup-notify')) {
                notify('', 'update');
                localStorage.removeItem('efz-cleanup-notify');
            }
            settingsDispatch({
                type: SettingsActions.MOUNT_APP,
                payload: {
                    isMoutingApp: false,
                    featureFlags: featureFlags,
                },
            });
        } catch (error) {
            console.error('Error fetching efz-cleanup');
        }
    };
    const getFFCleanup = async () => {
        try {
            const rspFFCleanup = await client.getConfigurationSetting({
                key: `.appconfig.featureflag/fe-cleanup-${process.env.REACT_APP_ENV?.toLowerCase()}`,
                label: `efz-sales-fe`,
            });
            const hasCleanUp = JSON.parse(rspFFCleanup.value || '');

            const ffDescription = JSON.parse(rspFFCleanup?.value).description;
            const sStorageValue = localStorage.getItem('efz-cleanup');
            if (hasCleanUp?.enabled && sStorageValue !== `efz:${ffDescription}`) {
                forceCleanup(ffDescription);
                window.location.reload();
            }
            if (!hasCleanUp?.enabled) {
                localStorage.removeItem('efz-cleanup');
                // sessionStorage.removeItem('efz-cleanup');
            }
            return false;
        } catch (error) {
            console.error('Error getFFCleanup efz-cleanup', error);
        }
    };

    const getFFid = async () => {
        try {
            await getFFCleanup();
            const ffId = await client.getConfigurationSetting({
                key: `.appconfig.featureflag/fe-id`,
                label: `efz-sales-fe`,
            });
            if (ffId?.value) {
                const _id = JSON.parse(ffId.value).description;
                return _id;
            }

            return null;
        } catch (error) {
            console.error('Error getFFid ', error);
        }
    };

    useEffect(() => {
        mountApp();
    }, []); // eslint-disable-line

    const updateFFHandler = async () => {
        try {
            const _currentFFId = await getFFid();
            if (_currentFFId !== id) mountApp();
        } catch (error) {
            console.error('Error updateFFHandler ', error);
        }
    };

    useEffect(() => {
        if (history.location.pathname.includes('*')) history.push(`${BASENAME_URL}app/403`);
        if (isDefined(id)) {
            updateFFHandler();
        }

        const fetchMeta = () => {
            axiosPublicInstance
                .get('/meta.json', {})
                .then((response) => {
                    const { data } = response;
                    const latestVersion = data.version;
                    const currentVersion = localStorage.getItem('Platform-Version');
                    if (!isFieldDefined(currentVersion)) {
                        localStorage.setItem('Platform-Version', latestVersion);
                        window.location.reload();
                        return;
                    }
                    if (latestVersion !== currentVersion && !['/login', '/'].includes(history.location.pathname)) {
                        // localStorage.setItem('Platform-Version', latestVersion);
                        settingsDispatch({
                            type: SettingsActions.SET_HAS_NEW_VERSION,
                            payload: {
                                hasNewVersion: true,
                                platformLatestVersion: latestVersion,
                            },
                        });
                    }
                })
                .catch((error) => console.log('metaJson erro', error));
        };

        fetchMeta(); // Initial fetch

        const intervalId = setInterval(fetchMeta, 60000); // Fetch every 1 minute

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [history.location.pathname]); // eslint-disable-line

    return {
        hasNewVersion: settingsState.hasNewVersion,
        isMoutingApp: settingsState.isMoutingApp,
        featureFlags: settingsState.featureFlags,
    };
};

export default useAppSettings;
